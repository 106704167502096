import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Units } from '@turf/turf'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  LocationType,
  SitemapItemShapeType,
  SitemapLineArrowPosition,
} from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Checkbox from '~/client/src/shared/components/Checkbox'
import GeoreferencingLabel from '~/client/src/shared/components/GeoreferencingLabel/GeoreferenceingLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import { getDistance } from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import SitemapCircleProperties, {
  FEET_TO_TURF_DISTANCE,
} from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import SitemapItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import SitemapItemsColorPicker from '~/client/src/shared/components/SitemapItemsColorPicker/SitemapItemsColorPicker'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import LocationBaseWithDimensions from '~/client/src/shared/models/LocationObjects/LocationBaseWithDimensions'
import { LocationIntegrationType } from '~/client/src/shared/models/LocationObjects/LocationIntegration'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import { ISitemapGeoPosition } from '~/client/src/shared/models/Sitemap'
import InitialState from '~/client/src/shared/stores/InitialState'
import {
  FORBIDDEN_SITE_NAME,
  FORBIDDEN_SITE_NAME_MESSAGE,
} from '~/client/src/shared/stores/domain/LocationBase.store'

import GeneralSitemapSetUpStore, {
  SetUpSteps,
} from '../../GeneralSitemapSetUp.store'
import SitemapItemsSetupStore from '../../stores/SitemapItemsSetup.store'
import SitemapSetupStore from '../../stores/SitemapsSetup.store'
import BuildingProperties from './BuildingProperties'
import DimensionProperties from './DimensionProperties'
import IconSelector from './IconSelector'
import ObjectAccessibleLevelsProperties from './ObjectAccessibleLevelsProperties'
import ParentsSelector from './ParentSelector'
import PropertySelect, { IPropertySelectOption } from './PropertySelect'
import ShapeSelector from './ShapeSelector'

import Colors from '~/client/src/shared/theme.module.scss'

import './PropertiesPanel.scss'

const propertiesPanel = {
  selectItemToEditProperties: 'Select item to edit properties',
  finishEditing: 'Finish editing',
  objectNestedUnder: 'Object nested under',
  edit: 'Edit',
  name: 'Name',
  shape: 'Shape',
  color: 'Color',
  object: 'Object',
  fills: 'Fills',
  opacity: 'Opacity',
  borders: 'Borders',
  line: 'Line',
  width: 'Width',
  radius: 'Radius',
  divisionStartAngle: 'Division start angle',
  divisionEndAngle: 'Division end angle',
  crane: 'Crane',
  limitSwingRadius: 'Limit swing radius',
  textStyle: 'Text style',
  fontSize: 'Font Size',
  fontColor: 'Font color',
  showTextBox: 'Show Text Box',
  showLabel: 'Show Label',
  showIcon: 'Show Icon',
  showShape: 'Show Shape',
  lineStyle: 'Line Style',
  arrows: 'Arrows',
  icon: 'Icon',
  iconColor: 'Icon color',
  text: 'Text',
}

const doneTypingInterval = 300
const NAME_INPUT_MAX_WIDTH = 215
const LETTER_WIDTH = 10

const PERCENT_MODIFIER = 100
const MAX_OPACITY_VALUE = 100
const MIN_OPACITY_VALUE = 0
const OPACITY_STEP = 1

const MAX_LINE_WIDTH_VALUE = 10
const MIN_LINE_WIDTH_VALUE = 1
const LINE_WIDTH_STEP = 1
const DESIMAL_LINE_WIDTH_STEP = 0.001

const MAX_FONT_VALUE = 100
const MIN_FONT_VALUE = 4
const FONT_STEP = 1
const maxDegrees = 360
const maxZoomValue = 21
const minZoomValue = 0.6
const zoomStep = 0.01
const bearingStep = 1

const shouldReferenceItemToRealWorld =
  'Should reference item to real world coordinates?'
const coordinatesOfThePoint = 'Coordinates of the point'
const latitude = 'Latitude'
const longitude = 'Longitude'
const saveAligment = 'Save Aligment'
const georeference = 'Georeferencing'
const LOADER_SIZE = 40
const longLatOfTheCurrentView = 'Longitude, latitude of current view'
const viewSettings = 'View settings'
const zoomOfTheCurrentView = 'Zoom of current view'
const orientationOfTheCurrentView = 'Orientation of the current'
const planProperties = 'Plan properties'
const baseMapProperies = 'Basemap properties'
const addressOfTheProject = 'Address of the project'
const latLngOfTheProject = 'Longitude, latitude of current view (center)'

export interface IProps {
  store: GeneralSitemapSetUpStore
  saveAligment: () => void
  exitRubber: () => void
  step: SetUpSteps
  viewport: ISitemapGeoPosition
  state?: InitialState
  selectedItemId?: string
  bearing?: number
  startAngle?: number
  endAngle?: number
}

const MAX_ANGLE = 180
const MIN_ANGLE = -180

enum DistanceUnitsTypes {
  feet = 'feet',
  meters = 'meters',
}

// TODO: extract all the text to the top
@inject('state')
@observer
export default class PropertiesPanel extends React.Component<IProps> {
  @observable private isLoading: boolean = false
  @observable private selectedCraneDistanceUnitType: Units =
    DistanceUnitsTypes.feet
  @observable private typingTimer: number
  @observable private radius: number = 0
  @observable private startAngle: string = '0'
  @observable private endAngle: string = '0'
  @observable private orientation: string = this.currentMapOrientation

  private readonly lineArrowPositionOptions: IPropertySelectOption[] = [
    { label: 'None', value: SitemapLineArrowPosition.None },
    { label: 'Start, End', value: SitemapLineArrowPosition.StartEnd },
    {
      label: 'Start, End  (Reversed)',
      value: SitemapLineArrowPosition.StartEndReversed,
    },
    {
      label: 'Start, Middle, End',
      value: SitemapLineArrowPosition.StartMiddleEnd,
    },
    {
      label: 'Start, Middle, End (Reversed)',
      value: SitemapLineArrowPosition.StartMiddleEndReversed,
    },
  ]

  public componentDidMount(): void {
    this.setCraneValues()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.selectedItemId !== prevProps.selectedItemId) {
      this.setCraneValues()
    }
    if (this.props.endAngle !== prevProps.endAngle) {
      this.onBlurDivisionEndAngle()
    }
    if (this.props.startAngle !== prevProps.startAngle) {
      this.onBlurDivisionStartAngle()
    }
    if (this.props.bearing !== prevProps.bearing) {
      this.orientation = this.currentMapOrientation
    }
  }

  public render() {
    const { sitemap } = this.props.store.sitemapItemsSetupStore
    if (!sitemap) {
      return null
    }

    return (
      <div className="properties-panel full-height no-grow relative">
        {this.renderLoader()}
        <div
          className={classList({
            'full-height scrollable': true,
            'unclickable-element': this.shouldShowLoading,
          })}
        >
          {this.renderControlButton()}
          {this.renderProperties()}
        </div>
      </div>
    )
  }

  private renderLoader(): JSX.Element {
    return (
      this.shouldShowLoading && (
        <div className="full-height full-width absolute modal-overlay unclickable-element">
          <Loader
            className="col x-center y-center full-height full-width"
            size={LOADER_SIZE}
          />
        </div>
      )
    )
  }

  private renderStepPlanProperties() {
    const {
      viewport,
      state: { projectAddress },
    } = this.props
    const lngLat = `${viewport.longitude}, ${viewport.latitude}`
    return (
      <div className="col pa10">
        <div className="text large center pb12 light bold bb-light-cool-grey">
          {baseMapProperies}
        </div>
        <div className="col mt20">
          <StruxhubInput
            label={addressOfTheProject}
            value={projectAddress.address}
            isDisabled={true}
            isRequired={true}
            onChange={null}
          />
          <StruxhubInput
            label={latLngOfTheProject}
            value={lngLat}
            isDisabled={true}
            isRequired={true}
            onChange={null}
          />
          <StruxhubInput
            label={zoomOfTheCurrentView}
            type="number"
            isRequired={true}
            max={maxZoomValue}
            min={minZoomValue}
            step={zoomStep}
            value={viewport.zoom.toFixed(2)}
            onChange={this.setZoom}
            negativeOrDecimal={true}
          />
          <StruxhubInput
            label={orientationOfTheCurrentView}
            type="number"
            isRequired={true}
            max={maxDegrees}
            min={-maxDegrees}
            step={bearingStep}
            value={this.orientation}
            onChange={this.setOrientation}
            negativeOrDecimal={true}
            onBlur={this.onBlurOrientationField}
          />
          {this.props.step === SetUpSteps.alignPlan && (
            <>
              <div
                className="action-step-button pointer text large center"
                onClick={this.props.saveAligment}
              >
                {saveAligment}
              </div>
              <div
                className="action-step-button pointer text large center reversed"
                onClick={this.props.exitRubber}
              >
                {Localization.translator.cancel}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  private renderPlanProperties() {
    const { selectedSitemap } = this.sitemapSetupStore

    return (
      <div className="col">
        <div className="text bold extra-large center pb12 mt10">
          {planProperties}
        </div>
        <div className="col">
          <div className="text medium pb12 mt10 no-grow w-max-content mx10 bold">
            {georeference}
          </div>
          <div className="row bb-light-cool-grey pa10">
            <GeoreferencingLabel
              selectedSitemap={selectedSitemap}
              className="no-grow"
            />
            {this.renderEditGeoreference()}
          </div>
        </div>
        {this.isSelectedSitemapReferenced && this.renderCurrentSettings()}
      </div>
    )
  }

  private renderCurrentSettings() {
    const { viewport } = this.props.store.mapBoxEditorStore
    const lngLat = `${viewport.longitude}, ${viewport.latitude}`
    return (
      <div className="col pa10">
        <div className="text medium bold pt10 pb20">{viewSettings}</div>
        <div className="col">
          <StruxhubInput
            label={longLatOfTheCurrentView}
            isRequired={true}
            isDisabled={true}
            value={lngLat}
            onChange={null}
          />
          <StruxhubInput
            label={zoomOfTheCurrentView}
            type="number"
            isRequired={true}
            max={maxZoomValue}
            min={minZoomValue}
            step={zoomStep}
            value={viewport.zoom.toFixed(2)}
            onChange={this.setZoom}
            negativeOrDecimal={true}
          />
          <StruxhubInput
            label={orientationOfTheCurrentView}
            isRequired={true}
            max={maxDegrees}
            min={-maxDegrees}
            step={bearingStep}
            value={this.orientation}
            onChange={this.setOrientation}
            negativeOrDecimal={true}
            onBlur={this.onBlurOrientationField}
          />
        </div>
      </div>
    )
  }

  @action.bound
  private setOrientation = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { setViewportProp } = this.props.store.mapBoxEditorStore

    this.orientation = event.currentTarget.value

    let bearing = Number(this.orientation)

    if (bearing > maxDegrees) {
      bearing = maxDegrees
      this.orientation = maxDegrees.toString()
    } else if (bearing < -maxDegrees) {
      bearing = -maxDegrees
      this.orientation = (-maxDegrees).toString()
    }

    const newBearing = isNaN(bearing) ? 0 : bearing
    setViewportProp(newBearing, 'bearing')
  }

  @action.bound
  private onBlurOrientationField() {
    this.orientation = this.currentMapOrientation
  }

  private setZoom = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { setViewportProp } = this.props.store.mapBoxEditorStore
    let zoom = Number(event.currentTarget.value)
    if (zoom > maxZoomValue) {
      zoom = maxZoomValue
    } else if (zoom < minZoomValue) {
      zoom = minZoomValue
    }
    setViewportProp(zoom, 'zoom')
  }

  private renderEditGeoreference() {
    if (this.isSelectedSitemapReferenced) {
      return (
        <div className="row x-end">
          <div className="row x-around dialog-footer">
            <div
              onClick={this.onEditGeoreferenceClick}
              className="text light-blue large edit-ref-button bold right mr20 pointer"
            >
              {Localization.translator.edit_verb}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row x-end">
        <div className="row x-around dialog-footer">
          <BaseActionButton
            id="edit-georeference"
            isEnabled={true}
            onClick={this.onEditGeoreferenceClick}
            title={Localization.translator.edit_verb}
          />
        </div>
      </div>
    )
  }

  private onEditGeoreferenceClick = () => {
    this.props.store.mapBoxEditorStore.setLeftVisibility()
    this.props.store.mapBoxEditorStore.isImageRubberMode = false
    if (this.props.store.mapBoxEditorStore?.canvasMap?.getMap?.()) {
      this.props.store.mapBoxEditorStore.setViewportFromAdress()
    }
    this.props.store.setStep(SetUpSteps.alignPlan)
  }

  private renderControlButton() {
    if (!this.selectedSitemapItem) {
      return (
        <div className="text large pb12 mt10">
          {this.props.step
            ? this.renderStepPlanProperties()
            : this.renderPlanProperties()}
        </div>
      )
    }

    return (
      <div className="properties-section pa12 mt10">
        <div className="text title uppercase pb12">{propertiesPanel.edit}</div>
        <button
          className={classList({
            'full-width bg-primary-blue ba-none brada4 pa10': true,
            'inactive-element': !this.selectedSitemapItem.isValid(),
          })}
          onClick={this.saveSelectedSitemapItem}
        >
          <span className="text white large">
            {propertiesPanel.finishEditing}
          </span>
        </button>
      </div>
    )
  }

  private saveSelectedSitemapItem = async () => {
    if (this.selectedSitemapItem.displayName === FORBIDDEN_SITE_NAME) {
      return
    }
    this.setLoading(true)

    await this.sitemapItemsSetupStore.saveSelectedSitemapItem()

    this.setLoading(false)
  }

  private renderHeader() {
    const dataObject = this.selectedSitemapItem.dataObject
    return (
      <div className="row pb12">
        <div className="text title uppercase">{propertiesPanel.object}</div>
        {!dataObject?.is(LocationIntegrationType.MaturixStation) && (
          <Icons.Delete className="no-grow" onClick={this.deleteItem} />
        )}
      </div>
    )
  }

  private renderProperties() {
    if (!this.selectedSitemapItem) {
      return null
    }

    return (
      <>
        <div className="properties-section px12 pt12">
          {this.renderHeader()}
          {this.renderNameInput()}
          {this.renderParentsInput()}
        </div>
        {this.renderIconSection()}
        {this.renderColorsSection()}
        {this.renderTextStyle()}
        {this.renderCraneSection()}
        {this.renderLineStyle()}
        {this.renderObjectAccessibleLevelsProperties()}
        {this.renderDimensions()}
        {this.renderCoordinatesInfo()}
        {this.renderBuildingsProperties()}
      </>
    )
  }

  private renderDimensions() {
    const location = this.selectedSitemapItem?.dataObject
    return location instanceof LocationBaseWithDimensions ? (
      <DimensionProperties
        dataObject={location}
        selectedItemId={this.props.selectedItemId}
      />
    ) : null
  }

  private renderCoordinatesInfo() {
    const { getItemCoordinates } = this.props.store.mapBoxEditorStore
    const { isDisplayed, sitemapItem } = this.selectedSitemapItem
    const { selectedSitemap } = this.sitemapSetupStore
    const isGeoReferenced = selectedSitemap?.center?.lat

    const { isReferenced } = sitemapItem
    const position =
      this.selectedSitemapItem.iconProperties &&
      getItemCoordinates(
        this.selectedSitemapItem.iconProperties.position.x,
        this.selectedSitemapItem.iconProperties.position.y,
      )
    if (!isGeoReferenced || !isDisplayed || !position) {
      return null
    }
    return (
      <>
        <div className="properties-section pa12">
          <div className="row y-center">
            <Checkbox
              isChecked={this.selectedSitemapItem.sitemapItem.isReferenced}
              className="properties-checkbox"
              onClick={this.toggleReferencing}
            />
            <div className="text uppercase title">
              {shouldReferenceItemToRealWorld}
            </div>
          </div>
        </div>
        {isReferenced && (
          <div className="col properties-section px12 pt12">
            <div className="text uppercase title pb12">
              {coordinatesOfThePoint}
            </div>
            <StruxhubInput
              label={latitude}
              type="number"
              isRequired={true}
              onChange={this.updateItemLatitude}
              value={position.latitude?.toString()}
              negativeOrDecimal={true}
            />
            <StruxhubInput
              label={longitude}
              type="number"
              isRequired={true}
              onChange={this.updateItemLongitude}
              value={position.longitude?.toString()}
              negativeOrDecimal={true}
            />
          </div>
        )}
      </>
    )
  }

  private updateItemLatitude = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { getUpdatedItemCoords } = this.props.store.mapBoxEditorStore

    this.selectedSitemapItem.sitemapItem.coordinates.latitude = Number(
      event.currentTarget.value,
    )
    this.sitemapItemsSetupStore.selectedSitemapItem = getUpdatedItemCoords(
      this.selectedSitemapItem,
    )
  }

  private updateItemLongitude = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { getUpdatedItemCoords } = this.props.store.mapBoxEditorStore

    this.selectedSitemapItem.sitemapItem.coordinates.longitude = Number(
      event.currentTarget.value,
    )
    this.sitemapItemsSetupStore.selectedSitemapItem = getUpdatedItemCoords(
      this.selectedSitemapItem,
    )
  }

  private deleteItem = () => {
    this.sitemapItemsSetupStore.showDeleteConfirmationDialog(
      this.selectedSitemapItem,
    )
  }

  private renderNameInput() {
    const { selectedSitemapItem, isItemAssignedToSitemap } =
      this.props.store.sitemapItemsSetupStore
    const shouldShowTag = isItemAssignedToSitemap(selectedSitemapItem)
    const color =
      selectedSitemapItem.labelProperties?.color || Colors.paletteBrandDark

    return (
      <StruxhubInput
        label={propertiesPanel.name}
        isRequired={true}
        isDisabled={this.selectedSitemapItem.isRichTextBox}
        value={this.selectedSitemapItem.displayName}
        isValid={this.selectedSitemapItem.displayName !== FORBIDDEN_SITE_NAME}
        validationMessage={FORBIDDEN_SITE_NAME_MESSAGE}
      >
        {(onFocus, onBlur) => (
          <SitemapAttributeTag
            className="no-flex"
            shouldShowAsTag={shouldShowTag}
            dataObject={this.selectedSitemapItem.dataObject}
            sitemapItem={this.selectedSitemapItem.sitemapItem}
          >
            <input
              className="ba-none no-outline bg-transparent text extra-large line-24"
              type="text"
              value={this.selectedSitemapItem.displayName}
              onChange={this.onItemNameChange}
              onFocus={onFocus}
              onBlur={this.addCurrentStateToHistory.bind(this, onBlur)}
              disabled={this.selectedSitemapItem.isRichTextBox}
              style={{
                width: Math.min(
                  (this.selectedSitemapItem.displayName.length + 1) *
                    LETTER_WIDTH,
                  NAME_INPUT_MAX_WIDTH,
                ),
                color,
              }}
            />
          </SitemapAttributeTag>
        )}
      </StruxhubInput>
    )
  }

  private onItemNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.selectedSitemapItem.setName(event.target.value)
  }

  private renderParentsInput() {
    const { selectedSitemapItemAllowedParents } = this.sitemapItemsSetupStore
    if (!selectedSitemapItemAllowedParents.length) {
      return null
    }

    return (
      <div className="pb12">
        <ParentsSelector
          label={propertiesPanel.objectNestedUnder}
          store={this.sitemapItemsSetupStore}
        />
      </div>
    )
  }

  private renderIconSection() {
    const colorPicker = this.renderIconColorPicker()

    return (
      colorPicker && (
        <div className="properties-section px12 pt12">{colorPicker}</div>
      )
    )
  }

  private renderColorsSection() {
    const dataObject = this.selectedSitemapItem.dataObject
    if (dataObject?.is(LocationIntegrationType.MaturixStation)) {
      return null
    }
    const shapeColorPicker = this.renderShapeColorPicker()
    const bordersSection = this.renderBordersSection()
    if (!shapeColorPicker && !bordersSection) {
      return null
    }
    return (
      <div className="properties-section px12 pt12">
        {shapeColorPicker}
        {bordersSection}
      </div>
    )
  }

  private renderIconColorPicker() {
    const shouldHideColorPicker =
      !this.selectedSitemapItem.iconProperties &&
      !this.selectedSitemapItem.labelProperties
    if (
      shouldHideColorPicker ||
      (this.selectedSitemapItem.iconProperties
        ? this.isPartPropertiesHidden(SitemapItemDrawnPart.Icon)
        : this.isPartPropertiesHidden(SitemapItemDrawnPart.Label))
    ) {
      return null
    }

    const label = this.selectedSitemapItem.isRichTextBox
      ? propertiesPanel.borders
      : this.selectedSitemapItem.iconProperties
      ? propertiesPanel.icon
      : propertiesPanel.text
    return (
      <>
        <div className="text uppercase title pb12">{label}</div>
        <div className="pb12 row y-end">
          <div className="no-grow pr12 pb4">
            <div className="text pb4">{propertiesPanel.icon}</div>
            <div>
              <IconSelector
                item={this.selectedSitemapItem}
                store={this.sitemapItemsSetupStore}
              />
            </div>
          </div>
          <div className="no-grow pr12">
            <div className="text pb4">{propertiesPanel.color}</div>
            <div>
              <SitemapItemsColorPicker
                value={this.selectedSitemapItem.color}
                onChange={this.onColorChange}
              />
            </div>
          </div>
          {!this.selectedSitemapItem.isDataLess && (
            <div className="row pb8">
              <div className="no-grow">
                <Checkbox
                  isChecked={
                    this.selectedSitemapItem.iconProperties.isDisplayed
                  }
                  className="properties-checkbox"
                  onClick={this.toggleIconVisibility}
                />
              </div>
              <div className="text large pl8">{propertiesPanel.showIcon}</div>
            </div>
          )}
        </div>
      </>
    )
  }

  private toggleReferencing = () => {
    const { getUpdatedItemCoords } = this.props.store.mapBoxEditorStore

    this.selectedSitemapItem.sitemapItem.isReferenced =
      !this.selectedSitemapItem.sitemapItem.isReferenced

    this.sitemapItemsSetupStore.selectedSitemapItem = getUpdatedItemCoords(
      this.selectedSitemapItem,
    )
  }

  private toggleIconVisibility = () => {
    const { iconProperties } = this.selectedSitemapItem
    iconProperties.toggleIsDisplayed()
    if (
      !iconProperties.isDisplayed &&
      this.sitemapItemsSetupStore.selectedSitemapItemDrawnPart ===
        SitemapItemDrawnPart.Icon
    ) {
      this.sitemapItemsSetupStore.deselectSitemapItemDrawnPart()
    }
    this.addCurrentStateToHistory()
  }

  private toggleShapeVisibility = () => {
    const { shapeProperties } = this.selectedSitemapItem
    shapeProperties.toggleIsDisplayed()
    if (
      !shapeProperties.isDisplayed &&
      this.sitemapItemsSetupStore.selectedSitemapItemDrawnPart ===
        SitemapItemDrawnPart.Shape
    ) {
      this.sitemapItemsSetupStore.deselectSitemapItemDrawnPart()
    }
    this.addCurrentStateToHistory()
  }

  private onColorChange = (color: string) => {
    this.selectedSitemapItem.setAllColors(color)
    this.addCurrentStateToHistory()
  }

  private renderShapeColorPicker() {
    const { shapeProperties, isDataLess } = this.selectedSitemapItem

    if (
      !shapeProperties ||
      this.isPartPropertiesHidden(SitemapItemDrawnPart.Shape)
    ) {
      return this.renderShapeSelectOnly()
    }

    if (shapeProperties.type === SitemapItemShapeType.Polyline) {
      const poly = shapeProperties as SitemapPolyLineProperties
      if (!poly.isClosed) {
        return this.renderShapeSelectOnly()
      }
    }

    const { fillColor, fillOpacity } = shapeProperties
    return (
      <>
        <div className="row">
          <div className="text title uppercase pb12">
            {propertiesPanel.shape}
          </div>
          {!isDataLess && (
            <div className="row">
              <div className="no-grow">
                <Checkbox
                  isChecked={shapeProperties.isDisplayed}
                  className="properties-checkbox"
                  onClick={this.toggleShapeVisibility}
                />
              </div>
              <div className="text large pl8">{propertiesPanel.showShape}</div>
            </div>
          )}
        </div>
        <div className="pb12">
          {this.renderShapeSelect()}
          <div className="inline-block vertical-align-middle">
            <div className="inline-block vertical-align-middle pr12">
              <div className="text pb4">{propertiesPanel.shape}</div>
              <div>
                <SitemapItemsColorPicker
                  value={fillColor}
                  opacity={fillOpacity}
                  onChange={this.onFillColorChange}
                />
              </div>
            </div>
            <div className="inline-block vertical-align-middle">
              <StruxhubInput
                label={propertiesPanel.opacity}
                customRightIcon="%"
                type="number"
                isRequiredTextHidden={true}
                step={OPACITY_STEP}
                min={MIN_OPACITY_VALUE}
                max={MAX_OPACITY_VALUE}
                value={Math.round(fillOpacity * PERCENT_MODIFIER).toString()}
                onChange={this.onFillOpacityChange}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  private renderShapeSelect() {
    if (
      this.selectedSitemapItem.isDataLess ||
      this.isPartPropertiesHidden(SitemapItemDrawnPart.Shape)
    ) {
      return null
    }

    const { copy, paste, copiedShape, getAllowedShapesForType } =
      this.sitemapItemsSetupStore

    const isPasteAvailable =
      copiedShape &&
      getAllowedShapesForType(
        this.selectedSitemapItem.dataObject.type,
      ).includes(copiedShape?.type)

    return (
      <div className="row">
        <div className="inline-block vertical-align-top pr12">
          <div className="text pb4">{propertiesPanel.shape}</div>
          <ShapeSelector
            item={this.selectedSitemapItem}
            store={this.sitemapItemsSetupStore}
            setInitValues={this.setCraneValues}
          />
        </div>
        <div className="row">
          <Icon
            icon={IconNames.DUPLICATE}
            onClick={copy}
            className={classList({
              pointer: true,
              'inactive-element': !this.selectedSitemapItem.shapeProperties,
            })}
          />
          <div
            className={classList({
              'inactive-element': !this.selectedSitemapItem.shapeProperties,
              'text large pl8': true,
            })}
          >
            {Localization.translator.copy}
          </div>
          <Icon
            className={classList({
              pointer: true,
              'inactive-element': !isPasteAvailable,
            })}
            icon={IconNames.INSERT}
            onClick={paste}
          />
          <div
            className={classList({
              'inactive-element': !isPasteAvailable,
              'text large pl8': true,
            })}
          >
            {Localization.translator.paste}
          </div>
        </div>
      </div>
    )
  }

  private renderShapeSelectOnly() {
    const shapeSelect = this.renderShapeSelect()
    return (
      shapeSelect && (
        <>
          <div className="text title uppercase pb12">
            {propertiesPanel.shape}
          </div>
          <div className="pb12">{shapeSelect}</div>
        </>
      )
    )
  }

  private onFillColorChange = (color: string) => {
    const { shapeProperties } = this.selectedSitemapItem
    shapeProperties.setFillColor(color)
    this.addCurrentStateToHistory()
  }

  private onFillOpacityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { shapeProperties } = this.selectedSitemapItem
    const percentOpacity = Number(event.target.value)
    const opacity =
      Math.max(Math.min(percentOpacity, 100), 0) / PERCENT_MODIFIER
    shapeProperties.setFillOpacity(opacity)
    this.addCurrentStateToHistory()
  }

  private renderBordersSection() {
    const { shapeProperties } = this.selectedSitemapItem

    if (
      !shapeProperties ||
      this.isPartPropertiesHidden(SitemapItemDrawnPart.Shape)
    ) {
      return null
    }

    const { lineColor, lineWidth } = shapeProperties

    return (
      <>
        <div className="text title uppercase">{propertiesPanel.borders}</div>
        <div className="inline-block vertical-align-middle pb12">
          <div className="inline-block vertical-align-middle pr12">
            <div className="text pb4">{propertiesPanel.line}</div>
            <SitemapItemsColorPicker
              className="no-grow"
              value={lineColor}
              onChange={this.onLineColorChange}
            />
          </div>
          <div className="inline-block vertical-align-middle">
            <StruxhubInput
              label={propertiesPanel.width}
              customRightIcon="px"
              isRequiredTextHidden={true}
              type="number"
              step={LINE_WIDTH_STEP}
              min={MIN_LINE_WIDTH_VALUE}
              max={MAX_LINE_WIDTH_VALUE}
              value={lineWidth.toString()}
              onChange={this.onLineWidthChange}
            />
          </div>
        </div>
      </>
    )
  }

  private onLineColorChange = (color: string) => {
    const { shapeProperties } = this.selectedSitemapItem
    shapeProperties.setLineColor(color)

    if (this.selectedSitemapItem.isDataLess) {
      this.selectedSitemapItem.setColor(color)
    }

    this.addCurrentStateToHistory()
  }

  private onLineWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { shapeProperties } = this.selectedSitemapItem
    const width = Number(event.target.value)
    shapeProperties.setLineWidth(width)
    this.addCurrentStateToHistory()
  }

  private setDistanceUnitType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.selectedCraneDistanceUnitType = DistanceUnitsTypes[event.target.value]
    this.setCraneValues()
  }

  private renderCraneSection() {
    const { shapeProperties } = this.selectedSitemapItem
    if (
      !shapeProperties ||
      shapeProperties.type !== SitemapItemShapeType.Circle ||
      this.isPartPropertiesHidden(SitemapItemDrawnPart.Shape)
    ) {
      return null
    }
    const circle = shapeProperties as SitemapCircleProperties

    return (
      <div className="properties-section pa12">
        <div className="text title uppercase pb12">{propertiesPanel.crane}</div>
        {this.isSelectedSitemapReferenced && (
          <StruxhubSelect
            label={'Dimension units'}
            value={this.selectedCraneDistanceUnitType.toString()}
            onChange={this.setDistanceUnitType}
          >
            {Object.keys(DistanceUnitsTypes).map(option => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            })}
          </StruxhubSelect>
        )}
        <StruxhubInput
          label={propertiesPanel.radius}
          customRightIcon={
            this.isSelectedSitemapReferenced &&
            this.selectedCraneDistanceUnitType.toString()
          }
          isRequiredTextHidden={true}
          type="number"
          negativeOrDecimal={true}
          step={DESIMAL_LINE_WIDTH_STEP}
          value={this.radius.toString()}
          onChange={this.onRadiusChange}
          onKeyDown={this.keyDownAction}
          onKeyUp={this.keyUpAction.bind(null, this.setNewRadius)}
        />
        <div className="row">
          <div className="no-grow">
            <Checkbox
              isChecked={circle.isDivided}
              className="properties-checkbox"
              onClick={this.toggleCircleIsDivided}
            />
          </div>
          <div className="text large pl8">
            {propertiesPanel.limitSwingRadius}
          </div>
        </div>
        {circle.isDivided && (
          <>
            <StruxhubInput
              label={propertiesPanel.divisionStartAngle}
              customRightIcon="°"
              isRequiredTextHidden={true}
              type="number"
              step={LINE_WIDTH_STEP}
              min={MIN_ANGLE}
              max={MAX_ANGLE}
              negativeOrDecimal={true}
              value={this.startAngle}
              onChange={this.onStartAngleChange}
              onKeyDown={this.keyDownAction}
              onKeyUp={this.keyUpAction.bind(null, this.setDivisionStartAngle)}
              onBlur={this.onBlurDivisionStartAngle}
            />
            <StruxhubInput
              label={propertiesPanel.divisionEndAngle}
              customRightIcon="°"
              isRequiredTextHidden={true}
              type="number"
              step={LINE_WIDTH_STEP}
              min={MIN_ANGLE}
              max={MAX_ANGLE}
              negativeOrDecimal={true}
              value={this.endAngle}
              onChange={this.onEndAngleChange}
              onKeyDown={this.keyDownAction}
              onKeyUp={this.keyUpAction.bind(null, this.setDivisionEndAngle)}
              onBlur={this.onBlurDivisionEndAngle}
            />
          </>
        )}
      </div>
    )
  }

  private setDivisionEndAngle = (): void => {
    const { shapeProperties } = this.selectedSitemapItem
    const circle = shapeProperties as SitemapCircleProperties
    const angle = Number(this.endAngle)

    if (!isNaN(angle)) {
      circle.setDivisionEndAngle(angle)
    }
  }

  private setDivisionStartAngle = (): void => {
    const { shapeProperties } = this.selectedSitemapItem
    const circle = shapeProperties as SitemapCircleProperties
    const angle = Number(this.startAngle)

    if (!isNaN(angle)) {
      circle.setDivisionStartAngle(angle)
    }
  }

  private onBlurDivisionStartAngle = (): void => {
    if (!this.selectedSitemapItem?.shapeProperties) {
      return
    }
    const circle = this.selectedSitemapItem
      .shapeProperties as SitemapCircleProperties

    this.startAngle = Math.round(circle.divisionStartAngle).toString()
  }

  private onBlurDivisionEndAngle = (): void => {
    if (!this.selectedSitemapItem?.shapeProperties) {
      return
    }
    const circle = this.selectedSitemapItem
      .shapeProperties as SitemapCircleProperties

    this.endAngle = Math.round(circle.divisionEndAngle).toString()
  }

  private onEndAngleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.endAngle = this.verifyAngle(event.target.value)
  }

  private onStartAngleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.startAngle = this.verifyAngle(event.target.value)
  }

  private verifyAngle = (angleStr: string): string => {
    const angle = Number(angleStr)

    if (angle > MAX_ANGLE) {
      return MAX_ANGLE.toString()
    } else if (angle < MIN_ANGLE) {
      return MIN_ANGLE.toString()
    }
    return angleStr
  }

  private onRadiusChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (this.isSelectedSitemapReferenced) {
      this.radius = Math.max(Number(event.target.value), FEET_TO_TURF_DISTANCE)
    } else {
      this.radius = Number(event.target.value)
    }
  }

  private toggleCircleIsDivided = () => {
    const { shapeProperties } = this.selectedSitemapItem
    const circle = shapeProperties as SitemapCircleProperties
    circle.toggleIsDivided()
    this.endAngle = Math.round(circle.divisionEndAngle).toString()
    this.startAngle = Math.round(circle.divisionStartAngle).toString()
    this.addCurrentStateToHistory()
  }

  private renderTextStyle() {
    const { labelProperties, isDataLess, isRichTextBox } =
      this.selectedSitemapItem

    if (
      !labelProperties ||
      this.isPartPropertiesHidden(SitemapItemDrawnPart.Label)
    ) {
      return null
    }

    return (
      <div className="properties-section pa12">
        <div className="text title uppercase">{propertiesPanel.textStyle}</div>
        <div className="pb12 row y-center">
          <div className="no-grow pr12">
            <StruxhubInput
              label={propertiesPanel.fontSize}
              customRightIcon="px"
              isRequiredTextHidden={true}
              type="number"
              step={FONT_STEP}
              min={MIN_FONT_VALUE}
              max={MAX_FONT_VALUE}
              value={labelProperties.fontSize.toString()}
              onChange={this.onFontSizeChange}
            />
          </div>

          <div className="row pb8">
            <div className="no-grow">
              <Checkbox
                isChecked={labelProperties.isTextBoxDisplayed}
                className="properties-checkbox"
                onClick={this.toggleTextBoxVisibility}
                isDisabled={!labelProperties.isDisplayed}
              />
            </div>
            <div className="text large pl8">{propertiesPanel.showTextBox}</div>
          </div>
          {!isDataLess && (
            <div className="row pb8">
              <div className="no-grow">
                <Checkbox
                  isChecked={labelProperties.isDisplayed}
                  className="properties-checkbox"
                  onClick={this.toggleLabelVisibility}
                />
              </div>
              <div className="text large pl8">{propertiesPanel.showLabel}</div>
            </div>
          )}
        </div>
        {isRichTextBox && (
          <div className="pb12 row y-end">
            <div className="no-grow pr12">
              <div className="text pb4">{propertiesPanel.fontColor}</div>
              <div>
                <SitemapItemsColorPicker
                  value={labelProperties.color}
                  onChange={this.onTextColorChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
  private onTextColorChange = (color: string) => {
    this.selectedSitemapItem.labelProperties.color = color
    this.addCurrentStateToHistory()
  }

  private onFontSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { labelProperties } = this.selectedSitemapItem
    labelProperties.setFontSize(Number(event.target.value))
    this.addCurrentStateToHistory()
  }

  private toggleTextBoxVisibility = () => {
    const { labelProperties } = this.selectedSitemapItem
    labelProperties.toggleTextBoxVisibility()
    this.addCurrentStateToHistory()
  }

  private toggleLabelVisibility = () => {
    const { labelProperties } = this.selectedSitemapItem
    labelProperties.toggleIsDisplayed()
    if (
      !labelProperties.isDisplayed &&
      this.sitemapItemsSetupStore.selectedSitemapItemDrawnPart ===
        SitemapItemDrawnPart.Label
    ) {
      this.sitemapItemsSetupStore.deselectSitemapItemDrawnPart()
    }
    this.addCurrentStateToHistory()
  }

  private renderLineStyle() {
    const { shapeProperties } = this.selectedSitemapItem

    if (
      !shapeProperties ||
      shapeProperties.type !== SitemapItemShapeType.Polyline ||
      this.isPartPropertiesHidden(SitemapItemDrawnPart.Shape)
    ) {
      return null
    }

    const poly = shapeProperties as SitemapPolyLineProperties
    if (poly.isClosed) {
      return null
    }

    return (
      <div className="properties-section pa12">
        <div className="text title uppercase pb12">
          {propertiesPanel.lineStyle}
        </div>
        <div>
          <div className="text pb4">{propertiesPanel.arrows}</div>
          <div>
            <PropertySelect
              options={this.lineArrowPositionOptions}
              value={poly.arrowPosition}
              onChange={this.onLineArrowPositionChange}
            />
          </div>
        </div>
      </div>
    )
  }

  private onLineArrowPositionChange = (value: SitemapLineArrowPosition) => {
    const { shapeProperties } = this.selectedSitemapItem
    const poly = shapeProperties as SitemapPolyLineProperties
    poly.setArrowPosition(value)
    this.addCurrentStateToHistory()
  }

  private isPartPropertiesHidden(part: SitemapItemDrawnPart) {
    const selectedPart =
      this.sitemapItemsSetupStore.selectedSitemapItemDrawnPart
    return selectedPart && selectedPart !== part
  }

  private addCurrentStateToHistory = (handleBlur?: () => void) => {
    handleBlur?.()
    this.sitemapItemsSetupStore.addCurrentStateToHistory()
  }

  private renderBuildingsProperties() {
    const { dataObject } = this.selectedSitemapItem
    if (!dataObject || dataObject.type !== LocationType.Building) {
      return null
    }

    const building = dataObject as Building

    return (
      <BuildingProperties
        building={building}
        store={this.sitemapItemsSetupStore}
        setLoading={this.setLoading}
      />
    )
  }

  private renderObjectAccessibleLevelsProperties() {
    const { dataObject } = this.selectedSitemapItem

    let sitemapObject: VerticalObject | OffloadingEquipment = null
    switch (dataObject?.type) {
      case LocationType.VerticalObject:
        sitemapObject = dataObject as VerticalObject
        break
      case LocationType.OffloadingEquipment:
        sitemapObject = dataObject as OffloadingEquipment
        break
    }

    if (!sitemapObject) {
      return null
    }

    return (
      <ObjectAccessibleLevelsProperties
        className="relative"
        dataObject={sitemapObject}
      />
    )
  }

  @action.bound
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  @computed
  public get shouldShowLoading(): boolean {
    return (
      this.selectedSitemapItem &&
      (this.isLoading || this.sitemapItemsSetupStore.isSitemapUpdating)
    )
  }

  private get sitemapSetupStore(): SitemapSetupStore {
    return this.props.store.sitemapSetupStore
  }

  private get sitemapItemsSetupStore(): SitemapItemsSetupStore {
    return this.props.store.sitemapItemsSetupStore
  }

  private get selectedSitemapItem(): SitemapItemBase {
    return this.sitemapItemsSetupStore.selectedSitemapItem
  }

  private setNewRadius = (): void => {
    const { shapeProperties } = this.selectedSitemapItem
    const circle = shapeProperties as SitemapCircleProperties

    let newRadius = this.radius
    if (this.isSelectedSitemapReferenced) {
      // simple proportion to calculate new radius as the most efficient and easy to upderstand approach here
      newRadius = (circle.radius * this.radius) / this.initRadius
    }

    circle.setRadius(newRadius)
    this.addCurrentStateToHistory()
  }

  private keyUpAction = (action: () => void): void => {
    clearTimeout(this.typingTimer)

    this.typingTimer = window.setTimeout(() => action(), doneTypingInterval)
  }

  private keyDownAction = (): void => {
    clearTimeout(this.typingTimer)
  }

  private setCraneValues = (): void => {
    if (
      !this.selectedSitemapItem ||
      !this.selectedSitemapItem.shapeProperties ||
      this.selectedSitemapItem.shapeProperties.type !==
        SitemapItemShapeType.Circle ||
      this.isPartPropertiesHidden(SitemapItemDrawnPart.Shape)
    ) {
      return null
    }
    const circle = this.selectedSitemapItem
      .shapeProperties as SitemapCircleProperties

    if (this.isSelectedSitemapReferenced) {
      this.radius = this.initRadius
    } else {
      this.radius = circle.radius
    }
    this.endAngle = Math.round(circle.divisionEndAngle).toString()
    this.startAngle = Math.round(circle.divisionStartAngle).toString()
  }

  private get initRadius(): number {
    const { getItemCoordinates } = this.props.store.mapBoxEditorStore
    const { shapeProperties } = this.selectedSitemapItem
    const circle = shapeProperties as SitemapCircleProperties

    const point1 = getItemCoordinates(circle.position.x, circle.position.y)
    const point2 = getItemCoordinates(
      circle.position.x,
      circle.position.y + circle.radius,
    )
    return getDistance(
      point1.longitude,
      point2.longitude,
      point1.latitude,
      point2.latitude,
      this.selectedCraneDistanceUnitType,
    )
  }

  private get currentMapOrientation(): string {
    return this.props.store.mapBoxEditorStore.viewport.bearing.toFixed(1)
  }

  private get isSelectedSitemapReferenced(): boolean {
    return this.sitemapSetupStore.selectedSitemap?.isReferenced
  }
}

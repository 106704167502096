import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import { SAVE_DELIVERY_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'

import FileInputBase from '../../../FileInput/FileInput'
import MapBoxEditorStore from '../../../MapBoxEditor/MapBoxEditor.store'
import SitemapsGallery from '../../../SitemapsGallery/SitemapsGallery'
import DeliveriesViewStore from '../../DeliveriesView.store'
import DeliveriesMapViewStore from './DeliveriesMapView.store'
import DeliveriesMapSitemap from './components/DeliveriesMapSitemap'

export interface IProps {
  currentDate: Date
  deliveriesViewStore: DeliveriesViewStore
  FileInputType: typeof FileInputBase

  state?: InitialState
  sitemapsStore?: SitemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
}

@inject(
  'locationAttributesStore',
  'sitemapItemsStore',
  'sitemapsStore',
  'state',
)
@observer
export default class DeliveriesMapView extends React.Component<IProps> {
  private textboxesCache: ICanvasImageCache = {}
  private readonly mapBoxEditorStore: MapBoxEditorStore = null

  public constructor(props: IProps) {
    super(props)

    this.mapBoxEditorStore = new MapBoxEditorStore(
      () => props.deliveriesViewStore.mapStore.displayedSitemap,
      props.sitemapItemsStore,
      props.sitemapsStore,
      props.locationAttributesStore,
      props.state,
      false,
      null,
      true,
    )
  }

  public componentDidMount() {
    this.store.resetDisplayedDelivery()
  }

  public componentDidUpdate(prevProps: IProps) {
    const { currentDate } = this.props

    if (prevProps.currentDate !== currentDate) {
      this.store.resetDisplayedDelivery()
    }
  }

  public componentWillUnmount() {
    this.textboxesCache = {}
  }

  public render() {
    const {
      FileInputType,
      deliveriesViewStore: { deliveriesSitemapsIds },
      currentDate,
    } = this.props

    return (
      <div className="scrollable col overflow-hidden">
        <div className="full-height relative">
          <SitemapsGallery
            sitemapsIds={deliveriesSitemapsIds}
            shouldDisableZoom={true}
            SelectedSitemapComponent={this.SitemapComponent}
            eventName={SAVE_DELIVERY_CONFIGURATIONS}
            areArrowsLeftSided={true}
            shouldDisableSwap={true}
            shouldUseFullHeight={true}
            selectSitemap={this.store.onSlide}
            FileInputType={FileInputType}
            currentDate={currentDate}
            isCompactMode={true}
            mapboxEditorStore={this.mapBoxEditorStore}
          />
        </div>
      </div>
    )
  }

  private get SitemapComponent() {
    return props => (
      <div className="full-width full-height">
        <DeliveriesMapSitemap
          {...props}
          mapStore={this.store}
          textboxesCache={this.textboxesCache}
          isCompactMode={true}
          mapBoxEditorStore={this.mapBoxEditorStore}
        />
      </div>
    )
  }

  private get store(): DeliveriesMapViewStore {
    return this.props.deliveriesViewStore.mapStore
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import Sitemap from '../../models/Sitemap'

import './GeoreferencingLabel.scss'

export interface IProps {
  selectedSitemap: Sitemap
  className?: string
}

const georeferenced = 'Georeferenced'
const nonGeoreferenced = 'Non-georeferenced'

@observer
export default class GeoreferencingLabel extends React.Component<IProps> {
  public render() {
    const { selectedSitemap, className } = this.props

    return (
      <div
        className={classList({
          'row x-between georeferencing-label': true,
          className: !!className,
        })}
      >
        <div
          className={classList({
            'georeferenced-label no-grow row brada5 w-max-content': true,
            referenced: selectedSitemap.isReferenced,
            'not-referenced': !selectedSitemap.isReferenced,
          })}
        >
          {selectedSitemap.isReferenced ? (
            <Icons.Referenced className="no-grow geo-icon" />
          ) : (
            <Icons.NotReferenced className="no-grow geo-icon" />
          )}
          <span>
            {selectedSitemap.isReferenced ? georeferenced : nonGeoreferenced}
          </span>
        </div>
      </div>
    )
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'

import { SitemapItemShapeType } from '~/client/graph'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import SitemapRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapRectangleProperties'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import KonvaCircle from '../konvaElements/KonvaCircle'
import KonvaPolyLine from '../konvaElements/KonvaPolyLine'
import KonvaRectangle from '../konvaElements/KonvaRectangle'

interface IProps {
  item: SitemapItemBase
  containerWidth: number
  containerHeight: number
}
const { getHEXColor } = ThemeMode
const MAX_PERCENT = 100

@observer
export default class SitemapShape extends React.Component<IProps & any> {
  public render() {
    const { shapeProperties, isDisplayed } = this.props.item
    if (!shapeProperties || !isDisplayed || !shapeProperties.isDisplayed) {
      return null
    }
    switch (shapeProperties.type) {
      case SitemapItemShapeType.Circle:
        return this.renderCircle()
      case SitemapItemShapeType.Polyline:
        return this.renderPolyLine()
      case SitemapItemShapeType.Rectangle:
        return this.renderRectangle()
    }
    return null
  }

  private renderCircle() {
    const { containerHeight, containerWidth, item, ...rest } = this.props
    if (!containerHeight || !containerWidth) {
      return null
    }

    const circle = item.shapeProperties as SitemapCircleProperties
    const centerX = (containerWidth * circle.position.x) / MAX_PERCENT
    const centerY = (containerHeight * circle.position.y) / MAX_PERCENT
    const radius = (containerHeight * circle.radius) / MAX_PERCENT

    let angle = 360
    let rotation = 0
    if (circle.isDividedAndDivisionAnglesValid) {
      rotation = circle.divisionStartAngle
      angle = circle.divisionEndAngle - circle.divisionStartAngle
    }

    return (
      <KonvaCircle
        color={getHEXColor(circle.lineColor)}
        radius={radius}
        lineWidth={circle.lineWidth}
        fillColor={getHEXColor(circle.fillColor)}
        fillOpacity={circle.fillOpacity}
        angle={angle}
        rotation={rotation}
        x={centerX}
        y={centerY}
        {...rest}
      />
    )
  }

  private renderPolyLine() {
    const { containerHeight, containerWidth, item, ...rest } = this.props

    const polyline = item.shapeProperties as SitemapPolyLineProperties
    const konvaPoints = [].concat(
      ...polyline.points.map(point => [
        (point.x * containerWidth) / MAX_PERCENT,
        (point.y * containerHeight) / MAX_PERCENT,
      ]),
    )

    return (
      <KonvaPolyLine
        points={konvaPoints}
        color={getHEXColor(polyline.lineColor)}
        lineWidth={polyline.lineWidth}
        fillColor={getHEXColor(polyline.fillColor)}
        fillOpacity={polyline.fillOpacity}
        isClosed={polyline.isClosed}
        arrowPosition={polyline.arrowPosition}
        {...rest}
      />
    )
  }

  private renderRectangle() {
    const { containerHeight, containerWidth, item, ...rest } = this.props

    const rectangle = item.shapeProperties as SitemapRectangleProperties

    const x = (containerWidth * rectangle.position.x) / MAX_PERCENT
    const y = (containerHeight * rectangle.position.y) / MAX_PERCENT
    const width = (containerWidth * rectangle.width) / MAX_PERCENT
    const height = (containerHeight * rectangle.height) / MAX_PERCENT

    return (
      <KonvaRectangle
        x={x}
        y={y}
        width={width}
        height={height}
        color={getHEXColor(rectangle.lineColor)}
        lineWidth={rectangle.lineWidth}
        fillColor={getHEXColor(rectangle.fillColor)}
        fillOpacity={rectangle.fillOpacity}
        rotation={rectangle.rotation}
        {...rest}
      />
    )
  }
}

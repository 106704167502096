import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { AutoSizer, MultiGrid } from 'react-virtualized'
import tinycolor from 'tinycolor2'

import { LocationType, NotificationType } from '~/client/graph'
import AnnouncementEditionForm from '~/client/src/desktop/components/AnnouncementEditionForm/AnnouncementEditionForm'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import AnnouncementViewDialog from '~/client/src/desktop/views/Logistics/components/AnnouncementViewDialog/AnnouncementViewDialog'
import ConfirmAnnouncementDialog from '~/client/src/desktop/views/Logistics/components/ConfirmAnnouncementDialog'
import NotificationDetailsPermit from '~/client/src/desktop/views/NotificationDetails/components/NotificationDetailsPermit'
import BaseListWithFixedColumns, {
  IBaseListWithFixedColumns,
} from '~/client/src/shared/components/ListWithFixedColumns/BaseListWithFixedColumns'
import { ILWFCRow } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import TableSeparators from '~/client/src/shared/components/TableSeparators'
import ConfirmDialogTypes from '~/client/src/shared/enums/ConfirmDialogTypes'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Announcement from '~/client/src/shared/models/Announcement'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { DELETE_ANNOUNCEMENTS } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import DeliverySitemapSetUpStore from '../../../../../AppsSitemap/GeneralSitemapSetUp.store'
import DeleteDeliveryAttributeConfirmDialog from '../../../../../ProjectWorkflowsSetUp/components/DeliveryRequestSetUp/components/DeliveryDetailsConfigurations/components/DeleteDeliveryAttributeConfirmDialog'
import ObjectsListStore, { ColumnKey, SITE_ROW_ID } from './ObjectsList.store'
import ObjectCell from './components/ObjectCell/ObjectCell'
import EquipmentCell from './components/ObjectCell/components/EquipmentCell'
import VerticalObjectCell from './components/ObjectCell/components/VerticalObjectCell'

import Colors from '~/client/src/shared/theme.module.scss'

import './ObjectsList.scss'

const cellComponentByType = {
  [LocationType.VerticalObject]: VerticalObjectCell,
  [LocationType.OffloadingEquipment]: EquipmentCell,
}

const accessibleLevelsTypes = [
  LocationType.OffloadingEquipment,
  LocationType.VerticalObject,
]

// translated

interface IProps extends IBaseListWithFixedColumns {
  data: { [tagKey: string]: LocationBase[] }
  rows: ILWFCRow[]
  store: ObjectsListStore
  datePickerStore: TwoMonthsDatePickerStore

  tagsStore?: TagsStore
  eventsStore?: DesktopEventStore
  locationAttributesStore?: LocationAttributesStore
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  fileUploadingStore?: FileUploadingStore
  closuresStore?: ClosuresStore
  syncRestrictionsStore?: SyncRestrictionsStore
  activityFiltersStore?: ActivityFiltersStore
  userProjectsStore?: UserProjectsStore
}

const deliverySetting = 'Delivery Setting'
const MIN_ROW_HEIGHT = 110
const PADDING_Y = 15
const CELL_PADDING_HEIGHT = 2 * PADDING_Y
const ACTION_BUTTON_BLOCK_HEIGHT = 52
const TEXT_ROW_HEIGHT = 50
const OBJ_LABEL_HEIGHT = 35
const DEFAULT_COLUMN_WIDTH = 205
const AVERAGE_COLUMN_WIDTH = 260
const NAME_COLUMN_WIDTH = 350
const WIDE_COLUMN_WIDTH = 390
const SMALL_COLUMN_WIDTH = 140
const TINY_COLUMN_WIDTH = 80
const HEADER_ROW_HEIGHT = 63
const MAX_TABLE_WIDTH = 1800
const CATEGORY_ROW_HEIGHT = 30
const PERMIT_SIDEBAR_WIDTH = 400
const ACCESSIBLE_LEVELS_INFO_HEIGHT = 85
const CLOSURES_HISTORY_DEFAULT_HEIGHT = 28
const DAILY_CLOSURES_BTN_HEIGHT = 36
const DEFAULT_TEXT_HEIGHT = 18

const { neutral100, neutral0, neutral60_1 } = Colors

@inject(
  'tagsStore',
  'eventsStore',
  'locationAttributesStore',
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'syncRestrictionsStore',
  'activityFiltersStore',
  'userProjectsStore',
  'fileUploadingStore',
  'closuresStore',
)
@observer
export default class ObjectsList extends BaseListWithFixedColumns<IProps> {
  private static getObjectCellHeight(obj: LocationAttributeBase): number {
    let objectTypeSpecificOffset = 0

    if (accessibleLevelsTypes.includes(obj.type)) {
      objectTypeSpecificOffset = ACCESSIBLE_LEVELS_INFO_HEIGHT
    }

    return CELL_PADDING_HEIGHT + OBJ_LABEL_HEIGHT + objectTypeSpecificOffset
  }

  private static getListContainedCellHeight(
    listLength: number,
    isLargeText?: boolean,
  ): number {
    if (!listLength) {
      return MIN_ROW_HEIGHT
    }

    return (
      CELL_PADDING_HEIGHT +
      ACTION_BUTTON_BLOCK_HEIGHT +
      listLength * (isLargeText ? TEXT_ROW_HEIGHT : DEFAULT_TEXT_HEIGHT)
    )
  }

  private static noRowsRenderer = () => {
    return (
      <div className="row py20 mt50">
        {Localization.translator.thereIsNoObjectOfSelectedType}...
      </div>
    )
  }

  private oldClosuresToggleState = observable(new Map<string, boolean>())
  private readonly deliverySitemapSetUpStore: DeliverySitemapSetUpStore = null

  public constructor(props: IProps) {
    super(props)

    this.deliverySitemapSetUpStore = new DeliverySitemapSetUpStore(
      props.eventsStore,
      props.sitemapsStore,
      props.basemapsStore,
      props.locationAttributesStore,
      props.sitemapItemsStore,
      props.fileUploadingStore,
      props.syncRestrictionsStore,
      props.activityFiltersStore,
      props.userProjectsStore,
      props.tagsStore,
    )
    this.deliverySitemapSetUpStore.sitemapSetupStore.selectInitialSitemap()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.rows.length !== this.props.rows.length ||
      prevProps.data !== this.props.data
    ) {
      this.recomputeGridSize()
    }
    if (this.grid) {
      this.shouldRenderArrow = this.grid.props.width < MAX_TABLE_WIDTH
    }
    const { sitemapSetupStore } = this.deliverySitemapSetUpStore
    if (!sitemapSetupStore.sitemapUrl) {
      sitemapSetupStore.selectInitialSitemap()
    }
  }

  protected renderGrid(): JSX.Element {
    const {
      columnsCount,
      fixedColumnsCount,
      tagsStore: { isDataReceived },
      rows,
      eventsStore: { appState },
    } = this.props

    const {
      onApply,
      onHide,
      selectedAttributeType,
      selectedAttributeName,
      isDeleteConfirmationDialogShown,
      displayedAnnouncement,
      isAnnouncementViewDisplayed,
      isDeleteAnnouncementDialogDisplayed,
      hideAnnouncementDeleteDialog,
      showAnnouncementDeleteConfirmDialog,
    } = this.store

    const { scrollToColumn, scrollToRow } = this
    if (!isDataReceived) {
      return <Loader hint={Localization.translator.loading} />
    }

    const isAnnouncementBeingDeleted =
      appState.loading.get(DELETE_ANNOUNCEMENTS)

    return (
      <>
        <DeleteDeliveryAttributeConfirmDialog
          isShown={isDeleteConfirmationDialogShown}
          onApply={onApply}
          onHide={onHide}
          type={selectedAttributeName}
          name={selectedAttributeType}
        />
        {this.renderPermitSidebar()}
        {isAnnouncementViewDisplayed && (
          <AnnouncementViewDialog
            announcements={[displayedAnnouncement]}
            displayedAnnouncement={displayedAnnouncement}
            onClose={this.backClickedAnnouncement}
            onEdit={this.onEditAnnouncement}
            onDelete={showAnnouncementDeleteConfirmDialog}
          />
        )}
        {isDeleteAnnouncementDialogDisplayed && (
          <ConfirmAnnouncementDialog
            type={ConfirmDialogTypes.DELETE}
            isOpen={isDeleteAnnouncementDialogDisplayed}
            loading={isAnnouncementBeingDeleted}
            closeDialog={hideAnnouncementDeleteDialog}
            performItems={this.removeAnnouncements}
            itemsToPerform={[displayedAnnouncement]}
          />
        )}
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              width={width}
              height={height}
              rowHeight={this.getRowHeight}
              ref={ref => (this.grid = ref)}
              fixedColumnCount={fixedColumnsCount}
              scrollToColumn={scrollToColumn}
              fixedRowCount={1}
              scrollToRow={scrollToRow}
              cellRenderer={this.rowRenderer}
              columnWidth={this.getColumnWidth}
              enableFixedColumnScroll={true}
              onScroll={this.onScroll}
              enableFixedRowScroll={false}
              rowCount={rows.length}
              columnCount={columnsCount}
              hideTopRightGridScrollbar={true}
              hideBottomLeftGridScrollbar={true}
              styleBottomRightGrid={{ outline: 'none' }}
              className="objects-list"
              onScrollbarPresenceChange={this.onScrollbarPresenceChange}
            />
          )}
        </AutoSizer>
        {rows.length === 1 && ObjectsList.noRowsRenderer()}
      </>
    )
  }

  protected renderSelectionPopUp(): JSX.Element {
    const {
      isAnnouncementEditingDialogDisplayed,
      hideAddAnnouncementDialog,
      announcementLocation,
      displayedAnnouncement,
    } = this.store

    if (!isAnnouncementEditingDialogDisplayed) {
      return
    }

    return (
      <div className="announcement-creation-form">
        {displayedAnnouncement ? (
          <AnnouncementEditionForm
            onClose={hideAddAnnouncementDialog}
            displayedAnnouncement={displayedAnnouncement.copy()}
          />
        ) : (
          <AnnouncementEditionForm
            onClose={hideAddAnnouncementDialog}
            initialTitle={Localization.translator.locationClosure}
            initialLocation={announcementLocation}
          />
        )}
      </div>
    )
  }

  protected recomputeGridSize = () => {
    if (this.grid) {
      this.grid.recomputeGridSize()
    }
  }

  private renderPermitSidebar() {
    const { displayedPermitId } = this.store
    if (!displayedPermitId) {
      return
    }

    return (
      <div className="permit-sidebar-holder">
        <div className="full-height" style={{ maxWidth: PERMIT_SIDEBAR_WIDTH }}>
          <NotificationDetailsPermit
            backClicked={this.backClicked}
            notificationType={NotificationType.PermitAccepted}
            permitId={displayedPermitId}
          />
        </div>
      </div>
    )
  }

  private backClickedAnnouncement = () => {
    this.store.setDisplayedAnnouncement(this.store.displayedAnnouncement)
  }

  private onEditAnnouncement = () => {
    this.store.isAnnouncementEditingDialogDisplayed = true
    this.store.isAnnouncementViewDisplayed = false
  }

  @action.bound
  private removeAnnouncements(announcements: Announcement[]) {
    this.store.isAnnouncementViewDisplayed = false
    this.props.eventsStore.dispatch(
      DELETE_ANNOUNCEMENTS,
      announcements.map(({ id }) => id),
      this.store.announcementDeleteDialogCallback,
    )
  }

  private backClicked = () => {
    this.store.setDisplayedPermitId(this.store.displayedPermitId)
  }

  private getColumnWidth = ({ index }): number => {
    const key = this.store.getColumnKeyByIndex(index)
    switch (key) {
      case ColumnKey.name:
        return NAME_COLUMN_WIDTH
      case ColumnKey.operatingIntervals:
        return AVERAGE_COLUMN_WIDTH
      case ColumnKey.closedIntervals:
        return WIDE_COLUMN_WIDTH
      case ColumnKey.associatedPermits:
      case ColumnKey.associatedAnnouncements:
        return SMALL_COLUMN_WIDTH
      case ColumnKey.status:
        return TINY_COLUMN_WIDTH
      default:
        return DEFAULT_COLUMN_WIDTH
    }
  }

  private rowRenderer = ({ key, style, rowIndex, columnIndex }: any) => {
    if (!rowIndex) {
      return this.renderHeaderCell(columnIndex, style)
    }

    const columnKey = this.store.getColumnKeyByIndex(columnIndex)

    const {
      onObjectDelete,
      onObjectDuplicate,
      setDisplayedPermitId,
      showAddAnnouncementDialog,
      setDisplayedAnnouncement,
    } = this.store

    const { rows } = this.props

    // first row is header
    const obj = rows[rowIndex]

    if (obj.category) {
      return this.renderCategory(obj, columnIndex, key, style)
    }

    const CellComponent =
      cellComponentByType[this.store.objectTypeId] || ObjectCell

    return (
      <div
        key={key}
        style={style}
        className="row object-row bb-palette-brand-lighter"
      >
        <CellComponent
          columnKey={columnKey}
          obj={obj.data as LocationAttributeBase}
          level={obj.level}
          onChange={this.recomputeGridSize}
          datePickerStore={this.props.datePickerStore}
          paddingY={PADDING_Y}
          onObjectDelete={onObjectDelete}
          onObjectDuplicate={onObjectDuplicate}
          deliverySitemapSetUpStore={this.deliverySitemapSetUpStore}
          onPermitClick={setDisplayedPermitId}
          onAnnouncementClick={setDisplayedAnnouncement}
          onAddAnnouncement={showAddAnnouncementDialog}
          toggleOldClosures={this.toggleOldClosures}
          shouldShowOldClosures={this.oldClosuresToggleState.get(obj.data.id)}
        />
      </div>
    )
  }

  private renderCategory(
    obj: ILWFCRow,
    columnIndex: number,
    key: number,
    style: React.CSSProperties,
  ) {
    const { collapsedCategories, isSiteCollapsed } = this.store
    const {
      category: { categoryId, categoryLabel },
      data,
    } = obj
    const backgroundColor = data
      ? tinycolor(ThemeMode.getHEXColor(data.color)).setAlpha(0.3).toString()
      : neutral60_1

    const isSiteCategory = categoryId === SITE_ROW_ID
    const color = isSiteCategory ? neutral100 : neutral0
    const isExpanded = isSiteCategory
      ? !isSiteCollapsed
      : !collapsedCategories.get(categoryId)
    const icon = isExpanded ? IconNames.CARET_DOWN : IconNames.CARET_UP

    return (
      <div
        key={key}
        style={{ ...style, backgroundColor, color }}
        className="bb-light-input-border bt-light-input-border category-row row"
      >
        {!columnIndex && (
          <>
            {!isSiteCategory && (
              <TableSeparators
                level={1}
                className="pl10 no-grow full-height br-palette-grey"
              />
            )}
            <div
              className="no-grow dark ml3"
              onClick={this.toggleCategory.bind(this, categoryId)}
            >
              <Icon icon={icon} />
            </div>

            <SitemapAttributeTag
              shouldShowAsTag={false}
              contentContainerClassName="text-ellipsis py2 row name-cell"
              dataObject={data as LocationAttributeBase}
            >
              {categoryLabel}
            </SitemapAttributeTag>
          </>
        )}
      </div>
    )
  }

  private toggleCategory(categoryId: string) {
    if (categoryId === SITE_ROW_ID) {
      this.store.toggleSite()
    } else {
      this.store.toggleCategoryCollapsing(categoryId)
    }
    this.recomputeGridSize()
  }

  private handleColumnSort = (columnKey: string) => {
    this.store.handleColumnSort(columnKey)
    this.recomputeGridSize()
  }

  private renderHeaderCell(
    index: number,
    style: React.CSSProperties,
  ): JSX.Element {
    const { columnKey, order } = this.store.sortState
    const key = this.store.getColumnKeyByIndex(index)
    const isColumnSorted = key === columnKey

    return (
      <div
        onClick={this.handleColumnSort.bind(this, key)}
        key={index}
        style={style}
        className={classList({
          'col y-end text bold uppercase px12 bb-brand-dark lp05 py8 mt20 header-row-cell':
            true,
          'sorted bold': isColumnSorted,
        })}
      >
        {
          <div className="row pointer">
            {this.renderHeaderText(index)}
            {isColumnSorted && (
              <Icon
                className="pl2"
                iconSize={8}
                icon={
                  order === SortOrder.ASC
                    ? IconNames.ARROW_DOWN
                    : IconNames.ARROW_UP
                }
              />
            )}
          </div>
        }
      </div>
    )
  }

  private renderHeaderText(index: number) {
    const {
      object,
      status,
      levelsToClose,
      dateClosures,
      hoursOfOperation,
      permittedCompanies,
      maxBookingDuration,
      maxOverlappingBookings,
      associatedForms,
      associatedAnnouncements,
    } = Localization.translator
    const key = this.store.getColumnKeyByIndex(index)
    switch (key) {
      case ColumnKey.name:
        return object
      case ColumnKey.status:
        return status
      case ColumnKey.levelsToClose:
        return levelsToClose
      case ColumnKey.permittedCompanies:
        return permittedCompanies
      case ColumnKey.operatingIntervals:
        return hoursOfOperation
      case ColumnKey.closedIntervals:
        return dateClosures
      case ColumnKey.associatedPermits:
        return associatedForms
      case ColumnKey.associatedAnnouncements:
        return associatedAnnouncements
      case ColumnKey.maxBookingDuration:
        return (
          <div className="col">
            <span>{deliverySetting}</span>
            <span>{`${maxBookingDuration}(HRS)`}</span>
          </div>
        )
      case ColumnKey.maxOverlappingDeliveries:
        return (
          <div className="col">
            <span>{deliverySetting}</span>
            <span>{`${maxOverlappingBookings}(#)`}</span>
          </div>
        )
    }
  }

  private getRowHeight = ({ index }: any): number => {
    if (!index) {
      return HEADER_ROW_HEIGHT
    }

    const obj = this.props.rows[index]

    if (obj.category) {
      return CATEGORY_ROW_HEIGHT
    }

    const { getLocationClosures, getLocationActiveClosures } =
      this.props.closuresStore

    const { permittedCompanies = [], operatingIntervals = [], id } = obj.data

    const allClosedIntervalsLength = getLocationClosures(id)?.length

    const upToDateClosuresLength = getLocationActiveClosures(id)?.length

    const shouldShowAllClosures = this.oldClosuresToggleState.get(obj.data.id)
    const closureIntervalsLength = shouldShowAllClosures
      ? allClosedIntervalsLength
      : upToDateClosuresLength
    const additionalClosureHeight = closureIntervalsLength
      ? CLOSURES_HISTORY_DEFAULT_HEIGHT + DAILY_CLOSURES_BTN_HEIGHT
      : DAILY_CLOSURES_BTN_HEIGHT

    return Math.max(
      MIN_ROW_HEIGHT,
      ObjectsList.getObjectCellHeight(obj.data as LocationAttributeBase),
      ObjectsList.getListContainedCellHeight(permittedCompanies?.length, false),
      ObjectsList.getListContainedCellHeight(operatingIntervals?.length, true),
      ObjectsList.getListContainedCellHeight(closureIntervalsLength, true) +
        additionalClosureHeight,
    )
  }

  private get store(): ObjectsListStore {
    return this.props.store
  }

  private toggleOldClosures = (id: string) => {
    this.oldClosuresToggleState.set(id, !this.oldClosuresToggleState.get(id))
    this.recomputeGridSize()
  }
}

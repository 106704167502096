import * as React from 'react'

interface IMenuCloser {
  isOpen: boolean
  closeMenu: () => void
  className?: string
  excludeId?: string
}

export default class MenuCloser extends React.Component<IMenuCloser> {
  public static defaultProps = {
    isOpen: true,
  }
  private wrapperRef: HTMLDivElement

  public render() {
    const { className, children } = this.props
    return (
      <div className={className} ref={this.setWrapperRef}>
        {children}
      </div>
    )
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  private setWrapperRef = (node: HTMLDivElement) => {
    this.wrapperRef = node
  }

  private handleClickOutside = (event: any) => {
    const { closeMenu, isOpen, excludeId } = this.props
    const excludeElement = document.getElementById(excludeId)
    if (
      !this.wrapperRef?.contains(event.target) &&
      !excludeElement?.contains(event.target) &&
      isOpen
    ) {
      event.stopPropagation()
      closeMenu()
    }
  }
}

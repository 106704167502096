import { SaveGatesDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import LocationAttributesStoreBase from './LocationAttributesBase.store'

export default class GatesStore extends LocationAttributesStoreBase<Gate> {
  protected saveMutation = SaveGatesDocument
  protected graphName = 'gates'
  protected defaultIconName = SitemapAttributeIcon.Gate

  public get byId() {
    const { gates } = this.eventsStore.appState
    return gates
  }

  protected fromDto(dto: ILocationAttributeDto): Gate {
    return Gate.fromDto(dto)
  }
}

import { observable } from 'mobx'

import {
  IGeoJson2DGeographicCoordinates,
  ISitemapItem,
  ISitemapItemShapeCoordinates,
  LocationType,
} from '~/client/graph'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import SitemapItemType from '~/client/src/shared/enums/SitemapItemType'
import IHierarchyParent from '~/client/src/shared/types/IHierarchyParent'
import Guard from '~/client/src/shared/utils/Guard'

import { areObjectsEqual, copyObject } from '../utils/util'
import BaseModel from './BaseModel'
import LocationBase from './LocationObjects/LocationBase'

export default class SitemapItem
  extends BaseModel<ISitemapItem>
  implements ISitemapItem
{
  public static fromDto(dto: ISitemapItem) {
    const isReferenced =
      typeof dto.isReferenced === 'undefined' ? true : dto.isReferenced
    return new SitemapItem(
      dto.id,
      dto.name,
      dto.color,
      dto.type as SitemapItemType,
      dto.iconName as SitemapAttributeIcon,
      dto.projectId,
      dto.assignedTo as LocationType,
      dto.assignedId,
      dto.parent as IHierarchyParent,
      dto.createdAt,
      dto.updatedAt,
      dto.coordinates,
      dto.shapeCoordinates,
      isReferenced,
    )
  }

  @observable public name: string
  @observable public color: string
  @observable public type: SitemapItemType
  @observable public iconName: SitemapAttributeIcon
  public projectId: string
  @observable public assignedTo?: LocationType
  @observable public coordinates?: IGeoJson2DGeographicCoordinates
  @observable public assignedId?: string
  @observable public parent: IHierarchyParent = null
  @observable public shapeCoordinates?: ISitemapItemShapeCoordinates
  @observable public isReferenced?: boolean

  public constructor(
    id: string,
    name: string,
    color: string,
    type: SitemapItemType,
    iconName: SitemapAttributeIcon,
    projectId: string,
    assignedTo?: LocationType,
    assignedId?: string,
    parent?: IHierarchyParent,
    createdAt: number = 0,
    updatedAt: number = 0,
    coordinates?: IGeoJson2DGeographicCoordinates,
    shapeCoordinates?: ISitemapItemShapeCoordinates,
    isReferenced?: boolean,
  ) {
    super(id)
    this.name = name
    this.color = color
    this.type = type
    this.iconName = iconName
    this.projectId = projectId
    this.assignedTo = assignedTo
    this.assignedId = assignedId
    this.parent = parent
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
    this.coordinates = coordinates
    this.shapeCoordinates = shapeCoordinates
    this.isReferenced = isReferenced
    Guard.requireAll({ name, projectId, color })
  }

  public isEqual(other: SitemapItem) {
    return (
      this.id === other.id &&
      this.name === other.name &&
      this.color === other.color &&
      this.projectId === other.projectId &&
      this.assignedTo === other.assignedTo &&
      this.assignedId === other.assignedId &&
      this.coordinates === other.coordinates &&
      areObjectsEqual(this.parent || {}, other.parent || {}) &&
      areObjectsEqual(
        this.shapeCoordinates || {},
        other.shapeCoordinates || {},
      ) &&
      this.isReferenced === other.isReferenced
    )
  }

  public isAssignedTo(dto: LocationBase) {
    return this.assignedId === dto.id && this.assignedTo === dto.type
  }

  public assign(dto: LocationBase) {
    this.assignedTo = dto.type
    this.assignedId = dto.id
  }

  public get isAssigned(): boolean {
    return !!this.assignedTo && !!this.assignedId
  }

  public copy(): SitemapItem {
    return new SitemapItem(
      this.id,
      this.name,
      this.color,
      this.type,
      this.iconName,
      this.projectId,
      this.assignedTo,
      this.assignedId,
      this.parent && copyObject(this.parent),
      this.createdAt,
      this.updatedAt,
      this.coordinates && copyObject(this.coordinates),
      this.shapeCoordinates && copyObject(this.shapeCoordinates),
      this.isReferenced,
    )
  }
}

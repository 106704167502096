import { action, computed, observable } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import MapBoxEditorStore from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor.store'
import BaseSitemapSetUpStore from '~/client/src/shared/components/SitemapHelpers/DeliverySitemap.store'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import Company from '~/client/src/shared/models/Company'
import Delivery from '~/client/src/shared/models/Delivery'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import Material from '~/client/src/shared/models/Material'
import Sitemap from '~/client/src/shared/models/Sitemap'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import IDeliveryAttributeDto from '~/client/src/shared/types/IDeliveryAttributeDto'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import DesktopDeliveryViewStore from '../../Deliveries.store'
import DeliveriesBySitemapListStore from './DeliveriesBySitemapList.store'

// localization: no display text to translate

export default class DeliveriesMapStore {
  @observable public selectedAttrId: string = EMPTY_STRING
  public readonly deliveriesBySitemapListStore: DeliveriesBySitemapListStore =
    null
  public selectedDeliveriesMap: { [attrId: string]: boolean } = {}
  @observable public topOffset: number = 0
  @observable public leftOffset: number = 0

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly deliveryAssignmentsStore: DeliveryAssignmentsStore,
    private readonly desktopDeliveryViewStore: DesktopDeliveryViewStore,
    private readonly baseSitemapSetUpStore: BaseSitemapSetUpStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly userProjectsStore: UserProjectsStore,
    public readonly mapBoxEditorStore: MapBoxEditorStore,
  ) {
    this.deliveriesBySitemapListStore = new DeliveriesBySitemapListStore(
      this.eventsStore.appState,
      this.projectDateStore,
      this.deliveryAssignmentsStore,
      this.desktopDeliveryViewStore,
      this,
      this.companiesStore,
      this.projectMembersStore,
      this.materialCategoryStore,
      this.userProjectsStore,
    )
  }

  @action.bound
  public mount() {
    if (!this.activeDeliveryId) {
      return
    }

    this.deliveriesBySitemapListStore.scrollToInstance(this.activeDeliveryId)
  }

  @action.bound
  public unmount() {
    this.baseSitemapSetUpStore.deselectSitemap()
  }

  @action.bound
  public setGroupingFilter() {
    const { deliveryFilters } = this.eventsStore.appState

    if (
      !Object.values(DeliveryGroupingOption).includes(
        deliveryFilters.groupingKey,
      ) ||
      deliveryFilters.groupingKey === DeliveryGroupingOption[FieldIds.LOCATION]
    ) {
      deliveryFilters.groupingKey = DeliveryGroupingOption[FieldIds.DATE]
    }
  }

  public getZoneById = (zoneId: string): Zone => {
    return this.desktopDeliveryViewStore.getZoneById(zoneId)
  }

  public getGateById = (gateId: string): Gate => {
    return this.desktopDeliveryViewStore.getGateById(gateId)
  }

  public getRouteById = (routeId: string): Route => {
    return this.desktopDeliveryViewStore.getRouteById(routeId)
  }

  public getBuildingById = (buildingId: string): Building => {
    return this.desktopDeliveryViewStore.getBuildingById(buildingId)
  }

  public getMaterialsByIds = (materialIds: string[]): Material[] => {
    return this.desktopDeliveryViewStore.getMaterialsByIds(materialIds)
  }

  public getCompanyById = (companyId: string): Company => {
    return this.desktopDeliveryViewStore.getCompanyById(companyId)
  }

  public getOffloadEquipmentsByIds = (
    offloadingEquipmentIds: string[],
  ): IDeliveryAttributeDto[] => {
    return this.desktopDeliveryViewStore.getOffloadEquipmentsByIds(
      offloadingEquipmentIds,
    )
  }

  public getClosestAttrIdToDeliveriesMap = (
    displayedSitemapItems: SitemapItemBase[],
  ): { [attrId: string]: Delivery[] } => {
    this.deSelectDeliveries()

    const displayedAttrsIds = displayedSitemapItems
      .filter(this.isSitemapItemSupported)
      .map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = this.availableDeliveries.filter(
        d => d.isRelatedAttr(attrId) && !this.selectedDeliveriesMap[d.id],
      )
      this.selectDeliveries(acc[attrId].map(d => d.id))
      return acc
    }, {})
  }

  @computed
  public get attributesBySitemapItems(): LocationBase[] {
    if (!this.selectedSitemap) {
      return []
    }

    const { items } = this.selectedSitemap
    const { getSitemapItemById } = this.baseSitemapSetUpStore

    const attributesBySitemap: LocationBase[] = []

    const sitemapItems = Object.values(items).filter(item => !item.isHidden)
    sitemapItems.forEach(itemData => {
      const item = getSitemapItemById(itemData.sitemapItemId)

      if (!item) {
        return
      }

      const attribute = this.allSitemapsAttributes.find(a =>
        item.isAssignedTo(a),
      )

      if (attribute) {
        attributesBySitemap.push(attribute)
      }
    })

    return attributesBySitemap
  }

  @computed
  public get attributesBySelectedSitemap(): LocationBase[] {
    if (!this.selectedSitemap) {
      return []
    }

    return this.allSitemapsAttributes.filter(a =>
      a.isSitemapAssigned(this.selectedSitemap.id),
    )
  }

  public displayedSitemapDeliveries = (): Delivery[] => {
    if (!this.selectedSitemap) {
      return []
    }

    const { id } = this.selectedSitemap
    const { sitemaps: siteSitemaps } =
      this.eventsStore.appState.delivery.configurations

    if (siteSitemaps.some(s => s.sitemapId === id)) {
      return this.selectedSitemap.isReferenced
        ? this.deliveriesByReferencedSitemapItems
        : this.deliveriesBySitemapItems
    }

    return this.deliveriesByLowestChildrenTags
  }

  @computed
  public get deliveriesBySitemapItems(): Delivery[] {
    return this.desktopDeliveryViewStore.currentViewDeliveries
      .filter(
        d =>
          this.attributesBySitemapItems.length &&
          this.attributesBySitemapItems.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  @computed
  public get deliveriesByReferencedSitemapItems(): Delivery[] {
    return this.desktopDeliveryViewStore.currentViewDeliveries
      .filter(
        d =>
          this.mapBoxEditorStore.itemsOnMap.length &&
          this.mapBoxEditorStore.itemsOnMap.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  @computed
  public get deliveriesByLowestChildrenTags(): Delivery[] {
    const lowestChildrenAttributes = this.attributesBySelectedSitemap.filter(
      a => !this.attributesBySelectedSitemap.some(at => at.isParent(a)),
    )

    return this.desktopDeliveryViewStore.currentViewDeliveries
      .filter(
        d =>
          lowestChildrenAttributes.length &&
          lowestChildrenAttributes.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  @computed
  public get completedToTotalCountLabel(): string {
    const totalCount = this.availableDeliveries.length
    const completedCount = this.availableDeliveries.filter(d => d.isDone).length

    return `${completedCount}/${totalCount}`
  }

  public get listGroupingKey(): string {
    return this.deliveriesBySitemapListStore.groupingKey
  }

  public get activeDeliveryId(): string {
    return this.desktopDeliveryViewStore.activeDeliveryId
  }

  @computed
  public get availableDeliveries(): Delivery[] {
    return this.deliveriesBySitemapListStore.filteredCollection
  }

  public isSelectedAttr = (attrId: string): boolean => {
    return this.selectedAttrId === attrId
  }

  @action.bound
  public selectAttr(attrId: string, y: number, x: number) {
    if (this.selectedAttrId === attrId) {
      return this.resetAttrSelection()
    }

    this.selectedAttrId = attrId
    this.topOffset = y
    this.leftOffset = x
  }

  @action.bound
  public resetAttrSelection() {
    this.selectedAttrId = EMPTY_STRING
  }

  @action.bound
  public selectSitemap(sitemap: Sitemap) {
    this.baseSitemapSetUpStore.selectSitemap(sitemap)
  }

  @action.bound
  public deselectSitemap() {
    this.baseSitemapSetUpStore.deselectSitemap()
  }

  @action.bound
  public selectDelivery(deliveryId: string) {
    this.desktopDeliveryViewStore.activateDeliveryById(deliveryId)
  }

  @action.bound
  public resetAllFilters() {
    this.desktopDeliveryViewStore.resetAllFilters()
  }

  public isSitemapItemSupported = (item: SitemapItemBase): boolean => {
    return !item.isDataLess
  }

  private getSitemapsOrderById(id: string): number {
    const { sitemaps } = this.eventsStore.appState.delivery.configurations
    return sitemaps.find(s => s.sitemapId === id).order || 0
  }

  private sortDeliveriesFunc = (a: Delivery, b: Delivery): number => {
    const aBuilding = this.getBuildingById(a.building)
    const bBuilding = this.getBuildingById(b.building)
    const aBuildingName = (aBuilding && aBuilding.name) || EMPTY_STRING
    const bBuildingName = (bBuilding && bBuilding.name) || EMPTY_STRING

    return aBuildingName.localeCompare(bBuildingName)
  }

  @computed
  public get attrIdToDeliveriesMap(): { [attrId: string]: Delivery[] } {
    const { displayedSitemapItems } = this.baseSitemapSetUpStore

    const displayedAttrsIds = displayedSitemapItems
      .filter(this.isSitemapItemSupported)
      .map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = this.availableDeliveries.filter(d =>
        d.isRelatedAttr(attrId),
      )
      return acc
    }, {})
  }

  @computed
  public get highlightedDeliveriesIds(): string[] {
    const deliveriesIds = this.attrIdToDeliveriesMap[this.selectedAttrId]
    return deliveriesIds && deliveriesIds.map(({ id }) => id)
  }

  @computed
  public get deliveriesSitemaps(): Sitemap[] {
    const { list } = this.baseSitemapSetUpStore.sitemapsStore
    const { sitemaps } = this.eventsStore.appState.delivery.configurations
    return list
      .filter(s =>
        sitemaps.some(orderedSitemap => orderedSitemap.sitemapId === s.id),
      )
      .sort(
        (a, b) =>
          this.getSitemapsOrderById(a.id) - this.getSitemapsOrderById(b.id),
      )
  }

  @computed
  public get deliveriesSitemapsIds(): string[] {
    const { sitemaps } = this.eventsStore.appState.delivery.configurations

    return sitemaps.map(sitemap => sitemap.sitemapId)
  }

  @computed
  public get displayedSitemapItems(): SitemapItemBase[] {
    return this.baseSitemapSetUpStore.displayedSitemapItems
  }

  @computed
  public get sitemapItems(): SitemapItemBase[] {
    return this.baseSitemapSetUpStore.sitemapItems
  }

  @computed
  public get allSitemapsAttributes(): LocationBase[] {
    return this.baseSitemapSetUpStore.hierarchyAttributes
  }

  @computed
  public get selectedSitemapItem(): SitemapItemBase {
    return this.displayedSitemapItems.find(i => i.id === this.selectedAttrId)
  }

  @computed
  public get selectedSitemap(): Sitemap {
    return this.baseSitemapSetUpStore.sitemap
  }

  public get selectedSitemapUrl(): string {
    return this.baseSitemapSetUpStore.sitemapUrl
  }

  public get isInitialLoaderShown(): boolean {
    return this.baseSitemapSetUpStore.isInitialLoaderShown
  }

  private deSelectDeliveries = () => {
    this.selectedDeliveriesMap = {}
  }

  private selectDeliveries = (deliveriesIds: string[]) => {
    deliveriesIds.forEach(id => {
      this.selectedDeliveriesMap[id] = true
    })
  }
}

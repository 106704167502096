import * as React from 'react'

import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import ReactSlider from 'react-slider'

import { SitemapItemShapeType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Sitemap, {
  ISitemapGeoPosition,
} from '~/client/src/shared/models/Sitemap'
import { SAVE_SITEMAP } from '~/client/src/shared/stores/EventStore/eventConstants'
import { ProjectSetUpSteps } from '~/client/src/shared/stores/InitialState'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import DndArea from '../../../../../shared/components/DndArea/DndArea'
import UploadButton from '../../../../../shared/components/UploadButton/UploadButton'
import OpacityBackground from '../../../SimpleGanttView/components/OpacityBackground/OpacityBackground'
import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import DeleteDeliveryAttributeConfirmDialog from '../ProjectWorkflowsSetUp/components/DeliveryRequestSetUp/components/DeliveryDetailsConfigurations/components/DeleteDeliveryAttributeConfirmDialog'
import GeneralSitemapSetUpStore, {
  SetUpSteps,
} from './GeneralSitemapSetUp.store'
import LeftPanel from './components/LeftPanel'
import ObjectPallet from './components/ObjectPallet'
import PdfToImageConverter from './components/PdfToImageConverter'
import PropertiesPanel from './components/PropertiesPanel/PropertiesPanel'
import SitemapAssociationControl from './components/SitemapAssociationControl'
import SitemapEditor from './components/SitemapEditor/SitemapEditor'
import SitemapSetupEditableLabel from './components/SitemapSetupEditableLabel'
import DeleteSitemapConfirmDialog from './components/dialogs/DeleteSitemapConfirmDialog'
import ViewSelectDialog from './components/dialogs/ViewSelectDialog/ViewSelectDialog'
import SitemapRibbon from './components/ribbons/SitemapRibbon'
import {
  ACTION_KEY,
  DTO_ID_KEY,
  NEW_SITEMAP_TYPE_KEY,
  SITEMAP_ID_KEY,
} from './models/ISitemapDelayedAction'

import './GeneralSitemapSetUp.scss'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore
  eventsStore?: DesktopEventStore
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  locationAttributesStore?: LocationAttributesStore
  sitemapItemsStore?: SitemapItemsStore
  fileUploadingStore?: FileUploadingStore
  common?: DesktopCommonStore
  syncRestrictionsStore?: SyncRestrictionsStore
  activityFiltersStore?: ActivityFiltersStore
  userProjectsStore?: UserProjectsStore
  tagsStore?: TagsStore
}

interface ILocation {
  icon: JSX.Element
  name: string
  text: JSX.Element
}

interface ICircleAngles {
  startAngle: number
  endAngle: number
}

const dntText = {
  fileTypes: 'png, jpg, pdf file here.',
  pdfConverted: 'Pdf file will automatically be converted to image.',
  postAction:
    'When a new map is uploaded location based pins will need to be located on the map.',
}
const firstTooltipText = {
  georeferenceYourPlan: 'Georeference your plan',
  whatIsGeoreferencing: 'What is georeferencing?',
  toGetBenefits:
    'To get the full benefits of using your siteplan in StruxHub, you ' +
    'need to georeference it.',
  georeferencingIs:
    'Georeferencing is the process of assigning geospatial positioning ' +
    '(e.g. longitude, latitude) to digital plans.',
  oncePlanIsGeoreferenced: 'Once your siteplan is georeferenced, you can:',
  sendTextTodrivers:
    'Send drivers the exact longitude & latitude of entry gates',
  seeAndShare: 'See and share your location on the siteplan',
  visualize:
    'Visualize tracked trucks or assets on your siteplan (contact Sales)',
}
const thirdTooltip = {
  addLocation: 'Add locations',
  struxHubAllows:
    'StruxHub allows you to organize & control any location or resource ' +
    'on your site. There are various location object types, each with ' +
    'different properties and functions in StruxHub (see below).',
  locationObjectTypes: 'Location object types',
}
const secondTooltip = {
  georeferenceYourPlan: 'Georeference your plan',
  howToGeoreference: 'How to georeference your siteplan?',
  yourObjectiveIs:
    'Your objective is to tell StruxHub where your plan is located on the Earth.',
  toGeoreferenceYouWill:
    'To do this, you will use distinguishing features such as streets, ' +
    'intersections, and building outlines to align your plan with the ' +
    'satellite map of the Earth.',
  withThisFeature:
    'With these feature identified, you can perform 3 operations on the ' +
    'satellite map (i.e. basemap) in order to have the features ' +
    'conincide.',
}
const transparency = 'transparency'
const lbsTag = 'LBS Tag'
const publishTo = 'Publish To'
const sitemapsDataIsLoading = 'Sitemaps Data is loading...'

export const ACCEPTED_SITEMAP_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.pdf']
const DELAYED_ACTION_QUERY_PARAMS = [
  ACTION_KEY,
  NEW_SITEMAP_TYPE_KEY,
  DTO_ID_KEY,
  SITEMAP_ID_KEY,
]

@inject(
  'eventsStore',
  'sitemapsStore',
  'basemapsStore',
  'locationAttributesStore',
  'sitemapItemsStore',
  'fileUploadingStore',
  'common',
  'syncRestrictionsStore',
  'activityFiltersStore',
  'userProjectsStore',
  'tagsStore',
)
@observer
export default class GeneralSitemapSetUp extends React.Component<IProps> {
  private readonly store: GeneralSitemapSetUpStore

  public constructor(props: IProps) {
    super(props)

    this.store = new GeneralSitemapSetUpStore(
      props.eventsStore,
      props.sitemapsStore,
      props.basemapsStore,
      props.locationAttributesStore,
      props.sitemapItemsStore,
      props.fileUploadingStore,
      props.syncRestrictionsStore,
      props.activityFiltersStore,
      props.userProjectsStore,
      props.tagsStore,
    )
  }

  public componentDidMount() {
    this.store.sitemapSetupStore.setViewportFromAdress()
  }

  public UNSAFE_componentWillMount() {
    const { search, pathname } = this.props.common.history.location
    const queryParams = new URLSearchParams(search)

    const { sitemapSetupStore } = this.store
    sitemapSetupStore.setDelayedActionParamsFromUrl(queryParams)

    DELAYED_ACTION_QUERY_PARAMS.forEach(param => {
      queryParams.delete(param)
    })
    const path = pathname + '?' + queryParams.toString()
    this.props.common.history.replace(path)
  }

  public render() {
    const { projectSetUpPageStore } = this.props
    const {
      isInitialLoaderShown,
      sitemapItemsSetupStore,
      isLoading,
      sitemapViewsSetupStore: { isDialogOpened },
      sitemapSetupStore: { isSavingGeolocation, selectedSitemap },
      mapBoxEditorStore: {
        isFirstTooltipShown,
        isSecondTooltipShown,
        isThirdTooltipShown,
        isLastTooltipShown,
        sitemapStage,
      },
    } = this.store
    if (isInitialLoaderShown) {
      return <Loader hint={sitemapsDataIsLoading} />
    }

    if (isLoading) {
      return <Loader hint={Localization.translator.loading} />
    }

    const {
      deletableSitemapItem,
      deletableSitemapItemCaption,
      applySitemapItemDeleteConfirmDialog,
      hideSitemapItemDeleteConfirmDialog,
    } = sitemapItemsSetupStore

    const isSomeTooltipShown =
      isFirstTooltipShown ||
      isSecondTooltipShown ||
      isThirdTooltipShown ||
      isLastTooltipShown

    return (
      <div className="general-sitemap-setup full-height">
        {deletableSitemapItem && (
          <DeleteDeliveryAttributeConfirmDialog
            isShown={!!deletableSitemapItem}
            isSubTextHidden={
              deletableSitemapItem && !deletableSitemapItem.dataObject
            }
            type={deletableSitemapItemCaption}
            name={deletableSitemapItem.displayName}
            onApply={applySitemapItemDeleteConfirmDialog}
            onHide={hideSitemapItemDeleteConfirmDialog}
          />
        )}
        <DeleteSitemapConfirmDialog store={this.store} />

        {isDialogOpened ? (
          <ViewSelectDialog
            store={this.store}
            updateSitemapName={this.updateSitemapName}
            viewport={this.viewport}
            setViewport={this.setViewport}
          />
        ) : isSavingGeolocation ? (
          <Loader hint="Georeferencing sitemap" />
        ) : (
          <div className="main-area row full-height">
            <SitemapRibbon
              store={this.store}
              shouldHideAdditionMenu={!selectedSitemap}
            />
            {sitemapStage && isSomeTooltipShown && <OpacityBackground />}
            {sitemapStage && isFirstTooltipShown && this.renderFirstTooltip()}
            {sitemapStage && isSecondTooltipShown && this.renderSecondTooltip()}
            {sitemapStage && isThirdTooltipShown && this.renderThirdTooltip()}
            {sitemapStage && isLastTooltipShown && this.renderLastTooltip()}
            <div className="col full-height">
              {this.renderHeader()}
              <div className="row sitemap-edit-content">
                <LeftPanel
                  store={this.store}
                  projectSetUpPageStore={projectSetUpPageStore}
                  viewport={this.viewport}
                  setViewport={this.setViewport}
                  step={this.store.currentStep}
                />
                {this.renderContent()}
              </div>
            </div>
            <PropertiesPanel
              store={this.store}
              step={this.store.currentStep}
              saveAligment={this.saveSitemap}
              exitRubber={this.exitRubberMode}
              viewport={this.viewport}
              selectedItemId={
                this.store.sitemapItemsSetupStore.selectedSitemapItem?.id
              }
              bearing={this.store.mapBoxEditorStore?.viewport?.bearing}
              startAngle={this.circle?.startAngle}
              endAngle={this.circle?.endAngle}
            />
          </div>
        )}
      </div>
    )
  }

  private saveSitemap = async () => {
    const { appState } = this.props.eventsStore
    const {
      mapBoxEditorStore: { width, height, setSourceBounds },
      mapBoxEditorStore,
      sitemapSetupStore: { selectedSitemap, updateGeoposition },
      setStep,
    } = this.store
    appState.loading.set(SAVE_SITEMAP, true)

    setSourceBounds()
    await updateGeoposition(
      selectedSitemap,
      this.viewport,
      width,
      height,
      mapBoxEditorStore.getCorners,
    )
    if (
      this.props.eventsStore.appState.projectCreationStep ===
      ProjectSetUpSteps.GeoreferencePlan
    ) {
      this.props.eventsStore.appState.projectCreationStep =
        ProjectSetUpSteps.AddSiteLocations
      mapBoxEditorStore.isThirdTooltipShown = true
    }

    setStep(null)
    appState.loading.set(SAVE_SITEMAP, false)
  }

  private exitRubberMode = async () => {
    const { appState } = this.props.eventsStore
    appState.loading.set(SAVE_SITEMAP, true)
    await this.store.sitemapSetupStore.updateGeoposition(
      this.store.sitemapSetupStore.selectedSitemap,
    )
    this.store.mapBoxEditorStore.isRubberMode = false
    this.store.mapBoxEditorStore.setSourceBounds()

    if (
      this.props.eventsStore.appState.projectCreationStep ===
      ProjectSetUpSteps.GeoreferencePlan
    ) {
      this.props.eventsStore.appState.projectCreationStep =
        ProjectSetUpSteps.AddSiteLocations
      this.store.mapBoxEditorStore.isThirdTooltipShown = true
    }

    await this.store.setStep(null)
    appState.loading.set(SAVE_SITEMAP, false)
  }

  private renderContent() {
    const {
      sitemapSetupStore,
      sitemapItemsSetupStore,
      currentStep,
      mapBoxEditorStore: {
        setOpacity,
        opacity,
        isRubberMode,
        isFirstTooltipShown,
        isSecondTooltipShown,
      },
    } = this.store

    const { selectedSitemap, pdfSitemapFile, uploadConvertedImage } =
      sitemapSetupStore

    const shouldShowTransparencySlider =
      isRubberMode && !isFirstTooltipShown && !isSecondTooltipShown

    return (
      <div className="main-area-container full-height relative">
        {selectedSitemap && !currentStep && (
          <ObjectPallet store={sitemapItemsSetupStore} />
        )}
        {selectedSitemap && !!currentStep && this.renderSetupRow()}
        {selectedSitemap && (
          <div className="sitemap-editor-container row">
            <SitemapEditor
              store={this.store}
              sitemap={selectedSitemap}
              viewport={this.viewport}
              setViewport={this.setViewport}
              opacity={this.store.mapBoxEditorStore.opacity}
            />
          </div>
        )}
        {!selectedSitemap && (
          <div className="general-sitemap-setup-dnd-area">
            <DndArea
              fileType={FileType.Image}
              accept={ACCEPTED_SITEMAP_EXTENSIONS}
              title={this.dndTitle}
              onDrop={this.onDrop}
            />
          </div>
        )}
        {!selectedSitemap && pdfSitemapFile && (
          <PdfToImageConverter
            pdfFile={pdfSitemapFile}
            uploadImage={uploadConvertedImage}
          />
        )}
        {shouldShowTransparencySlider && (
          <div className="absolute brada10 bg-white pa5 opacity-slider row">
            <div>
              <div className="text uppercase transparency-text lp15">
                {transparency}
              </div>
              <div className="row">
                <Icon
                  icon={IconNames.MAP}
                  className="no-grow mr10"
                  onClick={setOpacity.bind(null, 0)}
                />
                <div className="mr20 slider-holder">
                  <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    value={opacity}
                    renderThumb={this.renderOpacitySlider}
                    onChange={setOpacity}
                  />
                </div>
                <Icons.Globe
                  className="no-grow mr10"
                  onClick={setOpacity.bind(null, 100)}
                />
                <input
                  type="number"
                  value={opacity}
                  onChange={this.updateOpacity}
                  max={100}
                  min={1}
                />
                %
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  private renderOpacitySlider = (props, state) => {
    return <div {...props}>{state.valueNow}</div>
  }

  private onDrop = async (file: File) => {
    const { sitemapSetupStore, setStep } = this.store

    const { uploadNewSitemap } = sitemapSetupStore
    setStep(SetUpSteps.alignPlan)
    if (
      this.props.eventsStore.appState.projectCreationStep ===
      ProjectSetUpSteps.UploadSitePlan
    ) {
      this.props.eventsStore.appState.projectCreationStep =
        ProjectSetUpSteps.GeoreferencePlan
      this.store.mapBoxEditorStore.isFirstTooltipShown = true
    }
    await uploadNewSitemap(file)
  }

  private updateOpacity = event => {
    this.store.setOpacity(event.target.value)
  }

  private renderSetupRow() {
    return (
      <div className="full-width row set-up-steps-row">
        {Object.values(SetUpSteps).map(step => {
          const isCurrent = this.store.currentStep === step
          return (
            <div
              key={step}
              className={classList({ item: true, active: isCurrent })}
            >
              {step}
            </div>
          )
        })}
      </div>
    )
  }

  private get viewport(): ISitemapGeoPosition {
    return this.store.mapBoxEditorStore.viewport
  }

  private setViewport = (viewport: ISitemapGeoPosition) => {
    if (this.store.mapBoxEditorStore.canvasMap) {
      viewport.bounds = this.store.mapBoxEditorStore.canvasMap
        .getMap()
        .getBounds()
    }
    this.store.mapBoxEditorStore.viewport = viewport
  }

  private get dndTitle() {
    const { isSitemapUploading } = this.store.sitemapSetupStore

    if (isSitemapUploading) {
      return (
        <div className="general-sitemap-setup-dnd-area-title row y-center">
          <Loader hint="Uploading..." />
        </div>
      )
    }

    return (
      <div className="general-sitemap-setup-dnd-area-title">
        <UploadButton text="Upload a base image for your delivery map or drag your" />
        <div className="pl30">{dntText.fileTypes}</div>
        <div className="mt20 pl30">{dntText.pdfConverted}</div>
        <div className="mt20 pl30">{dntText.postAction}</div>
      </div>
    )
  }

  private renderHeader() {
    const {
      isDeliveriesDisabled,
      isLogisticsDisabled,
      isTrackerDisabled,
      isFormsDisabled,
    } = this.props.eventsStore.appState
    const {
      sitemapSetupStore,
      sitemapViewsSetupStore: { toggleDialog },
      sitemapItemsSetupStore,
    } = this.store

    const {
      selectedSitemap,
      siteAssignedSitemaps,
      formsAssignedSitemaps,
      activitiesAssignedSitemaps,
      deliveriesAssignedSitemaps,
      onSiteSitemapSectionClick,
      onFormsSitemapSectionClick,
      onActivitiesSitemapSectionClick,
      onDeliveriesSectionClick,
    } = sitemapSetupStore

    return (
      <div className="row sitemap-edit-header-row bb-light-input-border pa5">
        <div className="row px10 main-header-cell y-between">
          <SitemapSetupEditableLabel
            selectedSitemap={selectedSitemap}
            text={selectedSitemap?.name}
            update={this.updateSitemapName.bind(this, selectedSitemap)}
            className="editable-label text large px10 brada4 text-ellipsis"
          />
          <div className="row x-end no-grow pointer">
            <Icon icon={IconNames.CARET_DOWN} onClick={toggleDialog} />
          </div>
        </div>
        <div className="col br-light-input-border px10 header-cell y-between">
          <div className="row text uppercase small light lp15 bold">
            {lbsTag}
          </div>
          <div className="row">
            <SitemapAssociationControl
              sitemapItemsSetupStore={sitemapItemsSetupStore}
              sitemapSetupStore={sitemapSetupStore}
            />
          </div>
        </div>

        <div className="col br-light-input-border px10 header-cell y-between">
          <div className="row text uppercase small light lp15 bold">
            {publishTo}
          </div>
          <div className="row">
            {selectedSitemap && (
              <div className="row">
                {!isLogisticsDisabled && (
                  <div
                    className="relative no-grow"
                    onClick={onSiteSitemapSectionClick.bind(
                      null,
                      selectedSitemap,
                    )}
                  >
                    {!siteAssignedSitemaps[selectedSitemap?.id] ? (
                      <Icons.HomeRoundedCrossed className="no-grow" />
                    ) : (
                      <Icons.HomeRounded className="no-grow publish-to-icon" />
                    )}
                  </div>
                )}
                {!isFormsDisabled && (
                  <div
                    className="relative no-grow"
                    onClick={onFormsSitemapSectionClick.bind(
                      null,
                      selectedSitemap,
                    )}
                  >
                    {!formsAssignedSitemaps[selectedSitemap?.id] ? (
                      <Icons.PermitsRoundedCrossed className="no-grow" />
                    ) : (
                      <Icons.PermitsRoundedActive className="no-grow publish-to-icon" />
                    )}
                  </div>
                )}
                {!isDeliveriesDisabled && (
                  <div
                    className="relative no-grow"
                    onClick={onDeliveriesSectionClick.bind(
                      null,
                      selectedSitemap,
                    )}
                  >
                    {!deliveriesAssignedSitemaps[selectedSitemap?.id] ? (
                      <Icons.DeliveryRoundedCrossed className="no-grow" />
                    ) : (
                      <Icons.DeliveryRounded className="no-grow publish-to-icon" />
                    )}
                  </div>
                )}
                {!isTrackerDisabled && (
                  <div
                    className="relative no-grow"
                    onClick={onActivitiesSitemapSectionClick.bind(
                      null,
                      selectedSitemap,
                    )}
                  >
                    {!activitiesAssignedSitemaps[selectedSitemap?.id] ? (
                      <Icons.ScheduleRoundedCrossed className="no-grow" />
                    ) : (
                      <Icons.ScheduleRounded className="no-grow publish-to-icon" />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="col br-light-input-border header-cell y-between" />
      </div>
    )
  }

  private updateSitemapName = (sitemap: Sitemap, newText: string) => {
    sitemap.setName(newText)
    this.store.sitemapSetupStore.saveSitemapWithLoader(sitemap)
  }

  // TODO: move text to the top
  private renderFirstTooltip() {
    return (
      <>
        <div className="absolute full-width full-height opacity-backgroung" />
        <div className="georeferencing-tooltip">
          <div className="text size24 pb20">
            {firstTooltipText.georeferenceYourPlan}
          </div>
          <div className="text header bold pb20">
            {firstTooltipText.whatIsGeoreferencing}
          </div>
          <div className="text large pb10">
            {firstTooltipText.toGetBenefits}
          </div>
          <div className="text large pb10">
            {firstTooltipText.georeferencingIs}
          </div>
          <div className="text large">
            {firstTooltipText.oncePlanIsGeoreferenced}
          </div>
          <ul className="ma0 text large pb10">
            <li>
              <Icon
                icon={IconNames.DOT}
                intent={Intent.PRIMARY}
                className="mx10"
              />
              {firstTooltipText.sendTextTodrivers}
            </li>
            <li>
              <Icon
                icon={IconNames.DOT}
                intent={Intent.PRIMARY}
                className="mx10"
              />
              {firstTooltipText.seeAndShare}
            </li>
            <li>
              <Icon
                icon={IconNames.DOT}
                intent={Intent.PRIMARY}
                className="mx10"
              />
              {firstTooltipText.visualize}
            </li>
          </ul>
          <button
            onClick={this.setSecondTooltip}
            className="submit-project-button light pa10 full-width"
          >
            {Localization.translator.next}
          </button>
        </div>
      </>
    )
  }

  private renderThirdTooltip() {
    return (
      <>
        <div className="absolute full-width full-height opacity-backgroung" />
        <div className="location-objects-tooltip">
          <div className="text size24 pb20">{thirdTooltip.addLocation}</div>
          <div className="tooltip-content">
            <div className="text large pb20">{thirdTooltip.struxHubAllows}</div>
            <div className="text header bold pb10">
              {thirdTooltip.locationObjectTypes}
            </div>
            {this.locations.map(location => this.renderLocationType(location))}
          </div>
          <div className="bt-light-cool-grey mt20">
            <button
              onClick={this.setLastTooltip}
              className="submit-project-button light pa10 full-width"
            >
              {Localization.translator.next}
            </button>
          </div>
        </div>
      </>
    )
  }

  private renderLastTooltip() {
    return (
      <>
        <div className="absolute full-width full-height opacity-backgroung" />
        <div className="location-objects-tooltip">
          <div className="text size24 pb20">Location breakdown structure</div>
          <div className="tooltip-content">
            <div className="text large pb20">
              All location objects are organized in a hierarchy called the{' '}
              Location Breakdown Structure (LBS). The LBS is located on the left{' '}
              side of the map setup page.
            </div>
            <div className="text large pb20">
              At the top of the hierarchy is{' '}
              <span className="text large orange">Project</span>. All other{' '}
              objects are nested either below{' '}
              <span className="text large orange">Project</span> in the Site{' '}
              logistics folder or under other objects.
            </div>
            <div className="text large pb10">
              For example, a typical single building project has the main{' '}
              <span className="text large orange">Building</span> object nested{' '}
              below <span className="text large orange">Project</span>,{' '}
              <span className="text large orange">Levels</span> below{' '}
              <span className="text large orange">Building</span>, and work{' '}
              <span className="text large orange">Areas</span> below{' '}
              <span className="text large orange">Levels</span>. All external{' '}
              locations (e.g.{' '}
              <span className="text large orange">Offloading zones</span>,{' '}
              <span className="text large orange">Routes</span>,{' '}
              <span className="text large orange">Gates</span>) are nested below{' '}
              <span className="text large orange">Project</span> in the Site{' '}
              Logistics.
            </div>
            <div className="row x-center full-width">
              <Icons.BreakDownSystem />
            </div>
            <div className="text large pb20">
              The benefit of the LBS is to help users navigate through locations{' '}
              in menus and especially in workflow forms.
            </div>
            <div className="text large pb20">
              For example, when a specific{' '}
              <span className="text large orange">Building</span> is selected,{' '}
              only those objects nested under this{' '}
              <span className="text large orange">Building</span> will be{' '}
              displayed as options in other fields. In this way, the LBS both{' '}
              reduces errors and eliminates friction in workflow request forms.
            </div>
          </div>

          <div className="bt-light-cool-grey mt20">
            <button
              onClick={this.hideTooltips}
              className="submit-project-button light pa10 full-width"
            >
              {Localization.translator.done}
            </button>
          </div>
        </div>
      </>
    )
  }

  private renderLocationType = ({ icon, name, text }: ILocation) => {
    return (
      <div className="row pb10">
        <div className="no-grow">{icon}</div>
        <div className="location-name text large">{name}</div>
        <div>{text}</div>
      </div>
    )
  }

  private renderSecondTooltip() {
    return (
      <>
        <div className="absolute full-width full-height opacity-backgroung" />
        <div className="georeference-instructions">
          <div className="text size24 pb20">
            {secondTooltip.georeferenceYourPlan}
          </div>
          <div className="text header bold pb20">
            {secondTooltip.howToGeoreference}
          </div>
          <div className="text large pb10">{secondTooltip.yourObjectiveIs}</div>
          <div className="text large pb10">
            {secondTooltip.toGeoreferenceYouWill}
          </div>
          <div className="text large pb10">{secondTooltip.withThisFeature}</div>
          <div className="row y-start pb10">
            <div className="col">
              <Icons.GeoreferencingZooming className="pb10" />
              <div className="map-controls-instruction col y-center">
                <Icons.GeoreferencingTooltipZooming />
              </div>
            </div>
            <div className="col">
              <Icons.GeoreferencingRotation className="pb10" />
              <div className="map-controls-instruction col y-center">
                <Icons.GeoreferencingTooltipRotation />
              </div>
            </div>
            <div className="col">
              <Icons.GeoreferencingTranslation className="pb10" />
              <div className="map-controls-instruction col y-center">
                <Icons.GeoreferencingTooltipTranslation />
              </div>
            </div>
          </div>
          <button
            onClick={this.hideSecondTooltip}
            className="submit-project-button light pa10 full-width"
          >
            {Localization.translator.done}
          </button>
        </div>
      </>
    )
  }

  private hideSecondTooltip = () => {
    this.store.mapBoxEditorStore.isSecondTooltipShown = false
  }

  private hideTooltips = () => {
    this.store.mapBoxEditorStore.isSecondTooltipShown = false
    this.store.mapBoxEditorStore.isThirdTooltipShown = false
    this.store.mapBoxEditorStore.isLastTooltipShown = false
    this.props.eventsStore.appState.projectCreationStep = null
  }

  private setLastTooltip = () => {
    this.store.mapBoxEditorStore.isSecondTooltipShown = false
    this.store.mapBoxEditorStore.isThirdTooltipShown = false
    this.store.mapBoxEditorStore.isLastTooltipShown = true
  }

  private setSecondTooltip = () => {
    this.store.mapBoxEditorStore.isFirstTooltipShown = false
    this.store.mapBoxEditorStore.isSecondTooltipShown = true
  }

  private get locations(): ILocation[] {
    return [
      {
        icon: (
          <Icons.ProjectLocationSmall className="no-grow location-icon-grey mr10" />
        ),
        name: 'Project',
        text: (
          <div className="text large">
            <span className="text large orange">Project</span> is the top level{' '}
            ‘container’ for all objects on your site.
          </div>
        ),
      },
      {
        icon: <TagIcon.Building className="no-grow location-icon-grey mr20" />,
        name: 'Building',
        text: (
          <div className="text large">
            <span className="text large orange">Buildings</span> are for{' '}
            enclosed spaces. They contain{' '}
            <span className="text large orange">levels</span> and{' '}
            <span className="text large orange">vertical objects</span>.
          </div>
        ),
      },
      {
        icon: <TagIcon.Zone className="no-grow location-icon-grey mr20" />,
        name: 'Offloading zone',
        text: (
          <div className="text large">
            <span className="text large orange">Offloading zone</span> denote{' '}
            the location where delivery trucks offload materials.
          </div>
        ),
      },
      {
        icon: (
          <TagIcon.Gate className="no-grow location-icon-grey-stroke mr20" />
        ),
        name: 'Entry gate',
        text: (
          <div className="text large">
            <span className="text large orange">Entry gates</span> are where the{' '}
            delivery trucks enter the site.
          </div>
        ),
      },
      {
        icon: <TagIcon.Route className="no-grow location-icon-grey mr20" />,
        name: 'Route',
        text: (
          <div className="text large">
            <span className="text large orange">Routes</span> are roads or{' '}
            pathways on site or adjacent to the site that delivery trucks should{' '}
            use.
          </div>
        ),
      },
      {
        icon: <TagIcon.Level className="no-grow location-icon-grey mr20" />,
        name: 'Level',
        text: (
          <div className="text large">
            <span className="text large orange">Levels</span> are floors of a{' '}
            <span className="text large orange">building</span>. The labeling of{' '}
            <span className="text large orange">levels</span> is done in the{' '}
            object properties panel for the parent{' '}
            <span className="text large orange">building</span>.
          </div>
        ),
      },
      {
        icon: <TagIcon.Area className="no-grow location-icon-grey mr20" />,
        name: 'Area',
        text: (
          <div className="text large">
            <span className="text large orange">Areas</span> are sections of a{' '}
            <span className="text large orange">level</span>. In deliveries,{' '}
            this is the final destination of the material. In activites and{' '}
            permits, this is where the work takes place.
          </div>
        ),
      },
      {
        icon: <TagIcon.Stairs className="no-grow location-icon-grey mr20" />,
        name: 'Vertical object',
        text: (
          <div className="text large">
            <span className="text large orange">Vertical objects</span> include{' '}
            stairs, shafts, and elevators that traverse all or a subset of{' '}
            <span className="text large orange">levels</span> of a{' '}
            <span className="text large orange">building</span>.
          </div>
        ),
      },
      {
        icon: <TagIcon.Equipment className="no-grow location-icon-grey mr20" />,
        name: 'Equipment',
        text: (
          <div className="text large">
            <span className="text large orange">Equipment</span> are lifting{' '}
            machinery for moving materials or people. It is used in delivery or{' '}
            activity workflows.
          </div>
        ),
      },
      {
        icon: (
          <TagIcon.LogisticsObject className="no-grow location-icon-grey mr20" />
        ),
        name: 'Site object',
        text: (
          <div className="text large">
            <span className="text large orange">Site objects</span> are{' '}
            miscellaneous site locations (e.g. muster points, bathrooms,{' '}
            dumpsters), generally used for communication purposes.
          </div>
        ),
      },
    ]
  }

  private get circle(): ICircleAngles {
    if (
      this.store.sitemapItemsSetupStore.selectedSitemapItem?.shapeProperties
        ?.type === SitemapItemShapeType.Circle
    ) {
      const circleShape = this.store.sitemapItemsSetupStore.selectedSitemapItem
        .shapeProperties as SitemapCircleProperties
      return {
        startAngle: circleShape.divisionStartAngle,
        endAngle: circleShape.divisionEndAngle,
      } as ICircleAngles
    }

    return null
  }
}

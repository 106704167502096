import {
  IPosition,
  ISitemapCircle,
  ISitemapItemShapeInterface,
  ISitemapPin,
  ISitemapPolyline,
  ISitemapRectangle,
  ISitemapTextBox,
  LocationType,
  SitemapItemShapeType,
} from '~/client/graph'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import SitemapItemType from '~/client/src/shared/enums/SitemapItemType'
import Area from '~/client/src/shared/models/LocationObjects/Area'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import InteriorDoor from '~/client/src/shared/models/LocationObjects/InteriorDoor'
import InteriorPath from '~/client/src/shared/models/LocationObjects/InteriorPath'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationIntegration from '~/client/src/shared/models/LocationObjects/LocationIntegration'
import LogisticsObject from '~/client/src/shared/models/LocationObjects/LogisticsObject'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import Staging from '~/client/src/shared/models/LocationObjects/Staging'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapItem from '~/client/src/shared/models/SitemapItem'
import { DEFAULT_SITEMAP_ITEM_COLOR } from '~/client/src/shared/utils/SitemapItemsColors'

import SitemapCircleProperties from './SitemapCircleProperties'
import SitemapIconProperties from './SitemapIconProperties'
import SitemapItemBase, { ShapeProperties } from './SitemapItemBase'
import SitemapLabelProperties from './SitemapLabelProperties'
import SitemapPolyLineProperties from './SitemapPolyLineProperties'
import SitemapRectangleProperties from './SitemapRectangleProperties'

const NEW_SITEMAP_ITEM_NAME_BY_TYPE = {
  [SitemapItemType.TextBox]: 'New Text Box',
  [SitemapItemType.Line]: 'New Line',
}

const ICON_BY_SITEMAP_ITEM_TYPE = {
  [SitemapItemType.Line]: SitemapAttributeIcon.Line,
  [SitemapItemType.TextBox]: SitemapAttributeIcon.TextBox,
}

const ICON_BY_ATTRIBUTE_TYPE = {
  [LocationType.Building]: SitemapAttributeIcon.Building,
  [LocationType.Zone]: SitemapAttributeIcon.Zone,
  [LocationType.Route]: SitemapAttributeIcon.Route,
  [LocationType.Gate]: SitemapAttributeIcon.Gate,
  [LocationType.OffloadingEquipment]: SitemapAttributeIcon.Equipment,
  [LocationType.LogisticsObject]: SitemapAttributeIcon.Logistics,
  [LocationType.VerticalObject]: SitemapAttributeIcon.Stairs,
  [LocationType.Level]: SitemapAttributeIcon.Level,
  [LocationType.Area]: SitemapAttributeIcon.Area,
  [LocationType.Integration]: SitemapAttributeIcon.Monitoring,
  [LocationType.Staging]: SitemapAttributeIcon.Staging,
  [LocationType.InteriorDoor]: SitemapAttributeIcon.InteriorDoor,
  [LocationType.InteriorPath]: SitemapAttributeIcon.InteriorPath,
}

const DATA_OBJECTS_CONSTRUCTOR_BY_TYPE = {
  [LocationType.Building]: Building,
  [LocationType.Zone]: Zone,
  [LocationType.Route]: Route,
  [LocationType.Gate]: Gate,
  [LocationType.OffloadingEquipment]: OffloadingEquipment,
  [LocationType.LogisticsObject]: LogisticsObject,
  [LocationType.VerticalObject]: VerticalObject,
  [LocationType.Level]: Level,
  [LocationType.Area]: Area,
  [LocationType.Integration]: LocationIntegration,
  [LocationType.Staging]: Staging,
  [LocationType.InteriorDoor]: InteriorDoor,
  [LocationType.InteriorPath]: InteriorPath,
}

const EMPTY_SITEMAP_ITEM_NAME = ' - '

export default class SitemapItemFactory {
  public static fromItem(
    dataObject: LocationAttributeBase,
    item: SitemapItem,
    sitemap: Sitemap,
  ) {
    const displayData = sitemap.getItemDisplayData(item.id)
    const isDisplayed = sitemap.isItemDisplayed(item.id)

    const defaultColor = (dataObject || item).color

    const shape = SitemapItemFactory.getShapeProperties(
      displayData.shape,
      defaultColor,
    )
    const icon = SitemapItemFactory.getIconProperties(displayData.icon, shape)
    const label = SitemapItemFactory.getLabelProperties(displayData.label, icon)
    return new SitemapItemBase(
      dataObject,
      item,
      isDisplayed,
      icon,
      label,
      shape,
      item.coordinates,
    )
  }

  public static getIconProperties(
    icon: ISitemapPin,
    shape?: ShapeProperties,
  ): SitemapIconProperties {
    return (
      icon &&
      new SitemapIconProperties(
        !icon.isHidden,
        icon.position || (shape && shape.calculateIconPosition()),
      )
    )
  }

  public static getLabelProperties(
    label: ISitemapTextBox,
    icon?: SitemapIconProperties,
  ): SitemapLabelProperties {
    return (
      label &&
      new SitemapLabelProperties(
        label.fontSize,
        label.isTextBoxDisplayed,
        !label.isHidden,
        label.position || (icon && icon.calculateLabelPosition()),
        label.color,
      )
    )
  }

  public static getShapeProperties(
    shape: ISitemapItemShapeInterface,
    defaultColor: string,
  ): ShapeProperties {
    if (!shape) {
      return null
    }

    switch (shape.type) {
      case SitemapItemShapeType.Polyline:
        return SitemapItemFactory.getPolyLineProperties(
          shape as ISitemapPolyline,
          defaultColor,
        )
      case SitemapItemShapeType.Rectangle:
        return SitemapItemFactory.getRectangleProperties(
          shape as ISitemapRectangle,
          defaultColor,
        )
      case SitemapItemShapeType.Circle:
        return SitemapItemFactory.getCircleProperties(
          shape as ISitemapCircle,
          defaultColor,
        )
    }
  }

  public static getPolyLineProperties(
    polyline: ISitemapPolyline,
    defaultColor: string,
  ): SitemapPolyLineProperties {
    return new SitemapPolyLineProperties(
      polyline.lineWidth,
      polyline.lineColor || defaultColor,
      polyline.fillColor || defaultColor,
      polyline.fillOpacity,
      polyline.isClosed,
      polyline.arrowPosition,
      polyline.points,
      polyline.isDisplayed,
    )
  }

  public static getRectangleProperties(
    rectangle: ISitemapRectangle,
    defaultColor: string,
  ): SitemapRectangleProperties {
    return new SitemapRectangleProperties(
      rectangle.lineWidth,
      rectangle.lineColor || defaultColor,
      rectangle.fillColor || defaultColor,
      rectangle.fillOpacity,
      rectangle.position,
      rectangle.width,
      rectangle.height,
      rectangle.rotation,
      rectangle.isDisplayed,
    )
  }

  public static getCircleProperties(
    circle: ISitemapCircle,
    defaultColor: string,
  ): SitemapCircleProperties {
    return new SitemapCircleProperties(
      circle.lineWidth,
      circle.lineColor || defaultColor,
      circle.fillColor || defaultColor,
      circle.fillOpacity,
      circle.position,
      circle.radius,
      circle.isDivided,
      circle.divisionStartAngle,
      circle.divisionEndAngle,
      circle.isDisplayed,
    )
  }

  public static createDataLessItem(
    type: SitemapItemType,
    projectId: string,
    existingNames: string[],
  ) {
    const sitemapItem = SitemapItemFactory.createSitemapItem(projectId, type)
    sitemapItem.name = SitemapItemFactory.getValidatedName(
      sitemapItem.name,
      existingNames,
    )
    return new SitemapItemBase(
      null,
      sitemapItem,
      true,
      null,
      SitemapItemFactory.getSitemapItemLabel(sitemapItem),
      SitemapItemFactory.getSitemapItemShape(sitemapItem),
    )
  }

  public static getSitemapItemLabel(item: SitemapItem) {
    if (item.type === SitemapItemType.TextBox) {
      return new SitemapLabelProperties()
    }
  }

  public static getSitemapItemShape(item: SitemapItem) {
    if (item.type === SitemapItemType.Line) {
      return new SitemapPolyLineProperties(null, item.color, item.color)
    }
  }

  public static createDataItem(
    type: LocationType,
    iconName: SitemapAttributeIcon,
    position: IPosition,
    projectId: string,
    existingNames: string[],
    color?: string,
    isReferenced?: boolean,
  ) {
    const dataObject = SitemapItemFactory.createDataAttribute(
      type,
      projectId,
      iconName,
      null,
      color,
    )
    dataObject.name = SitemapItemFactory.getValidatedName(
      dataObject.name,
      existingNames,
    )

    const sitemapItem = SitemapItemFactory.createSitemapItem(
      projectId,
      null,
      color,
      isReferenced,
    )

    const icon = new SitemapIconProperties(true, position)
    const label = new SitemapLabelProperties(
      null,
      false,
      type !== LocationType.LogisticsObject,
      icon.calculateLabelPosition(),
    )

    return new SitemapItemBase(dataObject, sitemapItem, true, icon, label)
  }

  public static createDataAttribute(
    type: LocationType,
    projectId: string,
    iconName: SitemapAttributeIcon,
    typeName?: string,
    color?: string,
  ) {
    const Constructor = DATA_OBJECTS_CONSTRUCTOR_BY_TYPE[type]
    return (
      Constructor &&
      new Constructor(
        null,
        `New ${typeName || type}`,
        color || DEFAULT_SITEMAP_ITEM_COLOR,
        iconName || ICON_BY_ATTRIBUTE_TYPE[type],
        projectId,
      )
    )
  }

  public static getValidatedName(name: string, existingNames: string[]) {
    let validatedName = name
    let counter = 1
    while (existingNames.includes(validatedName)) {
      counter++
      validatedName = `${name} ${counter}`
    }
    return validatedName
  }

  private static createSitemapItem(
    projectId: string,
    type?: SitemapItemType,
    color?: string,
    isReferenced?: boolean,
  ) {
    return new SitemapItem(
      null,
      NEW_SITEMAP_ITEM_NAME_BY_TYPE[type] || EMPTY_SITEMAP_ITEM_NAME,
      color || DEFAULT_SITEMAP_ITEM_COLOR,
      type,
      ICON_BY_SITEMAP_ITEM_TYPE[type],
      projectId,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      isReferenced,
    )
  }
}

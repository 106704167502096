import React from 'react'

import { inject, observer } from 'mobx-react'

import DateSelector from '~/client/src/desktop/components/Filters/DateSelector/DateSelector'
import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import BaseHeaderBar from '~/client/src/desktop/components/HeaderBar/HeaderBar'
import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import FormReportGroupingOption, {
  formReportGroupingOptionList,
  getFormReportGroupingOptionTranslatorKey,
} from '~/client/src/shared/enums/FormReportGroupingOption'
import ScanStationGroupingOption, {
  getScanStationGroupingOptionTranslatorKey,
  scanStationGroupingOptionList,
} from '~/client/src/shared/enums/ScanStationGroupingOption'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import ReportsStore from '../../Reports.store'
import DeliveryReportListStore from '../ReportsList/DeliveryReport/DeliveryReportList.store'
import FormReportListStore from '../ReportsList/FormReportList/FormReportList.store'
import ScanStationListStore from '../ReportsList/ScanStation/ScanStationList.store'
import ReportsHeaderStore from './ReportsHeader.store'
import ReportsDownload from './components/ReportsDownload/ReportsDownload'
import DeliveryReportFilterStore from './components/ReportsFilter/DeliveryReportFilter.store'
import FormReportFilterStore from './components/ReportsFilter/FormReportFilter.store'
import ReportsFilter from './components/ReportsFilter/ReportsFilter'
import ScanStationFilterStore from './components/ReportsFilter/ScanStationFilter.store'
import DeliveryReportGroupingOptionsStore from './components/ReportsGroupingOptionsStore/DeliveryReportGroupingOptions.store'
import ReportsGroupingOptions from './components/ReportsGroupingOptionsStore/ReportsGroupingOptions'
import ReportsGroupingOptionsStore from './components/ReportsGroupingOptionsStore/ReportsGroupingOptions.store'

const datePickerStates = [
  TwoMonthsDatePickerMode.ONE_DAY,
  TwoMonthsDatePickerMode.WEEK,
  TwoMonthsDatePickerMode.FLOATING_THREE_WEEKS,
  TwoMonthsDatePickerMode.MONTH,
  TwoMonthsDatePickerMode.SIX_WEEKS,
  TwoMonthsDatePickerMode.THREE_MONTHS,
  TwoMonthsDatePickerMode.YEAR,
  TwoMonthsDatePickerMode.PROJECT,
]

interface IProps {
  eventsStore?: DesktopEventStore
  state?: DesktopInitialState
  reportsStore: ReportsStore
  scanStationListStore: ScanStationListStore
  formReportListStore: FormReportListStore
  deliveryReportListStore: DeliveryReportListStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
  scannersStore?: ScannersStore
  companiesStore?: CompaniesStore
  projectsStore?: ProjectsStore
  projectDateStore?: ProjectDateStore
  locationAttributesStore?: LocationAttributesStore
  tagsStore?: TagsStore
}

@inject(
  'state',
  'eventsStore',
  'projectMembersStore',
  'userProjectsStore',
  'scannersStore',
  'companiesStore',
  'projectsStore',
  'projectDateStore',
  'locationAttributesStore',
  'tagsStore',
)
@observer
export default class ReportsHeaderBar extends BaseHeaderBar<IProps> {
  protected store: ReportsHeaderStore = null
  private readonly scanStationGroupingOptionsStore: ReportsGroupingOptionsStore =
    null
  private readonly formReportGroupingOptionsStore: ReportsGroupingOptionsStore =
    null
  private readonly deliveryReportGroupingOptionsStore: DeliveryReportGroupingOptionsStore =
    null
  private readonly scanStationFilterStore: ScanStationFilterStore = null
  private readonly formReportFilterStore: FormReportFilterStore = null
  private readonly deliveryReportFilterStore: DeliveryReportFilterStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ReportsHeaderStore(
      props.state,
      props.reportsStore,
      props.scanStationListStore,
      props.formReportListStore,
      props.deliveryReportListStore,
    )

    const { eventsStore } = props

    const { onShowGroupByChanged, onShowChanged } = this.store

    this.formReportFilterStore = new FormReportFilterStore(
      props.eventsStore,
      props.reportsStore,
      props.formReportListStore,
      onShowChanged,
      props.scannersStore,
      props.companiesStore,
      props.projectMembersStore,
      props.userProjectsStore,
      props.locationAttributesStore,
      props.tagsStore,
    )

    this.deliveryReportFilterStore = new DeliveryReportFilterStore(
      props.eventsStore,
      props.reportsStore,
      props.deliveryReportListStore,
      onShowChanged,
      props.scannersStore,
      props.companiesStore,
      props.projectMembersStore,
      props.userProjectsStore,
      props.locationAttributesStore,
      props.tagsStore,
    )

    this.scanStationFilterStore = new ScanStationFilterStore(
      props.eventsStore,
      props.reportsStore,
      props.scanStationListStore,
      onShowChanged,
      props.scannersStore,
      props.companiesStore,
      props.projectMembersStore,
      props.userProjectsStore,
      props.locationAttributesStore,
      props.tagsStore,
    )

    this.scanStationGroupingOptionsStore = new ReportsGroupingOptionsStore(
      eventsStore,
      onShowGroupByChanged,
      NOOP,
      eventsStore.appState.scanStationFilters,
      scanStationGroupingOptionList,
      ScanStationGroupingOption.DAY,
      eventsStore.appState.scanStationFilterSettings.desktopFilterMap,
      getScanStationGroupingOptionTranslatorKey,
    )

    this.formReportGroupingOptionsStore = new ReportsGroupingOptionsStore(
      eventsStore,
      onShowGroupByChanged,
      NOOP,
      eventsStore.appState.formReportFilters,
      formReportGroupingOptionList,
      FormReportGroupingOption.NONE,
      eventsStore.appState.formReportFilterSettings.desktopFilterMap,
      getFormReportGroupingOptionTranslatorKey,
    )

    this.deliveryReportGroupingOptionsStore =
      new DeliveryReportGroupingOptionsStore(
        eventsStore,
        onShowGroupByChanged,
        NOOP,
      )
  }

  protected renderLeftSection() {
    const { forceSearchClose, onShowSearchChanged } = this.store
    const { isScanStation, isFormReport, isDeliveryReport } =
      this.props.reportsStore

    return (
      <div className="row x-start full-height left-bar no-grow">
        {isScanStation && (
          <EntityNameFilter
            filters={this.props.state.scanStationFilters}
            forceClose={forceSearchClose}
            onShowChanged={onShowSearchChanged}
          />
        )}
        {isFormReport && (
          <EntityNameFilter
            filters={this.props.state.formReportFilters}
            forceClose={forceSearchClose}
            onShowChanged={onShowSearchChanged}
          />
        )}
        {isDeliveryReport && (
          <EntityNameFilter
            filters={this.props.state.deliveryFilters}
            forceClose={forceSearchClose}
            onShowChanged={onShowSearchChanged}
          />
        )}
        {this.renderGroupingOptions()}
      </div>
    )
  }

  protected renderCenterSection() {
    const { eventsStore } = this.props
    const { isScanStation, isFormReport, isDeliveryReport, activeReportView } =
      this.props.reportsStore
    return (
      <>
        {isScanStation && (
          <ReportsFilter
            store={this.scanStationFilterStore}
            forceCloseMap={this.store.forceCloseMap}
            eventsStore={eventsStore}
            filters={eventsStore.appState.scanStationFilters}
            activeReportViewId={activeReportView?.id}
          />
        )}
        {isFormReport && (
          <ReportsFilter
            store={this.formReportFilterStore}
            forceCloseMap={this.store.forceCloseMap}
            eventsStore={eventsStore}
            filters={eventsStore.appState.formReportFilters}
            activeReportViewId={activeReportView?.id}
          />
        )}
        {isDeliveryReport && (
          <ReportsFilter
            store={this.deliveryReportFilterStore}
            forceCloseMap={this.store.forceCloseMap}
            eventsStore={eventsStore}
            filters={eventsStore.appState.deliveryReportFilters}
            activeReportViewId={activeReportView?.id}
          />
        )}

        <div className="row full-height x-start scrollable-section">
          {this.renderDatePicker()}
        </div>
      </>
    )
  }

  private renderDatePicker() {
    const {
      projectsStore,
      projectDateStore,
      reportsStore: {
        dateFilters,
        selectedDatePickerMode,
        changeDatePickerMode,
      },
    } = this.props
    const { forceDatePickerClose, onShowDatePickerChanged } = this.store

    return (
      <DateSelector
        filters={dateFilters}
        forceClose={forceDatePickerClose}
        shouldUseRelativePosition={false}
        onShowChanged={onShowDatePickerChanged}
        mode={selectedDatePickerMode}
        projectsStore={projectsStore}
        projectDateStore={projectDateStore}
        shouldUseOtherDatesForFullProjectMode={true}
        shouldShowTodayButton={true}
        shouldRenderDateModes={true}
        datePickerModes={datePickerStates}
        onOptionModeClick={changeDatePickerMode}
        shouldHideTopButtons={true}
      />
    )
  }

  protected renderViewToggle() {
    return null
  }

  protected renderRightSection() {
    const { reportTitle, reportTemplate, reportData } = this.store
    const { activeReportView, startDate, endDate, isDateFilterActive } =
      this.props.reportsStore

    return (
      <ReportsDownload
        title={reportTitle}
        templateId={reportTemplate}
        activeReportViewType={activeReportView?.type}
        reportData={reportData}
        dateFrom={startDate}
        dateTo={endDate}
        isProjectRangeActive={!isDateFilterActive}
      />
    )
  }

  private renderGroupingOptions() {
    const { isScanStation, isFormReport, isDeliveryReport } =
      this.props.reportsStore
    const { forceGroupByClose } = this.store

    return (
      <>
        {isScanStation && (
          <ReportsGroupingOptions
            store={this.scanStationGroupingOptionsStore}
            forceClose={forceGroupByClose}
          />
        )}
        {isFormReport && (
          <ReportsGroupingOptions
            store={this.formReportGroupingOptionsStore}
            forceClose={forceGroupByClose}
          />
        )}
        {isDeliveryReport && (
          <ReportsGroupingOptions
            store={this.deliveryReportGroupingOptionsStore}
            forceClose={forceGroupByClose}
          />
        )}
      </>
    )
  }
}

import LogisticsGroupingOption from '~/client/src/shared/enums/LogisticsGroupingOption'

import FilterInfo from '../../../shared/stores/substates/FilterInfo'

export default class LogisticsFiltersSettingState {
  public desktopFilterMap: { [key: string]: FilterInfo } = {
    [LogisticsGroupingOption.APP]: new FilterInfo(
      LogisticsGroupingOption.APP,
      false,
    ),
    [LogisticsGroupingOption.LBS]: new FilterInfo(
      LogisticsGroupingOption.LBS,
      false,
    ),
    [LogisticsGroupingOption.STATUS]: new FilterInfo(
      LogisticsGroupingOption.STATUS,
      false,
    ),
    [LogisticsGroupingOption.COMPANY]: new FilterInfo(
      LogisticsGroupingOption.COMPANY,
      false,
    ),
    [LogisticsGroupingOption.DATE]: new FilterInfo(
      LogisticsGroupingOption.DATE,
      false,
    ),
    [LogisticsGroupingOption.RESPONSIBLE]: new FilterInfo(
      LogisticsGroupingOption.RESPONSIBLE,
      false,
    ),
  }
}

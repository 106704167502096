import { action, observable } from 'mobx'

import { IFilterGroup, IOrderedSitemap } from '~/client/graph'
import DesktopInitialState, {
  IBandOption,
} from '~/client/src/desktop/stores/DesktopInitialState'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import EventTypes from '~/client/src/shared/stores/EventStore/eventTypes'

import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'

export const EMPTY_OPTION = ''
export const INDENT_OPTION_MAIN_ID = 'indent'

// localization: translated

export interface ICustomBandOption {
  name: string
  caption: string
}

export default abstract class BaseGroupingOptionsStore {
  public maxCustomBandsLevel: number
  public leftCaptionKey: KnownTranslatorKeys
  public rightCaptionKey: KnownTranslatorKeys
  public shouldShowEmptyBandsSection: boolean = false
  @observable public isShown: boolean = false
  @observable public showEmptyBandsDraft: boolean = false
  @observable public selectedBandsOptionIdDraft: string
  @observable public groupLevels: string[] = [EMPTY_OPTION]

  public constructor(
    protected eventsStore: DesktopEventStore,
    protected readonly onShowChanged: (isShown: boolean) => void,
  ) {}

  public abstract get groupByCaption(): string

  @action.bound
  public toggleGroupingOptions() {
    this.isShown = !this.isShown
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  @action.bound
  public hideGroupingOptions() {
    this.isShown = false
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onActionRequest(eventContext: EventContext) {
    return
  }

  public initPopup() {
    return
  }

  public setSelectedBand() {
    return
  }

  public abstract get bandsOptions(): IBandOption[]

  public get areDefaultOptionsSelected(): boolean {
    return false
  }

  public resetGroupingOptions() {
    return
  }

  @action.bound
  public changeSelectedBands(event: any) {
    this.selectedBandsOptionIdDraft = event.target.value
  }

  public onShowEmptyWbsChange() {
    this.showEmptyBandsDraft = !this.showEmptyBandsDraft
  }

  public abstract applyGroupingOptions(): void

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public shouldIndent = (id: string): boolean => {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public deleteCustomGrouping = (index: number) => {
    return
  }

  public saveSelectedLevels(
    eventName: EventTypes,
    savedGroupsName: string,
    sitemaps: IOrderedSitemap[],
    savedEntitiesGroups: IFilterGroup[] = [],
  ) {
    const group = {
      filterNames: this.groupLevels.filter(v => !!v),
    } as IFilterGroup
    const groupsToSave = [...savedEntitiesGroups, group]

    this.saveGroupingConfig(eventName, savedGroupsName, sitemaps, groupsToSave)

    this.groupLevels = [EMPTY_OPTION]
  }

  protected saveGroupingConfig(
    eventName: EventTypes,
    savedGroupsName: string,
    sitemaps: IOrderedSitemap[],
    savedEntitiesGroups: IFilterGroup[],
  ) {
    const configurations = {
      sitemaps,
      [savedGroupsName]: savedEntitiesGroups,
    }

    this.eventsStore.dispatch(eventName, configurations)
  }

  public get options(): ICustomBandOption[] {
    return
  }

  public get isLoading(): boolean {
    return false
  }

  public get doesGroupingAlreadyExist(): boolean {
    return false
  }

  protected get state(): DesktopInitialState {
    return this.eventsStore.appState
  }
}

import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 20

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const ICON_PATH =
  'M12 0c2.205 0 4.277.874 5.835 2.462 2.884 2.938 3.242 8.464.766 11.827L12.001 24l-6.61-9.725c-2.466-3.35-2.108-8.876.776-11.813C7.725.874 9.797 0 12 0zm.072 4.871c-1.896 0-3.438 1.584-3.438 3.53 0 1.945 1.542 3.53 3.438 3.53 1.896 0 3.437-1.585 3.437-3.53 0-1.946-1.541-3.53-3.437-3.53z'

const STROKE_WIDTH = 1

const OFFSET_X = -3
const OFFSET_Y = -2
const PATH_SCALE = 0.6
const { getHEXColor } = ThemeMode

const KonvaLogisticsObject = ({ color, children, ...rest }) => {
  const halfSize = ICON_SIZE / 2
  return (
    <Group {...rest} offsetX={halfSize} offsetY={halfSize}>
      {children}
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={ICON_PATH}
        fill={getHEXColor(Colors.neutral100)}
        scaleX={PATH_SCALE}
        scaleY={PATH_SCALE}
        offsetX={OFFSET_X}
        offsetY={OFFSET_Y}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
    </Group>
  )
}

export default KonvaLogisticsObject

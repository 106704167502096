import { action, observable } from 'mobx'

import { ILocationClosureInterval } from '~/client/graph'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import SitemapItemsSetupStore from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/stores/SitemapItemsSetup.store'
import SitemapSetupStore from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/stores/SitemapsSetup.store'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Closure from '~/client/src/shared/models/Closure'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapItem from '~/client/src/shared/models/SitemapItem'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import { FORBIDDEN_SITE_NAME } from '~/client/src/shared/stores/domain/LocationBase.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore, {
  isWithinRange,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import IHierarchyParent from '~/client/src/shared/types/IHierarchyParent'
import IMsDateInterval from '~/client/src/shared/types/IMsDateInterval'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'
import { areObjectsEqual } from '~/client/src/shared/utils/util'

export default class ObjectCellStore {
  @observable public shouldShowLevelsClosureModal = false
  @observable public shouldShowPermittedCompaniesModal = false
  @observable public shouldShowBookingDurationModal = false
  @observable public shouldShowOverlappingBookingsModal = false
  @observable public numberValue: number = 1
  @observable public isMenuOpened: boolean = false
  @observable public draftName: string = EMPTY_STRING

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly closuresStore: ClosuresStore,
    private readonly datePickerStore: TwoMonthsDatePickerStore,
    private readonly onChange: () => void,
    private obj: LocationAttributeBase,
    private readonly sitemapItemsSetupStore: SitemapItemsSetupStore,
    private readonly syncRestrictionsStore: SyncRestrictionsStore,
    private readonly sitemapSetupStore: SitemapSetupStore,
  ) {
    this.setObjectName(obj?.name)
  }

  public reinit(obj: LocationAttributeBase) {
    this.obj = obj
    this.setObjectName(obj?.name)
  }

  public isClosureIntervalActive = ({
    startDate,
    endDate,
  }: ILocationClosureInterval) => {
    const { startOfDay, endOfDay } = this.projectDateStore

    return isWithinRange(Date.now(), startOfDay(startDate), endOfDay(endDate))
  }

  @action.bound
  public toggleBookingDurationModal() {
    this.shouldShowBookingDurationModal = !this.shouldShowBookingDurationModal
  }

  @action.bound
  public hideBookingDurationModal() {
    this.shouldShowBookingDurationModal = false
  }

  @action.bound
  public toggleOverlappingBookingsModal() {
    this.shouldShowOverlappingBookingsModal =
      !this.shouldShowOverlappingBookingsModal
  }

  @action.bound
  public hideOverlappingBookingsModal() {
    this.shouldShowOverlappingBookingsModal = false
  }

  @action.bound
  public togglePermittedCompaniesModal() {
    this.shouldShowPermittedCompaniesModal =
      !this.shouldShowPermittedCompaniesModal
  }

  @action.bound
  public hidePermittedCompaniesModal() {
    this.shouldShowPermittedCompaniesModal = false
  }

  @action.bound
  public permitCompanies(permittedCompanies: string[]) {
    this.hidePermittedCompaniesModal()
    this.obj.permittedCompanies = permittedCompanies

    this.saveChanges()
  }

  @action.bound
  public toggleLevelsClosureModal() {
    this.shouldShowLevelsClosureModal = !this.shouldShowLevelsClosureModal
  }

  @action.bound
  public hideLevelsClosureModal() {
    this.shouldShowLevelsClosureModal = false
  }

  @action.bound
  public updateObjectColor(color: string) {
    this.selectedSitemapItem.setAllColors(color)
    this.obj.color = color

    this.saveChanges()
    this.hideMenu()
  }

  @action.bound
  public updateObjectParent(parent: IHierarchyParent) {
    if (areObjectsEqual(this.obj.parent || {}, parent || {})) {
      return
    }
    const copiedObj = this.obj.copy()
    copiedObj.parent = parent

    this.saveChanges(copiedObj)
    this.syncRestrictionsStore.updateRestrictionsForItem(copiedObj)
    this.hideMenu()

    this.obj.parent = copiedObj.parent

    this.sitemapItemsSetupStore.updateAccessibleLevelsByLevel(this.obj)
  }

  @action.bound
  public setObjectName(name: string) {
    this.draftName = name
  }

  @action.bound
  public updateObjectName() {
    if (this.draftName === FORBIDDEN_SITE_NAME) {
      return
    }
    if (this.draftName && this.obj.name !== this.draftName) {
      this.obj.name = this.draftName
      this.saveChanges()
      this.hideMenu()
    }
  }

  @action.bound
  public saveNameAndHideMenu() {
    if (
      this.draftName &&
      this.obj.name !== this.draftName &&
      this.draftName !== FORBIDDEN_SITE_NAME
    ) {
      this.obj.name = this.draftName
      this.saveChanges()
    }

    this.draftName = this.obj.name
    this.hideMenu()
  }

  @action.bound
  public removeOperatingInterval(interval: IMsDateInterval) {
    this.obj.operatingIntervals = this.obj.operatingIntervals.filter(
      i => i !== interval,
    )

    this.saveChanges()
  }

  @action.bound
  public removeClosure(closure: Closure) {
    this.closuresStore.deleteClosures([closure.id])
    this.onChange()
  }

  @action.bound
  public async showMenu() {
    await this.sitemapItemsSetupStore.selectSitemapItem(
      this.selectedSitemapItem,
    )
    this.isMenuOpened = true
  }

  @action.bound
  public hideMenu() {
    this.sitemapItemsSetupStore.deselectSitemapItem()
    this.closeMenu()
  }

  @action.bound
  public closeMenu() {
    this.isMenuOpened = false
  }

  @action.bound
  public closeAllMenus() {
    this.sitemapItemsSetupStore.deselectSitemapItem()
    this.closeMenu()
  }

  @action.bound
  public updateObjectItemColor(color: string) {
    const {
      selectedSitemapItem: { sitemapItem },
    } = this.sitemapItemsSetupStore

    if (!sitemapItem) {
      this.updateObjectColor(color)
      return
    }

    this.obj.color = color
    this.closeMenu()

    const sitemapsByDisplayedItems = this.sitemapSetupStore.allSitemaps.filter(
      sm =>
        sm.hasDisplayData(sitemapItem.id) && sm.isItemDisplayed(sitemapItem.id),
    )

    this.updateItemsBySitemaps(sitemapsByDisplayedItems, color, sitemapItem)

    this.updateObjectColor(color)
  }

  @action.bound
  public updateItemsBySitemaps(
    sitemaps: Sitemap[],
    color: string,
    sitemapItem: SitemapItem,
  ) {
    if (!sitemaps.length) {
      this.updateAllSitemapItems(color, sitemapItem)
      return
    }

    sitemaps.forEach(sitemap => {
      this.sitemapSetupStore.selectSitemap(sitemap, false)
      this.updateAllSitemapItems(color, sitemapItem)
    })
  }

  @action.bound
  public updateAllSitemapItems(color: string, sitemapItem: SitemapItem) {
    const { saveSitemapItem, sitemapItems } = this.sitemapItemsSetupStore

    const items = sitemapItems.filter(
      item => item.sitemapItem.id === sitemapItem.id,
    )

    items.forEach(async item => {
      item.setAllColors(color)
      await saveSitemapItem(item)
    })
  }

  @action.bound
  public setBookingDurationValue(propKey: string, value: string) {
    this.shouldShowBookingDurationModal = false
    const newValue = +value

    if (this.obj[propKey] === newValue) {
      return
    }

    this.obj[propKey] = newValue

    this.saveChanges()
  }

  @action.bound
  public setOverlappingBookingsValue(propKey: string, value: string) {
    this.shouldShowOverlappingBookingsModal = false
    const newValue = Math.ceil(+value)

    if (this.obj[propKey] === newValue) {
      return
    }

    this.obj[propKey] = newValue

    this.saveChanges()
  }

  @action.bound
  public addClosure(isDaily: boolean, startDate: Date, endDate: Date) {
    if (!startDate) {
      return
    }

    const closure = Closure.fromDto({
      locationId: this.obj.id,
      closureInterval: {
        startDate: startDate.getTime(),
        endDate: endDate
          ? endDate.getTime()
          : this.projectDateStore.endOfDay(startDate).getTime(),
        isDaily,
      },
      projectId: this.obj.projectId,
    })

    this.closuresStore.saveClosures([closure])
    this.onChange()
  }

  @action.bound
  public addOperatingInterval(startDate: Date, endDate: Date) {
    if (!startDate) {
      return
    }
    if (!this.obj.operatingIntervals) {
      this.obj.operatingIntervals = []
    }
    this.obj.operatingIntervals.push({
      startDate: startDate.getTime(),
      endDate: endDate
        ? endDate.getTime()
        : this.projectDateStore.endOfDay(startDate).getTime(),
    })

    this.saveChanges()
  }

  @action.bound
  public showOperatingIntervalPicker() {
    const { setHours, projectStartHourMinutes, projectEndHourMinutes } =
      this.projectDateStore

    const startDate = setHours(
      new Date(),
      projectStartHourMinutes[0],
      projectStartHourMinutes[1],
    )
    const endDate = setHours(
      new Date(),
      projectEndHourMinutes[0],
      projectEndHourMinutes[1],
    )

    this.datePickerStore.showWithOptions({
      initialRange: { startDate, endDate },
      handler: this.addOperatingInterval,
      isSeparatedMonths: true,
      shouldHideCalendar: true,
      isTimeSensitive: true,
    })
  }

  public showClosureDatePicker = () => {
    this.showClosureIntervalPicker()
  }

  public showDailyClosurePicker = () => {
    this.showClosureIntervalPicker(true)
  }

  public formatOperatingInterval = ({
    startDate,
    endDate,
  }: IMsDateInterval): string => {
    const { getTimeIntervalPerDayToDisplay } = this.projectDateStore

    return `${getTimeIntervalPerDayToDisplay(startDate, endDate)} / ${
      Localization.translator.monSat
    }`
  }

  public formatLevelsClosureInterval = ({
    startDate,
    endDate,
  }: IMsDateInterval): string => {
    return this.formatClosureInterval({ startDate, endDate })
  }

  public formatClosureInterval = ({
    startDate,
    endDate,
    isDaily,
  }: ILocationClosureInterval): string => {
    const {
      getWeekdayMonthDayAndTimeToDisplay,
      getWeekdayMonthDayYearAndTimeToDisplay,
      getTimeIntervalPerDayToDisplay,
      isThisYear,
    } = this.projectDateStore

    if (isDaily) {
      return `${getTimeIntervalPerDayToDisplay(startDate, endDate)} / ${
        Localization.translator.daily
      }`
    }

    const getDateToDisplay = date => {
      return isThisYear(date)
        ? getWeekdayMonthDayAndTimeToDisplay(date)
        : getWeekdayMonthDayYearAndTimeToDisplay(date)
    }
    const startDateFormatted = getDateToDisplay(startDate)
    const endDateFormatted = endDate
      ? getDateToDisplay(endDate)
      : Localization.translator.forTheFuture

    return `${startDateFormatted} - ${endDateFormatted}`
  }

  public saveChanges = (obj?: LocationAttributeBase) => {
    this.objStore.saveItem(obj || this.obj)
    this.onChange()
  }

  @action.bound
  private showClosureIntervalPicker(isRepeatedDaily?: boolean) {
    const { setHours, projectEndHourMinutes } = this.projectDateStore

    const startDate = new Date()
    const endDate = setHours(
      new Date(),
      projectEndHourMinutes[0],
      projectEndHourMinutes[1],
    )

    this.datePickerStore.showWithOptions({
      initialRange: { startDate, endDate },
      handler: this.addClosure.bind(this, isRepeatedDaily),
      isSeparatedMonths: true,
      shouldHideCalendar: isRepeatedDaily,
      isTimeSensitive: true,
    })
  }

  private get objStore(): any {
    return this.tagsStore.tagStoreByTagTypeMap[this.obj.type]
  }

  private get selectedSitemapItem(): SitemapItemBase {
    return this.sitemapItemsSetupStore.sitemapItems.find(
      sitemapItem => sitemapItem.dataObject.id === this.obj.id,
    )
  }
}

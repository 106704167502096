import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { Stage } from 'konva/types/Stage'
import { action, computed, observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'

import BaseSitemap from '~/client/src/shared/components/BaseSitemap/BaseSitemap'
import SitemapElementsWrapper from '~/client/src/shared/components/SitemapElementsWrapper'
import SitemapItems, {
  MAX_PERCENT,
} from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'

import Activity from '../../models/Activity'
import EventsStore from '../../stores/EventStore/Events.store'
import ActivitiesStore from '../../stores/domain/Activities.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import BaseActivityListStore from '../../stores/ui/BaseActivityList.store'
import { clickPosition } from '../../utils/SitemapCalculationHelpers'
import { ViewMode } from '../DraggableBar/DraggableBar'
import KonvaWorkflowActivityPin from '../Konva/KonvaWorkflowActivityPin'
import MapBoxEditorStore from '../MapBoxEditor/MapBoxEditor.store'
import SitemapActivitiesDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapActivitiesDraggableModal'
import SitemapItemBase from '../SitemapHelpers/models/SitemapItemBase'
import ActivitiesSitemapSetUpStore from './ActivitiesSitemapSetUp.store'

const SAME_POSITION_OFFSET = 20
const OFFSET_Y = 4

interface IProps {
  sitemapId: string
  activityListStore: BaseActivityListStore
  textboxesCache: ICanvasImageCache
  store: ActivitiesSitemapSetUpStore
  mapBoxEditorStore: MapBoxEditorStore

  openActivity: (code: string) => void

  selectViewMode?: (mode: ViewMode) => void

  eventsStore?: EventsStore
  basemapsStore?: BasemapsStore
  activitiesStore?: ActivitiesStore
  sitemapsStore?: SitemapsStore
}

@inject('eventsStore', 'basemapsStore', 'activitiesStore', 'sitemapsStore')
@observer
export default class ActivitiesSitemap extends React.Component<IProps> {
  @observable public swipeableItemIndex: number = 0
  @observable public swipeableItemId: string = null
  @observable private sitemap: BaseSitemap
  @observable private containerRef: HTMLElement = null
  public static contextType = MobXProviderContext

  public constructor(props: IProps) {
    super(props)

    const { sitemapId, mapBoxEditorStore, store, sitemapsStore } = props
    store.deselectAll()
    const sitemap = sitemapsStore.byId.get(sitemapId)
    store.selectSitemap(sitemap)

    mapBoxEditorStore.setViewportFromAdress()
    mapBoxEditorStore.setDefaultMapMode()
    mapBoxEditorStore.hideToggleMapMode()
  }

  public componentDidUpdate(props: IProps) {
    const { sitemapId, mapBoxEditorStore, store, sitemapsStore } = this.props
    if (sitemapId !== props.sitemapId) {
      store.deselectAll()
      const sitemap = sitemapsStore.byId.get(sitemapId)
      store.selectSitemap(sitemap)
      mapBoxEditorStore.setViewportFromAdress()
      mapBoxEditorStore.setItems()
      mapBoxEditorStore.resetMapboxInfo()
      mapBoxEditorStore.removeRefs()
    }
  }

  public render() {
    return (
      <div
        className="full-height full-width relative activities-map-container"
        ref={this.setContainerRef}
      >
        {this.sitemapElement}
        {this.draggableModal}
      </div>
    )
  }

  private get draggableModal(): JSX.Element {
    const {
      openActivity,
      activitiesStore: { selection: displayedActivityId },
    } = this.props

    const {
      selectedAttribute,
      selectedActivities,
      areActivitiesSelected,
      deselectAll,
      topOffset,
      leftOffset,
    } = this.props.store

    return (
      areActivitiesSelected &&
      !!this.containerRef && (
        <SitemapActivitiesDraggableModal
          item={selectedAttribute}
          containerRef={this.containerRef}
          selectedActivities={selectedActivities}
          selectedActivityId={displayedActivityId}
          openActivity={openActivity}
          onClose={deselectAll}
          topOffset={topOffset}
          leftOffset={leftOffset}
        />
      )
    )
  }

  private get sitemapElement(): JSX.Element {
    const {
      basemapsStore,
      store: { sitemapUrl, deselectAll, sitemap },
      mapBoxEditorStore,
      eventsStore: { appState },
    } = this.props

    const {
      isDraggingMode,
      isImageRubberMode,
      shouldShowImage,
      sourceBounds,
      setViewport,
      viewport,
      mapViewport,
      opacity,
      sitemapStage,
    } = mapBoxEditorStore

    return (
      <>
        <BaseSitemap
          sitemapUrl={sitemapUrl}
          isDraggable={true}
          onClick={deselectAll}
          onTouch={this.onSitemapTouch}
          setSitemap={this.setSitemapStage}
          isDraggingMode={isDraggingMode}
          selectedSitemap={sitemap}
          opacity={opacity}
          mapBoxEditorStore={mapBoxEditorStore}
          mapViewport={viewport || mapViewport}
          setViewport={setViewport}
          shouldShowImage={shouldShowImage}
          isImageRubberMode={isImageRubberMode}
          sourceBounds={sourceBounds}
          basemapsStore={basemapsStore}
          projectAddress={appState.projectAddress}
          ref={this.setSitemapRef}
        >
          {({ width, height, key, isMainSitemap }) => {
            if (!this.sitemap || (sitemap.isReferenced && !sitemapStage)) {
              return null
            }

            return (
              <Provider {...this.context}>
                {this.renderSitemapChildren(width, height, key, isMainSitemap)}
              </Provider>
            )
          }}
        </BaseSitemap>
      </>
    )
  }

  private renderSitemapChildren = (
    width: number,
    height: number,
    key: string,
    isMainSitemap?: boolean,
  ): JSX.Element => {
    const {
      textboxesCache,
      mapBoxEditorStore,
      store: { displayedSitemapItems },
      sitemapId,
      sitemapsStore,
    } = this.props
    const { secondaryItems, itemsFiltering } = mapBoxEditorStore
    const sitemap = sitemapsStore.byId.get(sitemapId)
    const referencedChildren = isMainSitemap
      ? itemsFiltering
      : secondaryItems[key]
    const itemsToShow = sitemap.isReferenced
      ? referencedChildren
      : displayedSitemapItems

    return (
      <>
        <SitemapItems
          className={toggleClass('unclickable-element', !isMainSitemap)}
          items={itemsToShow || []}
          containerWidth={width}
          containerHeight={height}
          textboxesCache={textboxesCache}
          mapBoxEditorStore={mapBoxEditorStore}
          isReferenced={sitemap?.isReferenced}
        />
        {(isMainSitemap || !sitemap.isReferenced) && (
          <SitemapElementsWrapper>
            {this.displayedActivities.map(
              this.renderActivityPin.bind(this, width, height),
            )}
          </SitemapElementsWrapper>
        )}
      </>
    )
  }

  private renderActivityPin(width: number, height: number, activity: Activity) {
    const {
      store: { getRelatedItems },
    } = this.props

    const items = getRelatedItems(activity.locations)

    if (!items) {
      return
    }

    return items.map((item, lIndex) => {
      const { position } = item.iconProperties
      const samePositionActivities = this.displayedActivities.filter(a => {
        return getRelatedItems(a.locations).includes(item)
      })

      const samePositionIdx = samePositionActivities.indexOf(activity)

      if (samePositionIdx > 0) {
        return
      }

      const text =
        samePositionActivities.length > 1
          ? samePositionActivities.length.toString()
          : activity.code
      const x =
        (width * position.x) / MAX_PERCENT +
        samePositionIdx * SAME_POSITION_OFFSET
      const y = (height * position.y) / MAX_PERCENT

      const isSelected = this.isActivityDisplayed(activity.code)

      return (
        <KonvaWorkflowActivityPin
          text={text}
          isSelected={isSelected}
          isCanceled={false}
          shouldRenderCircle={samePositionActivities.length > 1}
          key={activity.code + lIndex}
          x={x}
          y={y}
          offsetY={OFFSET_Y}
          onTouchEnd={this.onActivityPillTouch.bind(
            this,
            samePositionActivities,
            item,
            width,
            height,
          )}
          onClick={this.onActivityPillClick.bind(
            this,
            samePositionActivities,
            item,
            width,
            height,
          )}
        />
      )
    })
  }

  @action.bound
  private setSitemapRef(ref: BaseSitemap) {
    this.sitemap = ref
  }

  @action.bound
  private setSitemapStage(stage: Stage) {
    this.props.mapBoxEditorStore.sitemapStage = stage
  }

  @action.bound
  private setContainerRef(ref: HTMLElement) {
    this.containerRef = ref
  }

  private onSitemapTouch = () => {
    this.props.store.deselectAll()
    this.selectMapViewMode()
    this.swipeableItemId = null
  }

  private onActivityPillTouch(
    activities: Activity[],
    item: SitemapItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<TouchEvent>,
  ) {
    this.selectMapViewMode()
    const { canvasMap, clickCoords } = this.props.mapBoxEditorStore
    const position = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      null,
      item,
    )
    this.props.store.selectActivities(activities, item, position.x, position.y)
    event.cancelBubble = true
  }

  private onActivityPillClick(
    activities: Activity[],
    item: SitemapItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<MouseEvent>,
  ) {
    const { canvasMap, clickCoords } = this.props.mapBoxEditorStore
    const position = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      null,
      item,
    )
    this.props.store.selectActivities(activities, item, position.x, position.y)
    event.cancelBubble = true
  }

  private isActivityDisplayed = (code: string): boolean => {
    const { selectedActivities = [] } = this.props.store
    return selectedActivities.some(activity => activity.code === code)
  }

  @computed
  public get displayedActivities(): Activity[] {
    const {
      activityListStore: { filteredActivities },
      store,
    } = this.props

    return filteredActivities.filter(activity => {
      return activity.locations.some(
        l =>
          store.sortedSitemapAttributes.some(
            sitemapItem => l.id === sitemapItem.id,
          ) || !!store.findParentItemByAttrId(l.id),
      )
    })
  }

  private selectMapViewMode() {
    const { selectViewMode } = this.props
    if (selectViewMode) {
      selectViewMode(ViewMode.Closed)
    }
  }
}

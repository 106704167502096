import * as React from 'react'

import Konva from 'konva'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Group, Image } from 'react-konva'

import ImageRenderer, { ITextboxImageProps } from './ImageRenderer'

const TEXT_PADDING_X = 4
const TEXT_PADDING_Y = 2
const REFERENCED_Y_OFFSET = 20

@observer
export default class KonvaTextBox extends React.Component<
  ITextboxImageProps & Konva.NodeConfig
> {
  @observable private imageContent: HTMLCanvasElement = null
  private group: Konva.Group = null
  private imageElement: Konva.Image = null
  private imageRenderer: ImageRenderer = new ImageRenderer()

  public UNSAFE_componentWillMount() {
    this.imageRenderer.renderText(this.props, this.setImage)
  }

  public UNSAFE_componentWillReceiveProps(
    props: ITextboxImageProps & Konva.NodeConfig,
  ) {
    const oldKey = this.imageRenderer.generateKey(this.props)
    const newKey = this.imageRenderer.generateKey(props)
    if (oldKey !== newKey) {
      this.imageRenderer.renderText(props, this.setImage)
    }
  }

  public componentDidMount() {
    this.setTextBoxSizes()
  }

  public componentDidUpdate() {
    this.setTextBoxSizes()
  }

  public render() {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      text,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      color,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fontSize,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isTextBoxShown,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cornerRadius,
      ...rest
    } = this.props

    return (
      <Group {...rest} ref={this.setGroup}>
        <Image
          y={TEXT_PADDING_Y}
          x={TEXT_PADDING_X}
          image={this.imageContent}
          ref={this.setTextImage}
        />
      </Group>
    )
  }

  @action.bound
  private setImage(image: HTMLCanvasElement) {
    this.imageContent = image
  }

  private setTextBoxSizes() {
    if (!this.imageElement) {
      return
    }

    const { isReferenced } = this.props
    const width = this.imageElement.width() + TEXT_PADDING_X * 2
    const height = this.imageElement.height() + TEXT_PADDING_Y * 2

    const y = isReferenced ? height / 2 - REFERENCED_Y_OFFSET : height / 2
    if (this.group) {
      this.group.offsetX(width / 2)
      this.group.offsetY(y)
    }
  }

  private setTextImage = (image: Konva.Image) => {
    this.imageElement = image
  }

  private setGroup = (group: Konva.Group) => {
    this.group = group
  }
}

import { SaveInteriorPathsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import InteriorPath from '../../models/LocationObjects/InteriorPath'
import LocationStoreBase from './LocationBase.store'

export default class InteriorPathsStore extends LocationStoreBase<InteriorPath> {
  protected saveMutation = SaveInteriorPathsDocument
  protected graphName = 'interiorPaths'
  protected defaultIconName = SitemapAttributeIcon.InteriorPath

  public get byId(): Map<string, InteriorPath> {
    return this.eventsStore.appState.interiorPaths
  }

  protected fromDto(dto: ILocationDto): InteriorPath {
    return InteriorPath.fromDto(dto)
  }
}

import { action, computed, observable } from 'mobx'
import * as createUuid from 'uuid/v1'

import {
  CalendricalType,
  IInspectionOptions,
  IOperationRule,
  IWorkflowStep,
  WorkflowStepType,
} from '~/client/graph'
import { getWorkflowStepLevel } from '~/client/src/shared/constants/formStatusesTags'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getWorkflowStepDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import { AndOrOperator } from '~/client/src/shared/models/LogicOperation'
import OperationRule from '~/client/src/shared/models/OperationRule'
import PermitType from '~/client/src/shared/models/PermitType'
import User from '~/client/src/shared/models/User'
import OperationRulesStore from '~/client/src/shared/stores/domain/OperationRules.store'
import PermitBallInCourtStore, {
  PERMIT_TYPE_TAG,
} from '~/client/src/shared/stores/ui/PermitBallInCourt.store'
import { copyObjectDeep } from '~/client/src/shared/utils/util'

const FAKE_ID_TAG = 'FakeID'

const forbiddenLastSteps = [
  WorkflowStepType.RecurringInspection,
  WorkflowStepType.Start,
]

const defaultInspectionOptions: IInspectionOptions = {
  inspectionFrequency: 1,
  inspectionFrequencyType: CalendricalType.Day,
  deadlineTime: 24,
  selectedDaysToRepeat: [],
}

const isExistingRuleId = (ruleId: string): boolean =>
  !!ruleId && ruleId !== OperationRule.NEW_ID

export default class WorkflowStore {
  @observable public removingRuleId: string = null
  @observable public isDeleteStepModalShown: boolean = false
  @observable public isStepDescrDialogShown: boolean = false
  @observable public isAutoToggleDialogShown: boolean = false

  @observable public permitTypeId: string
  @observable public isAutoActivationEnabled = false
  @observable public workflowSteps: IWorkflowStep[] = []
  @observable public inspectionOptions: IInspectionOptions

  @observable private _originalWorkflowSteps: IWorkflowStep[] = []
  @observable private _originalInspectionOptions: IInspectionOptions
  @observable private _originalActivationState: boolean = false

  private removingStepIndex: number

  public constructor(
    private readonly operationRulesStore: OperationRulesStore,
    private readonly permitBallInCourtStore: PermitBallInCourtStore,
    permitType: PermitType,
    private readonly saveWorkflowSettings: (
      workflowSteps: IWorkflowStep[],
      inspectionOptions: IInspectionOptions,
      isAutoActivationEnabled: boolean,
    ) => void,
  ) {
    this.init(permitType)
  }

  @action.bound
  public init(permitType: PermitType) {
    const { type, inspectionOptions, workflowSteps, isAutoActivationEnabled } =
      permitType

    this.permitTypeId = type

    this.workflowSteps = workflowSteps
    this._originalWorkflowSteps = workflowSteps.map(s => s)

    this.inspectionOptions = copyObjectDeep(inspectionOptions)
    this._originalInspectionOptions = copyObjectDeep(inspectionOptions)

    this.isAutoActivationEnabled = isAutoActivationEnabled || false
    this._originalActivationState = this.isAutoActivationEnabled

    this.hideNewRuleRow()
    this.hideDeleteStepModal()
  }

  @action.bound
  public showDeleteStepModal(stepIndex: number) {
    this.removingStepIndex = stepIndex
    this.isDeleteStepModalShown = true
  }

  @action.bound
  public hideDeleteStepModal() {
    this.isDeleteStepModalShown = false
    this.removingStepIndex = null
  }

  @action.bound
  public showStepDescrDialog() {
    this.isStepDescrDialogShown = true
  }

  @action.bound
  public hideStepDescrDialog() {
    this.isStepDescrDialogShown = false
  }

  @action.bound
  public showAutoToggleDialog() {
    this.isAutoToggleDialogShown = true
  }

  @action.bound
  public hideAutoToggleDialog() {
    this.isAutoToggleDialogShown = false
  }

  @action.bound
  public hideAllDialogs() {
    this.hideAutoToggleDialog()
    this.hideDeleteStepModal()
  }

  @action.bound
  public addNewWorkflowStep(index: number, stepType: WorkflowStepType) {
    this.workflowSteps.splice(index + 1, 0, {
      id: `${FAKE_ID_TAG}_${createUuid()}`,
      type: stepType,
      fields: [],
      conditionalFields: [],
      workflowRuleIds: [],
    })

    if (stepType === WorkflowStepType.Start) {
      this.correctRecurringStepPosition()
    }
  }

  @action.bound
  public replaceWorkflowStep(index: number, newStepType: WorkflowStepType) {
    const existingStep = this.workflowSteps[index]
    existingStep.type = newStepType
    this.workflowSteps[index] = existingStep
  }

  @action.bound
  public confirmRemoveWorkflowStep() {
    if (
      !this.isAutoActivationEnabled &&
      this.workflowSteps[this.removingStepIndex]?.type ===
        WorkflowStepType.Start &&
      this.hasRecurringStep
    ) {
      this.isDeleteStepModalShown = false
      return this.showAutoToggleDialog()
    }

    this.removeWorkflowStep()
  }

  @action.bound
  public confirmAutoActivationChange() {
    if (this.isAutoActivationEnabled) {
      const approvalStepIdx = this.workflowSteps.findIndex(
        s => s.type === WorkflowStepType.Approval,
      )
      this.addNewWorkflowStep(approvalStepIdx, WorkflowStepType.Start)
    } else {
      this.removeWorkflowStep()
    }

    this.isAutoActivationEnabled = !this.isAutoActivationEnabled
    this.hideAutoToggleDialog()
  }

  @action.bound
  private correctRecurringStepPosition() {
    const recurringStep = this.workflowSteps.find(
      s => s.type === WorkflowStepType.RecurringInspection,
    )

    if (!recurringStep) return

    this.workflowSteps = this.workflowSteps.filter(
      s => s.type !== WorkflowStepType.RecurringInspection,
    )
    const idx = this.hasStartStep
      ? this.workflowSteps.findIndex(s => s.type === WorkflowStepType.Start)
      : this.workflowSteps.findIndex(s => s.type === WorkflowStepType.Approval)

    if (idx !== -1) {
      this.workflowSteps.splice(idx + 1, 0, recurringStep)
    }
  }

  @action.bound
  public changeInspectionOptions(inspectionOptions: IInspectionOptions) {
    this.inspectionOptions = inspectionOptions
  }

  @action.bound
  public restoreWorkflowSettings() {
    if (this.areWorkflowStepsChanged) {
      this.workflowSteps = this._originalWorkflowSteps.map(s =>
        copyObjectDeep(s),
      )
    }

    this.restoreInspectionOptions()
    this.restoreAutoActivationState()
  }

  @action.bound
  public toggleAutoActivationState() {
    if (
      this.isAutoActivationEnabled &&
      this.hasRecurringStep &&
      !this.hasStartStep
    ) {
      return this.showAutoToggleDialog()
    }

    this.isAutoActivationEnabled = !this.isAutoActivationEnabled
  }

  public saveWorkflowChanges = () => {
    if (!this.isAllowedToSave) return

    const hasRecurringStep = this.workflowSteps.some(
      s => s.type === WorkflowStepType.RecurringInspection,
    )

    this.saveWorkflowSettings(
      this.workflowSteps.map(s => ({
        ...s,
        id: s.id.includes(`${FAKE_ID_TAG}_`) ? null : s.id,
        workflowRuleIds: (s.workflowRuleIds || []).filter(isExistingRuleId),
      })),
      this.getInspectionOptionsForSave(hasRecurringStep),
      this.isAutoActivationEnabled,
    )
  }

  //#region Operation rules methods
  @action.bound
  public showNewRuleRow(stepId: string) {
    this.hideNewRuleRow()

    const workflowStep = this.workflowSteps.find(s => s.id === stepId)
    if (!workflowStep.workflowRuleIds) {
      workflowStep.workflowRuleIds = []
    }
    workflowStep.workflowRuleIds.push(OperationRule.NEW_ID)
  }

  @action.bound
  public hideNewRuleRow() {
    this.workflowSteps.forEach(s => {
      if (s.workflowRuleIds?.includes(OperationRule.NEW_ID)) {
        s.workflowRuleIds = s.workflowRuleIds.filter(isExistingRuleId)
      }
    })
  }

  @action.bound
  public tryToRemoveRule(ruleId: string) {
    this.removingRuleId = ruleId
  }

  @action.bound
  public resetRemovingRuleId() {
    this.removingRuleId = null
  }

  @action.bound
  public addOrDeleteUserFromRule(
    rule: OperationRule,
    userId: string,
    isNew?: boolean,
  ) {
    if (isNew) {
      this.permitBallInCourtStore.addUserToRule(
        rule,
        userId,
        !rule.isExisting && this.addRuleToWorkflow,
      )
    } else {
      this.removeUserFromRule(rule, userId)
    }
  }

  @action.bound
  public removeUserFromRule(rule: OperationRule, userId: string) {
    this.permitBallInCourtStore.removeUserFromRule(rule, userId)
  }

  @action.bound
  public duplicateRule(ruleId: string) {
    if (!isExistingRuleId(ruleId)) return

    const existingRule = this.operationRulesStore.getRuleById(ruleId)

    const newRule: OperationRule = existingRule.getCopy() as OperationRule
    newRule.id = OperationRule.NEW_ID

    const ruleStepId = this.workflowSteps.find(s =>
      s.workflowRuleIds?.includes(ruleId),
    )?.id

    if (ruleStepId) {
      this.showNewRuleRow(ruleStepId)
      this.operationRulesStore.saveMany([newRule], this.addRuleToWorkflow)
    }
  }

  @action.bound
  public performRemoveRule() {
    if (!this.removingRuleId) {
      return
    }

    if (isExistingRuleId(this.removingRuleId)) {
      this.operationRulesStore.removeMany([this.removingRuleId])
      this.workflowSteps.forEach(s => {
        s.workflowRuleIds = s.workflowRuleIds.filter(
          id =>
            id !== this.removingRuleId &&
            this.operationRulesStore.isRuleExisting(id),
        )
      })
      this.saveWorkflowChanges()
    } else {
      this.hideNewRuleRow()
    }

    this.resetRemovingRuleId()
  }

  @action.bound
  public changeRuleExpression(
    rule: OperationRule,
    locationAndCompanyExpression: string,
  ) {
    const permitTypeExpression = OperationRule.makeTagExpressionString(
      PERMIT_TYPE_TAG,
      this.permitTypeId,
    )

    let newExpression = permitTypeExpression

    if (locationAndCompanyExpression) {
      newExpression = newExpression.concat(
        ` ${AndOrOperator.AND} `,
        locationAndCompanyExpression,
      )
    }

    rule.setExpression(newExpression)

    this.operationRulesStore.saveMany(
      [rule],
      !rule.isExisting && this.addRuleToWorkflow,
    )
  }

  public getUsersFromExpressions = (
    assignmentExpressions: string[],
  ): User[] => {
    return this.permitBallInCourtStore.getUsersFromAssignment(
      assignmentExpressions,
    )
  }

  @action.bound
  private removeWorkflowStep() {
    this.workflowSteps.splice(this.removingStepIndex, 1)

    this.correctRecurringStepPosition()
    this.correctAutoActivationState()
    this.hideDeleteStepModal()
  }

  @action.bound
  private restoreInspectionOptions() {
    if (this.areInspectionOptionsChanged) {
      this.inspectionOptions = copyObjectDeep(this._originalInspectionOptions)
    }
  }

  @action.bound
  private restoreAutoActivationState() {
    if (this.isAutoActivationChanged) {
      this.isAutoActivationEnabled = this._originalActivationState
    }
  }

  @action.bound
  private correctAutoActivationState() {
    if (!this.hasApprovalStep && !this.hasStartStep) {
      this.isAutoActivationEnabled = false
    }
  }

  @action.bound
  private addRuleToWorkflow(newRules: IOperationRule[]) {
    const workflowStep = this.workflowSteps.find(s =>
      s.workflowRuleIds?.includes(OperationRule.NEW_ID),
    )

    if (!workflowStep) return

    workflowStep.workflowRuleIds = workflowStep.workflowRuleIds.filter(
      id => isExistingRuleId(id) && this.operationRulesStore.isRuleExisting(id),
    )
    workflowStep.workflowRuleIds.push(...newRules.map(or => or.id))

    this.saveWorkflowChanges()
  }
  //#endregion

  @computed
  public get areWorkflowStepsChanged(): boolean {
    return (
      JSON.stringify(
        this.workflowSteps.map(s => ({ ...s, workflowRuleIds: [] })),
      ) !==
      JSON.stringify(
        this._originalWorkflowSteps.map(s => ({ ...s, workflowRuleIds: [] })),
      )
    )
  }

  @computed
  public get areInspectionOptionsChanged(): boolean {
    return (
      JSON.stringify(this.inspectionOptions) !==
      JSON.stringify(this._originalInspectionOptions)
    )
  }

  @computed
  public get isAutoActivationChanged(): boolean {
    return this.isAutoActivationEnabled !== this._originalActivationState
  }

  @computed
  public get isWorkflowChanged(): boolean {
    return (
      this.areWorkflowStepsChanged ||
      this.areInspectionOptionsChanged ||
      this.isAutoActivationChanged
    )
  }

  @computed
  public get isAnyModalShown(): boolean {
    return !!this.removingRuleId || this.isDeleteStepModalShown
  }

  @computed
  public get hasRequestStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Request)
  }

  @computed
  public get hasApprovalStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Approval)
  }

  @computed
  public get hasStartStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Start)
  }

  @computed
  public get hasRecurringStep(): boolean {
    return this.workflowSteps.some(
      s => s.type === WorkflowStepType.RecurringInspection,
    )
  }

  @computed
  public get lastWorkflowStep(): IWorkflowStep {
    return this.workflowSteps[this.workflowSteps.length - 1]
  }

  @computed
  public get warningMessage(): string {
    const { mustContainApproval, cannotBeTheLastStep } =
      Localization.translator.workflowConfDescr
    if (this.hasRequestStep && !this.hasApprovalStep) {
      return mustContainApproval
    }
    if (this.isLastStepForbidden) {
      const stepLevel = getWorkflowStepLevel(
        this.lastWorkflowStep?.id,
        this.workflowSteps,
      )
      const stepName = getWorkflowStepDisplayName(
        this.lastWorkflowStep?.type,
        stepLevel,
      )
      return cannotBeTheLastStep(stepName)
    }
  }

  @computed
  public get isAllowedToSave(): boolean {
    return (
      !this.isLastStepForbidden &&
      (!this.hasRequestStep || this.hasApprovalStep)
    )
  }

  private get isLastStepForbidden(): boolean {
    return forbiddenLastSteps.includes(this.lastWorkflowStep?.type)
  }

  private getInspectionOptionsForSave(
    hasRecurringStep: boolean,
  ): IInspectionOptions {
    if (!hasRecurringStep) return null

    return this.inspectionOptions?.inspectionFrequency
      ? this.inspectionOptions
      : defaultInspectionOptions
  }
}

import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ISitemapSpecificItemData } from '~/client/graph'
import BaseObservableTable from '~/client/src/desktop/components/BaseObservableTable/BaseObservableTable'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Checkbox from '~/client/src/shared/components/Checkbox'
import GeoreferencingLabel from '~/client/src/shared/components/GeoreferencingLabel/GeoreferenceingLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  ILWFCCategory,
  ILWFCColumn,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import SitemapDeliveryAttributes from '~/client/src/shared/components/SitemapDeliveryAttributes/SitemapDeliveryAttributes'
import TableSeparators from '~/client/src/shared/components/TableSeparators'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Basemap from '~/client/src/shared/models/Basemap'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap, {
  ISitemapGeoPosition,
} from '~/client/src/shared/models/Sitemap'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import {
  getBackgroundColor,
  getTextColor,
} from '~/client/src/shared/utils/tableColoring'

import DeliverySitemapSetUpStore from '../../../GeneralSitemapSetUp.store'
import SitemapViewsSetupStore, {
  DataKeys,
  site,
} from '../../../stores/SitemapViewsSetup.store'
import SitemapSetupStore, {
  UNTITLED,
} from '../../../stores/SitemapsSetup.store'
import SitemapAssociationControl from '../../SitemapAssociationControl'
import SitemapRibbon from '../../ribbons/SitemapRibbon'
import LocationAssociationModal from '../LocationAssociationModal'
import EditableLabel from './components/EditableLabel'

import './ViewSelectDialog.scss'

const DEFAULT_ROW_HEIGHT = 80
const savingChanges = 'Saving Changes'
const backToMap = 'Back to map'
const LBS = 'LBS'
const lbsTag = 'LBS Tag'

interface IProps {
  store: DeliverySitemapSetUpStore
  updateSitemapName: (sitemap: Sitemap, newText: string) => void

  viewport: ISitemapGeoPosition
  setViewport: (viewport: ISitemapGeoPosition) => void

  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  basemapsStore?: BasemapsStore
  sitemapsStore?: SitemapsStore
  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
  buildingsStore?: BuildingsStore
}
@inject(
  'state',
  'projectDateStore',
  'basemapsStore',
  'eventsStore',
  'sitemapsStore',
  'tagsStore',
  'buildingsStore',
)
@observer
export default class ViewSelectDialog extends React.Component<IProps> {
  @observable private sitemap: Sitemap = null
  @observable private ref = null
  private lbsTagWidths = observable(new Map<string, number>())

  private get sitemapSetupStore(): SitemapSetupStore {
    return this.props.store.sitemapSetupStore
  }

  public componentDidMount() {
    this.sitemapViewsSetupStore.resetHiddenSelection()
    this.sitemapViewsSetupStore.toggleHiddenInstance(
      this.sitemapViewsSetupStore.selectedSitemap?.id,
    )
  }

  public render() {
    const { isLoading } = this.props.state
    const { isDialogOpened } = this.props.store.sitemapViewsSetupStore

    return (
      <>
        {isDialogOpened && (
          <div className="col view-select-dialog relative full-height">
            {isLoading && (
              <div
                style={{ zIndex: 1 }}
                className="absolute-block full-height bg-light-cool-grey opacity5"
              >
                <Loader hint={savingChanges} />
              </div>
            )}
            {this.renderAddTagButton()}
            {this.renderTopMenu()}
            {this.renderSitemapsTable()}
          </div>
        )}
      </>
    )
  }

  private renderTopMenu() {
    const {
      state: { sitemapFilters },
      store,
    } = this.props
    const { filteredCollection, isGoToMapActive } = store.sitemapViewsSetupStore

    return (
      <div className="col pa12">
        <div className="row">
          <div
            className="row x-start pointer text extra-large"
            onClick={this.handleCellClick}
          >
            <Icon
              className="back-icon"
              icon={IconNames.CHEVRON_LEFT}
              iconSize={18}
            />
            {backToMap}
          </div>
          <div className="row x-end pointer">
            <BaseActionButton
              className="scale-blue-theme ml-auto ml12"
              title="Go to map"
              isActive={false}
              isEnabled={isGoToMapActive}
              onClick={this.handleCellClick}
            />
          </div>
        </div>
        <div className="row x-start top-menu bb-light-input-border pb5">
          <EntityNameFilter filters={sitemapFilters} />
          <div
            style={{ height: 32 }}
            className="y-center row text large ba-light-input-border no-grow brada5 py5 px10 tertiary-icon"
          >
            <Icons.TertiaryObject className="no-grow" />
            {LBS}
          </div>
        </div>
        <div className="row x-between relative mt10 mb20 secondary-top-menu">
          {`${filteredCollection.length} Maps showing`}
          <SitemapRibbon
            store={store}
            shouldHideAdditionMenu={true}
            shouldFixTopPosition={true}
          />
        </div>
      </div>
    )
  }

  private updateBasemapName = (basemap: Basemap, newText: string) => {
    basemap.setName(newText)
    this.sitemapSetupStore.saveBasemapWithLoader(basemap)
  }

  private async duplicateSitemap(
    value: Sitemap,
    recomputeGridSize: () => void,
  ) {
    await this.sitemapSetupStore.duplicateSitemap(value)
    recomputeGridSize()
  }

  private renderValue = (
    value: any,
    dataKey: string,
    data: LWFCRowData,
    recomputeGridSize?: () => void,
    level?: number,
  ) => {
    switch (dataKey) {
      case DataKeys.CHECKBOX:
        return this.renderCheckbox(data[DataKeys.View])
      case DataKeys.View:
        return this.renderViewValue(value, level)
      case DataKeys.Tags:
        return this.renderTagsValue(value)
      case DataKeys.Objects:
        return this.renderObjectsValue(value)
      case DataKeys.Image:
        return this.renderImageValue(value, recomputeGridSize)
      case DataKeys.BaseMap:
        return this.renderBasemap(value)
      case DataKeys.PublishTo:
        return this.renderPublishTo(value, recomputeGridSize)
      case DataKeys.Referencing:
        return this.renderGeoreferencing(value)
    }
  }

  private renderCheckbox(sitemap: Sitemap) {
    const isChecked = this.selectedValuesMap[sitemap?.id]
    return (
      <div className="col y-center full-height">
        <Checkbox
          className="checkbox-selector text large no-bold dark-checkbox"
          isCentered={true}
          isChecked={isChecked}
        />
      </div>
    )
  }

  private get selectedValuesMap(): { [instanceId: string]: boolean } {
    return this.sitemapViewsSetupStore.selectedInstancesIds.reduce(
      (map, value) => {
        map[value] = true
        return map
      },
      {},
    )
  }

  private renderViewValue(sitemap: Sitemap, level: number) {
    const {
      updateSitemapName,
      store: { sitemapSetupStore, sitemapItemsSetupStore },
    } = this.props

    return (
      <div className="row full-height ml10" style={{ maxWidth: '590px' }}>
        {this.renderDeleteIcon(sitemap)}
        <TableSeparators
          level={level}
          className="no-grow full-height br-palette-grey ml10"
        />
        <div className="col full-height sitemap-image-holder x-center pt10 no-grow ml10 unclickable-element">
          <DesktopFileInput
            id={sitemap.id}
            name=""
            value={sitemap.filledImage}
            isReadonly={true}
            textClassName="hint"
            shouldHideIconAndOutline={true}
          />
        </div>
        {/* width of this block have to be relative but as we don't know the exact width of lbsTag before rendering these calculations have to be done */}
        <div
          className="row full-height ml20 y-center sitemap-item-name"
          style={{
            maxWidth:
              470 - level * 12 - (this.lbsTagWidths.get(sitemap.id) || 0),
          }}
        >
          <div className="row full-width relative">
            <EditableLabel
              text={sitemap.name}
              update={updateSitemapName.bind(this, sitemap)}
              shouldRenderEditIcon={true}
              shouldBlockTextClick={true}
              isIconLeftSided={true}
              className="editable-label text bold large px10 brada4 text-ellipsis bg-unset"
            />
          </div>
        </div>
        <div
          className="col px10 y-between no-grow"
          ref={ref => this.setRef(ref, sitemap.id)}
        >
          <div className="row text uppercase small light lp15 bold">
            {lbsTag}
          </div>
          <div className="row">
            <SitemapAssociationControl
              sitemapItemsSetupStore={sitemapItemsSetupStore}
              sitemapSetupStore={sitemapSetupStore}
              shouldHideModal={true}
              sitemap={sitemap}
              onToggle={this.onModalToggle.bind(this, sitemap)}
            />
          </div>
        </div>
      </div>
    )
  }

  private setRef = (ref: HTMLDivElement, id: string) => {
    if (ref) {
      this.lbsTagWidths.set(id, ref.clientWidth)
    }
  }

  private onModalToggle = (sitemap: Sitemap) => {
    this.sitemap = sitemap
    this.sitemapSetupStore.toggleAssignSitemapDialog()
  }

  private renderDeleteIcon = (sitemap: Sitemap): JSX.Element => {
    return (
      <span
        className="row delete-map-icon absolute pointer py15 pr20"
        onClick={this.showDeleteDialog.bind(this, sitemap)}
      >
        <Icons.Delete className="no-grow" />
      </span>
    )
  }

  private renderObjectsValue(objects: ISitemapSpecificItemData[]) {
    return (
      <div className="col full-height tags-col y-center">
        <div className="text large">{objects.length || 0}</div>
      </div>
    )
  }

  private renderBasemap(basemapId: string) {
    const basemap = this.props.basemapsStore.byId.get(basemapId)
    return <div className="row full-height">{basemap?.fileName}</div>
  }

  private onSiteSitemapSectionClick = (
    sitemap: Sitemap,
    recomputeGridSize?: () => void,
  ) => {
    this.props.store.sitemapSetupStore.onSiteSitemapSectionClick(sitemap)
    recomputeGridSize()
  }

  private onFormsSitemapSectionClick = (
    sitemap: Sitemap,
    recomputeGridSize?: () => void,
  ) => {
    this.props.store.sitemapSetupStore.onFormsSitemapSectionClick(sitemap)
    recomputeGridSize()
  }

  private onActivitiesSitemapSectionClick = (
    sitemap: Sitemap,
    recomputeGridSize?: () => void,
  ) => {
    this.props.store.sitemapSetupStore.onActivitiesSitemapSectionClick(sitemap)
    recomputeGridSize()
  }

  private onDeliveriesSectionClick = (
    sitemap: Sitemap,
    recomputeGridSize?: () => void,
  ) => {
    this.props.store.sitemapSetupStore.onDeliveriesSectionClick(sitemap)
    recomputeGridSize()
  }

  private renderGeoreferencing(sitemap: Sitemap) {
    return (
      <div className="row full-height">
        <GeoreferencingLabel selectedSitemap={sitemap} />
      </div>
    )
  }

  private renderPublishTo(sitemap: Sitemap, recomputeGridSize?: () => void) {
    const {
      isDeliveriesDisabled,
      isFormsDisabled,
      isLogisticsDisabled,
      isTrackerDisabled,
    } = this.props.state
    const {
      siteAssignedSitemaps,
      formsAssignedSitemaps,
      activitiesAssignedSitemaps,
      deliveriesAssignedSitemaps,
    } = this.props.store.sitemapSetupStore

    return (
      <div className="row full-height">
        {!isLogisticsDisabled && (
          <div
            className="relative no-grow"
            onClick={this.onSiteSitemapSectionClick.bind(
              null,
              sitemap,
              recomputeGridSize,
            )}
          >
            {!siteAssignedSitemaps[sitemap.id] ? (
              <Icons.HomeRoundedCrossed className="no-grow" />
            ) : (
              <Icons.HomeRounded className="no-grow publish-to-icon" />
            )}
          </div>
        )}
        {!isFormsDisabled && (
          <div
            className="relative no-grow"
            onClick={this.onFormsSitemapSectionClick.bind(
              null,
              sitemap,
              recomputeGridSize,
            )}
          >
            {!formsAssignedSitemaps[sitemap.id] ? (
              <Icons.PermitsRoundedCrossed className="no-grow" />
            ) : (
              <Icons.PermitsRoundedActive className="no-grow publish-to-icon" />
            )}
          </div>
        )}
        {!isDeliveriesDisabled && (
          <div
            className="relative no-grow"
            onClick={this.onDeliveriesSectionClick.bind(
              null,
              sitemap,
              recomputeGridSize,
            )}
          >
            {!deliveriesAssignedSitemaps[sitemap.id] ? (
              <Icons.DeliveryRoundedCrossed className="no-grow" />
            ) : (
              <Icons.DeliveryRounded className="no-grow publish-to-icon" />
            )}
          </div>
        )}
        {!isTrackerDisabled && (
          <div
            className="relative no-grow"
            onClick={this.onActivitiesSitemapSectionClick.bind(
              null,
              sitemap,
              recomputeGridSize,
            )}
          >
            {!activitiesAssignedSitemaps[sitemap.id] ? (
              <Icons.ScheduleRoundedCrossed className="no-grow" />
            ) : (
              <Icons.ScheduleRounded className="no-grow publish-to-icon" />
            )}
          </div>
        )}
      </div>
    )
  }

  private renderImageValue(sitemap: Sitemap, recomputeGridSize: () => void) {
    const { showDeleteConfirmationDialog } = this.sitemapSetupStore
    return (
      <div className="row full-height">
        <div className="col full-height sitemap-image-holder x-center pt5">
          <DesktopFileInput
            id={sitemap.id}
            name=""
            value={sitemap.filledImage}
            isReadonly={true}
            textClassName="hint"
            shouldHideIconAndOutline={true}
          />
        </div>
        <div className="col full-height y-center x-center">
          <div
            className="action-button my10"
            onClick={this.duplicateSitemap.bind(
              this,
              sitemap,
              recomputeGridSize,
            )}
          >
            <Icons.Duplicate />
          </div>
          <div
            className="action-button my10"
            onClick={showDeleteConfirmationDialog.bind(this, sitemap)}
          >
            <Icons.Delete />
          </div>
        </div>
      </div>
    )
  }

  private renderTagsValue(sitemapId: string) {
    const sitemap = this.props.sitemapsStore.byId.get(sitemapId)
    return (
      <SitemapDeliveryAttributes
        sitemap={sitemap}
        shouldHideCrossIcons={true}
        className="row wrap full-width x-start y-start py10 tags-holder"
      />
    )
  }

  private renderCategoryLabel = (basemap: Basemap) => {
    const basemapName = (basemap && basemap.name) || UNTITLED

    return (
      <>
        <span className="no-grow mx10 row">
          <EditableLabel
            text={basemapName}
            update={this.updateBasemapName.bind(this, basemap)}
            className="mr10 w-fit-content no-grow px10 brada4 editable-header"
          />
        </span>
        <div
          className="no-grow text grey large"
          onClick={this.openBaseMapView.bind(this, basemap?.source)}
        >
          {Localization.translator.viewBasemap}
        </div>
      </>
    )
  }

  @action.bound
  private openBaseMapView(basemapId: string, event: React.SyntheticEvent) {
    event?.stopPropagation()

    this.props.eventsStore.dispatch(
      SHOW_FULLSCREEN_PREVIEW,
      [{ fileUrl: basemapId, fileType: FileType.Image }],
      0,
    )
  }

  private renderSitemapsTable(): JSX.Element {
    const { sitemapViewsSetupStore } = this.props.store

    return (
      <div className="table-small relative full-height">
        <BaseObservableTable
          ref={ref => (this.ref = ref)}
          store={sitemapViewsSetupStore}
          autoSizerClassName="full-height full-width"
          valueRenderer={this.renderValue}
          renderCategoryLabel={this.renderCategoryLabel}
          rowHeightGetter={this.getRowHeight}
          headerRowRenderer={this.headerRowRenderer}
          categoryContent={this.renderCategoryCell}
          dataCellCustomClassName="y-start"
          categoryRowCustomClassName="row text large bold primary-blue cell bb-cool-grey category-cell full-height no-select pointer"
          shouldPreventCollapsing={true}
          fixedWidth={sitemapViewsSetupStore.tableWidth}
          handleCellClick={this.handleRowClick}
        />
      </div>
    )
  }

  private handleRowClick = (rowData: LWFCRowData, column?: ILWFCColumn) => {
    const instanceId = rowData[DataKeys.id]
    if (column.dataKey === DataKeys.CHECKBOX) {
      this.sitemapViewsSetupStore.toggleInstance(instanceId)
      return
    }
    this.sitemapViewsSetupStore.resetHiddenSelection()
    this.sitemapViewsSetupStore.toggleHiddenInstance(instanceId)
  }

  private getRowHeight = ({ index }) => {
    const row = this.props.store.sitemapViewsSetupStore.rows[index]
    if (!index) {
      return 0
    }
    return (row && row.height) || DEFAULT_ROW_HEIGHT
  }

  private headerRowRenderer = () => {
    return (
      <div className="row header-row bb-brand-dark y-end">
        {this.sitemapViewsSetupStore.columns.map(
          ({ dataKey, width, translatorKey }) => {
            return (
              <div
                key={dataKey}
                style={{ minWidth: width, maxWidth: width }}
                className={classList({
                  'row y-end text uppercase no-outline-container cell cell-header pb5 bold header-icon':
                    true,
                  pl20: dataKey === DataKeys.View,
                })}
              >
                {translatorKey && Localization.getText(translatorKey)}
              </div>
            )
          },
        )}
      </div>
    )
  }

  private renderCategoryCell = (
    category: ILWFCCategory,
    dataKey: string,
    gridProps: any,
    data: LWFCRowData,
    level?: number,
    recomputeTableSize?: () => void,
  ) => {
    const { sitemapViewsSetupStore } = this.props.store
    const { collapsedCategories } = sitemapViewsSetupStore
    const { categoryLabel, categoryId, isChecked } = category

    const deliveryAttribute = data as LocationAttributeBase
    const backgroundColor = getBackgroundColor(categoryId, deliveryAttribute)
    const textColor = getTextColor(categoryId)
    const icon = collapsedCategories.get(categoryId)
      ? IconNames.CARET_UP
      : IconNames.CARET_DOWN

    let content: JSX.Element
    if (dataKey === DataKeys.CHECKBOX) {
      content = (
        <div className="row ml20 full-height">
          <Checkbox
            className={classList({
              'checkbox-selector text large no-bold no-grow mr10': true,
              'light-checkbox': categoryId === site,
              'dark-checkbox': categoryId !== site,
            })}
            isCentered={true}
            isChecked={isChecked}
            onClick={this.sitemapViewsSetupStore.toggleCategory.bind(
              null,
              category,
            )}
          />
        </div>
      )
    }
    if (dataKey === DataKeys.View) {
      content = (
        <div className="row full-height ml10">
          <TableSeparators
            level={level}
            className="no-grow full-height br-palette-grey ml10"
          />
          <div>
            <Icon
              className="pointer text grey ml3"
              icon={icon}
              onClick={this.toggleCategoryCollapsing.bind(
                null,
                categoryId,
                recomputeTableSize,
              )}
            />
            <SitemapAttributeTag
              dataObject={deliveryAttribute as LocationBase}
              shouldShowAsTag={false}
              contentContainerClassName="text-ellipsis py2"
            >
              <span title={categoryLabel}>{categoryLabel}</span>
            </SitemapAttributeTag>
          </div>
        </div>
      )
    }

    return (
      <div
        {...gridProps}
        style={{ backgroundColor, color: textColor }}
        className="text large cell category-cell category-row full-height no-select pointer"
      >
        {content}
      </div>
    )
  }

  private toggleCategoryCollapsing = (
    categoryId: string,
    recomputeTableSize?: () => void,
  ) => {
    this.sitemapViewsSetupStore.toggleCategoryCollapsing(categoryId)
    recomputeTableSize?.()
  }

  @action.bound
  private handleCellClick() {
    this.sitemapViewsSetupStore.openSelectedSitemap()
  }

  private get sitemapViewsSetupStore(): SitemapViewsSetupStore {
    return this.props.store.sitemapViewsSetupStore
  }

  private showDeleteDialog = (sitemap: Sitemap) => {
    const { showDeleteConfirmationDialog } = this.props.store.sitemapSetupStore
    showDeleteConfirmationDialog(sitemap)
  }

  private renderAddTagButton = () => {
    const { sitemapSetupStore, sitemapItemsSetupStore } = this.props.store
    const { isAssignSitemapDialogShown } = sitemapSetupStore

    return (
      <div className="absolute-block absolute-block-x-center">
        {isAssignSitemapDialogShown && (
          <LocationAssociationModal
            onClose={this.onClose}
            sitemapItemsSetupStore={sitemapItemsSetupStore}
            sitemapSetupStore={sitemapSetupStore}
            sitemap={this.sitemap}
            postUpdateAction={this.recomputeGrid}
          />
        )}
      </div>
    )
  }

  private recomputeGrid = () => {
    if (this.ref) {
      this.ref.recomputeTableSize()
    }
  }

  private onClose = () => {
    const { hideAssignSitemapDialog } = this.props.store.sitemapSetupStore
    hideAssignSitemapDialog()
  }
}

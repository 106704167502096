import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CalendarDayView, {
  CalendarDayMode,
} from '~/client/src/shared/components/CalendarDayView/CalendarDayView'
import DeliveryListComponent from '~/client/src/shared/components/Deliveries/components/DeliveryList'
import DeliveryActions from '~/client/src/shared/components/DeliveryActions/DeliveryActions'
import Spinner from '~/client/src/shared/components/Spinner/Spinner'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import WeatherForecastsStore from '~/client/src/shared/stores/domain/WeatherForecasts.store'
import DeliveryFilterStore from '~/client/src/shared/stores/ui/DeliveryFilter.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import EventsStore from '../../stores/EventStore/Events.store'
import ActivitiesStore from '../../stores/domain/Activities.store'
import DeliveriesStore from '../../stores/domain/Deliveries.store'
import DeliveryAssignmentsStore from '../../stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '../../stores/domain/DeliveryFollowings.store'
import DeliveryVehicleTypesStore from '../../stores/domain/DeliveryVehicleTypes.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import MaterialCategoryStore from '../../stores/domain/MaterialCategories.store'
import MaterialsStore from '../../stores/domain/Materials.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import SitemapItemsStore from '../../stores/domain/SitemapItems.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import CommonStore from '../../stores/ui/Common.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import DeliveryDetailsStore from '../DeliveryDetails/DeliveryDetails.store'
import FileInputBase from '../FileInput/FileInput'
import DeliveriesViewStore, {
  DeliveryPageMode,
  DeliveryPageViewMode,
} from './DeliveriesView.store'
import DeliveriesMapView from './components/DeliveriesMapView/DeliveriesMapView'
import DeliveryHeader from './components/DeliveryHeader'

import './Deliveries.scss'

// translated

interface IProps {
  shouldHandleMouse?: boolean
  className?: string
  shouldUseSavedFilters?: boolean
  eventsStore?: EventsStore
  common?: CommonStore
  deliveriesStore?: DeliveriesStore
  activitiesStore?: ActivitiesStore
  deliveryDetailsStore?: DeliveryDetailsStore
  dateSelectorPostfix?: React.ReactNode
  projectDateStore?: ProjectDateStore
  sitemapsStore?: SitemapsStore
  locationAttributesStore?: LocationAttributesStore
  sitemapItemsStore?: SitemapItemsStore
  deliveryFollowingsStore?: DeliveryFollowingsStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
  companiesStore?: CompaniesStore
  deliveryVehicleTypesStore?: DeliveryVehicleTypesStore
  materialCategoryStore?: MaterialCategoryStore
  materialsStore?: MaterialsStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
  weatherForecastsStore?: WeatherForecastsStore
  clickOnMenuIcon?: () => void
  renderFooter?: (onCreateClicked?: () => void) => JSX.Element
  FileInputType: typeof FileInputBase
}

@inject(
  'eventsStore',
  'common',
  'deliveriesStore',
  'activitiesStore',
  'deliveryDetailsStore',
  'projectDateStore',
  'sitemapsStore',
  'locationAttributesStore',
  'sitemapItemsStore',
  'deliveryFollowingsStore',
  'deliveryAssignmentsStore',
  'companiesStore',
  'deliveryVehicleTypesStore',
  'materialCategoryStore',
  'materialsStore',
  'userProjectsStore',
  'projectMembersStore',
  'weatherForecastsStore',
)
@observer
export default class CompactDeliveriesView extends React.Component<IProps> {
  private readonly deliveriesViewStore: DeliveriesViewStore
  private readonly deliveryFilterStore: DeliveryFilterStore = null
  private readonly clearPostEventCallback: () => void = NOOP
  private hashChangeListener = null

  public constructor(props: IProps) {
    super(props)

    this.deliveryFilterStore = new DeliveryFilterStore(
      props.eventsStore,
      props.deliveriesStore,
      true,
      props.locationAttributesStore,
      props.deliveryFollowingsStore,
      props.deliveryAssignmentsStore,
      props.companiesStore,
      props.projectMembersStore,
    )

    this.deliveriesViewStore = new DeliveriesViewStore(
      props.deliveriesStore,
      props.eventsStore,
      props.activitiesStore,
      props.deliveryDetailsStore,
      props.common,
      props.sitemapsStore,
      props.projectDateStore,
      props.locationAttributesStore,
      this.deliveryFilterStore,
      props.sitemapItemsStore,
      props.deliveryFollowingsStore,
      props.deliveryAssignmentsStore,
      props.companiesStore,
      props.deliveryVehicleTypesStore,
      props.materialCategoryStore,
      props.materialsStore,
      props.shouldUseSavedFilters,
      props.userProjectsStore,
      props.projectMembersStore,
      false,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onDataFiltersReceived,
    )

    this.setViewModeFromHash()
  }

  private setViewModeFromHash = () => {
    const hash = window.location.hash.replace('#', '')
    if (
      Object.values(DeliveryPageViewMode).includes(hash as DeliveryPageViewMode)
    ) {
      this.deliveriesViewStore.setViewMode(hash as DeliveryPageViewMode)
    }
  }

  public componentDidMount() {
    this.hashChangeListener = window.addEventListener(
      'hashchange',
      this.setViewModeFromHash,
    )
  }

  public componentWillUnmount() {
    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }

    if (this.hashChangeListener) {
      window.removeEventListener('hashchange', this.hashChangeListener)
    }
  }

  public render() {
    const { className } = this.props
    const { isLoading, onEmptyNewDeliveryCreate } = this.deliveriesViewStore

    return (
      <div
        className={classList({
          'col full-height relative overflow-hidden': true,
          [className]: !!className,
        })}
      >
        {this.renderActivityOrDesktopModeHeader()}
        {isLoading ? (
          <Spinner className="ma-auto" size={50} />
        ) : (
          <>
            {this.header}
            {this.renderContent()}
            {this.renderActionBar()}
            {this.props.renderFooter?.(onEmptyNewDeliveryCreate)}
          </>
        )}
      </div>
    )
  }

  private renderContent() {
    const { FileInputType } = this.props
    const { selectedViewMode, currentStartDate } = this.deliveriesViewStore

    switch (selectedViewMode) {
      case DeliveryPageViewMode.Calendar:
        return this.renderCalendarView()
      case DeliveryPageViewMode.Map:
        return (
          <DeliveriesMapView
            currentDate={currentStartDate}
            deliveriesViewStore={this.deliveriesViewStore}
            FileInputType={FileInputType}
          />
        )
      case DeliveryPageViewMode.List:
        return (
          <DeliveryListComponent
            deliveriesViewStore={this.deliveriesViewStore}
            FileInputType={FileInputType}
          />
        )
    }
  }

  private renderCalendarView() {
    const { shouldHandleMouse, eventsStore } = this.props
    const {
      onNewDeliveryCreate,
      onEventClicked,
      currentStartDate,
      calendarStore,
      prevDate,
      nextDate,
      calendarEvents,
    } = this.deliveriesViewStore

    const mode = eventsStore.appState.isPresentationMode
      ? CalendarDayMode.VIEW
      : CalendarDayMode.CREATE

    return (
      <CalendarDayView
        events={calendarEvents}
        activeDate={currentStartDate}
        store={calendarStore}
        mode={mode}
        onNewEventCreate={onNewDeliveryCreate}
        onEventClicked={onEventClicked}
        onLeftSwipe={nextDate}
        onRightSwipe={prevDate}
        shouldHandleMouse={shouldHandleMouse}
      />
    )
  }

  @action.bound
  private backClicked() {
    const { toActivityDetails } = this.props.common
    toActivityDetails()
  }

  private renderActivityOrDesktopModeHeader() {
    const { mode, isActivityMode } = this.deliveriesViewStore

    return (
      isActivityMode && (
        <div className="row pa10 pl20 bb-light-grey">
          <div className="text primary-blue extra-large bold uppercase">
            {mode === DeliveryPageMode.REQUEST_FROM_ACTIVITY
              ? Localization.translator.chooseADeliveryTime
              : Localization.translator.deliveries}
          </div>
          <div className="no-grow" onClick={this.backClicked}>
            <Icon icon={IconNames.MENU} />
          </div>
        </div>
      )
    )
  }

  private get header(): JSX.Element {
    const { clickOnMenuIcon, weatherForecastsStore, projectDateStore } =
      this.props
    const { currentStartDate } = this.deliveriesViewStore

    return (
      <DeliveryHeader
        currentStartDate={currentStartDate}
        deliveriesViewStore={this.deliveriesViewStore}
        deliveryFilterStore={this.deliveryFilterStore}
        projectDateStore={projectDateStore}
        weatherForecastsStore={weatherForecastsStore}
        clickOnMenuIcon={clickOnMenuIcon}
      />
    )
  }

  private renderActionBar() {
    const { isViewWithHighlightedDeliveryMode, highlightedDelivery } =
      this.deliveriesViewStore
    const { isPresentationMode } = this.props.eventsStore.appState

    return (
      isViewWithHighlightedDeliveryMode &&
      !isPresentationMode && (
        <DeliveryActions
          deliveryId={highlightedDelivery.id}
          hideViewButton={true}
          className=" bt-light-grey"
        />
      )
    )
  }

  @action.bound
  private onDataFiltersReceived() {
    if (!this.deliveriesViewStore.isLoading) {
      this.deliveriesViewStore.setSavedFilterValues()
      this.clearPostEventCallback()
    }
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { LocationType, OperationSubjectType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import {
  AndOrOperator,
  getOperatorDisplayText,
} from '~/client/src/shared/models/LogicOperation'
import OperationRulesStore from '~/client/src/shared/stores/domain/OperationRules.store'
import TagsStore, {
  deliveryRelatedTagTypes,
  permitRelatedTagTypes,
} from '~/client/src/shared/stores/domain/Tags.store'

import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'
import BaseNotificationRulesStore from './components/NotificationRules/BaseNotificationRules.store'
import UnsafeNotificationRules from './components/NotificationRules/NotificationRules'

import Colors from '~/client/src/shared/theme.module.scss'

const NotificationRules = withErrorBoundary(UnsafeNotificationRules)

export enum NotificationSetupModes {
  form = 'form',
  delivery = 'delivery',
}
// translated

interface IProps {
  projectSetUpPageStore?: ProjectSetUpPageStore
  mode: NotificationSetupModes

  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
  operationRulesStore?: OperationRulesStore
}

@inject('eventsStore', 'tagsStore', 'operationRulesStore')
@observer
export default class NotificationsSetup extends React.Component<IProps> {
  public render() {
    return (
      <Wrapper>
        <Header
          title={Localization.translator.notificationRules}
          tooltipContent={this.tooltipContent}
        />
        {this.renderContent()}
      </Wrapper>
    )
  }

  private get tooltipContent(): JSX.Element {
    const entityTypeTranslation =
      this.props.mode === NotificationSetupModes.delivery
        ? Localization.translator.deliveries
        : Localization.translator.forms

    const { zone, officeField, notificationsRulesSetupDescriptions } =
      Localization.translator

    const { helperText1, helperText2 } = notificationsRulesSetupDescriptions

    const mockBuildingTag = {
      type: LocationType.Building,
      iconName: SitemapAttributeIcon.Building,
      name: officeField,
      color: Colors.purple92,
    }

    const mockZoneTag = {
      type: LocationType.Zone,
      iconName: SitemapAttributeIcon.Zone,
      color: Colors.purple92,
    }

    const mockZonesNames = [`${zone} 1`, `${zone} 2`]

    return (
      <div className="col" style={{ width: 370 }}>
        <div className="pb20">
          {helperText1(entityTypeTranslation.toLocaleLowerCase())}
        </div>
        <div className="pb10">{helperText2}</div>
        <div className="pt5">
          <SitemapAttributeTag
            dataObject={mockBuildingTag as LocationAttributeBase}
            shouldShowAsTag={true}
          >
            <span>{mockBuildingTag.name}</span>
          </SitemapAttributeTag>
        </div>
        <div className="row pt5">
          <span className="no-grow mr8">
            <SitemapAttributeTag
              contentContainerClassName="no-grow"
              dataObject={mockZoneTag as LocationAttributeBase}
              shouldShowAsTag={true}
            >
              <span>{mockZonesNames[0]}</span>
            </SitemapAttributeTag>
          </span>
          {getOperatorDisplayText(AndOrOperator.AND)}
          <span className="ml8">
            <SitemapAttributeTag
              contentContainerClassName="no-grow"
              dataObject={mockBuildingTag as LocationAttributeBase}
              shouldShowAsTag={true}
            >
              <span>{mockBuildingTag.name}</span>
            </SitemapAttributeTag>
          </span>
        </div>
        <div className="pt5">
          <span className="no-grow mr8">
            <SitemapAttributeTag
              contentContainerClassName="no-grow"
              dataObject={mockZoneTag as LocationAttributeBase}
              shouldShowAsTag={true}
            >
              <span>{mockZonesNames[0]}</span>
            </SitemapAttributeTag>
          </span>
          {getOperatorDisplayText(AndOrOperator.AND)} (
          <span className="no-grow mx8">
            <SitemapAttributeTag
              contentContainerClassName="no-grow"
              dataObject={mockBuildingTag as LocationAttributeBase}
              shouldShowAsTag={true}
            >
              <span>{mockBuildingTag.name}</span>
            </SitemapAttributeTag>
          </span>
          {getOperatorDisplayText(AndOrOperator.OR)}
          <span className="no-grow mx8">
            <SitemapAttributeTag
              contentContainerClassName="no-grow"
              dataObject={mockZoneTag as LocationAttributeBase}
              shouldShowAsTag={true}
            >
              <span>{mockZonesNames[1]}</span>
            </SitemapAttributeTag>
          </span>
          )
        </div>
      </div>
    )
  }

  private renderContent() {
    const {
      mode,
      tagsStore: { isDataReceived: areTagsReceived },
      operationRulesStore: { isDataReceived: areRulesReceived },
    } = this.props

    const isDataReceived = areTagsReceived && areRulesReceived

    return isDataReceived ? (
      <NotificationRules store={this.store} mode={mode} />
    ) : (
      <Loader hint={Localization.translator.loading} />
    )
  }

  private get store(): BaseNotificationRulesStore {
    const {
      eventsStore: { appState },
      operationRulesStore,
      tagsStore,
      mode,
    } = this.props
    let tags: TagType[]
    let operationSubjectType: OperationSubjectType

    switch (mode) {
      case NotificationSetupModes.delivery:
        tags = deliveryRelatedTagTypes
        operationSubjectType = OperationSubjectType.Delivery
        break
      case NotificationSetupModes.form:
        tags = permitRelatedTagTypes
        operationSubjectType = OperationSubjectType.Permit
        break
    }

    return new BaseNotificationRulesStore(
      appState,
      operationRulesStore,
      tagsStore,
      tags,
      operationSubjectType,
    )
  }
}

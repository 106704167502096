import { computed } from 'mobx'

import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'

export default class HierarchyNode {
  public nodeId: string = null
  public isSite: boolean = false
  public children: HierarchyNode[] = []

  public constructor(
    private expandState: Map<string, boolean>,
    public item?: SitemapItemBase,
    public parent: HierarchyNode = null,
    public nodeName?: string,
  ) {
    this.isSite = !item
    this.nodeId = nodeName || item.id
    if (parent) {
      this.nodeId = parent.nodeId + this.nodeId
    }
  }

  public get isCollapsed(): boolean {
    return this.expandState.get(this.nodeId)
  }

  public changeCollapsedState = () => {
    const currentState = this.expandState.get(this.nodeId)
    if (!currentState) {
      this.expandState.set(this.nodeId, true)
    } else {
      this.expandState.delete(this.nodeId)
    }
  }

  public get hasChildren(): boolean {
    return !!this.children.length
  }

  public get childrenCount(): number {
    return this.children.length
  }

  @computed
  public get groupNodes(): HierarchyNode[] {
    return [this as HierarchyNode].concat(
      ...this.children.map(n => this.getGroupNodes(n)),
    )
  }

  private getGroupNodes = (node: HierarchyNode): HierarchyNode[] => {
    const groupNodes = [node]

    if (!node.hasChildren) {
      return groupNodes
    }

    return groupNodes.concat(...node.children.map(n => this.getGroupNodes(n)))
  }
}

import * as React from 'react'

import { Icon, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import { Loader } from '~/client/src/shared/components/Loader'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubAttributeSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import StruxhubTextArea from '~/client/src/shared/components/StruxhubInputs/StruxhubTextArea'
import Timestamp from '~/client/src/shared/components/Timestamp'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ScanHistory from '~/client/src/shared/models/ScanHistory'
import { QRResponse } from '~/client/src/shared/models/User'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScanHistoriesStore from '~/client/src/shared/stores/domain/ScanHistories.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import EventsStore from '../../../../stores/EventStore/Events.store'
import InitialState from '../../../../stores/InitialState'
import CompaniesStore from '../../../../stores/domain/Companies.store'
import TagsStore from '../../../../stores/domain/Tags.store'
import CommonStore from '../../../../stores/ui/Common.store'
import ConfirmDialog from '../../../ConfirmDialog/ConfirmDialog'
import { NO_SCROLL } from '../../../ListWithFixedColumns/BaseListWithFixedColumns'
import AllowedUsersPicker from '../../AllowedUsersPicker'
import QrCodesStore from '../../QRCodes.store'
import EndRideConfirm from '../EndRideConfirm'
import { QRCodesViewEditFormStore, TabIds } from './QRCodesViewEditForm.store'

import './QRCodesViewEditForm.scss'

interface IProps {
  store: QrCodesStore

  isFullscreenAllowed: boolean

  state?: InitialState
  common?: CommonStore
  projectMembersStore?: ProjectMembersStore
  scannersStore?: ScannersStore
  eventsStore?: EventsStore
  scanHistoriesStore?: ScanHistoriesStore
  userProjectsStore?: UserProjectsStore
  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore

  tagsStore?: TagsStore
}

const exitScannerLabel = 'Exit Scanner'
const deleteScanner = 'Delete Scanner'
const editScannerText = 'Edit Scanner'
const shouldDeleteScanner = 'Should delete scanner'
const activateScanner = 'Activate scanner'
const allowedUsers = 'Allowed Users'
const badgeDescription = 'Badge description'
const selectLocation = 'Select Location'
const makeItTimed =
  "Make this a 'Timed' scanner for users." +
  'Scan-Start : Scan-Stop = Total Time'
const limitAccess =
  "Limit access to specified 'Badged' Users, Teams or Companies"
const endRideConfirm = 'Are you sure you want to end the ride?'
const changeModeConfirm = 'Are you sure you want to change the ride mode?'
const isScannerTimedByDay = 'Timer automatically ENDS at close of work day'

const endRide = 'End Ride'
const scanTo = 'Scan to'

const totalPassengers = 'Total Passengers'
const scannerActive = 'Scanner Active'
const pauseScanner = 'Pause Scanner'
const resumeScanner = 'Resume Scanner'
const endRideForAll = 'End ride for all'

const DEFAULT_ROW_HEIGHT = 47
const MULTILINE_ROW_HEIGHT = 63.5
const DATE_ROW_HEIGHT = 65

@inject(
  'state',
  'common',
  'projectMembersStore',
  'scannersStore',
  'eventsStore',
  'scanHistoriesStore',
  'userProjectsStore',
  'projectDateStore',
  'companiesStore',
  'tagsStore',
)
@observer
export default class QRCodesViewEditForm extends React.Component<IProps> {
  @observable private scrollToIndex: number = NO_SCROLL
  private readonly clearPostEventCallback: () => void = null
  private readonly cellMeasurerCache: CellMeasurerCache = null
  private store: QRCodesViewEditFormStore = null
  private listRef: any = null

  public constructor(props: IProps) {
    super(props)

    this.store = new QRCodesViewEditFormStore(
      props.store,
      props.common,
      props.scanHistoriesStore,
      props.projectDateStore,
      props.projectMembersStore,
      props.tagsStore,
      props.scannersStore,
      props.userProjectsStore,
      props.state,
    )

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: DEFAULT_ROW_HEIGHT,
      fixedWidth: true,
    })

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.handlePostEventCallback,
    )
  }

  public componentDidMount(): void {
    this.store.initiateForm()
  }

  public componentWillUnmount(): void {
    this.clearPostEventCallback?.()
  }

  public render(): JSX.Element {
    const { html5QrcodeScanner } = this.store
    const { isLoading, isViewMode, isTimedScanner } = this.props.store

    if (isLoading) {
      return <Loader />
    }

    return (
      <>
        <div className="qr-edit-form col px10 relative full-height">
          <div className="col full-height">
            {isViewMode && this.renderHeader()}
            {this.renderBody()}
          </div>
        </div>
        {html5QrcodeScanner &&
          isTimedScanner &&
          this.renderActiveScannerFooter()}
      </>
    )
  }

  private renderBody() {
    const {
      location,
      isLocationPickerShown,
      isUserDirectoryShown,
      onSingleSelect,
      toggleLocationPickerState,
      toggleUserDirectory,
      isEndConfirmShown,
      toggleEndRideConfirm,
      isRideModeModalShown,
      toggleRideModeModalShown,
      isViewMode,
    } = this.props.store
    const { selectedTabId, onEndRide, toggleMode } = this.store
    const selectedIds = location?.id ? [location.id] : []
    const isFormView = selectedTabId === TabIds.Scan

    if (isLocationPickerShown) {
      return (
        <LocationPicker
          className="full-height"
          title={selectLocation}
          applyButtonTitle={Localization.translator.select}
          isSingleSelectionMode={true}
          selectedIds={selectedIds}
          onSingleSelect={onSingleSelect}
          onChange={NOOP}
          onApplyChanges={toggleLocationPickerState}
          onClose={toggleLocationPickerState}
        />
      )
    }
    if (isUserDirectoryShown) {
      return (
        <AllowedUsersPicker
          appliedUsers={this.store.selectedUsers}
          onClose={toggleUserDirectory}
          onApply={this.handleApplyingAllowedUsers}
        />
      )
    }

    return (
      <>
        <EndRideConfirm
          title={endRideConfirm}
          onHide={toggleEndRideConfirm}
          onApply={onEndRide.bind(null, false)}
          applyButtonText={Localization.translator.yes}
          cancelButtonText={Localization.translator.cancel}
          isShown={isEndConfirmShown}
        />
        <EndRideConfirm
          title={changeModeConfirm}
          onHide={toggleRideModeModalShown}
          onApply={toggleMode}
          applyButtonText={Localization.translator.yes}
          cancelButtonText={Localization.translator.cancel}
          isShown={isRideModeModalShown}
        />
        {this.renderDeletionConfirmDialog()}
        {!isViewMode || isFormView
          ? this.renderScannerForm()
          : this.renderWholeLog()}
      </>
    )
  }

  private renderHeader(): JSX.Element {
    return (
      <div className="row py10">
        {Object.keys(TabIds).map(tabId => this.renderTab(tabId))}
      </div>
    )
  }

  private renderTab(tabId: string): JSX.Element {
    const { toggleSelectedTab } = this.store

    return (
      <div
        onClick={toggleSelectedTab}
        className={classList({
          'text large center pa10 pointer': true,
          'bb-light-input-border': tabId !== this.store.selectedTabId,
          'bb2-primary-blue primary-blue': tabId === this.store.selectedTabId,
        })}
        key={tabId}
      >
        {TabIds[tabId]}
      </div>
    )
  }

  private renderWholeLog = (): JSX.Element => {
    const isLoading = this.props.state.loading.get(e.SAVE_SCAN_HISTORIES)
    return (
      <div className="col overflow-auto qr-log">
        {isLoading ? <Loader /> : this.renderScannerLog()}
      </div>
    )
  }

  private renderScannerLog = (): JSX.Element => {
    const { rows } = this.store
    const { scrollToIndex } = this

    return (
      <div className="relative full-height">
        <AutoSizer>
          {({ width, height }) => (
            <List
              className="px10"
              deferredMeasurementCache={this.cellMeasurerCache}
              data={rows}
              width={width}
              height={height}
              ref={this.setListRef}
              rowCount={rows.length}
              onScroll={this.onScroll}
              scrollToAlignment="start"
              rowHeight={this.getRowHeight}
              rowRenderer={this.rowRenderer}
              scrollToIndex={scrollToIndex}
            />
          )}
        </AutoSizer>
      </div>
    )
  }

  private onScroll = (): void => {
    Promise.resolve().then(() => (this.scrollToIndex = NO_SCROLL))
  }

  private getRowHeight = ({ index }): number => {
    const row = this.store.rows[index]

    if (!row.scanHistory) {
      return DATE_ROW_HEIGHT
    }

    const { getById } = this.props.projectMembersStore
    const { isThisYear } = this.props.projectDateStore
    const user = getById(row.scanHistory.userId)

    if (user?.employeeId || !isThisYear(row.scanHistory.date)) {
      return MULTILINE_ROW_HEIGHT
    }

    return DEFAULT_ROW_HEIGHT
  }

  private rowRenderer = ({ index, key, style, parent }: any): JSX.Element => {
    const { rows } = this.store
    const { date, scanHistory } = rows[index]
    let content: JSX.Element

    if (date) {
      content = this.renderDateRow(date)
    } else {
      content = this.renderHistoryRecord(scanHistory)
    }

    return (
      <CellMeasurer
        cache={this.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div
            style={style}
            ref={registerChild}
            className={classList({
              'col px10': true,
              pt10: !scanHistory,
            })}
          >
            {content}
          </div>
        )}
      </CellMeasurer>
    )
  }

  private renderDateRow = (date: Date): JSX.Element => {
    const { getWeekdayMonthAndDayToDisplay } = this.props.projectDateStore

    return (
      <DaySeparator
        shouldHideBorder={true}
        date={date}
        customDateTimeFormatter={getWeekdayMonthAndDayToDisplay}
        className="bb-light-input-border"
      />
    )
  }

  private renderScannerForm(): JSX.Element {
    return (
      <>
        <div className="col px10 overflow-auto">
          {this.renderScannerHeader()}
          {this.renderScannerFields()}
          {this.renderScannerFooter()}
        </div>
        {this.renderScannerScreen()}
      </>
    )
  }

  private renderDeletionConfirmDialog(): JSX.Element {
    const {
      isScannerDeleteModalShown,
      isLoading,
      performDelete,
      toggleDeleteModal,
    } = this.props.store

    return (
      <ConfirmDialog
        isOpen={isScannerDeleteModalShown}
        onCancelClicked={toggleDeleteModal}
        onDoneClicked={performDelete}
        doneTitle={Localization.translator.delete}
        loading={isLoading}
      >
        <div className="text large pre-line">{shouldDeleteScanner}</div>
      </ConfirmDialog>
    )
  }

  private renderScannerScreen(): JSX.Element {
    const {
      isFullscreenAllowed,
      store: { isFullScreenMode, response },
    } = this.props
    const { selectedTabId, html5QrcodeScanner, toggleFullScreenMode } =
      this.store
    const icon = isFullScreenMode ? IconNames.CROSS : IconNames.FULLSCREEN
    const isRedBackground = response === QRResponse.notAllowed
    const isGreenBackground = response === QRResponse.allowed
    const shouldUseMargin = selectedTabId === TabIds.Scan && html5QrcodeScanner
    const style = isFullScreenMode
      ? {}
      : { maxHeight: 400, marginBottom: shouldUseMargin ? 150 : 0 }

    return (
      <div
        className={classList({
          'col x-center no-grow': true,
          'y-center fixed full-width full-height fullscreen-scanner bg-light-cool-grey':
            isFullScreenMode,
          'bg-red': isRedBackground,
          'bg-palette-green': isGreenBackground,
        })}
        style={style}
      >
        {html5QrcodeScanner && isFullscreenAllowed && (
          <div onClick={toggleFullScreenMode} className="no-grow pa10">
            <Icon icon={icon} size={20} />
          </div>
        )}
        <div id="reader" style={{ width: '80%' }} />
      </div>
    )
  }

  private renderScannerHeader(): JSX.Element {
    const {
      toggleEndRideConfirm,
      editScanner,
      toggleDeleteModal,
      selectedScanner,
    } = this.props.store
    const { exitScanner, scanningActivationTime, html5QrcodeScanner } =
      this.store

    return (
      <div className="row x-around">
        <div
          className="pointer row x-start ba-light-input-border brada10 py5 px10 w-fit-content"
          onClick={exitScanner}
        >
          <Icon icon={IconNames.ARROW_LEFT} />
          <span className="text large ml10">{exitScannerLabel}</span>
        </div>
        {html5QrcodeScanner && scanningActivationTime && (
          <div
            className="pointer row x-end ba-light-input-border brada10 py5 px10 w-fit-content"
            onClick={toggleEndRideConfirm}
          >
            <Icon icon={IconNames.KNOWN_VEHICLE} />
            <div className="text large w-fit-content ml10">{endRide}</div>
          </div>
        )}
        {!html5QrcodeScanner && selectedScanner && (
          <div
            className="pointer row x-end ba-light-input-border brada10 py5 px10 w-fit-content"
            onClick={editScanner}
          >
            <Icon icon={IconNames.EDIT} />
            <span className="text large ml10">{editScannerText}</span>
          </div>
        )}
        {!html5QrcodeScanner && selectedScanner && (
          <div
            className="pointer row x-end ba-light-input-border brada10 py5 px10 w-fit-content"
            onClick={toggleDeleteModal}
          >
            <Icon icon={IconNames.DELETE} />
            <span className="text large ml10">{deleteScanner}</span>
          </div>
        )}
      </div>
    )
  }

  private renderScannerFooter(): JSX.Element {
    const {
      isSaveButtonEnabled,
      newScanner,
      isViewMode,
      onSaveClick,
      toggleRideModeModalShown,
      isTimedScanner,
    } = this.props.store
    const { html5QrcodeScanner, initiateScanner, activeMode } = this.store

    return (
      <>
        {isViewMode && (
          <>
            {isTimedScanner && (
              <div
                className="text large pa10 pointer row x-center no-grow brada10 ba-light-input-border"
                onClick={toggleRideModeModalShown}
              >
                {scanTo}: {activeMode}
              </div>
            )}
            {!html5QrcodeScanner && (
              <BaseActionButton
                title={activateScanner}
                className="secondary-blue-theme bold pa10 brada10 my10"
                isEnabled={true}
                onClick={initiateScanner}
              />
            )}
          </>
        )}
        {newScanner && (
          <BaseActionButton
            title={Localization.translator.save}
            className="secondary-blue-theme bold pa10 brada10 my10"
            isEnabled={isSaveButtonEnabled}
            onClick={onSaveClick}
          />
        )}
      </>
    )
  }

  private renderScannerFields(): JSX.Element {
    const {
      projectMembersStore: { getById },
      store: {
        location,
        toggleLocationPickerState,
        onNameChange,
        name,
        maxElapsedTime,
        onMaxElapsedTimeChange,
        badgeName,
        onBNameChange,
        scanner,
        isViewMode,
        isOpenScanner,
        isTimedScanner,
        isTimedByDay,
        toggleIsTimedScanner,
        toggleIsOpenScanner,
        toggleIsTimedByDay,
        toggleUserDirectory,
      },
    } = this.props
    const { isUsersListExpanded, toggleUsersList } = this.store

    const icon = isUsersListExpanded
      ? IconNames.CHEVRON_DOWN
      : IconNames.CHEVRON_UP

    return (
      <>
        <StruxhubInput
          label={Localization.translator.name}
          isHelperTextHidden={true}
          isRequired
          value={name}
          onChange={onNameChange}
          className="no-grow"
          isReadOnly={isViewMode}
        />
        <StruxhubTextArea
          className="no-grow"
          label={badgeDescription}
          isRequired
          isHelperTextCondensed={true}
          value={badgeName || EMPTY_STRING}
          onChange={isViewMode ? NOOP : onBNameChange}
          rows={4}
        />
        {!isViewMode && (
          <StruxhubAttributeSelector
            onClick={toggleLocationPickerState}
            label={Localization.translator.location}
            value={location?.id}
            className="no-grow"
            isReadOnly={isViewMode}
            isHelperTextHidden={true}
          />
        )}
        {isTimedScanner && (
          <StruxhubInput
            label={Localization.translator.maxElapsedTime}
            isHelperTextHidden
            isRequiredTextHidden
            type="number"
            value={`${maxElapsedTime || ''}`}
            onChange={onMaxElapsedTimeChange}
            className="no-grow"
            isReadOnly={isViewMode}
          />
        )}
        {!isViewMode && (
          <div className="row pa10">
            <div className="text left large line-extra-large bold blue-highlight">
              {limitAccess}
            </div>
            <Switch
              checked={!isOpenScanner}
              onChange={toggleIsOpenScanner}
              className="no-grow primary-blue-switch bp3-align-right no-outline-container"
            />
          </div>
        )}
        {!isViewMode && (
          <div className="row pa10">
            <div className="text left large line-extra-large bold blue-highlight">
              {makeItTimed}
            </div>
            <Switch
              checked={isTimedScanner}
              onChange={toggleIsTimedScanner}
              className="no-grow primary-blue-switch bp3-align-right no-outline-container"
            />
          </div>
        )}
        {!isViewMode && isTimedScanner && (
          <div className="row pa10">
            <div className="text left large line-extra-large bold blue-highlight">
              {isScannerTimedByDay}
            </div>
            <Switch
              checked={isTimedByDay}
              onChange={toggleIsTimedByDay}
              className="no-grow primary-blue-switch bp3-align-right no-outline-container"
            />
          </div>
        )}
        {!isOpenScanner && (
          <div className="col py10 no-grow">
            <div className="row bb-light-input-border pa10">
              <div className="text large">{allowedUsers}</div>
              <Icon
                onClick={toggleUsersList}
                icon={icon}
                className="no-grow pointer"
              />
              {!isViewMode && (
                <div
                  className="no-grow text nowrap large blue bold right pointer pl15"
                  onClick={toggleUserDirectory}
                >
                  <Icon icon={IconNames.PLUS}></Icon>{' '}
                  {Localization.translator.addNew}
                </div>
              )}
            </div>
            {isUsersListExpanded && !!scanner.allowedUsers?.length && (
              <div className="col overflow-auto bb-light-input-border">
                {scanner.allowedUsers.map(userId => (
                  <UserProfilePreview
                    key={userId}
                    user={getById(userId)}
                    className="overflow-unset py2"
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  private renderHistoryRecord({
    id,
    isAllowed,
    userId,
    date,
    endDate,
  }: ScanHistory): JSX.Element {
    const { isOpenScanner } = this.props.store.scanner
    const { getById } = this.props.projectMembersStore

    return (
      <div
        key={id}
        className="col unclickable-element pa5 bb-light-input-border no-grow"
      >
        <div className="row">
          <UserProfilePreview user={getById(userId)} withEmployeeId={true} />
          <div className="row text small x-end">
            {endDate && (
              <div className="text center">
                {Localization.translator.startTime}
              </div>
            )}
            <Timestamp time={date} className="no-grow pl10" />
          </div>
          {endDate && (
            <div className="row text small x-end">
              <div className="text center">
                {Localization.translator.endTime}
              </div>
              <Timestamp time={endDate} className="no-grow pl10" />
            </div>
          )}
          {!isOpenScanner &&
            (isAllowed ? (
              <div className="dot no-grow mx10 active" />
            ) : (
              <div className="dot no-grow mx10" />
            ))}
        </div>
      </div>
    )
  }

  private renderActiveScannerFooter = (): JSX.Element => {
    const {
      store: { toggleEndRideConfirm, className: storeClassName },
    } = this.props
    const {
      userBusEnterMap,
      isScannerFooterHidden,
      onScannerFooterToggle,
      isScannerPaused,
      pauseResumeScanner,
    } = this.store

    const passengersCount: number = Object.keys(userBusEnterMap)?.length
    const className = `absolute normalized-shadow qr-codes-scanner-active hidden-scanner ${storeClassName}`

    if (isScannerFooterHidden) {
      return (
        <div className={className}>
          <div className="row x-end py15">
            <Icon
              onClick={onScannerFooterToggle}
              icon={IconNames.CHEVRON_UP}
              className="no-grow pointer"
            />
          </div>
        </div>
      )
    }

    return (
      <div className="absolute normalized-shadow qr-codes-scanner-active">
        <div className="col">
          <div className="row">
            <div className="text large pa10">
              {totalPassengers}
              <span className="text bold large ml5">{passengersCount}</span>
            </div>
            <div className="row x-end">
              <Icon
                onClick={onScannerFooterToggle}
                icon={IconNames.CHEVRON_DOWN}
                className="no-grow pointer"
              />
            </div>
          </div>
          <div className="text center light full-width large pa5">
            {scannerActive}
          </div>
          <div className="row x-evenly">
            <BaseActionButton
              onClick={toggleEndRideConfirm}
              title={endRideForAll}
              isEnabled
            />
            <BaseActionButton
              onClick={pauseResumeScanner}
              title={isScannerPaused ? resumeScanner : pauseScanner}
              isEnabled
            />
          </div>
        </div>
      </div>
    )
  }

  private handlePostEventCallback = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    this.store.onScannerUpdated(eventContext)

    if (eventType === e.SCAN_HISTORIES_RECEIVED) {
      this.recomputeGridSize()
    }
  }

  private setListRef = (ref: any): void => (this.listRef = ref)

  private recomputeGridSize = (): void => {
    this.listRef?.recomputeGridSize()
    this.cellMeasurerCache?.clearAll()
  }

  @action.bound
  private handleApplyingAllowedUsers(appliedUsersIds: string[] = []) {
    this.props.store.onApplyAllowedUsers(appliedUsersIds)

    // To summarize the result of the applying
    this.store.showUsersList()
  }
}

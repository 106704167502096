import * as React from 'react'

import { Position, Tooltip } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import SitemapItemType from '~/client/src/shared/enums/SitemapItemType'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import SitemapItemsSetupStore from '../stores/SitemapItemsSetup.store'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 24
const gateEntry = 'Gate/Entry'
const textSticker = 'Text sticker'
const drawingSticker = 'Drawing sticker'
const informationPoints = 'Information Points'
const verticalObjects = 'Vertical objects'
const shaft = 'Shaft'
const restrooms = 'Restrooms'
const canteen = 'Canteen'
const toilet = 'Toilet'
const breakText = 'Break'
const dumpster = 'Dumpster'
const elevator = 'Elevator'
const electricalRoom = 'Electrical room'
const access = 'Access'
const washArea = 'Wash area'
const medicalArea = 'Medical area'
const musterPoint = 'Muster point'
const parking = 'Parking'
const smokingArea = 'Smoking area'
const stairway = 'Stairway'
const covidCheckpoint = 'Covid checkpoint'
const breakArea = 'Break area'
const walkway = 'Walkway'
const crane = 'Crane'
const hoist = 'Hoist'
const aerialLift = 'Aerial Lift'
const gradall = 'Gradall'
const forkLift = 'Fork Lift'
const trailer = 'Trailer'

interface IObject {
  Icon: React.FC<any>
  getTooltip?: () => string
  tooltipPosition?: Position
  dataObjectType?: LocationType
  iconName?: SitemapAttributeIcon
  sitemapItemType?: SitemapItemType
  className?: string
  children?: IObject[]
}

export interface IProps {
  store: SitemapItemsSetupStore
}

@observer
export default class ObjectPallet extends React.Component<IProps> {
  private readonly objects: IObject[] = [
    {
      Icon: TagIcon.Building,
      dataObjectType: LocationType.Building,
      getTooltip: () => Localization.translator.building,
    },
    {
      Icon: TagIcon.Zone,
      dataObjectType: LocationType.Zone,
      getTooltip: () => Localization.translator.zone,
    },
    {
      Icon: TagIcon.Staging,
      dataObjectType: LocationType.Staging,
      getTooltip: () => Localization.translator.staging,
    },
    {
      Icon: TagIcon.Area,
      dataObjectType: LocationType.Area,
      getTooltip: () => Localization.translator.workArea,
    },
    {
      Icon: TagIcon.Stairs,
      dataObjectType: LocationType.VerticalObject,
      iconName: SitemapAttributeIcon.Stairs,
      getTooltip: () => verticalObjects,
      tooltipPosition: Position.TOP,
      children: [
        {
          Icon: TagIcon.Stairs,
          dataObjectType: LocationType.VerticalObject,
          iconName: SitemapAttributeIcon.Stairs,
          getTooltip: () => stairway,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Elevator,
          dataObjectType: LocationType.VerticalObject,
          iconName: SitemapAttributeIcon.Elevator,
          getTooltip: () => elevator,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Shaft,
          dataObjectType: LocationType.VerticalObject,
          iconName: SitemapAttributeIcon.Shaft,
          getTooltip: () => shaft,
          tooltipPosition: Position.RIGHT,
        },
      ],
    },
    {
      Icon: TagIcon.Route,
      dataObjectType: LocationType.Route,
      getTooltip: () => Localization.translator.route,
      className: 'ml10',
    },
    {
      Icon: TagIcon.Gate,
      dataObjectType: LocationType.Gate,
      getTooltip: () => gateEntry,
    },
    {
      Icon: TagIcon.InteriorPath,
      dataObjectType: LocationType.InteriorPath,
      getTooltip: () => Localization.translator.interiorPath,
    },
    {
      Icon: TagIcon.InteriorDoor,
      dataObjectType: LocationType.InteriorDoor,
      getTooltip: () => Localization.translator.interiorDoor,
    },
    {
      Icon: TagIcon.Equipment,
      dataObjectType: LocationType.OffloadingEquipment,
      iconName: SitemapAttributeIcon.Equipment,
      getTooltip: () => Localization.translator.sharedResources,
      tooltipPosition: Position.TOP,
      className: 'ml10',
      children: [
        {
          Icon: TagIcon.Crane,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: SitemapAttributeIcon.Crane,
          getTooltip: () => crane,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Hoist,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: SitemapAttributeIcon.Hoist,
          getTooltip: () => hoist,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.AerialLift,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: SitemapAttributeIcon.AerialLift,
          getTooltip: () => aerialLift,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Gradall,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: SitemapAttributeIcon.Gradall,
          getTooltip: () => gradall,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.ForkLift,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: SitemapAttributeIcon.ForkLift,
          getTooltip: () => forkLift,
          tooltipPosition: Position.RIGHT,
        },
      ],
    },
    {
      Icon: TagIcon.LogisticsObject,
      dataObjectType: LocationType.LogisticsObject,
      iconName: SitemapAttributeIcon.Logistics,
      getTooltip: () => informationPoints,
      tooltipPosition: Position.TOP,
      children: [
        {
          Icon: TagIcon.Bathroom,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Bathroom,
          getTooltip: () => restrooms,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Break,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Break,
          getTooltip: () => breakText,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Canteen,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Canteen,
          getTooltip: () => canteen,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Dumpster,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Dumpster,
          getTooltip: () => dumpster,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Entrance,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Entrance,
          getTooltip: () => access,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.HandWash,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.HandWash,
          getTooltip: () => washArea,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Medical,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Medical,
          getTooltip: () => medicalArea,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.MeetingPoint,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.MeetingPoint,
          getTooltip: () => musterPoint,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Parking,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Parking,
          getTooltip: () => parking,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Smoking,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Smoking,
          getTooltip: () => smokingArea,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Temperature,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Temperature,
          getTooltip: () => covidCheckpoint,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Tent,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Tent,
          getTooltip: () => breakArea,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Toilet,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Toilet,
          getTooltip: () => toilet,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Walkway,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Walkway,
          getTooltip: () => walkway,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.ElectricalRoom,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.ElectricalRoom,
          getTooltip: () => electricalRoom,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Trailer,
          dataObjectType: LocationType.LogisticsObject,
          iconName: SitemapAttributeIcon.Trailer,
          getTooltip: () => trailer,
          tooltipPosition: Position.RIGHT,
        },
      ],
    },
    {
      Icon: TagIcon.Text,
      sitemapItemType: SitemapItemType.TextBox,
      getTooltip: () => textSticker,
      className: 'ml10',
    },
    {
      Icon: TagIcon.Line,
      sitemapItemType: SitemapItemType.Line,
      getTooltip: () => drawingSticker,
    },
  ]

  public render() {
    return (
      <div
        className={classList({
          'object-pallet absolute': true,
          'inactive-element': this.props.store.isSitemapBlocked,
        })}
      >
        {this.objects.map((object, idx) => {
          return (
            <div
              className={classList({
                'object relative': true,
                [object.className]: !!object.className,
              })}
              key={idx}
            >
              {this.renderObject(object, idx)}
              {this.renderObjectChildren(object)}
            </div>
          )
        })}
      </div>
    )
  }

  private renderObject = (object: IObject, key: React.Key): JSX.Element => {
    const isHighlighted = this.shouldHighlightObject(object)
    const color = isHighlighted ? Colors.neutral0 : Colors.neutral60

    const content = (
      <div
        key={key}
        className="object-icon"
        onClick={this.onObjectClicked.bind(this, object)}
      >
        <object.Icon color={color} size={ICON_SIZE} />
        {object.children && <div className="arrow" />}
      </div>
    )

    if (!object.getTooltip) {
      return content
    }

    return (
      <Tooltip
        key={key}
        className="bp3-dark"
        content={object.getTooltip()}
        position={object.tooltipPosition || Position.BOTTOM}
      >
        {content}
      </Tooltip>
    )
  }

  private renderObjectChildren = (object: IObject): JSX.Element => {
    if (!object.children) {
      return null
    }

    return (
      <div className="object-children absolute">
        {object.children.map((childObject, childIdx) => {
          return this.renderObject(childObject, childIdx)
        })}
      </div>
    )
  }

  private onObjectClicked(object: IObject) {
    if (this.props.store.isSitemapBlocked) {
      return
    }
    const { dataObjectType, sitemapItemType, iconName } = object

    if (sitemapItemType && sitemapItemType === SitemapItemType.Line) {
      this.props.store.createDataLessItem(sitemapItemType)
      return
    }
    if (sitemapItemType && sitemapItemType === SitemapItemType.TextBox) {
      this.props.store.enableCreatingText()
      return
    }
    if (dataObjectType) {
      this.props.store.enableCreatingAttribute(dataObjectType, iconName)
      return
    }
  }

  private shouldHighlightObject(object: IObject) {
    const {
      selectedSitemapItem,
      creatableAttributeType,
      creatableAttributeIcon,
      isTextStickerCreationActive,
    } = this.props.store

    const { sitemapItemType, dataObjectType, iconName } = object

    if (sitemapItemType === SitemapItemType.TextBox) {
      return isTextStickerCreationActive
    }

    if (
      sitemapItemType &&
      selectedSitemapItem &&
      !selectedSitemapItem.id &&
      selectedSitemapItem.sitemapItem.type === sitemapItemType
    ) {
      return true
    }

    return (
      creatableAttributeType === dataObjectType &&
      creatableAttributeIcon === iconName
    )
  }
}

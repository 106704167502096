import * as React from 'react'

import { classList } from 'react-classlist-helper'

import DynamicOverflowList from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList'
import InitialState from '~/client/src/shared/stores/InitialState'
import AreasStore from '~/client/src/shared/stores/domain/Areas.store'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import RoutesStore from '~/client/src/shared/stores/domain/Routes.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import BaseExpandableWorkflowCardStore from './BaseExpandableWorkflowCard.store'
import ExpandableCardAttributeValues from './components/ExpandableCardAttributeValues'

import './BaseExpandableWorkflowCard.scss'

export interface IBaseWorkflowCardProps {
  className?: string
  measureSizeFn?: () => void

  eventStyles?: React.CSSProperties

  isCalendarTooltip?: boolean

  state?: InitialState
  companiesStore?: CompaniesStore
  projectDateStore?: ProjectDateStore
  areasStore?: AreasStore
  gatesStore?: GatesStore
  zonesStore?: ZonesStore
  deliveryVehicleTypesStore?: DeliveryVehicleTypesStore
  offloadingEquipmentsStore?: OffloadingEquipmentsStore
  buildingsStore?: BuildingsStore
  routesStore?: RoutesStore
  levelsStore?: LevelsStore
  materialsStore?: MaterialsStore
  projectMembersStore?: ProjectMembersStore
  materialCategoryStore?: MaterialCategoryStore
}

export const workflowCardInjects = [
  'state',
  'companiesStore',
  'projectDateStore',
  'areasStore',
  'gatesStore',
  'zonesStore',
  'deliveryVehicleTypesStore',
  'offloadingEquipmentsStore',
  'buildingsStore',
  'routesStore',
  'levelsStore',
  'materialsStore',
  'projectMembersStore',
  'materialCategoryStore',
]

const MIN_ELEMENT_WIDTH = 50

export default class BaseExpandableWorkflowCard<
  T extends IBaseWorkflowCardProps,
> extends React.Component<T> {
  protected readonly store: BaseExpandableWorkflowCardStore

  public constructor(props: T) {
    super(props)

    this.store = new BaseExpandableWorkflowCardStore()
  }

  public componentDidMount() {
    this.props.measureSizeFn?.()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public componentDidUpdate(_prevProps: T) {
    this.props.measureSizeFn?.()
  }

  public render() {
    const { className, eventStyles, isCalendarTooltip } = this.props

    return (
      <div
        className={classList({
          'expandable-workflow-card row y-start no-flex pa10': true,
          [className]: !!className,
        })}
        onClick={this.onClick}
        style={isCalendarTooltip && { color: eventStyles.color }}
      >
        {this.icon}
        <div className="col ml4 relative overflow-hidden">
          {this.titleValues}
          {this.attributeValues}
        </div>
      </div>
    )
  }

  protected get icon(): JSX.Element {
    return null
  }

  protected get titleValues(): JSX.Element {
    return null
  }

  protected get attributeElements() {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onClick = (ev: React.SyntheticEvent) => {
    return null
  }

  private get attributeValues(): JSX.Element {
    const { isExpanded, rowsCount, setExpandedState } = this.store
    const { isCalendarTooltip } = this.props

    return (
      <DynamicOverflowList
        items={this.attributeElements}
        rowsCount={rowsCount}
        minContainerWidthConstraint={MIN_ELEMENT_WIDTH}
        shouldRecalculateRowsCount={true}
        allowRecalculationForItems={true}
        shouldIgnoreScrollBarWidth={isCalendarTooltip}
      >
        {({ visibleElements, overflowElements, containerRefSetter }) => (
          <ExpandableCardAttributeValues
            isExpanded={!isCalendarTooltip && isExpanded}
            visibleElements={visibleElements}
            overflowElements={!isCalendarTooltip && overflowElements}
            containerRefSetter={containerRefSetter}
            measureSizeFn={this.props.measureSizeFn}
            setExpandedState={setExpandedState}
          />
        )}
      </DynamicOverflowList>
    )
  }
}

import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { Stage } from 'konva/types/Stage'
import { action, computed, observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IPosition } from '~/client/graph'
import BaseSitemap from '~/client/src/shared/components/BaseSitemap/BaseSitemap'
import MapLayersMenu from '~/client/src/shared/components/MapLayersSelector/components/MapLayersMenu'
import SitemapElementsWrapper from '~/client/src/shared/components/SitemapElementsWrapper'
import SitemapItems, {
  MAX_PERCENT,
} from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import SitePermit from '~/client/src/shared/models/Permit'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'

import Activity from '../../models/Activity'
import Announcement from '../../models/Announcement'
import Delivery from '../../models/Delivery'
import { LocationIntegrationType } from '../../models/LocationObjects/LocationIntegration'
import EventsStore from '../../stores/EventStore/Events.store'
import ActivitiesStore from '../../stores/domain/Activities.store'
import CompaniesStore from '../../stores/domain/Companies.store'
import DeliveriesStore from '../../stores/domain/Deliveries.store'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import TagsStore from '../../stores/domain/Tags.store'
import BaseLogisticsStore from '../../stores/ui/BaseLogistics.store'
import BaseLogisticsMapStore from '../../stores/ui/BaseLogisticsMap.store'
import CommonStore from '../../stores/ui/Common.store'
import DeliverySitemapPinStore from '../../stores/ui/DeliverySitemapPin.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import { clickPosition } from '../../utils/SitemapCalculationHelpers'
import DeliveryDetailsStore from '../DeliveryDetails/DeliveryDetails.store'
import { ViewMode } from '../DraggableBar/DraggableBar'
import KonvaWorkflowActivityPin from '../Konva/KonvaWorkflowActivityPin'
import KonvaWorkflowAnnouncementPin from '../Konva/KonvaWorkflowAnnouncementPin'
import KonvaWorkflowDeliveriesPin from '../Konva/KonvaWorkflowDeliveriesPin'
import KonvaWorkflowPermitPin from '../Konva/KonvaWorkflowPermitPin'
import MapBoxEditorStore from '../MapBoxEditor/MapBoxEditor.store'
import MenuCloser from '../MenuCloser'
import CompactAnnouncementCard from '../SitemapCards/CompactAnnouncementCard'
import LogisticsMapSwipeableCards from '../SitemapCards/LogisticsSwipeableCards'
import SitemapActivitiesDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapActivitiesDraggableModal'
import SitemapDeliveriesDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapDeliveriesDraggableModal'
import SitemapLocationModal from '../SitemapDraggableModalWrapper/components/SitemapLocationModal'
import SitemapMaturixDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapMaturixStationDraggableModal'
import SitemapPermitsDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapPermitsDraggableModal'
import SitemapItemBase from '../SitemapHelpers/models/SitemapItemBase'
import LogisticsSitemapSetUpStore from './LogisticsSitemapSetUp.store'

const SAME_POSITION_OFFSET = 20
const OFFSET_Y = 4

interface IProps {
  sitemapId: string
  textboxesCache: ICanvasImageCache
  store: LogisticsSitemapSetUpStore
  logisticsMapStore: BaseLogisticsMapStore
  logisticsStore: BaseLogisticsStore
  mapBoxEditorStore: MapBoxEditorStore

  areAnnouncementsHidden?: boolean
  arePermitsHidden?: boolean
  areDeliveriesHidden?: boolean
  areActivitiesHidden?: boolean
  areMonitoringsHidden?: boolean

  toggleAnnouncementsHiddenState: () => void
  toggleDeliveriesHiddenState: () => void
  togglePermitsHiddenState: () => void
  toggleMonitoringsHiddenState: () => void
  renderShowOnMenu?: () => JSX.Element

  onPinClick?: (announcement: Announcement) => void
  openPermit?: (permit: SitePermit) => void
  openAnnouncement?: (announcement: Announcement) => void
  openDelivery?: (deliveryId: string) => void
  openActivity?: (code: string) => void
  deleteAnnouncement?: (announcement: Announcement) => void

  eventsStore?: EventsStore
  common?: CommonStore
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  projectDateStore?: ProjectDateStore
  deliveryDetailsStore?: DeliveryDetailsStore
  deliveriesStore?: DeliveriesStore
  tagsStore?: TagsStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
  activitiesStore?: ActivitiesStore

  shouldUseFullHeight?: boolean

  isCompactMode?: boolean
  isLayersMenuShown?: boolean
  toggleLayersMenu?: () => void

  openSiteDelivery?: (delivery: Delivery) => void
  selectViewMode?: (mode: ViewMode) => void
  shouldRenderSwipeableCards?: boolean

  isPermitOnly?: boolean
}

@inject(
  'eventsStore',
  'common',
  'basemapsStore',
  'projectDateStore',
  'deliveryDetailsStore',
  'deliveriesStore',
  'tagsStore',
  'companiesStore',
  'permitTypesStore',
  'activitiesStore',
  'sitemapsStore',
)
@observer
export default class LogisticsSitemap extends React.Component<IProps> {
  @observable public swipeableItemIndex: number = 0
  @observable public swipeableItemId: string = null
  @observable private deliverySitemap: BaseSitemap
  private readonly pinStore: DeliverySitemapPinStore = null
  public static contextType = MobXProviderContext

  @observable private containerRef: HTMLElement = null

  public constructor(props: IProps) {
    super(props)

    const { sitemapId, mapBoxEditorStore, store, eventsStore, sitemapsStore } =
      props
    this.pinStore = new DeliverySitemapPinStore(eventsStore.appState)
    const sitemap = sitemapsStore.byId.get(sitemapId)
    store.selectSitemap(sitemap)

    mapBoxEditorStore.setViewportFromAdress()
    mapBoxEditorStore.setDefaultMapMode()
    mapBoxEditorStore.hideToggleMapMode()
  }

  public componentDidUpdate(props: IProps) {
    const { sitemapId, mapBoxEditorStore, store, sitemapsStore } = this.props
    if (sitemapId !== props.sitemapId) {
      const sitemap = sitemapsStore.byId.get(sitemapId)
      store.selectSitemap(sitemap)
      mapBoxEditorStore.setViewportFromAdress()
      mapBoxEditorStore.setItems()
      mapBoxEditorStore.resetMapboxInfo()
      mapBoxEditorStore.removeRefs()
    }

    if (this.shouldShowAnnouncementCards && store.leftOffset === undefined) {
      this.calculateAnnouncementCardOffset()
    }
  }

  public render() {
    const {
      openPermit,
      openSiteDelivery,
      openAnnouncement,
      shouldRenderSwipeableCards,
      isLayersMenuShown,
      toggleDeliveriesHiddenState,
      toggleAnnouncementsHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      areDeliveriesHidden,
      areAnnouncementsHidden,
      arePermitsHidden,
      areMonitoringsHidden,
      toggleLayersMenu,
      isPermitOnly,
      mapBoxEditorStore,
    } = this.props

    return (
      <div
        className="full-height full-width relative logistics-map-container"
        ref={this.setContainerRef}
      >
        {isLayersMenuShown && (
          <div className="layers-container">
            <MenuCloser closeMenu={toggleLayersMenu}>
              <MapLayersMenu
                store={mapBoxEditorStore}
                isLogisticsView={!shouldRenderSwipeableCards}
                toggleDeliveriesHiddenState={
                  !isPermitOnly && toggleDeliveriesHiddenState
                }
                togglePermitsHiddenState={togglePermitsHiddenState}
                toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
                areDeliveriesHidden={isPermitOnly || areDeliveriesHidden}
                toggleAnnouncementsHiddenState={
                  !isPermitOnly && toggleAnnouncementsHiddenState
                }
                areAnnouncementsHidden={isPermitOnly || areAnnouncementsHidden}
                arePermitsHidden={arePermitsHidden}
                areMonitoringsHidden={isPermitOnly || areMonitoringsHidden}
                onClose={toggleLayersMenu}
                className="compact-view"
              />
            </MenuCloser>
          </div>
        )}
        {this.renderSitemap()}
        {shouldRenderSwipeableCards ? (
          <LogisticsMapSwipeableCards
            deliveries={isPermitOnly ? [] : this.availableDeliveries}
            announcements={isPermitOnly ? [] : this.displayedAnnouncements}
            permits={this.displayedPermits}
            openSitePermit={openPermit}
            swipeableItemId={this.swipeableItemId}
            showPreviousItem={this.showPreviousItem}
            showNextItem={this.showNextItem}
            openDelivery={!isPermitOnly && openSiteDelivery}
            openAnnouncement={!isPermitOnly && openAnnouncement}
          />
        ) : (
          this.renderCards
        )}
      </div>
    )
  }

  private get renderCards(): JSX.Element {
    const {
      openPermit,
      openActivity,
      deleteAnnouncement,
      logisticsStore: { startDate },
      openDelivery,
      deliveryDetailsStore: { displayedDelivery },
      shouldRenderSwipeableCards,
      activitiesStore: { selection: displayedActivityId },
      isPermitOnly,
    } = this.props

    if (shouldRenderSwipeableCards) {
      return
    }

    const {
      selectedAnnouncements = [],
      selectedPermits = [],
      selectedActivities,
      leftOffset,
      topOffset,
      arePermitsSelected,
      areActivitiesSelected,
      selectedAttribute,
      selectedDeliveries,
      areDeliveriesSelected,
      shouldShowSelectedAttribute,
      displayedPermitId,
      deselectAll,
    } = this.props.store

    const shouldShowDeliveryModal =
      areDeliveriesSelected && !!this.containerRef && !isPermitOnly
    const shouldShowActivityModal =
      areActivitiesSelected && !!this.containerRef && !isPermitOnly
    const shouldShowPermitModal = arePermitsSelected && !!this.containerRef

    return (
      <>
        {this.shouldShowAnnouncementCards && (
          <CompactAnnouncementCard
            topOffset={topOffset}
            leftOffset={leftOffset}
            announcements={selectedAnnouncements}
            showAnnouncement={this.openAnnouncement}
            deleteAnnouncement={deleteAnnouncement}
            containerRef={this.containerRef}
            openPermit={openPermit}
          />
        )}
        {shouldShowPermitModal && (
          <SitemapPermitsDraggableModal
            item={selectedAttribute}
            containerRef={this.containerRef}
            selectedSitePermits={selectedPermits}
            selectedPermitId={displayedPermitId}
            openPermit={openPermit}
            onClose={deselectAll}
            topOffset={topOffset}
            leftOffset={leftOffset}
          />
        )}
        {shouldShowActivityModal && (
          <SitemapActivitiesDraggableModal
            item={selectedAttribute}
            containerRef={this.containerRef}
            selectedActivities={selectedActivities}
            selectedActivityId={displayedActivityId}
            currentViewDate={startDate}
            openActivity={openActivity}
            onClose={deselectAll}
            topOffset={topOffset}
            leftOffset={leftOffset}
          />
        )}
        {shouldShowSelectedAttribute &&
          (selectedAttribute.dataObject?.is(
            LocationIntegrationType.MaturixStation,
          ) ? (
            <SitemapMaturixDraggableModal
              item={selectedAttribute}
              containerRef={this.containerRef}
              onClose={deselectAll}
              topOffset={topOffset}
              leftOffset={leftOffset}
            />
          ) : (
            <SitemapLocationModal
              item={selectedAttribute}
              topOffset={topOffset}
              leftOffset={leftOffset}
              openPermit={openPermit}
              currentDate={startDate}
              containerRef={this.containerRef}
              onClose={deselectAll}
            />
          ))}
        {shouldShowDeliveryModal && (
          <SitemapDeliveriesDraggableModal
            onClose={deselectAll}
            item={selectedAttribute}
            containerRef={this.containerRef}
            onDeliveryClick={openDelivery}
            activeDeliveryId={displayedDelivery?.id}
            onFilterResetClick={deselectAll}
            relatedDeliveries={selectedDeliveries}
            topOffset={topOffset}
            leftOffset={leftOffset}
          />
        )}
      </>
    )
  }

  @action.bound
  private openAnnouncement(announcement: Announcement): void {
    const {
      openAnnouncement,
      store: { selectedAnnouncements },
    } = this.props
    selectedAnnouncements.splice(
      selectedAnnouncements.findIndex(x => x.id === announcement.id),
      1,
    )
    openAnnouncement(announcement)
  }

  private renderSitemap(): JSX.Element {
    const {
      areAnnouncementsHidden,
      arePermitsHidden,
      areDeliveriesHidden,
      areMonitoringsHidden,
      shouldUseFullHeight,
      toggleDeliveriesHiddenState,
      toggleAnnouncementsHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      renderShowOnMenu,
      basemapsStore,
      store: { sitemapUrl, deselectAll, sitemap },
      mapBoxEditorStore,
      eventsStore: { appState },
      isPermitOnly,
    } = this.props
    const {
      isDraggingMode,
      isImageRubberMode,
      shouldShowImage,
      sourceBounds,
      setViewport,
      viewport,
      mapViewport,
      opacity,
      sitemapStage,
    } = mapBoxEditorStore

    return (
      <>
        <BaseSitemap
          isDeliveryView={true}
          sitemapUrl={sitemapUrl}
          isDraggable={true}
          shouldUseFullHeight={shouldUseFullHeight}
          onClick={deselectAll}
          onTouch={this.onSitemapTouch}
          isDraggingMode={isDraggingMode}
          selectedSitemap={sitemap}
          opacity={opacity}
          mapBoxEditorStore={mapBoxEditorStore}
          showOnMenu={!isPermitOnly && renderShowOnMenu}
          mapViewport={viewport || mapViewport}
          setViewport={setViewport}
          shouldShowImage={shouldShowImage}
          isImageRubberMode={isImageRubberMode}
          sourceBounds={sourceBounds}
          basemapsStore={basemapsStore}
          toggleAnnouncementsHiddenState={toggleAnnouncementsHiddenState}
          toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
          togglePermitsHiddenState={togglePermitsHiddenState}
          toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
          isLogisticsView={true}
          arePermitsHidden={!isPermitOnly && arePermitsHidden}
          areAnnouncementsHidden={areAnnouncementsHidden}
          areDeliveriesHidden={areDeliveriesHidden}
          areMonitoringsHidden={areMonitoringsHidden}
          projectAddress={appState.projectAddress}
          ref={this.setDeliverySitemap}
          setSitemap={this.setDeliverySitemapStage}
        >
          {({ width, height, key, isMainSitemap }) => {
            if (
              !this.deliverySitemap ||
              (sitemap.isReferenced && !sitemapStage)
            ) {
              return null
            }
            return (
              <Provider {...this.context}>
                {this.renderSitemapChildren(width, height, key, isMainSitemap)}
              </Provider>
            )
          }}
        </BaseSitemap>
      </>
    )
  }

  private setDeliverySitemap = ref => {
    this.deliverySitemap = ref
  }

  private renderSitemapChildren = (
    width: number,
    height: number,
    key: string,
    isMainSitemap?: boolean,
  ) => {
    const {
      textboxesCache,
      areAnnouncementsHidden,
      arePermitsHidden,
      areActivitiesHidden,
      areDeliveriesHidden,
      areMonitoringsHidden,
      mapBoxEditorStore,
      store: {
        selectItem,
        getClosestAttrIdToDeliveriesMap,
        displayedSitemapItems,
      },
      sitemapId,
      sitemapsStore,
    } = this.props
    const { secondaryItems, itemsFiltering } = mapBoxEditorStore

    const attrsToDeliveryMap = getClosestAttrIdToDeliveriesMap(
      this.availableDeliveries,
      this.deliverySortedSitemapItems,
    )
    const sitemap = sitemapsStore.byId.get(sitemapId)

    const referencedChildren = isMainSitemap
      ? itemsFiltering
      : secondaryItems[key]
    const itemsToShow = (
      sitemap.isReferenced ? referencedChildren : displayedSitemapItems
    )?.filter(
      i =>
        !i.dataObject?.is(LocationIntegrationType.MaturixStation) ||
        !areMonitoringsHidden,
    )

    return (
      <>
        <SitemapItems
          className={classList({ 'unclickable-element': !isMainSitemap })}
          items={itemsToShow || []}
          containerWidth={width}
          containerHeight={height}
          textboxesCache={textboxesCache}
          onSelectItem={selectItem}
          mapBoxEditorStore={mapBoxEditorStore}
          isReferenced={sitemap?.isReferenced}
        />
        {(isMainSitemap || !sitemap.isReferenced) && (
          <SitemapElementsWrapper>
            {!arePermitsHidden &&
              this.displayedPermits.map(
                this.renderPermit.bind(this, width, height),
              )}
            {!areAnnouncementsHidden &&
              this.displayedAnnouncements.map(
                this.renderAnnouncement.bind(this, width, height),
              )}
            {!areActivitiesHidden &&
              this.displayedActivities.map(
                this.renderActivity.bind(this, width, height),
              )}
            {!areDeliveriesHidden &&
              this.deliverySortedSitemapItems.map(item =>
                this.renderWorkflowPin(
                  item,
                  width,
                  height,
                  null,
                  attrsToDeliveryMap,
                ),
              )}
          </SitemapElementsWrapper>
        )}
      </>
    )
  }

  private onSitemapTouch = () => {
    this.props.store.deselectAll()
    this.selectMapViewMode()
    this.swipeableItemId = null
  }

  private renderAnnouncement(
    width: number,
    height: number,
    announcement: Announcement,
    idx: number,
  ) {
    const {
      arePermitsHidden,
      shouldRenderSwipeableCards,
      store: { getAnnouncementLocationPosition, getPermitLocationPositions },
    } = this.props

    const location = getAnnouncementLocationPosition(announcement)

    if (!location) {
      return
    }

    const samePositionPermits = this.displayedPermits.filter(p => {
      return getPermitLocationPositions(p).some(
        l => l.x === location.x && l.y === location.y,
      )
    })
    const samePositionAnnouncements = this.displayedAnnouncements.filter(n => {
      const announcementPosition = getAnnouncementLocationPosition(n)
      return (
        announcementPosition &&
        announcementPosition.x === location.x &&
        announcementPosition.y === location.y
      )
    })

    const samePositionIdx = samePositionAnnouncements.indexOf(announcement)

    if (samePositionIdx > 0) {
      return
    }
    const text =
      samePositionAnnouncements.length > 1
        ? samePositionAnnouncements.length.toString()
        : announcement.datesLabel
    const x =
      (width * location.x) / MAX_PERCENT +
      samePositionIdx * SAME_POSITION_OFFSET
    const y = (height * location.y) / MAX_PERCENT

    const PIN_OFFSET =
      !arePermitsHidden && samePositionPermits ? SAME_POSITION_OFFSET : 0
    const isSelected = shouldRenderSwipeableCards
      ? samePositionAnnouncements.some(n => this.swipeableItemId === n.id)
      : this.isAnnouncementDisplayed(announcement.id)

    return (
      <KonvaWorkflowAnnouncementPin
        text={text}
        isSelected={isSelected}
        isCanceled={false}
        shouldRenderCircle={samePositionAnnouncements.length > 1}
        key={idx + 1}
        x={x + PIN_OFFSET}
        y={y + OFFSET_Y}
        onClick={this.onAnnouncementPinClick.bind(
          this,
          samePositionAnnouncements,
          location,
          width,
          height,
        )}
        onTouchEnd={this.onAnnouncementPinTouch.bind(
          this,
          samePositionAnnouncements,
          location,
          width,
          height,
        )}
      />
    )
  }

  private renderPermit(width: number, height: number, permit: SitePermit) {
    const {
      shouldRenderSwipeableCards,
      permitTypesStore,
      eventsStore: { appState },
    } = this.props
    const { getPermitLocationPositions, getRelatedItems } = this.props.store

    const permitType = permit?.getTypeOfPermitType(permitTypesStore)
    const items = getRelatedItems(permit.locations)

    if (!items) {
      return
    }

    return items.map((item, lIndex) => {
      const { position } = item.iconProperties
      const samePositionPermits = this.displayedPermits.filter(p => {
        return getPermitLocationPositions(p).some(
          l => l.x === position.x && l.y === position.y,
        )
      })

      const samePositionIdx = samePositionPermits.indexOf(permit)

      if (samePositionIdx > 0) {
        return
      }
      const text =
        samePositionPermits.length > 1
          ? samePositionPermits.length.toString()
          : permit.code
      const x =
        (width * position.x) / MAX_PERCENT +
        samePositionIdx * SAME_POSITION_OFFSET
      const y = (height * position.y) / MAX_PERCENT

      const isSelected = shouldRenderSwipeableCards
        ? samePositionPermits.some(p => this.swipeableItemId === p.id)
        : this.isPermitDisplayed(permit.id)

      const shouldShowPinAsDone = samePositionPermits.every(
        p => p.isDoneOrDenied,
      )
      const shouldShowPinAsAssigned = samePositionPermits.some(p =>
        p.isAssignedToUser(appState.user),
      )

      return (
        <KonvaWorkflowPermitPin
          text={text}
          isDone={shouldShowPinAsDone}
          isAssigned={shouldShowPinAsAssigned}
          isSelected={isSelected}
          isCanceled={false}
          shouldRenderCircle={samePositionPermits.length > 1}
          key={permit.id + lIndex}
          x={x}
          y={y}
          offsetY={OFFSET_Y}
          permitType={permitType}
          onTouchEnd={this.onPermitPillTouch.bind(
            this,
            samePositionPermits,
            item,
            width,
            height,
          )}
          onClick={this.onPermitPillClick.bind(
            this,
            samePositionPermits,
            item,
            width,
            height,
          )}
        />
      )
    })
  }

  private renderActivity(width: number, height: number, activity: Activity) {
    const {
      shouldRenderSwipeableCards,
      arePermitsHidden,
      areAnnouncementsHidden,
      areDeliveriesHidden,
    } = this.props
    const {
      getLocationPositions,
      getRelatedItems,
      getPermitLocationPositions,
      getAnnouncementLocationPosition,
    } = this.props.store

    const items = getRelatedItems(activity.locations)

    if (!items) {
      return
    }

    return items.map((item, lIndex) => {
      const { position } = item.iconProperties
      const samePositionActivities = this.displayedActivities.filter(a => {
        return getLocationPositions(a.locations).includes(position)
      })

      const samePositionIdx = samePositionActivities.indexOf(activity)

      if (samePositionIdx > 0) {
        return
      }

      const samePositionPermits =
        !arePermitsHidden &&
        this.displayedPermits.some(permit => {
          const permitLocation = getPermitLocationPositions(permit)[0]
          return (
            permitLocation.x === position.x && position.y === permitLocation.y
          )
        })

      const samePositionAnnouncements =
        !areAnnouncementsHidden &&
        this.displayedAnnouncements.some(announcement => {
          const announcementLocation =
            getAnnouncementLocationPosition(announcement)
          return (
            announcementLocation &&
            announcementLocation.x === position.x &&
            announcementLocation.y === position.y
          )
        })

      const text =
        samePositionActivities.length > 1
          ? samePositionActivities.length.toString()
          : activity.code

      let additionalXOffset = 0
      if (!samePositionPermits) {
        additionalXOffset = 0
      } else if (!samePositionAnnouncements) {
        additionalXOffset = SAME_POSITION_OFFSET
      } else if (areDeliveriesHidden) {
        additionalXOffset = -SAME_POSITION_OFFSET
      } else {
        additionalXOffset = -SAME_POSITION_OFFSET + -SAME_POSITION_OFFSET
      }

      const additionalYOffset = samePositionPermits ? OFFSET_Y : 0
      const x = (width * position.x) / MAX_PERCENT + additionalXOffset
      const y = (height * position.y) / MAX_PERCENT + additionalYOffset

      const isSelected = shouldRenderSwipeableCards
        ? samePositionActivities.some(a => this.swipeableItemId === a.code)
        : this.isActivityDisplayed(activity.code)

      return (
        <KonvaWorkflowActivityPin
          text={text}
          isSelected={isSelected}
          isCanceled={false}
          shouldRenderCircle={samePositionActivities.length > 1}
          key={activity.code + lIndex}
          x={x}
          y={y}
          offsetY={OFFSET_Y}
          onTouchEnd={this.onActivityPillTouch.bind(
            this,
            samePositionActivities,
            item,
            width,
            height,
          )}
          onClick={this.onActivityPillClick.bind(
            this,
            samePositionActivities,
            item,
            width,
            height,
          )}
        />
      )
    })
  }

  private setDeliverySitemapStage = (stage: Stage) => {
    this.props.mapBoxEditorStore.sitemapStage = stage
  }

  private isAnnouncementDisplayed(id: string): boolean {
    const { selectedAnnouncements = [] } = this.props.store
    return selectedAnnouncements.some(announcement => announcement.id === id)
  }

  private isPermitDisplayed(id: string): boolean {
    const { selectedPermits = [] } = this.props.store
    return (
      selectedPermits.some(permit => permit.id === id) ||
      this.props.store.displayedPermitId === id
    )
  }

  private isDeliveryDisplayed(id: string): boolean {
    const { selectedDeliveries = [] } = this.props.store
    return (
      selectedDeliveries.some(delivery => delivery.id === id) ||
      this.props.deliveryDetailsStore.displayedDelivery?.id === id
    )
  }

  private isActivityDisplayed(code: string): boolean {
    const {
      store: { selectedActivities = [] },
      activitiesStore: { selectedActivity },
    } = this.props
    return (
      selectedActivities.some(activity => activity.code === code) ||
      selectedActivity?.code === code
    )
  }

  private onAnnouncementPinTouch(
    announcements: Announcement[],
    position: IPosition,
    width: number,
    height: number,
    event: KonvaEventObject<TouchEvent>,
  ) {
    const { shouldRenderSwipeableCards, store } = this.props
    const {
      selectAnnouncements,
      mapBoxEditorStore: { clickCoords, canvasMap },
    } = store
    if (shouldRenderSwipeableCards) {
      this.swipeableItemId = announcements[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === announcements[0]?.id,
      )
    }
    this.selectMapViewMode()
    const clickOffset = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      position,
    )
    selectAnnouncements(announcements, clickOffset.y, clickOffset.x)
    event.cancelBubble = true
  }

  private onAnnouncementPinClick(
    announcements: Announcement[],
    position: IPosition,
    width: number,
    height: number,
    event: KonvaEventObject<MouseEvent>,
  ) {
    if (this.props.onPinClick) {
      this.props.onPinClick(announcements[0])
    }

    const {
      selectAnnouncements,
      mapBoxEditorStore: { clickCoords, canvasMap },
    } = this.props.store
    const clickOffset = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      position,
    )
    selectAnnouncements(announcements, clickOffset.y, clickOffset.x)
    this.swipeableItemIndex = this.allItems.findIndex(
      currentItem => currentItem.id === announcements[0]?.id,
    )
    event.cancelBubble = true
  }

  private onPermitPillTouch(
    permits: SitePermit[],
    item: SitemapItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<TouchEvent>,
  ) {
    const { shouldRenderSwipeableCards, store } = this.props
    const {
      selectPermits,
      mapBoxEditorStore: { clickCoords, canvasMap },
    } = store

    if (shouldRenderSwipeableCards) {
      this.swipeableItemId = permits[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === permits[0]?.id,
      )
    }
    this.selectMapViewMode()

    const clickOffset = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      null,
      item,
    )
    selectPermits(permits, item, clickOffset.y, clickOffset.x)

    event.cancelBubble = true
  }

  private onPermitPillClick(
    permits: SitePermit[],
    item: SitemapItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<MouseEvent>,
  ) {
    const {
      selectPermits,
      mapBoxEditorStore: { clickCoords, canvasMap },
    } = this.props.store
    const clickOffset = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      null,
      item,
    )

    selectPermits(permits, item, clickOffset.y, clickOffset.x)
    event.cancelBubble = true
  }

  private onActivityPillTouch(
    activities: Activity[],
    item: SitemapItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<TouchEvent>,
  ) {
    const { shouldRenderSwipeableCards, store } = this.props
    const {
      selectActivities,
      mapBoxEditorStore: { clickCoords, canvasMap },
    } = store

    if (shouldRenderSwipeableCards) {
      this.swipeableItemId = activities[0]?.code
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === activities[0]?.code,
      )
    }

    this.selectMapViewMode()

    const clickOffset = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      null,
      item,
    )
    selectActivities(activities, item, clickOffset.y, clickOffset.x)

    event.cancelBubble = true
  }

  private onActivityPillClick(
    activities: Activity[],
    item: SitemapItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<MouseEvent>,
  ) {
    const {
      selectActivities,
      mapBoxEditorStore: { clickCoords, canvasMap },
    } = this.props.store
    const clickOffset = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      null,
      item,
    )
    selectActivities(activities, item, clickOffset.y, clickOffset.x)
    event.cancelBubble = true
  }

  private onDeliveryPillTouch = (
    deliveries: Delivery[],
    sitemapItem: SitemapItemBase,
    event: KonvaEventObject<TouchEvent>,
  ) => {
    const { shouldRenderSwipeableCards, store } = this.props

    if (shouldRenderSwipeableCards) {
      this.swipeableItemId = deliveries[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === deliveries[0]?.id,
      )
    }

    this.selectMapViewMode()

    store.selectDeliveries(deliveries, sitemapItem)

    event.cancelBubble = true
  }

  private onDeliveryPillClick = (
    deliveries: Delivery[],
    item: SitemapItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<MouseEvent>,
  ) => {
    const {
      selectDeliveries,
      mapBoxEditorStore: { clickCoords, canvasMap },
    } = this.props.store
    const clickOffset = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      null,
      item,
    )
    selectDeliveries(deliveries, item, clickOffset.y, clickOffset.x)
    event.cancelBubble = true
  }

  @action.bound
  private setContainerRef(ref: HTMLElement) {
    this.containerRef = ref
  }

  private renderWorkflowPin(
    item: SitemapItemBase,
    cWidth: number,
    cHeight: number,
    listGroupingKey: string,
    attrIdToDeliveriesMap: { [attrId: string]: Delivery[] },
  ): JSX.Element {
    const {
      arePermitsHidden,
      areAnnouncementsHidden,
      areActivitiesHidden,
      companiesStore,
      shouldRenderSwipeableCards,
      eventsStore: { appState },
    } = this.props

    const {
      getPinLabel,
      shouldShowPin,
      getPinPosition,
      shouldRenderCircle,
      getPositionByDisplayedProperties,
      shouldShowPinAsDone,
      shouldShowPinAsAssigned,
      shouldShowPinAsCanceled,
    } = this.pinStore

    const deliveries = attrIdToDeliveriesMap[item.id] || []

    if (!shouldShowPin(item, deliveries, listGroupingKey)) {
      return null
    }

    const {
      getLocationPositions,
      getPermitLocationPositions,
      getAnnouncementLocationPosition,
    } = this.props.store
    const { x, y } = getPinPosition(item, cWidth, cHeight)

    const deliveryPosition = getPositionByDisplayedProperties(item)

    const samePositionPermits =
      !arePermitsHidden &&
      this.displayedPermits.some(permit => {
        const permitLocation = getPermitLocationPositions(permit)[0]
        return (
          permitLocation.x === deliveryPosition.x &&
          deliveryPosition.y === permitLocation.y
        )
      })

    const samePositionAnnouncements =
      !areAnnouncementsHidden &&
      this.displayedAnnouncements.some(announcement => {
        const announcementLocation =
          getAnnouncementLocationPosition(announcement)
        return (
          announcementLocation &&
          announcementLocation.x === deliveryPosition.x &&
          announcementLocation.y === deliveryPosition.y
        )
      })

    const samePositionActivities =
      !areActivitiesHidden &&
      this.displayedActivities.some(activity => {
        const activityPositions = getLocationPositions(activity.locations)
        return activityPositions.some(
          pos => pos.x === deliveryPosition.x && deliveryPosition.y === pos.y,
        )
      })

    const PIN_OFFSET =
      samePositionPermits || samePositionAnnouncements || samePositionActivities
        ? -SAME_POSITION_OFFSET
        : 0

    const isSelected = shouldRenderSwipeableCards
      ? deliveries.some(n => this.swipeableItemId === n.id)
      : this.isDeliveryDisplayed(deliveries[0]?.id)

    return (
      <KonvaWorkflowDeliveriesPin
        key={item.id}
        isDone={shouldShowPinAsDone(deliveries)}
        isAssigned={shouldShowPinAsAssigned(deliveries, appState.user)}
        isSelected={isSelected}
        isCanceled={shouldShowPinAsCanceled(deliveries)}
        text={getPinLabel(deliveries, companiesStore)}
        shouldRenderCircle={shouldRenderCircle(deliveries)}
        x={x + PIN_OFFSET}
        y={y + OFFSET_Y}
        onTouchEnd={this.onDeliveryPillTouch.bind(this, deliveries, item)}
        onClick={this.onDeliveryPillClick.bind(
          this,
          deliveries,
          item,
          cWidth,
          cHeight,
        )}
      />
    )
  }

  @computed
  private get deliverySortedSitemapItems(): SitemapItemBase[] {
    const { store, mapBoxEditorStore } = this.props

    return this.pinStore.getItemsByPinLocationOption(
      store.sitemap?.isReferenced
        ? mapBoxEditorStore.itemsOnMap
        : store.displayedSitemapItems,
    )
  }

  @computed
  private get availableDeliveries(): Delivery[] {
    const {
      projectDateStore: { isWithinDateInterval },
      logisticsStore: { startDate },
      store,
      mapBoxEditorStore: { itemsOnMap },
      logisticsMapStore: { filteredDeliveries },
    } = this.props

    const availableDeliveries = filteredDeliveries.filter(d => {
      return (
        startDate && isWithinDateInterval(d.startDate, d.endDate, startDate)
      )
    })

    const displayedAttrsIds = (
      store.sitemap?.isReferenced ? itemsOnMap : store.sortedSitemapAttributes
    ).map(si => si.id)
    return availableDeliveries.filter(delivery =>
      displayedAttrsIds.some(attrId => delivery.isRelatedAttr(attrId)),
    )
  }

  @computed
  public get displayedPermits(): SitePermit[] {
    const {
      store,
      mapBoxEditorStore: { itemsOnMap },
      logisticsMapStore: { filteredPermits },
    } = this.props

    return filteredPermits.filter(permit => {
      const attributes = store.sitemap?.isReferenced
        ? itemsOnMap
        : store.sortedSitemapAttributes

      return permit.locations?.some(
        l =>
          attributes.some(item => l.id === item.id) ||
          !!store.findParentItemByAttrId(l.id),
      )
    })
  }

  @computed
  public get displayedAnnouncements(): Announcement[] {
    const {
      store,
      mapBoxEditorStore: { itemsOnMap },
      logisticsMapStore: { filteredAndOrderedAnnouncements },
    } = this.props

    return filteredAndOrderedAnnouncements.filter(announcement => {
      const announcementLocationId = announcement?.location?.id
      const attributes = store.sitemap?.isReferenced
        ? itemsOnMap
        : store.sortedSitemapAttributes

      return (
        attributes.some(
          sitemapItem => announcementLocationId === sitemapItem.id,
        ) || !!store.findParentItemByAttrId(announcementLocationId)
      )
    })
  }

  @computed
  public get displayedActivities(): Activity[] {
    const {
      store,
      logisticsMapStore: { filteredActivities },
    } = this.props

    return filteredActivities.filter(activity => {
      return activity.locations.some(
        l =>
          store.sortedSitemapAttributes.some(
            sitemapItem => l.id === sitemapItem.id,
          ) || !!store.findParentItemByAttrId(l.id),
      )
    })
  }

  private get shouldShowAnnouncementCards(): boolean {
    const { shouldRenderSwipeableCards, isPermitOnly } = this.props
    if (isPermitOnly) {
      return false
    }

    const { shouldShowAnnouncementsList } = this.props.store
    return (
      !shouldRenderSwipeableCards &&
      shouldShowAnnouncementsList &&
      !!this.containerRef
    )
  }

  private showPreviousItem = () => {
    if (this.swipeableItemIndex) {
      this.swipeableItemIndex--
      this.swipeableItemId = this.allItems[this.swipeableItemIndex].id
    }
  }

  private showNextItem = () => {
    if (this.swipeableItemIndex + 1 < this.allItems.length) {
      this.swipeableItemIndex++
      this.swipeableItemId = this.allItems[this.swipeableItemIndex].id
    }
  }

  private get allItems() {
    return [
      ...this.displayedAnnouncements,
      ...this.displayedPermits,
      ...this.availableDeliveries,
      ...this.displayedActivities,
    ]
  }

  private selectMapViewMode() {
    const { selectViewMode } = this.props
    if (selectViewMode) {
      selectViewMode(ViewMode.Closed)
    }
  }

  private calculateAnnouncementCardOffset() {
    this.props.store.setAnnouncementCardOffset(this.containerRef, this.pinStore)
  }
}

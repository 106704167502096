import * as React from 'react'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'

import SitemapItemsSetupStore from '../stores/SitemapItemsSetup.store'
import SitemapViewsSetupStore from '../stores/SitemapViewsSetup.store'

const CONTENT_OFFSET = 16
const ICON_SIZE = 16

export interface IProps {
  store: SitemapItemsSetupStore
  sitemapViewsSetupStore: SitemapViewsSetupStore
  item: SitemapItemBase
  childrenCount?: number
}

export default class SitemapItemTag extends React.Component<IProps> {
  private text: HTMLDivElement = null

  public componentDidUpdate() {
    this.setEllipsisStyle()
  }

  public render() {
    const {
      store,
      item,
      childrenCount,
      sitemapViewsSetupStore: { isAttributeAssigned },
    } = this.props
    const shouldShowAsTag =
      store.isItemAssignedToSitemap(item) || isAttributeAssigned(item)

    return (
      <SitemapAttributeTag
        shouldShowAsTag={shouldShowAsTag}
        sitemapItem={item.sitemapItem}
        dataObject={item.dataObject}
        iconSize={ICON_SIZE}
      >
        <div className="text large ellipsis" ref={this.setTextRef}>
          {item.displayName || item.sitemapItem.name}
          {!!childrenCount && (
            <span className="text large light">&nbsp;({childrenCount})</span>
          )}
        </div>
      </SitemapAttributeTag>
    )
  }

  private setTextRef = (ref: HTMLDivElement) => {
    this.text = ref
    this.setEllipsisStyle()
  }

  private setEllipsisStyle() {
    if (!this.text) {
      return
    }

    const content = this.text.parentElement
    const tag = content.parentElement

    if (content.clientWidth > tag.clientWidth - CONTENT_OFFSET) {
      content.style.maxWidth = `${tag.clientWidth - CONTENT_OFFSET}px`
    }
  }
}

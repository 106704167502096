import { computed } from 'mobx'

import { LocationType } from '~/client/graph'
import { SaveLevelsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import Level from '../../models/LocationObjects/Level'
import { sortLevels } from '../../utils/sortingFunctions'
import EventsStore from '../EventStore/Events.store'
import BuildingsStore from './Buildings.store'
import GraphExecutorStore from './GraphExecutor.store'
import LocationStoreBase from './LocationBase.store'

export default class LevelsStore extends LocationStoreBase<Level> {
  protected saveMutation = SaveLevelsDocument
  protected graphName = 'levels'
  protected defaultIconName = SitemapAttributeIcon.Level

  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly graphExecutorStore: GraphExecutorStore,
    private readonly buildingsStore: BuildingsStore,
  ) {
    super(eventsStore, graphExecutorStore)
  }

  @computed
  public get list() {
    const allLevels = this.listWithDeletedItems.filter(
      entry => !entry.isDeleted,
    )

    const levelsWithoutBuildingParent = allLevels
      .filter(lvl => lvl.parent?.parentType !== LocationType.Building)
      .sort((a, b) => sortLevels(b, a))

    const levelsSortedByBuildings = this.buildingsStore.list.reduce(
      (list, buildingDto) => {
        const levelsByBuilding = allLevels.filter(lvl =>
          lvl.isParent(buildingDto),
        )
        const sortedLevels =
          buildingDto.sortLevelsByCustomOrder(levelsByBuilding)

        list.push(...sortedLevels)

        return list
      },
      [] as Level[],
    )

    return levelsSortedByBuildings.concat(levelsWithoutBuildingParent)
  }

  public get byId() {
    const { levels } = this.eventsStore.appState
    return levels
  }

  protected fromDto(dto: ILocationDto): Level {
    return Level.fromDto(dto)
  }
}

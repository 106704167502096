import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField, PermitFieldType } from '~/client/graph'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import { Loader } from '~/client/src/shared/components/Loader'
import UnsafeSitePermitCreationFormContent from '~/client/src/shared/components/SitePermitCreationForm/SitePermitCreationFormContent'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import Tabs from '~/client/src/shared/components/Tabs/Tabs'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import {
  PERMIT_TYPES_LOAD_SUCCESS,
  PERMIT_TYPES_RECEIVED,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import OperationRulesStore from '~/client/src/shared/stores/domain/OperationRules.store'
import PermitBicInspectionsStore from '~/client/src/shared/stores/domain/PermitBicInspections.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import PermitBallInCourtStore from '~/client/src/shared/stores/ui/PermitBallInCourt.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import { Wrapper } from '../SetupPageLayout/SetupPageLayout'
import PermitsSetUpStore, { formSectionsAsTabs } from './PermitsSetUp.store'
import PreviewPermitFormStore from './PreviewPermitForm.store'
import FormConfigurator from './components/FormConfigurator/FormConfigurator'
import FormWorkflowSteps from './components/FormWorkflowSteps'
import UnsafeNewFormTypeDialog from './components/NewFormTypeDialog'
import NonWorkTimesConfigurator from './components/NonWorkTimesConfigurator/NonWorkTimesConfigurator'
import PermitTypesList from './components/PermitTypesList/PermitTypesList'
import Workflow from './components/Workflow/Workflow'

import './PermitsSetUp.scss'

const SitePermitCreationFormContent = withErrorBoundary(
  UnsafeSitePermitCreationFormContent,
)
const NewFormTypeDialog = withErrorBoundary(UnsafeNewFormTypeDialog)

interface IProps {
  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
  operationRulesStore?: OperationRulesStore
  sitePermitsStore?: SitePermitsStore
  permitBicInspectionsStore?: PermitBicInspectionsStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  locationAttributesStore?: LocationAttributesStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoriesStore
}

const loaderSize = 40

const remove = 'Remove'
const areYouSureWantToDeleteTheForm =
  'Are you sure you want to delete the form?'
const selectWorkflowStepToAddFields =
  'Select a workflow step to add/modify fields'

@inject(
  'eventsStore',
  'tagsStore',
  'projectDateStore',
  'companiesStore',
  'permitTypesStore',
  'operationRulesStore',
  'sitePermitsStore',
  'permitBicInspectionsStore',
  'userProjectsStore',
  'projectMembersStore',
  'locationAttributesStore',
  'materialsStore',
  'materialCategoryStore',
)
@observer
export default class PermitsSetUp extends React.Component<IProps> {
  private readonly store: PermitsSetUpStore
  private readonly previewPermitStore: PreviewPermitFormStore
  private readonly permitBicStore: PermitBallInCourtStore

  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    this.previewPermitStore = new PreviewPermitFormStore(
      props.eventsStore,
      props.projectDateStore,
      null,
      props.permitTypesStore,
      props.permitBicInspectionsStore,
      props.userProjectsStore,
      props.projectMembersStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.materialsStore,
      props.materialCategoryStore,
    )

    this.store = new PermitsSetUpStore(
      props.eventsStore,
      props.permitTypesStore,
      this.previewPermitStore,
    )

    this.permitBicStore = new PermitBallInCourtStore(
      props.eventsStore,
      props.operationRulesStore,
      props.projectMembersStore,
    )

    if (this.store.isReceived) {
      this.store.setActivePermitTypeId()
    } else {
      this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
        this.onPermitTypesReceived,
      )
    }
  }

  public componentDidMount() {
    this.store.resetWorkflowStep()
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    if (!this.store.isReceived) {
      return (
        <Loader
          className="full-height col x-center y-center"
          hint={Localization.translator.loading}
        />
      )
    }

    return (
      <div className="row full-height permit-settings">
        <Wrapper className="overflow-hidden">
          <div className="row full-height y-start relative">
            <div className="absolute full-height full-width">
              {this.store.isUpdating && (
                <div className="full-height full-width absolute modal-overlay">
                  <Loader
                    className="fixed-loader col x-center"
                    size={loaderSize}
                    hint={`${Localization.translator.templateUpdating}...`}
                  />
                </div>
              )}
            </div>

            <div className="col full-height full-width relative">
              {this.tabsContainer}
              {this.content}
            </div>
          </div>
        </Wrapper>
        {this.renderFormPreview()}
      </div>
    )
  }

  private get tabsContainer(): JSX.Element {
    const {
      activeFormsTab,
      changeActiveFormTab,
      activePermitTypeId,
      permitTypesAsTabs,
      isFormSelectorShown,
    } = this.store

    return (
      <div className="forms-tabs-container row x-between y-center mt5 pb10 px20 overflow-x-auto y-scroll-hidden">
        <Tabs
          tabObjects={formSectionsAsTabs}
          activeTabId={activeFormsTab}
          onTabClick={changeActiveFormTab}
          className="bb-light-grey w-fit-content mw-fit-content mx5"
          tabTextClassName="px20"
          tabClassName="forms-tab-item text extra-large center pointer"
        />
        {isFormSelectorShown && (
          <div className="row no-flex">
            <span className="ml5 no-grow nowrap">
              {Localization.translator.formType}
            </span>
            <div className="mx20 w-fit-content">
              <StruxhubSelect
                className="form-selector-field"
                isMinimalisticMode
                value={activePermitTypeId}
                onChange={this.changeActiveType}
              >
                {permitTypesAsTabs.map(tab => (
                  <option
                    key={tab.page}
                    value={tab.page}
                    className="text large"
                  >
                    {tab.title}
                  </option>
                ))}
              </StruxhubSelect>
            </div>
          </div>
        )}
      </div>
    )
  }

  private get content(): JSX.Element {
    const {
      activePermitType,
      isTypesTabSelected,
      isWorkflowTabSelected,
      isConfiguratorTabSelected,
    } = this.store

    if (!activePermitType) {
      return <Loader />
    }

    if (isTypesTabSelected) {
      return this.typesTabContent
    }

    if (isWorkflowTabSelected) {
      return this.workflowTabContent
    }

    if (isConfiguratorTabSelected) {
      return this.configuratorTabContent
    }

    return null
  }

  private get typesTabContent(): JSX.Element {
    const {
      permitTypes,
      togglePermitType,
      isNewFormTypeDialogShown,
      showNewFormTypeDialog,
      isCustomType,
      removeCustomPermitType,
      isRemoveFormDialogShown,
      hideRemoveFormDialog,
      showRemoveFormDialog,
      changeTypeName,
    } = this.store

    return (
      <>
        {isNewFormTypeDialogShown && <NewFormTypeDialog store={this.store} />}

        <ConfirmDialog
          doneTitle={remove}
          onDoneClicked={removeCustomPermitType}
          isOpen={isRemoveFormDialogShown}
          onCancelClicked={hideRemoveFormDialog}
        >
          {areYouSureWantToDeleteTheForm}
        </ConfirmDialog>

        <div className="col px20">
          <PermitTypesList
            permitTypes={permitTypes}
            onToggle={togglePermitType}
            onAddNewFormClick={showNewFormTypeDialog}
            isCustomType={isCustomType}
            onRemoveClick={showRemoveFormDialog}
            changeTypeName={changeTypeName}
          />
        </div>
      </>
    )
  }

  private get workflowTabContent(): JSX.Element {
    const {
      activePermitType,
      changeWorkflowSettings,
      isUpdating,
      goToSelectedStep,
    } = this.store

    return (
      <div className="col full-height px20 overflow-auto">
        <Workflow
          className="ml12 mb40"
          activePermitType={activePermitType}
          isUpdating={isUpdating}
          permitBallInCourtStore={this.permitBicStore}
          onWorkflowSettingsChange={changeWorkflowSettings}
          onStepClick={goToSelectedStep}
        />
      </div>
    )
  }

  private get configuratorTabContent(): JSX.Element {
    const {
      activePermitType: { workflowSteps, isMaterialTransfer },
      changePermitTypeWorkflowStep,
      selectedWorkflowStep,
      isInitialStepSelected,
      selectedWorkflowStepId,
      changeWorkflowStepId,
    } = this.store

    return (
      <>
        <span className="px50 pb10 text large bold">
          {selectWorkflowStepToAddFields}
        </span>
        <div className="row overflow-auto">
          <div className="row x-center">
            <FormWorkflowSteps
              selectedStepId={selectedWorkflowStepId}
              workflowSteps={workflowSteps}
              onStepClick={changeWorkflowStepId}
            />
          </div>
        </div>
        <div className="px20 bt-light-grey col full-height overflow-auto">
          <FormConfigurator
            workflowStep={selectedWorkflowStep}
            isInitialFormStep={isInitialStepSelected}
            onWorkflowStepChange={changePermitTypeWorkflowStep}
            renderNonWorkTimeSelector={this.renderNonWorkTimeSelector}
            isMaterialTransfer={isMaterialTransfer}
          />
        </div>
      </>
    )
  }

  private renderNonWorkTimeSelector = ({
    type,
  }: IPermitTypeField): JSX.Element => {
    const { activePermitType, changeNonWorkTimeBlocking } = this.store

    if (type !== PermitFieldType.StartFinishDates) {
      return null
    }

    return (
      <NonWorkTimesConfigurator
        activePermitType={activePermitType}
        onNonWorkTimesChange={changeNonWorkTimeBlocking}
      />
    )
  }

  private renderFormPreview(): JSX.Element {
    return (
      <div className="no-grow permit-form-preview full-height px20 pt20 col">
        <div className="text white extra-large center mb20">
          {Localization.translator.formPreview}
        </div>
        <SitePermitCreationFormContent
          store={this.previewPermitStore}
          isReadonly={false}
          isTypeReadOnly
          FileInputType={DesktopFileInput}
        />
      </div>
    )
  }

  private onPermitTypesReceived = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (eventType === PERMIT_TYPES_LOAD_SUCCESS) {
      this.store.setActivePermitTypeId()
    }
    if (eventType === PERMIT_TYPES_RECEIVED) {
      this.store.resetWorkflowStepIfNeed()
    }
  }

  private changeActiveType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.store.setActivePermitTypeId(event.target.value)
  }
}

import { action, computed, observable } from 'mobx'

import {
  IActivitiesConfigurations,
  IDeliveryConfigurations,
  IFormsConfigurations,
  ILogisticsConfigurations,
  IOrderedSitemap,
  UploadingType,
} from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import {
  SCALED_IMAGE_QUALITY,
  SCALED_IMAGE_TYPE,
} from '~/client/src/shared/components/BaseSitemap/BaseSitemap'
import { ISitemapsSetupSettings } from '~/client/src/shared/enums/ProjectSpecificUserProps'
import SitemapType from '~/client/src/shared/enums/SitemapType'
import Basemap from '~/client/src/shared/models/Basemap'
import Sitemap, {
  ISitemapGeoPosition,
} from '~/client/src/shared/models/Sitemap'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import EventTypes from '~/client/src/shared/stores/EventStore/eventTypes'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import { NORTH_HEADING, WORLD_BOUNDS } from '~/client/src/shared/utils/Address'

import DeliverySitemapSetUpStore from '../GeneralSitemapSetUp.store'
import ISitemapDelayedAction, {
  ACTION_KEY,
  DTO_ID_KEY,
  DelayedActionType,
  NEW_SITEMAP_TYPE_KEY,
  SITEMAP_ID_KEY,
} from '../models/ISitemapDelayedAction'
import SitemapItemsSetupStore from './SitemapItemsSetup.store'

export const UNTITLED = 'Untitled'

const MAX_IMAGE_WIDTH = 1920
const MAX_IMAGE_HEIGHT = 1080

const DOT = '.'

export default class SitemapSetupStore {
  @observable public viewport: ISitemapGeoPosition = {
    latitude: 0,
    longitude: 0,
    zoom: 0,
    bearing: NORTH_HEADING,
    pitch: 0,
    bounds: {
      ne: WORLD_BOUNDS._ne,
      sw: WORLD_BOUNDS._sw,
    },
  }
  @observable public newSitemapType: SitemapType = SitemapType.Default
  @observable public isSitemapUploading: boolean = false
  @observable public isSavingGeolocation: boolean = false

  @observable public deletableSitemap: Sitemap

  @observable public isAssignSitemapDialogShown: boolean = false

  @observable public editableSitemapName: string = ''
  @observable public pdfSitemapFile: File = null
  @observable private selectedSitemapId: string = null

  private delayedAction: ISitemapDelayedAction

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly sitemapsStore: SitemapsStore,
    private readonly basemapsStore: BasemapsStore,
    private readonly fileUploadingStore: FileUploadingStore,
    private readonly deliverySitemapSetUpStore: DeliverySitemapSetUpStore,
    private readonly gatesStore: GatesStore,
    private readonly zonesStore: ZonesStore,
    private readonly userProjectsStore: UserProjectsStore,
  ) {}

  @computed
  public get allSitemaps(): Sitemap[] {
    return this.sitemapsStore.list.slice()
  }

  @computed
  public get defaultSitemaps(): Sitemap[] {
    return this.sitemapsStore.defaultSitemaps
  }

  @computed
  public get gateSitemaps(): Sitemap[] {
    return this.sitemapsStore.gateSitemaps
  }

  @computed
  public get zoneSitemaps(): Sitemap[] {
    return this.sitemapsStore.zoneSitemaps
  }

  @computed
  public get selectedSitemap(): Sitemap {
    return this.sitemapsStore.byId.get(this.selectedSitemapId)
  }

  @computed
  public get selectedBasemap(): Basemap {
    return (
      this.selectedSitemap &&
      this.basemapsStore.byId.get(this.selectedSitemap.basemapId)
    )
  }

  public setViewportFromAdress() {
    const { projectAddress } = this.state

    this.viewport.bounds = this.selectedSitemap?.bounds || projectAddress.bounds
    this.viewport.zoom = this.selectedSitemap?.zoom || projectAddress.zoom
    this.viewport.altitude =
      this.selectedSitemap?.altitude || projectAddress.altitude
    this.viewport.pitch = this.selectedSitemap?.pitch || projectAddress.pitch
    this.viewport.bearing =
      this.selectedSitemap?.bearing || projectAddress.bearing
    this.viewport.latitude =
      this.selectedSitemap?.center?.lat || projectAddress.center?.lat
    this.viewport.longitude =
      this.selectedSitemap?.center?.lng || projectAddress.center?.lng
  }

  public setDelayedActionParamsFromUrl(query: URLSearchParams) {
    const params: ISitemapDelayedAction = {
      action: query.get(ACTION_KEY) as DelayedActionType,
      newSitemapType: query.get(NEW_SITEMAP_TYPE_KEY) as SitemapType,
      dtoId: query.get(DTO_ID_KEY),
      sitemapId: query.get(SITEMAP_ID_KEY),
    }
    if (this.isDelayedActionParamsValid(params)) {
      this.delayedAction = params
    }
  }

  public isDelayedActionParamsValid(params: ISitemapDelayedAction) {
    switch (params.action) {
      case DelayedActionType.SetDefault:
        return !!params.newSitemapType
      case DelayedActionType.AssignToGate:
      case DelayedActionType.AssignToZone:
        return !!params.newSitemapType && !!params.dtoId
      case DelayedActionType.SelectSitemap:
        return !!params.sitemapId
      default:
        return false
    }
  }

  @action.bound
  public selectInitialSitemap() {
    if (
      this.delayedAction &&
      this.delayedAction.action === DelayedActionType.SelectSitemap
    ) {
      const sitemap = this.sitemapsStore.byId.get(this.delayedAction.sitemapId)
      this.selectSitemap(sitemap)
      this.setLastEditedSitemap(sitemap.id)
      return
    }

    if (this.delayedAction) {
      this.selectUploadingNewSitemap(this.delayedAction.newSitemapType)
      return
    }
    if (this.lastEditedSitemap) {
      this.selectSitemap(this.lastEditedSitemap)
      return
    }
    if (this.defaultSitemap) {
      this.selectSitemap(this.defaultSitemap)
      this.setLastEditedSitemap(this.defaultSitemap.id)
      return
    }

    const sitemapsList = [
      ...this.sitemapsStore.defaultSitemaps,
      ...this.sitemapsStore.gateSitemaps,
      ...this.sitemapsStore.zoneSitemaps,
    ]

    if (sitemapsList[0]) {
      this.selectSitemap(sitemapsList[0])
      this.setLastEditedSitemap(sitemapsList[0].id)
    }
  }

  public get sitemapUrl() {
    return this.selectedBasemap && this.selectedBasemap.source
  }

  public get isSiteMapLabelsShown() {
    return this.selectedSitemap && this.selectedSitemap.isLabelsShown
  }

  @action.bound
  public async selectUploadingNewSitemap(type?: SitemapType) {
    await this.sitemapItemsSetupStore.saveSelectedSitemapItem()
    this.deselectSitemap()
    this.newSitemapType = type || SitemapType.Default
    this.editableSitemapName = UNTITLED
  }

  public updateGeoposition = async (
    sitemap: Sitemap,
    geoposition?: ISitemapGeoPosition,
    width?: number,
    height?: number,
    bounds?,
  ) => {
    this.isSavingGeolocation = true
    if (geoposition) {
      sitemap.setGeoposition(geoposition, width, height, bounds)
    }

    await this.saveSitemap(sitemap, false, false, width, height)
    this.isSavingGeolocation = false
  }

  public uploadNewSitemap = async (
    file: File | Blob,
    name?: string,
    isProjectOverviewMap?: boolean,
    projectId?: string,
    geoposition?: ISitemapGeoPosition,
    width?: number,
    height?: number,
    bounds?,
  ) => {
    if (!file) {
      return
    }

    this.isSitemapUploading = true

    if (this.setPdf(file)) {
      return
    }

    const fullFileName = this.pdfSitemapFile
      ? this.pdfSitemapFile.name
      : this.getFileName(file)

    const fileName = name || fullFileName.split(DOT).slice(0, -1).join(DOT)

    const sitemapUrl = await this.getImageUrl(file)

    const basemap = new Basemap(
      null,
      fileName,
      projectId || this.state.activeProject.id,
      fileName,
      sitemapUrl,
    )
    const basemapId = await this.saveBasemap(basemap)

    const sitemap = new Sitemap(
      null,
      fileName,
      projectId || this.state.activeProject.id,
      SitemapType.Default,
      basemapId,
      sitemapUrl,
      null,
      true,
      [],
      isProjectOverviewMap,
    )

    if (geoposition) {
      sitemap.setGeoposition(geoposition, width, height, bounds)
    }
    sitemap.id = await this.saveSitemap(sitemap, false, false, width, height)

    if (sitemap.id) {
      this.runPostCreationAction(sitemap.id)
      this.selectSitemap(sitemap)
      this.setLastEditedSitemap(sitemap.id)
    }

    this.isSitemapUploading = false

    return sitemap
  }

  public uploadConvertedImage = async (imgFile: Blob) => {
    if (imgFile) {
      await this.uploadNewSitemap(imgFile)
    }

    this.pdfSitemapFile = null
  }

  public runPostCreationAction(sitemapId: string) {
    if (!this.delayedAction) {
      return
    }
    const { action: actionType, dtoId } = this.delayedAction
    switch (actionType) {
      case DelayedActionType.SetDefault:
        const { sitemaps } = this.state.logistics.configurations
        if (!sitemaps.some(s => s.sitemapId === sitemapId)) {
          sitemaps.push({
            sitemapId,
            order: sitemaps.length,
          })

          this.eventsStore.dispatch(e.SAVE_LOGISTICS_CONFIGURATIONS, {
            sitemaps,
          })
        }
        break
      case DelayedActionType.AssignToGate:
        const gate = this.gatesStore.byId.get(dtoId)
        if (gate) {
          gate.assignSitemap(sitemapId)
          this.gatesStore.saveItem(gate)
        }
        break
      case DelayedActionType.AssignToZone:
        const zone = this.zonesStore.byId.get(dtoId)
        if (zone) {
          zone.assignSitemap(sitemapId)
          this.zonesStore.saveItem(zone)
        }
        break
    }

    this.delayedAction = null
  }

  @action.bound
  public duplicateSitemap = async (sitemap: Sitemap) => {
    this.isSitemapUploading = true

    const newSitemap = Object.assign(sitemap.getCopy(), {
      id: null,
    })

    newSitemap.id = await this.saveSitemap(newSitemap)

    if (sitemap.id) {
      this.runPostCreationAction(newSitemap.id)
      this.selectSitemap(newSitemap)
      this.setLastEditedSitemap(newSitemap.id)
    }

    this.isSitemapUploading = false
  }

  @action.bound
  public updateEditableSitemapName(value: string) {
    this.editableSitemapName = value
  }

  public updateSelectedSitemapName = async () => {
    if (
      !this.editableSitemapName ||
      this.selectedSitemap.isNameEqual(this.editableSitemapName)
    ) {
      return
    }

    this.selectedSitemap.setName(this.editableSitemapName)

    await this.saveSitemapWithLoader(this.selectedSitemap)
  }

  public saveSitemap(
    sitemap: Sitemap,
    shouldApplyChangesBeforeSave?: boolean,
    avoidSaving?: boolean,
    width?: number,
    height?: number,
  ): Promise<string> {
    return new Promise<string>(resolve => {
      this.sitemapsStore.save(
        sitemap,
        id => resolve(id),
        shouldApplyChangesBeforeSave,
        avoidSaving,
        width,
        height,
      )
    })
  }

  public saveBasemap(basemap: Basemap): Promise<string> {
    return new Promise<string>(resolve =>
      this.basemapsStore.save(basemap, id => resolve(id)),
    )
  }

  public uploadBaseMap = async (file: File | Blob): Promise<Basemap> => {
    if (!file) {
      return
    }

    this.isSitemapUploading = true

    const fullFileName = this.pdfSitemapFile
      ? this.pdfSitemapFile.name
      : this.getFileName(file)

    const fileName = fullFileName.split(DOT).slice(0, -1).join(DOT)

    const sitemapUrl = await this.getImageUrl(file)

    return new Basemap(
      null,
      fileName,
      this.state.activeProject.id,
      fileName,
      sitemapUrl,
    )
  }

  public updateBasemap = async (
    file: File | Blob,
    basemap?: Basemap,
    fileName?: string,
  ) => {
    this.deliverySitemapSetUpStore.showLoader()

    const basemapUrl = await this.getImageUrl(file)

    basemap.setSource(basemapUrl)
    basemap.setFileName(fileName || this.getFileName(file))

    await this.saveBasemap(basemap)

    this.allSitemaps
      .filter(sitemap => sitemap.basemapId === basemap.id)
      .forEach(async sitemap => {
        const filledImage = await this.createFilledImage(
          basemapUrl,
          sitemap.itemsFilledImage,
        )

        sitemap.setFilledImage(filledImage)

        await this.saveSitemap(sitemap)
      })

    this.deliverySitemapSetUpStore.hideLoader()
  }

  public updateBasemapForSitemap = async (
    file: File | Blob,
    sitemap: Sitemap,
    fileName?: string,
  ) => {
    this.deliverySitemapSetUpStore.showLoader()

    const sitemapUrl = await this.getImageUrl(file)

    const basemap = new Basemap(
      null,
      fileName || this.getFileName(file),
      this.state.activeProject.id,
      fileName || this.getFileName(file),
      sitemapUrl,
    )

    basemap.id = await this.saveBasemap(basemap)

    sitemap.basemapId = basemap.id

    const filledImage = await this.createFilledImage(
      sitemapUrl,
      sitemap.itemsFilledImage,
    )

    sitemap.setFilledImage(filledImage)

    await this.saveSitemap(sitemap)

    this.deliverySitemapSetUpStore.hideLoader()
  }

  public setSitemapProjectOverviewMap = async (
    value: boolean,
    sitemap?: Sitemap,
  ) => {
    if (sitemap) {
      sitemap.setIsProjectOverviewMap(value)
    } else {
      this.selectedSitemap.setIsProjectOverviewMap(value)
    }
  }

  public saveSitemapWithLoader = async (
    sitemap: Sitemap,
    shouldApplyChangesBeforeSave?: boolean,
  ): Promise<string> => {
    this.deliverySitemapSetUpStore.showLoader()

    const id = await this.saveSitemap(sitemap, shouldApplyChangesBeforeSave)

    this.deliverySitemapSetUpStore.hideLoader()

    return id
  }

  public async saveBasemapWithLoader(basemap: Basemap): Promise<string> {
    this.deliverySitemapSetUpStore.showLoader()

    const id = await this.saveBasemap(basemap)

    this.deliverySitemapSetUpStore.hideLoader()

    return id
  }

  public toggleSitemapLabelsShown = async () => {
    this.selectedSitemap.toggleLabelsShown()

    await this.saveSitemapWithLoader(this.selectedSitemap)

    await this.deliverySitemapSetUpStore.requestSaveDeliverySitemapImage()
  }

  public updateSitemapFilledImage = async (
    sitemap: Sitemap,
    file: File,
    itemsFile: File,
  ) => {
    if (!this.selectedSitemap) {
      return
    }
    const sitemapUrl = await this.getImageUrl(file)
    const itemsUrl = await this.getImageUrl(itemsFile)

    sitemap.setFilledImage(sitemapUrl)
    sitemap.setItemsFilledImage(itemsUrl)

    await this.saveSitemap(sitemap)
  }

  @action.bound
  public async selectSitemap(
    sitemap: Sitemap,
    shouldSaveSelectedItem: boolean = true,
  ) {
    if (!sitemap) {
      return
    }

    if (shouldSaveSelectedItem) {
      await this.sitemapItemsSetupStore.saveSelectedSitemapItem()
    }

    this.deselectSitemap()

    this.selectedSitemapId = sitemap.id
    this.editableSitemapName = sitemap.name
    this.setViewportFromAdress()
  }

  @action.bound
  public setLastEditedSitemap(lastEditedSitemapId: string) {
    const { userActiveProjectSettings } = this.eventsStore.appState
    const userActiveProjectSettingsDto = userActiveProjectSettings.toDto()

    const sitemapSettings: ISitemapsSetupSettings = {
      lastEditedSitemapId,
    }

    userActiveProjectSettingsDto.sitemapsSetupSettings = sitemapSettings

    this.userProjectsStore.save([userActiveProjectSettingsDto])
  }

  @action.bound
  public deselectSitemap() {
    this.selectedSitemapId = null
    this.setViewportFromAdress()
    this.hideAssignSitemapDialog()
  }

  @action.bound
  public showDeleteConfirmationDialogForSelectedSitemap() {
    if (this.selectedSitemap) {
      this.showDeleteConfirmationDialog(this.selectedSitemap)
    }
  }

  @action.bound
  public showDeleteConfirmationDialog(sitemap: Sitemap) {
    this.deletableSitemap = sitemap
  }

  @action.bound
  public hideSitemapDeleteConfirmDialog() {
    this.deletableSitemap = null
  }

  @action.bound
  public applySitemapDeleteConfirmDialog() {
    if (!this.deletableSitemap) {
      return
    }

    this.sitemapsStore.removeOne(this.deletableSitemap.id)

    this.hideSitemapDeleteConfirmDialog()

    this.selectInitialSitemap()
  }

  @action.bound
  public toggleAssignSitemapDialog() {
    this.isAssignSitemapDialogShown = !this.isAssignSitemapDialogShown
  }

  @action.bound
  public hideAssignSitemapDialog() {
    this.isAssignSitemapDialogShown = false
  }

  @computed
  public get deliveriesAssignedSitemaps(): { [filterType: string]: boolean } {
    return this.state.delivery.configurations.sitemaps.reduce(
      (map, sitemap) => {
        map[sitemap.sitemapId] = true
        return map
      },
      {},
    )
  }

  @computed
  public get siteAssignedSitemaps(): { [filterType: string]: boolean } {
    return this.state.logistics.configurations.sitemaps.reduce(
      (map, sitemap) => {
        map[sitemap.sitemapId] = true
        return map
      },
      {},
    )
  }

  @computed
  public get formsAssignedSitemaps(): { [filterType: string]: boolean } {
    return this.state.forms.configurations.sitemaps.reduce((map, sitemap) => {
      map[sitemap.sitemapId] = true
      return map
    }, {})
  }

  @computed
  public get activitiesAssignedSitemaps(): { [filterType: string]: boolean } {
    return this.state.activitiesSettings.configurations.sitemaps.reduce(
      (map, sitemap) => {
        map[sitemap.sitemapId] = true
        return map
      },
      {},
    )
  }

  @action.bound
  public onSiteSitemapSectionClick(sitemap: Sitemap) {
    if (this.siteAssignedSitemaps[sitemap.id]) {
      this.clearSiteSitemap(sitemap)
    } else {
      this.assignSiteSitemap(sitemap)
    }
  }

  @action.bound
  public onFormsSitemapSectionClick(sitemap: Sitemap) {
    if (this.formsAssignedSitemaps[sitemap.id]) {
      this.clearFromsSitemap(sitemap)
    } else {
      this.assignFormsSitemap(sitemap)
    }
  }

  @action.bound
  public onDeliveriesSectionClick(sitemap: Sitemap) {
    if (this.deliveriesAssignedSitemaps[sitemap.id]) {
      this.clearDeliveriesSitemap(sitemap)
    } else {
      this.assignDeliveriesSitemap(sitemap)
    }
  }

  @action.bound
  public onActivitiesSitemapSectionClick(sitemap: Sitemap) {
    if (this.activitiesAssignedSitemaps[sitemap.id]) {
      this.clearActivitiesSitemap(sitemap)
    } else {
      this.assignActivitiesSitemap(sitemap)
    }
  }

  private async createFilledImage(
    backgroundImage: string,
    itemsImage?: string,
  ) {
    if (!itemsImage) {
      return backgroundImage
    }

    const background = await this.loadImage(backgroundImage)
    const items = await this.loadImage(itemsImage)

    const { width, height } = background
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    ctx.canvas.width = width
    ctx.canvas.height = height

    ctx.drawImage(background, 0, 0, width, height)
    ctx.drawImage(items, 0, 0, width, height)

    const blob = await new Promise<Blob>(resolve => {
      canvas.toBlob(resolve, SCALED_IMAGE_TYPE, SCALED_IMAGE_QUALITY)
    })

    return this.getImageUrl(blob)
  }

  private loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise(resolve => {
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = src
      image.onload = () => {
        resolve(image)
      }
    })
  }

  @action.bound
  private clearDeliveriesSitemap(sitemap: Sitemap) {
    const { configurations } = this.state.delivery
    this.removeSitemapFromConfig(
      configurations,
      e.SAVE_DELIVERY_CONFIGURATIONS,
      sitemap,
    )
  }

  @action.bound
  private clearFromsSitemap(sitemap: Sitemap) {
    const { configurations } = this.state.forms
    this.removeSitemapFromConfig(
      configurations,
      e.SAVE_FORMS_CONFIGURATIONS,
      sitemap,
    )
  }

  @action.bound
  private clearSiteSitemap(sitemap: Sitemap) {
    const { configurations } = this.state.logistics
    this.removeSitemapFromConfig(
      configurations,
      e.SAVE_LOGISTICS_CONFIGURATIONS,
      sitemap,
    )
  }

  @action.bound
  private clearActivitiesSitemap(sitemap: Sitemap) {
    const { configurations } = this.state.activitiesSettings
    this.removeSitemapFromConfig(
      configurations,
      e.SAVE_ACTIVITIES_CONFIGURATIONS,
      sitemap,
    )
  }

  @action.bound
  private assignFormsSitemap(sitemap: Sitemap) {
    const { sitemaps } = this.state.forms.configurations
    this.addSitemapToConfig(sitemaps, e.SAVE_FORMS_CONFIGURATIONS, sitemap)
  }

  @action.bound
  private assignSiteSitemap(sitemap: Sitemap) {
    const { sitemaps } = this.state.logistics.configurations
    this.addSitemapToConfig(sitemaps, e.SAVE_LOGISTICS_CONFIGURATIONS, sitemap)
  }

  @action.bound
  private assignActivitiesSitemap(sitemap: Sitemap) {
    const { sitemaps } = this.state.activitiesSettings.configurations
    this.addSitemapToConfig(sitemaps, e.SAVE_ACTIVITIES_CONFIGURATIONS, sitemap)
  }

  @action.bound
  private assignDeliveriesSitemap(sitemap: Sitemap) {
    const { sitemaps } = this.state.delivery.configurations
    this.addSitemapToConfig(sitemaps, e.SAVE_DELIVERY_CONFIGURATIONS, sitemap)
  }

  @action.bound
  private addSitemapToConfig(
    sitemaps: IOrderedSitemap[],
    eventType: EventTypes,
    sitemap: Sitemap,
  ) {
    if (!sitemap) {
      return
    }

    const { id } = sitemap
    if (!sitemaps.some(s => s.sitemapId === id)) {
      sitemaps.push({
        sitemapId: id,
        order: sitemaps.length,
      })

      this.eventsStore.dispatch(eventType, {
        sitemaps,
        projectId: this.state.activeProject.id,
      })
    }
  }

  @action.bound
  private removeSitemapFromConfig(
    config:
      | IDeliveryConfigurations
      | IActivitiesConfigurations
      | IFormsConfigurations
      | ILogisticsConfigurations,
    eventType: EventTypes,
    sitemap: Sitemap,
  ) {
    if (!sitemap) {
      return
    }

    const { id } = sitemap

    config.sitemaps = config.sitemaps.filter(s => s.sitemapId !== id)

    this.eventsStore.dispatch(eventType, {
      sitemaps: config.sitemaps,
    })
  }

  private get defaultSitemap(): Sitemap {
    const { sitemaps } = this.state.logistics.configurations
    return sitemaps.length && this.sitemapsStore.byId.get(sitemaps[0].sitemapId)
  }

  private get lastEditedSitemap(): Sitemap {
    const { sitemapsSetupSettings } = this.state.userActiveProjectSettings

    const lastEditedSitemapId = sitemapsSetupSettings?.lastEditedSitemapId

    if (!lastEditedSitemapId) {
      return null
    }

    if (this.sitemapsStore.byId.has(lastEditedSitemapId)) {
      return this.sitemapsStore.byId.get(lastEditedSitemapId)
    }
  }

  private get sitemapItemsSetupStore(): SitemapItemsSetupStore {
    return this.deliverySitemapSetUpStore.sitemapItemsSetupStore
  }

  private get state(): DesktopInitialState {
    return this.eventsStore.appState
  }

  private setPdf(file: File | Blob) {
    if (file.type === 'application/pdf') {
      this.pdfSitemapFile = file as File
      return true
    }

    return false
  }

  private getFileName(file: File | Blob) {
    return (file as File).name || UNTITLED
  }

  private async getImageUrl(sourceFile: File | Blob): Promise<string> {
    const file = await this.getScaledImage(sourceFile)

    const isImage = file.type.startsWith('image/')

    const fileType = isImage ? UploadingType.Image : UploadingType.Pdf
    const [result] = await this.fileUploadingStore.uploadFile(
      file,
      fileType,
      (file as File).name,
    )
    return result.fileURL
  }

  private async getScaledImage(file: File | Blob): Promise<File | Blob> {
    const isImage = file.type.startsWith('image/')

    if (!isImage) {
      return file
    }

    const img = new Image()
    img.src = URL.createObjectURL(file)

    await new Promise(resolve => {
      img.onload = resolve
    })

    if (img.width <= MAX_IMAGE_WIDTH && img.height <= MAX_IMAGE_HEIGHT) {
      return file
    }
    return this.scaleImage(img)
  }

  private scaleImage(img: HTMLImageElement): Promise<Blob> {
    let { width, height } = img

    if (width > MAX_IMAGE_WIDTH) {
      height *= MAX_IMAGE_WIDTH / width
      width = MAX_IMAGE_WIDTH
    }

    if (height > MAX_IMAGE_HEIGHT) {
      width *= MAX_IMAGE_HEIGHT / height
      height = MAX_IMAGE_HEIGHT
    }

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, width, height)

    return new Promise<Blob>(resolve => {
      canvas.toBlob(resolve, SCALED_IMAGE_TYPE, SCALED_IMAGE_QUALITY)
    })
  }
}

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICompanyFieldsFragment = Pick<
  Types.ICompany,
  | 'id'
  | 'name'
  | 'projectId'
  | 'typeTags'
  | 'code'
  | 'isDeleted'
  | 'businessEmail'
  | 'businessPhone'
  | 'responsibleContactIds'
  | 'roleIds'
  | 'tradeIds'
  | 'reportsToId'
  | 'logoUrl'
  | 'avatarUrl'
  | 'dba'
  | 'licenseNumber'
> & {
  address?: Types.Maybe<
    Pick<
      Types.ICompanyAddress,
      'address' | 'country' | 'city' | 'state' | 'zipCode'
    >
  >
}

export type ICompaniesByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type ICompaniesByProjectIdQuery = {
  companies?: Types.Maybe<{ data: Array<ICompanyFieldsFragment> }>
}

export type IAvailableCompanyOptionsByProjectCodeQueryVariables = Types.Exact<{
  projectCode: Types.Scalars['String']
}>

export type IAvailableCompanyOptionsByProjectCodeQuery = {
  companyOptions?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ICompany, 'value' | 'name'>>>
  >
}

export type ICompanyTypeTagsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type ICompanyTypeTagsQuery = {
  companyTypeTags?: Types.Maybe<{
    data: Array<Pick<Types.IEntityTag, 'id' | 'value' | 'color'>>
  }>
}

export type IListenCompanyTypeTagSubscriptionVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IListenCompanyTypeTagSubscription = {
  companyTypeTag?: Types.Maybe<
    Pick<Types.IEntityTagChangeEvent, 'id'> & {
      item?: Types.Maybe<Pick<Types.IEntityTag, 'id' | 'value'>>
    }
  >
}

export type IListenCompaniesByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenCompaniesByProjectIdSubscription = {
  company?: Types.Maybe<
    Pick<Types.ICompanyChangeEvent, 'id'> & {
      item?: Types.Maybe<ICompanyFieldsFragment>
    }
  >
}

export type ISaveManyCompaniesMutationVariables = Types.Exact<{
  companies:
    | Array<Types.Maybe<Types.ICompanyInput>>
    | Types.Maybe<Types.ICompanyInput>
}>

export type ISaveManyCompaniesMutation = {
  saveManyCompanies?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ICompany, 'id' | 'name'>>>
  >
}

export type IDeleteCompaniesMutationVariables = Types.Exact<{
  companyIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteCompaniesMutation = Pick<
  Types.IMutation,
  'softDeleteManyCompanies'
>

export type IActivateCompaniesMutationVariables = Types.Exact<{
  companyIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IActivateCompaniesMutation = Pick<
  Types.IMutation,
  'recoverManyCompanies'
>

export const CompanyFieldsFragmentDoc = gql`
  fragment CompanyFields on Company {
    id
    name
    projectId
    typeTags
    code
    isDeleted
    address {
      address
      country
      city
      state
      zipCode
    }
    businessEmail
    businessPhone
    responsibleContactIds
    roleIds
    tradeIds
    reportsToId
    logoUrl
    avatarUrl
    dba
    licenseNumber
  }
`
export const CompaniesByProjectIdDocument = gql`
  query CompaniesByProjectId($projectId: ObjectId!) {
    companies(projectId: $projectId, limit: 1000000) {
      data {
        ...CompanyFields
      }
    }
  }
  ${CompanyFieldsFragmentDoc}
`

/**
 * __useCompaniesByProjectIdQuery__
 *
 * To run a query within a React component, call `useCompaniesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCompaniesByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICompaniesByProjectIdQuery,
    ICompaniesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ICompaniesByProjectIdQuery,
    ICompaniesByProjectIdQueryVariables
  >(CompaniesByProjectIdDocument, options)
}
export function useCompaniesByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICompaniesByProjectIdQuery,
    ICompaniesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICompaniesByProjectIdQuery,
    ICompaniesByProjectIdQueryVariables
  >(CompaniesByProjectIdDocument, options)
}
export type CompaniesByProjectIdQueryHookResult = ReturnType<
  typeof useCompaniesByProjectIdQuery
>
export type CompaniesByProjectIdLazyQueryHookResult = ReturnType<
  typeof useCompaniesByProjectIdLazyQuery
>
export type CompaniesByProjectIdQueryResult = Apollo.QueryResult<
  ICompaniesByProjectIdQuery,
  ICompaniesByProjectIdQueryVariables
>
export const AvailableCompanyOptionsByProjectCodeDocument = gql`
  query AvailableCompanyOptionsByProjectCode($projectCode: String!) {
    companyOptions(projectCode: $projectCode) {
      value
      name
    }
  }
`

/**
 * __useAvailableCompanyOptionsByProjectCodeQuery__
 *
 * To run a query within a React component, call `useAvailableCompanyOptionsByProjectCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCompanyOptionsByProjectCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCompanyOptionsByProjectCodeQuery({
 *   variables: {
 *      projectCode: // value for 'projectCode'
 *   },
 * });
 */
export function useAvailableCompanyOptionsByProjectCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    IAvailableCompanyOptionsByProjectCodeQuery,
    IAvailableCompanyOptionsByProjectCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IAvailableCompanyOptionsByProjectCodeQuery,
    IAvailableCompanyOptionsByProjectCodeQueryVariables
  >(AvailableCompanyOptionsByProjectCodeDocument, options)
}
export function useAvailableCompanyOptionsByProjectCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IAvailableCompanyOptionsByProjectCodeQuery,
    IAvailableCompanyOptionsByProjectCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IAvailableCompanyOptionsByProjectCodeQuery,
    IAvailableCompanyOptionsByProjectCodeQueryVariables
  >(AvailableCompanyOptionsByProjectCodeDocument, options)
}
export type AvailableCompanyOptionsByProjectCodeQueryHookResult = ReturnType<
  typeof useAvailableCompanyOptionsByProjectCodeQuery
>
export type AvailableCompanyOptionsByProjectCodeLazyQueryHookResult =
  ReturnType<typeof useAvailableCompanyOptionsByProjectCodeLazyQuery>
export type AvailableCompanyOptionsByProjectCodeQueryResult =
  Apollo.QueryResult<
    IAvailableCompanyOptionsByProjectCodeQuery,
    IAvailableCompanyOptionsByProjectCodeQueryVariables
  >
export const CompanyTypeTagsDocument = gql`
  query CompanyTypeTags {
    companyTypeTags {
      data {
        id
        value
        color
      }
    }
  }
`

/**
 * __useCompanyTypeTagsQuery__
 *
 * To run a query within a React component, call `useCompanyTypeTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTypeTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTypeTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyTypeTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ICompanyTypeTagsQuery,
    ICompanyTypeTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICompanyTypeTagsQuery, ICompanyTypeTagsQueryVariables>(
    CompanyTypeTagsDocument,
    options,
  )
}
export function useCompanyTypeTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICompanyTypeTagsQuery,
    ICompanyTypeTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICompanyTypeTagsQuery,
    ICompanyTypeTagsQueryVariables
  >(CompanyTypeTagsDocument, options)
}
export type CompanyTypeTagsQueryHookResult = ReturnType<
  typeof useCompanyTypeTagsQuery
>
export type CompanyTypeTagsLazyQueryHookResult = ReturnType<
  typeof useCompanyTypeTagsLazyQuery
>
export type CompanyTypeTagsQueryResult = Apollo.QueryResult<
  ICompanyTypeTagsQuery,
  ICompanyTypeTagsQueryVariables
>
export const ListenCompanyTypeTagDocument = gql`
  subscription ListenCompanyTypeTag($id: ObjectId) {
    companyTypeTag(id: $id) {
      id
      item {
        id
        value
      }
    }
  }
`

/**
 * __useListenCompanyTypeTagSubscription__
 *
 * To run a query within a React component, call `useListenCompanyTypeTagSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenCompanyTypeTagSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenCompanyTypeTagSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListenCompanyTypeTagSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    IListenCompanyTypeTagSubscription,
    IListenCompanyTypeTagSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenCompanyTypeTagSubscription,
    IListenCompanyTypeTagSubscriptionVariables
  >(ListenCompanyTypeTagDocument, options)
}
export type ListenCompanyTypeTagSubscriptionHookResult = ReturnType<
  typeof useListenCompanyTypeTagSubscription
>
export type ListenCompanyTypeTagSubscriptionResult =
  Apollo.SubscriptionResult<IListenCompanyTypeTagSubscription>
export const ListenCompaniesByProjectIdDocument = gql`
  subscription ListenCompaniesByProjectId($projectId: ObjectId!) {
    company(projectId: $projectId) {
      id
      item {
        ...CompanyFields
      }
    }
  }
  ${CompanyFieldsFragmentDoc}
`

/**
 * __useListenCompaniesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenCompaniesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenCompaniesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenCompaniesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenCompaniesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenCompaniesByProjectIdSubscription,
    IListenCompaniesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenCompaniesByProjectIdSubscription,
    IListenCompaniesByProjectIdSubscriptionVariables
  >(ListenCompaniesByProjectIdDocument, options)
}
export type ListenCompaniesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenCompaniesByProjectIdSubscription
>
export type ListenCompaniesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenCompaniesByProjectIdSubscription>
export const SaveManyCompaniesDocument = gql`
  mutation SaveManyCompanies($companies: [CompanyInput]!) {
    saveManyCompanies(companies: $companies) {
      id
      name
    }
  }
`
export type ISaveManyCompaniesMutationFn = Apollo.MutationFunction<
  ISaveManyCompaniesMutation,
  ISaveManyCompaniesMutationVariables
>

/**
 * __useSaveManyCompaniesMutation__
 *
 * To run a mutation, you first call `useSaveManyCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyCompaniesMutation, { data, loading, error }] = useSaveManyCompaniesMutation({
 *   variables: {
 *      companies: // value for 'companies'
 *   },
 * });
 */
export function useSaveManyCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyCompaniesMutation,
    ISaveManyCompaniesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyCompaniesMutation,
    ISaveManyCompaniesMutationVariables
  >(SaveManyCompaniesDocument, options)
}
export type SaveManyCompaniesMutationHookResult = ReturnType<
  typeof useSaveManyCompaniesMutation
>
export type SaveManyCompaniesMutationResult =
  Apollo.MutationResult<ISaveManyCompaniesMutation>
export type SaveManyCompaniesMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyCompaniesMutation,
  ISaveManyCompaniesMutationVariables
>
export const DeleteCompaniesDocument = gql`
  mutation DeleteCompanies($companyIds: [ObjectId]!) {
    softDeleteManyCompanies(ids: $companyIds)
  }
`
export type IDeleteCompaniesMutationFn = Apollo.MutationFunction<
  IDeleteCompaniesMutation,
  IDeleteCompaniesMutationVariables
>

/**
 * __useDeleteCompaniesMutation__
 *
 * To run a mutation, you first call `useDeleteCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompaniesMutation, { data, loading, error }] = useDeleteCompaniesMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useDeleteCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteCompaniesMutation,
    IDeleteCompaniesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteCompaniesMutation,
    IDeleteCompaniesMutationVariables
  >(DeleteCompaniesDocument, options)
}
export type DeleteCompaniesMutationHookResult = ReturnType<
  typeof useDeleteCompaniesMutation
>
export type DeleteCompaniesMutationResult =
  Apollo.MutationResult<IDeleteCompaniesMutation>
export type DeleteCompaniesMutationOptions = Apollo.BaseMutationOptions<
  IDeleteCompaniesMutation,
  IDeleteCompaniesMutationVariables
>
export const ActivateCompaniesDocument = gql`
  mutation ActivateCompanies($companyIds: [ObjectId]!) {
    recoverManyCompanies(ids: $companyIds)
  }
`
export type IActivateCompaniesMutationFn = Apollo.MutationFunction<
  IActivateCompaniesMutation,
  IActivateCompaniesMutationVariables
>

/**
 * __useActivateCompaniesMutation__
 *
 * To run a mutation, you first call `useActivateCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompaniesMutation, { data, loading, error }] = useActivateCompaniesMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useActivateCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IActivateCompaniesMutation,
    IActivateCompaniesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IActivateCompaniesMutation,
    IActivateCompaniesMutationVariables
  >(ActivateCompaniesDocument, options)
}
export type ActivateCompaniesMutationHookResult = ReturnType<
  typeof useActivateCompaniesMutation
>
export type ActivateCompaniesMutationResult =
  Apollo.MutationResult<IActivateCompaniesMutation>
export type ActivateCompaniesMutationOptions = Apollo.BaseMutationOptions<
  IActivateCompaniesMutation,
  IActivateCompaniesMutationVariables
>

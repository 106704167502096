import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { observer } from 'mobx-react'
import { Group } from 'react-konva'

import { SitemapItemShapeType } from '~/client/graph'
import MapBoxEditorStore from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor.store'
import BoundingBox from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/BoundingBox'
import SitemapIcon from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/SitemapIcon'
import SitemapLabel from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/SitemapLabel'
import SitemapShape from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/SitemapShape'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import SitemapItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import SitemapRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapRectangleProperties'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'

import SitemapItemsSetupStore from '../../stores/SitemapItemsSetup.store'
import SitemapSetupStore from '../../stores/SitemapsSetup.store'
import BoundsEditor from './BoundsEditor'
import CircleEditor from './CircleEditor'
import PolyLineEditor from './PolyLineEditor'
import RectangleEditor from './RectangleEditor'

interface IMovableItem {
  move(x: number, y: number)
}

const MAX_PERCENT = 100
const ZERO = 0

interface IProps {
  containerWidth: number
  containerHeight: number
  store: SitemapItemsSetupStore
  textboxesCache: ICanvasImageCache
  sitemapSetupStore: SitemapSetupStore
  mapBoxEditorStore: MapBoxEditorStore
}

@observer
export default class SitemapEditableItem extends React.Component<IProps> {
  public render() {
    const {
      sitemapSetupStore: { selectedSitemap },
      store: { selectedSitemapItem, selectedSitemapItemDrawnPart },
    } = this.props
    if (
      !selectedSitemap ||
      !selectedSitemapItem ||
      selectedSitemapItem.isRichTextBox
    ) {
      return null
    }

    if (!selectedSitemapItemDrawnPart || !selectedSitemapItem.isDisplayed) {
      return this.renderItem()
    }

    switch (selectedSitemapItemDrawnPart) {
      case SitemapItemDrawnPart.Icon:
        return (
          <>
            {this.renderShape()}
            {this.renderLabel()}
            {this.renderEditableIcon()}
          </>
        )
      case SitemapItemDrawnPart.Label:
        return (
          <>
            {this.renderShape()}
            {this.renderIcon()}
            {this.renderEditableLabel()}
          </>
        )
      case SitemapItemDrawnPart.Shape:
        return (
          <>
            {this.renderLabel()}
            {this.renderIcon()}
            {this.renderEditableShape()}
          </>
        )
    }
  }

  private renderIcon = () => {
    const {
      containerHeight,
      containerWidth,
      store: { selectedSitemapItem },
    } = this.props

    return (
      <SitemapIcon
        item={selectedSitemapItem}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onDblClick={this.selectDrawnPartEditing.bind(
          this,
          SitemapItemDrawnPart.Icon,
        )}
        onClick={this.onDrawnPartClick}
      />
    )
  }

  private renderLabel = () => {
    const { containerHeight, containerWidth, textboxesCache } = this.props
    const { selectedSitemapItem } = this.props.store

    return (
      <SitemapLabel
        item={selectedSitemapItem}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onDblClick={this.selectDrawnPartEditing.bind(
          this,
          SitemapItemDrawnPart.Label,
        )}
        onClick={this.onDrawnPartClick}
        textboxesCache={textboxesCache}
      />
    )
  }

  private renderShape = () => {
    const { containerHeight, containerWidth } = this.props
    const { selectedSitemapItem } = this.props.store

    return (
      <SitemapShape
        item={selectedSitemapItem}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onDblClick={this.selectDrawnPartEditing.bind(
          this,
          SitemapItemDrawnPart.Shape,
        )}
        onClick={this.onDrawnPartClick}
        isStaticSize={true}
      />
    )
  }

  private onDrawnPartClick = (event: KonvaEventObject<MouseEvent>) => {
    event.cancelBubble = true
  }

  private selectDrawnPartEditing(
    part: SitemapItemDrawnPart,
    event: KonvaEventObject<MouseEvent>,
  ) {
    event.cancelBubble = true
    const { selectSitemapItemDrawnPart } = this.props.store
    selectSitemapItemDrawnPart(part)
  }

  private renderItem() {
    const { selectedSitemapItem } = this.props.store
    const {
      containerHeight,
      containerWidth,
      mapBoxEditorStore,
      sitemapSetupStore,
    } = this.props

    return (
      <Group
        draggable={true}
        onMouseDown={this.startEditing}
        onMouseUp={this.cancelEditing}
        onDragEnd={this.onItemDragEnd.bind(this, selectedSitemapItem)}
      >
        {this.renderItemBoundingBox()}
        {this.renderShape()}
        {this.renderLabel()}
        {this.renderIcon()}
        <BoundsEditor
          item={selectedSitemapItem}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
          mapBoxEditorStore={mapBoxEditorStore}
          isReferenced={sitemapSetupStore.selectedSitemap.isReferenced}
        />
      </Group>
    )
  }

  private renderItemBoundingBox() {
    const { selectedSitemapItem } = this.props.store
    const { containerHeight, containerWidth } = this.props

    const boundingBox = selectedSitemapItem.getBoundingBox(
      containerWidth,
      containerHeight,
    )

    return <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
  }

  private renderEditableIcon = () => {
    const { containerHeight, containerWidth } = this.props
    const { selectedSitemapItem } = this.props.store
    const { iconProperties, iconName } = selectedSitemapItem

    const boundingBox = iconProperties.getBoundingBox(
      iconName,
      containerWidth,
      containerHeight,
    )

    return (
      <Group
        draggable={true}
        onMouseDown={this.startEditing}
        onMouseUp={this.cancelEditing}
        onDragEnd={this.onItemDragEnd.bind(this, iconProperties)}
      >
        <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
        <SitemapIcon
          item={selectedSitemapItem}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
        />
      </Group>
    )
  }

  private renderEditableLabel = () => {
    const { containerHeight, containerWidth, textboxesCache } = this.props
    const { selectedSitemapItem } = this.props.store
    const { labelProperties, name } = selectedSitemapItem

    const boundingBox = labelProperties.getBoundingBox(
      name,
      containerWidth,
      containerHeight,
    )

    return (
      <Group
        draggable={true}
        onDragEnd={this.onItemDragEnd.bind(this, labelProperties)}
        onMouseDown={this.startEditing}
        onMouseUp={this.cancelEditing}
      >
        <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
        <SitemapLabel
          item={selectedSitemapItem}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
          textboxesCache={textboxesCache}
        />
      </Group>
    )
  }

  private renderEditableShape = () => {
    const { containerHeight, containerWidth } = this.props
    const { selectedSitemapItem } = this.props.store
    const { shapeProperties } = selectedSitemapItem

    const boundingBox = shapeProperties.getBoundingBox(
      containerWidth,
      containerHeight,
    )
    return (
      <Group
        draggable={true}
        onDragEnd={this.onItemDragEnd.bind(this, shapeProperties)}
        onMouseDown={this.startEditing}
        onMouseUp={this.cancelEditing}
      >
        <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
        <SitemapShape
          item={selectedSitemapItem}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
          isStaticSize={true}
        />
        {this.renderShapeEditor()}
      </Group>
    )
  }

  private renderShapeEditor() {
    const {
      containerHeight,
      containerWidth,
      store,
      sitemapSetupStore,
      mapBoxEditorStore,
    } = this.props
    const { shapeProperties } = store.selectedSitemapItem

    switch (shapeProperties.type) {
      case SitemapItemShapeType.Circle:
        return (
          <CircleEditor
            store={store}
            circle={shapeProperties as SitemapCircleProperties}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            isReferenced={sitemapSetupStore.selectedSitemap?.isReferenced}
            mapBoxEditorStore={mapBoxEditorStore}
            onModifyStart={this.startEditing}
            onModifyEnd={this.cancelEditing}
          />
        )
      case SitemapItemShapeType.Polyline:
        return (
          <PolyLineEditor
            store={store}
            item={store.selectedSitemapItem}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            isReferenced={sitemapSetupStore.selectedSitemap?.isReferenced}
            mapBoxEditorStore={mapBoxEditorStore}
            onModifyStart={this.startEditing}
            onModifyEnd={this.cancelEditing}
          />
        )
      case SitemapItemShapeType.Rectangle:
        return (
          <RectangleEditor
            store={store}
            rectangle={shapeProperties as SitemapRectangleProperties}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            onModifyStart={this.startEditing}
            onModifyEnd={this.cancelEditing}
          />
        )
    }
  }

  private startEditing = () => {
    this.props.mapBoxEditorStore.isEditableItemInDragMode = true
  }

  private cancelEditing = () => {
    this.props.mapBoxEditorStore.isEditableItemInDragMode = false
  }

  private onItemDragEnd = (
    item: IMovableItem,
    event: KonvaEventObject<DragEvent>,
  ) => {
    const { containerWidth, containerHeight, store, mapBoxEditorStore } =
      this.props
    const { currentTarget } = event
    item.move(
      (currentTarget.x() / containerWidth) * MAX_PERCENT,
      (currentTarget.y() / containerHeight) * MAX_PERCENT,
    )
    currentTarget.x(ZERO)
    currentTarget.y(ZERO)
    store.addCurrentStateToHistory()
    mapBoxEditorStore.isEditableItemInDragMode = false
  }
}

import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_HEIGHT = 20
const ICON_WIDTH = 20

const ICON_OFFSET_X = 4
const ICON_OFFSET_Y = 3
const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const ICON_SCALE = 0.8

const ICON_PATH =
  'M15.9315007,14.9389474 L14.0928346,11.1326316 C13.9666516,10.8631579 13.6782334,10.6947368 13.353763,10.6947368 L12.5425868,10.6947368 L10.1631365,1.09473684 C10.0009013,0.437894737 9.38801262,0 8.68499324,0 L7.3330329,0 C6.61198738,0 6.01712483,0.454736842 5.85488959,1.09473684 L3.45741325,10.6947368 L2.64623704,10.6947368 C2.3397927,10.6947368 2.05137449,10.8631579 1.90716539,11.1326316 L0.068499324,14.9389474 C-0.165840469,15.4273684 0.230734565,16 0.807570978,16 L15.192429,16 C15.7692654,16 16.1658405,15.4442105 15.9315007,14.9389474 Z M5.87291573,5.50736842 C6.37764759,5.69263158 7.13474538,5.81052632 8,5.81052632 C8.86525462,5.81052632 9.62235241,5.69263158 10.1270843,5.50736842 L10.61379,7.42736842 C10.1451104,7.69684211 9.15367283,7.89894737 8.01802614,7.89894737 C6.86435331,7.89894737 5.89094187,7.69684211 5.40423614,7.42736842 L5.87291573,5.50736842 Z M4.61108607,10.6105263 C5.22397476,10.9810526 6.50383055,11.2336842 8,11.2336842 C9.49616945,11.2336842 10.7940514,10.9810526 11.3889139,10.6105263 L11.9657503,12.8505263 C11.2266787,13.2715789 9.74853538,13.5578947 8,13.5578947 C6.25146462,13.5578947 4.77332132,13.2715789 4.03424966,12.8505263 L4.61108607,10.6105263 Z M7.3330329,1.02736842 L8.68499324,1.02736842 C8.88328076,1.02736842 9.06354214,1.16210526 9.09959441,1.33052632 L9.568274,3.28421053 C9.17169896,3.40210526 8.61288869,3.48631579 8,3.48631579 C7.36908517,3.48631579 6.8102749,3.40210526 6.41369986,3.28421053 L6.91843173,1.33052632 C6.954484,1.14526316 7.13474538,1.02736842 7.3330329,1.02736842 Z'

const STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaAreaIcon = ({ color, ...rest }) => {
  const halfSize = ICON_HEIGHT / 2
  return (
    <Group {...rest} offsetX={ICON_WIDTH / 2} offsetY={ICON_HEIGHT / 2}>
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={ICON_PATH}
        fill={getHEXColor(Colors.neutral100)}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        scaleX={ICON_SCALE}
        scaleY={ICON_SCALE}
      />
    </Group>
  )
}

export default KonvaAreaIcon

import { action, observable } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import MapBoxEditorStore from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor.store'
import {
  ACTIVATE_PROJECT,
  SAVE_DELIVERY_SITEMAP_IMAGE,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import SitemapItemsSetupStore from './stores/SitemapItemsSetup.store'
import SitemapViewsSetupStore from './stores/SitemapViewsSetup.store'
import SitemapSetupStore from './stores/SitemapsSetup.store'

const MIME_TYPE_REGEX = /:(.*?);/

export enum SetUpSteps {
  uploadPlan = '1. Upload plan',
  alignPlan = '2. Align plan & basemap',
}
export default class GeneralSitemapSetUpStore {
  public sitemapSetupStore: SitemapSetupStore
  public sitemapItemsSetupStore: SitemapItemsSetupStore
  public sitemapViewsSetupStore: SitemapViewsSetupStore
  public mapBoxEditorStore: MapBoxEditorStore

  @observable
  public isUpdatingSitemapLoaderShown: boolean = false
  @observable public currentStep: SetUpSteps = null

  private resolveSaveImagePromise: () => void = null

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    public readonly sitemapsStore: SitemapsStore,
    basemapsStore: BasemapsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly sitemapItemsStore: SitemapItemsStore,
    fileUploadingStore: FileUploadingStore,
    syncRestrictionsStore: SyncRestrictionsStore,
    activityFiltersStore: ActivityFiltersStore,
    userProjectsStore: UserProjectsStore,
    tagsStore: TagsStore,
  ) {
    this.sitemapSetupStore = new SitemapSetupStore(
      eventsStore,
      sitemapsStore,
      basemapsStore,
      fileUploadingStore,
      this,
      locationAttributesStore.gatesStore,
      locationAttributesStore.zonesStore,
      userProjectsStore,
    )

    this.sitemapItemsSetupStore = new SitemapItemsSetupStore(
      eventsStore,
      sitemapItemsStore,
      locationAttributesStore,
      syncRestrictionsStore,
      activityFiltersStore,
      this,
    )
    this.sitemapViewsSetupStore = new SitemapViewsSetupStore(
      eventsStore.appState,
      this,
      locationAttributesStore,
      tagsStore,
    )

    this.mapBoxEditorStore = new MapBoxEditorStore(
      () => this.sitemapSetupStore.selectedSitemap,
      sitemapItemsStore,
      sitemapsStore,
      locationAttributesStore,
      this.eventsStore.appState,
      true,
      this.sitemapItemsSetupStore.isSitemapItemSelected,
    )
  }

  public setStep = (step: SetUpSteps) => {
    this.currentStep = step
    if (step !== SetUpSteps.alignPlan) {
      this.mapBoxEditorStore.resetMapboxInfo()
      this.mapBoxEditorStore.updateMapboxInfo()
    }
    this.mapBoxEditorStore.shouldShowImage = false
    this.mapBoxEditorStore.isRubberMode = step === SetUpSteps.alignPlan
    this.mapBoxEditorStore.isDraggingMode = step === SetUpSteps.alignPlan

    if (!this.sitemapSetupStore.selectedSitemap?.isReferenced) {
      this.mapBoxEditorStore.setViewportFromAdress()
    }

    this.mapBoxEditorStore.updateOpacityBySetupStep()
  }

  public setOpacity = (opacity: number) => {
    this.mapBoxEditorStore.opacity = opacity
  }

  public get isInitialLoaderShown(): boolean {
    return (
      !this.sitemapsStore.isDataReceived ||
      !this.sitemapItemsStore.isDataReceived ||
      !this.locationAttributesStore.isDataReceived
    )
  }

  public get isLoading(): boolean {
    const { loading } = this.eventsStore.appState
    return loading.get(ACTIVATE_PROJECT)
  }

  @action.bound
  public showLoader() {
    this.isUpdatingSitemapLoaderShown = true
  }

  @action.bound
  public hideLoader() {
    this.isUpdatingSitemapLoaderShown = false
  }

  public requestSaveDeliverySitemapImage(): Promise<void> {
    this.setSaveSitemapImageLoading(true)
    this.showLoader()

    return new Promise(resolve => {
      this.resolveSaveImagePromise = resolve
      this.eventsStore.dispatch(SAVE_DELIVERY_SITEMAP_IMAGE)
    })
  }

  public saveDeliverySitemapImage = async (
    base64Image: string,
    base64ItemsImage: string,
  ) => {
    const sitemap = this.sitemapSetupStore.selectedSitemap

    if (!base64Image) {
      return this.resolveImagePromise()
    }

    const file = this.base64ImageToFile(base64Image, 'filled-sitemap.png')
    const itemsFile =
      base64ItemsImage &&
      this.base64ImageToFile(base64ItemsImage, 'items-filled-sitemap.png')

    await this.sitemapSetupStore.updateSitemapFilledImage(
      sitemap,
      file,
      itemsFile,
    )

    this.hideLoaderAndHideImageLoading()
    this.resolveImagePromise()
  }

  public base64ImageToFile = (base64Image: string, fileName: string): File => {
    const [base64Type, base64Data] = base64Image.split(',')

    const mime = base64Type.match(MIME_TYPE_REGEX)[1]
    const decodedImageData = atob(base64Data)

    const fileData = new Uint8Array(decodedImageData.length)

    let index = decodedImageData.length
    while (index--) {
      fileData[index] = decodedImageData.charCodeAt(index)
    }

    return new File([fileData], fileName, { type: mime })
  }

  private hideLoaderAndHideImageLoading = () => {
    this.hideLoader()
    this.setSaveSitemapImageLoading(false)
  }

  private setSaveSitemapImageLoading = (isLoading: boolean) => {
    this.eventsStore.appState.loading.set(
      SAVE_DELIVERY_SITEMAP_IMAGE,
      isLoading,
    )
  }

  private resolveImagePromise = () => {
    if (this.resolveSaveImagePromise) {
      this.resolveSaveImagePromise()
      this.resolveSaveImagePromise = null
    }
  }
}

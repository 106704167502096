import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IMaterialConfigurationFieldsFragment = Pick<
  Types.IMaterialConfiguration,
  'id' | 'projectId'
> & {
  sitemaps?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IOrderedSitemap, 'sitemapId' | 'order'>>>
  >
  savedGroups?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IFilterGroup, 'filterNames'>>>
  >
  columnsConfig?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IColumnConfiguration, 'columnId' | 'isHidden' | 'isFixed'>
      >
    >
  >
}

export type IGetMaterialConfigurationQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetMaterialConfigurationQuery = {
  materialConfiguration?: Types.Maybe<IMaterialConfigurationFieldsFragment>
}

export type ISaveMaterialConfigurationMutationVariables = Types.Exact<{
  materialConfiguration: Types.IMaterialConfigurationInput
}>

export type ISaveMaterialConfigurationMutation = {
  saveMaterialConfiguration?: Types.Maybe<IMaterialConfigurationFieldsFragment>
}

export type IListenToMaterialConfigurationSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToMaterialConfigurationSubscription = {
  materialConfiguration?: Types.Maybe<
    Pick<Types.IMaterialConfigurationChangeEvent, 'id'> & {
      item?: Types.Maybe<IMaterialConfigurationFieldsFragment>
    }
  >
}

export const MaterialConfigurationFieldsFragmentDoc = gql`
  fragment MaterialConfigurationFields on MaterialConfiguration {
    id
    sitemaps {
      sitemapId
      order
    }
    savedGroups {
      filterNames
    }
    columnsConfig {
      columnId
      isHidden
      isFixed
    }
    projectId
  }
`
export const GetMaterialConfigurationDocument = gql`
  query GetMaterialConfiguration($projectId: ObjectId!) {
    materialConfiguration(projectId: $projectId) {
      ...MaterialConfigurationFields
    }
  }
  ${MaterialConfigurationFieldsFragmentDoc}
`

/**
 * __useGetMaterialConfigurationQuery__
 *
 * To run a query within a React component, call `useGetMaterialConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialConfigurationQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetMaterialConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetMaterialConfigurationQuery,
    IGetMaterialConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetMaterialConfigurationQuery,
    IGetMaterialConfigurationQueryVariables
  >(GetMaterialConfigurationDocument, options)
}
export function useGetMaterialConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMaterialConfigurationQuery,
    IGetMaterialConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetMaterialConfigurationQuery,
    IGetMaterialConfigurationQueryVariables
  >(GetMaterialConfigurationDocument, options)
}
export type GetMaterialConfigurationQueryHookResult = ReturnType<
  typeof useGetMaterialConfigurationQuery
>
export type GetMaterialConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetMaterialConfigurationLazyQuery
>
export type GetMaterialConfigurationQueryResult = Apollo.QueryResult<
  IGetMaterialConfigurationQuery,
  IGetMaterialConfigurationQueryVariables
>
export const SaveMaterialConfigurationDocument = gql`
  mutation SaveMaterialConfiguration(
    $materialConfiguration: MaterialConfigurationInput!
  ) {
    saveMaterialConfiguration(materialConfiguration: $materialConfiguration) {
      ...MaterialConfigurationFields
    }
  }
  ${MaterialConfigurationFieldsFragmentDoc}
`
export type ISaveMaterialConfigurationMutationFn = Apollo.MutationFunction<
  ISaveMaterialConfigurationMutation,
  ISaveMaterialConfigurationMutationVariables
>

/**
 * __useSaveMaterialConfigurationMutation__
 *
 * To run a mutation, you first call `useSaveMaterialConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMaterialConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMaterialConfigurationMutation, { data, loading, error }] = useSaveMaterialConfigurationMutation({
 *   variables: {
 *      materialConfiguration: // value for 'materialConfiguration'
 *   },
 * });
 */
export function useSaveMaterialConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveMaterialConfigurationMutation,
    ISaveMaterialConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveMaterialConfigurationMutation,
    ISaveMaterialConfigurationMutationVariables
  >(SaveMaterialConfigurationDocument, options)
}
export type SaveMaterialConfigurationMutationHookResult = ReturnType<
  typeof useSaveMaterialConfigurationMutation
>
export type SaveMaterialConfigurationMutationResult =
  Apollo.MutationResult<ISaveMaterialConfigurationMutation>
export type SaveMaterialConfigurationMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveMaterialConfigurationMutation,
    ISaveMaterialConfigurationMutationVariables
  >
export const ListenToMaterialConfigurationDocument = gql`
  subscription ListenToMaterialConfiguration($projectId: ObjectId!) {
    materialConfiguration(projectId: $projectId) {
      id
      item {
        ...MaterialConfigurationFields
      }
    }
  }
  ${MaterialConfigurationFieldsFragmentDoc}
`

/**
 * __useListenToMaterialConfigurationSubscription__
 *
 * To run a query within a React component, call `useListenToMaterialConfigurationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToMaterialConfigurationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToMaterialConfigurationSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToMaterialConfigurationSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToMaterialConfigurationSubscription,
    IListenToMaterialConfigurationSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToMaterialConfigurationSubscription,
    IListenToMaterialConfigurationSubscriptionVariables
  >(ListenToMaterialConfigurationDocument, options)
}
export type ListenToMaterialConfigurationSubscriptionHookResult = ReturnType<
  typeof useListenToMaterialConfigurationSubscription
>
export type ListenToMaterialConfigurationSubscriptionResult =
  Apollo.SubscriptionResult<IListenToMaterialConfigurationSubscription>

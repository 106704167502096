import * as React from 'react'

import { observer } from 'mobx-react'

import DeliverySitemapPreviewHolder from '~/client/src/desktop/components/DeliverySitemapPreviewHolder/DeliverySitemapPreviewHolder'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DeliveryActionTypes from '~/client/src/desktop/enums/DeliveryActionTypes'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapsGallery from '~/client/src/shared/components/SitemapsGallery/SitemapsGallery'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { SAVE_DELIVERY_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'

import NewDeliveryActionButton from '../DeliveriesHeaderBar/components/NewDeliveryActionButton/NewDeliveryActionButton'
import DeliveriesMapStore from './DeliveriesMap.store'
import DeliveriesMapSidebar from './components/DeliveriesMapSidebar/DeliveriesMapSidebar'

// localization: translated
interface IProps {
  store: DeliveriesMapStore

  activeActionType: DeliveryActionTypes
  onNewDeliveryCreate: (
    columnFields?: any,
    startDate?: Date,
    endDate?: Date,
    zoneId?: string,
  ) => void
}

@observer
export default class DeliveriesMap extends React.Component<IProps> {
  private textboxesCache: ICanvasImageCache = {}

  public constructor(props: IProps) {
    super(props)

    props.store.setGroupingFilter()
  }

  public render() {
    const { store } = this.props

    return (
      <div className="row full-height bt-light-cool-grey">
        <DeliveriesMapSidebar
          listStore={store.deliveriesBySitemapListStore}
          mapStore={store}
        />
        {this.renderContent()}
      </div>
    )
  }

  public componentDidMount() {
    this.props.store.mount()
  }

  public componentWillUnmount() {
    this.props.store.unmount()
    this.textboxesCache = {}
  }

  public renderContent() {
    const { store } = this.props
    const { isInitialLoaderShown, deliveriesSitemapsIds } = store

    if (isInitialLoaderShown) {
      return (
        <Loader hint={Localization.translator.sitemapsDataIsLoading + '...'} />
      )
    }

    return (
      <div className="col full-height">
        <div className="full-height relative">
          <SitemapsGallery
            isHideButtonDisplayed={false}
            sitemapsIds={deliveriesSitemapsIds}
            shouldDisableZoom={true}
            SelectedSitemapComponent={this.SitemapComponent}
            eventName={SAVE_DELIVERY_CONFIGURATIONS}
            areArrowsLeftSided={true}
            shouldUseFullHeight={true}
            shouldDisableSwap={true}
            FileInputType={DesktopFileInput}
            renderCreateButton={this.renderCreateNewButton}
            mapboxEditorStore={store.mapBoxEditorStore}
          />
        </div>
      </div>
    )
  }

  private renderCreateNewButton = () => {
    return (
      <NewDeliveryActionButton
        className="no-flex"
        onClick={this.props.onNewDeliveryCreate}
        isActive={this.props.activeActionType === DeliveryActionTypes.NEW}
      />
    )
  }

  private get SitemapComponent() {
    const { store } = this.props
    return props => (
      <DeliverySitemapPreviewHolder
        {...props}
        deliveriesMapStore={store}
        textboxesCache={this.textboxesCache}
      />
    )
  }
}

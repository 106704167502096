import { action, computed, observable } from 'mobx'

import { FormFieldType } from '~/client/src/shared/enums/FormFieldType'
import InfoSectionId from '~/client/src/shared/enums/InfoSectionId'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { Field } from '~/client/src/shared/types/CustomFieldType'
import { ICountry } from '~/client/src/shared/utils/phoneNumberHelpers'

import KnownTranslatorKeys from '../../localization/knownTranslatorKeys'
import { NOOP } from '../../utils/noop'
import FormStore from './Form.store'

export interface IAddEditDialogField extends Field {
  sectionId: InfoSectionId
}

// TODO: Revisit inheritance chain
export default abstract class AddEditItemDialogStore<
  ItemType,
  ItemFormStoreType,
> {
  @observable public formStores: Array<FormStore<ItemFormStoreType>> = []
  @observable public shouldSaveConfirmModalOpen: boolean = false
  @observable public activeFormIndex = 0

  protected idPropName = 'id'
  protected requiredFieldIds: string[] = []
  protected avatarFieldId: string = ''

  @observable private items: ItemType[] = []
  @observable private _itemsToEdit: ItemType[] = []

  public constructor(
    itemsToEdit: ItemType[] = [],
    items: ItemType[] = [],
    private addTitleGetterKey: KnownTranslatorKeys,
    private editTitleGetterKey: KnownTranslatorKeys,
    private resetErrorMessage: () => void = NOOP,
  ) {
    this.itemsToEdit = itemsToEdit
    this.allItems = items
    this.handleChange = this.handleChange.bind(this)
  }

  public get allItems(): ItemType[] {
    return this.items
  }

  public set allItems(items: ItemType[]) {
    this.items = items || []
  }

  public get itemsToEdit(): ItemType[] {
    return this._itemsToEdit
  }

  public set itemsToEdit(items: ItemType[]) {
    this._itemsToEdit = items || []
  }

  public get isAddMode(): boolean {
    return !this.itemsToEdit.some(item => !!item[this.idPropName])
  }

  public get isMultiMode(): boolean {
    return this.itemsToEdit.length > 1
  }

  public get dialogCaption() {
    const getterKey = this.isAddMode
      ? this.addTitleGetterKey
      : this.editTitleGetterKey
    return Localization.getText(getterKey, this.itemsToEdit.length)
  }

  public get editingItem(): ItemType {
    return (
      (this.itemsToEdit.length && this.itemsToEdit[this.activeFormIndex]) ||
      ({} as any)
    )
  }

  public get activeItemId(): string {
    return this.editingItem[this.idPropName]
  }

  public getFieldsBySectionId = (
    sectionId: InfoSectionId,
  ): IAddEditDialogField[] => {
    return this.fieldsBySectionIdMap[sectionId] || []
  }

  @computed
  public get fieldsBySectionIdMap(): {
    [key in InfoSectionId]: IAddEditDialogField[]
  } {
    return this.fields.reduce((acc, field) => {
      if (acc[field.sectionId]) {
        acc[field.sectionId].push(field)
      } else {
        acc[field.sectionId] = [field]
      }

      return acc
    }, {} as any)
  }

  public abstract get fields(): IAddEditDialogField[]

  public get navigationTitle(): string {
    return Localization.translator.xOfY(
      this.activeFormIndex + 1,
      this.itemsToEdit.length,
    )
  }

  public getFormattedFieldLabel({ id, label }: IAddEditDialogField): string {
    const { requiredFields } = this.activeFormStore
    return requiredFields && requiredFields.includes(id)
      ? `* ${label}`
      : `${label} (${Localization.translator.optional.toLowerCase()})`
  }

  public get okActionCaption() {
    return this.isAddMode
      ? Localization.translator.add
      : Localization.translator.update_verb
  }

  @action.bound
  public showSaveConfirmModal() {
    this.shouldSaveConfirmModalOpen = true
  }

  @action.bound
  public hideSaveConfirmModal() {
    this.shouldSaveConfirmModalOpen = false
  }

  protected handleChange(event: any) {
    this.activeFormStore.handleChange(event)
  }

  protected handleValueReset(name: string) {
    this.activeFormStore.handleFieldValueReset(name)
  }

  @action.bound
  public handlePhoneNumberChange(
    phoneNumberPropName: string,
    phoneNumber: string,
    country: ICountry,
  ) {
    this.activeFormStore.handlePhoneNumberChange(
      phoneNumberPropName,
      phoneNumber,
      country,
    )
  }

  @action.bound
  public handleTagChange(propName: string, value: string) {
    this.activeFormStore.handleCustomPropChange(propName, value)
  }

  @action.bound
  public handleNumberChange(propName: string, value: string) {
    this.activeFormStore.handleCustomPropChange(propName, value)
  }

  @action.bound
  public handleTagsChange(propName: string, values: string[]) {
    this.activeFormStore.handleCustomPropChange(propName, values)
  }

  @action.bound
  public handleAvatarChange(avatarUrl: string) {
    const event = {
      currentTarget: {
        name: this.avatarFieldId,
        type: FormFieldType.Avatar,
        value: avatarUrl,
      },
    }

    this.handleChange(event)
  }

  @action.bound
  public handlePreClose() {
    if (!this.hasUnsavedChanges) {
      this.closeDialog()
    } else {
      this.showSaveConfirmModal()
    }
  }

  @action.bound
  public handleClose() {
    this.hideSaveConfirmModal()
    this.closeDialog()
  }

  @action.bound
  public submit() {
    this.hideSaveConfirmModal()

    if (this.hasErrors) {
      return
    }

    this.itemsToEdit.forEach((item, index) => {
      const form = this.formStores[index]

      this.setFieldsToItem(item, form.fields)
    })

    const itemsToUpdate = this.itemsToEdit.slice()

    this.updateItems(itemsToUpdate, this.activeItemId)
    this.activeFormIndex = 0
  }

  @action.bound
  public goToPreviousItem() {
    if (this.activeFormIndex > 0) {
      this.activeFormIndex--
    } else {
      this.activeFormIndex = this.itemsToEdit.length - 1
    }

    this.formStores[this.activeFormIndex].validate()
  }

  public get hasErrors(): boolean {
    return this.formStores.some(f => !!f.error || !!f.invalidFields.size)
  }

  public get hasUnsavedChanges(): boolean {
    return this.formStores.some(f => f.hasChanges)
  }

  public get canFormsBeSubmitted(): boolean {
    return (
      !this.hasErrors &&
      this.hasUnsavedChanges &&
      this.areAllRequiredFieldsFilled
    )
  }

  public get areAllRequiredFieldsFilled(): boolean {
    const { requiredFields } = this.activeFormStore
    return (
      !requiredFields ||
      requiredFields.every(field => this.getFieldValueById(field))
    )
  }

  public getFieldValueById = (fieldId: string): any => {
    return this.activeFormStore.fields[fieldId]
  }

  public get activeFormStore(): FormStore<ItemFormStoreType> {
    return this.formStores?.[this.activeFormIndex] || ({ fields: {} } as any)
  }

  public isFieldValid({ id }: IAddEditDialogField): boolean {
    const { invalidFields } = this.activeFormStore
    return invalidFields ? !invalidFields.get(id) : true
  }

  public recreateFormStores() {
    this.formStores = this.itemsToEdit.map((u, i) => {
      return new FormStore<ItemFormStoreType>(
        'Form ' + i,
        this.createFormItemFields(),
        this.formValidate.bind(this),
        this.resetErrorMessage,
        this.getRequiredFields(u),
      )
    })

    this.activeFormIndex = 0
  }

  @action.bound
  public updateFormStores() {
    this.itemsToEdit.forEach((item, index) => {
      const form = this.formStores[index]

      this.updateFormStoreItem(item)

      form.setRequiredFields(this.getRequiredFields(item))
      form.reset(item)
      if (!this.isAddMode) {
        form.validate()
      }
    })
  }

  public setFieldsToItem(item: ItemType, fields: any) {
    Object.assign(item, fields)
  }

  @action.bound
  public goToNextItem() {
    if (this.activeFormIndex + 1 < this.itemsToEdit.length) {
      this.activeFormIndex++
    } else {
      this.activeFormIndex = 0
    }

    this.formStores[this.activeFormIndex].validate()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected updateFormStoreItem(item: ItemType) {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getRequiredFields(item?: ItemType): string[] {
    return this.requiredFieldIds
  }

  protected abstract createFormItemFields(): ItemFormStoreType

  protected formValidate(form: FormStore<ItemFormStoreType>) {
    form.invalidFields.clear()
    form.error = ''

    Object.keys(form.fields).forEach(fieldId => {
      const fieldValue = form.fields[fieldId]

      const isRequired = form.requiredFields.includes(fieldId)
      const isEmpty = FormStore.isFieldValueEmpty(fieldValue)

      if (isRequired && isEmpty) {
        return this.setError(form, fieldId, true)
      }
      if (!isEmpty && !this.validateField(form, fieldId, fieldValue)) {
        this.setError(form, fieldId)
      }
    })
  }

  protected validateField(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    form: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fieldId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fieldValue: any,
  ): boolean {
    return true
  }

  protected setError(form: any, fieldId: string, isEmpty: boolean = false) {
    const { requiredFieldCannotBeEmpty, pleaseCorrectErrors } =
      Localization.translator

    form.invalidFields.set(fieldId, true)
    form.error = isEmpty ? requiredFieldCannotBeEmpty : pleaseCorrectErrors
  }

  protected closeDialog() {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected updateItems(itemsToUpdate: ItemType[], lastUpdatedItemId?: string) {
    return
  }
}

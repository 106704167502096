import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import PermitType from '~/client/src/shared/models/PermitType'

import NonWorkTimesConfiguratorStore from './NonWorkTimesConfigurator.store'

interface IProps {
  activePermitType: PermitType
  onNonWorkTimesChange(shouldBlockOnNonWorkTimes: boolean): void
}

const blockRequestsForNonWorkTimes = 'Block requests for non-work times'

@observer
export default class NonWorkTimesConfigurator extends React.Component<IProps> {
  private readonly store: NonWorkTimesConfiguratorStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new NonWorkTimesConfiguratorStore(
      props.activePermitType,
      props.onNonWorkTimesChange,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { activePermitType } = this.props

    if (activePermitType !== prevProps.activePermitType) {
      this.store.init(activePermitType)
    }
  }

  public render() {
    const { shouldBlockOnNonWorkTimes, changeNonWorkState } = this.store

    return (
      <div className="row non-work-times-configurator ml5">
        <div className="text large bold full-flex-basis">
          {blockRequestsForNonWorkTimes}
        </div>
        <Switch
          className="primary-blue-switch bp3-align-right no-outline-container mr25"
          checked={shouldBlockOnNonWorkTimes}
          onChange={changeNonWorkState}
        />
      </div>
    )
  }
}

import { Stage } from 'konva/types/Stage'
import mapboxgl, { LngLat } from 'mapbox-gl'
import { action, computed, observable } from 'mobx'
import { MapRef } from 'react-map-gl'
import { SHAPE } from 'react-map-gl-draw'

import {
  IGeoJson2DGeographicCoordinates,
  IGeoJson2DGeographicCoordinatesInput,
  IOrderedSitemap,
  IPosition,
  IProjectAddressInput,
  LocationType,
  SitemapItemShapeType,
} from '~/client/graph'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import SitemapItemFactory from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemFactory'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import SitemapRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapRectangleProperties'
import SitemapItemType from '~/client/src/shared/enums/SitemapItemType'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap, {
  ISitemapGeoPosition,
} from '~/client/src/shared/models/Sitemap'
import InitialState from '~/client/src/shared/stores/InitialState'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import {
  NORTH_HEADING,
  WORLD_BOUNDS,
  toAddressBoundsInput,
} from '~/client/src/shared/utils/Address'

import { IMapBoxFeature } from '../../interfaces/IMapBoxFeature'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import {
  ITEMS_LAYER_ID,
  SCALED_IMAGE_PIXEL_RATE,
  SCALED_IMAGE_QUALITY,
  SCALED_IMAGE_TYPE,
} from '../BaseSitemap/BaseSitemap'
import { IMapBoxItem, createGeoJSONCircle, getDistance } from './MapBoxEditor'

const MAIN_STAGE_INDEX = 0
const IMAGE_LAYER_INDEX = 0

const MAPBOX_COUNTRY = 'country'
const MAPBOX_POSTCODE = 'postcode'
const MAPBOX_PLACE = 'place'
const MAPBOX_REGION = 'region'
const BASE_ZOOM_LEVEL = 10
const PROJECT_MARKER_ZOOM_LEVEL = 12
const DEFAULT_ZOOM_VALUE = 18
const RUBBER_MODE_OPACITY = 50

const TRAFFIC_SOURCE = /mapbox-traffic-v\d/
const SOURCE_LAYER_LITERAL = 'source'
const LAYOUT_LAYER_LITERAL = 'layout'
const VISIBILITY_LAYER_LITERAL = 'visibility'
const MAPBOX_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
const DEFAULT_RADIUS_METERS = 100

export enum BaseMapStyle {
  STREET_STYLE_CODE = 'mapbox://styles/mapbox/streets-v11',
  SATELLITE_STYLE_CODE = 'mapbox://styles/mapbox/satellite-v9',
  TRAFFIC_STYLE_CODE = 'mapbox://mapbox.mapbox-traffic-v1',
}

export async function getMapBoxPlaceFromBareCoordinates(
  lng: number,
  lat: number,
  token: string,
): Promise<IMapBoxFeature> {
  const resp = await fetch(getMapBoxUrl(`${lng},${lat}`, token, false))
  const data: GeoJSON.FeatureCollection = await resp.json()
  return data.features[0] as IMapBoxFeature
}

export const getMapBoxUrl = (
  query: string,
  token: string,
  addTypes: boolean,
): string => {
  return `${MAPBOX_BASE_URL}${query}.json?access_token=${token}${
    addTypes ? '&types=address,place' : ''
  }`
}

export async function getMapBoxPlaceFromCoordinates(
  coords: LngLat,
  token: string,
): Promise<IMapBoxFeature> {
  const resp = await fetch(
    getMapBoxUrl(`${coords.lng},${coords.lat}`, token, false),
  )
  const data: GeoJSON.FeatureCollection = await resp.json()
  return data.features[0] as IMapBoxFeature
}

export async function getAdressFromCoords(
  lat: number,
  lng: number,
): Promise<IMapBoxFeature> {
  const lngtLat = new LngLat(lng, lat)
  return await getMapBoxPlaceFromCoordinates(lngtLat, mapboxgl.accessToken)
}

export function mapboxFeatureToAddress(
  item: IMapBoxFeature,
): IProjectAddressInput {
  if (!item) {
    const worldCenter = new LngLat(0, 0)

    return {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      bounds: toAddressBoundsInput(worldCenter.toBounds(DEFAULT_RADIUS_METERS)),
      center: worldCenter,
      bearing: 0,
      projectId: null,
    }
  }

  const { context } = item

  const countryProp = context.find(i => i.id.startsWith(MAPBOX_COUNTRY))
  const postCodeProp = context.find(i => i.id.startsWith(MAPBOX_POSTCODE))
  const placeProp = context.find(i => i.id.startsWith(MAPBOX_PLACE))
  const regionProp = context.find(i => i.id.startsWith(MAPBOX_REGION))
  const state = regionProp
    ? regionProp.short_code
      ? regionProp.short_code.split('-')[1]
      : regionProp.text
    : ''

  const [lng, lat] = item.center
  const center = new LngLat(lng, lat)

  return {
    address: item.place_name?.split(',')[0] || '',
    city: placeProp?.text || '',
    state,
    zipcode: postCodeProp?.text || '',
    country: countryProp?.text || '',
    bounds: toAddressBoundsInput(center.toBounds(DEFAULT_RADIUS_METERS)),
    center,
    bearing: 0,
    projectId: null,
  }
}

const ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT = [
  SitemapItemType.Line,
  SitemapItemType.TextBox,
]

const STORAGE_TRUE_VALUE = 'true'
const TRAFFIC_STORAGE_KEY = 'traffic-key'
const BASE_MAP_STORAGE_KEY = 'base-map-key'
const IS_SECONDARY_MAP_MODE_STORAGE_KEY = 'is-secondary-map-key'
const SHOW_PLAN_STORAGE_KEY = 'show-plan-key'

export default class MapBoxEditorStore {
  @observable public isLoaded: boolean = false
  @observable public clickCoords: number[] = []
  @observable public moveCoords: number[] = []
  @observable public viewport: ISitemapGeoPosition = {
    latitude: 0,
    longitude: 0,
    zoom: 0,
    bearing: NORTH_HEADING,
    pitch: 0,
    bounds: {
      ne: WORLD_BOUNDS._ne,
      sw: WORLD_BOUNDS._sw,
    },
  }
  @observable public baseMap: BaseMapStyle = BaseMapStyle.STREET_STYLE_CODE
  @observable public canvasMap: MapRef
  @observable public canvas: MapRef
  @observable public isEditableItemInDragMode: boolean = false
  @observable public movableItemId: string = null
  @observable public sitemapStage: Stage
  @observable public height: number = 0
  @observable public width: number = 0
  @observable public offsetX: number = 0
  @observable public offsetY: number = 0
  @observable public zoom: number = null
  @observable public movableItemCoordinates: IGeoJson2DGeographicCoordinates = {
    longitude: 0,
    latitude: 0,
  }

  // tooltips for new project creation
  @observable public isFirstTooltipShown: boolean = false
  @observable public isSecondTooltipShown: boolean = false
  @observable public isThirdTooltipShown: boolean = false
  @observable public isLastTooltipShown: boolean = false

  @observable public opacity: number = 0
  // bounds for maps [main and secondary]
  @observable public sourceBounds: number[][]
  @observable public additionalSourcesBoundsMap: {
    [attrId: string]: number[][]
  }
  // visibility on map during rubber sheeting
  @observable public leftPanelVisibility: {
    [attrId: string]: boolean
  } = {}

  @observable public shouldShowImage: boolean = false
  @observable public isDraggingMode: boolean = false
  @observable public isRubberMode: boolean = false
  @observable public isImageRubberMode: boolean = false
  @observable public items: SitemapItemBase[] = []
  @observable public itemsFiltering: SitemapItemBase[] = []
  @observable public secondaryItems: {
    [sitemapId: string]: SitemapItemBase[]
  } = {}
  @observable public itemsOnMap: SitemapItemBase[] = []
  @observable public isTrafficShown: boolean = false
  @observable public isMoreMenuShown: boolean = false
  @observable public isResetActive: boolean = false
  @observable public isMainMapMode: boolean = true
  @observable public shouldShowProjectMarker: boolean = false
  @observable public shouldShowAdditionalProjectMarkers: boolean = false
  public imageLoadingPromise: Promise<void>
  public canvasContext: CanvasRenderingContext2D
  @observable private displayedItemsIds: string[] = []

  public constructor(
    private readonly selectedSitemap: () => Sitemap,
    private readonly sitemapItemsStore: SitemapItemsStore,
    private readonly sitemapsStore: SitemapsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    private readonly state: InitialState,
    public readonly isEditorMode?: boolean,
    public readonly isSitemapItemSelected?: (item: SitemapItemBase) => boolean,
    private readonly isDeliveryMode?: boolean,
    private readonly isSiteMode?: boolean,
    private readonly isActivityMode?: boolean,
    private readonly isFormsMode?: boolean,
  ) {
    this.setDefaultValues()
  }

  public setDefaultValues = (): void => {
    this.baseMap =
      (localStorage.getItem(BASE_MAP_STORAGE_KEY) as BaseMapStyle) ||
      BaseMapStyle.STREET_STYLE_CODE
    this.isMainMapMode = !localStorage.getItem(
      IS_SECONDARY_MAP_MODE_STORAGE_KEY,
    )
    this.isTrafficShown = !!localStorage.getItem(TRAFFIC_STORAGE_KEY)
    this.opacity = Number(localStorage.getItem(SHOW_PLAN_STORAGE_KEY))
  }

  @action.bound
  public toogleAdditionalMarkers(): void {
    this.shouldShowAdditionalProjectMarkers =
      !this.shouldShowAdditionalProjectMarkers
  }

  public toggleMainMapMode = (): void => {
    this.isMainMapMode = !this.isMainMapMode
    if (this.isMainMapMode) {
      localStorage.removeItem(IS_SECONDARY_MAP_MODE_STORAGE_KEY)
    } else {
      localStorage.setItem(
        IS_SECONDARY_MAP_MODE_STORAGE_KEY,
        STORAGE_TRUE_VALUE,
      )
    }
    this.setItems()
    this.filterByContainer(this.items)
  }

  public hideToggleMapMode = (): void => {
    localStorage.removeItem(IS_SECONDARY_MAP_MODE_STORAGE_KEY)
    if (this.items?.length) {
      this.setItems()
      this.filterByContainer(this.items)
    }
  }

  public get mapViewport(): ISitemapGeoPosition {
    if (!this.selectedSitemap()) {
      return null
    }

    const { bounds, center, altitude, zoom, bearing, pitch } =
      this.selectedSitemap()

    return {
      longitude: center?.lng,
      latitude: center?.lat,
      zoom,
      altitude,
      bearing,
      pitch,
      bounds,
    } as ISitemapGeoPosition
  }

  public toggleVisibility = (itemId: string): void => {
    this.leftPanelVisibility[itemId] = !this.leftPanelVisibility[itemId]
  }

  public toggleMoreMenu = (
    event?: React.SyntheticEvent<HTMLDivElement>,
  ): void => {
    event?.stopPropagation?.()
    this.isMoreMenuShown = !this.isMoreMenuShown
  }

  public hideMoreMenu = (
    event?: React.SyntheticEvent<HTMLDivElement>,
  ): void => {
    event?.stopPropagation?.()
    if (this.isMoreMenuShown) {
      this.isMoreMenuShown = false
    }
  }

  public get mapBoxItems(): IMapBoxItem[] {
    return (
      this.isRubberMode ? this.allSitemapItems : this.displayedSitemapItems
    )
      .filter(
        item =>
          !!item.dataObject &&
          item.sitemapItem?.isReferenced &&
          !!item.sitemapItem.coordinates &&
          this.leftPanelVisibility[item.id],
      )
      .map(item => {
        const baseItem: IMapBoxItem = {
          item,
          coordinates: item.sitemapItem.coordinates,
        }
        return baseItem
      })
  }

  // shapes for native mapbox view [rubber sheeting]
  public get features() {
    return (
      (this.isRubberMode ? this.allSitemapItems : this.displayedSitemapItems)
        ?.filter(
          item =>
            !!item.sitemapItem.shapeCoordinates &&
            this.leftPanelVisibility[item.id],
        )
        .map(item => {
          const {
            coordinates,
            type: sitemapItemType,
            isClosed,
            divisionEndAngle,
            divisionStartAngle,
          } = item.sitemapItem.shapeCoordinates
          const { shapeProperties } = item

          let geometryCoordinates = [
            coordinates.map(coord => {
              return [coord.longitude, coord.latitude]
            }),
          ]
          let type = ''
          let drawType = ''
          switch (sitemapItemType) {
            case SitemapItemShapeType.Circle:
              type = SHAPE.POLYGON
              drawType = SHAPE.POLYGON
              const distance =
                getDistance(
                  coordinates[0].longitude,
                  coordinates[1].longitude,
                  coordinates[0].latitude,
                  coordinates[1].latitude,
                ) || 1

              geometryCoordinates = createGeoJSONCircle(
                coordinates[0],
                distance,
                this.selectedSitemap().bearing,
                divisionStartAngle,
                divisionEndAngle,
              )
              break
            case SitemapItemShapeType.Polyline:
              drawType = isClosed ? SHAPE.POLYGON : SHAPE.LINE_STRING
              type = isClosed ? SHAPE.POLYGON : SHAPE.LINE_STRING
              break
            case SitemapItemShapeType.Rectangle:
              drawType = SHAPE.POLYGON
              type = SHAPE.RECTANGLE
              break
          }
          const isCircle = sitemapItemType === SitemapItemShapeType.Circle
          return {
            type: 'Feature',
            properties: {
              drawType,
              baseBearing: this.selectedSitemap().bearing,
              shape: drawType,
              stroke: shapeProperties?.lineColor || item.color,
              strokeWidth: shapeProperties?.lineWidth || 3,
              fill: shapeProperties?.fillColor || item.color,
              fillOpacity:
                drawType === SHAPE.LINE_STRING
                  ? 0
                  : shapeProperties?.fillOpacity || 0.2,
              strokeDasharray: 0,
              type: isCircle ? SitemapItemShapeType.Polyline : sitemapItemType,
              shapeType: type,
              item,
              isCircle,
              center: {
                longitude: coordinates[0].longitude,
                latitude: coordinates[0].latitude,
              },
              angleStart:
                isCircle &&
                coordinates.length === 4 &&
                coordinates[2].longitude,
              angleEnd:
                isCircle &&
                coordinates.length === 4 &&
                coordinates[3].longitude,
            },
            geometry: {
              type: drawType,
              coordinates: geometryCoordinates,
            },
          }
        }) || []
    )
  }

  public createMapImage = async (
    shouldHideBackground: boolean,
    sitemapImage?: string,
  ): Promise<string> => {
    const { x, y, width, height } = this.sitemapStage
      .getStage()
      .findOne(`#${ITEMS_LAYER_ID}`).children[0].attrs

    const config = {
      mimeType: SCALED_IMAGE_TYPE,
      quality: SCALED_IMAGE_QUALITY,
      pixelRatio: SCALED_IMAGE_PIXEL_RATE,
      width,
      height,
      x,
      y,
    }

    const canvas = this.canvas?.getMap()
    const canvasUrl = canvas?._canvas?.toDataURL(Object.assign({}, config))
    const itemsCanvas = this.sitemapStage
      .getStage()
      .findOne(`#${ITEMS_LAYER_ID}`)
      .getLayer()
      .children[1].clone()
    itemsCanvas.rotate(this.viewport.bearing - this.selectedSitemap().bearing)
    const itemsCanvasUrl = itemsCanvas.toDataURL(Object.assign({}, config))

    return this.createReferencedSitemapDataUrl(
      canvasUrl,
      itemsCanvasUrl,
      0,
      0,
      shouldHideBackground,
      sitemapImage,
    )
  }

  public setItems = (): void => {
    this.displayedItemsIds = []
    this.items =
      this.isRubberMode || !this.isMainMapMode
        ? this.allDisplayedSitemapItems
        : this.displayedEditableSitemapItems

    this.itemsFiltering = this.filterByZoom(this.items)
  }

  public setOpacity = (opacity: number): void => {
    this.opacity = opacity
  }

  public updateOpacityBySetupStep = (): void => {
    this.opacity = this.isDraggingMode
      ? RUBBER_MODE_OPACITY
      : Number(localStorage.getItem(SHOW_PLAN_STORAGE_KEY))
  }

  public toggleOpacity = (): void => {
    this.opacity = this.isMapboxHidden ? 0 : 100
    localStorage.setItem(SHOW_PLAN_STORAGE_KEY, this.opacity.toString())
  }

  public get isMapboxHidden(): boolean {
    return this.opacity === 100
  }

  public setDefaultMapMode = (): void => {
    this.isImageRubberMode = false
    this.setDefaultValues()
  }

  public setViewport = (viewport: ISitemapGeoPosition): void => {
    if (!this.isLoaded) {
      return
    }
    if (!this.sourceBounds) {
      this.sourceBounds = this.getCurrentSitemapGeoCorners()
      this.additionalSourcesBoundsMap = this.getImagesPositionsMap
    }
    if (this.canvasMap?.getMap?.()) {
      viewport.bounds = this.canvasMap.getMap().getBounds()
    }
    const shouldFilter = this.viewport.zoom !== viewport.zoom
    this.viewport = viewport

    if (shouldFilter) {
      this.itemsFiltering = this.filterByZoom(this.items)
    }
    this.filterByContainer(this.items)
  }

  public setSourceBounds = (): void => {
    this.sourceBounds = this.getCurrentSitemapGeoCorners()
    this.additionalSourcesBoundsMap = this.getImagesPositionsMap
  }

  // remove mapbox info on unmount
  public resetMapboxInfo = (): void => {
    this.viewport = {
      latitude: 0,
      longitude: 0,
      zoom: 0,
      bearing: NORTH_HEADING,
      pitch: 0,
      bounds: {
        ne: WORLD_BOUNDS._ne,
        sw: WORLD_BOUNDS._sw,
      },
    }

    this.sourceBounds = null
    this.additionalSourcesBoundsMap = null
    this.isDraggingMode = false
    this.isImageRubberMode = false
    this.setDefaultValues()
    this.shouldShowImage = false
    this.items = []
    this.secondaryItems = {}
  }

  // coordinates of the corners for the current sitemap
  @computed
  public get getCorners(): IGeoJson2DGeographicCoordinatesInput[] {
    return this.sourceBounds.map(bound => {
      return {
        longitude: bound[0],
        latitude: bound[1],
      } as IGeoJson2DGeographicCoordinatesInput
    })
  }

  // update map on sitemap change
  public updateMapboxInfo = (): void => {
    if (
      !this.shouldShowImage &&
      this.selectedSitemap()?.isReferenced &&
      (this.isEditorMode ? this.canvasMap : this.canvas)?.getMap() &&
      !!this.sitemapStage?.getStage().children?.[MAIN_STAGE_INDEX]?.children?.[
        IMAGE_LAYER_INDEX
      ]
    ) {
      this.viewport.latitude = this.selectedSitemap().center.lat
      this.viewport.longitude = this.selectedSitemap().center.lng
      this.viewport.zoom = this.zoom = (
        this.isEditorMode ? this.canvasMap : this.canvas
      ).getMap().transform.zoom

      this.isDraggingMode = true
      this.isImageRubberMode = true
      this.shouldShowImage = true

      if (!this.items.length) {
        this.itemsOnMap = []
        this.setItems()
      }
    }
  }

  public setLeftVisibility = (): void => {
    this.leftPanelVisibility = {}
    this.displayedEditableSitemapItems.forEach(item => {
      this.leftPanelVisibility[item.id] = true
    })
  }

  public setViewportFromAdress = (): void => {
    const { projectAddress } = this.state

    this.viewport.bounds =
      this.selectedSitemap?.()?.bounds || projectAddress.bounds
    this.viewport.zoom =
      this.selectedSitemap?.()?.zoom ||
      projectAddress.zoom ||
      DEFAULT_ZOOM_VALUE
    this.viewport.altitude =
      this.selectedSitemap?.()?.altitude || projectAddress.altitude
    this.viewport.pitch =
      this.selectedSitemap?.()?.pitch || projectAddress.pitch
    this.viewport.bearing =
      this.selectedSitemap?.()?.bearing || projectAddress.bearing
    this.viewport.latitude =
      this.selectedSitemap?.()?.center?.lat || projectAddress.center?.lat
    this.viewport.longitude =
      this.selectedSitemap?.()?.center?.lng || projectAddress.center?.lng
  }

  public setViewportProp = (propValue: number, propName: string): void => {
    const newViewport = {
      ...this.viewport,
    }
    newViewport[propName] = propValue
    this.setViewport(newViewport)
  }

  public setRefForProjectMap = (ref: MapRef): void => {
    if (ref) {
      this.canvasMap = ref
      this.canvasMap.getMap().on('load', () => {
        this.setItems()
        this.filterByContainer(this.items)
        this.sourceBounds = this.getCurrentSitemapGeoCorners()
        this.additionalSourcesBoundsMap = this.getImagesPositionsMap
        if (this.isTrafficShown && !this.hasTraffic) {
          this.createTraffic()
        }
        this.isLoaded = true
      })
    }
  }

  public setCanvasRef = (ref: MapRef): void => {
    if (ref) {
      this.canvas = ref
    }
  }

  public toggleTraffic = (): void => {
    if (!this.hasTraffic) {
      this.isTrafficShown = true
      localStorage.setItem(TRAFFIC_STORAGE_KEY, STORAGE_TRUE_VALUE)
      this.createTraffic()
    } else {
      this.setTrafficVisibility(this.isTrafficShown ? 'none' : 'visible')
      this.isTrafficShown = !this.isTrafficShown
      if (this.isTrafficShown) {
        localStorage.setItem(TRAFFIC_STORAGE_KEY, STORAGE_TRUE_VALUE)
      } else {
        localStorage.removeItem(TRAFFIC_STORAGE_KEY)
      }
    }
  }

  public removeRefs = (): void => {
    this.canvasMap = null
    this.sitemapStage = null
    this.canvas = null
  }

  public setActiveItem = (id: string): void => {
    this.movableItemId = id
  }

  public setActiveItemCoordinates = (
    coordinates: IGeoJson2DGeographicCoordinates,
  ): void => {
    this.movableItemCoordinates = coordinates
  }

  public toggleMapStyle = (
    event: React.SyntheticEvent<HTMLDivElement>,
  ): void => {
    event.stopPropagation()
    this.baseMap =
      this.baseMap === BaseMapStyle.STREET_STYLE_CODE
        ? BaseMapStyle.SATELLITE_STYLE_CODE
        : BaseMapStyle.STREET_STYLE_CODE

    localStorage.setItem(BASE_MAP_STORAGE_KEY, this.baseMap)
    this.isTrafficShown = false
    localStorage.removeItem(TRAFFIC_STORAGE_KEY)
  }

  public getDataURL = (): string => {
    if (!this.canvasMap) {
      return null
    }

    const stage = this.canvasMap.getMap().getCanvas()
    const dataUrlConfig = {
      mimeType: 'image/jpeg',
      quality: SCALED_IMAGE_QUALITY,
      pixelRatio: SCALED_IMAGE_PIXEL_RATE,
      width: stage.width,
      height: stage.height,
      x: stage.offsetLeft,
      y: stage.offsetTop,
    }
    return stage.toDataURL(Object.assign({}, dataUrlConfig))
  }

  // corners for additional sitemaps
  public getAdditionalSitemapsCorners = (
    shouldUseImageMap?: boolean,
  ): { [attrId: string]: number[][] } => {
    const currentMap =
      this.isEditorMode || shouldUseImageMap
        ? this.canvasMap?.getMap?.()
        : this.canvas?.getMap?.()

    if (!currentMap) {
      return
    }

    return this.sitemapsOnGlobe
      .filter(item => item.geoCorners?.length)
      .reduce((map, item) => {
        const newPoint1 = currentMap.project([
          item.geoCorners[0].longitude,
          item.geoCorners[0].latitude,
        ])

        const newPoint2 = currentMap.project([
          item.geoCorners[1].longitude,
          item.geoCorners[1].latitude,
        ])

        const newPoint3 = currentMap.project([
          item.geoCorners[2].longitude,
          item.geoCorners[2].latitude,
        ])

        const newPoint4 = currentMap.project([
          item.geoCorners[3].longitude,
          item.geoCorners[3].latitude,
        ])

        map[item.id] = [
          [newPoint4.x, newPoint4.y],
          [newPoint3.x, newPoint3.y],
          [newPoint2.x, newPoint2.y],
          [newPoint1.x, newPoint1.y],
        ]
        return map
      }, {})
  }

  // rotation for every map for live update
  @computed
  public get sitemapsRotations(): { [attrId: string]: number } {
    return this.sitemapsOnGlobe
      .filter(item => item.geoCorners?.length)
      .reduce((map, item) => {
        map[item.id] = item.bearing
        return map
      }, {})
  }

  // sitemaps to show as additional on the 'GLOBE'
  public get sitemapsOnGlobe(): Sitemap[] {
    return this.sitemaps.filter(item => item.geoCorners?.length)
  }

  // coordinates of the corners of current (selected) sitemap
  public getCurrentSitemapGeoCorners = (): number[][] => {
    const map = this.isEditorMode
      ? this.canvasMap?.getMap?.()
      : this.canvas?.getMap?.()
    if (!map) {
      return
    }
    const width: number = this.width || map.transform.width
    const height: number = this.height || map.transform.height
    const offsetX: number = this.offsetX || 0
    const offsetY: number = this.offsetY || 0

    const newPoint1 = map.unproject([offsetX, height + offsetY])
    const newPoint2 = map.unproject([offsetX + width, height + offsetY])
    const newPoint3 = map.unproject([offsetX + width, offsetY])
    const newPoint4 = map.unproject([offsetX, offsetY])

    return [
      [newPoint4.lng, newPoint4.lat],
      [newPoint3.lng, newPoint3.lat],
      [newPoint2.lng, newPoint2.lat],
      [newPoint1.lng, newPoint1.lat],
    ]
  }

  public getItemCoordinates = (
    x: number,
    y: number,
  ): IGeoJson2DGeographicCoordinates => {
    if (!this.canvas?.getMap?.()) {
      return null
    }
    const xPosition = (this.canvas?.getMap?.().transform.width / 100) * x
    const yPosition = (this.canvas?.getMap?.().transform.height / 100) * y
    const newPoint = this.canvas?.getMap().unproject([xPosition, yPosition])

    return {
      longitude: newPoint.lng,
      latitude: newPoint.lat,
    }
  }

  // project item's coordinates to follow the position on the map
  public getUpdatedItemCoords = (
    item: SitemapItemBase,
    shouldUseImageMap?: boolean,
    width?: number,
    height?: number,
  ): SitemapItemBase => {
    const currentMap =
      this.isEditorMode && shouldUseImageMap
        ? this.canvasMap?.getMap?.()
        : this.canvas?.getMap?.()

    if (!currentMap) {
      return item
    }

    const newItem = item.copy()
    if (item.sitemapItem.coordinates) {
      const newPoint = currentMap.project([
        item.sitemapItem.coordinates.longitude,
        item.sitemapItem.coordinates.latitude,
      ])

      const xPosition =
        (100 * newPoint.x) / (width || currentMap.transform.width)
      const yPosition =
        (100 * newPoint.y) / (height || currentMap.transform.height)
      newItem.setPosition(xPosition, yPosition)
    }

    if (newItem.sitemapItem.shapeCoordinates) {
      switch (newItem.sitemapItem.shapeCoordinates.type) {
        case SitemapItemShapeType.Polyline:
          if (!newItem.shapeProperties) {
            newItem.shapeProperties = new SitemapPolyLineProperties().copy()
          }
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;(newItem.shapeProperties as SitemapPolyLineProperties).isClosed =
            newItem.sitemapItem.shapeCoordinates.isClosed
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;(newItem.shapeProperties as SitemapPolyLineProperties).points =
            newItem.sitemapItem.shapeCoordinates.coordinates.map(shapePoint => {
              const newShapePoint = currentMap.project([
                shapePoint.longitude,
                shapePoint.latitude,
              ])
              const x =
                (100 * newShapePoint.x) / (width || currentMap.transform.width)
              const y =
                (100 * newShapePoint.y) /
                (height || currentMap.transform.height)

              return {
                x,
                y,
              }
            })
          break
        case SitemapItemShapeType.Rectangle:
          newItem.shapeProperties = this.getRectangle(
            newItem,
            width,
            height,
            currentMap,
          )
          break
        case SitemapItemShapeType.Circle:
          newItem.shapeProperties = this.getCircle(
            newItem,
            width,
            height,
            currentMap,
          )
          break
      }
    }

    return newItem
  }

  private filterByZoom = (items: SitemapItemBase[]): SitemapItemBase[] => {
    const { zoom } = this.viewport

    this.shouldShowProjectMarker = zoom <= BASE_ZOOM_LEVEL
    return items.filter(item => {
      if (
        !!item.dataObject &&
        (item.dataObject.type === LocationType.Building ||
          item.dataObject.type === LocationType.Route)
      ) {
        return zoom > BASE_ZOOM_LEVEL
      } else {
        return zoom > PROJECT_MARKER_ZOOM_LEVEL
      }
    })
  }

  // create circle coordinates for the map
  private getCircle = (
    item: SitemapItemBase,
    width: number,
    height: number,
    map,
  ): SitemapCircleProperties => {
    const shapeProperties =
      (item.shapeProperties as SitemapCircleProperties)?.copy() ||
      new SitemapCircleProperties()

    const points = item.sitemapItem.shapeCoordinates.coordinates.map(
      shapePoint => {
        const newShapePoint = map.project([
          shapePoint.longitude,
          shapePoint.latitude,
        ])
        const x = (100 * newShapePoint.x) / (width || map?.transform.width)
        const y = (100 * newShapePoint.y) / (height || map?.transform.height)

        return {
          x,
          y,
        }
      },
    )

    const newRadius = this.pointsDistance(points[0], points[1])
    shapeProperties.radius = newRadius
    shapeProperties.position = points[0]

    return shapeProperties
  }

  private pointsDistance(p1: IPosition, p2: IPosition): number {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
  }

  // create rect coordinates for the map
  private getRectangle = (
    item: SitemapItemBase,
    width: number,
    height: number,
    map,
  ): SitemapRectangleProperties => {
    const shapeProperties =
      (item.shapeProperties as SitemapRectangleProperties)?.copy() ||
      new SitemapRectangleProperties()

    shapeProperties.position = null
    let mapPoint = null

    item.sitemapItem.shapeCoordinates.coordinates.forEach(shapePoint => {
      const newShapePoint = map.project([
        shapePoint.longitude,
        shapePoint.latitude,
      ])
      const x = (100 * newShapePoint.x) / (width || map?.transform.width)
      const y = (100 * newShapePoint.y) / (height || map?.transform.height)

      if (!shapeProperties.position) {
        shapeProperties.position = {
          x,
          y,
        }
      }
      if (!mapPoint) {
        mapPoint = {
          x,
          y,
        }
      }
      if (x < shapeProperties.position.x) {
        shapeProperties.position.x = x
      }
      if (y < shapeProperties.position.y) {
        shapeProperties.position.y = y
      }
      if (x > mapPoint.x) {
        mapPoint.x = x
      }
      if (y > mapPoint.y) {
        mapPoint.y = y
      }

      return {
        x,
        y,
      }
    })

    shapeProperties.width = Math.abs(shapeProperties.position.x - mapPoint.x)
    shapeProperties.height = Math.abs(shapeProperties.position.y - mapPoint.y)

    return shapeProperties
  }

  private sitemapItemsBase = (sitemap: Sitemap): SitemapItemBase[] => {
    let localItems: SitemapItemBase[] = []
    this.hierarchyAttributes.forEach(dataObject => {
      const assignedItems = this.sitemapItemsStore.list.filter(s =>
        s.isAssignedTo(dataObject),
      )

      if (!assignedItems?.length) {
        return
      }

      const convertedItems: SitemapItemBase[] = assignedItems.map(item => {
        return SitemapItemFactory.fromItem(
          dataObject.copy(),
          item.copy(),
          sitemap,
        )
      })

      localItems.push(
        convertedItems.reduce((prevVal, current) =>
          current?.isDisplayed ? current : prevVal,
        ),
      )
    })

    localItems = localItems.filter(item => item)

    this.sitemapItemsStore.list
      .filter(
        item =>
          !item.isAssigned &&
          ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT.includes(item.type) &&
          this.allSitemaps.some(s => s.hasDisplayData(item.id)),
      )
      .forEach(item => {
        localItems.push(SitemapItemFactory.fromItem(null, item.copy(), sitemap))
      })

    return localItems
  }

  private getSitemapsOrderById(id: string): number {
    const { sitemaps: deliverySitemaps } = this.state.delivery.configurations
    const { sitemaps: formsSitemaps } = this.state.forms.configurations
    const { sitemaps: siteSitemaps } = this.state.logistics.configurations
    const { sitemaps: activitySitemaps } =
      this.state.activitiesSettings.configurations

    let sitemaps
    switch (true) {
      case this.isDeliveryMode:
        sitemaps = deliverySitemaps.slice()
        break
      case this.isFormsMode:
        sitemaps = formsSitemaps.slice()
        break
      case this.isSiteMode:
        sitemaps = siteSitemaps.slice()
        break
      case this.isActivityMode:
        sitemaps = activitySitemaps.slice()
        break
    }
    return sitemaps.find(s => s.sitemapId === id).order || 0
  }

  private setTrafficVisibility = (visibility: string): void => {
    const style = this.canvasMap.getMap().getStyle()

    style.layers.forEach(layer => {
      if (TRAFFIC_SOURCE.test(layer[SOURCE_LAYER_LITERAL])) {
        layer[LAYOUT_LAYER_LITERAL] = layer[LAYOUT_LAYER_LITERAL] || {}
        layer[LAYOUT_LAYER_LITERAL][VISIBILITY_LAYER_LITERAL] = visibility
      }
    })
    this.canvasMap.getMap().setStyle(style)
  }

  private createTraffic = (): void => {
    this.canvasMap.getMap().addSource(BaseMapStyle.TRAFFIC_STYLE_CODE, {
      type: 'vector',
      url: BaseMapStyle.TRAFFIC_STYLE_CODE,
    })

    const roadLayers = this.canvasMap
      .getMap()
      .getStyle()
      .layers.filter(layer => layer['source-layer'] === 'road')
    const topRoadLayer = roadLayers[roadLayers.length - 1].id
    const style = this.canvasMap.getMap().getStyle()
    const trafficStyle = addLayers(style, trafficLayers, topRoadLayer)
    this.canvasMap.getMap().setStyle(trafficStyle)
  }

  // filter items by shown map part on the screen
  private filterByContainer(
    items: SitemapItemBase[],
    shouldAdd?: boolean,
  ): void {
    if (this.canvasMap?.getMap?.()) {
      const itemsToAdd = items.filter(
        item =>
          !item?.sitemapItem?.coordinates ||
          this.canvasMap
            .getMap()
            .getBounds()
            .contains([
              item.sitemapItem?.coordinates?.longitude,
              item.sitemapItem?.coordinates?.latitude,
            ]),
      )
      if (shouldAdd) {
        this.itemsOnMap.push(...itemsToAdd)
      }
      this.itemsOnMap = itemsToAdd
    }
  }

  @computed
  private get hierarchyAttributes(): LocationBase[] {
    return this.locationAttributesStore.allAttributes
  }

  private get allSitemaps(): Sitemap[] {
    // Sorting:
    // selected sitemap first
    // then sitemaps with same basemaps
    // then rest
    return this.sitemapsStore.list.slice().sort((a, b) => {
      let aVal = 0
      let bVal = 0

      if (a === this.selectedSitemap()) {
        aVal = 2
      } else if (
        this.selectedSitemap() &&
        this.selectedSitemap().basemapId === a.basemapId
      ) {
        aVal = 1
      }

      if (b === this.selectedSitemap()) {
        bVal = 2
      } else if (
        this.selectedSitemap() &&
        this.selectedSitemap().basemapId === b.basemapId
      ) {
        bVal = 1
      }

      return bVal - aVal
    })
  }

  private get displayedSitemapItems(): SitemapItemBase[] {
    return this.allSitemapItems.filter(
      i =>
        i.isDisplayed &&
        (!this.isSitemapItemSelected || !this.isSitemapItemSelected(i)),
    )
  }

  @computed
  private get allSitemapItems(): SitemapItemBase[] {
    if (!this.selectedSitemap()) {
      return []
    }

    return this.sitemapItemsBase(this.selectedSitemap())
  }

  private get sitemaps(): Sitemap[] {
    const { list } = this.sitemapsStore
    const { sitemaps: deliverySitemaps } = this.state.delivery.configurations
    const { sitemaps: formsSitemaps } = this.state.forms.configurations
    const { sitemaps: siteSitemaps } = this.state.logistics.configurations
    const { sitemaps: activitySitemaps } =
      this.state.activitiesSettings.configurations

    let sitemaps: IOrderedSitemap[] = []
    switch (true) {
      case this.isDeliveryMode:
        sitemaps = deliverySitemaps.slice()
        break
      case this.isSiteMode:
        sitemaps = siteSitemaps.slice()
        break
      case this.isFormsMode:
        sitemaps = formsSitemaps.slice()
        break
      case this.isActivityMode:
        sitemaps = activitySitemaps.slice()
        break
      default:
        return list
    }

    return list
      .filter(s =>
        sitemaps.some(orderedSitemap => orderedSitemap.sitemapId === s.id),
      )
      .sort(
        (a, b) =>
          this.getSitemapsOrderById(a.id) - this.getSitemapsOrderById(b.id),
      )
  }

  public createReferencedSitemapDataUrl = async (
    backgroundImage: string,
    itemsImage: string,
    dx: number,
    dy: number,
    shouldHideBackground?: boolean,
    sitemapBackground?: string,
  ): Promise<string> => {
    let sitemapBack
    let background
    if (sitemapBackground) {
      sitemapBack = await this.loadImage(sitemapBackground)
    }
    if (backgroundImage) {
      background = await this.loadImage(backgroundImage)
    }
    const items = await this.loadImage(itemsImage)

    const { width: backgroundW, height: backgroundH } =
      background || sitemapBack
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    ctx.canvas.width = backgroundW
    ctx.canvas.height = backgroundH

    if (!shouldHideBackground) {
      ctx.drawImage(
        sitemapBack || background,
        -dx,
        -dy,
        backgroundW,
        backgroundH,
      )
    }
    ctx.drawImage(items, 0, 0, backgroundW, backgroundH)

    ctx.fill()
    return canvas.toDataURL()
  }

  private loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise(resolve => {
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = src
      image.onload = () => {
        resolve(image)
      }
    })
  }

  private get getImagesPositionsMap(): { [attrId: string]: number[][] } {
    const currentMap = this.isEditorMode
      ? this.canvasMap?.getMap?.()
      : this.canvas?.getMap?.()
    if (!currentMap) {
      return
    }

    return this.sitemapsOnGlobe
      .filter(item => item.geoCorners?.length)
      .reduce((map, item) => {
        map[item.id] = [
          [item.geoCorners[0].longitude, item.geoCorners[0].latitude],
          [item.geoCorners[1].longitude, item.geoCorners[1].latitude],
          [item.geoCorners[2].longitude, item.geoCorners[2].latitude],
          [item.geoCorners[3].longitude, item.geoCorners[3].latitude],
        ]
        return map
      }, {})
  }

  @computed
  private get allDisplayedSitemapItems(): SitemapItemBase[] {
    if (!this.selectedSitemap()) {
      return []
    }

    const items: { [sitemapId: string]: SitemapItemBase[] } = {}
    const itemsToDisplay: SitemapItemBase[] = []

    const sitemaps = this.sitemapsOnGlobe.slice()
    sitemaps.push(this.selectedSitemap())
    sitemaps
      .filter(item => item.geoCorners?.length)
      .forEach(sitemap => {
        items[sitemap.id] = this.sitemapItemsBase(sitemap)
          .filter(
            item =>
              !this.displayedItemsIds.includes(item.id) &&
              item?.isDisplayed &&
              item?.sitemapItem.isReferenced &&
              (!this.isSitemapItemSelected ||
                !this.isSitemapItemSelected(item)),
          )
          .map(item => this.getUpdatedItemCoords(item, false))

        this.displayedItemsIds.push(...items[sitemap.id].map(item => item.id))
        itemsToDisplay.push(...items[sitemap.id])
      })

    return itemsToDisplay
  }

  @computed
  private get displayedEditableSitemapItems(): SitemapItemBase[] {
    if (
      !this.selectedSitemap() ||
      !this.selectedSitemap().isReferenced ||
      !this.selectedSitemap().bounds
    ) {
      return this.displayedSitemapItems
    }

    return this.displayedSitemapItems.map(item => {
      if (
        (!item.coordinates &&
          !item.sitemapItem?.coordinates &&
          !item.sitemapItem?.shapeCoordinates) ||
        !item.sitemapItem.isReferenced
      ) {
        return item
      }

      return this.getUpdatedItemCoords(item, false)
    })
  }

  private get hasTraffic(): boolean {
    const style = this.canvasMap.getMap().getStyle()

    return !!style.sources[BaseMapStyle.TRAFFIC_STYLE_CODE]
  }
}

export function addLayers(style, layers, before) {
  for (let i = 0; i < style.layers.length; i++) {
    const layer = style.layers[i]
    if (before === layer.id) {
      const newLayers = style.layers
        .slice(0, i)
        .concat(layers)
        .concat(style.layers.slice(i))
      return Object.assign({}, style, {
        layers: newLayers,
      })
    }
  }
  return style
}

// traffic layers to display traffic on the mapbox
export const trafficLayers = [
  {
    id: 'traffic-street-link-bg',
    type: 'line',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    source: BaseMapStyle.TRAFFIC_STYLE_CODE,
    'source-layer': 'traffic',
    minzoom: 15,
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        ['has', 'congestion'],
        ['in', 'class', 'link', 'motorway_link', 'service', 'street'],
      ],
    ],
    layout: {
      visibility: 'visible',
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [14, 2.5],
          [20, 15.5],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(145, 95%, 30%)'],
          ['moderate', 'hsl(30, 100%, 42%)'],
          ['heavy', 'hsl(355, 100%, 37%)'],
          ['severe', 'hsl(355, 70%, 22%)'],
        ],
      },
      'line-offset': {
        base: 1.5,
        stops: [
          [14, 2],
          [20, 18],
        ],
      },
      'line-opacity': {
        base: 1,
        stops: [
          [15, 0],
          [16, 1],
        ],
      },
    },
  },
  {
    id: 'traffic-secondary-tertiary-bg',
    type: 'line',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    source: BaseMapStyle.TRAFFIC_STYLE_CODE,
    'source-layer': 'traffic',
    minzoom: 6,
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['all', ['has', 'congestion'], ['in', 'class', 'secondary', 'tertiary']],
    ],
    layout: {
      visibility: 'visible',
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [9, 1.5],
          [18, 11],
          [20, 16.5],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(145, 95%, 30%)'],
          ['moderate', 'hsl(30, 100%, 42%)'],
          ['heavy', 'hsl(355, 100%, 37%)'],
          ['severe', 'hsl(355, 70%, 22%)'],
        ],
      },
      'line-offset': {
        base: 1.5,
        stops: [
          [10, 0.5],
          [15, 5],
          [18, 11],
          [20, 14.5],
        ],
      },
      'line-opacity': {
        base: 1,
        stops: [
          [13, 0],
          [14, 1],
        ],
      },
    },
  },
  {
    id: 'traffic-primary-bg',
    type: 'line',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    source: BaseMapStyle.TRAFFIC_STYLE_CODE,
    'source-layer': 'traffic',
    minzoom: 6,
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['all', ['==', 'class', 'primary'], ['has', 'congestion']],
    ],
    layout: {
      visibility: 'visible',
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [10, 0.75],
          [15, 6],
          [20, 18],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(145, 95%, 30%)'],
          ['moderate', 'hsl(30, 100%, 42%)'],
          ['heavy', 'hsl(355, 100%, 37%)'],
          ['severe', 'hsl(355, 70%, 22%)'],
        ],
      },
      'line-offset': {
        base: 1.2,
        stops: [
          [10, 0],
          [12, 1.5],
          [18, 13],
          [20, 16],
        ],
      },
      'line-opacity': {
        base: 1,
        stops: [
          [11, 0],
          [12, 1],
        ],
      },
    },
  },
  {
    id: 'traffic-trunk-bg',
    type: 'line',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    source: BaseMapStyle.TRAFFIC_STYLE_CODE,
    'source-layer': 'traffic',
    minzoom: 6,
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['all', ['==', 'class', 'trunk'], ['has', 'congestion']],
    ],
    layout: {
      visibility: 'visible',
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [8, 0.5],
          [9, 2.25],
          [18, 13],
          [20, 17.5],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(145, 95%, 30%)'],
          ['moderate', 'hsl(30, 100%, 42%)'],
          ['heavy', 'hsl(355, 100%, 37%)'],
          ['severe', 'hsl(355, 70%, 22%)'],
        ],
      },
      'line-offset': {
        base: 1.5,
        stops: [
          [7, 0],
          [9, 1],
          [18, 13],
          [20, 18],
        ],
      },
      'line-opacity': 1,
    },
  },
  {
    id: 'traffic-motorway-bg',
    type: 'line',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    source: BaseMapStyle.TRAFFIC_STYLE_CODE,
    'source-layer': 'traffic',
    minzoom: 6,
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['all', ['==', 'class', 'motorway'], ['has', 'congestion']],
    ],
    layout: {
      visibility: 'visible',
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [6, 0.5],
          [9, 3],
          [18, 16],
          [20, 20],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(145, 95%, 30%)'],
          ['moderate', 'hsl(30, 100%, 42%)'],
          ['heavy', 'hsl(355, 100%, 37%)'],
          ['severe', 'hsl(355, 70%, 22%)'],
        ],
      },
      'line-opacity': 1,
      'line-offset': {
        base: 1.5,
        stops: [
          [7, 0],
          [9, 1.2],
          [11, 1.2],
          [18, 10],
          [20, 15.5],
        ],
      },
    },
  },
  {
    id: 'traffic-primary',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    ref: 'traffic-primary-bg',
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [10, 1],
          [15, 4],
          [20, 16],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(142, 55%, 50%)'],
          ['moderate', 'hsl(30, 100%, 55%)'],
          ['heavy', 'hsl(355, 100%, 50%)'],
          ['severe', 'hsl(355, 70%, 35%)'],
        ],
      },
      'line-offset': {
        base: 1.2,
        stops: [
          [10, 0],
          [12, 1.5],
          [18, 13],
          [20, 16],
        ],
      },
      'line-opacity': 1,
    },
  },
  {
    id: 'traffic-secondary-tertiary',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    ref: 'traffic-secondary-tertiary-bg',
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [9, 0.5],
          [18, 9],
          [20, 14],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(142, 55%, 50%)'],
          ['moderate', 'hsl(30, 100%, 55%)'],
          ['heavy', 'hsl(355, 100%, 50%)'],
          ['severe', 'hsl(355, 70%, 35%)'],
        ],
      },
      'line-offset': {
        base: 1.5,
        stops: [
          [10, 0.5],
          [15, 5],
          [18, 11],
          [20, 14.5],
        ],
      },
      'line-opacity': 1,
    },
  },
  {
    id: 'traffic-street-link',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    ref: 'traffic-street-link-bg',
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [14, 1.5],
          [20, 13.5],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(142, 55%, 50%)'],
          ['moderate', 'hsl(30, 100%, 55%)'],
          ['heavy', 'hsl(355, 100%, 50%)'],
          ['severe', 'hsl(355, 70%, 35%)'],
        ],
      },
      'line-offset': {
        base: 1.5,
        stops: [
          [14, 2],
          [20, 18],
        ],
      },
      'line-opacity': 1,
    },
  },
  {
    id: 'traffic-trunk',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    ref: 'traffic-trunk-bg',
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [8, 0.75],
          [18, 11],
          [20, 15],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(142, 55%, 50%)'],
          ['moderate', 'hsl(30, 100%, 55%)'],
          ['heavy', 'hsl(355, 100%, 50%)'],
          ['severe', 'hsl(355, 70%, 35%)'],
        ],
      },
      'line-offset': {
        base: 1.5,
        stops: [
          [7, 0],
          [9, 1],
          [18, 13],
          [20, 18],
        ],
      },
      'line-opacity': 1,
    },
  },
  {
    id: 'traffic-motorway',
    metadata: {
      'mapbox:group': '4053de47c16e55481b10fd748eaa994c',
    },
    ref: 'traffic-motorway-bg',
    paint: {
      'line-width': {
        base: 1.5,
        stops: [
          [6, 0.5],
          [9, 1.5],
          [18, 14],
          [20, 18],
        ],
      },
      'line-color': {
        base: 1,
        type: 'categorical',
        property: 'congestion',
        stops: [
          ['low', 'hsl(142, 55%, 50%)'],
          ['moderate', 'hsl(30, 100%, 55%)'],
          ['heavy', 'hsl(355, 100%, 50%)'],
          ['severe', 'hsl(355, 70%, 35%)'],
        ],
      },
      'line-opacity': 1,
      'line-offset': {
        base: 1.5,
        stops: [
          [7, 0],
          [9, 1.2],
          [11, 1.2],
          [18, 10],
          [20, 15.5],
        ],
      },
    },
  },
]

import { action, computed, observable } from 'mobx'

import { IDeliveryCardField } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Delivery from '~/client/src/shared/models/Delivery'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import InitialState from '~/client/src/shared/stores/InitialState'
import AreasStore from '~/client/src/shared/stores/domain/Areas.store'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import LocationStoreBase from '~/client/src/shared/stores/domain/LocationBase.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import RoutesStore from '~/client/src/shared/stores/domain/Routes.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { truncateText } from '~/client/src/shared/utils/textUtils'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

export interface ICardAttribute {
  fieldId: string
  fieldValue: string
  isBold: boolean
  color?: string
}

export const MAX_NAME_LENGTH = 18

const TITLE_IDS: string[] = [
  FieldIds.COMPANY,
  FieldIds.BOOKING_TIME,
  FieldIds.STATUS,
]

export default class DeliveryCardFieldsConfigStore {
  @observable private delivery: Delivery

  public constructor(
    delivery: Delivery,
    private readonly state: InitialState,
    private readonly projectDateStore: ProjectDateStore,
    private readonly areasStore: AreasStore,
    private readonly gatesStore: GatesStore,
    private readonly zonesStore: ZonesStore,
    private readonly deliveryVehicleTypesStore: DeliveryVehicleTypesStore,
    private readonly offloadingEquipmentsStore: OffloadingEquipmentsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly buildingsStore: BuildingsStore,
    private readonly routesStore: RoutesStore,
    private readonly levelsStore: LevelsStore,
    private readonly materialsStore: MaterialsStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
  ) {
    this.init(delivery)
  }

  @action.bound
  public init(delivery: Delivery) {
    this.delivery = delivery
  }

  public getCardAttributeValues = (
    shouldUseMockValues: boolean,
  ): ICardAttribute[] => {
    return this.attributesCardFields
      .map(field => {
        const fieldName = this.getFieldValueById(
          field.fieldId,
          shouldUseMockValues,
        )
        const formattedValue = truncateText(fieldName, MAX_NAME_LENGTH)
        const isContactField = field.fieldId === FieldIds.ON_SITE_CONTACTS

        return (
          formattedValue && {
            fieldId: field.fieldId,
            fieldValue: formattedValue,
            isBold: isContactField,
            color: this.fieldColorsMap[field.fieldId],
          }
        )
      })
      .filter(v => v)
  }

  public getFieldValueById = (
    fieldId: string,
    shouldUseMockValues: boolean,
  ) => {
    if (!shouldUseMockValues || fieldId === FieldIds.BOOKING_TIME) {
      return this.fieldValuesMap[fieldId] || EMPTY_STRING
    }

    return this.state.getDeliveryFieldName(fieldId)
  }

  private isFieldHidden = (field: IDeliveryCardField): boolean => {
    if (field.fieldId === FieldIds.MATERIAL_CATEGORY) {
      return (
        this.isMaterialSectionHidden ||
        this.areMaterialsCategoryDisabled ||
        field.isHidden
      )
    }

    if (field.fieldId === FieldIds.MATERIAL_NOTE) {
      return (
        this.isMaterialSectionHidden ||
        this.hiddenFields[field.fieldId] ||
        field.isHidden
      )
    }

    return this.hiddenFields[field.fieldId] || field.isHidden
  }

  private getLocationsByStore = (
    id: string,
    store: LocationStoreBase<LocationBase>,
  ) => {
    const { isDone } = this.delivery
    const location = store.byId.get(id)

    return location && (!location.isDeleted || isDone) ? location : null
  }

  @computed
  public get fieldValuesMap(): {
    [fieldId: string]: string | string[]
  } {
    const {
      codeToDisplay,
      startDate,
      endDate,
      company,
      vendor,
      driverPhoneNumbers,
      onSiteContactPersonIds,
      status,
    } = this.delivery

    const { getShortTimeIntervalPerDay } = this.projectDateStore
    const { getCompanyNameById } = this.companiesStore

    return {
      [FieldIds.ID]: codeToDisplay(this.companiesStore),
      [FieldIds.COMPANY]: getCompanyNameById(company, EMPTY_STRING),
      [FieldIds.BOOKING_TIME]: getShortTimeIntervalPerDay(startDate, endDate),
      [FieldIds.STATUS]: status,
      [FieldIds.BUILDING]: this.building?.name,
      [FieldIds.ZONE]: this.zone?.name,
      [FieldIds.OFFLOADING_EQUIPMENT]: this.equipmentsText,
      [FieldIds.GATE]: this.gate?.name,
      [FieldIds.ROUTE]: this.route?.name,
      [FieldIds.LEVEL]: this.level?.name,
      [FieldIds.AREA]: this.area?.name,
      [FieldIds.MATERIAL_CATEGORY]: this.materialsCategoryText,
      [FieldIds.MATERIAL_NOTE]: this.materialsDescriptionText,
      [FieldIds.VENDOR]: getCompanyNameById(vendor, EMPTY_STRING),
      [FieldIds.TRUCK_SIZE]: this.vehicleText,
      [FieldIds.DRIVER_PHONE_NUMBERS]: driverPhoneNumbers,
      [FieldIds.ON_SITE_CONTACTS]: this.projectMembersStore
        .getByIds(onSiteContactPersonIds)
        .map(member => member.fullName),
    }
  }

  @computed
  public get fieldColorsMap(): {
    [fieldId: string]: string
  } {
    return {
      [FieldIds.BUILDING]: this.building?.color,
      [FieldIds.ZONE]: this.zone?.color,
      [FieldIds.OFFLOADING_EQUIPMENT]: this.equipments?.[0]?.color,
      [FieldIds.GATE]: this.gate?.color,
      [FieldIds.ROUTE]: this.route?.color,
      [FieldIds.LEVEL]: this.level?.color,
      [FieldIds.AREA]: this.area?.color,
    }
  }

  public get attributesCardFields(): IDeliveryCardField[] {
    return this.cardConfig.filter(
      f => !TITLE_IDS.includes(f.fieldId) && f.fieldId !== FieldIds.ID,
    )
  }

  public get titleCardFields(): IDeliveryCardField[] {
    return this.cardConfig.filter(f => TITLE_IDS.includes(f.fieldId))
  }

  @computed
  private get building() {
    return this.getLocationsByStore(this.delivery.building, this.buildingsStore)
  }

  @computed
  private get zone() {
    return this.getLocationsByStore(this.delivery.zone, this.zonesStore)
  }

  @computed
  private get area() {
    return this.getLocationsByStore(this.delivery.area, this.areasStore)
  }

  @computed
  private get gate() {
    return this.getLocationsByStore(this.delivery.gate, this.gatesStore)
  }

  @computed
  private get equipments() {
    const { isDone, offloadingEquipmentIds } = this.delivery

    return offloadingEquipmentIds
      .map(id => {
        const equipment = this.offloadingEquipmentsStore.byId.get(id)
        return equipment && (!equipment.isDeleted || isDone) ? equipment : null
      })
      .filter(eq => !!eq)
  }

  @computed
  private get equipmentsText(): string {
    const equipments = this.equipments?.map(eq => eq?.name).join(', ')

    return !this.hiddenFields[FieldIds.NUMBER_OF_EQUIPMENT_PICKS] &&
      this.delivery.numberOfEquipmentPicks
      ? `(${this.delivery.numberOfEquipmentPicks}) ${equipments}`
      : equipments
  }

  @computed
  private get route() {
    return this.getLocationsByStore(this.delivery.route, this.routesStore)
  }

  @computed
  private get level() {
    return this.getLocationsByStore(this.delivery.level, this.levelsStore)
  }

  @computed
  private get materialsCategoryText(): string {
    const { materialIds } = this.delivery
    const materials = this.materialsStore.getByIds(materialIds)

    return materials
      .map(
        mat =>
          mat.productName ||
          this.materialCategoryStore.getCategoryNameById(mat.categoryId),
      )
      .filter(m => !!m)
      .join(', ')
  }

  @computed
  private get materialsDescriptionText(): string {
    return this.delivery.materials
      ?.map(mat => mat.note, MAX_NAME_LENGTH)
      .filter(m => !!m)
      .join(', ')
  }

  @computed
  private get vehicleText(): string {
    const { truckNumber, truckSize, isDone } = this.delivery
    const truck = this.deliveryVehicleTypesStore.getInstanceById(truckSize)

    return truck && (!truck.isDeleted || isDone)
      ? `(${truckNumber || 1})${' '}${truck.name}`
      : EMPTY_STRING
  }

  private get isMaterialSectionHidden(): boolean {
    return this.hiddenFields[FieldIds.MATERIALS_SECTION]
  }

  private get areMaterialsCategoryDisabled(): boolean {
    const { isCSIDisabled, isCSISubCategoriesDisabled } =
      this.state.projectMaterialOptions
    return isCSIDisabled && isCSISubCategoriesDisabled
  }

  private get cardConfig(): IDeliveryCardField[] {
    return this.deliverySettings.configurations.cardFieldsConfig.filter(
      f => !this.isFieldHidden(f),
    )
  }

  private get hiddenFields(): { [fieldName: string]: boolean } {
    return this.deliverySettings.hiddenFields
  }

  private get deliverySettings() {
    return this.state.delivery
  }
}

const fromSectionPrefix = 'From'
export const getFromSectionAnalogFieldId = fieldId =>
  fromSectionPrefix + fieldId

enum FieldIds {
  // Names for these inputs should match those of Delivery class properties on Graph/API
  // to comply with defaultHidden[Mandatory]Fields in Configuration collection
  ID = 'Id',
  COMPANY = 'Company',
  TRUCK_SIZE = 'TruckSize',
  TRUCK_NUMBER = 'TruckNumber',
  TRUCK_LICENSE_PLATE = 'TruckLicensePlate',
  VENDOR = 'Vendor',
  VENDOR_EMAILS = 'VendorEmails',
  DRIVER_PHONE_NUMBERS = 'DriverPhoneNumbers',
  ON_SITE_CONTACT = 'OnSiteContactPersonId',
  ON_SITE_CONTACTS = 'OnSiteContactPersonIds',
  IS_INSPECTION_REQUIRED = 'IsInspectionRequired',
  OFFLOADING_EQUIPMENT = 'OffloadingEquipments',
  NUMBER_OF_EQUIPMENT_PICKS = 'NumberOfEquipmentPicks',
  ACTIVITY_ID = 'ActivityId',
  MATERIAL_UNITS = 'Units',
  INSPECTOR = 'Inspector',
  NOTE = 'Note',

  FROM_TO_SWITCH = 'FromToSwitch',

  // Delivery Location Fields
  BUILDING = 'Building',
  FROM_BUILDING = 'FromBuilding',

  GATE = 'Gate',

  ZONE = 'Zone',
  FROM_ZONE = 'FromZone',

  LEVEL = 'Level',
  FROM_LEVEL = 'FromLevel',

  AREA = 'Area',
  FROM_AREA = 'FromArea',

  ROUTE = 'Route',

  STAGING = 'Staging',
  FROM_STAGING = 'FromStaging',

  INTERIOR_DOOR = 'InteriorDoor',

  INTERIOR_PATH = 'InteriorPath',

  INSTALLATION_ZONE = 'InstallationZone',
  FROM_INSTALLATION_ZONE = 'FromInstallationZone',
  // ---

  // These should match consts in DeliveryFieldsConfigurations class on Graph/API
  MATERIAL_QUANTITY = 'Quantity',
  MATERIAL_CATEGORY = 'MaterialCategory',
  MATERIAL = 'Material',
  MATERIAL_NOTE = 'MaterialNote',
  MATERIAL_LOCATION = 'MaterialLocation',

  // Should match delivery config field
  CUSTOM_ACTION_BUTTON = 'isCustomActionIncluded',
  CANCELATION_REASON = 'cancelationReason',

  DATE = 'input-date',
  END_DATE = 'input-end-date',
  BOOKING_TIME = 'bookingTime',
  START_TIME = 'input-start-time',
  END_TIME = 'input-end-time',
  REQUESTER = 'input-requester-name',
  SITE_MAPS = 'input-site-maps',
  LATE_REQUEST = 'scheduled-in-advance',
  UNSCHEDULED_REQUEST = 'input-unscheduled-request',
  DURATION = 'duration',
  RECURRING_OPTIONS = 'recurringOptions',
  WEATHER_FORECAST = 'WeatherForecast',

  NON_WORK_TIMES = 'non-work-times',

  INSPECTION_SECTION = 'inspection',
  MATERIALS_SECTION = 'materials',

  // presented in the form but doesn't have config
  MATERIAL_PROCUREMENT_ID = 'MaterialProcurementId',
  MATERIAL_INSTALL_LOCATION = 'MaterialInstallLocation',

  // not on Delivery Form
  LOGISTICS_OBJECT = 'LogisticsObject',
  VERTICAL_OBJECT = 'VerticalObject',
  STATUS = 'status',
  LOCATION = 'location',
  SITE = 'site',
  INTEGRATION = 'integration',
  MATURIX_STATION = 'maturix',
  IS_MULTIPLE_EQUIPMENT = 'isMultipleEquipmentAllowed',
}
export default FieldIds

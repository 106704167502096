import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

import Sitemap from '../../models/Sitemap'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import { customDebounce } from '../../utils/util'
import FileInputBase from '../FileInput/FileInput'
import SitemapDeliveryAttributes from '../SitemapDeliveryAttributes/SitemapDeliveryAttributes'
import StruxhubInput from '../StruxhubInputs/StruxhubInput'

import './CompactSitemapPicker.scss'

const PRESS_DELAY = 200

const SortableItem = SortableElement(({ item }) => item)

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem key={`item-${item.key}`} index={index} item={item} />
      ))}
    </div>
  )
})

const doneTypingInterval = 300
interface IProps {
  onClose: () => void
  onApply: (sitemap: Sitemap) => void
  sitemapsIds: string[]
  selectedSitemapId: string
  reassignSitemapsOrders?: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: any
    newIndex: any
  }) => void

  FileInputType: typeof FileInputBase
  sitemapsStore?: SitemapsStore
  eventsStore?: EventsStore
  withPressDelay?: boolean
}

@inject('eventsStore', 'sitemapsStore')
@observer
export default class CompactSitemapPicker extends React.Component<IProps> {
  @observable public inputValue: string = ''
  @observable public searchKey: string = ''

  public render() {
    const { onClose } = this.props
    return (
      <>
        <div onClick={onClose} className="dimmer shown" />
        <div className="sitemap-picker-holder col filter-holder bb-light-grey shown">
          {this.renderHeader()}
          {this.renderSearchBox()}
          <div className="scrollable sitemaps-options-list">
            {this.renderContent()}
          </div>
        </div>
      </>
    )
  }

  private renderHeader() {
    const { onClose } = this.props
    return (
      <div className="row pa10 bb-brand-dark sitemaps-options-header ">
        <div className="col no-grow">
          <Icons.Cross
            className="cross-icon no-grow row y-center"
            onClick={onClose}
          />
        </div>
        <div className="col x-center text large bold mr20">
          {Localization.translator.jumpToMap}
        </div>
      </div>
    )
  }

  private renderContent() {
    const { reassignSitemapsOrders } = this.props
    return (
      <SortableList
        shouldCancelStart={this.shouldCancelStart}
        items={this.sitemapsItems}
        onSortEnd={reassignSitemapsOrders}
        lockAxis="y"
        pressDelay={this.pressDelay}
      />
    )
  }

  private get pressDelay(): number {
    return this.props.withPressDelay ? PRESS_DELAY : 0
  }

  @computed
  private get filteredSitemaps(): Sitemap[] {
    const { sitemapsIds, sitemapsStore } = this.props
    const key = this.searchKey.toLowerCase()
    return sitemapsIds
      .map(id => sitemapsStore.byId.get(id))
      .filter(({ name }) => name.toLowerCase().includes(key))
  }

  private get sitemapsItems() {
    const { FileInputType } = this.props
    return this.filteredSitemaps.map(sitemap => {
      const isSelected = this.isSitemapSelected(sitemap.id)
      return (
        <div
          className={classList({
            'row sitemap-option pa10 bb-light-grey z-index-100': true,
            selected: isSelected,
          })}
          key={sitemap.id}
          onClick={this.onSitemapSelect.bind(this, sitemap)}
        >
          <FileInputType
            id={sitemap.id}
            name=""
            className="no-grow"
            value={sitemap.filledImage}
            isReadonly={true}
            textClassName="hint"
            shouldHideIconAndOutline={true}
            shouldHideName={true}
          />
          <div className="ml20">
            <span className="text large bold">{sitemap.name}</span>
            <SitemapDeliveryAttributes
              sitemap={sitemap}
              shouldHideCrossIcons={true}
              areTagsBordered={true}
              className="row wrap full-width x-start y-start tags-holder"
            />
          </div>
          {isSelected && <Icons.CheckEmptyBlue className="no-grow" />}
        </div>
      )
    })
  }

  private isSitemapSelected = (id: string): boolean => {
    return id === this.props.selectedSitemapId
  }

  private onSitemapSelect = (
    sitemap: Sitemap,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation()
    this.props.onApply(sitemap)
  }

  private renderSearchBox() {
    return (
      <div className="row">
        <StruxhubInput
          isMinimalisticMode={true}
          placeholder={Localization.translator.search}
          value={this.inputValue}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  @action.bound
  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget

    this.inputValue = value

    this.updateSearchKeyWithDelay(value)
  }

  @action.bound
  private updateSearchKey(newValue: string) {
    this.searchKey = newValue
  }

  private updateSearchKeyWithDelay = customDebounce(
    this.updateSearchKey,
    doneTypingInterval,
  )

  private shouldCancelStart = () => {
    return !this.props.eventsStore.appState.userActiveProjectSettings?.isAdmin
  }
}

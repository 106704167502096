import { observable } from 'mobx'

import {
  IPosition,
  ISitemapPolyline,
  SitemapItemShapeType,
  SitemapLineArrowPosition,
} from '~/client/graph'

import { IBoundingBox } from './SitemapItemBase'

const DEFAULT_IS_DISPLAYED = true
const DEFAULT_LINE_WIDTH = 3
const DEFAULT_FILL_OPACITY = 0.2

const MAX_PERCENT = 100
const DEGREE_IN_RADIANS = Math.PI / 180

export default class SitemapPolyLineProperties implements ISitemapPolyline {
  public type: SitemapItemShapeType = SitemapItemShapeType.Polyline
  @observable public lineWidth: number
  @observable public fillColor: string
  @observable public fillOpacity: number
  @observable public isClosed: boolean
  @observable public arrowPosition: SitemapLineArrowPosition
  @observable public points: IPosition[]
  @observable public lineColor: string
  @observable public isDisplayed: boolean

  public constructor(
    lineWidth?: number,
    lineColor?: string,
    fillColor?: string,
    fillOpacity?: number,
    isClosed?: boolean,
    arrowPosition?: SitemapLineArrowPosition,
    points?: IPosition[],
    isDisplayed?: boolean,
  ) {
    this.lineWidth = lineWidth || DEFAULT_LINE_WIDTH
    this.lineColor = lineColor
    this.fillColor = fillColor
    this.fillOpacity =
      typeof fillOpacity === 'number' ? fillOpacity : DEFAULT_FILL_OPACITY
    this.isClosed = !!isClosed
    this.arrowPosition = arrowPosition || SitemapLineArrowPosition.None
    this.points = points || []
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
  }

  public getBoundingBox(
    containerWidth: number,
    containerHeight: number,
  ): IBoundingBox {
    if (!this.isValid()) {
      return null
    }

    const minX = Math.min(...this.points.map(p => p.x))
    const maxX = Math.max(...this.points.map(p => p.x))
    const minY = Math.min(...this.points.map(p => p.y))
    const maxY = Math.max(...this.points.map(p => p.y))

    return {
      x: (containerWidth * minX) / MAX_PERCENT,
      y: (containerHeight * minY) / MAX_PERCENT,
      width: (containerWidth * (maxX - minX)) / MAX_PERCENT,
      height: (containerHeight * (maxY - minY)) / MAX_PERCENT,
    }
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public isValid(): boolean {
    return this.points.length > 1
  }

  public isValidForClosing(): boolean {
    return this.points.length > 2
  }

  public move(x: number, y: number) {
    this.points.forEach(point => {
      point.x += x
      point.y += y
    })
  }

  public setLineWidth(width: number) {
    this.lineWidth = width
  }

  public setLineColor(color: string) {
    this.lineColor = color
  }

  public setFillColor(color: string) {
    this.fillColor = color
  }

  public setFillOpacity(opacity: number) {
    this.fillOpacity = opacity
  }

  public toggleIsClosed() {
    if (!this.isClosed) {
      this.closeShape()
    } else {
      this.isClosed = false
    }
  }

  public closeShape() {
    if (this.points.length > 2) {
      this.isClosed = true
    }
  }

  public setArrowPosition(arrowPosition: SitemapLineArrowPosition) {
    this.arrowPosition = arrowPosition
  }

  public calculateIconPosition(): IPosition {
    if (!this.isValid()) {
      return null
    }
    if (this.isClosed) {
      // center of closed shape
      const sumX = this.points.reduce((total, point) => total + point.x, 0)
      const sumY = this.points.reduce((total, point) => total + point.y, 0)
      return {
        x: sumX / this.points.length,
        y: sumY / this.points.length,
      }
    }
    // middle of the line
    const middlePointIdx = Math.floor((this.points.length - 1) / 2)
    const startPoint = this.points[middlePointIdx]
    const endPoint = this.points[middlePointIdx + 1]
    return {
      x: (startPoint.x + endPoint.x) / 2,
      y: (startPoint.y + endPoint.y) / 2,
    }
  }

  public addPoint(point: IPosition, idx?: number): IPosition {
    if (typeof idx === 'number' && idx >= 0 && idx < this.points.length) {
      this.points.splice(idx, 0, point)
    } else {
      this.points.push(point)
    }
    return this.points[this.points.length - 1]
  }

  public deletePoints(predicate: (point: IPosition) => boolean) {
    this.points = this.points.filter(point => !predicate(point))
    if (this.points.length < 3) {
      this.isClosed = false
    }
  }

  public scale(originPoint: IPosition, scaleX: number, scaleY: number) {
    this.points.forEach(point => {
      point.x = scaleX * (point.x - originPoint.x) + originPoint.x
      point.y = scaleY * (point.y - originPoint.y) + originPoint.y
    })
  }

  public rotate(center: IPosition, angle: number) {
    const cos = Math.cos(angle * DEGREE_IN_RADIANS)
    const sin = Math.sin(angle * DEGREE_IN_RADIANS)

    this.points = this.points.map(({ x, y }) => {
      x -= center.x
      y -= center.y

      const newX = x * cos - y * sin
      const newY = x * sin + y * cos

      return {
        x: newX + center.x,
        y: newY + center.y,
      }
    })
  }

  public isDisplayDataEqual(poly: ISitemapPolyline) {
    if (
      this.isDisplayed !==
        (typeof poly.isDisplayed === 'boolean'
          ? poly.isDisplayed
          : DEFAULT_IS_DISPLAYED) ||
      this.type !== poly.type ||
      this.lineWidth !== poly.lineWidth ||
      this.lineColor !== poly.lineColor ||
      this.fillColor !== poly.fillColor ||
      this.fillOpacity !== poly.fillOpacity ||
      this.isClosed !== poly.isClosed ||
      this.arrowPosition !== poly.arrowPosition ||
      this.points.length !== poly.points.length
    ) {
      return false
    }
    return this.points.every(
      (point, idx) =>
        point.x === poly.points[idx].x && point.y === poly.points[idx].y,
    )
  }

  public getDisplayData(): ISitemapPolyline {
    const points = this.points.map(p => ({
      x: p.x,
      y: p.y,
    }))

    return {
      type: this.type,
      lineWidth: this.lineWidth,
      lineColor: this.lineColor,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      isClosed: this.isClosed,
      arrowPosition: this.arrowPosition,
      points,
      isDisplayed: this.isDisplayed,
    }
  }

  public copy(): SitemapPolyLineProperties {
    const points = this.points.map(p => ({
      x: p.x,
      y: p.y,
    }))
    return new SitemapPolyLineProperties(
      this.lineWidth,
      this.lineColor,
      this.fillColor,
      this.fillOpacity,
      this.isClosed,
      this.arrowPosition,
      points,
      this.isDisplayed,
    )
  }
}

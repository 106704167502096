import * as React from 'react'

import {
  Classes,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { observer } from 'mobx-react'

import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import StruxhubAttributeSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import IHierarchyParent from '~/client/src/shared/types/IHierarchyParent'
import { NOOP } from '~/client/src/shared/utils/noop'

import SitemapItemsSetupStore from '../../stores/SitemapItemsSetup.store'

import './ParentSelector.scss'

const NONE_VALUE = null
const select = 'Select'
const selectParent = 'Select Parent'

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'scrollParent',
  },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
  arrow: { enabled: false },
}

export interface IProps {
  label: string
  updateObjectParent?: (parent: IHierarchyParent) => void
  store: SitemapItemsSetupStore
}

@observer
export default class ParentsSelector extends React.Component<IProps> {
  public render() {
    const {
      store: {
        selectedSitemapItemAllowedParents,
        selectedSitemapItem,
        selectedSitemapItemRestrictedParents,
      },
      label,
    } = this.props

    const { parent } = selectedSitemapItem
    const value = parent || NONE_VALUE

    const selectedItem =
      value &&
      selectedSitemapItemAllowedParents.find(item => item.id === value.parentId)

    const restrictedIds = selectedSitemapItemRestrictedParents.map(
      ({ id }) => id,
    )
    const selectedIds = selectedItem ? [selectedItem.id] : []

    return (
      <Popover
        position={PopoverPosition.BOTTOM}
        minimal={true}
        usePortal={false}
        canEscapeKeyClose={false}
        modifiers={popoverPopperModifiers}
        targetClassName="full-width"
        className="vertical-align-middle"
        content={
          <LocationPicker
            className="parent-selector-modal"
            buttonsClassName={Classes.POPOVER_DISMISS}
            title={selectParent}
            applyButtonTitle={select}
            isSingleSelectionMode={true}
            selectedIds={selectedIds}
            restrictedObjectIds={restrictedIds}
            onSingleSelect={this.onItemSelect}
            onChange={NOOP}
            onApplyChanges={NOOP}
          />
        }
      >
        <StruxhubAttributeSelector label={label} value={selectedItem?.id} />
      </Popover>
    )
  }

  private onItemSelect = (selectedAttr: LocationBase) => {
    const { updateObjectParent, store } = this.props
    const {
      selectedSitemapItem,
      addCurrentStateToHistory,
      resetAccessibleLevels,
    } = store

    if (selectedAttr) {
      selectedSitemapItem.setParent({
        parentId: selectedAttr.id,
        parentType: selectedAttr.type,
      })
      selectedSitemapItem.setAllColors(selectedAttr.color)
    } else {
      selectedSitemapItem.setParent(null)
    }

    resetAccessibleLevels(selectedSitemapItem)
    addCurrentStateToHistory()

    updateObjectParent?.(selectedSitemapItem.parent)
  }
}

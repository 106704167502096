import { action, computed } from 'mobx'

import {
  IFilterGroup,
  IMaterialConfiguration,
  IOrderedSitemap,
} from '~/client/graph'
import BaseGroupingOptionsStore, {
  EMPTY_OPTION,
  ICustomBandOption,
  INDENT_OPTION_MAIN_ID,
} from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions.store'
import { IBandOption } from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import MaterialsGroupingOption, {
  materialsGroupingOptionList,
} from '~/client/src/shared/enums/MaterialsGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import EventTypes from '~/client/src/shared/stores/EventStore/eventTypes'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import MaterialConfigurationStore from '~/client/src/shared/stores/domain/MaterialConfiguration.store'
import FilterInfo from '~/client/src/shared/stores/substates/FilterInfo'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'
import { areArraysEqual } from '~/client/src/shared/utils/util'

const INDENT_OPTION_IDS: string[] = [
  MaterialsGroupingOption.PLANNED_INSTALL_LOCATION,
  MaterialsGroupingOption.COMPANY,
  MaterialsGroupingOption.BUILDING,
  MaterialsGroupingOption.MATERIAL_CATEGORY,
]
const MAX_CUSTOM_BANDS_LEVEL = 4
const MIN_CUSTOM_BANDS_COUNT = 1
const CUSTOM_BAND_PREFIX = 'Custom_'

export default class MaterialsGroupingOptionsStore extends BaseGroupingOptionsStore {
  public maxCustomBandsLevel: number = MAX_CUSTOM_BANDS_LEVEL
  public leftCaptionKey = KnownTranslatorKeys.options

  public constructor(
    protected readonly eventsStore: DesktopEventStore,
    protected readonly onShowChanged: (isShown: boolean) => void,
    protected readonly onChanged: () => void,
    private filters: IFilters,
    private groupingOptions: string[],
    private defaultGroupingOption: string,
    private desktopFilterMap: { [key: string]: FilterInfo },
    private translatorKeyGetter: (id: string) => KnownTranslatorKeys,
    private readonly materialConfigStore: MaterialConfigurationStore,
  ) {
    super(eventsStore, onShowChanged)
  }

  @computed
  public get bandsOptions(): IBandOption[] {
    const defaultBands = this.groupingOptions.map((option, index) => ({
      id: option,
      nameTraslatorKey: this.translatorKeyGetter(option),
      num: index + 1,
      bands: [option],
    }))

    const { savedGroups } = this.materialConfigStore.configuration

    const customBands: IBandOption[] = savedGroups?.map(
      ({ filterNames }, index) => {
        const filters = Object.values(filterNames)
        return {
          name: filters
            .map(value => this.desktopFilterMap[value].getCaption())
            .join(', '),
          id: `${CUSTOM_BAND_PREFIX}${index}${filters.join(', ')}`,
          num: defaultBands.length + index + 1,
          shortName: Localization.translator.custom,
          disabled: false,
          bands: filters,
          canDelete: true,
          deletionIndex: index,
        }
      },
    )

    return [...defaultBands, ...(customBands || [])]
  }

  @computed
  public get groupByCaption(): string {
    if (!this.appliedOption) {
      return EMPTY_STRING
    }

    const { name, nameTraslatorKey } = this.appliedOption
    return nameTraslatorKey
      ? Localization.getText(nameTraslatorKey)
      : name || EMPTY_STRING
  }

  @computed
  public get options(): ICustomBandOption[] {
    const options: ICustomBandOption[] = [
      {
        name: EMPTY_OPTION,
        caption: Localization.translator.none,
      },
      ...Object.values(this.desktopFilterMap).reduce(
        (list, { name, getCaption }) => {
          if (
            name === MaterialsGroupingOption.CURRENT_LOCATION ||
            this.shouldIndent(name)
          ) {
            list.push({
              name: INDENT_OPTION_MAIN_ID,
              caption: EMPTY_OPTION,
            })
          }

          list.push({ name, caption: getCaption() })

          return list
        },
        [] as ICustomBandOption[],
      ),
    ]

    return options
  }

  @action.bound
  public initPopup() {
    this.selectedBandsOptionIdDraft =
      this.appliedOption?.id || this.defaultGroupingOption
  }

  @action.bound
  public applyGroupingOptions() {
    this.hideGroupingOptions()
    this.setSelectedBandsOption()
    this.onChanged()
  }

  @action.bound
  public resetGroupingOptions() {
    this.selectedBandsOptionIdDraft = this.defaultGroupingOption
  }

  public resetGroupingIfNeed = () => {
    const isSelectedDraftOptionExists = this.bandsOptions.some(
      opt => opt.id === this.selectedBandsOptionIdDraft,
    )

    if (this.appliedOption && isSelectedDraftOptionExists) {
      return
    }

    this.resetGroupingOptions()
    this.setSelectedBandsOption()
    this.onChanged()
  }

  private get appliedOption() {
    return this.bandsOptions.find(o => o.id === this.filters.groupingKey)
  }

  public get areDefaultOptionsSelected(): boolean {
    return this.appliedOption?.id === this.defaultGroupingOption
  }

  public shouldIndent = (id: string): boolean => {
    return (
      INDENT_OPTION_IDS.includes(id) || id.startsWith(`${CUSTOM_BAND_PREFIX}0`)
    )
  }

  public get isLoading(): boolean {
    return this.state.isLoading
  }

  public deleteCustomGrouping = (index: number) => {
    if (index < 0) {
      return
    }

    const { configuration } = this.materialConfigStore
    const { sitemaps, savedGroups } = configuration

    configuration.savedGroups = savedGroups.splice(index, 1)

    this.saveGroupingConfig(null, EMPTY_STRING, sitemaps, savedGroups || [])
  }

  public setSelectedBand() {
    const { selectedMaterialBandsOption } = this.state.filters
    if (
      !selectedMaterialBandsOption ||
      !this.bandsOptions.some(({ id }) => id === selectedMaterialBandsOption.id)
    ) {
      this.resetGroupingIfNeed()
    }
  }

  protected saveGroupingConfig(
    _eventName: EventTypes,
    _savedGroupsName: string,
    sitemaps: IOrderedSitemap[],
    savedEntitiesGroups: IFilterGroup[],
  ) {
    const config: IMaterialConfiguration = {
      sitemaps,
      projectId: this.eventsStore.appState.activeProject.id,
      savedGroups: savedEntitiesGroups,
    }

    this.materialConfigStore.save(config)
  }

  private setSelectedBandsOption() {
    if (this.canCreateCustomGrouping) {
      const { sitemaps, savedGroups } = this.materialConfigStore.configuration
      this.saveSelectedLevels(null, EMPTY_STRING, sitemaps, savedGroups || [])
      return
    }

    this.groupLevels = [EMPTY_OPTION]

    if (this.filters.groupingKey !== this.selectedBandsOptionIdDraft) {
      this.state.filters.selectedMaterialBandsOption = this.bandsOptions.find(
        ({ id }) => id === this.selectedBandsOptionIdDraft,
      )
      this.filters.groupingKey = this.selectedBandsOptionIdDraft
    }
  }

  public get doesGroupingAlreadyExist(): boolean {
    const { savedGroups } = this.materialConfigStore.configuration
    const filteredGroups = this.groupLevels.filter(v => v)

    return (
      materialsGroupingOptionList.some(o =>
        areArraysEqual([o], filteredGroups),
      ) ||
      savedGroups?.some(g =>
        areArraysEqual(g.filterNames || [], filteredGroups),
      )
    )
  }

  private get canCreateCustomGrouping(): boolean {
    return (
      this.groupLevels.length > MIN_CUSTOM_BANDS_COUNT &&
      !this.doesGroupingAlreadyExist
    )
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'
import { Swipeable } from 'react-swipeable'

import Announcement from '../../models/Announcement'
import Delivery from '../../models/Delivery'
import SitePermit from '../../models/Permit'
import AnnouncementListItem from '../AnnouncementListItem'
import DeliveryWorkflowCard from '../ExpandableWorkflowCard/components/DeliveryWorkflowCard'
import PermitCard from './PermitCard'

import './LogisticsSwipeableCards.scss'

const MAX_PERCENT = 100
const CARDS_OFFSET = 12

export interface IProps {
  swipeableItemId: string
  showPreviousItem: () => void
  showNextItem: () => void
  permits: SitePermit[]
  openSitePermit: (permit: SitePermit) => void

  announcements: Announcement[]
  openAnnouncement: (announcement: Announcement) => void

  deliveries: Delivery[]
  openDelivery: (delivery: Delivery) => void
}

@observer
export default class LogisticsMapSwipeableCards extends React.Component<IProps> {
  public render() {
    const {
      permits,
      openSitePermit,
      openAnnouncement,
      announcements,
      deliveries,
      showNextItem,
      showPreviousItem,
      openDelivery,
      swipeableItemId,
    } = this.props

    if (!this.allItems.length) {
      return null
    }
    const index = this.allItems.findIndex(item => item.id === swipeableItemId)

    const percentTranslate = MAX_PERCENT * index
    const pixelTranslate = index * CARDS_OFFSET

    return (
      <div className="logistics-carousel full-width absolute">
        <Swipeable
          onSwipedRight={showPreviousItem}
          onSwipedLeft={showNextItem}
          trackMouse={true}
          className="logistics-cards full-height overflow-hidden"
        >
          <div
            className="logistics-cards-container nowrap py10"
            style={{
              transform: `translateX(calc(-${percentTranslate}% - ${pixelTranslate}px))`,
            }}
          >
            {announcements.map(announcement => (
              <div
                key={announcement.id}
                className="logistics-card brada8 inline-block mr12 vertical-align-middle no-select bg-white"
              >
                <AnnouncementListItem
                  key={announcement.id}
                  announcement={announcement}
                  onClick={openAnnouncement}
                />
              </div>
            ))}
            {permits.map(permit => (
              <div
                key={permit.id}
                className="logistics-card brada8 inline-block mr12 vertical-align-middle no-select bg-white"
              >
                <PermitCard
                  className="brada8 inline-block mr12 vertical-align-middle no-select bg-white"
                  permit={permit}
                  key={permit.id}
                  onClick={openSitePermit}
                />
              </div>
            ))}
            {deliveries.map(delivery => (
              <div
                key={delivery.id}
                className="logistics-card brada8 mr12 inline-block vertical-align-middle no-select bg-white"
              >
                <DeliveryWorkflowCard
                  delivery={delivery}
                  onClick={openDelivery}
                />
              </div>
            ))}
          </div>
        </Swipeable>
      </div>
    )
  }

  private get allItems() {
    const { announcements, deliveries, permits } = this.props
    return [...announcements, ...permits, ...deliveries]
  }
}

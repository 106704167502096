import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { Stage } from 'konva/types/Stage'
import { computed, observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Group } from 'react-konva'

import BaseSitemap from '~/client/src/shared/components/BaseSitemap/BaseSitemap'
import KonvaWorkflowDeliveriesPin from '~/client/src/shared/components/Konva/KonvaWorkflowDeliveriesPin'
import MapLayersMenu from '~/client/src/shared/components/MapLayersSelector/components/MapLayersMenu'
import BaseSitemapSetUpStore from '~/client/src/shared/components/SitemapHelpers/DeliverySitemap.store'
import SitemapItems from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Delivery from '~/client/src/shared/models/Delivery'
import InitialState from '~/client/src/shared/stores/InitialState'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import DeliverySitemapPinStore from '~/client/src/shared/stores/ui/DeliverySitemapPin.store'

import MapBoxEditorStore from '../../../../MapBoxEditor/MapBoxEditor.store'
import MenuCloser from '../../../../MenuCloser'
import SitemapItemBase from '../../../../SitemapHelpers/models/SitemapItemBase'
import DeliveriesMapViewStore from '../DeliveriesMapView.store'
import DeliveriesMapSwipeableCards from './DeliveriesMapSwipeableCards'

interface IProps {
  sitemapId: string
  textboxesCache: ICanvasImageCache

  mapStore: DeliveriesMapViewStore
  mapBoxEditorStore: MapBoxEditorStore

  state?: InitialState
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
  companiesStore?: CompaniesStore

  isCompactMode?: boolean
  isLayersMenuShown?: boolean
  toggleLayersMenu?: () => void
}

@inject(
  'state',
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'locationAttributesStore',
  'companiesStore',
)
@observer
export default class DeliveriesMapSitemap extends React.Component<IProps> {
  @observable private deliverySitemap: BaseSitemap
  private readonly store: BaseSitemapSetUpStore = null
  private readonly sitemapPinStore: DeliverySitemapPinStore = null
  private selectedDeliveriesMap: { [attrId: string]: boolean } = {}
  public static contextType = MobXProviderContext

  public constructor(props: IProps) {
    super(props)

    this.store = new BaseSitemapSetUpStore(
      props.sitemapsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
    )
    this.sitemapPinStore = new DeliverySitemapPinStore(props.state)

    this.store.selectSitemapFromId(props.sitemapId)

    props.mapBoxEditorStore.setViewportFromAdress()
    props.mapBoxEditorStore.setDefaultMapMode()
    props.mapBoxEditorStore.hideToggleMapMode()
  }

  public componentDidUpdate(props: IProps) {
    if (this.props.sitemapId !== props.sitemapId) {
      this.store.selectSitemapFromId(this.props.sitemapId)
      this.selectedDeliveriesMap = {}
      this.props.mapBoxEditorStore.resetMapboxInfo()
      this.props.mapBoxEditorStore.removeRefs()
    }
  }

  public render() {
    const {
      mapStore: { resetSelectedDelivery },
      sitemapId,
      toggleLayersMenu,
      isLayersMenuShown,
      isCompactMode,
      mapBoxEditorStore,
      state,
      sitemapsStore,
    } = this.props
    const { sitemapUrl } = this.store

    const {
      isDraggingMode,
      isImageRubberMode,
      shouldShowImage,
      sourceBounds,
      setViewport,
      viewport,
      mapViewport,
      opacity,
      sitemapStage,
    } = mapBoxEditorStore
    const sitemap = sitemapsStore.byId.get(sitemapId)
    return (
      <div className="relative general-map-container full-width full-height">
        {isLayersMenuShown && (
          <div className="layers-container">
            <MenuCloser closeMenu={toggleLayersMenu}>
              <MapLayersMenu
                store={this.props.mapBoxEditorStore}
                onClose={toggleLayersMenu}
                className="compact-view"
              />
            </MenuCloser>
          </div>
        )}
        <BaseSitemap
          isDraggable={true}
          setSitemap={this.setDeliverySitemapStage}
          isDraggingMode={isDraggingMode}
          selectedSitemap={sitemap}
          opacity={opacity}
          mapBoxEditorStore={this.props.mapBoxEditorStore}
          mapViewport={viewport || mapViewport}
          setViewport={setViewport}
          shouldShowImage={shouldShowImage}
          isImageRubberMode={isImageRubberMode}
          sourceBounds={sourceBounds}
          shouldUseFullHeight={true}
          sitemapUrl={sitemapUrl}
          onClick={resetSelectedDelivery}
          onTouch={resetSelectedDelivery}
          shouldHideControls={isCompactMode}
          basemapsStore={this.props.basemapsStore}
          projectAddress={state.projectAddress}
          ref={this.setDeliverySitemap}
        >
          {({ width, height, key, isMainSitemap }) => {
            if (
              !this.deliverySitemap ||
              (sitemap.isReferenced && !sitemapStage)
            ) {
              return null
            }
            return (
              <Provider {...this.context}>
                {this.renderSitemapChildren(width, height, key, isMainSitemap)}
              </Provider>
            )
          }}
        </BaseSitemap>
        <DeliveriesMapSwipeableCards store={this.props.mapStore} />
      </div>
    )
  }

  private setDeliverySitemap = ref => {
    this.deliverySitemap = ref
  }

  private renderSitemapChildren = (
    width: number,
    height: number,
    key: string,
    isMainSitemap?: boolean,
  ) => {
    const {
      textboxesCache,
      mapBoxEditorStore,
      mapStore: { selectedDeliveryId },
      sitemapId,
      sitemapsStore,
    } = this.props
    const sitemap = sitemapsStore.byId.get(sitemapId)
    const { secondaryItems, itemsFiltering } = mapBoxEditorStore

    const { attrIdToDeliveriesMap } = this

    const referencedChildren = isMainSitemap
      ? itemsFiltering
      : secondaryItems[key]
    const itemsToShow = sitemap.isReferenced
      ? referencedChildren
      : this.store.displayedSitemapItems

    return (
      <>
        <SitemapItems
          className={classList({ 'unclickable-element': !isMainSitemap })}
          items={itemsToShow || []}
          containerWidth={width}
          containerHeight={height}
          textboxesCache={textboxesCache}
          mapBoxEditorStore={mapBoxEditorStore}
          isReferenced={sitemap?.isReferenced}
        />
        {(isMainSitemap || !sitemap.isReferenced) && (
          <Group>
            {this.sortedSitemapItems.map(item =>
              this.renderWorkflowPin(
                item,
                width,
                height,
                attrIdToDeliveriesMap,
                selectedDeliveryId,
              ),
            )}
          </Group>
        )}
      </>
    )
  }

  private setDeliverySitemapStage = (stage: Stage) => {
    this.props.mapBoxEditorStore.sitemapStage = stage
  }

  private renderWorkflowPin(
    item: SitemapItemBase,
    cWidth: number,
    cHeight: number,
    attrIdToDeliveriesMap: { [attrId: string]: Delivery[] },
    selectedDeliveryId: string,
  ): JSX.Element {
    const deliveries = attrIdToDeliveriesMap[item.id]
    const {
      companiesStore,
      state: { user },
    } = this.props

    const {
      shouldShowPin,
      getPinPosition,
      getPinLabel,
      shouldRenderCircle,
      shouldShowPinAsDone,
      shouldShowPinAsAssigned,
      shouldShowPinAsCanceled,
    } = this.sitemapPinStore

    if (!shouldShowPin(item, deliveries)) {
      return null
    }

    const { id: sitemapItemId } = item
    const { x, y } = getPinPosition(item, cWidth, cHeight)

    const isSelected = deliveries.some(d => d.id === selectedDeliveryId)

    return (
      <KonvaWorkflowDeliveriesPin
        key={sitemapItemId}
        isSelected={isSelected}
        isDone={shouldShowPinAsDone(deliveries)}
        isAssigned={shouldShowPinAsAssigned(deliveries, user)}
        isCanceled={shouldShowPinAsCanceled(deliveries)}
        text={getPinLabel(deliveries, companiesStore)}
        shouldRenderCircle={shouldRenderCircle(deliveries)}
        x={x}
        y={y}
        onClick={this.onDeliveryPillTouch.bind(this, deliveries)}
        onTouchEnd={this.onDeliveryPillTouch.bind(this, deliveries)}
      />
    )
  }

  @computed
  public get attrIdToDeliveriesMap(): { [attrId: string]: Delivery[] } {
    const { displayedSitemapDeliveries } = this.props.mapStore

    this.resetSelectedDeliveriesMap()

    return this.sortedSitemapItems
      .map(i => i.id)
      .reduce((acc, attrId) => {
        acc[attrId] = displayedSitemapDeliveries.filter(
          d => d.isRelatedAttr(attrId) && !this.selectedDeliveriesMap[d.id],
        )

        this.selectDeliveries(acc[attrId].map(d => d.id))
        return acc
      }, {})
  }

  @computed
  private get sortedSitemapItems(): SitemapItemBase[] {
    return this.sitemapPinStore
      .getItemsByPinLocationOption(this.store.displayedSitemapItems)
      .filter(item => !item.isDataLess)
  }

  private selectDeliveries = (deliveriesIds: string[]) => {
    deliveriesIds.forEach(id => {
      this.selectedDeliveriesMap[id] = true
    })
  }

  private resetSelectedDeliveriesMap = () => {
    this.selectedDeliveriesMap = {}
  }

  private onDeliveryPillTouch(
    deliveries: Delivery[],
    event: KonvaEventObject<TouchEvent>,
  ) {
    const {
      isGallerySwipingDisabled,
      displayedSitemapDeliveries,
      setDisplayedDeliveryIdx,
      setSelectedDeliveryId,
    } = this.props.mapStore

    if (!isGallerySwipingDisabled) {
      const deliveryId = deliveries?.[0]?.id
      const deliveryIndex = displayedSitemapDeliveries.findIndex(
        item => item.id === deliveryId,
      )

      setDisplayedDeliveryIdx(deliveryIndex)
      setSelectedDeliveryId(deliveryId)
    }

    event.cancelBubble = true
  }
}

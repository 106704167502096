import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import JsPDF from 'jspdf'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import { Header, View } from '~/client/src/shared/components/Layout'
import InfoSectionId from '~/client/src/shared/enums/InfoSectionId'
import UserFieldId from '~/client/src/shared/enums/UserFieldId'
import { NOOP } from '~/client/src/shared/utils/noop'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import fieldIdToTagTypeMap from '../../constants/fieldIdToTagTypeMap'
import LocationAttributeBase from '../../models/LocationObjects/LocationAttributeBase'
import { ITag } from '../../models/Tag'
import User from '../../models/User'
import ChatService from '../../services/ChatService/Chat.service'
import EventsStore from '../../stores/EventStore/Events.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import TagsStore from '../../stores/domain/Tags.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import { IAddEditDialogField } from '../../stores/ui/AddEditItemDialog.store'
import CommonStore from '../../stores/ui/Common.store'
import { AvatarSize } from '../Avatar/Avatar'
import EmailLinkerDialog from '../EmailLinkerDialog/EmailLinkerDialog'
import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper'
import PageActionHeader from '../PageActionHeader/PageActionHeader'
import QRCodeAccess from '../QRCodes/components/QRCodeAccess'
import SitemapAttributeTag from '../SitemapAttributeTag/SitemapAttributeTag'
import UserProfilePreview from '../UserProfilePreview/UserProfilePreview'
import CompactUserProfileStore from './CompactUserProfile.store'

import './CompactUserProfile.scss'

interface IProps {
  user: User
  allUsers: User[]
  backClick?: () => void

  isPreviewMode?: boolean

  common?: CommonStore
  tagsStore?: TagsStore
  eventsStore?: EventsStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  chatService?: ChatService
}

const edit = 'Edit'
const save = 'Save'
const call = 'Phone'
const message = 'Chat'
const _email = 'Email'
const userProfile = 'User Profile'
const myProfile = 'My Profile'
const PDF_NAME = 'profile.pdf'

const backIconSize = 20

@inject(
  'common',
  'tagsStore',
  'eventsStore',
  'userProjectsStore',
  'projectMembersStore',
  'chatService',
)
@observer
export default class CompactUserProfile extends React.Component<IProps> {
  private contentRef: HTMLDivElement = null
  @observable private isUserOnline = false
  public static defaultProps = {
    backClick: NOOP,
  }

  private readonly store: CompactUserProfileStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new CompactUserProfileStore(
      props.eventsStore,
      props.projectMembersStore,
      props.userProjectsStore,
      props.user,
      props.allUsers,
    )
  }

  public async componentDidMount() {
    if (this.props.chatService) {
      const onlineContacts = await this.props.chatService.getOnlineContacts()
      if (onlineContacts.includes(this.props.user.id)) {
        this.isUserOnline = true
      }
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.user.id !== prevProps.user.id) {
      this.store.setUser(this.props.user)
      this.store.itemsToEdit = [this.props.user]
      this.store.recreateFormStores()
      this.store.updateFormStores()
    }
  }

  public render() {
    const { user, isPreviewMode } = this.props
    const {
      isEditing,
      fields,
      isEmailLinkingDialogOpen,
      hideEmailLinkingDialog,
      isCurrentUser,
      isScanMaster,
    } = this.store
    const avatarField = fields.find(f => f.id === UserFieldId.AvatarUrl)

    return (
      <View className="compact-user-profile-container">
        {!isPreviewMode && <Header>{this.renderHeader()}</Header>}
        <EmailLinkerDialog
          isOpen={isEmailLinkingDialogOpen}
          onClose={hideEmailLinkingDialog}
          onComplete={hideEmailLinkingDialog}
        />
        <div className="wrapper" ref={ref => (this.contentRef = ref)}>
          {!isPreviewMode && this.renderSaveToPDFButton()}
          <div className="col x-center pt20">
            <UserProfilePreview
              user={user}
              avatarSize={AvatarSize.Enormous}
              isAvatarUpdateAllowed={isEditing}
              onAvatarChange={avatarField.onChange}
              newAvatarUrl={avatarField.value.url}
              isUserNameClickable={false}
              isOnline={this.isUserOnline}
              shouldSplitToRows={true}
            />
          </div>
          {!isEditing && !isCurrentUser && (
            <div className="row x-center mt20 handle-icons-container">
              {this.renderHandleIcons()}
            </div>
          )}
          <div
            className={classList({
              'col user-project-items': true,
              'user-project-items edit': isEditing,
            })}
          >
            {this.renderSections()}
            {isScanMaster && <QRCodeAccess user={user} className="pl20" />}
          </div>
        </div>
      </View>
    )
  }

  private renderSaveToPDFButton(): JSX.Element {
    const { isCurrentUser, isEditing } = this.store
    return (
      isCurrentUser &&
      !isEditing && (
        <div className="relative">
          <div className="save-to-pdf-button" onClick={this.onSaveToPDF}>
            <Icons.Pdf className="no-grow" />
          </div>
        </div>
      )
    )
  }

  private renderHeader(): JSX.Element {
    const {
      isEditing,
      cancelEditing,
      startEditing,
      isEditAllowed,
      isCurrentUser,
    } = this.store

    return (
      <PageActionHeader
        title={isCurrentUser ? myProfile : userProfile}
        customLeftComponent={
          <Icon
            iconSize={backIconSize}
            className="text large no-grow"
            icon={IconNames.CROSS}
            onClick={isEditing ? cancelEditing : this.props.backClick}
          />
        }
        customRightComponent={
          isEditAllowed && (
            <div
              className="row no-grow text extra-large blue-highlight"
              onClick={isEditing ? this.store.submit : startEditing}
            >
              {isEditing ? save : edit}
            </div>
          )
        }
      />
    )
  }

  private renderSections(): JSX.Element[] {
    const { getFieldsBySectionId, isEditing } = this.store
    return Object.values(InfoSectionId).map(sectionId => {
      const fields = getFieldsBySectionId(sectionId)

      if (!fields.length) {
        return null
      }

      const content = isEditing
        ? fields.map(this.renderEditField)
        : fields.map(this.renderField)

      return <React.Fragment key={sectionId}>{content}</React.Fragment>
    })
  }

  private renderEditField = (field: IAddEditDialogField): JSX.Element => {
    if (
      field.id === UserFieldId.AvatarUrl ||
      (field.id === UserFieldId.Email && !this.store.isCurrentUser)
    ) {
      return
    }

    return <FormFieldWrapper key={field.id} field={field} />
  }

  private renderField = ({
    id,
    value,
    label,
  }: IAddEditDialogField): JSX.Element => {
    if (this.excludedFields.includes(id)) {
      return
    }

    let content: any = null

    switch (id) {
      case UserFieldId.Email:
      case UserFieldId.PhoneNumber:
        content = value
        break
      case UserFieldId.Company:
        const company = this.getTagsByIds(id, value)
        content = company.length
          ? company[0].name
          : User.getDefaultCompanyName()
        break
      case UserFieldId.Teams:
      case UserFieldId.DefaultTeams:
      case UserFieldId.Roles:
      case UserFieldId.Trades:
        const tags = this.getTagsByIds(id, value)
        content =
          tags?.length &&
          tags?.map(tag => (
            <span className="mr8" key={tag.id}>
              <SitemapAttributeTag
                dataObject={tag as LocationAttributeBase}
                shouldShowAsTag={true}
                iconSize={17}
              >
                <span>{tag.name}</span>
              </SitemapAttributeTag>
            </span>
          ))
        break

      default:
        return null
    }

    return (
      <div key={id} className="row x-between y-center pt15 pb15">
        <span className="text large line-20">{label}</span>
        <span className="text extra-large line-24 end ml8 word-break-all">
          {content || NO_VALUE}
        </span>
      </div>
    )
  }

  private getTagsByIds = (id: any, value: any): ITag[] => {
    return this.props.tagsStore.getTagsByIds(fieldIdToTagTypeMap[id], value)
  }

  private renderHandleIcons(): JSX.Element[] {
    const { user, common } = this.props
    const { phoneNumber, email } = user

    const icons = [
      {
        title: _email,
        icon: <Icons.Email />,
        href: `mailto:${email}:`,
      },
      {
        title: call,
        icon: <Icons.Phone />,
        href: `tel:${phoneNumber}`,
      },
      {
        title: message,
        icon: <Icons.Chat />,
        onClick: common.handleOpeningChatWithUser.bind(null, user.id),
      },
    ]

    return icons.map(({ title, icon, href, onClick }) => (
      <a
        className="row x-center y-center user-actions"
        key={title}
        onClick={onClick}
        href={href}
      >
        <span className="no-grow icon">{icon}</span>
        <span className="text large line-extra-large no-grow item-name">
          {title}
        </span>
      </a>
    ))
  }

  private get isScanMaster(): boolean {
    const { userActiveProjectSettings } = this.props.eventsStore.appState

    return (
      userActiveProjectSettings?.isAdmin ||
      userActiveProjectSettings?.isScanMaster
    )
  }

  private onSaveToPDF = (): void => {
    const profile = new JsPDF('portrait', 'px', 'a4')
    profile.html(this.contentRef).then(() => {
      profile.save(PDF_NAME)
    })
  }

  private get excludedFields(): Array<string> {
    return [
      UserFieldId.AvatarUrl,
      UserFieldId.FirstName,
      UserFieldId.LastName,
      UserFieldId.Initials,
    ]
  }
}

import { ICompany, ICompanyAddress } from '~/client/graph'
import BaseModel from '~/client/src/shared/models/BaseModel'

export default class Company extends BaseModel<ICompany> implements ICompany {
  public static fromDto(dto: ICompany): Company {
    return new Company(
      dto.id,
      dto.name,
      dto.projectId,
      dto.typeTags,
      dto.code,
      dto.logoUrl,
      dto.avatarUrl,
      dto.businessEmail,
      dto.businessPhone,
      dto.responsibleContactIds,
      dto.roleIds,
      dto.tradeIds,
      dto.address,
      dto.reportsToId,
      dto.dba,
      dto.licenseNumber,
      dto.isDeleted,
    )
  }

  public static getNewCompanyDto(
    projectId: string,
    name: string = '',
  ): ICompany {
    return new Company(null, name, projectId).asJson
  }

  public responsibleContactIds: string[] = []
  public roleIds: string[] = []
  public tradeIds: string[] = []
  public typeTags: string[] = []
  public address: ICompanyAddress = {}

  public constructor(
    id: string,
    public name: string,
    public projectId: string,
    typeTags: string[] = [],
    public code: string = '',
    public logoUrl: string = '',
    public avatarUrl: string = '',
    public businessEmail: string = '',
    public businessPhone: string = '',
    responsibleContactIds: string[] = [],
    roleIds: string[] = [],
    tradeIds: string[] = [],
    address: ICompanyAddress = {},
    public reportsToId: string = null,
    public dba: string = '',
    public licenseNumber: string = '',
    public isDeleted: boolean = false,
  ) {
    super(id)

    this.responsibleContactIds = responsibleContactIds || []
    this.roleIds = roleIds || []
    this.tradeIds = tradeIds || []
    this.typeTags = typeTags || []
    this.address = address || {}
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MapBoxEditorStore, {
  BaseMapStyle,
} from '../../MapBoxEditor/MapBoxEditor.store'
import MapLayersMenu from './MapLayersMenu'

import './MapLayerSelector.scss'

// translated

interface IMapLayerSelectorProps {
  store: MapBoxEditorStore

  isFixed: boolean
  toggleAnnouncementsHiddenState?: () => void
  toggleDeliveriesHiddenState?: () => void
  togglePermitsHiddenState?: () => void
  toggleMonitoringsHiddenState?: () => void
  isLogisticsView?: boolean
  arePermitsHidden?: boolean
  areAnnouncementsHidden?: boolean
  areDeliveriesHidden?: boolean
  areMonitoringsHidden?: boolean

  isDeliveryView?: boolean
  areAdditionalMarkersAvailable?: boolean
}

const STREET_PATH = '/static/icons/mapbox-icons/map-styles/map-layer-street.png'
const SATELLITE_PATH =
  '/static/icons/mapbox-icons/map-styles/map-layer-satellite.png'

@observer
export default class MapLayerSelector extends React.Component<IMapLayerSelectorProps> {
  public render() {
    const {
      isFixed,
      store,
      toggleAnnouncementsHiddenState,
      toggleDeliveriesHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      isLogisticsView,
      arePermitsHidden,
      areAnnouncementsHidden,
      isDeliveryView,
      areDeliveriesHidden,
      areMonitoringsHidden,
      areAdditionalMarkersAvailable,
    } = this.props
    const { baseMap, isMoreMenuShown, hideMoreMenu } = store
    const isSatelliteMode = baseMap === BaseMapStyle.SATELLITE_STYLE_CODE

    return (
      <div
        className={classList({
          'layers-container row y-end': true,
          'above-show-on': isDeliveryView,
          satellite: !isSatelliteMode,
          street: isSatelliteMode,
          fixed: isFixed,
          absolute: !isFixed,
        })}
      >
        {this.renderSmallMenu()}
        <div className={classList({ hidden: !isMoreMenuShown })}>
          <MenuCloser closeMenu={hideMoreMenu}>
            <MapLayersMenu
              store={store}
              isLogisticsView={isLogisticsView}
              toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
              toggleAnnouncementsHiddenState={toggleAnnouncementsHiddenState}
              togglePermitsHiddenState={togglePermitsHiddenState}
              toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
              areDeliveriesHidden={areDeliveriesHidden}
              areAnnouncementsHidden={areAnnouncementsHidden}
              arePermitsHidden={arePermitsHidden}
              areMonitoringsHidden={areMonitoringsHidden}
              areAdditionalMarkersAvailable={areAdditionalMarkersAvailable}
            />
          </MenuCloser>
        </div>
      </div>
    )
  }

  private renderSmallMenu() {
    const {
      store: {
        baseMap,
        toggleMapStyle,
        toggleTraffic,
        isTrafficShown,
        isMoreMenuShown,
        toggleMoreMenu,
      },
    } = this.props
    const isSatelliteMode = baseMap === BaseMapStyle.SATELLITE_STYLE_CODE
    const imageSrc = isSatelliteMode ? STREET_PATH : SATELLITE_PATH

    return (
      <div className={classList({ row: true, hidden: isMoreMenuShown })}>
        <div
          className="col main-cell relative layers-container-holder"
          onClick={toggleMapStyle}
        >
          <img src={imageSrc} className="main-icon no-grow pointer" />
          <div className="main-text full-width">
            <div className="layers-text text center large absolute row full-width x-center">
              <Icons.MoreLayerStylesSmall className="no-grow more-layer-small-icon" />
              {Localization.translator.layers}
            </div>
            <div className="type-text text center large absolute full-width x-center">
              {isSatelliteMode
                ? Localization.translator.street
                : Localization.translator.satellite}
            </div>
          </div>
        </div>
        <div className="hidden-layer row ml10 brada10 bg-white pa10">
          <div
            className={classList({
              'col secondary-cell mx5 x-center pointer': true,
              active: isTrafficShown,
              'inactive-element': isSatelliteMode,
            })}
            onClick={toggleTraffic}
          >
            <Icons.MapLayerTraffic className="no-grow col secondary-cell-icon relative" />
            <div className="text large mode-text">
              {Localization.translator.traffic}
            </div>
          </div>
          <div
            className={classList({
              'col secondary-cell mx5 x-center pointer': true,
              active: isMoreMenuShown,
            })}
            onClick={toggleMoreMenu}
          >
            <Icons.MoreLayerStyles className="no-grow col" />
            <div className="text large mode-text">
              {Localization.translator.more}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import { observable } from 'mobx'

import { LocationType } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

import { areArraysEqual, copyArray } from '../../utils/util'
import IAccessibleLevelsAttribute from '../IAccessibleLevelsAttribute'
import LocationAttributeBase from './LocationAttributeBase'

export default class OffloadingEquipment extends LocationAttributeBase<OffloadingEquipment> {
  public static fromDto(dto: IAccessibleLevelsAttribute) {
    const item = LocationAttributeBase.factory(
      OffloadingEquipment,
      dto,
      SitemapAttributeIcon.Equipment,
    )

    item.accessibleLevels = dto.accessibleLevels || []

    return item
  }

  @observable public accessibleLevels: string[] = []

  public type = LocationType.OffloadingEquipment
  protected relatedFieldId = FieldIds.OFFLOADING_EQUIPMENT
  protected model = OffloadingEquipment

  public isEqual(dto: OffloadingEquipment) {
    return (
      super.isEqual(dto) &&
      areArraysEqual(this.accessibleLevels, dto.accessibleLevels)
    )
  }

  public copy(): OffloadingEquipment {
    const item = super.copy()
    item.accessibleLevels = copyArray(this.accessibleLevels || [])
    return item
  }

  public getDto(): IAccessibleLevelsAttribute {
    const dto = super.getDto() as IAccessibleLevelsAttribute
    dto.accessibleLevels = this.accessibleLevels || []
    return dto
  }
}

import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_PATH =
  'M8.673 19.786c.507 3.4 6.099 3.457 6.133-.57 0-1.888-1.802-3.235-3.597-2.744v-2.5c-1.46 0-1.412-.58-1.412-1.712h4.601c0 1.149.046 1.712-1.425 1.712v.675c2.098.462 3.771 2.299 3.771 4.535.034 6.05-8.797 6.458-9.35.688-.095-.981.487-1.532.732-1.492.142.023.38.28.547 1.408zm8.571-8.069H6.497l-.083-.237-2.361-6.812-.063-.18.119-.151L7.416.15l.106-.135H16.475l.107.143 3.306 4.437.112.15-.064.177-2.362 6.562-.084.233h-.246zm-4.518-.703l5.747-7.144-1.45-1.945-7.31 9.089h3.013zM14.278.719h-3.013l-5.479 6.81.908 2.618L14.278.719z'
const PATH_SCALE = 0.6
const ICON_SIZE = 20

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const CIRCLE_STROKE_WIDTH = 1
const ICON_OFFSET_X = 3
const ICON_OFFSET_Y = 4
const { getHEXColor } = ThemeMode

const KonvaEquipmentIcon = ({ color, children, ...rest }) => {
  const halfSize = ICON_SIZE / 2
  return (
    <Group {...rest} offsetX={ICON_SIZE / 2} offsetY={ICON_SIZE / 2}>
      {children}
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={CIRCLE_STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={ICON_PATH}
        fill={getHEXColor(Colors.neutral100)}
        scaleX={PATH_SCALE}
        scaleY={PATH_SCALE}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
      />
    </Group>
  )
}

export default KonvaEquipmentIcon

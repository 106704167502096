import * as React from 'react'

import { BaseLayer } from 'konva/types/BaseLayer'
import { KonvaEventObject } from 'konva/types/Node'
import { Stage } from 'konva/types/Stage'
import { observable } from 'mobx'
import { Observer, inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  Group,
  Image as KonvaImage,
  Layer,
  Stage as StageComponent,
} from 'react-konva'
import { AutoSizer } from 'react-virtualized'

import { IPosition, IProjectAddress, LocationType } from '~/client/graph'
import {
  IMapBoxItem,
  MapBoxEditor,
} from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor'
import EDITABLE_LABEL_ID from '~/client/src/shared/constants/editableLabel'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { NOOP } from '~/client/src/shared/utils/noop'

import Sitemap, { ISitemapGeoPosition } from '../../models/Sitemap'
import EventContext from '../../stores/EventStore/EventContext'
import EventsStore from '../../stores/EventStore/Events.store'
import BasemapsStore from '../../stores/domain/Basemaps.store'
import MapBoxEditorStore from '../MapBoxEditor/MapBoxEditor.store'
import SitemapItemBase from '../SitemapHelpers/models/SitemapItemBase'

import './BaseSitemap.scss'

export interface ISitemapSizes {
  width: number
  height: number
  offsetX: number
  offsetY: number
  offsetXImage: number
  offsetYImage: number
}
interface IDataUrlConfig {
  x?: number
  y?: number
  width?: number
  height?: number
  pixelRatio?: number
  mimeType?: string
  quality?: number
  callback?: (str: string) => void
}

export const SCALED_IMAGE_TYPE = 'image/png'
export const SCALED_IMAGE_QUALITY = 1
export const SCALED_IMAGE_PIXEL_RATE = 2
const MAX_SCALE = 10
const MIN_SCALE = 0.05
const DEFAULT_ZOOM_VALUE = 100
const CLOSED_ICON_SCALE = 1.4
const SELECTED_ICON_SCALE = 1.4
const ZOOM_SCALE = 0.95
const SPACE_KEY_CODE = 32
export const ITEMS_LAYER_ID = 'items-layer'

export interface IProps {
  zoom?: number
  sitemapUrl: string
  showOnMenu?: () => JSX.Element
  getEditableItem?: (
    width: number,
    height: number,
    stage: Stage,
  ) => React.ReactNode
  onClick?: (evt: KonvaEventObject<MouseEvent>) => void
  onTouch?: () => void
  children?: (bounds: {
    width: number
    height: number
    offsetX?: number
    offsetY?: number
    isMainSitemap?: boolean
    key?: string
  }) => React.ReactNode
  onMount?: () => void
  shouldUseFullHeight?: boolean
  isDraggable?: boolean
  isEditorMode?: boolean
  setSitemap?: (stage: Stage) => void

  isDraggingMode?: boolean
  selectedSitemap?: Sitemap
  opacity?: number
  mapBoxEditorStore?: MapBoxEditorStore

  saveSitemapItem?: (item: SitemapItemBase) => Promise<void>
  selectedSitemapItem?: SitemapItemBase
  creatableAttributeType?: LocationType
  mapBoxItems?: IMapBoxItem[]
  mapViewport?: ISitemapGeoPosition
  features?
  setViewport?: (viewport: ISitemapGeoPosition) => void

  shouldShowImage?: boolean
  isImageRubberMode?: boolean

  sourceBounds?: number[][]
  stage?: BaseLayer

  toggleAnnouncementsHiddenState?: () => void
  toggleDeliveriesHiddenState?: () => void
  togglePermitsHiddenState?: () => void
  toggleMonitoringsHiddenState?: () => void
  isLogisticsView?: boolean
  arePermitsHidden?: boolean
  areAnnouncementsHidden?: boolean
  areDeliveriesHidden?: boolean
  areMonitoringsHidden?: boolean

  shouldHideControls?: boolean
  isAdditional?: boolean
  basemapsStore?: BasemapsStore
  renderSecondaryChildren?: (
    width: number,
    height: number,
    key: string,
    isMainSitemap?: boolean,
  ) => JSX.Element

  isDeliveryView?: boolean
  projectAddress: IProjectAddress
  isFirstGeoreferencing?: boolean
  isRubberMode?: boolean
  isTextStickerCreationActive?: boolean
  areAdditionalMarkersAvailable?: boolean

  eventsStore?: EventsStore
}

// TODO: create store and move there all the logic to make the component standalone library and make it easy to understand
@inject('eventsStore')
@observer
export default class BaseSitemap extends React.Component<IProps> {
  public static defaultProps = {
    onMount: NOOP,
  }
  @observable private sitemapImage: HTMLImageElement
  @observable private sitemapImages = observable(
    new Map<string, HTMLImageElement>(),
  )
  @observable private isScrollingByDraggingAllowed: boolean = false
  @observable private isScrollingByDraggingActive: boolean = false
  @observable private lastDist: number = 0
  @observable private lastCenter: IPosition = null
  @observable private isSingleTouchEvent: boolean = true

  private clearPostEventCallbackItems: () => void
  private stage: Stage
  private imageLoadingPromise: Promise<void>

  public constructor(props: IProps) {
    super(props)
    this.clearPostEventCallbackItems = props.eventsStore.addPostEventCallback(
      this.onSitemapItemUpdate,
    )
  }

  public componentDidMount() {
    this.loadSitemap()
    this.props.onMount()
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
    document.addEventListener('mouseup', this.onMouseUp)
    this.adjustSitemapItems()
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
    document.removeEventListener('mouseup', this.onMouseUp)

    if (this.clearPostEventCallbackItems) {
      this.clearPostEventCallbackItems()
    }
  }

  public componentDidUpdate(oldProps: IProps) {
    this.clearCanvasCache()
    if (oldProps.sitemapUrl !== this.props.sitemapUrl) {
      this.sitemapImage = null
      this.loadSitemap()
    }

    if (this.props.isImageRubberMode !== oldProps.isImageRubberMode) {
      this.setZeroPosition()
      this.resize(1)
    } else {
      this.applyDefaultZoom(oldProps?.zoom)
    }
    this.adjustSitemapItems()
  }

  public getDataURL = (): Promise<string> => {
    return this.createImage(false)
  }

  public getItemsDataURL = (): Promise<string> => {
    return this.createImage(true)
  }

  public render(): JSX.Element {
    if (!this.sitemapImage) {
      return null
    }

    const {
      zoom,
      children,
      onClick,
      getEditableItem,
      isDraggable,
      showOnMenu,
      isEditorMode,

      isDraggingMode,
      selectedSitemap,
      opacity,
      mapBoxEditorStore,
      mapBoxItems,
      mapViewport,
      features,
      shouldShowImage,
      shouldUseFullHeight,
      toggleAnnouncementsHiddenState,
      toggleDeliveriesHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      isLogisticsView,
      arePermitsHidden,
      areAnnouncementsHidden,
      areDeliveriesHidden,
      areMonitoringsHidden,
      shouldHideControls,
      isDeliveryView,
      projectAddress,
      saveSitemapItem,
      selectedSitemapItem,
      creatableAttributeType,
      isTextStickerCreationActive,
      areAdditionalMarkersAvailable,
    } = this.props

    return (
      <div className="full-width full-height relative base-sitemap">
        {showOnMenu && (
          <div className="absolute show-on-holder brada10 row relative">
            {showOnMenu()}
          </div>
        )}
        <div
          className={classList({
            'delivery-sitemap full-width full-height': true,
            'overflow-hidden': !isEditorMode,
          })}
        >
          <AutoSizer>
            {({ width: containerWidth, height: containerHeight }) => (
              <Observer>
                {() => {
                  const { offsetX, offsetY, width, height } =
                    this.getSitemapSizes(containerHeight, containerWidth)

                  const { isRubberMode } = mapBoxEditorStore
                  const viewport = isDraggingMode
                    ? mapBoxEditorStore.viewport
                    : mapViewport

                  return (
                    <>
                      {/* unreferenced map */}
                      {(isRubberMode || !selectedSitemap?.isReferenced) && (
                        <StageComponent
                          width={containerWidth}
                          height={containerHeight}
                          onClick={onClick}
                          onWheel={!zoom && this.handleZooming}
                          onTouchMove={!zoom && this.handleMultiTouch}
                          onTouchEnd={!zoom && this.handleMultiTouchEnd}
                          draggable={isDraggable}
                          ref={this.setStage}
                        >
                          <Layer
                            id={ITEMS_LAYER_ID}
                            offsetX={-offsetX}
                            offsetY={-offsetY}
                          >
                            <KonvaImage
                              width={width}
                              height={height}
                              image={this.sitemapImage}
                            />
                            <Group>
                              {children &&
                                children({
                                  width,
                                  height,
                                  offsetX: -offsetX,
                                  offsetY: -offsetY,
                                })}
                            </Group>
                          </Layer>
                        </StageComponent>
                      )}
                      {/* referenced maps */}
                      {this.sitemapImage &&
                        containerHeight &&
                        containerWidth &&
                        (isDraggingMode || selectedSitemap?.isReferenced) && (
                          <div
                            style={{
                              opacity: isRubberMode ? opacity / 100 : 100,
                              position: 'absolute',
                              zIndex: 1,
                              top: 0,
                            }}
                          >
                            <MapBoxEditor
                              isDeliveryView={isDeliveryView}
                              isFixed={shouldUseFullHeight}
                              store={mapBoxEditorStore}
                              saveSitemapItem={saveSitemapItem}
                              selectedSitemapItem={selectedSitemapItem}
                              creatableAttributeType={creatableAttributeType}
                              isTextStickerCreationActive={
                                isTextStickerCreationActive
                              }
                              viewport={viewport}
                              setViewport={this.setViewport}
                              markersArray={mapBoxItems}
                              selectedSitemap={selectedSitemap}
                              shouldHideControls={
                                !isDraggingMode ||
                                shouldUseFullHeight ||
                                shouldHideControls
                              }
                              width={width}
                              height={height}
                              offsetY={-offsetY}
                              offsetX={-offsetX}
                              containerWidth={containerWidth}
                              containerHeight={containerHeight}
                              isDraggingMode={isDraggingMode}
                              features={features}
                              sourceBounds={mapBoxEditorStore.sourceBounds}
                              sourceGeoCornersMap={
                                mapBoxEditorStore.additionalSourcesBoundsMap
                              }
                              isRubberMode={isRubberMode}
                              shouldShowImage={!isRubberMode && shouldShowImage}
                              layer={this.renderLayer}
                              toggleAnnouncementsHiddenState={
                                toggleAnnouncementsHiddenState
                              }
                              toggleDeliveriesHiddenState={
                                toggleDeliveriesHiddenState
                              }
                              togglePermitsHiddenState={
                                togglePermitsHiddenState
                              }
                              toggleMonitoringsHiddenState={
                                toggleMonitoringsHiddenState
                              }
                              isLogisticsView={isLogisticsView}
                              arePermitsHidden={arePermitsHidden}
                              areAnnouncementsHidden={areAnnouncementsHidden}
                              areMonitoringsHidden={areMonitoringsHidden}
                              areDeliveriesHidden={areDeliveriesHidden}
                              latitude={projectAddress.center?.lat}
                              longitude={projectAddress.center?.lng}
                              areAdditionalMarkersAvailable={
                                areAdditionalMarkersAvailable
                              }
                            />
                          </div>
                        )}
                      {getEditableItem &&
                        getEditableItem(width, height, this.stage)}
                    </>
                  )
                }}
              </Observer>
            )}
          </AutoSizer>
        </div>
        <div
          className={classList({
            'unclickable-element transparent':
              !this.isScrollingByDraggingAllowed,
            'absolute grab-area full-width full-height':
              this.isScrollingByDraggingAllowed,
            'grabbing-cursor': this.isScrollingByDraggingActive,
          })}
          onMouseDown={this.onGrabAreaMouseDown}
          onMouseMove={this.onGrabAreaMouseMove}
        />
      </div>
    )
  }

  // single map renderer [mapbox]
  private renderLayer = (
    containerWidth: number,
    containerHeight: number,
    width: number,
    height: number,
    rotation: number,
    positionX: number,
    positionY: number,
    id: string,
    isMainLayer?: boolean,
  ): JSX.Element => {
    const { zoom, children, onClick, opacity } = this.props

    return (
      <div
        style={{
          position: 'absolute',
          zIndex: isMainLayer ? 2 : 1,
          top: 0,
          width: '100%',
          height: '100%',
        }}
        key={id}
      >
        <StageComponent
          width={isMainLayer ? containerWidth : containerWidth}
          height={isMainLayer ? containerHeight : containerHeight}
          onClick={onClick}
          onWheel={!zoom && this.handleZooming}
          onTouchMove={!zoom && this.handleMultiTouch}
          onTouchEnd={!zoom && this.handleMultiTouchEnd}
          ref={ref =>
            isMainLayer ? this.setStage(ref) : this.setSecondaryStage(ref, id)
          }
        >
          <Layer id={ITEMS_LAYER_ID}>
            <KonvaImage
              x={positionX}
              y={positionY}
              rotation={rotation}
              width={width}
              height={height}
              image={this.sitemapImages.get(id)}
              opacity={(100 - opacity) / 100}
            />
            <Group x={positionX} y={positionY} rotation={rotation}>
              {children &&
                children({
                  width,
                  height,
                  isMainSitemap: isMainLayer,
                  key: id,
                })}
            </Group>
          </Layer>
        </StageComponent>
      </div>
    )
  }

  private setViewport = (viewport: ISitemapGeoPosition): void => {
    if (!this.props?.mapBoxEditorStore.isEditableItemInDragMode) {
      this.props.setViewport(viewport)
    }
  }

  private setSecondaryStage = (ref, id: string): void => {
    if (this.props.selectedSitemap.id === id) {
      this.stage = ref
      if (this.props.setSitemap && ref) {
        this.props.setSitemap(this.stage)
        this.props.mapBoxEditorStore.updateMapboxInfo()
      }
    }
  }

  private setStage = ref => {
    // Issue with updating stage
    this.stage = ref

    if (this.props.setSitemap && ref) {
      this.props.setSitemap(this.stage)
      this.props.mapBoxEditorStore.updateMapboxInfo()
    }
  }

  private loadSitemap(): void {
    this.imageLoadingPromise = new Promise(resolve => {
      const image = document.createElement('img')
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.props.sitemapUrl
      this.props.mapBoxEditorStore.sitemapsOnGlobe.forEach(sitemap => {
        const globeImage = document.createElement('img')
        globeImage.setAttribute('crossOrigin', 'anonymous')
        const basemap = this.props.basemapsStore.byId.get(sitemap.basemapId)

        if (basemap?.source) {
          globeImage.src = basemap.source

          globeImage.onload = () => {
            this.sitemapImages.set(sitemap.id, globeImage)
            resolve()
          }
        }
      })
      image.onload = () => {
        this.sitemapImage = image
        resolve()
        this.imageLoadingPromise = null
        this.setZeroPosition()
        this.resize(1)
        if (this.props.setSitemap && this.stage) {
          this.props.setSitemap(this.stage)
          this.props.mapBoxEditorStore.updateMapboxInfo()
        }
      }
    })
  }

  private onKeyDown = (event: KeyboardEvent): void => {
    // EditableLabel component triggers this as well but it needs to be allowed to put space
    const target: Element = event.target as Element
    if (target.id === EDITABLE_LABEL_ID) {
      return
    }
    switch (event.keyCode) {
      case SPACE_KEY_CODE:
        this.isScrollingByDraggingAllowed = true
        break
    }
  }

  private onKeyUp = (event: KeyboardEvent): void => {
    switch (event.keyCode) {
      case SPACE_KEY_CODE:
        this.isScrollingByDraggingAllowed = false
        this.isScrollingByDraggingActive = false
        break
    }
  }

  private onMouseUp = (): void => {
    this.isScrollingByDraggingActive = false
  }

  private onGrabAreaMouseDown = (): void => {
    if (!this.props.shouldShowImage) {
      this.isScrollingByDraggingActive = true
    }
  }

  private onGrabAreaMouseMove = (
    event: React.MouseEvent<HTMLDivElement>,
  ): void => {
    if (this.props.shouldShowImage) {
      return
    }
    if (
      !this.isScrollingByDraggingAllowed ||
      !this.isScrollingByDraggingActive ||
      !this.stage
    ) {
      return
    }
    const stage = this.stage.getStage()
    const scrollLeft = stage.x() + event.movementX
    const scrollTop = stage.y() + event.movementY

    stage.x(scrollLeft)
    stage.y(scrollTop)
    stage.batchDraw()
  }

  private adjustSitemapItems = (): void => {
    if (this.stage) {
      this.resize(this.stage.getStage().scaleX(), null, false, true)
    }
  }

  // canvas zooming handling
  private handleZooming = (event: KonvaEventObject<WheelEvent>): void => {
    if (this.props.shouldShowImage) {
      return
    }
    event.evt.preventDefault()

    const stage = this.stage.getStage()
    const oldScale = stage.scaleX()
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    }

    const newScale =
      event.evt.deltaY > 0 ? oldScale * ZOOM_SCALE : oldScale / ZOOM_SCALE

    const newPosition = {
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    }

    this.resize(newScale, newPosition)
  }

  // reset canvas zooming
  private setZeroPosition(): void {
    if (!this.stage) {
      return
    }
    const stage = this.stage.getStage()
    stage.x(0)
    stage.y(0)
  }

  // canvas multi touch like mobile finger action
  private handleMultiTouch = (event: KonvaEventObject<TouchEvent>): void => {
    if (this.props.shouldShowImage) {
      return
    }
    event.evt.preventDefault()
    const stage = this.stage.getStage()
    // in order for react konva to work properly with multi touch dragging should be disabled
    stage.stopDrag()

    const { touches } = event.evt

    if (touches[0] && touches[1]) {
      this.isSingleTouchEvent = false
      const point1 = {
        x: touches[0].clientX,
        y: touches[0].clientY,
      }
      const point2 = {
        x: touches[1].clientX,
        y: touches[1].clientY,
      }
      const newCenter = this.getCenter(point1, point2)

      if (!this.lastCenter) {
        this.lastCenter = newCenter
        return
      }

      const dist = this.getDistance(point1, point2)
      if (!this.lastDist) {
        this.lastDist = dist
      }
      const scale = stage.scaleX() * (dist / this.lastDist)
      const offsetX = newCenter.x - this.lastCenter.x
      const offsetY = newCenter.y - this.lastCenter.y

      const pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      }
      const newPosition = {
        x: newCenter.x - pointTo.x * scale + offsetX,
        y: newCenter.y - pointTo.y * scale + offsetY,
      }

      this.resize(scale, newPosition)

      this.lastDist = dist
      this.lastCenter = newCenter
    } else {
      stage.startDrag()
    }
  }

  private handleMultiTouchEnd = (): void => {
    if (this.props.shouldShowImage) {
      return
    }
    const { onTouch } = this.props
    this.lastDist = 0
    this.lastCenter = null

    if (this.isSingleTouchEvent && onTouch) {
      onTouch()
    }
    this.isSingleTouchEvent = true
  }

  private getDistance(point1: IPosition, point2: IPosition): number {
    return Math.sqrt(
      Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2),
    )
  }

  private getCenter(point1: IPosition, point2: IPosition): IPosition {
    return {
      x: (point1.x + point2.x) / 2,
      y: (point1.y + point2.y) / 2,
    }
  }

  // canvas resize
  private resize(
    newScale: number,
    newPosition?: IPosition,
    shouldOnlyFirstLayer?: boolean,
    shouldIgnoreStage?: boolean,
  ): void {
    if (this.props.shouldShowImage) {
      return
    }
    if (newScale > MAX_SCALE || newScale < MIN_SCALE || !this.stage) {
      return
    }

    const stage = this.stage.getStage()

    let sitemapItemLayers = stage.getLayers()[0].children[1].children.toArray()

    if (shouldOnlyFirstLayer) {
      sitemapItemLayers = sitemapItemLayers.slice(0, 1)
    }

    if (!shouldIgnoreStage) {
      // resize stage
      stage.scaleX(newScale)
      stage.scaleY(newScale)
    }

    // resize stage items
    sitemapItemLayers.forEach(layer => {
      layer.children
        .toArray()
        .filter(child => !child.attrs.isStaticSize)
        .forEach(child => {
          let baseScale = 1
          if (child.attrs.isClosedIcon) {
            baseScale = CLOSED_ICON_SCALE
          } else if (child.attrs.isSelected) {
            baseScale = SELECTED_ICON_SCALE
          }

          child.scaleY(baseScale / newScale)
          child.scaleX(baseScale / newScale)
        })
    })

    if (newPosition) {
      stage.position(newPosition)
    }
    stage.batchDraw()
  }

  private clearCanvasCache(): void {
    const stage = this.stage?.getStage()
    if (!stage) {
      return
    }
    stage.clearCache()
    stage.bufferCanvas.pixelRatio = 1
    stage.bufferCanvas.isCache = false
    stage.bufferCanvas.width = 0
    stage.bufferCanvas.height = 0
  }

  private applyDefaultZoom = (oldZoom: number): void => {
    const { zoom } = this.props
    if (!zoom) {
      return
    }

    this.resize(zoom / DEFAULT_ZOOM_VALUE, null, true)

    if (oldZoom !== zoom && zoom === DEFAULT_ZOOM_VALUE) {
      this.setZeroPosition()
    }
  }

  private getSitemapSizes(
    containerHeight: number,
    containerWidth: number,
  ): ISitemapSizes {
    const { naturalWidth, naturalHeight } = this.sitemapImage
    const { shouldUseFullHeight, mapBoxEditorStore } = this.props
    const imageScale = shouldUseFullHeight
      ? Math.min(1, containerHeight / naturalHeight)
      : Math.min(containerHeight / naturalHeight, containerWidth / naturalWidth)

    const width = naturalWidth * imageScale
    const height = naturalHeight * imageScale

    const offsetX = shouldUseFullHeight
      ? (containerWidth - width) / 2
      : Math.max(0, (containerWidth - width) / 2)

    const offsetY = shouldUseFullHeight
      ? (containerHeight - height) / 2
      : Math.max(0, (containerHeight - height) / 2)

    const offsetXImage = Math.max(0, (containerWidth - width) / 2)
    const offsetYImage = Math.max(0, (containerHeight - height) / 2)
    mapBoxEditorStore.width = width
    mapBoxEditorStore.height = height
    mapBoxEditorStore.offsetX = offsetX
    mapBoxEditorStore.offsetY = offsetY

    return {
      offsetX,
      offsetY,
      width,
      height,
      offsetXImage,
      offsetYImage,
    }
  }

  private onSitemapItemUpdate = async (
    eventContext: EventContext,
  ): Promise<void> => {
    const [eventType] = eventContext.event

    if (
      (eventType === e.SITEMAP_ITEMS_RECIEVED ||
        eventType === e.SITEMAP_ITEM_UPDATED ||
        eventType === e.SITEMAP_UPDATED ||
        eventType === e.SAVE_SITEMAP_ITEM_SUCCESS) &&
      this.stage
    ) {
      this.props.mapBoxEditorStore.setItems()
    }
  }

  private get baseConfig(): IDataUrlConfig {
    const { isReferenced } = this.props.selectedSitemap
    const layer = this.stage.getStage().findOne(`#${ITEMS_LAYER_ID}`)
    let width
    let height

    if (isReferenced) {
      const referencedLayer = layer.children[0]

      width = referencedLayer.width()
      height = referencedLayer.height()
    } else {
      const scale = this.stage.getStage().scaleX()
      const offsetX = layer.attrs.offsetX
      const offsetY = layer.attrs.offsetY

      width = (layer.width() + offsetX * 2) * scale
      height = (layer.height() + offsetY * 2) * scale
    }

    return {
      mimeType: SCALED_IMAGE_TYPE,
      quality: SCALED_IMAGE_QUALITY,
      pixelRatio: SCALED_IMAGE_PIXEL_RATE,
      width,
      height,
    } as IDataUrlConfig
  }

  private createImage = async (
    shouldHideBackground: boolean,
  ): Promise<string> => {
    if (!this.stage) {
      return null
    }
    if (this.imageLoadingPromise) {
      await this.imageLoadingPromise
    }

    const {
      mapBoxEditorStore: {
        imageLoadingPromise,
        isMapboxHidden,
        createMapImage,
        viewport,
      },
      selectedSitemap,
    } = this.props

    if (imageLoadingPromise) {
      await imageLoadingPromise
    }

    if (selectedSitemap.isReferenced && isMapboxHidden) {
      return createMapImage(shouldHideBackground)
    }

    const layer = this.stage.getStage().findOne(`#${ITEMS_LAYER_ID}`).getLayer()
      .children[0]

    layer.rotate(viewport.bearing - selectedSitemap.bearing)
    const image = layer.toDataURL(Object.assign({}, this.baseConfig))

    return createMapImage(shouldHideBackground, image)
  }
}

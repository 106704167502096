import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_HEIGHT = 20
const ICON_WIDTH = 20

const ICON_OFFSET_X = 1.8
const ICON_OFFSET_Y = 1.8
const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const ICON_SCALE = 0.8

const ICON_PATH =
  'M16.3581 18.4961C15.8164 18.4961 15.3303 18.3398 14.8997 18.0273C14.4692 17.7148 14.1706 17.3155 14.0039 16.8294H9.69141C8.77474 16.8294 7.99002 16.503 7.33724 15.8503C6.68446 15.1975 6.35807 14.4128 6.35807 13.4961C6.35807 12.5794 6.68446 11.7947 7.33724 11.1419C7.99002 10.4891 8.77474 10.1628 9.69141 10.1628H11.3581C11.8164 10.1628 12.2088 9.99957 12.5352 9.67318C12.8615 9.34679 13.0247 8.95443 13.0247 8.49609C13.0247 8.03776 12.8615 7.6454 12.5352 7.31901C12.2088 6.99262 11.8164 6.82943 11.3581 6.82943H7.04557C6.86502 7.31554 6.56293 7.71484 6.13932 8.02734C5.71571 8.33984 5.23307 8.49609 4.69141 8.49609C3.99696 8.49609 3.40668 8.25304 2.92057 7.76693C2.43446 7.28082 2.19141 6.69054 2.19141 5.99609C2.19141 5.30165 2.43446 4.71137 2.92057 4.22526C3.40668 3.73915 3.99696 3.49609 4.69141 3.49609C5.23307 3.49609 5.71571 3.65234 6.13932 3.96484C6.56293 4.27734 6.86502 4.67665 7.04557 5.16276H11.3581C12.2747 5.16276 13.0595 5.48915 13.7122 6.14193C14.365 6.7947 14.6914 7.57943 14.6914 8.49609C14.6914 9.41276 14.365 10.1975 13.7122 10.8503C13.0595 11.503 12.2747 11.8294 11.3581 11.8294H9.69141C9.23307 11.8294 8.84071 11.9926 8.51432 12.319C8.18793 12.6454 8.02474 13.0378 8.02474 13.4961C8.02474 13.9544 8.18793 14.3468 8.51432 14.6732C8.84071 14.9996 9.23307 15.1628 9.69141 15.1628H14.0039C14.1845 14.6766 14.4865 14.2773 14.9102 13.9648C15.3338 13.6523 15.8164 13.4961 16.3581 13.4961C17.0525 13.4961 17.6428 13.7391 18.1289 14.2253C18.615 14.7114 18.8581 15.3016 18.8581 15.9961C18.8581 16.6905 18.615 17.2808 18.1289 17.7669C17.6428 18.253 17.0525 18.4961 16.3581 18.4961ZM4.69141 6.82943C4.92752 6.82943 5.12543 6.74957 5.28516 6.58984C5.44488 6.43012 5.52474 6.2322 5.52474 5.99609C5.52474 5.75998 5.44488 5.56207 5.28516 5.40234C5.12543 5.24262 4.92752 5.16276 4.69141 5.16276C4.4553 5.16276 4.25738 5.24262 4.09766 5.40234C3.93793 5.56207 3.85807 5.75998 3.85807 5.99609C3.85807 6.2322 3.93793 6.43012 4.09766 6.58984C4.25738 6.74957 4.4553 6.82943 4.69141 6.82943Z'
const STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaInteriorPathIcon = ({ color, ...rest }) => {
  const halfSize = ICON_HEIGHT / 2
  return (
    <Group {...rest} offsetX={ICON_WIDTH / 2} offsetY={ICON_HEIGHT / 2}>
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={ICON_PATH}
        fill={getHEXColor(Colors.neutral100)}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        scaleX={ICON_SCALE}
        scaleY={ICON_SCALE}
      />
    </Group>
  )
}

export default KonvaInteriorPathIcon

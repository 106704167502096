import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Checkbox from '~/client/src/shared/components/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import SuperFilterStore from '~/client/src/shared/stores/ui/SuperFilter.store'

import { AdditionalReportFile } from '../HeaderBar.store'

import './ReportModal.scss'

// localization: translated

interface IProps {
  startDate: Date
  endDate: Date
  reportName: string
  additionalReportFiles?: AdditionalReportFile[]
  filterStoresByTypeMap: {
    [filterType: string]: SuperFilterStore
  }
  toggleAdditionalReportFiles?: (ext: string) => void
  groupedBy?: string
  renderButton: () => JSX.Element
  getIconByFilter: (filterType: string) => JSX.Element
  toggleModal: () => void
  specificFilterTypes?: string[]
  handleSpicificFilterType?: (filterType: string, index: number) => void
  projectDateStore?: ProjectDateStore
  state?: DesktopInitialState
}

@inject('state', 'projectDateStore')
@observer
export default class ReportModal extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)
  }

  @computed
  private get mainContentStyle() {
    const additionalReportFilesLength =
      this.props.additionalReportFiles?.length || 0
    return {
      height: `calc(100% - ${130 + additionalReportFilesLength * 30}px)`,
    }
  }

  public render() {
    const {
      startDate,
      endDate,
      renderButton,
      toggleModal,
      reportName,
      additionalReportFiles,
      groupedBy,
    } = this.props
    const { name } = this.props.state.activeProject
    const { getMonthAndDayToDisplay } = this.props.projectDateStore

    const startProjectDate = getMonthAndDayToDisplay(startDate)
    const endProjectDate = getMonthAndDayToDisplay(endDate)
    return (
      <div className="delivery-report-container fixed-modal-container dark-background">
        <MenuCloser isOpen closeMenu={toggleModal}>
          <div className="fixed delivery-report-modal fixed-modal-base">
            <div className="row x-end pointer pt10 pr10" onClick={toggleModal}>
              <Icons.Cross className="no-grow" />
            </div>
            <div
              style={this.mainContentStyle}
              className="col brada10 pb20 pt10 px50 filter-modal-main-content"
            >
              <div className="modal-header-text row no-grow">
                <Icons.StruxHubFull className="no-grow" />
              </div>
              <div className="col mt20 pb40 no-grow">
                <div className="text size40 lpMinus05 left line-46 bold ">
                  {reportName}
                </div>
                <div className="text left lp015 size22 bold line-29">
                  {name}
                </div>
              </div>
              <div className="scrollable">
                <div className="col mb20 mt30 no-grow">
                  <div className="text orange size22 bold left line-33 mb20">
                    {Localization.translator.reportDetails}
                  </div>
                  <div className="row x-between mb10">
                    <div className="row bold center lp15 row text uppercase medium line-26">
                      <Icons.Calendar className="no-grow" />
                      {Localization.translator.date}
                    </div>
                    <div className="row filters-date-details lp035 center lp15 row text w-fit-content extra-large line-26 center lp15">
                      {Localization.translator.xDateToYDate(
                        startProjectDate,
                        endProjectDate,
                      )}
                    </div>
                  </div>
                </div>
                <div className="text orange size22 bold left mb10 no-grow">
                  {Localization.translator.filters}
                </div>
                {this.renderReportFilters()}
                <div className="text orange size22 bold left mb10 mt10 no-grow">
                  {Localization.translator.groupBy}
                </div>
                <div className="row center text line-extra-large footer-text pb20 extra-large lp035">
                  {groupedBy}
                </div>
              </div>
            </div>
            <div className="col brada10 pb20 px50">
              <div className="row center text line-extra-large footer-text pb20 extra-large lp035">
                {
                  Localization.translator
                    .aReportWillBeGeneratedWithTheAboveFilters
                }
              </div>

              {!!additionalReportFiles?.length && (
                <div className="col text line-extra-large footer-text pb20 extra-large lp035">
                  {additionalReportFiles.map(file => (
                    <Checkbox
                      key={file.value}
                      label={file.label}
                      isChecked={file.isChecked}
                      onClick={this.props.toggleAdditionalReportFiles?.bind(
                        this,
                        file.value,
                      )}
                    />
                  ))}
                </div>
              )}
              <div className="row x-end">{renderButton()}</div>
            </div>
          </div>
        </MenuCloser>
      </div>
    )
  }

  private renderReportFilters() {
    const {
      filterStoresByTypeMap,
      getIconByFilter,
      specificFilterTypes,
      handleSpicificFilterType,
    } = this.props

    return (
      <div>
        {Object.keys(filterStoresByTypeMap)
          .filter(
            filterType =>
              !filterStoresByTypeMap[filterType].areAllOptionsSelected,
          )
          .map((filterType, index) => {
            if (specificFilterTypes?.includes(filterType)) {
              return handleSpicificFilterType(filterType, index)
            }
            const filterStore = filterStoresByTypeMap[filterType]
            const icon = getIconByFilter(filterType)
            return (
              <div key={filterType} className="row">
                <div className="text large orange mr20 no-grow">
                  {`${index + 1}.`}
                </div>
                <div className="filters-row row">
                  {icon}
                  {filterStore.typeCaption}
                </div>
                <div className="x-end row filters-date-details lp15 end lp15 row text line-26 filters-row">
                  {filterStore.appliedOptionsNames.join(', ')}
                </div>
              </div>
            )
          })}
      </div>
    )
  }
}

import {
  IDefaultPermitType,
  IInspectionOptions,
  IPermitType,
  IWorkflowStep,
  WorkflowStepType,
} from '~/client/graph'

import Localization from '../localization/LocalizationManager'
import BaseModel from './BaseModel'

export enum DefaultPermitType {
  Hotwork = 'hotwork',
  Ladder = 'ladder',
  EnergizedRoom = 'energized',
  Aerial = 'aerial',
  FireExtinguisherInspection = 'fireExtinguisher',
  SurfacePenetration = 'surfacePenetration',
  InteriorConcretePenetration = 'interiorConcretePenetration',
  OffRoadHeavyEquipment = 'offRoadHeavyEquipment',
  EmissionForm = 'emission',
  MaterialTransfer = 'materialTransfer',
}

export enum InstructionListType {
  Enumerated = 'enumerated',
  Bulleted = 'bulleted',
  None = 'none',
}

export function getInstructionListTypeTranslate(value: InstructionListType) {
  switch (value) {
    case InstructionListType.Bulleted:
      return Localization.translator.instructionListTypes.bulleted
    case InstructionListType.Enumerated:
      return Localization.translator.instructionListTypes.enumerated
    case InstructionListType.None:
      return Localization.translator.instructionListTypes.none
    default:
      throw new Error(value + ' is unhandled')
  }
}

export default class PermitType
  extends BaseModel<IPermitType>
  implements IPermitType
{
  public static fromDefault(dto: IDefaultPermitType): PermitType {
    return new PermitType(
      dto.id,
      dto.type,
      dto.name,
      null,
      dto.isEnabled,
      null,
      dto.orderIndex,
      dto.workflowSteps,
      dto.isAutoActivationEnabled,
      dto.inspectionOptions,
      null,
      false,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  public static fromDto(dto: IPermitType): PermitType {
    return new PermitType(
      dto.id,
      dto.type,
      dto.name,
      dto.projectId,
      dto.isEnabled,
      dto.basedOn,
      dto.orderIndex,
      dto.workflowSteps,
      dto.isAutoActivationEnabled,
      dto.inspectionOptions,
      dto.shouldBlockOnNonWorkTimes,
      dto.isDeleted,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  public orderIndex: number
  public workflowSteps: IWorkflowStep[]
  public shouldBlockOnNonWorkTimes: boolean

  public constructor(
    id: string,
    public type: string,
    public name: string,
    public projectId: string,
    public isEnabled: boolean,
    public basedOn: string,
    orderIndex: number,
    workflowSteps: IWorkflowStep[],
    public isAutoActivationEnabled: boolean,
    public inspectionOptions?: IInspectionOptions,
    shouldBlockOnNonWorkTimes?: boolean,
    public isDeleted?: boolean,
    createdAt: number = 0,
    updatedAt: number = 0,
  ) {
    super(id)
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)

    this.orderIndex = orderIndex || 0
    this.shouldBlockOnNonWorkTimes = shouldBlockOnNonWorkTimes || false

    this.workflowSteps = workflowSteps || []
  }

  // available within all Projects per Tenant
  public get isDefault(): boolean {
    return !this.projectId
  }

  public get isInspectionType(): boolean {
    return !!this.inspectionOptions
  }

  public get inspectionFrequency(): number {
    if (!this.isInspectionType) {
      return 0
    }

    return this.inspectionOptions.inspectionFrequency
  }

  public get hasStartStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Start)
  }

  public get hasBicInspectionStep(): boolean {
    return this.workflowSteps.some(
      s => s.type === WorkflowStepType.BicInspection,
    )
  }

  public get hasApprovalStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Approval)
  }

  public get isApprovalLast(): boolean {
    return this.lastStep?.type === WorkflowStepType.Approval
  }

  public get isEmissionFormType(): boolean {
    return this.type.includes(DefaultPermitType.EmissionForm)
  }

  public get isMaterialTransfer(): boolean {
    return this.type === DefaultPermitType.MaterialTransfer
  }

  public get initialStep(): IWorkflowStep {
    return this.workflowSteps[0]
  }

  public get lastStep(): IWorkflowStep {
    return this.workflowSteps[this.workflowSteps.length - 1]
  }

  public get stepsWithoutRecurring(): IWorkflowStep[] {
    return this.workflowSteps.filter(
      s => s.type !== WorkflowStepType.RecurringInspection,
    )
  }

  public get recurringInspectionStep(): IWorkflowStep {
    return this.workflowSteps.find(
      s => s.type === WorkflowStepType.RecurringInspection,
    )
  }

  public isLastStep = (workflowStepId: string): boolean => {
    return this.lastStep?.id === workflowStepId
  }

  public getStepRuleIds = (workflowStepId: string): string[] => {
    return (
      this.workflowSteps.find(s => s.id === workflowStepId)?.workflowRuleIds ||
      []
    )
  }
}

import { action, computed, observable } from 'mobx'

import { CategoryName, FilterType, RecentlyUpdatedMode } from '~/client/graph'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import { AND_CATEGORIES } from '~/client/src/shared/enums/CategoryNames'
import Activity from '~/client/src/shared/models/Activity'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import BaseActivityListStore from '~/client/src/shared/stores/ui/BaseActivityList.store'
import ProjectDateStore, {
  DAYS_IN_WEEK,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import CustomActivityListFilter, {
  ActivityFilterMap,
} from '~/client/src/shared/types/CustomActivityListFilter'
import { UNASSIGNED_FILTER_VALUE } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import ActivitiesDatePickerModes from '../../../../shared/enums/ActivitiesDatePickerModes'
import ActivityFiltersStore from '../../../../shared/stores/domain/ActivityFilters.store'
import UIFilterInfo from '../../../../shared/stores/substates/UIFilterInfo'
import { IDateFilters } from '../../../components/Filters/DateSelector/DateSelector.store'

export enum tagTypes {
  zone = 'zone',
  level = 'level',
  building = 'building',
  assignedMembers = 'assignedMembers',
}

export default class DesktopActivityListStore extends BaseActivityListStore {
  @observable public isAddActivityDialogShown = false
  @observable public isRemoveActivityDialogShown = false

  public dateFilters = observable<IDateFilters>({
    startDate: null,
    daysToAdd: 0,
    isDateFilterActive: true,
  })

  public constructor(
    protected readonly state: DesktopInitialState,
    activitiesStore: ActivitiesStore,
    activityPresetsStore: ActivityPresetsStore,
    activityFiltersStore: ActivityFiltersStore,
    activityDetailsStore: ActivityDetailsStore,
    statusUpdatesStore: StatusUpdatesStore,
    rfisStore: RfisStore,
    flagsStore: FlagsStore,
    scheduleCommentsStore: ScheduleCommentsStore,
    categoriesOfVarianceStore: CategoriesOfVarianceStore,
    safetyHazardsStore: SafetyHazardsStore,
    activityAssignmentsStore: ActivityAssignmentsStore,
    activityFollowingsStore: ActivityFollowingsStore,
    deliveriesStore: DeliveriesStore,
    projectDateStore: ProjectDateStore,
    private sitemapsStore: SitemapsStore,
  ) {
    super(
      state,
      activitiesStore,
      activityPresetsStore,
      activityFiltersStore,
      activityAssignmentsStore,
      activityFollowingsStore,
      activityDetailsStore,
      projectDateStore,
      statusUpdatesStore,
      rfisStore,
      flagsStore,
      scheduleCommentsStore,
      categoriesOfVarianceStore,
      safetyHazardsStore,
      deliveriesStore,
    )

    this.dateFilters.startDate = projectDateStore.startOfWeek(new Date())
    this.dateFilters.daysToAdd = this.daysToAdd - 1
  }

  @action.bound
  public recalculateDaysToAdd(): void {
    this.dateFilters.daysToAdd = this.daysToAdd - 1
  }

  public get daysToAdd(): number {
    const { user, userActiveProjectSettings } = this.state

    if (!user) {
      return DAYS_IN_WEEK * 3
    }

    const selectedDatePickerMode =
      userActiveProjectSettings?.activitiesDatePickerMode ||
      ActivitiesDatePickerModes.threeWeeks

    switch (selectedDatePickerMode) {
      case ActivitiesDatePickerModes.week:
        return DAYS_IN_WEEK
      case ActivitiesDatePickerModes.twoWeeks:
        return DAYS_IN_WEEK * 2
      case ActivitiesDatePickerModes.threeWeeks:
        return DAYS_IN_WEEK * 3
      case ActivitiesDatePickerModes.fourWeeks:
        return DAYS_IN_WEEK * 4
      case ActivitiesDatePickerModes.sixWeeks:
        return DAYS_IN_WEEK * 6
    }
  }

  @action.bound
  public setInitialDateValue() {
    this.dateFilters.startDate = this.projectDateStore.startOfWeek(new Date())
  }

  public get selectedActivity() {
    return this.activitiesStore.selectedActivity
  }

  public get isDataReceived() {
    return this.activitiesStore.isDataReceived
  }

  @action.bound
  public applyPageFromQueryParams(search: any) {
    const queryParams = new URLSearchParams(search)
    if (queryParams.get('view') === ViewModes.List) {
      this.state.activityList.viewMode = ViewModes.List
    }
    if (queryParams.get('activity')) {
      this.activitiesStore.select(queryParams.get('activity'))
      this.showActivityDetailsPanel()
    }
  }

  private get startDate() {
    return this.dateFilters.startDate
  }

  @computed
  private get endDate() {
    return this.projectDateStore.getEndDate(this.dateFilters)
  }

  @computed
  public get activitiesInChartInterval() {
    if (!this.dateFilters.isDateFilterActive) {
      return this.activitiesStore.list
    }
    return this.activitiesBetweenDates
  }

  @computed
  public get activitiesBetweenDates() {
    return this.activitiesStore.list.filter(activity =>
      activity.isActiveOrPlannedBetween(
        this.startDate,
        this.endDate,
        this.projectDateStore,
        this.statusUpdatesStore,
      ),
    )
  }

  @computed
  public get selectedActivities(): Activity[] {
    return this.filteredActivities.filter(
      a => a && this.multiSelection.includes(a.code),
    )
  }

  @action.bound
  public showActivityDetails() {
    const { pushNotificationEntityId } = this.state
    const activity = this.activitiesStore.list.find(
      a => a.code === pushNotificationEntityId.replace('/', '.'),
    )
    if (activity) {
      this.showActivityDetailsPanel()
      this.activitiesStore.select(activity.code)
      this.moveToActivityStart(activity)
    }
  }

  @computed
  public get recentlyUpdatedActivitiesCount(): number {
    return this.getRecentlyUpdatedActivities(this.activitiesBetweenDates).length
  }

  @computed
  public get activitiesWithSpecificStatusCount(): number {
    return this.getActivitiesWithSpecificStatus(this.activitiesBetweenDates)
      .length
  }

  private get appliedCategoryFilters(): CategoryName[] {
    return this.state.appliedCategoryFilters
  }

  public get allActivities() {
    return this.activitiesStore.allDoneInP6Activities
  }

  public buildCategoryFilteringMatrix(
    categoryFilters: CategoryName[],
  ): CategoryName[][] {
    return AND_CATEGORIES.map(orCategories => {
      return [...categoryFilters.filter(c => orCategories.includes(c))]
    }).filter(categories => categories.length)
  }

  @action.bound
  public enableDateFilter() {
    this.dateFilters.isDateFilterActive = true
  }

  public getActivityCountByCustomFilter = (
    filter: CustomActivityListFilter,
  ) => {
    if (!filter) {
      return 0
    }

    const categories: CategoryName[] = filter.appliedCategories || []
    const activitiesFilteredByFilters = this.activitiesInChartInterval.filter(
      a => this.isActivityInSelectedFilters(a, filter.filtersByFilterType),
    )

    return this.filterActivitiesWithCategoryFilters(
      activitiesFilteredByFilters,
      this.buildCategoryFilteringMatrix(categories),
      filter,
    ).length
  }

  @action.bound
  public moveToActivityStart(activity: Activity) {
    const { startDate, code } = activity
    this.dateFilters.startDate = this.projectDateStore.startOfWeek(
      this.projectDateStore.addDays(startDate, -DAYS_IN_WEEK),
    )
    this.activitiesStore.select(code)
    this.enableDateFilter()
  }

  @action.bound
  public moveToActivityFinish(activity: Activity) {
    const { code, finishDate } = activity

    this.dateFilters.startDate = this.projectDateStore.startOfWeek(
      this.projectDateStore.addDays(
        finishDate(this.projectDateStore, this.statusUpdatesStore),
        -DAYS_IN_WEEK,
      ),
    )
    this.activitiesStore.select(code)
    this.enableDateFilter()
  }

  @action.bound
  public toggleRemoveActivitiesDialog() {
    this.isRemoveActivityDialogShown = !this.isRemoveActivityDialogShown
  }

  @action.bound
  public toggleResponsibilitiesPanel() {
    const state = this.state.activityList
    state.shouldShowResponibilitiesPanel = !state.shouldShowResponibilitiesPanel
    if (state.shouldShowResponibilitiesPanel) {
      state.isActivityDetailsPanelDisplayed = false
      state.shouldShowBulkEditPanel = false
      this.hideAddActivityDialog()
    }
  }

  @action.bound
  public toggleBulkEditPanel() {
    const state = this.state.activityList
    state.shouldShowBulkEditPanel = !state.shouldShowBulkEditPanel
    if (state.shouldShowBulkEditPanel) {
      state.shouldShowResponibilitiesPanel = false
      state.isActivityDetailsPanelDisplayed = false
      this.hideAddActivityDialog()
    }
  }

  @action.bound
  public showActivityDetailsPanel(force?: boolean) {
    const state = this.state.activityList
    if (
      (!state.shouldShowResponibilitiesPanel &&
        !state.shouldShowBulkEditPanel) ||
      force
    ) {
      state.isActivityDetailsPanelDisplayed = true
      state.shouldShowResponibilitiesPanel = false
      state.shouldShowBulkEditPanel = false
      this.activityDetailsStore.setViewActivityMode()
      this.hideAddActivityDialog()
    }
  }

  @action.bound
  public showAddActivityDialog() {
    const state = this.state.activityList
    state.isActivityDetailsPanelDisplayed = false
    state.shouldShowResponibilitiesPanel = false
    state.shouldShowBulkEditPanel = false
    this.activityDetailsStore.setCreateActivityMode()

    this.isAddActivityDialogShown = true
  }

  @action.bound
  public hideAddActivityDialog() {
    this.isAddActivityDialogShown = false
  }

  @action.bound
  public setDatesIfHasSelectedActivity() {
    const shouldSetDateFromSelectedActivity =
      !this.isActivityInCurrentLookahead(this.selectedActivity) &&
      !!this.selectedActivity

    if (shouldSetDateFromSelectedActivity) {
      const newStartDate = this.projectDateStore.startOfWeek(
        this.selectedActivity.startDate,
      )
      this.dateFilters.startDate = newStartDate
      this.enableDateFilter()
    }
  }

  public isActivityInCurrentLookahead = (activity: Activity): boolean => {
    if (!activity) {
      return false
    }
    const { startDate, finishDate } = activity
    const { endOfDay, startOfDay } = this.projectDateStore

    const activityStartDate = startOfDay(startDate)
    const activityEndDate = endOfDay(
      finishDate(this.projectDateStore, this.statusUpdatesStore),
    )

    const startATime = this.startDate.getTime()
    const endATime = this.endDate.getTime()
    const startBTime = activityStartDate.getTime()
    const endBTime = activityEndDate.getTime()

    return (
      (endATime > startBTime && startATime < endBTime) ||
      (endBTime > startATime && startBTime < endATime)
    )
  }

  @action.bound
  public setSelection(ids: string[]) {
    this.activitiesStore.multiSelect(ids)
  }

  public get filteredActivities(): Activity[] {
    if (this.appliedPreset) {
      return this.appliedPresetActivities
    }

    return this.filterActivitiesWithCategoryFilters(
      this.filteredActivitiesExcludeFilters(),
      this.buildCategoryFilteringMatrix(this.appliedCategoryFilters),
    )
  }

  public filteredActivitiesExcludeFilters(
    excludedFilters: Array<FilterType | string> = [],
    activitiesIdsToFilter?: string[],
  ): Activity[] {
    let filteredActivities

    const shouldUsePeriodFilter = !excludedFilters.includes(FilterType.Period)
    if (activitiesIdsToFilter) {
      filteredActivities = this.activitiesInChartInterval.filter(a =>
        activitiesIdsToFilter.includes(a.code),
      )
    } else if (shouldUsePeriodFilter) {
      filteredActivities = this.activitiesInChartInterval
    } else {
      filteredActivities = this.activitiesStore.list
    }

    const shouldUseNameFilter = !excludedFilters.includes(FilterType.Name)
    if (shouldUseNameFilter && this.state.filters.searchKey) {
      const nameFilter = this.state.filters.searchKey.toLowerCase().trim()
      filteredActivities = filteredActivities.filter(({ name, id, code }) => {
        return `${name}${id}${code}`.toLowerCase().includes(nameFilter)
      })
    }

    this.enabledFilterTypes.forEach(filterType => {
      const shouldUseFilter = !excludedFilters.includes(filterType)
      const filter = this.filterInfoMap[filterType]
      if (shouldUseFilter && filter.isFilterActive) {
        // slice for faster usage
        const appliedOptions = filter.appliedFilterOptions.slice()
        filteredActivities = filteredActivities.filter(a =>
          appliedOptions.includes(a.id),
        )
      }
    })

    return filteredActivities
  }

  protected getRecentlyUpdatedActivities = (
    activities: Activity[],
    filter?: CustomActivityListFilter,
  ) => {
    const { mode, startDate, endDate } = this.getRecentlyUpdatedFilter(
      this.state.filters,
      filter,
    )

    if (RecentlyUpdatedMode.LastP6Update === mode) {
      return activities.filter(activity =>
        this.statusUpdatesStore.doesActivityHaveStatusAfterP6(activity),
      )
    }

    return activities.filter(activity =>
      this.statusUpdatesStore.doesActivityHaveStatusInSelectedPeriod(
        activity,
        new Date(startDate),
        new Date(endDate),
      ),
    )
  }

  protected getActivitiesWithSpecificStatus(
    activities: Activity[],
    filter?: CustomActivityListFilter,
  ): Activity[] {
    const mode = filter
      ? filter.statusFilterMode
      : this.state.filters.selectedActivityStatusMode
    return activities.filter(
      activity => activity.getExpandedStatus(this.projectDateStore) === mode,
    )
  }

  public get multiSelection() {
    return this.activitiesStore.multiSelection
  }

  private get filterInfoMap(): { [key: string]: UIFilterInfo } {
    return this.state.filters.locationsMap
  }

  private get enabledFilterTypes() {
    const settingsMap = this.state.activityFiltersSettings.filterInfoMap
    return Object.keys(this.filterInfoMap).filter(filterType => {
      return settingsMap[filterType] && !settingsMap[filterType].isDisabled
    })
  }

  private isActivityInCompanyFilter(activity: Activity, companies: string[]) {
    if (!activity.companies.length) {
      return companies.includes(UNASSIGNED_FILTER_VALUE)
    }

    return companies.includes(activity.company?.id)
  }

  private isActivityInSelectedFilters = (
    activity: Activity,
    filtersByFilterType?: ActivityFilterMap,
  ) => {
    return Object.keys(filtersByFilterType).every(filterType => {
      const values = filtersByFilterType[filterType]
      if (filterType === FilterType.Company) {
        return this.isActivityInCompanyFilter(activity, values)
      }

      const filterTypeCodeIds = this.getCodesByFilterType(filterType)
      const activityCodeIdsByType = activity.codes
        .filter(code => filterTypeCodeIds.includes(code))
        .map(({ id }) => id)

      if (!activityCodeIdsByType.length) {
        activityCodeIdsByType.push(UNASSIGNED_FILTER_VALUE)
      }

      return values && activityCodeIdsByType.some(id => values.includes(id))
    })
  }

  private getSitemapsOrderById(id: string): number {
    const { sitemaps } = this.state.activitiesSettings.configurations
    return sitemaps.find(s => s.sitemapId === id).order || 0
  }

  @computed
  public get activitiesSitemaps() {
    const { sitemaps } = this.state.activitiesSettings.configurations
    return this.sitemapsStore.list
      .filter(s =>
        sitemaps.some(orderedSitemap => orderedSitemap.sitemapId === s.id),
      )
      .sort(
        (a, b) =>
          this.getSitemapsOrderById(a.id) - this.getSitemapsOrderById(b.id),
      )
  }

  @computed
  public get activitiesSitemapsIds(): string[] {
    return this.activitiesSitemaps.map(sitemap => sitemap.id)
  }
}

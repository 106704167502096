import * as React from 'react'

import { observer } from 'mobx-react'

import PermitListItem from '~/client/src/shared/components/PermitListItem'
import SitePermit from '~/client/src/shared/models/Permit'

import SitemapItemBase from '../../SitemapHelpers/models/SitemapItemBase'
import SitemapDraggableModalWrapper from '../SitemapDraggableModalWrapper'

interface IProps {
  item: SitemapItemBase
  containerRef: HTMLElement
  selectedSitePermits: SitePermit[]
  selectedPermitId: string
  topOffset: number
  leftOffset: number

  openPermit(permit: SitePermit): void
  onClose(): void
}

// localization: no text to translate

@observer
export default class SitemapPermitsDraggableModal extends React.Component<IProps> {
  public render() {
    const {
      item,
      containerRef,
      selectedSitePermits = [],
      selectedPermitId,
      openPermit,
      onClose,
      topOffset,
      leftOffset,
    } = this.props

    if (!selectedSitePermits.length) {
      return
    }

    return (
      <SitemapDraggableModalWrapper
        className="overflow-auto"
        item={item}
        containerRef={containerRef}
        onClose={onClose}
        topOffset={topOffset}
        leftOffset={leftOffset}
      >
        <div className="relative overflow-auto draggable-elements-list">
          {selectedSitePermits.map(permit => (
            <PermitListItem
              key={permit.id}
              permit={permit}
              onClick={openPermit}
              isSelected={permit.id === selectedPermitId}
            />
          ))}
        </div>
      </SitemapDraggableModalWrapper>
    )
  }
}

import { computed } from 'mobx'

import DesktopEventsStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import {
  LogisticsMapFilterType,
  formsMapFilterTypes,
  getLogisticsMapFilterTypeCaption,
  hubMapFilterTypes,
} from '~/client/src/shared/enums/LogisticsMapFilterType'
import { TagType } from '~/client/src/shared/enums/TagType'
import { LogisticItemApp } from '~/client/src/shared/models/ILogisticItem'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { enumToList } from '~/client/src/shared/utils/converters'
import { LOCATION_SEPARATOR } from '~/client/src/shared/utils/usefulStrings'

import BaseLogisticsFilterStore, {
  ISourceMap,
} from '../../../../../../../shared/stores/BaseLogisticsFilter.store'
import LogisticsFilterStore from '../../../../../../../shared/stores/LogisticsFilter.store'
import LogisticsStore from '../../../../Logistics.store'
import LogisticsMapStore from '../../../LogisticsMap/LogisticsMap.store'

const EXCLUDED_APP_FILTER = [LogisticItemApp.SENSOR, LogisticItemApp.CLOSURE]

export default class LogisticsMapFilterStore extends LogisticsFilterStore {
  public constructor(
    protected readonly eventsStore: DesktopEventsStore,
    protected readonly logisticsStore: LogisticsStore,
    protected readonly logisticsMapStore: LogisticsMapStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    protected readonly locationAttributesStore: LocationAttributesStore,
    protected readonly permitTypesStore: PermitTypesStore,
    protected readonly tagsStore: TagsStore,
    private readonly onFilterClickHandler?: () => void,
    protected readonly isPermitsOnly?: boolean,
  ) {
    super(
      eventsStore,
      logisticsStore,
      onShowChanged,
      locationAttributesStore,
      permitTypesStore,
      tagsStore,
      isPermitsOnly,
    )
  }

  protected get availableInstances() {
    return this.logisticsMapStore.allLogistics
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseLogisticsFilterStore
  } {
    const { appState } = this.eventsStore
    const map: { [filterType: string]: BaseLogisticsFilterStore } = {}

    this.filterTypes.forEach(filterType => {
      map[filterType] = new BaseLogisticsFilterStore(
        filterType,
        appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.logisticsMapStore,
        this.onShowChanged,
        this.fieldsMap(),
        getLogisticsMapFilterTypeCaption,
        this.getOptionName,
        false,
        this.onFilterClickHandler,
      )
    })

    return map
  }

  @computed
  protected get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  } {
    const maps = this.filterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableInstances.forEach(instance => {
      this.filterTypes.forEach(filterType => {
        const map = maps[filterType]
        let optionIds = []

        switch (filterType) {
          case LogisticsMapFilterType.Location:
            optionIds = instance.locations?.length
              ? instance.locations?.map(
                  l => `${l.type}${LOCATION_SEPARATOR}${l.id}`,
                )
              : [UNASSIGNED_FILTER_OPTION]
            break
          case LogisticsMapFilterType.Company:
            optionIds = instance.companyIds?.length
              ? instance.companyIds
              : [UNASSIGNED_FILTER_OPTION]
            break
          case LogisticsMapFilterType.ResponsibleContact:
            optionIds = instance.responsibleContactIds?.length
              ? instance.responsibleContactIds
              : [UNASSIGNED_FILTER_OPTION]
            break
          case LogisticsMapFilterType.App:
            optionIds = [instance.app || UNASSIGNED_FILTER_OPTION]
            break
          case LogisticsMapFilterType.Type:
            if (instance.app === LogisticItemApp.FORM) {
              const form = this.logisticsStore.getFormById(instance.entityId)
              optionIds = [
                form?.getTypeOfPermitType(this.permitTypesStore) ||
                  UNASSIGNED_FILTER_OPTION,
              ]
            } else {
              optionIds = []
            }
            break
        }

        optionIds.forEach(optionId => {
          map[optionId]?.push(instance.id)
        })
      })
    })
    return maps
  }

  protected getDefaultSourceMapByType(type: LogisticsMapFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case LogisticsMapFilterType.App:
        sourceList = [
          ...enumToList(LogisticItemApp).filter(
            app => !EXCLUDED_APP_FILTER.includes(app),
          ),
        ]
        break
      case LogisticsMapFilterType.Location:
        sourceList = [
          ...this.locationAttributesStore.allAttributes.map(
            l => `${l.type}${LOCATION_SEPARATOR}${l.id}`,
          ),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case LogisticsMapFilterType.Company:
        sourceList = [
          ...this.tagsStore.tagListsByTagTypeMap[TagType.Company].map(
            t => t.id,
          ),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case LogisticsMapFilterType.ResponsibleContact:
        sourceList = [
          ...this.tagsStore.tagListsByTagTypeMap[TagType.User].map(t => t.id),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case LogisticsMapFilterType.Type:
        if (!this.isPermitsOnly) {
          sourceList = []
        } else {
          sourceList = this.availableInstances.reduce((acc, instance) => {
            const form = this.logisticsStore.getFormById(instance.entityId)
            const formType = form?.getTypeOfPermitType(this.permitTypesStore)
            if (formType && !acc.includes(formType)) {
              acc.push(formType)
            }
            return acc
          }, [])
          sourceList.push(UNASSIGNED_FILTER_OPTION)
        }
        break
    }
    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  protected fieldsMap() {
    const { logisticsMapFilters, formsMapFilters } = this.eventsStore.appState
    return this.isPermitsOnly
      ? formsMapFilters.fieldsMap
      : logisticsMapFilters.fieldsMap
  }

  protected get filterTypes(): LogisticsMapFilterType[] {
    return this.isPermitsOnly ? formsMapFilterTypes : hubMapFilterTypes
  }
}

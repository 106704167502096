import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import { DeliveryIcon } from '~/client/src/shared/components/DeliveryIcon/DeliveryIcon'
import DeliveryStatusText from '~/client/src/shared/components/DeliveryStatusText/DeliveryStatusText'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import Delivery from '~/client/src/shared/models/Delivery'
import InitialState from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import { INTERPUNCT } from '~/client/src/shared/utils/usefulStrings'

interface IProps {
  event: CalendarEvent

  state?: InitialState
  companiesStore?: CompaniesStore
  locationAttributesStore?: LocationAttributesStore
}

@inject('state', 'companiesStore', 'locationAttributesStore')
@observer
export default class DeliveryMultiDayEventLabel extends React.Component<IProps> {
  public render() {
    const { event } = this.props

    if (event.isNew) {
      return Localization.translator.newDelivery
    }

    const { companiesStore } = this.props

    const companyName = companiesStore.getCompanyNameById(
      this.delivery.company,
      null,
    )

    return (
      <>
        <DeliveryIcon
          className="row event-icon no-grow mr4"
          isFromCD={this.delivery.isFromConcreteDirect}
          color={event.styles.color}
        />
        {companyName && (
          <>
            <span className="text-bold no-grow">{companyName}</span>
            <span className="px4 no-grow">{INTERPUNCT}</span>
          </>
        )}

        <span className="text light no-grow">
          {this.delivery.codeToDisplay(companiesStore)}
        </span>
        <span className="px4 no-grow">{INTERPUNCT}</span>
        <DeliveryStatusText
          className="relative text bold capitalize no-grow"
          delivery={this.delivery}
        />

        {this.locationName && (
          <>
            <span className="px4 no-grow">{INTERPUNCT}</span>
            {this.locationName}
          </>
        )}
      </>
    )
  }

  private get delivery(): Delivery {
    return this.props.event.data
  }

  private get locationName(): string {
    const { state, locationAttributesStore } = this.props

    switch (state.delivery.configurations.cardColoringLocationType) {
      case LocationType.Area:
        return locationAttributesStore.areasStore.getInstanceById(
          this.delivery.area,
        )?.name

      case LocationType.Building:
        return locationAttributesStore.buildingsStore.getInstanceById(
          this.delivery.building,
        )?.name

      case LocationType.Gate:
        return locationAttributesStore.gatesStore.getInstanceById(
          this.delivery.gate,
        )?.name

      case LocationType.Level:
        return locationAttributesStore.levelsStore.getInstanceById(
          this.delivery.level,
        )?.name

      case LocationType.Route:
        return locationAttributesStore.routesStore.getInstanceById(
          this.delivery.route,
        )?.name

      case LocationType.Zone:
      default:
        return locationAttributesStore.zonesStore.getInstanceById(
          this.delivery.zone,
        )?.name
    }
  }
}

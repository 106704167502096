import { SaveVerticalObjectsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import VerticalObject from '../../models/LocationObjects/VerticalObject'
import * as e from '../EventStore/eventConstants'
import LocationStoreBase from './LocationBase.store'

export default class VerticalObjectsStore extends LocationStoreBase<VerticalObject> {
  protected saveMutation = SaveVerticalObjectsDocument
  protected graphName = 'verticalObjects'
  protected defaultIconName = SitemapAttributeIcon.Stairs

  public get byId() {
    const { verticalObjects } = this.eventsStore.appState
    return verticalObjects
  }

  public saveItem(item: VerticalObject): Promise<string> {
    this.changeAccessibleLevelsIfNeed(item)
    return super.saveItem(item)
  }

  public saveItemsWhenNestedNumberOfLevelsChanges = async (
    items: VerticalObject[],
  ): Promise<string[]> => {
    if (!items?.length) {
      return []
    }

    this.loadingMap.set(e.SAVE_LOCATION, true)

    const dtos = items.map(vo => {
      this.changeAccessibleLevelsIfNeed(vo)
      return vo.getDto()
    })

    const {
      data: { saveManyVerticalObjects: savedVerticalObjects },
    } = await this.graphExecutorStore.mutate(this.saveMutation, {
      [this.graphName]: dtos,
    })

    this.loadingMap.set(e.SAVE_LOCATION, false)

    return savedVerticalObjects?.map(dto => dto.id)
  }

  protected fromDto(dto: ILocationDto): VerticalObject {
    return VerticalObject.fromDto(dto)
  }

  private changeAccessibleLevelsIfNeed = (item: VerticalObject) => {
    const oldItem = this.byId.get(item.id)

    const isParentAdded = item.hasParent && !oldItem?.hasParent
    const isParentChanged = item.parent?.parentId !== oldItem?.parent?.parentId
    if (isParentChanged || isParentAdded || !item.accessibleLevels?.length) {
      item.accessibleLevels = this.getAllowedAccessibleLevels(item)
    }
  }
}

import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Rect } from 'react-konva'

import { IPosition, SitemapItemShapeType } from '~/client/graph'
import MapBoxEditorStore from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor.store'
import SitemapItemBase, {
  IBoundingBox,
} from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import {
  MAX_RELATIVE_POSITION,
  sitemapMousePointTo,
} from '~/client/src/shared/utils/SitemapCalculationHelpers'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const SQUARE_SIZE = 10
const SQUARE_STROKE_WIDTH = 2

enum BoundCorner {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

interface ICorner {
  type: BoundCorner
  position: IPosition
}

interface IProps {
  item: SitemapItemBase
  containerHeight: number
  containerWidth: number

  isReferenced: boolean
  mapBoxEditorStore: MapBoxEditorStore
}

const CURSOR_NESW_RESIZE =
  'url(/static/cursors/nesw-resize.png) 16 16, nesw-resize'
const CURSOR_NWSE_RESIZE =
  'url(/static/cursors/nwse-resize.png) 16 16, nwse-resize'

const CURSOR_NE_ROTATE = 'url(/static/cursors/ne-rotate.png) 16 16, auto'
const CURSOR_NW_ROTATE = 'url(/static/cursors/nw-rotate.png) 16 16, auto'
const CURSOR_SE_ROTATE = 'url(/static/cursors/se-rotate.png) 16 16, auto'
const CURSOR_SW_ROTATE = 'url(/static/cursors/sw-rotate.png) 16 16, auto'

const CURSOR_DEFAULT = 'default'

const RESIZE_CURSOR_BY_CORNER = {
  [BoundCorner.TopLeft]: CURSOR_NWSE_RESIZE,
  [BoundCorner.BottomRight]: CURSOR_NWSE_RESIZE,
  [BoundCorner.TopRight]: CURSOR_NESW_RESIZE,
  [BoundCorner.BottomLeft]: CURSOR_NESW_RESIZE,
}

const ROTATE_CURSOR_BY_CORNER = {
  [BoundCorner.TopLeft]: CURSOR_NW_ROTATE,
  [BoundCorner.BottomRight]: CURSOR_SE_ROTATE,
  [BoundCorner.TopRight]: CURSOR_NE_ROTATE,
  [BoundCorner.BottomLeft]: CURSOR_SW_ROTATE,
}

const OPPOSITE_CORNER_MAP = {
  [BoundCorner.TopLeft]: BoundCorner.BottomRight,
  [BoundCorner.BottomRight]: BoundCorner.TopLeft,
  [BoundCorner.TopRight]: BoundCorner.BottomLeft,
  [BoundCorner.BottomLeft]: BoundCorner.TopRight,
}

const DEGREE_IN_RADIANS = Math.PI / 180
const INPUTS_NODE_NAMES = ['INPUT', 'TEXTAREA']
const nodeNameProp = 'nodeName'

@observer
export default class BoundsEditor extends React.Component<IProps> {
  @observable private isConstrainScaleActive: boolean = false
  @observable private isRotationActive: boolean = false
  @observable private isTransformationActive: boolean = false

  private originPoint: IPosition = null
  private initialCursorPosition: IPosition = null
  private initialItem: SitemapItemBase = null

  @observable private hoveredCorner: BoundCorner = null

  public componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
    document.addEventListener('mouseup', this.onMouseUp)
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
    document.removeEventListener('mouseup', this.onMouseUp)
    this.setDefaultCursor()
  }

  public render() {
    const { shapeProperties, isDisplayed } = this.props.item
    if (!this.boundingBox || !shapeProperties || !isDisplayed) {
      return null
    }

    return this.boundingBoxCorners.map(corner => (
      <Rect
        key={corner.type}
        x={corner.position.x}
        y={corner.position.y}
        width={SQUARE_SIZE}
        height={SQUARE_SIZE}
        fill={ThemeMode.getHEXColor(Colors.neutral100)}
        strokeWidth={SQUARE_STROKE_WIDTH}
        stroke={ThemeMode.getHEXColor(Colors.primary60)}
        offsetX={SQUARE_SIZE / 2}
        offsetY={SQUARE_SIZE / 2}
        onMouseEnter={this.onCornerMouseEnter.bind(this, corner.type)}
        onMouseLeave={this.onCornerMouseLeave}
        onMouseDown={this.onCornerMouseDown.bind(this, corner.type)}
        onDragMove={this.onTransformationMouseMove.bind(this, corner)}
        draggable={true}
        dragBoundFunc={this.returnPosition.bind(this, corner.position)}
      />
    ))
  }

  private returnPosition(position: IPosition) {
    return position
  }

  private get boundingBox(): IBoundingBox {
    const { item, containerWidth, containerHeight } = this.props
    return item.getBoundingBox(containerWidth, containerHeight)
  }

  private get boundingBoxCorners(): ICorner[] {
    if (!this.boundingBox) {
      return []
    }

    const { x, y, width, height } = this.boundingBox
    return [
      {
        type: BoundCorner.TopLeft,
        position: { x, y },
      },
      {
        type: BoundCorner.TopRight,
        position: { x: x + width, y },
      },
      {
        type: BoundCorner.BottomRight,
        position: { x: x + width, y: y + height },
      },
      {
        type: BoundCorner.BottomLeft,
        position: { x, y: y + height },
      },
    ]
  }

  private onKeyDown = (event: KeyboardEvent) => {
    if (INPUTS_NODE_NAMES.includes(event?.target?.[nodeNameProp])) {
      return
    }

    if (event.ctrlKey || event.metaKey) {
      this.isRotationActive = true
      this.isConstrainScaleActive = false
      event.preventDefault()
      if (this.hoveredCorner && !this.isRotationActive) {
        this.setRotateCursorFromCorner(this.hoveredCorner)
      }
      return
    }
    if (event.shiftKey) {
      this.isConstrainScaleActive = true
      this.isRotationActive = false
      if (this.hoveredCorner) {
        this.setResizeCursor(this.hoveredCorner)
      }
      return
    }
  }

  private onKeyUp = () => {
    this.isConstrainScaleActive = false
    this.isRotationActive = false
    if (this.hoveredCorner) {
      this.setResizeCursor(this.hoveredCorner)
    }
  }

  private onCornerMouseEnter(
    corner: BoundCorner,
    event: KonvaEventObject<MouseEvent>,
  ) {
    this.hoveredCorner = corner
    if (event.evt.ctrlKey || event.evt.metaKey) {
      this.setRotateCursorFromCorner(corner)
      return
    }
    this.setResizeCursor(corner)
  }

  private onCornerMouseLeave = () => {
    this.hoveredCorner = null
    if (this.isTransformationActive) {
      return
    }
    this.setDefaultCursor()
  }

  private setResizeCursor(corner: BoundCorner) {
    const cursor = RESIZE_CURSOR_BY_CORNER[corner]
    document.body.style.cursor = cursor
  }

  private setRotateCursorFromCorner(corner: BoundCorner) {
    const cursor = ROTATE_CURSOR_BY_CORNER[corner]
    document.body.style.cursor = cursor
  }

  private setRotateCursorFromAngle(angle: number) {
    const sin = Math.sin(angle * DEGREE_IN_RADIANS)
    const cos = Math.cos(angle * DEGREE_IN_RADIANS)
    let cursor = CURSOR_DEFAULT
    if (sin >= 0 && cos >= 0) {
      cursor = CURSOR_SE_ROTATE
    }
    if (sin >= 0 && cos < 0) {
      cursor = CURSOR_SW_ROTATE
    }
    if (sin < 0 && cos < 0) {
      cursor = CURSOR_NW_ROTATE
    }
    if (sin < 0 && cos >= 0) {
      cursor = CURSOR_NE_ROTATE
    }
    document.body.style.cursor = cursor
  }

  private setDefaultCursor() {
    document.body.style.cursor = CURSOR_DEFAULT
  }

  private onCornerMouseDown(
    corner: BoundCorner,
    event: KonvaEventObject<MouseEvent>,
  ) {
    const oppositeCornerType = OPPOSITE_CORNER_MAP[corner]
    const oppositeCorner = this.boundingBoxCorners.find(
      c => c.type === oppositeCornerType,
    )
    this.originPoint = oppositeCorner.position

    this.initialItem = this.props.item.copy()
    this.initialCursorPosition = event.target.getStage().getPointerPosition()

    this.isTransformationActive = true

    event.evt.preventDefault()
  }

  private onTransformationMouseMove(
    corner: ICorner,
    event: KonvaEventObject<DragEvent>,
  ) {
    const { currentTarget } = event
    const pointerPosition = currentTarget.getStage().getPointerPosition()

    if (this.isRotationActive) {
      this.rotateItem(pointerPosition)
      return
    }
    this.scaleItem(event, corner, pointerPosition)
  }

  private rotateItem(pointerPosition: IPosition) {
    const { containerWidth, containerHeight, item } = this.props

    const initialAngle = this.getPointAngle(
      this.initialCursorPosition.x,
      this.initialCursorPosition.y,
    )
    const currentAngle = this.getPointAngle(
      pointerPosition.x,
      pointerPosition.y,
    )
    const rotationAngle = currentAngle - initialAngle

    const initialBoundingBox = this.initialItem.getBoundingBox(
      containerWidth,
      containerHeight,
    )
    const centerPoint = {
      x: initialBoundingBox.x + initialBoundingBox.width / 2,
      y: initialBoundingBox.y + initialBoundingBox.height / 2,
    }
    const centerRelativePoint = {
      x: (centerPoint.x / containerWidth) * MAX_RELATIVE_POSITION,
      y: (centerPoint.y / containerHeight) * MAX_RELATIVE_POSITION,
    }

    const rotatedItem = this.initialItem
      .copy()
      .rotate(centerRelativePoint, rotationAngle)
    item.setShapesFromItem(rotatedItem)
    this.setRotateCursorFromAngle(currentAngle)
  }

  private getPointAngle(x: number, y: number): number {
    const { containerWidth, containerHeight } = this.props
    const initialBoundingBox = this.initialItem.getBoundingBox(
      containerWidth,
      containerHeight,
    )
    const centerPoint = {
      x: initialBoundingBox.x + initialBoundingBox.width / 2,
      y: initialBoundingBox.y + initialBoundingBox.height / 2,
    }
    return Math.atan2(y - centerPoint.y, x - centerPoint.x) / DEGREE_IN_RADIANS
  }

  private scaleItem(
    event: KonvaEventObject<DragEvent>,
    corner: ICorner,
    pointerPosition: IPosition,
  ) {
    const {
      containerWidth,
      containerHeight,
      item,
      mapBoxEditorStore: { canvas, moveCoords },
      isReferenced,
    } = this.props

    const stage = event.currentTarget.getStage()
    const mousePointTo = isReferenced
      ? canvas?.getMap?.()?.project(moveCoords)
      : sitemapMousePointTo(
          stage,
          containerWidth,
          containerHeight,
          pointerPosition,
        )

    let newWidth = mousePointTo.x - this.originPoint.x
    let newHeight = mousePointTo.y - this.originPoint.y

    if ([BoundCorner.BottomLeft, BoundCorner.TopLeft].includes(corner.type)) {
      newWidth *= -1
    }
    if ([BoundCorner.TopLeft, BoundCorner.TopRight].includes(corner.type)) {
      newHeight *= -1
    }

    const initialBoundingBox = this.initialItem.getBoundingBox(
      containerWidth,
      containerHeight,
    )
    let scaleX = newWidth / initialBoundingBox.width
    let scaleY = newHeight / initialBoundingBox.height

    if (
      this.isConstrainScaleActive ||
      item.shapeProperties.type === SitemapItemShapeType.Circle // circle supports only constrain scale
    ) {
      const maxPositiveScale = Math.max(Math.abs(scaleX), Math.abs(scaleY))
      scaleX = (scaleX / Math.abs(scaleX)) * maxPositiveScale
      scaleY = (scaleY / Math.abs(scaleY)) * maxPositiveScale
    }

    const relativeOriginPoint = {
      x: (this.originPoint.x / containerWidth) * MAX_RELATIVE_POSITION,
      y: (this.originPoint.y / containerHeight) * MAX_RELATIVE_POSITION,
    }

    const scaledItem = this.initialItem
      .copy()
      .scale(relativeOriginPoint, scaleX, scaleY)
    item.setShapesFromItem(scaledItem)
  }

  private onMouseUp = () => {
    this.isTransformationActive = false
    this.originPoint = null
    this.initialItem = null
    this.setDefaultCursor()
  }
}

import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_HEIGHT = 20
const ICON_WIDTH = 20

const ICON_OFFSET_X = 2
const ICON_OFFSET_Y = 2
const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const ICON_SCALE = 0.8

const ICON_PATH =
  'M9.16667 16.1878V10.4795L4.16667 7.58366V13.292L9.16667 16.1878ZM10.8333 16.1878L15.8333 13.292V7.58366L10.8333 10.4795V16.1878ZM10 9.04199L14.9375 6.18783L10 3.33366L5.0625 6.18783L10 9.04199ZM3.33333 14.7503C3.06944 14.5975 2.86458 14.3962 2.71875 14.1462C2.57292 13.8962 2.5 13.6184 2.5 13.3128V6.68783C2.5 6.38227 2.57292 6.10449 2.71875 5.85449C2.86458 5.60449 3.06944 5.4031 3.33333 5.25033L9.16667 1.89616C9.43056 1.74338 9.70833 1.66699 10 1.66699C10.2917 1.66699 10.5694 1.74338 10.8333 1.89616L16.6667 5.25033C16.9306 5.4031 17.1354 5.60449 17.2813 5.85449C17.4271 6.10449 17.5 6.38227 17.5 6.68783V13.3128C17.5 13.6184 17.4271 13.8962 17.2813 14.1462C17.1354 14.3962 16.9306 14.5975 16.6667 14.7503L10.8333 18.1045C10.5694 18.2573 10.2917 18.3337 10 18.3337C9.70833 18.3337 9.43056 18.2573 9.16667 18.1045L3.33333 14.7503Z'
const STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaStagingIcon = ({ color, ...rest }) => {
  const halfSize = ICON_HEIGHT / 2
  return (
    <Group {...rest} offsetX={ICON_WIDTH / 2} offsetY={ICON_HEIGHT / 2}>
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={ICON_PATH}
        fill={getHEXColor(Colors.neutral100)}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        scaleX={ICON_SCALE}
        scaleY={ICON_SCALE}
      />
    </Group>
  )
}

export default KonvaStagingIcon

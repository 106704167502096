import { action, computed } from 'mobx'
import { isTablet } from 'react-device-detect'

import BaseGroupingOptionsStore, {
  EMPTY_OPTION,
  ICustomBandOption,
} from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions.store'
import GroupingOption from '~/client/src/desktop/enums/GroupingOptions'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import { IBandOption } from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ActivityGroupingType from '~/client/src/shared/enums/ActivityGroupingType'
import HierarchyModes from '~/client/src/shared/enums/HierarchyModes'
import ViewTypes from '~/client/src/shared/enums/ViewTypes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'

const DEFAULT_BANDS_COUNT = 4

const SAVED_ENTITIES_NAME = 'savedActivityGroups'

export default class ActivityGroupingOptionsStore extends BaseGroupingOptionsStore {
  public shouldShowEmptyBandsSection: boolean = true
  public leftCaptionKey = KnownTranslatorKeys.selectActivityGroups
  public rightCaptionKey = KnownTranslatorKeys.numberOfBands

  public get selectedBandsOption() {
    return this.state.filters.selectedBandsOption
  }

  @computed
  public get bandsOptions(): IBandOption[] {
    const isMapView = this.viewMode === ViewModes.Map
    const options: IBandOption[] = [
      {
        id: GroupingOption.None,
        nameTraslatorKey: KnownTranslatorKeys.none,
        num: 0,
        disabled: false,
        bands: [GroupingOption.None],
      },
      {
        id: GroupingOption.Wbs,
        nameTraslatorKey: KnownTranslatorKeys.workBreakdownStructureWbs,
        num: 1,
        disabled: isMapView,
        bands: [GroupingOption.Wbs],
      },
      {
        id: GroupingOption.Lbs,
        nameTraslatorKey: KnownTranslatorKeys.locationBreakdownStructureLbs,
        num: 3,
        disabled: isMapView,
        bands: [
          GroupingOption.Building,
          GroupingOption.Level,
          GroupingOption.Zone,
        ],
      },
      {
        id: GroupingOption.Company,
        nameTraslatorKey: KnownTranslatorKeys.companies,
        num: 1,
        disabled: isMapView,
        bands: [GroupingOption.Company],
      },
    ]

    const { activitiesSettings } = this.state
    const { savedActivityGroups } = activitiesSettings.configurations
    const bands: IBandOption[] = savedActivityGroups?.length
      ? savedActivityGroups.map(({ filterNames }, index) => {
          const filters = Object.values(filterNames)
          return {
            name: filters
              .map(value => this.options.find(o => o.name === value)?.caption)
              .filter(c => c)
              .join(', '),
            id: filters.join(', '),
            num: DEFAULT_BANDS_COUNT + index,
            disabled: isMapView,
            bands: filters,
            canDelete: true,
            deletionIndex: index,
          }
        })
      : []
    return [...options, ...bands]
  }

  @computed
  public get defaultBandsOptionId() {
    const { viewsToHierarchyModesMap, orderedAppliedBands } =
      this.hierarchyConfigurationStore

    const enabledOptionId = this.bandsOptions.find(o => !o.disabled).id
    if (this.viewMode === ViewModes.Map) {
      return enabledOptionId
    }

    const hierarchyMode = viewsToHierarchyModesMap[this.viewType]
    if (hierarchyMode === HierarchyModes.Hierarchy) {
      const bandsOption = this.bandsOptions.find(
        ({ bands, disabled }) =>
          this.isSameBands(bands, orderedAppliedBands) && !disabled,
      )

      return bandsOption ? bandsOption.id : enabledOptionId
    }

    return GroupingOption.Wbs
  }

  public get groupById() {
    if (this.viewMode === ViewModes.Map) {
      return this.defaultBandsOptionId
    }
    return this.selectedBandsOption?.id || this.defaultBandsOptionId
  }

  public get areDefaultOptionsSelected() {
    return (
      !this.showEmptyBandsDraft &&
      this.selectedBandsOptionIdDraft === this.defaultBandsOptionId
    )
  }

  @computed
  public get groupByCaption(): string {
    const { name, nameTraslatorKey } =
      this.viewMode === ViewModes.Map
        ? this.bandsOptions.find(({ id }) => id === this.defaultBandsOptionId)
        : this.selectedBandsOption

    return nameTraslatorKey ? Localization.getText(nameTraslatorKey) : name
  }

  private get viewType() {
    if (isTablet) {
      return ViewTypes.tablet
    }

    switch (this.viewMode) {
      case ViewModes.Gantt:
      case ViewModes.List:
        return ViewTypes.webGantt
      case ViewModes.Map:
        return ViewTypes.webSitemap
      default:
        return null
    }
  }

  private get viewMode() {
    return this.state.activityList.viewMode
  }

  public constructor(
    protected eventsStore: DesktopEventStore,
    private readonly hierarchyConfigurationStore: HierarchyConfigurationStore,
    protected readonly onShowChanged: (isShown: boolean) => void,
    private readonly onApplyClick?: () => void,
  ) {
    super(eventsStore, onShowChanged)
  }

  public setSelectedBand() {
    if (
      !this.selectedBandsOption ||
      !this.bandsOptions.some(({ id }) => id === this.selectedBandsOption.id)
    ) {
      this.resetGroupingIfNeed()
    }
  }

  @action.bound
  public onActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event

    if (e.ACTIVITIES_CONFIGURATIONS_UPDATED === eventType) {
      this.resetGroupingIfNeed()
    }
  }

  @action.bound
  public initPopup() {
    this.showEmptyBandsDraft = this.state.filters.showEmptyBands
    this.selectedBandsOptionIdDraft = this.groupById
  }

  @action.bound
  public onShowEmptyWbsChange() {
    this.showEmptyBandsDraft = !this.showEmptyBandsDraft
  }

  @action.bound
  public resetGroupingOptions() {
    this.showEmptyBandsDraft = false
    this.selectedBandsOptionIdDraft = this.defaultBandsOptionId
  }

  @action.bound
  public applyGroupingOptions() {
    this.hideGroupingOptions()
    this.state.filters.showEmptyBands = this.showEmptyBandsDraft
    this.setSelectedBandsOption(this.selectedBandsOptionIdDraft)
    this.state.activityList.sortingDataKey = null

    this.onApplyClick?.()
  }

  public deleteCustomGrouping = (index: number) => {
    if (index < 0) {
      return
    }
    const { configurations } = this.state.activitiesSettings
    const { sitemaps, savedActivityGroups } = configurations

    configurations.savedActivityGroups = savedActivityGroups.splice(index, 1)

    this.saveGroupingConfig(
      e.SAVE_ACTIVITIES_CONFIGURATIONS,
      SAVED_ENTITIES_NAME,
      sitemaps,
      savedActivityGroups,
    )
  }

  @computed
  public get options(): ICustomBandOption[] {
    const { filterInfoMap } = this.state.activityFiltersSettings

    const options: ICustomBandOption[] = [
      {
        name: EMPTY_OPTION,
        caption: Localization.translator.none,
      },
      {
        name: ActivityGroupingType.Equipment,
        caption: Localization.translator.equipment,
      },
      {
        name: ActivityGroupingType.VerticalObject,
        caption: Localization.translator.verticalObject,
      },
      ...Object.values(filterInfoMap).map(({ name, getCaption }) => {
        return {
          name,
          caption: getCaption(),
        }
      }),
      {
        name: ActivityGroupingType.Gate,
        caption: Localization.translator.gate,
      },
      {
        name: ActivityGroupingType.Route,
        caption: Localization.translator.route,
      },
    ]

    return options
  }

  public get isLoading(): boolean {
    return this.state.loading.get(e.SAVE_ACTIVITIES_CONFIGURATIONS)
  }

  @action.bound
  private resetGroupingIfNeed() {
    const isSelectedOptionExists = this.bandsOptions.some(
      opt => opt.id === this.selectedBandsOption?.id,
    )
    const isSelectedDraftOptionExists = this.bandsOptions.some(
      opt => opt.id === this.selectedBandsOptionIdDraft,
    )

    if (isSelectedOptionExists && isSelectedDraftOptionExists) {
      return
    }

    this.selectedBandsOptionIdDraft = this.defaultBandsOptionId
    this.setSelectedBandsOption(this.selectedBandsOptionIdDraft)
    this.state.activityList.sortingDataKey = null
  }

  private setSelectedBandsOption(selectedId: string) {
    const { sitemaps, savedActivityGroups } =
      this.state.activitiesSettings.configurations
    if (this.groupLevels.length > 1) {
      this.saveSelectedLevels(
        e.SAVE_ACTIVITIES_CONFIGURATIONS,
        SAVED_ENTITIES_NAME,
        sitemaps,
        savedActivityGroups || [],
      )
      return
    }
    this.groupLevels = [EMPTY_OPTION]

    this.state.filters.selectedBandsOption =
      this.bandsOptions.find(({ id }) => id === selectedId) ||
      this.bandsOptions.find(({ id }) => id === this.defaultBandsOptionId)
  }

  private isSameBands(bands1: string[], bands2: string[]) {
    return JSON.stringify(bands1) === JSON.stringify(bands2)
  }
}

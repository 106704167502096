import * as React from 'react'

import { Feature } from '@nebula.gl/edit-modes/dist-types/geojson-types'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import ReactMapGL, {
  GeolocateControl,
  MapEvent,
  Marker,
  NavigationControl,
  Popup,
  WebMercatorViewport,
} from 'react-map-gl'
import InlineSVG from 'svg-inline-react'

import {
  IGeoJson2DGeographicCoordinates,
  IPosition,
  IProjectAddressInput,
  LocationType,
} from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import Sitemap, {
  ISitemapGeoPosition,
} from '~/client/src/shared/models/Sitemap'
import AreaSrc from '~/client/static/icons/mapbox-icons/area.svg'
import BathroomSrc from '~/client/static/icons/mapbox-icons/bathroom.svg'
import BreakSrc from '~/client/static/icons/mapbox-icons/break.svg'
import BuildingSrc from '~/client/static/icons/mapbox-icons/building.svg'
import CanteenSrc from '~/client/static/icons/mapbox-icons/canteen.svg'
import CircleSrc from '~/client/static/icons/mapbox-icons/circle.svg'
import ConcreteTruckSrc from '~/client/static/icons/mapbox-icons/concrete-truck.svg'
import CraneSrc from '~/client/static/icons/mapbox-icons/crane.svg'
import TeamSrc from '~/client/static/icons/mapbox-icons/default-team.svg'
import DumpsterSrc from '~/client/static/icons/mapbox-icons/dumpster.svg'
import ElectricalRoomSrc from '~/client/static/icons/mapbox-icons/electrical-room.svg'
import ElevatorShaftSrc from '~/client/static/icons/mapbox-icons/elevator-shaft.svg'
import ElevatorSrc from '~/client/static/icons/mapbox-icons/elevator.svg'
import EntranceSrc from '~/client/static/icons/mapbox-icons/entrance.svg'
import EntrySrc from '~/client/static/icons/mapbox-icons/entry.svg'
import EquipmentSrc from '~/client/static/icons/mapbox-icons/equipment.svg'
import GateSrc from '~/client/static/icons/mapbox-icons/gate.svg'
import GcpSrc from '~/client/static/icons/mapbox-icons/gcp.svg'
import HandWashSrc from '~/client/static/icons/mapbox-icons/hand-wash.svg'
import HoistSrc from '~/client/static/icons/mapbox-icons/hoist.svg'
import LevelSrc from '~/client/static/icons/mapbox-icons/level.svg'
import LevelsSrc from '~/client/static/icons/mapbox-icons/levels.svg'
import LineSrc from '~/client/static/icons/mapbox-icons/line.svg'
import LocationSrc from '~/client/static/icons/mapbox-icons/location.svg'
import LogisticObjectSrc from '~/client/static/icons/mapbox-icons/logistic-object.svg'
import MedicalSrc from '~/client/static/icons/mapbox-icons/medical.svg'
import MeetingPointSrc from '~/client/static/icons/mapbox-icons/meeting-point.svg'
import ParkingSrc from '~/client/static/icons/mapbox-icons/parking.svg'
import PolygonSrc from '~/client/static/icons/mapbox-icons/polygon.svg'
import RectangleSrc from '~/client/static/icons/mapbox-icons/rectangle.svg'
import RouteSrc from '~/client/static/icons/mapbox-icons/route.svg'
import ShaftSrc from '~/client/static/icons/mapbox-icons/shaft.svg'
import SiteLocationSrc from '~/client/static/icons/mapbox-icons/site-location.svg'
import SmokingSrc from '~/client/static/icons/mapbox-icons/smoking.svg'
import StairsSrc from '~/client/static/icons/mapbox-icons/stairs.svg'
import TemperatureSrc from '~/client/static/icons/mapbox-icons/temperature.svg'
import TentSrc from '~/client/static/icons/mapbox-icons/tent.svg'
import ToiletSrc from '~/client/static/icons/mapbox-icons/toilet.svg'
import WalkwaySrc from '~/client/static/icons/mapbox-icons/walkway.svg'
import ZoneSrc from '~/client/static/icons/mapbox-icons/zone.svg'
import TLetterSrc from '~/client/static/icons/t-letter-icon.svg'
import TrailerSrc from '~/client/static/icons/trailer.svg'

import { getProjectUrl } from '../../constants/commonRoutes'
import InitialState from '../../stores/InitialState'
import ProjectsStore from '../../stores/domain/Projects.store'
import MapLayerSelector from '../MapLayersSelector/components/MapLayerSelector'
import СustomEditor from './Editor'
import MapBoxEditorStore, {
  getMapBoxPlaceFromBareCoordinates,
  mapboxFeatureToAddress,
} from './MapBoxEditor.store'

import './MapBoxEditor.scss'

export interface IMapBoxItem {
  item: SitemapItemBase
  coordinates: IGeoJson2DGeographicCoordinates
}

export interface IProjectMarkerInfo {
  coordinates: IGeoJson2DGeographicCoordinates
  projectId: string
  projectName: string
  projectCode: string
}

interface IMapBoxEditorProps {
  viewport: ISitemapGeoPosition

  selectedSitemap?: Sitemap
  latitude: number
  longitude: number
  store: MapBoxEditorStore

  saveSitemapItem?: (item: SitemapItemBase) => Promise<void | boolean>
  selectedSitemapItem?: SitemapItemBase
  creatableAttributeType?: LocationType
  isTextStickerCreationActive?: boolean

  setViewport: (
    viewport: ISitemapGeoPosition,
    latitude?: number,
    longitude?: number,
  ) => void
  width?: number
  offsetY?: number
  offsetX?: number
  height?: number

  sourceBounds?: number[][]
  isRubberMode?: boolean
  sourceGeoCornersMap?: {
    [attrId: string]: number[][]
  }

  features?: Feature[]
  containerWidth?: number
  containerHeight?: number
  markersArray?: IMapBoxItem[]
  layer?: (
    containerWidth: number,
    containerHeight: number,
    width: number,
    height: number,
    rotation: number,
    positionX: number,
    positionY: number,
    id: string,
    isMainLayer?: boolean,
  ) => JSX.Element
  updateLngLat?: (latitude: number, longitude: number) => void
  onAddressChanged?: (address: IProjectAddressInput) => void
  toggleAnnouncementsHiddenState?: () => void
  toggleDeliveriesHiddenState?: () => void
  togglePermitsHiddenState?: () => void
  toggleMonitoringsHiddenState?: () => void

  isDraggingMode?: boolean
  isImageRubberMode?: boolean
  shouldShowImage?: boolean
  isLogisticsView?: boolean
  arePermitsHidden?: boolean
  areAnnouncementsHidden?: boolean
  areDeliveriesHidden?: boolean
  areMonitoringsHidden?: boolean
  isFixed?: boolean
  isDeliveryView?: boolean
  shouldShowProjectMarker?: boolean
  shouldHideControls?: boolean

  areAdditionalMarkersAvailable?: boolean
  state?: InitialState
  projectsStore?: ProjectsStore
}

interface IFeatureStyle {
  feature: Feature
}

interface ILngLntEvent {
  lngLat: number[]
}

const SITEMAP_ICONS = {
  area: AreaSrc,
  bathroom: BathroomSrc,
  break: BreakSrc,
  building: BuildingSrc,
  canteen: CanteenSrc,
  'concrete-truck': ConcreteTruckSrc,
  crane: CraneSrc,
  dumpster: DumpsterSrc,
  elevator: ElevatorSrc,
  shaft: ShaftSrc,
  circle: CircleSrc,
  teams: TeamSrc,
  'electrical-room': ElectricalRoomSrc,
  entrance: EntranceSrc,
  level: LevelSrc,
  line: LineSrc,
  logistics: LocationSrc,
  'elevator-shaft': ElevatorShaftSrc,
  entry: EntrySrc,
  polygon: PolygonSrc,
  rectangle: RectangleSrc,
  temperature: TemperatureSrc,
  equipment: EquipmentSrc,
  gate: GateSrc,
  gcp: GcpSrc,
  'hand-wash': HandWashSrc,
  hoist: HoistSrc,
  levels: LevelsSrc,
  'logistic-object': LogisticObjectSrc,
  medical: MedicalSrc,
  'meeting-point': MeetingPointSrc,
  parking: ParkingSrc,
  route: RouteSrc,
  'site-location': SiteLocationSrc,
  smoking: SmokingSrc,
  stairs: StairsSrc,
  tent: TentSrc,
  toilet: ToiletSrc,
  walkway: WalkwaySrc,
  zone: ZoneSrc,
  tLetter: TLetterSrc,
  trailer: TrailerSrc,
}

const QUARTER_DEGREES = 90
export const UNITS_KILOMETERS: turf.Units = 'kilometers'
const COMMON_MARKER_OFFSET = -10
const PROJECT_MARKER_OFFSET_Y = -20
const CONTROLS_PADDING = '10px'
const MAX_SIZE = '100%'

const GEOLOCATION_STYLE = {
  bottom: 185,
  right: 13,
  padding: '10px',
  zIndex: 100,
  cursor: 'pointer',
}

const NAV_STYLE = {
  right: 0,
  padding: CONTROLS_PADDING,
  zIndex: 100,
  cursor: 'pointer',
}

const SCALE_CONTROL_STYLE = {
  bottom: 114,
  right: 13,
  padding: CONTROLS_PADDING,
  zIndex: 100,
  cursor: 'pointer',
}

const RESET_CONTROL_STYLE = {
  bottom: 80,
  right: 23,
  zIndex: 100,
  cursor: 'pointer',
}

const SCROLL_ZOOM = {
  speed: 1 / 800,
  smooth: true,
}

@inject('state', 'projectsStore')
@observer
export class MapBoxEditor extends React.Component<IMapBoxEditorProps> {
  @observable private selectedProjectMarkerInfo: IProjectMarkerInfo = null
  public state = {
    features: this.props.features,
  }
  @observable private sourcePositions: number[][]
  @observable private positionsMap: {
    [attrId: string]: IPosition
  }
  @observable private sourceGeoCornersPoints: {
    [attrId: string]: number[][]
  }

  public constructor(props: IMapBoxEditorProps) {
    super(props)

    this.sourcePositions = props.store.getCurrentSitemapGeoCorners()
    this.sourceGeoCornersPoints = props.store.getAdditionalSitemapsCorners(true)
  }

  public componentDidUpdate(prevProps: Readonly<IMapBoxEditorProps>): void {
    const { viewport, sourceBounds, sourceGeoCornersMap, features, store } =
      this.props

    if (
      prevProps.features?.length !== features?.length ||
      features !== prevProps.features
    ) {
      this.setState({ features })
    }

    const isViewportChanged =
      viewport.bearing !== prevProps.viewport.bearing ||
      viewport.zoom !== prevProps.viewport.zoom ||
      viewport.latitude !== prevProps.viewport.latitude ||
      viewport.longitude !== prevProps.viewport.longitude

    if (
      (isViewportChanged && sourceBounds?.length) ||
      (sourceGeoCornersMap && !this.positionsMap) ||
      store?.isResetActive
    ) {
      store.isResetActive = false
      this.setUpdatedPositions()
    }
  }

  public render(): JSX.Element {
    const {
      setViewport,
      viewport,
      store,
      shouldHideControls,
      shouldShowProjectMarker,
      width,
      height,
      isDraggingMode,
      isRubberMode,
      isFixed,
      selectedSitemap,
      isLogisticsView,
      toggleDeliveriesHiddenState,
      toggleAnnouncementsHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      arePermitsHidden,
      areDeliveriesHidden,
      areAnnouncementsHidden,
      areMonitoringsHidden,
      containerWidth,
      containerHeight,
      isDeliveryView,
      creatableAttributeType,
      isTextStickerCreationActive,
      selectedSitemapItem,
      areAdditionalMarkersAvailable,
    } = this.props

    const shouldShowAdditionalProjectMarkers =
      areAdditionalMarkersAvailable &&
      store.shouldShowProjectMarker &&
      store.shouldShowAdditionalProjectMarkers

    return (
      <div
        id="map"
        className={classList({
          'absolute-top mapbox-editor map-box-editor-map full-width full-height':
            true,
          'hidden-controls': shouldHideControls,
          'full-width full-height': !width && !height,
          'hidden-cursor':
            isTextStickerCreationActive ||
            !!creatableAttributeType ||
            !!selectedSitemapItem,
        })}
        style={{
          width: containerWidth ? containerWidth : MAX_SIZE,
          height: containerHeight ? containerHeight : MAX_SIZE,
          zIndex: 1,
        }}
      >
        <>
          {/* whole map */}
          {selectedSitemap?.isReferenced && (
            <ReactMapGL
              ref={ref => store?.setCanvasRef(ref)}
              zoom={this.sitemapZoom || selectedSitemap?.zoom}
              pitch={0}
              altitude={selectedSitemap?.altitude}
              bearing={selectedSitemap?.bearing}
              latitude={selectedSitemap?.center.lat}
              longitude={selectedSitemap?.center.lng}
              width={this.fixedImageWidth}
              height={this.fixedImageHeight}
              doubleClickZoom={false}
              mapStyle={store.baseMap}
              mapboxApiAccessToken={mapboxgl.accessToken}
              style={{
                opacity: 0,
                position: 'absolute',
                cursor: this.cursor,
              }}
            />
          )}
          {/* current active map */}
          <ReactMapGL
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            ref={ref => store?.setRefForProjectMap(ref)}
            zoom={
              !isDraggingMode ? this.zoom || viewport?.zoom : viewport?.zoom
            }
            pitch={0}
            altitude={viewport?.altitude}
            bearing={viewport?.bearing}
            latitude={viewport?.latitude}
            longitude={viewport?.longitude}
            doubleClickZoom={false}
            width={width ? containerWidth : MAX_SIZE}
            height={height ? containerHeight : MAX_SIZE}
            onViewportChange={setViewport}
            mapStyle={store.baseMap}
            preserveDrawingBuffer={true}
            mapboxApiAccessToken={mapboxgl.accessToken}
            scrollZoom={SCROLL_ZOOM}
            style={{
              cursor: this.cursor,
            }}
          >
            {/* all secondary maps */}
            {!isRubberMode && !shouldShowProjectMarker && this.sitemaps}
            {/* mapbox native items [relevant for rubbersheeting only] */}
            {selectedSitemap && !!this?.state.features?.length && (
              <div
                style={{
                  width: MAX_SIZE,
                  height: MAX_SIZE,
                  pointerEvents: 'none',
                }}
              >
                <СustomEditor
                  style={{
                    width: MAX_SIZE,
                    height: MAX_SIZE,
                    pointerEvents: 'none',
                  }}
                  features={this.state.features}
                  featureStyle={getFeatureStyle}
                />
              </div>
            )}
            {/* project marker [at some zoom point OR  project creation] */}
            {(shouldShowProjectMarker || store.shouldShowProjectMarker) &&
              this.projectAdressMarker}
            {shouldShowAdditionalProjectMarkers &&
              this.additionalProjectAddressMarkers}
            {/* markers [sitemap items] on canvas */}
            {isRubberMode && selectedSitemap && this.markersToDisplay}
            {store.shouldShowProjectMarker && this.renderProjectPopup()}
            {/* mapbox controls */}
            {!shouldHideControls && (
              <>
                <GeolocateControl
                  className={classList({ fixed: isFixed, absolute: !isFixed })}
                  style={{
                    ...GEOLOCATION_STYLE,
                    bottom: GEOLOCATION_STYLE.bottom,
                  }}
                />
                <NavigationControl
                  className={classList({ fixed: isFixed, absolute: !isFixed })}
                  style={{
                    ...SCALE_CONTROL_STYLE,
                    bottom: SCALE_CONTROL_STYLE.bottom,
                  }}
                  showCompass={false}
                />
                <div
                  className={classList({
                    'mapboxgl-ctrl mapboxgl-ctrl-group brada10 bg-white': true,
                    fixed: isFixed,
                    absolute: !isFixed,
                  })}
                  onClick={this.resetVieport}
                  style={RESET_CONTROL_STYLE}
                >
                  <button className="mapboxgl-ctrl-icon">
                    <Icons.ResetMap />
                  </button>
                </div>
                <Icons.NavigationCompass
                  onClick={this.setZeroBearing}
                  className={classList({ fixed: isFixed, absolute: !isFixed })}
                  style={{
                    ...NAV_STYLE,
                    bottom: 0,
                    transform: `rotate(${viewport.bearing}deg)`,
                  }}
                />
                <div
                  className={classList({
                    fixed: isFixed,
                    absolute: !isFixed,
                    'map-control-container': true,
                  })}
                >
                  <MapLayerSelector
                    isFixed={isFixed}
                    store={store}
                    isLogisticsView={isLogisticsView}
                    toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
                    toggleAnnouncementsHiddenState={
                      toggleAnnouncementsHiddenState
                    }
                    togglePermitsHiddenState={togglePermitsHiddenState}
                    toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
                    areDeliveriesHidden={areDeliveriesHidden}
                    areAnnouncementsHidden={areAnnouncementsHidden}
                    arePermitsHidden={arePermitsHidden}
                    areMonitoringsHidden={areMonitoringsHidden}
                    isDeliveryView={isDeliveryView}
                    areAdditionalMarkersAvailable={
                      areAdditionalMarkersAvailable
                    }
                  />
                </div>
              </>
            )}
          </ReactMapGL>
        </>
      </div>
    )
  }

  private get cursor(): string {
    const { isTextStickerCreationActive, creatableAttributeType } = this.props
    return isTextStickerCreationActive || !!creatableAttributeType
      ? 'unset'
      : 'grab'
  }

  private resetVieport = (): void => {
    this.props.store.setViewportFromAdress()
    this.props.setViewport(this.props.store.viewport)
    this.props.store.isResetActive = true
    this.setUpdatedPositions()
  }

  private onMouseDown = (evt: MapEvent): void => {
    this.props.store.clickCoords = evt.lngLat
  }

  private onMouseMove = (evt: MapEvent): void => {
    this.props.store.moveCoords = evt.lngLat
  }

  private renderProjectPopup(): JSX.Element {
    if (!this.selectedProjectMarkerInfo) {
      return null
    }

    const { coordinates, projectName, projectCode } =
      this.selectedProjectMarkerInfo
    const url = getProjectUrl(projectCode)

    return (
      <Popup
        tipSize={5}
        anchor="top"
        longitude={coordinates.longitude}
        latitude={coordinates.latitude}
        closeOnClick={false}
        className="mapbox-popup"
        onClose={this.setPopupInfo.bind(this, null)}
        onClick={this.openInNewTab.bind(this, url)}
      >
        {projectName}
      </Popup>
    )
  }

  @action.bound
  private setPopupInfo(info: IProjectMarkerInfo): void {
    this.selectedProjectMarkerInfo = info
  }

  @action.bound
  private openInNewTab(url: string): void {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  @computed
  private get projectsMarkers(): IProjectMarkerInfo[] {
    const { projectsStore } = this.props

    return projectsStore.list
      .map(project => projectsStore.getProjectMarkerInfo(project))
      .filter(project => !!project)
  }

  private get additionalProjectAddressMarkers(): JSX.Element[] {
    const { id: activeProjectId } = this.props.state.activeProject

    return this.projectsMarkers
      .filter(marker => marker.projectId !== activeProjectId)
      .map(marker => {
        const { projectId, coordinates } = marker
        return (
          <Marker
            key={projectId}
            latitude={coordinates.latitude}
            longitude={coordinates.longitude}
            offsetTop={PROJECT_MARKER_OFFSET_Y}
            offsetLeft={COMMON_MARKER_OFFSET}
            draggable={false}
            onClick={this.setPopupInfo.bind(null, marker)}
            className="additional-project-marker"
          >
            <div className="pointer">
              <Icons.ProjectLocation
                onClick={this.setPopupInfo.bind(null, marker)}
              />
            </div>
          </Marker>
        )
      })
  }

  private get projectAdressMarker(): JSX.Element {
    return (
      <Marker
        latitude={this.props.latitude}
        longitude={this.props.longitude}
        onDragStart={this.onProjectMarkerDragStart}
        onDrag={this.onProjectMarkerDrag}
        onDragEnd={this.onProjectMarkerDragEnd}
        offsetTop={PROJECT_MARKER_OFFSET_Y}
        offsetLeft={COMMON_MARKER_OFFSET}
        draggable={true}
      >
        <div>
          <Icons.ProjectLocation />
        </div>
      </Marker>
    )
  }

  // sitemaps on the mapbox
  private get sitemaps(): JSX.Element {
    const {
      layer,
      viewport: { bearing },
      selectedSitemap,
      store: { sitemapsRotations, isMainMapMode },
      containerWidth,
      containerHeight,
    } = this.props
    const sitemapIds = this.positionsMap && Object.keys(this.positionsMap)

    return (
      <>
        <div style={{ width: MAX_SIZE, height: MAX_SIZE }}>
          {layer &&
            sitemapIds?.map(id => {
              const positions = this.positionsMap[id]

              if (id === selectedSitemap.id) {
                return layer(
                  containerWidth,
                  containerHeight,
                  this.fixedImagesWidth[id],
                  this.fixedImagesHeight[id],
                  sitemapsRotations[id] - bearing,
                  positions.x,
                  positions.y,
                  id,
                  true,
                )
              }
              return !isMainMapMode
                ? layer(
                    containerWidth,
                    containerHeight,
                    this.fixedImagesWidth[id],
                    this.fixedImagesHeight[id],
                    sitemapsRotations[id] - bearing,
                    positions.x,
                    positions.y,
                    id,
                  )
                : null
            })}
        </div>
      </>
    )
  }

  // distance between ton and bottom points ration between initial and current sizes
  private get fixedImageHeight(): number {
    const { sourceBounds, height: containerHeight } = this.props
    const currentPosition = this.sourcePositions || sourceBounds

    const initialHeight = sourceBounds
      ? getDistance(
          sourceBounds[0][0],
          sourceBounds[3][0],
          sourceBounds[0][1],
          sourceBounds[3][1],
        )
      : 100
    const currentHeight = sourceBounds
      ? getDistance(
          currentPosition[0][0],
          currentPosition[3][0],
          currentPosition[0][1],
          currentPosition[3][1],
        )
      : 100

    return (initialHeight * containerHeight) / currentHeight
  }

  // distance between left and rigth points ration between initial and current sizes
  private get fixedImageWidth(): number {
    const { sourceBounds, width: containerWidth } = this.props
    const currentPosition = this.sourcePositions || sourceBounds

    const initialWidth = sourceBounds
      ? getDistance(
          sourceBounds[0][0],
          sourceBounds[1][0],
          sourceBounds[0][1],
          sourceBounds[1][1],
        )
      : 100
    const currentWidth = sourceBounds
      ? getDistance(
          currentPosition[0][0],
          currentPosition[1][0],
          currentPosition[0][1],
          currentPosition[1][1],
        )
      : 100

    return (initialWidth * containerWidth) / currentWidth
  }

  // distance between top and bottom points of every secondary image
  private get fixedImagesHeight(): { [atrrId: string]: number } {
    return Object.keys(this.sourceGeoCornersPoints).reduce((map, key) => {
      const sourceGeoCornersPoint = this.sourceGeoCornersPoints[key]
      map[key] = Math.sqrt(
        Math.pow(sourceGeoCornersPoint[0][0] - sourceGeoCornersPoint[3][0], 2) +
          Math.pow(
            sourceGeoCornersPoint[0][1] - sourceGeoCornersPoint[3][1],
            2,
          ),
      )
      return map
    }, {})
  }

  // distance between left and rigth points of every secondary image [calculate width changes based on the current zoom]
  private get fixedImagesWidth(): { [atrrId: string]: number } {
    return Object.keys(this.sourceGeoCornersPoints).reduce((map, key) => {
      const sourceGeoCornersPoint = this.sourceGeoCornersPoints[key]
      map[key] = Math.sqrt(
        Math.pow(sourceGeoCornersPoint[0][0] - sourceGeoCornersPoint[1][0], 2) +
          Math.pow(
            sourceGeoCornersPoint[0][1] - sourceGeoCornersPoint[1][1],
            2,
          ),
      )
      return map
    }, {})
  }

  private onProjectMarkerDragStart = async (
    event: ILngLntEvent,
  ): Promise<void> => {
    this.props.updateLngLat(event.lngLat[1], event.lngLat[0])
  }

  private onProjectMarkerDrag = async (event: ILngLntEvent): Promise<void> => {
    this.props.updateLngLat(event.lngLat[1], event.lngLat[0])
  }

  private onProjectMarkerDragEnd = async (
    event: ILngLntEvent,
  ): Promise<void> => {
    this.props.updateLngLat(event.lngLat[1], event.lngLat[0])

    const item = await getMapBoxPlaceFromBareCoordinates(
      event.lngLat[0],
      event.lngLat[1],
      mapboxgl.accessToken,
    )

    const addr = mapboxFeatureToAddress(item)

    this.props.onAddressChanged?.(addr)
  }

  // reset map orientation to the initial sitemap's value
  private setZeroBearing = (): void => {
    this.props.store.setViewportProp(0, 'bearing')
  }

  // sitemap items
  private get markersToDisplay(): JSX.Element[] {
    const {
      markersArray,
      store: { movableItemId, movableItemCoordinates },
    } = this.props

    return markersArray?.map(({ item, coordinates }) => {
      const imgData = SITEMAP_ICONS[item.iconName]
      const coordinatesFromStore =
        item.id === movableItemId && movableItemCoordinates

      const latitude = coordinatesFromStore?.latitude || coordinates?.latitude
      const longitude =
        coordinatesFromStore?.longitude || coordinates?.longitude

      return (
        <Marker
          key={item.id}
          latitude={latitude}
          longitude={longitude}
          onDragEnd={this.onMarkerDragEnd.bind(null, item)}
          draggable={true}
          offsetTop={COMMON_MARKER_OFFSET}
          offsetLeft={COMMON_MARKER_OFFSET}
        >
          <div>
            <InlineSVG
              src={imgData}
              style={{ fill: item.color }}
              className="row"
            />
            <div className="bg-white col brada4 x-center marker-description absolute">
              <span style={{ minWidth: 'max-content' }}>
                {item.displayName}
              </span>
            </div>
          </div>
        </Marker>
      )
    })
  }

  private onMarkerDragEnd = async (
    item: SitemapItemBase,
    event: ILngLntEvent,
  ): Promise<void> => {
    const {
      width,
      height,
      store: { setActiveItem, setActiveItemCoordinates, getUpdatedItemCoords },
    } = this.props

    setActiveItem(item.id)
    const coordinates = {
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    }

    let itemToSave
    setActiveItemCoordinates(coordinates)
    item.coordinates = coordinates
    item.sitemapItem.coordinates = coordinates
    if (item.sitemapItem.isReferenced) {
      itemToSave = getUpdatedItemCoords(item, false, width, height)
      itemToSave.sitemapItem.coordinates = coordinates
    }
    await this.props.saveSitemapItem?.(itemToSave || item)
    setActiveItem(null)
  }

  // zoom of the background mapbox calculated on diff of sitemap current zoom and global mapbox bounds
  private get zoom(): number {
    const {
      viewport,
      shouldShowProjectMarker,
      width,
      height,
      selectedSitemap,
    } = this.props

    if (!selectedSitemap) {
      return null
    }

    const { bounds } = viewport

    let desiredZoom: number

    if (bounds && !shouldShowProjectMarker && selectedSitemap.width) {
      if (bounds?._sw || bounds?._ne) {
        bounds.sw = bounds._sw
        bounds.ne = bounds._ne
      }

      const currentViewport = new WebMercatorViewport({
        width: selectedSitemap.width,
        height: selectedSitemap.height,
      }).fitBounds(
        [
          [bounds.sw.lng, bounds.ne.lat],
          [bounds.ne.lng, bounds.sw.lat],
        ],
        {},
      )

      const sitemapViewport = new WebMercatorViewport({
        width,
        height,
      }).fitBounds(
        [
          [selectedSitemap.bounds.sw.lng, selectedSitemap.bounds.ne.lat],
          [selectedSitemap.bounds.ne.lng, selectedSitemap.bounds.sw.lat],
        ],
        {},
      )
      desiredZoom = currentViewport.zoom - sitemapViewport.zoom
    }

    return selectedSitemap.zoom - desiredZoom
  }

  // zoom of the current sitemap mapbox calculated on diff of current mapbox bounds and its size
  private get sitemapZoom(): number {
    const { shouldShowProjectMarker, selectedSitemap } = this.props

    if (!selectedSitemap) {
      return null
    }

    const { bounds, width, height, zoom } = selectedSitemap

    let desiredZoom: number

    if (bounds && !shouldShowProjectMarker && width) {
      const currentViewport = new WebMercatorViewport({
        width,
        height,
      }).fitBounds(
        [
          [bounds.sw.lng, bounds.ne.lat],
          [bounds.ne.lng, bounds.sw.lat],
        ],
        {},
      )

      const sitemapViewport = new WebMercatorViewport({
        width: this.fixedImageWidth,
        height: this.fixedImageHeight,
      }).fitBounds(
        [
          [bounds.sw.lng, bounds.ne.lat],
          [bounds.ne.lng, bounds.sw.lat],
        ],
        {},
      )
      desiredZoom = currentViewport.zoom - sitemapViewport.zoom
    }

    return zoom - desiredZoom
  }

  // update items' position on sitemap change
  private setUpdatedPositions = (): void => {
    const {
      store: {
        getAdditionalSitemapsCorners,
        getCurrentSitemapGeoCorners,
        canvasMap,
      },
      sourceBounds,
      sourceGeoCornersMap,
    } = this.props

    if (!canvasMap?.getMap?.() || !sourceBounds) {
      return
    }

    this.sourcePositions = getCurrentSitemapGeoCorners()
    this.sourceGeoCornersPoints = getAdditionalSitemapsCorners(true)

    this.positionsMap = Object.keys(sourceGeoCornersMap).reduce((map, key) => {
      map[key] = canvasMap
        .getMap()
        .project([
          sourceGeoCornersMap[key][0][0],
          sourceGeoCornersMap[key][0][1],
        ])
      return map
    }, {})
  }
}

// styling for the shapes in the mapbox native
export function getFeatureStyle({ feature: { properties } }: IFeatureStyle) {
  return {
    stroke: properties.stroke,
    strokeWidth: properties.strokeWidth,
    fill: properties.fill,
    fillOpacity: properties.fillOpacity,
    strokeDasharray: properties.strokeDasharray,
  }
}

export function getDistance(
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  units: turf.Units = UNITS_KILOMETERS,
): number {
  const from = turf.point([x1, y1])
  const to = turf.point([x2, y2])
  const options = { units }
  return turf.distance(from, to, options)
}

// creates circle for mapbox displaying
export function createGeoJSONCircle(
  center: IGeoJson2DGeographicCoordinates,
  radiusInKm: number,
  baseBearing: number = 0,
  divisionStartAngle?: number,
  divisionEndAngle?: number,
): turf.helpers.Position[][] {
  if (divisionStartAngle && divisionEndAngle) {
    const acrCenter = [center.longitude, center.latitude]
    // Line arc require to rotate by quarter
    const arc = turf.lineArc(
      acrCenter,
      radiusInKm,
      divisionStartAngle + QUARTER_DEGREES + baseBearing,
      divisionEndAngle + QUARTER_DEGREES + baseBearing,
    )

    arc.geometry.coordinates.push([center.longitude, center.latitude])

    return [arc.geometry.coordinates]
  }
  const centerT = [center.longitude, center.latitude]
  const options = {
    steps: 50,
    units: UNITS_KILOMETERS,
    properties: { foo: 'bar' },
  }

  const circle = turf.circle(centerT, radiusInKm, options)

  return circle.geometry.coordinates
}

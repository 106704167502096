import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { Stage } from 'konva/types/Stage'
import { observer } from 'mobx-react'
import { Arc, Circle, Group, Rect } from 'react-konva'

import { IPosition } from '~/client/graph'
import MapBoxEditorStore from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor.store'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import {
  MAX_RELATIVE_POSITION,
  sitemapMousePointTo,
  validatePosition,
} from '~/client/src/shared/utils/SitemapCalculationHelpers'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import SitemapItemsSetupStore from '../../stores/SitemapItemsSetup.store'

import Colors from '~/client/src/shared/theme.module.scss'

const HIGHLIGHT_CIRCLE_STROKE_WIDTH = 3
const NEW_RADIUS_CORRELATION = HIGHLIGHT_CIRCLE_STROKE_WIDTH / 2

const BORDER_STOP_RESIZING_DISTANCE = 3

const RESIZE_CURSOR_DEFINITION = 'nwse-resize'
const DEFAULT_CURSOR_DEFINITION = 'default'

const MIN_POINT_RADIUS = 5
const POINT_STROKE_WIDTH = 3

const DEGREE_IN_RADIANS = Math.PI / 180
const { getHEXColor } = ThemeMode

interface IProps {
  store: SitemapItemsSetupStore
  circle: SitemapCircleProperties
  containerHeight: number
  containerWidth: number

  mapBoxEditorStore: MapBoxEditorStore
  isReferenced: boolean

  onModifyStart: () => void
  onModifyEnd: () => void
}

@observer
export default class CircleEditor extends React.Component<IProps> {
  private isResizing: boolean = false

  public render() {
    const { containerHeight, containerWidth, circle } = this.props

    const centerX = (containerWidth * circle.position.x) / MAX_RELATIVE_POSITION
    const centerY =
      (containerHeight * circle.position.y) / MAX_RELATIVE_POSITION
    const radius = (containerHeight * circle.radius) / MAX_RELATIVE_POSITION

    const highlightCircleRadius =
      radius + (circle.lineWidth + HIGHLIGHT_CIRCLE_STROKE_WIDTH) / 2

    let angle = 360
    let rotation = 0
    if (circle.isDividedAndDivisionAnglesValid) {
      rotation = circle.divisionStartAngle
      angle = circle.divisionEndAngle - circle.divisionStartAngle
    }

    return (
      <Group onMouseUp={this.stopResizing}>
        <Rect
          width={containerWidth}
          height={containerHeight}
          x={0}
          y={0}
          onMouseMove={this.onBackdropMouseMove}
        />
        <Arc
          x={centerX}
          y={centerY}
          outerRadius={
            highlightCircleRadius + HIGHLIGHT_CIRCLE_STROKE_WIDTH / 2
          }
          innerRadius={
            highlightCircleRadius - HIGHLIGHT_CIRCLE_STROKE_WIDTH / 2
          }
          angle={angle}
          rotation={rotation}
          fill={Colors.neutral100}
          onMouseEnter={this.setResizeCursor}
          onMouseLeave={this.setDefaultCursor}
          onMouseDown={this.startResizing}
          onClick={this.cancelBubble}
        />
        {this.renderChangeDivisionAnglePoints(centerX, centerY, radius)}
      </Group>
    )
  }

  private renderChangeDivisionAnglePoints(
    centerX: number,
    centerY: number,
    radius: number,
  ) {
    const {
      isDividedAndDivisionAnglesValid,
      divisionStartAngle,
      divisionEndAngle,
      lineWidth,
    } = this.props.circle
    if (!isDividedAndDivisionAnglesValid) {
      return null
    }

    const activePointRadius = Math.max(MIN_POINT_RADIUS, lineWidth)

    return (
      <>
        <Circle
          x={
            centerX + radius * Math.cos(divisionStartAngle * DEGREE_IN_RADIANS)
          }
          y={
            centerY + radius * Math.sin(divisionStartAngle * DEGREE_IN_RADIANS)
          }
          radius={activePointRadius}
          fill={getHEXColor(Colors.neutral100)}
          stroke={getHEXColor(Colors.neutral100)}
          strokeWidth={POINT_STROKE_WIDTH}
          draggable={true}
          onDragMove={this.onStartAnglePointDragMove}
          dragBoundFunc={this.getAngleChangeDragBoundsFunction(
            centerX,
            centerY,
            radius,
          )}
        />
        <Circle
          x={centerX + radius * Math.cos(divisionEndAngle * DEGREE_IN_RADIANS)}
          y={centerY + radius * Math.sin(divisionEndAngle * DEGREE_IN_RADIANS)}
          radius={activePointRadius}
          fill={getHEXColor(Colors.neutral100)}
          stroke={getHEXColor(Colors.neutral100)}
          strokeWidth={POINT_STROKE_WIDTH}
          draggable={true}
          onDragMove={this.onEndAnglePointDragMove}
          dragBoundFunc={this.getAngleChangeDragBoundsFunction(
            centerX,
            centerY,
            radius,
          )}
        />
      </>
    )
  }

  private onStartAnglePointDragMove = (event: KonvaEventObject<DragEvent>) => {
    const stage = event.currentTarget.getStage()
    const angle = this.getPointAngle(stage)
    this.props.circle.setDivisionStartAngle(angle)
  }

  private onEndAnglePointDragMove = (event: KonvaEventObject<DragEvent>) => {
    const stage = event.currentTarget.getStage()

    const angle = this.getPointAngle(stage)
    this.props.circle.setDivisionEndAngle(angle)
  }

  private getPointAngle(stage: Stage): number {
    const { containerHeight, containerWidth, circle } = this.props

    const mousePointTo = sitemapMousePointTo(
      stage,
      containerWidth,
      containerHeight,
    )
    const relativePosition = validatePosition({
      x: (mousePointTo.x / containerWidth) * MAX_RELATIVE_POSITION,
      y: (mousePointTo.y / containerHeight) * MAX_RELATIVE_POSITION,
    })

    return (
      Math.atan2(
        relativePosition.y - circle.position.y,
        relativePosition.x - circle.position.x,
      ) / DEGREE_IN_RADIANS
    )
  }

  private getAngleChangeDragBoundsFunction(
    centerX: number,
    centerY: number,
    radius: number,
  ) {
    return (pos: { x: number; y: number }) => {
      const scale =
        radius /
        Math.sqrt(Math.pow(pos.x - centerX, 2) + Math.pow(pos.y - centerY, 2))
      if (scale === 1) {
        return pos
      } else {
        return {
          y: Math.round((pos.y - centerY) * scale + centerY),
          x: Math.round((pos.x - centerX) * scale + centerX),
        }
      }
    }
  }

  private setResizeCursor = () => {
    document.body.style.cursor = RESIZE_CURSOR_DEFINITION
  }

  private setDefaultCursor = () => {
    document.body.style.cursor = DEFAULT_CURSOR_DEFINITION
  }

  private startResizing = (event: KonvaEventObject<MouseEvent>) => {
    this.cancelBubble(event)
    this.isResizing = true
    this.props.onModifyStart()
  }

  private stopResizing = (event?: KonvaEventObject<MouseEvent>) => {
    this.cancelBubble(event)
    if (this.isResizing) {
      this.props.store.addCurrentStateToHistory()
    }
    this.props.onModifyEnd()
    this.isResizing = false
  }

  private onBackdropMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    if (!this.isResizing) {
      return
    }
    const {
      containerHeight,
      containerWidth,
      circle,
      isReferenced,
      mapBoxEditorStore,
    } = this.props
    const { canvas, moveCoords } = mapBoxEditorStore
    const stage = event.currentTarget.getStage()

    const mousePointTo = isReferenced
      ? canvas?.getMap?.()?.project(moveCoords)
      : sitemapMousePointTo(stage, containerWidth, containerHeight)

    const circleCenter = {
      x: (containerWidth * circle.position.x) / MAX_RELATIVE_POSITION,
      y: (containerHeight * circle.position.y) / MAX_RELATIVE_POSITION,
    }

    const newRadius =
      this.pointsDistance(circleCenter, mousePointTo) - NEW_RADIUS_CORRELATION
    circle.setRadius((newRadius / containerHeight) * MAX_RELATIVE_POSITION)

    // stop resizing when cursor is out of canvas
    if (
      [
        mousePointTo.x,
        containerWidth - mousePointTo.x,
        mousePointTo.y,
        containerHeight - mousePointTo.y,
      ].some(value => value < BORDER_STOP_RESIZING_DISTANCE)
    ) {
      this.stopResizing()
    }
  }

  private pointsDistance(p1: IPosition, p2: IPosition) {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
  }

  private cancelBubble = (event?: KonvaEventObject<MouseEvent>) => {
    if (event) {
      event.cancelBubble = true
    }
  }
}

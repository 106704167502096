import { observable } from 'mobx'

import { IPosition, ISitemapPin } from '~/client/graph'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

import { IBoundingBox } from './SitemapItemBase'

const DEFAULT_IS_DISPLAYED = true
const DEFAULT_POSITION = {
  x: 50,
  y: 50,
}
const MAX_PERCENT = 100

const DEFAULT_MID_ICON_SIZE = {
  width: 24,
  height: 24,
}
const DEFAULT_LARGE_ICON_SIZE = {
  width: 32,
  height: 32,
}

const ICON_SIZE_MAP = {
  [SitemapAttributeIcon.Gate]: DEFAULT_LARGE_ICON_SIZE,
  [SitemapAttributeIcon.Zone]: {
    width: 22,
    height: 32,
  },
  [SitemapAttributeIcon.Building]: {
    width: 24,
    height: 32,
  },
  [SitemapAttributeIcon.Route]: DEFAULT_LARGE_ICON_SIZE,
  [SitemapAttributeIcon.Equipment]: DEFAULT_LARGE_ICON_SIZE,
  [SitemapAttributeIcon.Level]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Area]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Logistics]: {
    width: 18,
    height: 26,
  },
  [SitemapAttributeIcon.Bathroom]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Break]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Dumpster]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Elevator]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Shaft]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Entrance]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.HandWash]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Medical]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.MeetingPoint]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Parking]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Smoking]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Stairs]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Temperature]: {
    width: 18,
    height: 24,
  },
  [SitemapAttributeIcon.Tent]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Walkway]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.ElectricalRoom]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Trailer]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Crane]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Hoist]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.AerialLift]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.Gradall]: DEFAULT_MID_ICON_SIZE,
  [SitemapAttributeIcon.ForkLift]: DEFAULT_MID_ICON_SIZE,
}

const LABEL_OFFSET = 2.5
const DEGREE_IN_RADIANS = Math.PI / 180

export default class SitemapIconProperties {
  @observable public isDisplayed: boolean
  @observable public position: IPosition

  public constructor(isDisplayed?: boolean, position?: IPosition) {
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
    this.position = position || DEFAULT_POSITION
  }

  public getBoundingBox(
    iconName: SitemapAttributeIcon,
    containerWidth: number,
    containerHeight: number,
  ): IBoundingBox {
    const dimensions = ICON_SIZE_MAP[iconName]
    if (!dimensions) {
      return null
    }
    const { width, height } = dimensions

    const x = (containerWidth * this.position.x) / MAX_PERCENT
    const y = (containerHeight * this.position.y) / MAX_PERCENT

    return {
      x: x - width / 2,
      y: y - height / 2,
      width,
      height,
    }
  }

  public isValid(): boolean {
    return !!this.position
  }

  public move(x: number, y: number) {
    this.position = {
      x: this.position.x + x,
      y: this.position.y + y,
    }
  }

  public calculateLabelPosition(): IPosition {
    return {
      x: this.position.x,
      y: this.position.y + LABEL_OFFSET,
    }
  }

  public isPositionEqual(position: IPosition) {
    if (!this.position) {
      return !position
    }
    return (
      position &&
      this.position.x === position.x &&
      this.position.y === position.y
    )
  }

  public setPosition(position: IPosition) {
    this.position = position
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public isDisplayDataEqual(pin: ISitemapPin) {
    return (
      !pin.isHidden === this.isDisplayed && this.isPositionEqual(pin.position)
    )
  }

  public getDisplayData(): ISitemapPin {
    return {
      isHidden: !this.isDisplayed,
      position: {
        x: this.position.x,
        y: this.position.y,
      },
    }
  }

  public scale(originPoint: IPosition, scaleX: number, scaleY: number) {
    this.position.x = scaleX * (this.position.x - originPoint.x) + originPoint.x
    this.position.y = scaleY * (this.position.y - originPoint.y) + originPoint.y
  }

  public rotate(center: IPosition, angle: number) {
    let { x, y } = this.position

    x -= center.x
    y -= center.y

    const cos = Math.cos(angle * DEGREE_IN_RADIANS)
    const sin = Math.sin(angle * DEGREE_IN_RADIANS)

    const newX = x * cos - y * sin
    const newY = x * sin + y * cos

    this.position = {
      x: newX + center.x,
      y: newY + center.y,
    }
  }

  public copy(): SitemapIconProperties {
    return new SitemapIconProperties(
      this.isDisplayed,
      this.position && {
        x: this.position.x,
        y: this.position.y,
      },
    )
  }
}

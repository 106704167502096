import { action, observable } from 'mobx'

import PermitType from '~/client/src/shared/models/PermitType'

export default class NonWorkTimesConfiguratorStore {
  @observable public shouldBlockOnNonWorkTimes: boolean = false

  public constructor(
    permitType: PermitType,
    private readonly onNonWorkTimesChange: (
      shouldBlockOnNonWorkTimes: boolean,
    ) => void,
  ) {
    this.init(permitType)
  }

  @action.bound
  public init({ shouldBlockOnNonWorkTimes }: PermitType) {
    this.shouldBlockOnNonWorkTimes = shouldBlockOnNonWorkTimes
  }

  @action.bound
  public changeNonWorkState() {
    this.shouldBlockOnNonWorkTimes = !this.shouldBlockOnNonWorkTimes

    this.onNonWorkTimesChange(this.shouldBlockOnNonWorkTimes)
  }
}

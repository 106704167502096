import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_PATH =
  'M14 0c-1.067 0-2 .933-2 2s.933 2 2 2 2-.933 2-2-.933-2-2-2zM3.333 1.333c-.066 0-.2 0-.266.067C1.4 1.533 0 2.933 0 4.667 0 6.4 1.4 7.8 3.067 7.933c.066.067.2.067.266.067h9.334c1.133 0 2 .867 2 2s-.867 2-2 2H7.6l1.533-1.533c.267-.267.267-.667 0-.934-.266-.266-.666-.266-.933 0L5.533 12.2c-.266.267-.266.667 0 .933L8.2 15.8c.267.267.667.267.933 0 .267-.267.267-.667 0-.933L7.6 13.333h5.067c.066 0 .2 0 .266-.066C14.667 13.133 16 11.733 16 10c0-1.733-1.4-3.133-3.067-3.267-.066-.066-.2-.066-.266-.066H3.333c-1.133 0-2-.867-2-2 0-1.134.867-2 2-2H10c.4 0 .667-.267.667-.667S10.4 1.333 10 1.333H3.333zm10.667 0c.4 0 .667.267.667.667s-.267.667-.667.667-.667-.267-.667-.667.267-.667.667-.667zM2 10.667c-1.067 0-2 .933-2 2 0 1.066.933 2 2 2s2-.934 2-2c0-1.067-.933-2-2-2zM2 12c.4 0 .667.267.667.667s-.267.666-.667.666-.667-.266-.667-.666S1.6 12 2 12z'
const ICON_SIZE = 20

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const ICON_SCALE = 0.75
const CIRCLE_STROKE_WIDTH = 1
const ICON_OFFSET = 4
const { getHEXColor } = ThemeMode

const KonvaRouteIcon = ({ color, children, ...rest }) => {
  const halfSize = ICON_SIZE / 2

  return (
    <Group {...rest} offsetX={ICON_SIZE / 2} offsetY={ICON_SIZE / 2}>
      {children}
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={CIRCLE_STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={ICON_PATH}
        fill={getHEXColor(Colors.neutral100)}
        x={ICON_OFFSET}
        y={ICON_OFFSET}
        scaleY={ICON_SCALE}
        scaleX={ICON_SCALE}
      />
    </Group>
  )
}

export default KonvaRouteIcon

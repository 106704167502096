import * as React from 'react'

import {
  ELEMENT_TYPE,
  Editor,
  GEOJSON_TYPE,
  GeoJsonType,
  RENDER_STATE,
  SHAPE,
} from 'react-map-gl-draw'

// Native mapbox renderer for shapes
export default class СustomEditor extends Editor {
  /* HELPERS */
  public _getPathInScreenCoords(coordinates: any, type: GeoJsonType) {
    if (coordinates.length === 0) {
      return ''
    }

    const screenCoords = coordinates
      .filter(p => p.length === 2)
      .map(p => this.project(p))

    let pathString = ''
    switch (type) {
      case GEOJSON_TYPE.POINT:
        return screenCoords

      case GEOJSON_TYPE.LINE_STRING:
        pathString = screenCoords.map(p => `${p[0]},${p[1]}`).join('L')
        return `M ${pathString}`

      case GEOJSON_TYPE.POLYGON:
        pathString = screenCoords.map(p => `${p[0]},${p[1]}`).join('L')
        return `M ${pathString} z`

      default:
        return null
    }
  }

  public _getEditHandleState = (
    editHandle,
    renderState: string | null | undefined,
  ) => {
    const { pointerDownPicks, hovered } = this.state

    if (renderState) {
      return renderState
    }

    const editHandleIndex = editHandle.properties.positionIndexes[0]
    let draggingEditHandleIndex = null
    const pickedObject =
      pointerDownPicks && pointerDownPicks[0] && pointerDownPicks[0].object
    if (pickedObject && pickedObject.guideType === 'editHandle') {
      draggingEditHandleIndex = pickedObject.index
    }

    if (editHandleIndex === draggingEditHandleIndex) {
      return RENDER_STATE.SELECTED
    }
    // @ts-ignore: @Val considered that it was necessary
    if (hovered && hovered.type === ELEMENT_TYPE.EDIT_HANDLE) {
      if (hovered.index === editHandleIndex) {
        return RENDER_STATE.HOVERED
      }

      // cursor hovered on first vertex when drawing polygon
      if (
        hovered.index === 0 &&
        editHandle.properties.guideType === 'cursorEditHandle'
      ) {
        return RENDER_STATE.CLOSING
      }
    }

    return RENDER_STATE.INACTIVE
  }

  /* RENDER */

  public _renderEditHandle = (editHandle, feature) => {
    const coordinates = getFeatureCoordinates(editHandle)
    const p = this.project(coordinates?.[0])
    if (!p) {
      return null
    }

    const {
      properties: { featureIndex, positionIndexes, editHandleType },
    } = editHandle
    const { clickRadius, editHandleShape, editHandleStyle } = this.props

    const index =
      positionIndexes.length > 1 ? positionIndexes[1] : positionIndexes[0]

    const shape = this._getStyleProp(editHandleShape, {
      feature: feature || editHandle,
      index,
      featureIndex,
      state: this._getEditHandleState(editHandle, null),
    })

    let style = this._getStyleProp(editHandleStyle, {
      feature: feature || editHandle,
      index,
      featureIndex,
      shape,
      state: this._getEditHandleState(editHandle, null),
    })

    // disable events for cursor editHandle
    if (editHandle.properties.guideType === 'cursorEditHandle') {
      style = {
        ...style,
        // disable pointer events for cursor
        pointerEvents: 'none',
      }
    }

    const elemKey = `${ELEMENT_TYPE.EDIT_HANDLE}.${featureIndex}.${index}.${editHandleType}`
    // first <circle|rect> is to make path easily interacted with
    switch (shape) {
      case 'circle':
        return (
          <g key={elemKey} transform={`translate(${p[0]}, ${p[1]})`}>
            <circle
              data-type={ELEMENT_TYPE.EDIT_HANDLE}
              data-index={index}
              data-feature-index={featureIndex}
              key={`${elemKey}.hidden`}
              style={{ ...style, stroke: 'none', fill: '#000', fillOpacity: 0 }}
              cx={0}
              cy={0}
              r={clickRadius}
            />
            <circle
              data-type={ELEMENT_TYPE.EDIT_HANDLE}
              data-index={index}
              data-feature-index={featureIndex}
              key={elemKey}
              style={style}
              cx={0}
              cy={0}
            />
          </g>
        )
      case 'rect':
        return (
          <g key={elemKey} transform={`translate(${p[0]}, ${p[1]})`}>
            <rect
              data-type={ELEMENT_TYPE.EDIT_HANDLE}
              data-index={index}
              data-feature-index={featureIndex}
              key={`${elemKey}.hidden`}
              style={{
                ...style,
                height: clickRadius,
                width: clickRadius,
                fill: '#000',
                fillOpacity: 0,
              }}
              r={clickRadius}
            />
            <rect
              data-type={ELEMENT_TYPE.EDIT_HANDLE}
              data-index={index}
              data-feature-index={featureIndex}
              key={`${elemKey}`}
              style={style}
            />
          </g>
        )

      default:
        return null
    }
  }

  public _renderPath = (feature, index: number, path: string) => {
    const { featureStyle, clickRadius } = this.props
    const selectedFeatureIndex = this._getSelectedFeatureIndex()
    const selected = index === selectedFeatureIndex
    const renderState = this._getFeatureRenderState(index, null)
    const style = this._getStyleProp(featureStyle, {
      feature,
      index,
      state: renderState,
    })
    const coordinates = getFeatureCoordinates(feature)

    const elemKey = `feature.${index}`
    if (selected) {
      return (
        <g key={elemKey}>{this._renderSegments(index, coordinates, style)}</g>
      )
    }

    return (
      <g key={elemKey}>
        <path
          data-type={ELEMENT_TYPE.FEATURE}
          data-feature-index={index}
          key={`${elemKey}.hidden`}
          style={{
            ...style,
            stroke: 'rgba(0,0,0,0)',
            strokeWidth: clickRadius,
            opacity: 0,
          }}
          d={path}
        />
        <path
          data-type={ELEMENT_TYPE.FEATURE}
          data-feature-index={index}
          key={elemKey}
          style={style}
          d={path}
        />
      </g>
    )
  }

  public _renderLineString = (feature, index: number, path: string) => {
    const { featureStyle } = this.props
    const selectedFeatureIndex = this._getSelectedFeatureIndex()
    const selected = index === selectedFeatureIndex
    // @ts-ignore: @Val considered that it was necessary (maybe just pass null?)
    const renderState = this._getFeatureRenderState(index)
    const style = this._getStyleProp(featureStyle, {
      feature,
      index,
      state: renderState,
    })

    const elemKey = `feature.${index}`
    if (selected) {
      const coordinates = getFeatureCoordinates(feature)
      if (!coordinates) {
        return null
      }
      return (
        <g key={elemKey}>{this._renderSegments(index, coordinates, style)}</g>
      )
    }

    return (
      <path
        data-type={ELEMENT_TYPE.FEATURE}
        data-feature-index={index}
        key={elemKey}
        style={style}
        d={path}
      />
    )
  }

  public _renderFeature = (feature, index: number) => {
    const coordinates = getFeatureCoordinates(feature)
    // @ts-ignore: @Val considered that it was necessary
    if (!coordinates || !coordinates.length) {
      return null
    }
    const {
      properties: { shape, drawType },
      geometry: { type: geojsonType },
    } = feature
    // @ts-ignore: @Val considered that it was necessary
    const path = this._getPathInScreenCoords(coordinates, drawType)
    if (!path) {
      return null
    }

    const type = drawType || shape || geojsonType
    switch (type) {
      case SHAPE.POINT:
        return this._renderPoint(feature, index, path)
      case SHAPE.LINE_STRING:
        return this._renderLineString(feature, index, path)

      case SHAPE.CIRCLE:
      case SHAPE.POLYGON:
      case SHAPE.RECTANGLE:
        return this._renderPolygon(feature, index, path)

      default:
        return null
    }
  }
}

export function getFeatureCoordinates(feature) {
  const coordinates =
    feature && feature.geometry && feature.geometry.coordinates
  if (!coordinates) {
    return null
  }

  if (feature.properties.drawType === GEOJSON_TYPE.LINE_STRING) {
    return coordinates[0]
  }

  const isPolygonal = feature.geometry.type === GEOJSON_TYPE.POLYGON
  const isSinglePoint = feature.geometry.type === GEOJSON_TYPE.POINT
  return isPolygonal
    ? coordinates[0]
    : isSinglePoint
    ? [coordinates]
    : coordinates
}

import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import StruxhubInputHelperText from './components/StruxhubInputHelperText'
import StruxhubInputLabel from './components/StruxhubInputLabel'

// localization: no text to translate

export interface ISharedProps {
  label?: string
  value?: string
  isRequired?: boolean
  isDisabled?: boolean
  isReadOnly?: boolean
  isChanged?: boolean
  isValid?: boolean
  validationMessage?: string
  additionalHelperText?: string
  className?: string

  isMinimalisticMode?: boolean
  hasShortWidth?: boolean
  isRequiredTextHidden?: boolean
  isLabelHidden?: boolean
  isHelperTextCondensed?: boolean
  isHelperTextHidden?: boolean

  onValueReset?: () => void
  customRightIcon?: React.ReactNode
}

interface IProps extends ISharedProps {
  children: (
    isValueInvalid: boolean,
    isInFocus: boolean,
    onFocus: () => void,
    onBlur: () => void,
  ) => React.ReactNode
}

const RIGHT_PADDING = 8

@observer
export default class BaseStruxhubInput extends React.Component<IProps> {
  public static defaultProps = {
    isValid: true,
  }

  @observable private isInFocus: boolean = false

  public render() {
    const {
      label,
      isRequired,
      isChanged,
      additionalHelperText,
      validationMessage,
      hasShortWidth,
      value,
      isDisabled,
      isReadOnly,
      isMinimalisticMode,
      isRequiredTextHidden,
      isLabelHidden = false,
      isHelperTextCondensed = false,
      isHelperTextHidden = false,
      children,
      className,
    } = this.props

    return (
      <div
        className={classList({
          col: true,
          mt8: !isHelperTextCondensed,
          'inactive-element': isDisabled,
          'unclickable-element': isReadOnly,
          [className]: !!className,
        })}
      >
        {!isMinimalisticMode && !isLabelHidden && (
          <StruxhubInputLabel
            label={label}
            value={value}
            isRequired={isRequired}
            isValid={!this.isValueInvalid}
            hasShortWidth={hasShortWidth}
            isRequiredTextHidden={isRequiredTextHidden}
          />
        )}
        <div className="row mb4 relative">
          {children(
            this.isValueInvalid,
            this.isInFocus,
            this.onFocus,
            this.onBlur,
          )}
          {this.actionIcons}
        </div>
        {!isMinimalisticMode && !isHelperTextHidden && (
          <StruxhubInputHelperText
            isChanged={isChanged}
            isValid={!this.isValueInvalid}
            additionalHelperText={additionalHelperText}
            validationMessage={validationMessage}
            isCondensed={isHelperTextCondensed}
          />
        )}
      </div>
    )
  }

  private get actionIcons(): JSX.Element {
    const { onValueReset, customRightIcon } = this.props

    if (customRightIcon) {
      return (
        <div
          className="absolute text large light no-grow opacity5 row x-center y-center"
          style={{ right: RIGHT_PADDING }}
        >
          {customRightIcon}
        </div>
      )
    }

    if (!onValueReset) {
      return null
    }

    return (
      <>
        {this.shouldShowClearIcon && (
          <Icons.CrossGrey
            className="absolute pointer text light no-grow opacity5 row x-center y-center"
            onMouseDown={onValueReset}
            onClick={onValueReset}
            style={{ right: RIGHT_PADDING }}
          />
        )}
        {this.shouldShowAlertIcon && (
          <Icons.Alert
            className="absolute text red no-grow row x-center y-center"
            style={{ right: RIGHT_PADDING }}
          />
        )}
      </>
    )
  }

  private get shouldShowClearIcon(): boolean {
    const { onValueReset, value } = this.props
    return !!onValueReset && this.isInFocus && !!value
  }

  private get shouldShowAlertIcon(): boolean {
    return !!this.props.onValueReset && !this.isInFocus && this.isValueInvalid
  }

  private get isValueInvalid(): boolean {
    const { value, isValid } = this.props
    return !!value && !isValid
  }

  @action.bound
  private onFocus() {
    this.isInFocus = true
  }

  @action.bound
  private onBlur() {
    this.isInFocus = false
  }
}

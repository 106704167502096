import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IActivitiesConfigurationsFieldsFragment = Pick<
  Types.IActivitiesConfigurations,
  'id' | 'projectId'
> & {
  savedActivityGroups?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IFilterGroup, 'filterNames'>>>
  >
  sitemaps?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IOrderedSitemap, 'sitemapId' | 'order'>>>
  >
}

export type IGetActivitiesConfigurationsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetActivitiesConfigurationsQuery = {
  activitiesConfigurations?: Types.Maybe<{
    data: Array<IActivitiesConfigurationsFieldsFragment>
  }>
}

export type ISaveActivitiesConfigurationsMutationVariables = Types.Exact<{
  activitiesConfigurations: Types.IActivitiesConfigurationsInput
}>

export type ISaveActivitiesConfigurationsMutation = {
  saveActivitiesConfigurations?: Types.Maybe<IActivitiesConfigurationsFieldsFragment>
}

export type IListenToActivitiesConfigurationsSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenToActivitiesConfigurationsSubscription = {
  activitiesConfigurations?: Types.Maybe<
    Pick<Types.IActivitiesConfigurationsChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivitiesConfigurationsFieldsFragment>
    }
  >
}

export const ActivitiesConfigurationsFieldsFragmentDoc = gql`
  fragment ActivitiesConfigurationsFields on ActivitiesConfigurations {
    id
    savedActivityGroups {
      filterNames
    }
    sitemaps {
      sitemapId
      order
    }
    projectId
  }
`
export const GetActivitiesConfigurationsDocument = gql`
  query GetActivitiesConfigurations($projectId: ObjectId!) {
    activitiesConfigurations(projectId: $projectId, limit: 1) {
      data {
        ...ActivitiesConfigurationsFields
      }
    }
  }
  ${ActivitiesConfigurationsFieldsFragmentDoc}
`

/**
 * __useGetActivitiesConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetActivitiesConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesConfigurationsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetActivitiesConfigurationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivitiesConfigurationsQuery,
    IGetActivitiesConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivitiesConfigurationsQuery,
    IGetActivitiesConfigurationsQueryVariables
  >(GetActivitiesConfigurationsDocument, options)
}
export function useGetActivitiesConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivitiesConfigurationsQuery,
    IGetActivitiesConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivitiesConfigurationsQuery,
    IGetActivitiesConfigurationsQueryVariables
  >(GetActivitiesConfigurationsDocument, options)
}
export type GetActivitiesConfigurationsQueryHookResult = ReturnType<
  typeof useGetActivitiesConfigurationsQuery
>
export type GetActivitiesConfigurationsLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesConfigurationsLazyQuery
>
export type GetActivitiesConfigurationsQueryResult = Apollo.QueryResult<
  IGetActivitiesConfigurationsQuery,
  IGetActivitiesConfigurationsQueryVariables
>
export const SaveActivitiesConfigurationsDocument = gql`
  mutation SaveActivitiesConfigurations(
    $activitiesConfigurations: ActivitiesConfigurationsInput!
  ) {
    saveActivitiesConfigurations(
      activitiesConfigurations: $activitiesConfigurations
    ) {
      ...ActivitiesConfigurationsFields
    }
  }
  ${ActivitiesConfigurationsFieldsFragmentDoc}
`
export type ISaveActivitiesConfigurationsMutationFn = Apollo.MutationFunction<
  ISaveActivitiesConfigurationsMutation,
  ISaveActivitiesConfigurationsMutationVariables
>

/**
 * __useSaveActivitiesConfigurationsMutation__
 *
 * To run a mutation, you first call `useSaveActivitiesConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActivitiesConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActivitiesConfigurationsMutation, { data, loading, error }] = useSaveActivitiesConfigurationsMutation({
 *   variables: {
 *      activitiesConfigurations: // value for 'activitiesConfigurations'
 *   },
 * });
 */
export function useSaveActivitiesConfigurationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveActivitiesConfigurationsMutation,
    ISaveActivitiesConfigurationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveActivitiesConfigurationsMutation,
    ISaveActivitiesConfigurationsMutationVariables
  >(SaveActivitiesConfigurationsDocument, options)
}
export type SaveActivitiesConfigurationsMutationHookResult = ReturnType<
  typeof useSaveActivitiesConfigurationsMutation
>
export type SaveActivitiesConfigurationsMutationResult =
  Apollo.MutationResult<ISaveActivitiesConfigurationsMutation>
export type SaveActivitiesConfigurationsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveActivitiesConfigurationsMutation,
    ISaveActivitiesConfigurationsMutationVariables
  >
export const ListenToActivitiesConfigurationsDocument = gql`
  subscription ListenToActivitiesConfigurations($projectId: ObjectId!) {
    activitiesConfigurations(projectId: $projectId) {
      id
      item {
        ...ActivitiesConfigurationsFields
      }
    }
  }
  ${ActivitiesConfigurationsFieldsFragmentDoc}
`

/**
 * __useListenToActivitiesConfigurationsSubscription__
 *
 * To run a query within a React component, call `useListenToActivitiesConfigurationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivitiesConfigurationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivitiesConfigurationsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToActivitiesConfigurationsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivitiesConfigurationsSubscription,
    IListenToActivitiesConfigurationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivitiesConfigurationsSubscription,
    IListenToActivitiesConfigurationsSubscriptionVariables
  >(ListenToActivitiesConfigurationsDocument, options)
}
export type ListenToActivitiesConfigurationsSubscriptionHookResult = ReturnType<
  typeof useListenToActivitiesConfigurationsSubscription
>
export type ListenToActivitiesConfigurationsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivitiesConfigurationsSubscription>

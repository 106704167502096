import * as React from 'react'

import { Radio, RadioGroup } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import SavedFilterLabel from '~/client/src/desktop/components/Filters/SavedFilters/SavedFiltersSelector/components/SavedFilterLabel'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CustomActivityListFilter from '~/client/src/shared/types/CustomActivityListFilter'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'

// localization: translated

export interface IProps {
  activeFilterId: string
  getEntitiesCountByCustomFilter: (
    filter: CustomActivityListFilter | CustomDeliveryListFilter,
  ) => number
  selectCustomFilterById: (id: string) => void
  editCustomFilter: () => void
  selectCustomFilter: (event) => void
  customFilters: CustomActivityListFilter[] | CustomDeliveryListFilter[]
}

@observer
export default class SavedFiltersSelector extends React.Component<IProps> {
  public render() {
    const {
      activeFilterId,
      selectCustomFilter,
      customFilters,
      selectCustomFilterById,
      editCustomFilter,
      getEntitiesCountByCustomFilter,
    } = this.props

    if (!customFilters.length) {
      return Localization.translator.thereAreNoSavedFilters
    }

    return (
      <RadioGroup
        className="theme-radio-group"
        onChange={selectCustomFilter}
        selectedValue={activeFilterId}
      >
        {(
          customFilters as Array<
            CustomActivityListFilter | CustomDeliveryListFilter
          >
        ).map(filter => (
          <Radio key={filter.id} value={filter.id}>
            <SavedFilterLabel
              customFilter={filter}
              selectCustomFilterById={selectCustomFilterById}
              editCustomFilter={editCustomFilter}
              getEntitiesCountByCustomFilter={getEntitiesCountByCustomFilter}
            />
          </Radio>
        ))}
      </RadioGroup>
    )
  }
}

import { IDeliveryAttribute } from '~/client/graph'
import { SaveEquipmentsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import * as e from '../EventStore/eventConstants'
import LocationAttributesStoreBase from './LocationAttributesBase.store'

export default class OffloadingEquipmentsStore extends LocationAttributesStoreBase<OffloadingEquipment> {
  protected saveMutation = SaveEquipmentsDocument
  protected graphName = 'equipments'
  protected defaultIconName = SitemapAttributeIcon.Equipment

  public get byId() {
    const { offloadingEquipments } = this.eventsStore.appState
    return offloadingEquipments
  }

  public getByIds(offloadingEquipmentIds: string[] = []) {
    return offloadingEquipmentIds
      .map(eqId => this.byId.get(eqId))
      .filter(eq => !!eq)
  }

  public getString = (
    offloadingEquipments: IDeliveryAttribute[],
    shouldShowDeletedEquipment: boolean,
  ) => {
    let equipmentString = '—'
    if (offloadingEquipments.length) {
      equipmentString = offloadingEquipments
        .map(({ id }) => this.byId.get(id))
        .filter(
          equipment =>
            equipment && (!equipment.isDeleted || shouldShowDeletedEquipment),
        )
        .map(equipment => equipment.name)
        .join(', ')
    }

    return equipmentString
  }

  public saveItem(item: OffloadingEquipment): Promise<string> {
    this.changeAccessibleLevelsIfNeed(item)
    return super.saveItem(item)
  }

  public saveItemsWhenNestedNumberOfLevelsChanges = async (
    items: OffloadingEquipment[],
  ): Promise<string[]> => {
    if (!items?.length) {
      return []
    }

    this.loadingMap.set(e.SAVE_LOCATION, true)

    const dtos = items.map(vo => {
      this.changeAccessibleLevelsIfNeed(vo)
      return vo.getDto()
    })

    const {
      data: { saveManyOffloadingEquipments: savedEquipments },
    } = await this.graphExecutorStore.mutate(this.saveMutation, {
      [this.graphName]: dtos,
    })

    this.loadingMap.set(e.SAVE_LOCATION, false)

    return savedEquipments?.map(dto => dto.id)
  }

  protected fromDto(dto: ILocationAttributeDto): OffloadingEquipment {
    return OffloadingEquipment.fromDto(dto)
  }

  private changeAccessibleLevelsIfNeed = (item: OffloadingEquipment) => {
    const oldItem = this.byId.get(item.id)

    const isParentAdded = item.hasParent && !oldItem?.hasParent
    const isParentChanged = item.parent?.parentId !== oldItem?.parent?.parentId
    if (isParentChanged || isParentAdded || !item.accessibleLevels?.length) {
      item.accessibleLevels = this.getAllowedAccessibleLevels(item)
    }
  }
}

import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import SitemapElementsWrapper from '~/client/src/shared/components/SitemapElementsWrapper'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Awaiter from '~/client/src/shared/utils/Awaiter'

import MapBoxEditorStore from '../../MapBoxEditor/MapBoxEditor.store'
import BoundingBox from './drawnParts/BoundingBox'
import SitemapIcon from './drawnParts/SitemapIcon'
import SitemapLabel from './drawnParts/SitemapLabel'
import SitemapShape from './drawnParts/SitemapShape'

export const MAX_PERCENT = 100

interface IProps {
  items: SitemapItemBase[]
  containerWidth: number
  containerHeight: number
  mapBoxEditorStore: MapBoxEditorStore
  onSelectItem?: (item: SitemapItemBase, top?: number, left?: number) => void
  isEditMode?: boolean
  textboxesAwaiter?: Awaiter
  textboxesCache?: ICanvasImageCache

  bearing?: number
  className?: string
  isReferenced?: boolean
}

@observer
export default class SitemapItems extends React.Component<IProps> {
  @observable private boundingBoxItem: SitemapItemBase = null

  public render() {
    const { items, className } = this.props
    return (
      <SitemapElementsWrapper className={className}>
        {this.renderBoundingBox()}
        {items.map(this.renderShape)}
        {items.map(this.renderLabel)}
        {items.map(this.renderIcon)}
      </SitemapElementsWrapper>
    )
  }

  private renderIcon = (item: SitemapItemBase, idx: number) => {
    const { containerHeight, containerWidth, bearing } = this.props

    return (
      <SitemapIcon
        key={`${idx}-${item.id}`}
        item={item}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onClick={this.selectItem.bind(this, item)}
        onMouseEnter={this.showBoundingBox.bind(this, item)}
        onMouseLeave={this.hideBoundingBox}
        bearing={bearing}
      />
    )
  }

  private renderLabel = (item: SitemapItemBase, idx: number) => {
    const {
      containerHeight,
      containerWidth,
      textboxesAwaiter,
      textboxesCache,
      bearing,
      isReferenced,
    } = this.props

    return (
      <SitemapLabel
        key={`${idx}-${item.id}`}
        item={item}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onClick={this.selectItem.bind(this, item)}
        onMouseEnter={this.showBoundingBox.bind(this, item)}
        onMouseLeave={this.hideBoundingBox}
        textboxesAwaiter={textboxesAwaiter}
        textboxesCache={textboxesCache}
        bearing={bearing}
        isReferenced={isReferenced}
      />
    )
  }

  private renderShape = (item: SitemapItemBase, idx: number) => {
    const { containerHeight, containerWidth } = this.props

    return (
      <SitemapShape
        key={`${idx}-${item.id}`}
        item={item}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onClick={this.selectItem.bind(this, item)}
        onMouseEnter={this.showBoundingBox.bind(this, item)}
        onMouseLeave={this.hideBoundingBox}
        isStaticSize={true}
      />
    )
  }

  private selectItem(
    item: SitemapItemBase,
    event: KonvaEventObject<MouseEvent>,
  ) {
    const {
      onSelectItem,
      containerWidth,
      containerHeight,
      mapBoxEditorStore: { clickCoords, canvasMap },
    } = this.props

    if (!onSelectItem) {
      return
    }

    // for Line type (shape properties only)
    if (!item.iconProperties && !item.labelProperties) {
      event.cancelBubble = true
      onSelectItem(item)
      return
    }

    const { position } = item.iconProperties || item.labelProperties

    const clickPosition = clickCoords && {
      x: canvasMap?.getMap?.()?.project(clickCoords).x,
      y: canvasMap?.getMap?.()?.project(clickCoords).y,
    }
    const x = clickPosition?.x || (containerWidth * position.x) / MAX_PERCENT
    const y = clickPosition?.y || (containerHeight * position.y) / MAX_PERCENT

    event.cancelBubble = true
    onSelectItem(item, y, x)
  }

  private renderBoundingBox() {
    const { containerHeight, containerWidth, isEditMode, items } = this.props

    if (
      !isEditMode ||
      !this.boundingBoxItem ||
      !items.includes(this.boundingBoxItem)
    ) {
      return null
    }

    const boundingBox = this.boundingBoxItem.getBoundingBox(
      containerWidth,
      containerHeight,
    )

    return <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
  }

  private showBoundingBox(item: SitemapItemBase) {
    this.boundingBoxItem = item
  }

  private hideBoundingBox = () => {
    this.boundingBoxItem = null
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'

import SitePermit from '../../../models/Permit'
import LocationCard from '../../SitemapCards/LocationCard'
import SitemapItemBase from '../../SitemapHelpers/models/SitemapItemBase'
import SitemapDraggableModalWrapper from '../SitemapDraggableModalWrapper'

interface IProps {
  item: SitemapItemBase
  containerRef: HTMLElement
  openPermit: (permit: SitePermit) => void

  onClose(): void

  topOffset: number
  leftOffset: number

  currentDate: Date
}

// localization: no text to translate

@observer
export default class SitemapLocationModal extends React.Component<IProps> {
  public render() {
    const {
      containerRef,
      onClose,
      topOffset,
      leftOffset,
      item,
      openPermit,
      currentDate,
    } = this.props

    return (
      <SitemapDraggableModalWrapper
        className="overflow-auto brada10 short-modal"
        item={item}
        containerRef={containerRef}
        onClose={onClose}
        topOffset={topOffset}
        leftOffset={leftOffset}
        isStatic={true}
      >
        <LocationCard
          item={item}
          openPermit={openPermit}
          currentDate={currentDate}
        />
      </SitemapDraggableModalWrapper>
    )
  }
}

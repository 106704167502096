import { action, computed } from 'mobx'

import BaseGroupingOptionsStore, {
  EMPTY_OPTION,
  ICustomBandOption,
  INDENT_OPTION_MAIN_ID,
} from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions.store'
import { IBandOption } from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

// localization: translated

const DEFAULT_OPTION = DeliveryGroupingOption[FieldIds.DATE]
const INDENT_OPTION_IDS = [
  DeliveryGroupingOption[FieldIds.LOCATION],
  DeliveryGroupingOption[FieldIds.GATE],
  DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS],
  DeliveryGroupingOption[FieldIds.DURATION],
]
const SAVED_ENTITIES_NAME = 'savedDeliveryGroups'
const DEFAULT_BANDS_COUNT = 13

export default class DeliveryReportGroupingOptionsStore extends BaseGroupingOptionsStore {
  public leftCaptionKey = KnownTranslatorKeys.options

  public constructor(
    protected eventsStore: DesktopEventStore,
    protected readonly onShowChanged: (isShown: boolean) => void,
    protected readonly onChanged: () => void,
  ) {
    super(eventsStore, onShowChanged)
  }

  public get selectedBandsOptionFromState() {
    return this.state.filters.selectedDeliveryReportBandsOption
  }

  @computed
  public get bandsOptions(): IBandOption[] {
    const options: IBandOption[] = Object.keys(DeliveryGroupingOption).map(
      option => ({
        id: DeliveryGroupingOption[option],
        name: this.getOptionNameById(option),
        shortName: this.getShortOptionNameById(option),
        bands: [DeliveryGroupingOption[option]],
      }),
    )

    const {
      delivery,
      deliveryReportFiltersSettings: { desktopFilterMap },
    } = this.state
    const { savedDeliveryGroups } = delivery.configurations

    const bands: IBandOption[] = savedDeliveryGroups?.length
      ? savedDeliveryGroups.map(({ filterNames }, index) => {
          const filters = Object.values(filterNames)
          return {
            name: filters
              .map(value => desktopFilterMap[value].getCaption())
              .join(', '),
            id: filters.join(', '),
            num: DEFAULT_BANDS_COUNT + index,
            shortName: Localization.translator.custom,
            disabled: false,
            bands: filters,
            canDelete: true,
            deletionIndex: index,
          }
        })
      : []

    return [...options, ...bands]
  }

  @computed
  public get options(): ICustomBandOption[] {
    const { desktopFilterMap } = this.state.deliveryReportFiltersSettings

    const options: ICustomBandOption[] = [
      {
        name: EMPTY_OPTION,
        caption: Localization.translator.none,
      },
      ...Object.values(desktopFilterMap).reduce(
        (list, { name, getCaption }) => {
          if (
            this.shouldIndent(name) ||
            name === DeliveryGroupingOption[FieldIds.COMPANY] ||
            name === DeliveryGroupingOption[FieldIds.BUILDING]
          ) {
            list.push({
              name: INDENT_OPTION_MAIN_ID,
              caption: EMPTY_OPTION,
            })
          }

          list.push({ name, caption: getCaption() })

          return list
        },
        [] as ICustomBandOption[],
      ),
    ]

    return options
  }

  @action.bound
  public onActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event

    if (e.DELIVERY_CONFIGURATIONS_UPDATED === eventType) {
      this.resetGroupingIfNeed()
    }
  }

  public setSelectedBand() {
    if (
      !this.selectedBandsOptionFromState ||
      !this.bandsOptions.some(
        ({ id }) => id === this.selectedBandsOptionFromState.id,
      )
    ) {
      this.resetGroupingIfNeed()
    }
  }

  @computed
  public get groupByCaption(): string {
    return this.appliedOption?.shortName || ''
  }

  public get isLoading(): boolean {
    return this.state.loading.get(e.SAVE_DELIVERY_CONFIGURATIONS)
  }

  @action.bound
  public initPopup() {
    this.selectedBandsOptionIdDraft = this.appliedOption?.id || DEFAULT_OPTION
  }

  @action.bound
  public applyGroupingOptions() {
    this.hideGroupingOptions()

    this.state.filters.showEmptyBands = this.showEmptyBandsDraft
    this.setSelectedBandsOption(this.selectedBandsOptionIdDraft)
    this.onChanged()
  }

  @action.bound
  public resetGroupingOptions() {
    this.selectedBandsOptionIdDraft = DEFAULT_OPTION
  }

  public shouldIndent = (id: string): boolean => {
    return INDENT_OPTION_IDS.includes(id)
  }

  public deleteCustomGrouping = (index: number) => {
    if (index < 0) {
      return
    }

    const { configurations } = this.state.delivery
    const { sitemaps, savedDeliveryGroups } = configurations

    configurations.savedDeliveryGroups = savedDeliveryGroups.splice(index, 1)

    this.saveGroupingConfig(
      e.SAVE_DELIVERY_CONFIGURATIONS,
      SAVED_ENTITIES_NAME,
      sitemaps,
      savedDeliveryGroups || [],
    )
  }

  @action.bound
  private resetGroupingIfNeed() {
    const isSelectedDraftOptionExists = this.bandsOptions.some(
      opt => opt.id === this.selectedBandsOptionIdDraft,
    )

    if (this.appliedOption && isSelectedDraftOptionExists) {
      return
    }

    this.resetGroupingOptions()
    this.setSelectedBandsOption(this.selectedBandsOptionIdDraft)
    this.onChanged()
  }

  private get filter() {
    return this.state.deliveryReportFilters
  }

  private get appliedOption() {
    return this.bandsOptions.find(o => o.id === this.filter.groupingKey)
  }

  public get areDefaultOptionsSelected(): boolean {
    return this.appliedOption?.id === DEFAULT_OPTION
  }

  private getShortOptionNameById(option: any) {
    if (option === DeliveryGroupingOption[FieldIds.LOCATION]) {
      return Localization.translator.location
    }
    return this.getOptionNameById(option)
  }

  private getOptionNameById(option: any) {
    if (option === DeliveryGroupingOption[FieldIds.LOCATION]) {
      return Localization.translator.location
    }
    return this.eventsStore.appState.getDeliveryFieldName(option)
  }

  private setSelectedBandsOption(selectedId: string) {
    const { sitemaps, savedDeliveryGroups } = this.state.delivery.configurations

    if (this.groupLevels.length > 1) {
      this.saveSelectedLevels(
        e.SAVE_DELIVERY_CONFIGURATIONS,
        SAVED_ENTITIES_NAME,
        sitemaps,
        savedDeliveryGroups || [],
      )
      return
    }

    this.groupLevels = [EMPTY_OPTION]

    this.state.filters.selectedDeliveryReportBandsOption =
      this.bandsOptions.find(({ id }) => id === selectedId)
    this.filter.groupingKey = this.selectedBandsOptionIdDraft
  }
}

import React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'
import EDITABLE_LABEL_ID from '~/client/src/shared/constants/editableLabel'
import Sitemap from '~/client/src/shared/models/Sitemap'

import EditableLabel, {
  IEditableLabelProps,
  defaultClassName,
} from './dialogs/ViewSelectDialog/components/EditableLabel'

const currentMap = 'Current Map'

export interface ISitemapSetupEditableLabelProps extends IEditableLabelProps {
  selectedSitemap: Sitemap
}

export default class SitemapSetupEditableLabel extends EditableLabel<ISitemapSetupEditableLabelProps> {
  protected renderActiveMode() {
    const { className, type = 'text' } = this.props

    return (
      <div className="col current-map">
        <div className="row text uppercase small light lp15 bold">
          {currentMap}
        </div>
        <div className="row">
          <Icons.Sitemap className="no-grow" />
          <input
            id={EDITABLE_LABEL_ID}
            type={type}
            onBlur={this.exitEditMode}
            value={this.draftText}
            autoFocus={true}
            onChange={this.updateDraftText}
            className={className || defaultClassName}
          />
          <Icons.EditInactive className="pl10" />
        </div>
      </div>
    )
  }

  protected renderInactiveMode() {
    const { text, className, selectedSitemap } = this.props

    return (
      <div className="col current-map">
        <div className="row text uppercase small light lp15 bold">
          {currentMap}
        </div>
        {selectedSitemap && (
          <div className="row full-width relative">
            <Icons.Sitemap className="no-grow" />
            <div className="editable-label-holder no-grow row">
              <span className={className || defaultClassName}>
                {this.draftText || text}
              </span>
            </div>
            <Icons.EditInactive
              className="pl10 no-grow pointer"
              onClick={this.enterEditMode}
            />
          </div>
        )}
      </div>
    )
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'

import DetailListItem from '~/client/src/shared/components/DetailListItem'
import ActivityInfoStore from '~/client/src/shared/components/ActivityDetails/components/ActivityInfoSection/ActivityInfo.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import WorkflowCardLocationLabel from '../../../WorkflowCard/LocationLabel'

// translated

@observer
export default class LocationDetails extends React.Component<{
  store: ActivityInfoStore
}> {
  public render() {
    const { locations } = this.props.store

    return (
      <DetailListItem category={Localization.translator.location}>
        <div>
          <WorkflowCardLocationLabel
            locations={locations}
            isOneColor={false}
            shouldShowAsTag={true}
          />
        </div>
      </DetailListItem>
    )
  }
}

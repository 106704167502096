import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { Observer, observer } from 'mobx-react'
import { AutoSizer, List } from 'react-virtualized'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Checkbox from '~/client/src/shared/components/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import StruxhubBareEditableInput from '~/client/src/shared/components/StruxhubInputs/StruxhubBareEditableInput/StruxhubBareEditableInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import { enumToList } from '~/client/src/shared/utils/converters'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import './PermitTypesList.scss'

// localization: translated

interface IProps {
  permitTypes: PermitType[]

  isCustomType(type: string): boolean
  onToggle(permitTypeId: string): void
  onAddNewFormClick(): void
  onRemoveClick(id: string): void
  changeTypeName(pTypeId: string, newName: string): void
}

enum FormCategoryType {
  DEFAULT = 'Default',
  MATERIALS = 'Materials',
  CUSTOM = 'Custom',
}

const formCategoryTypesList = enumToList<FormCategoryType>(FormCategoryType)

interface IFormTypeCategoryRow extends PermitType {
  categoryType?: FormCategoryType
  categoryName?: string
}

const OVERSCAN_ROW_COUNT = 6
const TYPE_ROW_HEIGHT = 36
const COLLAPSE_ICON_SIZE = 16

@observer
export default class PermitTypesList extends React.Component<IProps> {
  private readonly hiddenFormTypes = observable(new Set<FormCategoryType>())

  public render() {
    const { permitTypes, onAddNewFormClick } = this.props

    return (
      <>
        <div className="row pb10 px5 bb-light-grey">
          <div className="row text large light">
            <div className="pr10 no-grow nowrap">
              {permitTypes.length} {Localization.translator.formTypes}
            </div>
            <div className="row px10 bl-light-cool-grey">
              {this.renderCollapseBtn()}
              {this.renderCollapseBtn(true)}
            </div>
            <div className="row x-end px10">
              <BaseActionButton
                className="mx4 primary-theme-inverted"
                isAnimated
                isEnabled
                title={Localization.translator.newFormType}
                icon={<Icons.PlusRounded className="row no-grow" />}
                onClick={onAddNewFormClick}
              />
            </div>
          </div>
        </div>
        <div className="col permit-types-list mb20">
          <div className="row text center bold uppercase bb-brand-dark py15 lp05">
            <span className="no-grow px8 cb-col">
              {Localization.translator.include}
            </span>
            <span className="px8 no-grow">{Localization.translator.name}</span>
          </div>
          <div className="virtualized-list-smart-wrapper types-container">
            <AutoSizer>
              {({ height, width }) => (
                <Observer>
                  {() => (
                    <List
                      data={this.typesWithCategories}
                      width={width}
                      height={height}
                      rowHeight={TYPE_ROW_HEIGHT}
                      rowCount={this.typesWithCategories.length}
                      rowRenderer={this.renderType}
                      scrollToAlignment="start"
                      overscanRowCount={OVERSCAN_ROW_COUNT}
                    />
                  )}
                </Observer>
              )}
            </AutoSizer>
          </div>
        </div>
      </>
    )
  }

  public renderType = ({ key, style, index }: any): JSX.Element => {
    const props = { key, style }
    const { isCustomType, onRemoveClick } = this.props
    const { categoryType, categoryName, isEnabled, type, id, name } =
      this.typesWithCategories[index] || {}

    return (
      <div {...props} className="relative row bb-palette-brand-lighter">
        {categoryName ? (
          <div
            className="row full-height text large bold bg-palette-brand-lightest pointer no-select"
            onClick={this.toggleFormCategory.bind(this, categoryType)}
          >
            <Icon
              icon={
                this.hiddenFormTypes.has(categoryType)
                  ? IconNames.CHEVRON_RIGHT
                  : IconNames.CHEVRON_DOWN
              }
              className="ml15 mr5"
            />
            {categoryName}
          </div>
        ) : (
          <>
            <span className="no-grow cb-col">
              <Checkbox
                isCentered={true}
                isChecked={isEnabled}
                onClick={this.handleToggle.bind(this, id)}
              />
            </span>

            <PermitTypeIcon permitType={type} className="row no-grow" />
            <StruxhubBareEditableInput
              className="row text large ellipsis pl8"
              inputClassName="text large ba2-group-highlight brada3"
              value={name}
              onChange={this.onTypeNameChange.bind(this, id)}
            />

            {isCustomType(type) && (
              <Icons.CrossGrey
                className="absolute remove-type-icon text light pointer ml4 no-grow opacity5 row x-center y-center"
                onMouseDown={onRemoveClick.bind(null, id)}
              />
            )}
          </>
        )}
      </div>
    )
  }

  private renderCollapseBtn = (isExpand?: boolean): JSX.Element => {
    const icon = isExpand ? IconNames.EXPAND_ALL : IconNames.COLLAPSE_ALL
    const onClickHandler = isExpand
      ? this.clearHiddenCategories
      : this.collapseAllCategories

    return (
      <BaseActionButton
        className="mx4 gray-theme"
        isEnabled
        shouldShowBorder={false}
        title={EMPTY_STRING}
        icon={<Icon icon={icon} size={COLLAPSE_ICON_SIZE} className="pl4" />}
        onClick={onClickHandler}
      />
    )
  }

  @computed
  private get typesWithCategories(): IFormTypeCategoryRow[] {
    const { permitTypes, isCustomType } = this.props
    const customTypes = permitTypes.filter(t => isCustomType(t.type))
    const defaultTypes = permitTypes.filter(
      t => !isCustomType(t.type) && !t.isMaterialTransfer,
    )
    const materialTypes = permitTypes.filter(t => t.isMaterialTransfer)

    return [
      {
        categoryType: FormCategoryType.DEFAULT,
        categoryName: Localization.translator.default,
      } as IFormTypeCategoryRow,
      ...(this.hiddenFormTypes.has(FormCategoryType.DEFAULT)
        ? []
        : defaultTypes),

      {
        categoryType: FormCategoryType.MATERIALS,
        categoryName: Localization.translator.materials,
      } as IFormTypeCategoryRow,
      ...(this.hiddenFormTypes.has(FormCategoryType.MATERIALS)
        ? []
        : materialTypes),

      {
        categoryType: FormCategoryType.CUSTOM,
        categoryName: Localization.translator.custom,
      } as IFormTypeCategoryRow,
      ...(this.hiddenFormTypes.has(FormCategoryType.CUSTOM) ? [] : customTypes),
    ]
  }

  private handleToggle = (permitTypeId: string) => {
    this.props.onToggle(permitTypeId)
  }

  private onTypeNameChange(pTypeId: string, newName: string) {
    this.props.changeTypeName(pTypeId, newName)
  }

  @action
  private toggleFormCategory(formCategoryType: FormCategoryType) {
    if (this.hiddenFormTypes.has(formCategoryType)) {
      this.hiddenFormTypes.delete(formCategoryType)
    } else {
      this.hiddenFormTypes.add(formCategoryType)
    }
  }

  @action.bound
  private collapseAllCategories() {
    if (this.hiddenFormTypes.size !== formCategoryTypesList.length) {
      formCategoryTypesList.forEach(c => this.hiddenFormTypes.add(c))
    }
  }

  @action.bound
  private clearHiddenCategories() {
    if (this.hiddenFormTypes.size) {
      this.hiddenFormTypes.clear()
    }
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'

import { SitemapItemShapeType } from '~/client/graph'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import SitemapItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import * as TagIcon from '~/client/src/shared/components/TagIcon'

import SitemapItemsSetupStore from '../../stores/SitemapItemsSetup.store'
import PropertySelect from './PropertySelect'

interface IProps {
  item: SitemapItemBase
  store: SitemapItemsSetupStore

  setInitValues?: () => void
}

const { Circle, Rectangle, Polyline } = SitemapItemShapeType
const OPTION_BY_SHAPE = {
  [Circle]: <TagIcon.Circle size={16} />,
  [Rectangle]: <TagIcon.Rectangle size={16} />,
  [Polyline]: <TagIcon.Polygon size={16} />,
}

const NONE_VALUE = null
const none = 'None'

@observer
export default class ShapeSelector extends React.Component<IProps> {
  public render() {
    const {
      store: { getAllowedShapesForType },
      item: { shapeProperties, dataObject },
    } = this.props

    const selectedValue = shapeProperties && shapeProperties.type
    const allowedShapes = getAllowedShapesForType(dataObject.type)

    const options = [
      { value: NONE_VALUE, label: none },
      ...allowedShapes.map(value => ({
        value,
        label: OPTION_BY_SHAPE[value],
      })),
    ]
    return (
      <PropertySelect
        options={options}
        value={selectedValue}
        className="inline-selector"
        onChange={this.onShapeChange}
      />
    )
  }

  private onShapeChange = (shapeType: SitemapItemShapeType) => {
    const { item, store, setInitValues } = this.props
    store.deselectSitemapItemDrawnPart()
    item.updateShape(shapeType)
    if (shapeType === SitemapItemShapeType.Circle) {
      setInitValues?.()
    }
    store.selectSitemapItemDrawnPart(SitemapItemDrawnPart.Shape)
    store.addCurrentStateToHistory()
  }
}

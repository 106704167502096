import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'

import { getCorrectModalOffsetPosition } from '../../utils/SitemapCalculationHelpers'

import './SitemapDraggableModalWrapper.scss'

// localization: no text to translates

interface IProps {
  item: SitemapItemBase
  containerRef: HTMLElement

  leftOffset: number
  topOffset: number
  onClose(): void
  isStatic?: boolean
  isItemLoading?: boolean
  className?: string
}

const minX = 0
const minY = 0

const marginTop = 40

@observer
export default class SitemapDraggableModalWrapper extends React.Component<IProps> {
  @observable private left: number = minX
  @observable private top: number = minY

  private relLeft: number = minX
  private relTop: number = minY
  private isDragging: boolean = false
  private modalRef: HTMLDivElement = null

  public componentDidMount() {
    this.setInitPosition()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      this.props.item !== prevProps.item ||
      this.props.isItemLoading !== prevProps.isItemLoading
    ) {
      this.setInitPosition()
    }
  }

  public componentWillUnmount() {
    this.removeServiceListeners()
  }

  public render() {
    const { item, onClose, className, children, isStatic } = this.props

    return (
      <div
        ref={this.setRef}
        style={{ left: this.left, top: this.top }}
        className={classList({
          'col sitemap-draggable-modal ba-palette-brand-light brada4 no-outline-container':
            true,
          [className]: !!className,
        })}
        onMouseDown={this.stopPropagation}
      >
        {!isStatic && (
          <header
            className="row text white large bold px12"
            onMouseDown={this.startDragging}
          >
            <SitemapAttributeTag
              shouldShowAsTag={false}
              dataObject={item.dataObject}
            >
              <span>{item.displayName}</span>
            </SitemapAttributeTag>
            <Icon
              icon={IconNames.CROSS}
              className="no-grow pointer"
              onClick={onClose}
            />
          </header>
        )}
        {children}
      </div>
    )
  }

  private startDragging = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (e.button !== 0) {
      return
    }

    this.isDragging = true

    this.relLeft = e.pageX - this.modalRef.offsetLeft
    this.relTop = e.pageY - this.modalRef.offsetTop

    this.addServiceListeners()
  }

  private onDragging = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    if (!this.isDragging) {
      return
    }

    const { offsetWidth: modalWidth, offsetHeight: modalHeight } = this.modalRef
    const {
      containerRef: { offsetWidth, offsetHeight },
    } = this.props

    const { x, y } = getCorrectModalOffsetPosition(
      e.pageX - this.relLeft,
      e.pageY - this.relTop,
      offsetWidth,
      offsetHeight,
      modalWidth,
      modalHeight,
    )
    this.left = x
    this.top = y
  }

  private stopDragging = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    this.isDragging = false

    this.removeServiceListeners()
  }

  private setInitPosition() {
    const {
      containerRef: { offsetWidth, offsetHeight },
      leftOffset,
      topOffset,
      item,
    } = this.props
    const { offsetWidth: modalWidth, offsetHeight: modalHeight } = this.modalRef

    const { position } = item.iconProperties || item.labelProperties
    const offsetX = modalWidth / 2
    const top = topOffset
      ? topOffset + marginTop / 2
      : (position.y * offsetHeight) / 100 + marginTop
    const left = leftOffset
      ? leftOffset - modalWidth / 2
      : (position.x * offsetWidth) / 100 - offsetX

    const { x, y } = getCorrectModalOffsetPosition(
      left,
      top,
      offsetWidth,
      offsetHeight,
      modalWidth,
      modalHeight,
    )
    this.left = x
    this.top = y
  }

  private addServiceListeners() {
    document.addEventListener('mousemove', this.onDragging)
    document.addEventListener('mouseup', this.stopDragging)
    document.addEventListener('mouseleave', this.stopDragging)
  }

  private removeServiceListeners() {
    document.removeEventListener('mousemove', this.onDragging)
    document.removeEventListener('mouseup', this.stopDragging)
    document.removeEventListener('mouseleave', this.stopDragging)
  }

  private setRef = (ref: HTMLDivElement) => {
    this.modalRef = ref
  }

  private stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
}

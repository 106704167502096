import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { action, computed, observable } from 'mobx'

import {
  ActivityStatus,
  DeliveryFilterType,
  FilterType,
  IActivitiesConfigurations,
  IDeliveryConfigurations,
  IFormsConfigurations,
  ILogisticsConfigurations,
  IProjectAddress,
  IProjectColoringOptions,
  IProjectHistory,
  IProjectMaterialOptions,
  IProjectStatusUpdateOptions,
  IProjectTypeOptions,
  IProjectVisitorOptions,
  ISchedule,
  IZoneMapThresholds,
  RecentlyUpdatedMode,
  ThresholdUnit,
} from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import FlaggedFeatures from '~/client/src/shared/enums/FlaggedFeatures'
import IDateTimeFilter from '~/client/src/shared/interfaces/IDateTimeFilter'
import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import AssociationItem from '~/client/src/shared/models/AssociationItem/AssociationItem'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import LogisticsObject from '~/client/src/shared/models/LocationObjects/LogisticsObject'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import OperationRule from '~/client/src/shared/models/OperationRule'
import Project from '~/client/src/shared/models/Project'
import Resource from '~/client/src/shared/models/Resource'
import Sitemap from '~/client/src/shared/models/Sitemap'
import User from '~/client/src/shared/models/User'
import CommonDeliveryAttributeDto from '~/client/src/shared/types/CommonDeliveryAttributeDto'
import CustomActivityListFilter, {
  ActivityFilterMap,
} from '~/client/src/shared/types/CustomActivityListFilter'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'
import MobXMap from '~/client/src/shared/types/MobXMap'
import SimpleCalendarDto from '~/client/src/shared/types/SimpleCalendarDto'

import Localization from '../../shared/localization/LocalizationManager'
import {
  DeliveryPageMode,
  DeliveryPageViewMode,
} from '../components/Deliveries/DeliveriesView.store'
import deliveryFieldIdToActivityFilterTypeMap from '../constants/deliveryFieldIdToActivityFilterTypeMap'
import DeliveryGroupingOption from '../enums/DeliveryGroupingOption'
import { IntegrationAppName } from '../enums/IntegrationAppName'
import Activity from '../models/Activity'
import ActivityCode from '../models/ActivityCode'
import ActivityCodeLocationRelationship from '../models/ActivityCodeLocationRelationship'
import ActivityCodeRelationship from '../models/ActivityCodeRelationship'
import ActivityCodeType from '../models/ActivityCodeType'
import ActivityCompanyRelationship from '../models/ActivityCompanyRelationship'
import Announcement from '../models/Announcement'
import Assignment from '../models/Assignment/Assignment'
import Basemap from '../models/Basemap'
import CategoryOfVariance from '../models/CategoryOfVariance'
import Closure from '../models/Closure'
import Delivery from '../models/Delivery'
import Flag from '../models/Flag'
import IIntegrationConfig from '../models/IIntegrationConfig'
import Area from '../models/LocationObjects/Area'
import InteriorDoor from '../models/LocationObjects/InteriorDoor'
import InteriorPath from '../models/LocationObjects/InteriorPath'
import Level from '../models/LocationObjects/Level'
import LocationIntegration from '../models/LocationObjects/LocationIntegration'
import Staging from '../models/LocationObjects/Staging'
import VerticalObject from '../models/LocationObjects/VerticalObject'
import Zone from '../models/LocationObjects/Zone'
import Message from '../models/Message'
import SitePermit from '../models/Permit'
import PermitBicInspection from '../models/PermitBicInspection'
import PermitInspection from '../models/PermitInspection'
import PermitInspectionChange from '../models/PermitInspectionChange'
import PermitStatusChange from '../models/PermitStatusChange'
import Photo from '../models/Photo'
import Rfi from '../models/Rfi'
import SafetyHazard from '../models/SafetyHazard'
import ScanHistory from '../models/ScanHistory'
import Scanner from '../models/Scanner'
import ScheduleComment from '../models/ScheduleComment'
import SitemapItem from '../models/SitemapItem'
import StatusUpdate from '../models/StatusUpdate'
import { ITag } from '../models/Tag'
import Thread from '../models/Thread'
import UserProject from '../models/UserProject'
import { createEmptyAddress } from '../utils/Address'
import DEFAULT_DELIVERY_CARD_CONFIG from '../utils/defaultDeliveryCardConfig'
import PRE_POPULATED_COLORS from '../utils/deliveryPrepopulatedColors'
import isTestEnvironment from '../utils/environment'
import isIntegrationConfigValid from '../utils/isIntegrationConfigValid'
import ActivityFilterInfo from './substates/ActivityFilterInfo'
import ActivityFiltersSettingState from './substates/ActivityFiltersSettingState'
import UIFilterInfo from './substates/UIFilterInfo'

export const defaultPresentationInactivityDuration = 3
export const fromPrefix = 'From'

export enum NotificationFilters {
  DELIVERIES = 'Deliveries',
  INBOX = 'Inbox',
  ARCHIVED = 'Archived',
  UNREAD = 'Unread',
  FLAGS = 'Flags',
  RFIS = `RFI's`,
  FORMS = 'Forms',
  READ = 'Read',
  SCHEDULE_COMMENTS = 'Schedule comment',
  ACTIVITY_CHANGED = 'Changed',
  DELIVERIES_DONE = 'Done',
  DELIVERIES_ON_SITE = 'On Site',
  DELIVERIES_LATE = 'Late',
  DELIVERIES_ON_TIME = 'On-Time',
  DELIVERIES_REQUESTED = 'Requested',
  DELIVERIES_CHANGED = 'Changed',
  DELIVERIES_SCHEDULED = 'Scheduled',
  DELIVERIES_PASSED_INSPECTION = 'Passed Inspection',
  DELIVERIES_FAILED_INSPECTION = 'Failed Inspection',
  DELIVERIES_DENIED = 'Denied',
  DELIVERIES_CANCELED = 'Canceled',
  DELIVERIES_ON_HOLD = 'On Hold',
  DELIVERIES_DELIVERING = 'Delivering',
  DELIVERIES_PAUSED = 'Paused',
  FOLLOWED = 'Followed',
  FORM_SUBMITTED = 'Form Submitted',
  FORM_CLOSED = 'Form Closed',
  FORM_ACCEPTED = 'Form Accepted',
  FORM_FINISHED = 'Form Finished',
  FORM_CHANGED = 'Form Changed',
  FORM_DENIED = 'Form Denied',
  FORM_TO_INSPECT = 'Form To Inspect',
  FORM_ACTIVATED = 'Form Activated',
  FORM_REVIEWED = 'Form Reviewed',
  FORM_FAILED = 'Form Failed',
  FORM_PASSED = 'Form Passed',
  FORM_BIC_INSPECTED = 'Form BIC Inspected',
  FORM_DELETED = 'Form Deleted',
  SCANNER_DEACTIVATED = 'Scanner Deactivated',
}

export enum ProjectSetUpSteps {
  ProjectDetails = 'Project details',
  UploadSitePlan = 'Upload site plan',
  GeoreferencePlan = 'Georeference plan',
  AddSiteLocations = 'Add site locations',
}

interface IDeliverySettings {
  deliveryFieldsConfigId: string
  mandatoryFields: { [fieldName: string]: boolean }
  defaultMandatoryFields: { [fieldName: string]: boolean }
  configurations: IDeliveryConfigurations
  compactView: CompactDeliveryView
  customFieldNames: { [fieldName: string]: string }
  hiddenFields: { [fieldName: string]: boolean }
  defaultHiddenFields: { [fieldName: string]: boolean }
  selectedSitemapId?: string
  isDefaultsReceived: boolean
  isDataReceived: boolean
}

interface IFormsSettings {
  configurations: IFormsConfigurations
  isDataReceived: boolean
}

interface ILogisticsSettings {
  configurations: ILogisticsConfigurations
  isDataReceived: boolean
}

interface IActivitiesSettings {
  configurations: IActivitiesConfigurations
}

const configErrorMessage = 'One of the integration configurations is incorrect'

export default class InitialState {
  public graphClient: ApolloClient<NormalizedCacheObject>

  @observable public projectCreationStep: ProjectSetUpSteps = null

  @observable public shouldOmitFormTypes: boolean = false
  @observable public countryCode: string = ''

  @observable public activeProject = Project.none
  @observable public activeSchedule: ISchedule = null
  @observable public shouldSendDesktopNotifications = false
  @observable public unreadNotificationsCount = 0
  @observable public user: User = null

  public delivery = observable<IDeliverySettings>({
    deliveryFieldsConfigId: null,
    configurations: null,
    compactView: new CompactDeliveryView(),
    customFieldNames: {},
    mandatoryFields: {},
    defaultMandatoryFields: null,
    hiddenFields: {},
    defaultHiddenFields: null,
    isDefaultsReceived: false,
    isDataReceived: false,
  })

  public forms = observable<IFormsSettings>({
    configurations: null,
    isDataReceived: false,
  })

  public logistics = observable<ILogisticsSettings>({
    configurations: null,
    isDataReceived: false,
  })

  public activitiesSettings = observable<IActivitiesSettings>({
    configurations: null,
  })

  public isUnauthorizedMode: boolean = false

  public loading = observable(new Map<any, boolean>()) // Loading flags for ui

  public onAuthStateChangedCallback: () => void

  private _initProjectCode: string = ''

  public get initProjectCode(): string {
    return this._initProjectCode
  }

  public set initProjectCode(code: string) {
    this._initProjectCode = code?.toUpperCase() || ''
  }

  public preSelectedPermitTypeId: string = null

  @observable public didInitialize = false
  @observable public initialRoute = ''
  @observable public activeConnections = new Map()

  @observable public activityFiltersSettings = new ActivityFiltersSettingState()

  public deliveryRequesterCompaniesFromLastSync: string[] = []

  public deliveryFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: DeliveryGroupingOption[FieldIds.DATE],
    fieldsMap: {
      [DeliveryFilterType.Status]: new UIFilterInfo(),
      [DeliveryFilterType.Company]: new UIFilterInfo(),
      [DeliveryFilterType.Gate]: new UIFilterInfo(),
      [DeliveryFilterType.Building]: new UIFilterInfo(),
      [DeliveryFilterType.Area]: new UIFilterInfo(),
      [DeliveryFilterType.Route]: new UIFilterInfo(),
      [DeliveryFilterType.Zone]: new UIFilterInfo(),
      [DeliveryFilterType.Level]: new UIFilterInfo(),
      [DeliveryFilterType.Followers]: new UIFilterInfo(),
      [DeliveryFilterType.Assigners]: new UIFilterInfo(),
      [DeliveryFilterType.Equipment]: new UIFilterInfo(),
    },
  })

  public closures = new MobXMap<Closure>()
  public deliveryReportFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: DeliveryGroupingOption[FieldIds.DATE],
    fieldsMap: {
      [DeliveryFilterType.Status]: new UIFilterInfo(),
      [DeliveryFilterType.Company]: new UIFilterInfo(),
      [DeliveryFilterType.Gate]: new UIFilterInfo(),
      [DeliveryFilterType.Building]: new UIFilterInfo(),
      [DeliveryFilterType.Area]: new UIFilterInfo(),
      [DeliveryFilterType.Route]: new UIFilterInfo(),
      [DeliveryFilterType.Zone]: new UIFilterInfo(),
      [DeliveryFilterType.Level]: new UIFilterInfo(),
      [DeliveryFilterType.Followers]: new UIFilterInfo(),
      [DeliveryFilterType.Assigners]: new UIFilterInfo(),
      [DeliveryFilterType.Equipment]: new UIFilterInfo(),
    },
  })

  public scanners = observable(new Map<string, Scanner>())
  public scanHistories = observable(new Map<string, ScanHistory>())
  public announcements = new MobXMap<Announcement>()
  public sitePermits = observable(new Map<string, SitePermit>())
  public permitStatusChanges = observable(new Map<string, PermitStatusChange>())
  public permitBicInspections = observable(
    new Map<string, PermitBicInspection>(),
  )
  public permitInspections = observable(new Map<string, PermitInspection>())
  public permitInspectionChanges = observable(
    new Map<string, PermitInspectionChange>(),
  )

  public basemaps = observable(new Map<string, Basemap>())
  public sitemaps = observable(new Map<string, Sitemap>())
  public sitemapItems = observable(new Map<string, SitemapItem>())
  public calendars = observable(new Map<string, SimpleCalendarDto>())

  public statusUpdates = new MobXMap<StatusUpdate>()
  public messages = new MobXMap<Message>()
  public photos = new MobXMap<Photo>()
  public activities = new MobXMap<Activity>()
  public activityCodes = new MobXMap<ActivityCode>()
  public activityCodeTypes = new MobXMap<ActivityCodeType>()
  public activityCodeRelationships = observable.array<ActivityCodeRelationship>(
    [],
  )
  public activityCodeLocationRelationships = observable(
    new Map<string, ActivityCodeLocationRelationship>(),
  )
  public activityCompanyRelationships = observable(
    new Map<string, ActivityCompanyRelationship>(),
  )
  public threads = observable(new Map<string, Thread>())
  public categoryOfVariances = observable(new Map<string, CategoryOfVariance>())
  public safetyHazards = observable(new Map<string, SafetyHazard>())
  public rfis = observable(new Map<string, Rfi>())
  public flags = observable(new Map<string, Flag>())
  public scheduleComments = observable(new Map<string, ScheduleComment>())

  public logisticsObjects = observable(new Map<string, LogisticsObject>())
  public stagings = observable(new Map<string, Staging>())
  public interiorPaths = observable(new Map<string, InteriorPath>())
  public interiorDoors = observable(new Map<string, InteriorDoor>())
  public locationIntegrations = observable(
    new Map<string, LocationIntegration>(),
  )
  public verticalObjects = observable(new Map<string, VerticalObject>())

  public levels = observable(new Map<string, Level>())
  public areas = observable(new Map<string, Area>())

  public deliveries = new MobXMap<Delivery>()
  public zones = observable(new Map<string, Zone>())
  public gates = observable(new Map<string, Gate>())
  public buildings = observable(new Map<string, Building>())
  public routes = observable(new Map<string, Route>())
  public offloadingEquipments = observable(
    new Map<string, OffloadingEquipment>(),
  )
  public deliveryVehicleTypes = observable(
    new Map<string, CommonDeliveryAttributeDto>(),
  )
  public deliveryUnits = observable(
    new Map<string, CommonDeliveryAttributeDto>(),
  )
  @observable public featureFlags: { [key in FlaggedFeatures]?: boolean } = {}
  public activityFollowings = observable(new Map<string, AssociationItem>())
  public deliveryFollowings = observable(new Map<string, AssociationItem>())
  public announcementFollowings = observable(new Map<string, AssociationItem>())
  public sitePermitFollowings = observable(new Map<string, AssociationItem>())
  public closureFollowings = observable(new Map<string, AssociationItem>())
  public castFollowings = observable(new Map<string, AssociationItem>())
  public activityAssignments = observable(new Map<string, Assignment>())
  public deliveryAssignments = observable(new Map<string, Assignment>())
  public announcementAssignments = observable(new Map<string, Assignment>())
  public sitePermitAssignments = observable(new Map<string, Assignment>())
  public closureAssignments = observable(new Map<string, Assignment>())
  public castAssignments = observable(new Map<string, Assignment>())

  public customActivityListFilters = observable(
    new Map<string, CustomActivityListFilter>(),
  )

  public customDeliveryListFilters = observable(
    new Map<string, CustomDeliveryListFilter>(),
  )

  public operationRules = observable(new Map<string, OperationRule>())

  public projectRoles = observable(new Map<string, ITag>())
  public projectTeams = observable(new Map<string, ITag>())
  public projectTrades = observable(new Map<string, ITag>())
  public defaultProjectTeams = observable(new Map<string, ITag>())

  @observable public projectMaterialOptions: IProjectMaterialOptions
  @observable public projectAddress: IProjectAddress = {
    projectId: null,
  }
  @observable public projectTypeOptions: IProjectTypeOptions = {
    isDeliveriesDisabled: false,
    isTrackerDisabled: false,
    isLogisticsDisabled: false,
    isMaterialsDisabled: false,
    isPhotosDisabled: false,
    isFormsDisabled: false,
    isAnalyticsDisabled: false,
    projectId: null,
  }
  @observable public projectStatusUpdateOptions: IProjectStatusUpdateOptions = {
    isAutoGenerated: true,
    isManpowerDisabled: false,
    isPercentCompleteDisabled: false,
    projectId: null,
  }
  @observable public projectColoringOptions: IProjectColoringOptions = {
    isWWPColoringDisabled: false,
    projectId: null,
  }

  @observable
  public zoneMapThresholds: IZoneMapThresholds = {
    deltaDaysValue: 0,
    deltaDaysBehindValue: 0,
    deltaDaysUnit: ThresholdUnit.Number,
    manpowerValue: 0,
    manpowerUnit: ThresholdUnit.Number,
    isManpowerDisabled: false,
    projectId: null,
  }

  @observable
  public projectHistory: IProjectHistory = {
    lastUpdated: [],
    projectId: null,
  }

  @observable
  public projectVisitorOptions: IProjectVisitorOptions = {
    limitUserDirectory: true,
    permitRequestEnabled: true,
    requireToCreateAccount: true,
    contactsVisibleToVisitors: {
      users: [],
      teams: [],
      defaultTeams: [],
    },
    projectId: null,
  }

  @observable
  public mobileActivityFiltersByFilterType: ActivityFilterMap = {
    [FilterType.Company]: [],
    [FilterType.Level]: [],
    [FilterType.Zone]: [],
  }

  @observable private _recipientIdForChatInit: string = ''
  @action.bound
  public setRecipientIdForChatInit(userId: string) {
    this._recipientIdForChatInit = userId
  }
  @action.bound
  public resetRecipientIdForChatInit() {
    this._recipientIdForChatInit = null
  }
  public get recipientIdForChatInit(): string {
    return this._recipientIdForChatInit
  }

  @observable private _idOfUserDisplayedOnGlobalCard: string = ''
  @action.bound
  public setIdOfUserDisplayedOnGlobalCard(userId: string) {
    this._idOfUserDisplayedOnGlobalCard = userId
  }
  @action.bound
  public resetIdOfUserDisplayedOnGlobalCard() {
    this._idOfUserDisplayedOnGlobalCard = null
  }
  public get idOfUserDisplayedOnGlobalCard(): string {
    return this._idOfUserDisplayedOnGlobalCard
  }

  public resources = observable(new Map<string, Resource>())

  @observable public activityPresets: ActivityPreset[] = []
  @observable public appliedActivityPresetId: string
  public compactActivityList = observable({
    shouldShowOnlyActiveTodaySections: true,
  })
  @observable private _userActiveProjectSettings: UserProject = null
  @observable private _latitude: number = 0
  @observable private _longitude: number = 0
  private readonly integrationsConfigMap = observable(
    new Map<IntegrationAppName, IIntegrationConfig>(),
  )

  public constructor() {
    this.resetToDefaultDeliveryConfigurations()
    this.resetToDefaultLogisticsConfigurations()
    this.resetToDefaultActivitiesConfigurations()
    this.resetToDefaultFormsConfigurations()
  }

  public set longitude(longitude: number) {
    this._longitude = longitude || 0
  }

  public get longitude(): number {
    return this._longitude
  }

  public set latitude(latitude: number) {
    this._latitude = latitude || 0
  }

  public get latitude(): number {
    return this._latitude
  }

  public set userActiveProjectSettings(up: UserProject) {
    this._userActiveProjectSettings = up
  }

  public get userActiveProjectSettings(): UserProject {
    return this._userActiveProjectSettings
  }

  public get isVisitorMode(): boolean {
    return this.userActiveProjectSettings?.isVisitor
  }

  public get isPresentationMode(): boolean {
    return this.userActiveProjectSettings?.isPresentationUser
  }

  public getDefaultProjectHistory(): IProjectHistory {
    return {
      lastUpdated: [],
      projectId: this.activeProject.id,
    }
  }

  public getDefaultProjectVisitorOptions(): IProjectVisitorOptions {
    return {
      limitUserDirectory: true,
      permitRequestEnabled: true,
      requireToCreateAccount: true,
      contactsVisibleToVisitors: {
        users: [],
        teams: [],
        defaultTeams: [],
      },
      projectId: this.activeProject.id,
    }
  }

  public getDefaultProjectMaterialOptions(): IProjectMaterialOptions {
    return {
      isCSIDisabled: false,
      isCSISubCategoriesDisabled: false,
      hiddenCategoryIds: [],
      hiddenSubCategoryIds: [],
      projectId: this.activeProject.id,
    }
  }

  public getDefaultProjectAddress(): IProjectAddress {
    return Object.assign(createEmptyAddress(), {
      projectId: this.activeProject.id,
    })
  }

  public getDefaultProjectTypeOptions(): IProjectTypeOptions {
    return {
      isDeliveriesDisabled: false,
      isTrackerDisabled: false,
      isLogisticsDisabled: false,
      isMaterialsDisabled: false,
      isPhotosDisabled: false,
      isFormsDisabled: false,
      isAnalyticsDisabled: false,
      projectId: this.activeProject.id,
    }
  }

  public getDefaultProjectStatusUpdateOptions(): IProjectStatusUpdateOptions {
    return {
      isAutoGenerated: true,
      isManpowerDisabled: false,
      isPercentCompleteDisabled: false,
      projectId: this.activeProject.id,
    }
  }

  public getDefaultProjectColoringOptions(): IProjectColoringOptions {
    return {
      isWWPColoringDisabled: false,
      projectId: this.activeProject.id,
    }
  }

  public getDefaultZoneMapThresholds(): IZoneMapThresholds {
    return {
      deltaDaysValue: 0,
      deltaDaysBehindValue: 0,
      deltaDaysUnit: ThresholdUnit.Number,
      manpowerValue: 0,
      manpowerUnit: ThresholdUnit.Number,
      isManpowerDisabled: false,
      projectId: this.activeProject.id,
    }
  }

  public getFormattedFieldName = (field: string) => {
    const fromFieldIdToRegularMap = {
      [FieldIds.FROM_BUILDING]: FieldIds.BUILDING,
      [FieldIds.FROM_LEVEL]: FieldIds.LEVEL,
      [FieldIds.FROM_AREA]: FieldIds.AREA,
      [FieldIds.FROM_ZONE]: FieldIds.ZONE,
      [FieldIds.FROM_INSTALLATION_ZONE]: FieldIds.INSTALLATION_ZONE,
      [FieldIds.FROM_STAGING]: FieldIds.STAGING,
    }

    return fromFieldIdToRegularMap[field] || field
  }

  public getDeliveryFieldName = (field: string) => {
    const customName = this.getDeliveryFieldCustomName(
      this.getFormattedFieldName(field),
    )

    if (customName) {
      return customName
    }

    switch (field) {
      case FieldIds.SITE_MAPS:
        return Localization.translator.deliverySitemap
      case FieldIds.GATE:
        return Localization.translator.gate
      case FieldIds.ZONE:
      case FieldIds.FROM_ZONE:
        return Localization.translator.zone
      case FieldIds.INSTALLATION_ZONE:
      case FieldIds.FROM_INSTALLATION_ZONE:
        return Localization.translator.installationZone
      case FieldIds.TRUCK_SIZE:
        return Localization.translator.deliveryVehicleType
      case FieldIds.TRUCK_NUMBER:
        return Localization.translator.numberOfVehicles
      case FieldIds.TRUCK_LICENSE_PLATE:
        return Localization.translator.numberOfVehicleLicensePlate
      case FieldIds.OFFLOADING_EQUIPMENT:
        return Localization.translator.equipment
      case FieldIds.MATERIAL:
        return Localization.translator.material
      case FieldIds.MATERIAL_UNITS:
        return Localization.translator.units
      case FieldIds.MATERIAL_LOCATION:
        return Localization.translator.materialDeliveryLocation
      case FieldIds.IS_INSPECTION_REQUIRED:
        return Localization.translator.inspectionRequired
      case FieldIds.INSPECTOR:
        return Localization.translator.inspector
      case FieldIds.VENDOR:
        return Localization.translator.vendor
      case FieldIds.VENDOR_EMAILS:
        return Localization.translator.vendorEmails
      case FieldIds.DRIVER_PHONE_NUMBERS:
        return Localization.translator.driverMobilePhoneNumbers
      case FieldIds.NOTE:
        return Localization.translator.specialInstructions
      case FieldIds.ACTIVITY_ID:
        return Localization.translator.linkedActivity
      case FieldIds.CUSTOM_ACTION_BUTTON:
        return Localization.translator.customActionButton
      case FieldIds.BUILDING:
      case FieldIds.FROM_BUILDING:
        return Localization.translator.building
      case FieldIds.ROUTE:
        return Localization.translator.route
      case FieldIds.SITE:
        return Localization.translator.site
      case FieldIds.LOGISTICS_OBJECT:
        return Localization.translator.siteLocation
      case FieldIds.VERTICAL_OBJECT:
        return Localization.translator.verticalObject
      case FieldIds.LEVEL:
      case FieldIds.FROM_LEVEL:
        return Localization.translator.level
      case FieldIds.AREA:
      case FieldIds.FROM_AREA:
        return Localization.translator.area
      case FieldIds.FROM_TO_SWITCH:
        return Localization.translator.fromToDelivery
      case FieldIds.MATERIAL_NOTE:
        return Localization.translator.materialNote
      case FieldIds.NUMBER_OF_EQUIPMENT_PICKS:
        return Localization.translator.numberOfPicks
      case FieldIds.STAGING:
      case FieldIds.FROM_STAGING:
        return Localization.translator.staging
      case FieldIds.INTERIOR_DOOR:
        return Localization.translator.interiorDoor
      case FieldIds.INTERIOR_PATH:
        return Localization.translator.interiorPath

      // cannot be renamed in settings
      case FieldIds.DATE:
        return Localization.translator.bookingDate
      case FieldIds.END_DATE:
        return Localization.translator.finishDate
      case FieldIds.START_TIME:
        return Localization.translator.startTime
      case FieldIds.END_TIME:
        return Localization.translator.endTime
      case FieldIds.COMPANY:
        return Localization.translator.requesterCompany
      case FieldIds.DURATION:
        return Localization.translator.duration
      case FieldIds.ON_SITE_CONTACTS:
        return Localization.translator.onsiteContacts
      case FieldIds.STATUS:
        return Localization.translator.status
      case FieldIds.MATERIAL_CATEGORY:
        return Localization.translator.materialsCategory
      case FieldIds.ID:
        return Localization.translator.deliveryId
      case FieldIds.BOOKING_TIME:
        return Localization.translator.bookingTime
      case FieldIds.MATERIAL_QUANTITY:
        return Localization.translator.quantity
      case FieldIds.CANCELATION_REASON:
        return Localization.translator.reason
      case FieldIds.INSPECTION_SECTION:
        return Localization.translator.inspection
      case FieldIds.LOCATION:
        return Localization.translator.location
      case FieldIds.MATERIAL_PROCUREMENT_ID:
        return Localization.translator.procurementID
      default:
        throw new Error(field + ' is not added to translator')
    }
  }

  @action
  public resetToDefaultCompactDeliveryView() {
    this.delivery.compactView.mode = DeliveryPageMode.VIEW
    this.delivery.compactView.highlightedDelivery = null
  }

  public resetToDefaultDeliveryConfigurations() {
    this.delivery.configurations = {
      deliveryDuration: 60,
      deadlineInterval: 1,
      sitemaps: [],
      shouldBlockLateRequesting: false,
      onTimeWindow: 0,
      allColors: PRE_POPULATED_COLORS,
      projectId: this.activeProject.id,
      cardFieldsConfig: DEFAULT_DELIVERY_CARD_CONFIG,
      hiddenCancelationReasons: [],
      unextendableFields: [],
      isMultipleEquipmentAllowed: false,
    }
    this.delivery.isDataReceived = false
  }

  public updateDeliveryConfigurations(
    deliveryConfigurations: IDeliveryConfigurations,
  ) {
    this.resetToDefaultDeliveryConfigurations()

    const { configurations } = this.delivery

    if (deliveryConfigurations) {
      Object.assign(configurations, deliveryConfigurations)

      // TODO: Check the response from back
      // It seems like there is no case when key is exist but value is null
      if (!configurations.hiddenCancelationReasons) {
        configurations.hiddenCancelationReasons = []
      }

      this.updateCardConfig()
    }

    this.delivery.isDataReceived = true
  }

  public resetToDefaultLogisticsConfigurations() {
    this.logistics.configurations = {
      sitemaps: [],
      projectId: this.activeProject.id,
    }
    this.logistics.isDataReceived = false
  }

  public resetToDefaultFormsConfigurations() {
    this.forms.configurations = {
      sitemaps: [],
      projectId: this.activeProject.id,
    }
    this.forms.isDataReceived = false
  }

  public updateFormsConfigurations(formsConfigurations: IFormsConfigurations) {
    this.resetToDefaultFormsConfigurations()

    const { configurations } = this.forms

    if (formsConfigurations) {
      Object.assign(configurations, formsConfigurations)
    }

    this.forms.isDataReceived = true
  }

  public updateLogisticsConfigurations(
    logisticsConfigurations: ILogisticsConfigurations,
  ) {
    this.resetToDefaultLogisticsConfigurations()

    const { configurations } = this.logistics

    if (logisticsConfigurations) {
      Object.assign(configurations, logisticsConfigurations)
    }

    this.logistics.isDataReceived = true
  }

  public resetToDefaultActivitiesConfigurations() {
    this.activitiesSettings.configurations = {
      sitemaps: [],
      projectId: this.activeProject.id,
    }
  }

  public updateActivitiesConfigurations(
    activitiesConfigurations: IActivitiesConfigurations,
  ) {
    this.resetToDefaultActivitiesConfigurations()

    const { configurations } = this.activitiesSettings

    if (activitiesConfigurations) {
      Object.assign(configurations, activitiesConfigurations)
    }
  }

  @action.bound
  public clearIntegrations() {
    this.integrationsConfigMap.clear()
  }

  @action.bound
  public receiveIntegrationConfig(integrationConfig: IIntegrationConfig) {
    try {
      if (!isIntegrationConfigValid(integrationConfig)) {
        throw Error(configErrorMessage)
      }

      this.integrationsConfigMap.set(
        integrationConfig.AppName,
        integrationConfig,
      )
    } catch (error) {
      console.warn(error.message)
    }
  }

  public isIntegrationEnabled = (appName: IntegrationAppName): boolean => {
    return (
      this.integrationsConfigMap.has(appName) &&
      this.integrationsConfigMap.get(appName).IsEnabled
    )
  }

  public get isWWPColoringDisabled(): boolean {
    return this.projectColoringOptions.isWWPColoringDisabled
  }

  public get isTrackerDisabled(): boolean {
    const { isActivityViewAvailable } = this.userActiveProjectSettings || {
      isActivityViewAvailable: false,
    }
    const { allowTrackerToggling } = this.featureFlags
    const isTrackerAllowed =
      typeof allowTrackerToggling === 'undefined' ? true : allowTrackerToggling
    return (
      this.projectTypeOptions.isTrackerDisabled ||
      !isTrackerAllowed ||
      !isActivityViewAvailable
    )
  }

  public get isPhotosDisabled(): boolean {
    const { allowPhotos } = this.featureFlags
    const isPhotosAllowed =
      typeof allowPhotos === 'undefined' ? true : allowPhotos
    return this.projectTypeOptions.isPhotosDisabled || !isPhotosAllowed
  }

  public get isAnalyticsDisabled(): boolean {
    const { allowAnalytics } = this.featureFlags
    const isAnalyticsAllowed =
      typeof allowAnalytics === 'undefined' ? true : allowAnalytics
    return this.projectTypeOptions.isAnalyticsDisabled || !isAnalyticsAllowed
  }

  public get isDeliveriesDisabled(): boolean {
    const { allowDeliveriesToggling } = this.featureFlags
    const isDeliveriesAllowed =
      typeof allowDeliveriesToggling === 'undefined'
        ? true
        : allowDeliveriesToggling
    return this.projectTypeOptions.isDeliveriesDisabled || !isDeliveriesAllowed
  }

  public get isFormsDisabled(): boolean {
    const { allowFormsToggling } = this.featureFlags
    const isFormsAllowed =
      typeof allowFormsToggling === 'undefined' ? true : allowFormsToggling

    return this.projectTypeOptions.isFormsDisabled || !isFormsAllowed
  }

  public get isThereLogisticsAssociatedMap(): boolean {
    return !!this.logistics.configurations.sitemaps.length
  }

  public get isThereFormsAssociatedMap(): boolean {
    return !!this.forms.configurations.sitemaps.length
  }

  public get isStatusUpdateManpowerDisabled(): boolean {
    return this.projectStatusUpdateOptions.isManpowerDisabled
  }

  public get isLogisticsDisabled(): boolean {
    const { allowLogisticsToggling } = this.featureFlags
    const isLogisticsAllowed =
      typeof allowLogisticsToggling === 'undefined'
        ? true
        : allowLogisticsToggling
    return this.projectTypeOptions.isLogisticsDisabled || !isLogisticsAllowed
  }

  public get isMaterialsDisabled(): boolean {
    const { allowMaterialsToggling } = this.featureFlags
    const isMaterialsAllowed =
      typeof allowMaterialsToggling === 'undefined'
        ? isTestEnvironment()
        : allowMaterialsToggling
    return this.projectTypeOptions.isMaterialsDisabled || !isMaterialsAllowed
  }

  public get activeOrInitProjectCode(): string {
    return this.activeProject.code || this.initProjectCode
  }

  public get activeScheduleId(): string {
    return this.activeProject.scheduleId
  }

  @computed
  public get isLoading(): boolean {
    return Array.from(this.loading.values()).some(value => value)
  }

  public getActivityFilterByDeliveryFieldId = (
    fieldId: string,
  ): ActivityFilterInfo => {
    const activityFilterType = deliveryFieldIdToActivityFilterTypeMap[fieldId]
    return this.activityFiltersSettings.filterInfoMap[activityFilterType]
  }

  private getDeliveryFieldCustomName(fieldId: string): string {
    const activityFilter = this.getActivityFilterByDeliveryFieldId(fieldId)

    return activityFilter
      ? activityFilter.getCaption()
      : this.delivery.customFieldNames[fieldId]
  }

  private updateCardConfig() {
    const { configurations } = this.delivery

    if (!configurations.cardFieldsConfig?.length) {
      configurations.cardFieldsConfig = DEFAULT_DELIVERY_CARD_CONFIG
    } else {
      const missedFields = DEFAULT_DELIVERY_CARD_CONFIG.filter(
        cardField =>
          !configurations.cardFieldsConfig.some(
            ({ fieldId }) => fieldId === cardField.fieldId,
          ),
      )
      configurations.cardFieldsConfig.push(...missedFields)
    }

    configurations.cardFieldsConfig.every(dc => delete dc['__typename'])
  }
}

class CompactDeliveryView {
  @observable public mode: DeliveryPageMode = DeliveryPageMode.VIEW
  @observable public viewMode: DeliveryPageViewMode =
    DeliveryPageViewMode.Calendar
  @observable public highlightedDelivery: Delivery = null
  @observable public activeDate: Date = new Date()
  @observable public activeEndDate: Date = new Date()

  public get isActivityMode() {
    return (
      this.mode === DeliveryPageMode.REQUEST_FROM_ACTIVITY ||
      this.mode === DeliveryPageMode.VIEW_FROM_ACTIVITY
    )
  }

  public get isViewWithHighlightedDeliveryMode() {
    return (
      this.mode === DeliveryPageMode.VIEW_WITH_HIGHLIGHTED_DELIVERY &&
      this.highlightedDelivery
    )
  }

  public resetViewMode() {
    this.viewMode = DeliveryPageViewMode.Calendar
  }
}

export interface IFilters {
  searchKey: string
  timeKey?: IDateTimeFilter
  dateKey?: IDateTimeFilter
  iconKey?: string
  groupingKey?: string
  fieldsMap?: {
    [filterType: string]: UIFilterInfo
  }
}

export interface ISharedFilters {
  selectedActivityStatusMode: ActivityStatus
  selectedRecentlyUpdatedMode: RecentlyUpdatedMode
  recentlyUpdatedStartDate: Date
  recentlyUpdatedEndDate: Date
}

import Localization from '../localization/LocalizationManager'
import { enumToList } from '../utils/converters'

enum CommonMapFilterType {
  Location = 'mapLocation',
  Company = 'mapCompany',
  ResponsibleContact = 'mapResponsibleContact',
}

enum HUBSpecificMapFilterType {
  App = 'mapApp',
}

enum FormsSpecificMapFilterType {
  Type = 'mapType',
}

export type LogisticsMapFilterType =
  | CommonMapFilterType
  | FormsSpecificMapFilterType
  | HUBSpecificMapFilterType
export const LogisticsMapFilterType = {
  ...CommonMapFilterType,
  ...FormsSpecificMapFilterType,
  ...HUBSpecificMapFilterType,
}

export const FormsMapFilterType = {
  ...CommonMapFilterType,
  ...FormsSpecificMapFilterType,
}

export const HUBMapFilterType = {
  ...CommonMapFilterType,
  ...HUBSpecificMapFilterType,
}

export const getLogisticsMapFilterTypeCaption = (
  type: LogisticsMapFilterType,
) => {
  switch (type) {
    case LogisticsMapFilterType.Location:
      return Localization.translator.location
    case LogisticsMapFilterType.Company:
      return Localization.translator.company
    case LogisticsMapFilterType.Type:
      return Localization.translator.type
    case LogisticsMapFilterType.App:
      return Localization.translator.app
    case LogisticsMapFilterType.ResponsibleContact:
      return Localization.translator.responsibleContact
    default:
      throw new Error(type + ' is unhandled')
  }
}

export const hubMapFilterTypes = enumToList(HUBMapFilterType)
export const formsMapFilterTypes = enumToList(FormsMapFilterType)

import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ActivitiesTree from '~/client/src/shared/components/ActivitiesTree/ActivitiesTree'
import AnnouncementListItem from '~/client/src/shared/components/AnnouncementListItem'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import * as Icons from '~/client/src/shared/components/Icons'
import LogisticsSitemapSetUpStore from '~/client/src/shared/components/LogisticsSitemap/LogisticsSitemapSetUp.store'
import PermitListItem from '~/client/src/shared/components/PermitListItem'
import BaseSitemapSetUpStore from '~/client/src/shared/components/SitemapHelpers/DeliverySitemap.store'
import Announcement from '~/client/src/shared/models/Announcement'
import Sitemap from '~/client/src/shared/models/Sitemap'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import smoothScrollTo from '~/client/src/shared/utils/smoothScrollTo'

import CompactDeliveriesList from '../../../../shared/components/CompactDeliveriesList/CompactDeliveriesList'
import DesktopCustomNodeWrapper from '../../../components/ActivitiesTreeNode/components/DesktopCustomNodeWrapper'
import DesktopLocationContainerNode from '../../../components/ActivitiesTreeNode/components/DesktopLocationContainerNode'
import DesktopFileInput from '../../../components/FileInput/DesktopFileInput'
import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import LogisticsStore from '../Logistics.store'
import LogisticsMapStore from './LogisticsMap/LogisticsMap.store'
import LogisticsSideBarActivities from './LogisticsSideBarActivities.store'
import LogisticsSideBarContent from './LogisticsSideBarContent'

interface IProps {
  store: LogisticsStore
  mapStore: LogisticsMapStore
  logisticsSitemapSetUpStore: LogisticsSitemapSetUpStore
  selectedSitemap: Sitemap

  projectDateStore?: ProjectDateStore
  deliveriesStore?: DeliveriesStore
  locationAttributesStore?: LocationAttributesStore
  sitemapsStore?: SitemapsStore
  sitemapItemsStore?: SitemapItemsStore
  basemapsStore?: BasemapsStore
  locationStore?: LocationsStore
  activitiesStore?: ActivitiesStore
  eventsStore?: DesktopEventStore
  companiesStore?: CompaniesStore
  activityFiltersStore?: ActivityFiltersStore
  hierarchyConfigurationStore?: HierarchyConfigurationStore
  statusUpdatesStore?: StatusUpdatesStore
  deliveryDetailsStore?: DeliveryDetailsStore

  isPermitOnly?: boolean
}

const ACTIVITY_HEIGHT = 220
const HEADER_HEIGHT = 32
const ANIMATION_TIME = 0.3

const siteNews = 'Site news'
const forms = 'Forms'
const deliveries = 'Deliveries'
const activities = 'Activities'

@inject(
  'projectDateStore',
  'deliveriesStore',
  'locationAttributesStore',
  'sitemapsStore',
  'sitemapItemsStore',
  'basemapsStore',
  'activitiesStore',
  'eventsStore',
  'companiesStore',
  'activityFiltersStore',
  'hierarchyConfigurationStore',
  'statusUpdatesStore',
  'deliveryDetailsStore',
)
@observer
export default class LogisticsSideBar extends React.Component<IProps> {
  private readonly baseSitemapSetUpStore: BaseSitemapSetUpStore
  private readonly logisticsSideBarActivitiesStore: LogisticsSideBarActivities

  private containerRef: HTMLDivElement

  private announcementsRef: HTMLDivElement
  private permitsRef: HTMLDivElement
  private deliveriesRef: HTMLDivElement
  private activitiesRef: HTMLDivElement

  public constructor(props: IProps) {
    super(props)
    this.baseSitemapSetUpStore = new BaseSitemapSetUpStore(
      props.sitemapsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
    )

    this.logisticsSideBarActivitiesStore = new LogisticsSideBarActivities(
      props.activitiesStore,
      props.eventsStore,
      props.activityFiltersStore,
      props.hierarchyConfigurationStore,
      props.locationStore,
      props.projectDateStore,
      props.companiesStore,
      props.store,
    )
  }

  public componentDidMount() {
    const { selectedSitemap } = this.props
    this.baseSitemapSetUpStore.selectSitemap(selectedSitemap)
  }

  public componentDidUpdate(newProps: IProps) {
    const { selectedSitemap } = this.props
    if (selectedSitemap !== newProps.selectedSitemap) {
      this.baseSitemapSetUpStore.selectSitemap(selectedSitemap)
    }
  }

  public render() {
    const { isPermitOnly } = this.props
    const stickyHeadersCountClass = `sticky-headers-count-${this.stickyHeaderRenderers.length}`
    return (
      <div
        className={`logistics-side-bar full-height overflow-auto no-grow ${stickyHeadersCountClass}`}
        ref={this.setContainerRef}
      >
        {!isPermitOnly && this.renderAnnouncements()}
        {this.stickyHeaderRenderers.map((renderer, idx) => (
          <React.Fragment key={idx}>{renderer?.(idx + 1)}</React.Fragment>
        ))}
      </div>
    )
  }

  private renderAnnouncements(): JSX.Element {
    const {
      showRemoveDialog,
      toggleAnnouncementsExpandState,
      areAnnouncementsExpanded,
    } = this.store
    const { filteredAndOrderedAnnouncements } = this.props.mapStore

    return (
      <LogisticsSideBarContent
        headerClassName="top0"
        headerTitle={siteNews}
        entitiesCount={filteredAndOrderedAnnouncements.length}
        isSectionExpanded={areAnnouncementsExpanded}
        onCollapseClick={toggleAnnouncementsExpandState}
        onHeaderClick={this.onAnnouncementsHeaderClick}
        headerIcon={Icons.MegaphoneFilled}
      >
        <div ref={this.setAnnouncementsRef}>
          {filteredAndOrderedAnnouncements.map(announcement => (
            <AnnouncementListItem
              key={announcement.id}
              announcement={announcement}
              onClick={this.showAnnouncement}
              onEdit={this.showCreationAndEditionAnnouncementForm}
              onDelete={showRemoveDialog}
              shouldRenderImagesPreviewLine={true}
              shouldShowPdfPreview={true}
              isSelected={this.isAnnouncementSelected(announcement.id)}
            />
          ))}
        </div>
      </LogisticsSideBarContent>
    )
  }

  private renderPermits = (headerIdx: number): JSX.Element => {
    const {
      showPermitViewOrApprovalDialog,
      togglePermitsExpandState,
      arePermitsExpanded,
    } = this.store
    const { filteredPermits } = this.props.mapStore
    const { isPermitOnly } = this.props

    return (
      <LogisticsSideBarContent
        headerClassName={classList({
          [`sticky-header-${headerIdx}`]: !isPermitOnly,
          top0: isPermitOnly,
        })}
        headerTitle={forms}
        entitiesCount={filteredPermits.length}
        isSectionExpanded={arePermitsExpanded}
        onCollapseClick={togglePermitsExpandState}
        onHeaderClick={this.onPermitsHeaderClick.bind(this, headerIdx)}
        headerIcon={Icons.GeneralForm}
      >
        <div ref={this.setPermitsRef}>
          {filteredPermits.map(permit => (
            <PermitListItem
              key={permit.id}
              permit={permit}
              onClick={showPermitViewOrApprovalDialog}
              isSelected={this.isPermitSelected(permit.id)}
            />
          ))}
        </div>
      </LogisticsSideBarContent>
    )
  }

  private renderDeliveries = (headerIdx: number): JSX.Element => {
    const {
      toggleDeliveriesExpandState,
      areDeliveriesExpanded,
      displayDeliverySideView,
    } = this.store
    const { filteredDeliveries } = this.props.mapStore
    const deliveriesCount = filteredDeliveries.length
    const rows = filteredDeliveries.map(delivery => ({ delivery }))

    return (
      <LogisticsSideBarContent
        headerClassName={`sticky-header-${headerIdx}`}
        headerTitle={deliveries}
        entitiesCount={deliveriesCount}
        isSectionExpanded={areDeliveriesExpanded}
        onCollapseClick={toggleDeliveriesExpandState}
        onHeaderClick={this.onDeliveriesHeaderClick.bind(this, headerIdx)}
        headerIcon={Icons.Truck}
      >
        <div
          className="full-width sidebar-deliveries-cards"
          ref={this.setDeliveriesRef}
        >
          <CompactDeliveriesList
            rows={rows}
            onCardClick={displayDeliverySideView}
            selectedDeliveryIds={this.selectedDeliveriesIds}
            className="row full-width"
          />
        </div>
      </LogisticsSideBarContent>
    )
  }

  private renderActivities = (headerIdx: number): JSX.Element => {
    const {
      startDate: viewDate,
      areActivitiesExpanded,
      toggleActivitiesExpandState,
    } = this.store
    const { getActivitiesTreeForDate } = this.logisticsSideBarActivitiesStore
    const { filteredActivities } = this.props.mapStore
    const activitiesTreeNodes = getActivitiesTreeForDate(filteredActivities)

    const activitiesCount = activitiesTreeNodes.length
    const height = activitiesCount * ACTIVITY_HEIGHT

    return (
      <LogisticsSideBarContent
        headerClassName={`sticky-header-${headerIdx}`}
        headerTitle={activities}
        entitiesCount={activitiesCount}
        isSectionExpanded={areActivitiesExpanded}
        onCollapseClick={toggleActivitiesExpandState}
        onHeaderClick={this.onActivitiesHeaderClick.bind(this, headerIdx)}
        headerIcon={Icons.Gantt}
      >
        <div
          className="full-width"
          style={{ height }}
          ref={this.setActivitiesRef}
        >
          <ActivitiesTree
            nodes={activitiesTreeNodes}
            viewDate={viewDate}
            CustomNodeWrapperType={DesktopCustomNodeWrapper}
            LocationContainerNodeType={DesktopLocationContainerNode}
            FileInputType={DesktopFileInput}
            compactActivityListStore={this.logisticsSideBarActivitiesStore}
          />
        </div>
      </LogisticsSideBarContent>
    )
  }

  private isAnnouncementSelected = (announcementId: string): boolean => {
    return (
      this.store.displayedAnnouncement?.id === announcementId ||
      this.selectedAnnouncementsIds.includes(announcementId)
    )
  }

  private get selectedDeliveriesIds(): string[] {
    const { displayedDelivery } = this.props.deliveryDetailsStore
    const selectedDeliveriesIds = this.sitemapSetupStore.selectedDeliveries.map(
      d => d.id,
    )
    if (!displayedDelivery) {
      return selectedDeliveriesIds
    }
    return [displayedDelivery.id, ...selectedDeliveriesIds]
  }

  private isPermitSelected = (permitId: string): boolean => {
    return (
      this.store.displayedPermitId === permitId ||
      this.selectedPermitsIds.includes(permitId)
    )
  }

  private showAnnouncement = (announcement: Announcement) => {
    this.sitemapSetupStore.selectAnnouncements([announcement])
  }

  private showCreationAndEditionAnnouncementForm = (
    announcement: Announcement,
  ) => {
    this.store.showCreationAndEditionAnnouncementForm(announcement)
  }

  private setAnnouncementsRef = (ref: HTMLDivElement) => {
    this.announcementsRef = ref
  }

  private setPermitsRef = (ref: HTMLDivElement) => {
    this.permitsRef = ref
  }

  private setDeliveriesRef = (ref: HTMLDivElement) => {
    this.deliveriesRef = ref
  }

  private setActivitiesRef = (ref: HTMLDivElement) => {
    this.activitiesRef = ref
  }

  private setContainerRef = (ref: HTMLDivElement) => {
    this.containerRef = ref
  }

  private onAnnouncementsHeaderClick = () => {
    this.onHeaderClick(this.announcementsRef, HEADER_HEIGHT)
  }

  private onPermitsHeaderClick(headerIdx: number) {
    const height = this.props.isPermitOnly
      ? HEADER_HEIGHT
      : HEADER_HEIGHT * (headerIdx + 1)
    this.onHeaderClick(this.permitsRef, height)
  }

  private onDeliveriesHeaderClick(headerIdx: number) {
    this.onHeaderClick(this.deliveriesRef, HEADER_HEIGHT * (headerIdx + 1))
  }

  private onActivitiesHeaderClick(headerIdx: number) {
    this.onHeaderClick(this.activitiesRef, HEADER_HEIGHT * (headerIdx + 1))
  }

  private onHeaderClick = (
    ref: HTMLDivElement,
    additionalHeightNum: number,
  ) => {
    if (!this.containerRef || !ref) {
      return
    }

    const parentRect = this.containerRef.getBoundingClientRect()
    const clientRec = ref.getBoundingClientRect()

    const scrollToNum =
      clientRec.top -
      additionalHeightNum -
      parentRect.top +
      this.containerRef.scrollTop

    smoothScrollTo(this.containerRef, scrollToNum, 0, ANIMATION_TIME)
  }

  private get store(): LogisticsStore {
    return this.props.store
  }

  private get selectedAnnouncementsIds(): string[] {
    return this.sitemapSetupStore.selectedAnnouncements.map(n => n.id)
  }

  private get selectedPermitsIds(): string[] {
    return this.sitemapSetupStore.selectedPermits.map(p => p.id)
  }

  private get sitemapSetupStore(): LogisticsSitemapSetUpStore {
    return this.props.logisticsSitemapSetUpStore
  }

  private get shouldShowForms(): boolean {
    return !this.props.eventsStore.appState.isFormsDisabled
  }

  private get shouldShowDeliveries(): boolean {
    const { isDeliveriesDisabled, isVisitorMode } =
      this.props.eventsStore.appState
    return !isVisitorMode && !isDeliveriesDisabled && !this.props.isPermitOnly
  }

  private get shouldShowActivities(): boolean {
    const { isTrackerDisabled, isVisitorMode } = this.props.eventsStore.appState
    return !isVisitorMode && !isTrackerDisabled && !this.props.isPermitOnly
  }

  private get stickyHeaderRenderers(): Array<
    (headerIdx: number) => JSX.Element
  > {
    return [
      ...(this.shouldShowForms ? [this.renderPermits] : []),
      ...(this.shouldShowDeliveries ? [this.renderDeliveries] : []),
      ...(this.shouldShowActivities ? [this.renderActivities] : []),
    ]
  }
}

import { LocationType } from '~/client/graph'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

import FieldIds from '../../enums/DeliveryFieldIds'
import LocationBase from './LocationBase'
import LocationBaseWithDimensions, {
  ILocationBaseWithDimensions,
} from './LocationBaseWithDimensions'

export default class InteriorPath extends LocationBaseWithDimensions<InteriorPath> {
  public static fromDto(dto: ILocationBaseWithDimensions) {
    const item = LocationBase.factory(
      InteriorPath,
      dto,
      SitemapAttributeIcon.InteriorDoor,
    )
    item.maxHeight = dto?.maxHeight
    item.maxWidth = dto?.maxWidth
    item.maxWeight = dto?.maxWeight

    return item
  }
  public type = LocationType.InteriorPath
  protected relatedFieldId = FieldIds.INTERIOR_PATH
  protected model = InteriorPath
}

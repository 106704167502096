import { action, computed, observable } from 'mobx'

import Delivery from '~/client/src/shared/models/Delivery'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import InitialState from '~/client/src/shared/stores/InitialState'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import DeliveriesViewStore from '../../DeliveriesView.store'

export default class DeliveriesMapViewStore {
  @observable public displayedDeliveryIdx: number = 0
  @observable public isGallerySwipingDisabled: boolean = false
  @observable public selectedDeliveryId: string = null

  public constructor(
    private readonly deliveriesViewStore: DeliveriesViewStore,
    private readonly state: InitialState,
    private readonly sitemapsStore: SitemapsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly sitemapItemsStore: SitemapItemsStore,
  ) {}

  private get currentSitemapId(): string {
    return this.state.delivery.selectedSitemapId
  }

  private set currentSitemapId(sitemapId: string) {
    this.state.delivery.selectedSitemapId = sitemapId
  }

  @action.bound
  public setSitemapId(sitemapId: string) {
    this.currentSitemapId = sitemapId
    this.resetDisplayedDelivery()
  }

  @action.bound
  public resetDisplayedDelivery() {
    this.displayedDeliveryIdx = 0
    this.resetSelectedDelivery()
  }

  @action.bound
  public setSwipingAbility(isDisabled: boolean) {
    this.isGallerySwipingDisabled = isDisabled
  }

  @action.bound
  public openDeliveryDetails(delivery: Delivery) {
    this.deliveriesViewStore.openDeliveryDetails(delivery)
  }

  public onSlide = (currentIndex: number) => {
    const sitemapId = this.getSitemapIdByIndex(currentIndex)
    this.setSitemapId(sitemapId)
  }

  public get displayedSitemap() {
    const sitemapId = this.getSitemapIdByIndex(this.currentSitemapIndex)
    return this.sitemapsStore.byId.get(sitemapId)
  }

  public get sitemapImages() {
    const { deliveriesSitemaps } = this.deliveriesViewStore

    return deliveriesSitemaps.map(s => ({
      original: s.filledImage,
      thumbnail: s.filledImage,
      id: s.id,
    }))
  }

  @computed
  public get attributesBySitemapItems(): LocationBase[] {
    if (!this.displayedSitemap) {
      return []
    }

    const { items } = this.displayedSitemap
    const { allAttributes } = this.locationAttributesStore

    const attributesBySitemap: LocationBase[] = []

    const sitemapItems = Object.values(items).filter(item => !item.isHidden)
    sitemapItems.forEach(itemData => {
      const item = this.sitemapItemsStore.byId.get(itemData.sitemapItemId)

      if (!item) {
        return
      }

      const attribute = allAttributes.find(a => item.isAssignedTo(a))

      if (attribute) {
        attributesBySitemap.push(attribute)
      }
    })

    return attributesBySitemap
  }

  @computed
  public get attributesBySelectedSitemap(): LocationBase[] {
    if (!this.displayedSitemap) {
      return []
    }

    return this.locationAttributesStore.allAttributes.filter(a =>
      a.isSitemapAssigned(this.displayedSitemap.id),
    )
  }

  @computed
  public get displayedSitemapDeliveries(): Delivery[] {
    if (!this.displayedSitemap) {
      return []
    }

    const { id } = this.displayedSitemap
    const { sitemaps: siteSitemaps } = this.state.logistics.configurations

    if (siteSitemaps.some(s => s.sitemapId === id)) {
      return this.deliveriesBySitemapItems
    }

    return this.deliveriesByLowestChildrenTags
  }

  @computed
  public get deliveriesBySitemapItems(): Delivery[] {
    return this.deliveriesViewStore.currentViewDeliveries
      .filter(
        d =>
          this.attributesBySitemapItems.length &&
          this.attributesBySitemapItems.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  @computed
  public get deliveriesByLowestChildrenTags(): Delivery[] {
    const lowestChildrenAttributes = this.attributesBySelectedSitemap.filter(
      a => !this.attributesBySelectedSitemap.some(at => at.isParent(a)),
    )

    return this.deliveriesViewStore.currentViewDeliveries
      .filter(
        d =>
          lowestChildrenAttributes.length &&
          lowestChildrenAttributes.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  public showPreviousDelivery = () => {
    if (this.displayedDeliveryIdx) {
      this.displayedDeliveryIdx--
      this.setSelectedDeliveryId(
        this.displayedSitemapDeliveries?.[this.displayedDeliveryIdx]?.id,
      )
    }
  }

  public showNextDelivery = () => {
    if (
      this.displayedDeliveryIdx + 1 <
      this.displayedSitemapDeliveries.length
    ) {
      this.displayedDeliveryIdx++
      this.setSelectedDeliveryId(
        this.displayedSitemapDeliveries?.[this.displayedDeliveryIdx]?.id,
      )
    }
  }

  @action.bound
  public setDisplayedDeliveryIdx(index: number) {
    this.displayedDeliveryIdx = index < 0 ? 0 : index
  }

  @action.bound
  public setSelectedDeliveryId(id: string) {
    this.selectedDeliveryId = id
  }

  @action.bound
  public resetSelectedDelivery() {
    this.selectedDeliveryId = null
  }

  public get currentSitemapIndex(): number {
    const index = this.getSitemapIndexById(this.currentSitemapId)
    return index !== -1 ? index : 0
  }

  private getSitemapIdByIndex(index: number): string {
    const image = this.sitemapImages[index]
    return image && image.id
  }

  private getSitemapIndexById(sitemapId: string): number {
    return this.sitemapImages.findIndex(s => s.id === sitemapId)
  }

  private getDeliveryAttributeById = (id: string): LocationBase => {
    return this.locationAttributesStore.getById(id)
  }

  private sortDeliveriesFunc = (a: Delivery, b: Delivery): number => {
    const aBuilding = this.getDeliveryAttributeById(a.building)
    const bBuilding = this.getDeliveryAttributeById(b.building)
    const aBuildingName = (aBuilding && aBuilding.name) || EMPTY_STRING
    const bBuildingName = (bBuilding && bBuilding.name) || EMPTY_STRING

    return aBuildingName.localeCompare(bBuildingName)
  }
}

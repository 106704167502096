import { action, computed } from 'mobx'

import { SitePermitStatus } from '~/client/graph'
import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import FormReportFilterType, {
  formReportFilterTypes,
  getFormReportFilterTypeCaption,
} from '~/client/src/shared/enums/FormReportFilterType'
import { LocalStorageKey } from '~/client/src/shared/enums/LocalStorageKey'
import { getFormStatusDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import SitePermit from '~/client/src/shared/models/Permit'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import { enumToList } from '~/client/src/shared/utils/converters'
import { LOCATION_SEPARATOR } from '~/client/src/shared/utils/usefulStrings'

import BaseReportsFilterStore, { ISourceMap } from './BaseReportsFilter.store'
import ReportsFilterStore from './ReportsFilter.store'

export default class FormReportFilterStore extends ReportsFilterStore {
  protected get availableInstances(): SitePermit[] {
    return this.instancesStore.allFormReportPermits
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseReportsFilterStore
  } {
    const map: { [filterType: string]: BaseReportsFilterStore } = {}

    formReportFilterTypes.forEach(filterType => {
      const { appState } = this.eventsStore

      map[filterType] = new BaseReportsFilterStore(
        filterType,
        appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.instancesListStore,
        this.onShowChanged,
        this.fieldsMap(),
        getFormReportFilterTypeCaption,
        this.getOptionName,
        LocalStorageKey.FormReportReportFilter,
      )
    })
    return map
  }

  @action.bound
  public resetAllFilters() {
    formReportFilterTypes.forEach(type =>
      localStorage.removeItem(
        `${this.eventsStore.appState.activeProject.id}_${LocalStorageKey.FormReportReportFilter}_${type}`,
      ),
    )
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  @computed
  protected get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  } {
    const maps = formReportFilterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableInstances.forEach(instance => {
      Object.keys(maps).forEach(filterType => {
        const map = maps[filterType]
        let optionIds: string[] = []

        switch (filterType) {
          case FormReportFilterType.COMPANY:
            optionIds = instance.companyIds?.length
              ? instance.companyIds
              : [UNASSIGNED_FILTER_OPTION]
            break
          case FormReportFilterType.STATUS:
            optionIds = [instance.status || UNASSIGNED_FILTER_OPTION]
            break
          case FormReportFilterType.LOCATION:
            optionIds = instance.locations?.length
              ? instance.locations?.map(
                  l => `${l.type}${LOCATION_SEPARATOR}${l.id}`,
                )
              : [UNASSIGNED_FILTER_OPTION]
            break
        }

        optionIds.forEach(optionId => {
          if (!map[optionId]) {
            optionId = UNASSIGNED_FILTER_OPTION
          }

          map[optionId].push(instance.id)
        })
      })
    })
    return maps
  }

  protected getDefaultSourceMapByType(type: FormReportFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case FormReportFilterType.COMPANY:
        sourceList = [
          ...this.companiesStore.availableSortedCompanies.map(c => c.id),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case FormReportFilterType.STATUS:
        sourceList = [...enumToList(SitePermitStatus), UNASSIGNED_FILTER_OPTION]
        break
      case FormReportFilterType.LOCATION:
        sourceList = Array.from(
          this.locationAttributesStore.allAttributes.reduce((acc, location) => {
            acc.add(`${location.type}${LOCATION_SEPARATOR}${location.id}`)
            return acc
          }, new Set([UNASSIGNED_FILTER_OPTION])),
        )
        break
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  protected fieldsMap() {
    return this.eventsStore.appState.formReportFilters.fieldsMap
  }

  protected getOptionName = (option: string, filterType: string): string => {
    if (filterType === FormReportFilterType.COMPANY) {
      return this.companiesStore.getCompanyNameById(
        option,
        UNASSIGNED_FILTER_OPTION,
      )
    }
    if (filterType === FormReportFilterType.STATUS) {
      return getFormStatusDisplayName(option as SitePermitStatus) || option
    }
    if (filterType === FormReportFilterType.LOCATION) {
      const [attributeType, attributeId] = option.split(LOCATION_SEPARATOR)
      const store = this.tagsStore.tagStoreByTagTypeMap[attributeType]

      return store?.byId.get(attributeId)?.name || option
    }

    return option
  }
}

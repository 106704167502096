import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'

import {
  FilterType,
  IGeoJson2DGeographicCoordinates,
  IPosition,
  ISitemapItemShapeCoordinates,
  LocationType,
  SitemapItemShapeType,
} from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import SitemapItemBase, {
  ShapeProperties,
} from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import SitemapItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import SitemapItemFactory from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemFactory'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import SitemapRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapRectangleProperties'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import SitemapItemType from '~/client/src/shared/enums/SitemapItemType'
import IAccessibleLevelsAttribute from '~/client/src/shared/models/IAccessibleLevelsAttribute'
import Area from '~/client/src/shared/models/LocationObjects/Area'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import InteriorDoor from '~/client/src/shared/models/LocationObjects/InteriorDoor'
import InteriorPath from '~/client/src/shared/models/LocationObjects/InteriorPath'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import LocationIntegration, {
  LocationIntegrationType,
} from '~/client/src/shared/models/LocationObjects/LocationIntegration'
import LogisticsObject from '~/client/src/shared/models/LocationObjects/LogisticsObject'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import Staging from '~/client/src/shared/models/LocationObjects/Staging'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapItem from '~/client/src/shared/models/SitemapItem'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import {
  sortAttributes,
  sortLevels,
} from '~/client/src/shared/utils/sortingFunctions'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'
import { areObjectsEqual } from '~/client/src/shared/utils/util'

import { getDistance } from '../../../../../../shared/components/MapBoxEditor/MapBoxEditor'
import DeliverySitemapSetUpStore from '../GeneralSitemapSetUp.store'
import { ILevelDisplayInfo } from '../components/PropertiesPanel/BuildingProperties'
import HierarchyNode from '../models/HierarchyNode'
import SitemapSetupStore from './SitemapsSetup.store'

const { Circle, Rectangle, Polyline } = SitemapItemShapeType

export const siteLogistics = 'Site Logistics'
export const maturixStations = 'Maturix Stations'
export const projectOverviewMaps = 'Project Overview Maps'
const ALLOWED_SHAPES_BY_TYPE = {
  [LocationType.OffloadingEquipment]: [Rectangle, Polyline, Circle],
  [LocationType.Route]: [Polyline],
  [LocationType.InteriorPath]: [Polyline],
}

const DEFAULT_ALLOWED_SHAPES = [Rectangle, Polyline]

const ALLOWED_PARENTS_BY_TYPE = {
  [LocationType.Area]: [LocationType.Level, LocationType.Zone],
  [LocationType.VerticalObject]: [LocationType.Building],
  [LocationType.Level]: [LocationType.Building],
  [LocationType.Building]: [LocationType.Building],
  [LocationType.Integration]: [LocationType.Level],
}
const DEFAULT_ALLOWED_PARENTS = [
  LocationType.Building,
  LocationType.Zone,
  LocationType.Level,
  LocationType.Area,
]

const ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT = [
  SitemapItemType.Line,
  SitemapItemType.TextBox,
]
const sitemapSaveEvents = [
  e.SAVE_BASEMAP,
  e.SAVE_SITEMAP,
  e.DELETE_SITEMAP,
  e.SAVE_SITEMAP_ITEM,
  e.SAVE_DELIVERY_SITEMAP_IMAGE,
]
const copiedLevelChildren = "Copied level's chidren"
const copiedSuccessfully = 'Copied Successfully'
const pastedSuccessfully = 'Pasted Successfully'
interface IActionHistory {
  selectedItem: SitemapItemBase
}

export enum ItemsCollapseState {
  collapsed,
  notCollapsed,
  transit,
}

export default class SitemapItemsSetupStore {
  @observable public selectedSitemapItem: SitemapItemBase = null
  @observable public selectedSitemapItemDrawnPart: SitemapItemDrawnPart = null

  @observable public isTextStickerCreationActive: boolean = false
  @observable public creatableAttributeType: LocationType = null
  @observable public creatableAttributeIcon: SitemapAttributeIcon = null

  @observable public focusedLevel: ILevelDisplayInfo = null
  @observable public copiedShape: ShapeProperties = null
  @observable public copiedItem: SitemapItemBase = null
  @observable public deletableSitemapItem: SitemapItemBase
  @observable public hoveredNode: HierarchyNode = null

  @observable private hierarchyExpandState = new Map<string, boolean>()
  @observable private isSaving: boolean = false

  private actionsHistory: IActionHistory[] = []
  private historyIndex: number = -1

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly sitemapItemsStore: SitemapItemsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly syncRestrictionsStore: SyncRestrictionsStore,
    private readonly activityFiltersStore: ActivityFiltersStore,
    private readonly deliverySitemapSetUpStore: DeliverySitemapSetUpStore,
  ) {}

  @computed
  public get hierarchyTree(): HierarchyNode[] {
    const hierarchy: HierarchyNode[] = []
    const siteLogisticsNode = new HierarchyNode(
      this.hierarchyExpandState,
      null,
      null,
      siteLogistics,
    )
    const maturixStationsNode = new HierarchyNode(
      this.hierarchyExpandState,
      null,
      null,
      maturixStations,
    )

    this.sitemapItems
      .filter(item => !item.hasParent)
      .forEach(item => {
        const node = new HierarchyNode(this.hierarchyExpandState, item)
        const dataObject = node.item?.dataObject
        node.children = this.getNodeChildren(node)

        if (dataObject?.type === LocationType.Building) {
          hierarchy.push(node)
        } else if (dataObject?.is(LocationIntegrationType.MaturixStation)) {
          maturixStationsNode.children.push(node)
        } else {
          siteLogisticsNode.children.push(node)
        }
      })

    return [...hierarchy, siteLogisticsNode, maturixStationsNode]
  }

  public copyLevel = (item?: SitemapItemBase) => {
    const itemToCopy = item || this.selectedSitemapItem
    if (itemToCopy?.dataObject?.type === LocationType.Level) {
      this.copiedItem = itemToCopy
      showToast(copiedLevelChildren, ToastTheme.SUCCESS, IconNames.DUPLICATE)
    }
  }

  public pasteLevel = async (item?: SitemapItemBase) => {
    const itemToPaste = item || this.selectedSitemapItem
    if (
      itemToPaste?.dataObject?.type === LocationType.Level &&
      !!this.copiedItem
    ) {
      await this.saveItemChildren(this.copiedItem, itemToPaste)

      showToast(pastedSuccessfully, ToastTheme.SUCCESS, IconNames.TICK)
    }
  }

  public copy = () => {
    const { iconProperties } = this.selectedSitemapItem
    const x = -iconProperties?.position?.x || 0
    const y = -iconProperties?.position?.y || 0
    this.copiedShape = this.setShapeCoordinates(
      this.selectedSitemapItem.shapeProperties,
      x,
      y,
    )
    showToast(copiedSuccessfully, ToastTheme.SUCCESS, IconNames.TICK)
  }

  public paste = () => {
    const { iconProperties } = this.selectedSitemapItem
    const x = iconProperties?.position?.x || 0
    const y = iconProperties?.position?.y || 0
    this.selectedSitemapItem.shapeProperties = this.setShapeCoordinates(
      this.copiedShape,
      x,
      y,
    )
    showToast(pastedSuccessfully, ToastTheme.SUCCESS, IconNames.TICK)
  }

  @computed
  public get sitemapHierarchyTree(): HierarchyNode[] {
    const hierarchy: HierarchyNode[] = []
    const siteOverviewMap = new HierarchyNode(
      this.hierarchyExpandState,
      null,
      null,
      projectOverviewMaps,
    )
    const siteLogisticsNode = new HierarchyNode(
      this.hierarchyExpandState,
      null,
      null,
      siteLogistics,
    )
    const maturixStationsNode = new HierarchyNode(
      this.hierarchyExpandState,
      null,
      null,
      maturixStations,
    )

    this.sitemapItems
      .filter(item => !item.hasParent)
      .forEach(item => {
        const node = new HierarchyNode(this.hierarchyExpandState, item)
        const dataObject = node.item?.dataObject
        node.children = this.getNodeChildren(node)

        if (dataObject?.type === LocationType.Building) {
          hierarchy.push(node)
        } else if (dataObject?.is(LocationIntegrationType.MaturixStation)) {
          maturixStationsNode.children.push(node)
        } else if (node.item?.dataObject) {
          siteLogisticsNode.children.push(node)
        } else {
          siteOverviewMap.children.push(node)
        }
      })

    return [
      siteOverviewMap,
      ...hierarchy,
      siteLogisticsNode,
      maturixStationsNode,
    ]
  }

  @computed
  public get referencedHierarchyTree(): HierarchyNode[] {
    const hierarchy: HierarchyNode[] = []
    const siteOverviewMap = new HierarchyNode(
      this.hierarchyExpandState,
      null,
      null,
      projectOverviewMaps,
    )
    const siteLogisticsNode = new HierarchyNode(
      this.hierarchyExpandState,
      null,
      null,
      siteLogistics,
    )
    const maturixStationsNode = new HierarchyNode(
      this.hierarchyExpandState,
      null,
      null,
      maturixStations,
    )

    this.sitemapItems
      .filter(item => !item.hasParent)
      .forEach(item => {
        const node = new HierarchyNode(this.hierarchyExpandState, item)
        const dataObject = node.item?.dataObject
        node.children = this.getNodeChildren(node)

        if (dataObject?.type === LocationType.Building) {
          hierarchy.push(node)
        } else if (dataObject?.is(LocationIntegrationType.MaturixStation)) {
          maturixStationsNode.children.push(node)
        } else if (node.item?.dataObject) {
          siteLogisticsNode.children.push(node)
        } else {
          siteOverviewMap.children.push(node)
        }
      })

    return [
      siteOverviewMap,
      ...hierarchy,
      siteLogisticsNode,
      maturixStationsNode,
    ]
  }

  public getItemById = (id: string) => {
    return this.sitemapItemsStore.byId.get(id)
  }

  @computed
  public get displayedSitemapItems(): SitemapItemBase[] {
    return this.sitemapItems.filter(
      i => i.isDisplayed && !this.isSitemapItemSelected(i),
    )
  }

  public toggleItemsCollapsingState = () => {
    if (this.hierarchyExpandState.size > 0) {
      this.hierarchyExpandState.clear()
    } else {
      this.sitemapItems.forEach(item => {
        this.hierarchyExpandState.set(item.id, true)
      })
      this.hierarchyExpandState.set(siteLogistics, true)
      this.hierarchyExpandState.set(maturixStations, true)
    }
  }

  public get itemsCollapseState(): ItemsCollapseState {
    switch (true) {
      case this.hierarchyExpandState.size === 0:
        return ItemsCollapseState.notCollapsed
      case this.hierarchyExpandState.size === this.sitemapItems.length + 1:
        return ItemsCollapseState.collapsed
      default:
        return ItemsCollapseState.transit
    }
  }

  public getAllowedShapesForType = (
    type: LocationType,
  ): SitemapItemShapeType[] => {
    return ALLOWED_SHAPES_BY_TYPE[type] || DEFAULT_ALLOWED_SHAPES
  }

  @computed
  public get sitemapItems(): SitemapItemBase[] {
    if (!this.sitemap) {
      return []
    }

    const items: SitemapItemBase[] = []

    this.hierarchyAttributes.forEach(dataObject => {
      const assignedItems = this.sitemapItemsStore.list.filter(s =>
        s.isAssignedTo(dataObject),
      )

      if (!assignedItems?.length) {
        const sitemapItem = this.getDefaultSitemapItemForAttribute(dataObject)

        items.push(
          SitemapItemFactory.fromItem(
            dataObject.copy(),
            sitemapItem.copy(),
            this.sitemap,
          ),
        )

        return
      }

      const convertedItems: SitemapItemBase[] = assignedItems.map(item => {
        return SitemapItemFactory.fromItem(
          dataObject.copy(),
          item.copy(),
          this.sitemap,
        )
      })

      items.push(
        convertedItems.reduce((prevVal, current) =>
          current?.isDisplayed ? current : prevVal,
        ),
      )
    })

    this.sitemapItemsStore.list
      .filter(
        item =>
          !item.isAssigned &&
          ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT.includes(item.type) &&
          this.allSitemaps.some(s => s.hasDisplayData(item.id)),
      )
      .forEach(item => {
        items.push(SitemapItemFactory.fromItem(null, item.copy(), this.sitemap))
      })

    if (
      this.selectedSitemapItem &&
      !this.selectedSitemapItem.id &&
      !this.isSitemapBlocked
    ) {
      items.push(this.selectedSitemapItem)
    }

    return items
  }

  @computed
  public get selectedSitemapItemAllowedParents(): LocationBase[] {
    if (!this.selectedSitemapItem) {
      return
    }

    const { dataObject } = this.selectedSitemapItem

    let allowedAttributes = this.hierarchyAttributes.filter(dto =>
      this.allowedTypes.includes(dto.type),
    )

    if (dataObject && dataObject?.type === LocationType.Building) {
      allowedAttributes = allowedAttributes.filter(
        a => a.id !== dataObject.id && !a.hasParent,
      )
    }
    return allowedAttributes
  }

  @computed
  public get selectedSitemapItemRestrictedParents(): LocationBase[] {
    if (!this.selectedSitemapItem) {
      return
    }

    const { dataObject } = this.selectedSitemapItem
    return this.hierarchyAttributes.filter(
      dto =>
        !this.allowedTypes.includes(dto.type) ||
        dto.id === dataObject?.id ||
        (dataObject?.type === LocationType.Building && dto.hasParent),
    )
  }

  public isSitemapItemSelected = (item: SitemapItemBase) => {
    return this.selectedSitemapItem && this.selectedSitemapItem.id === item.id
  }

  @action.bound
  public async selectSitemapItem(item: SitemapItemBase) {
    if (!item) {
      this.deselectSitemapItem()
    }
    if (!this.isSitemapItemSelected(item)) {
      await this.saveSelectedSitemapItem()
    }
    if (this.creatableAttributeType) {
      this.disableCreatingAttribute()
    }

    this.selectedSitemapItem = item.copy()
    this.deliverySitemapSetUpStore.mapBoxEditorStore.setItems()
    if (!this.selectedSitemapItem.isDisplayed) {
      this.selectedSitemapItem.fillDataBeforeDisplaying(this.allSitemaps)
    }
    this.selectSitemapItemDrawnPartIfRequired()
    this.initActionsHistory()
  }

  public selectSitemapItemDrawnPartIfRequired() {
    const {
      hasMultipleParts,
      sitemapItem: { type: sitemapItemType },
      iconProperties,
      labelProperties,
      shapeProperties,
      id,
    } = this.selectedSitemapItem

    if (hasMultipleParts || (id && sitemapItemType === SitemapItemType.Line)) {
      return
    }
    if (iconProperties) {
      this.selectSitemapItemDrawnPart(SitemapItemDrawnPart.Icon)
    }
    if (labelProperties) {
      this.selectSitemapItemDrawnPart(SitemapItemDrawnPart.Label)
    }
    if (shapeProperties) {
      this.selectSitemapItemDrawnPart(SitemapItemDrawnPart.Shape)
    }
  }

  @action.bound
  public selectSitemapItemDrawnPart(part: SitemapItemDrawnPart) {
    if (
      this.selectedSitemapItem &&
      this.selectedSitemapItem.hasDrawnPart(part)
    ) {
      this.selectedSitemapItemDrawnPart = part
    } else {
      this.deselectSitemapItemDrawnPart()
    }
  }

  @action.bound
  public deselectSitemapItemDrawnPart() {
    this.selectedSitemapItemDrawnPart = null
  }

  @action.bound
  public deselectSitemapItem() {
    this.selectedSitemapItem = null
    this.deselectSitemapItemDrawnPart()
    this.clearActionsHistory()
  }

  public onMapClick = async () => {
    if (this.isSaving) {
      return
    }
    this.setSaving(true)
    await this.saveSelectedSitemapItem()
    this.setSaving(false)
  }

  @computed
  public get isSitemapBlocked(): boolean {
    return this.selectedSitemapItem && (this.isSaving || this.isSitemapUpdating)
  }

  public saveSelectedSitemapItem = async (shouldKeepSelected?: boolean) => {
    if (!this.selectedSitemapItem || !this.selectedSitemapItem.isValid()) {
      return this.deselectSitemapItem()
    }
    this.setSaving(true)

    const objToUpdate = this.selectedSitemapItem.dataObject?.copy()
    const isLvlParentChanged = this.isLevelParentChanged(objToUpdate)

    const { canvas } = this.deliverySitemapSetUpStore.mapBoxEditorStore
    if (
      !!canvas &&
      !!canvas.getMap().transform &&
      this.selectedSitemapItem.iconProperties &&
      this.selectedSitemapItem?.sitemapItem?.isReferenced
    ) {
      const xPosition =
        (canvas.getMap().transform.width / 100) *
        this.selectedSitemapItem.iconProperties.position.x
      const yPosition =
        (canvas.getMap().transform.height / 100) *
        this.selectedSitemapItem.iconProperties.position.y

      const newPoint = canvas.getMap().unproject([xPosition, yPosition])
      this.selectedSitemapItem.coordinates = {
        latitude: newPoint.lat,
        longitude: newPoint.lng,
      }
      this.selectedSitemapItem.sitemapItem.coordinates = {
        latitude: newPoint.lat,
        longitude: newPoint.lng,
      }
    }

    if (
      !!canvas &&
      !!canvas.getMap().transform &&
      this.selectedSitemapItem.shapeProperties &&
      this.selectedSitemapItem?.sitemapItem?.isReferenced
    ) {
      this.selectedSitemapItem.sitemapItem.shapeCoordinates =
        this.saveSitemapItemShapeCoordinates(this.selectedSitemapItem)
    }

    const itemToSave = this.selectedSitemapItem.copy()

    const shouldUpdateSitemapSnapshot = await this.saveSitemapItem(itemToSave)

    if (isLvlParentChanged) {
      await this.updateAccessibleLevelsByLevel(objToUpdate)
    }

    if (!shouldKeepSelected) {
      this.deselectSitemapItem()
      this.deliverySitemapSetUpStore.mapBoxEditorStore.setItems()
    }

    if (shouldUpdateSitemapSnapshot) {
      await this.deliverySitemapSetUpStore.requestSaveDeliverySitemapImage()
    }

    this.setSaving(false)
  }

  @computed
  public get isSitemapUpdating(): boolean {
    return sitemapSaveEvents.some(ev =>
      this.eventsStore.appState.loading.get(ev),
    )
  }

  public getItemPosition = (): IGeoJson2DGeographicCoordinates => {
    if (!this.deliverySitemapSetUpStore.sitemapSetupStore.selectedSitemap) {
      return
    }
    const { bounds, bearing, center } =
      this.deliverySitemapSetUpStore.sitemapSetupStore.selectedSitemap

    const radBearing = bearing * (Math.PI / 180)
    if (!bounds || !this.selectedSitemapItem.iconProperties) {
      return
    }
    const north = bounds?.ne?.lat
    const south = bounds?.sw?.lat
    const east = bounds?.ne?.lng
    const west = bounds?.sw?.lng
    const height = Math.abs(north - south)
    const width = Math.abs(east - west)
    let lngSub = 0
    let latSub = 0

    lngSub = Math.min(east, west)
    latSub = Math.max(north, south)
    const x =
      lngSub +
      (this.selectedSitemapItem.iconProperties.position.x * width) / 100
    const y =
      latSub -
      (this.selectedSitemapItem.iconProperties.position.y * height) / 100

    const newCoorX = x - center.lng
    const newCoorY = y - center.lat

    const rotatedX =
      newCoorX * Math.cos(radBearing) + newCoorY * Math.sin(radBearing)
    const rotatedY =
      -newCoorX * Math.sin(radBearing) + newCoorY * Math.cos(radBearing)

    const finalCoorX = rotatedX + center.lng
    const finalCoorY = rotatedY + center.lat
    this.selectedSitemapItem.coordinates = {
      latitude: finalCoorY,
      longitude: finalCoorX,
    }
    this.selectedSitemapItem.sitemapItem.coordinates = {
      latitude: finalCoorY,
      longitude: finalCoorX,
    }
  }

  public saveSitemapItem = async (item: SitemapItemBase): Promise<boolean> => {
    this.deliverySitemapSetUpStore.showLoader()

    const { item: dataObject, isUpdated: isDataObjectUpdated } =
      await this.saveDataObjectIfChanged(item)

    if (dataObject) {
      item.sitemapItem.assign(dataObject)
    }

    const { id: sitemapItemId, isUpdated: isSitemapItemUpdated } =
      await this.saveSitemapItemRelatedData(item)

    item.sitemapItem.id = sitemapItemId
    const isSitemapSpecificItemUpdated = this.updateSitemapSpecificItem(item)
    this.deliverySitemapSetUpStore.mapBoxEditorStore.setItems()

    if (isSitemapSpecificItemUpdated) {
      await this.sitemapsSetupStore.saveSitemap(this.sitemap)
    }

    this.deliverySitemapSetUpStore.hideLoader()

    return (
      isDataObjectUpdated ||
      isSitemapItemUpdated ||
      isSitemapSpecificItemUpdated
    )
  }

  // save shape coordinates [real world] for sitemap items
  public saveSitemapItemShapeCoordinates = ({
    shapeProperties,
  }: SitemapItemBase): ISitemapItemShapeCoordinates => {
    const { getItemCoordinates } =
      this.deliverySitemapSetUpStore.mapBoxEditorStore

    const coordinates: IGeoJson2DGeographicCoordinates[] = []
    let isClosed = false
    let divisionEndAngle
    let divisionStartAngle

    // saving center, border point, start angle point, end angle point
    switch (shapeProperties.type) {
      case SitemapItemShapeType.Circle:
        isClosed = (shapeProperties as SitemapCircleProperties).isDivided
        const {
          position,
          radius,
          divisionEndAngle: angleStart,
          divisionStartAngle: angleEnd,
          isDividedAndDivisionAnglesValid,
        } = shapeProperties as SitemapCircleProperties

        divisionStartAngle = angleStart
        divisionEndAngle = angleEnd

        if (isDividedAndDivisionAnglesValid) {
          const centerReal = getItemCoordinates(position.x, position.y)
          const realPoint = getItemCoordinates(position.x, position.y + radius)
          const distance = getDistance(
            centerReal.longitude,
            realPoint.longitude,
            centerReal.latitude,
            realPoint.latitude,
          )
          const position2 = {
            longitude: divisionStartAngle,
            latitude: position.y + distance * Math.sin(divisionStartAngle),
          }
          const position3 = {
            longitude: divisionEndAngle,
            latitude: position.y + distance * Math.sin(divisionEndAngle),
          }

          coordinates.push(centerReal)
          coordinates.push(realPoint)
          coordinates.push(position2)
          coordinates.push(position3)
        } else {
          coordinates.push(getItemCoordinates(position.x, position.y))
          coordinates.push(getItemCoordinates(position.x, position.y + radius))
        }
        break
      // saving all points
      case SitemapItemShapeType.Polyline:
        isClosed = (shapeProperties as SitemapPolyLineProperties).isClosed
        const { points } = shapeProperties as SitemapPolyLineProperties

        points.forEach(point => {
          coordinates.push(getItemCoordinates(point.x, point.y))
        })
        break
      // saving all 4 corners and 1-st corner twice
      case SitemapItemShapeType.Rectangle:
        const {
          position: { x, y },
          width,
          height,
        } = shapeProperties as SitemapRectangleProperties

        coordinates.push(
          ...[
            getItemCoordinates(x, y),
            getItemCoordinates(x + width, y),
            getItemCoordinates(x + width, y + height),
            getItemCoordinates(x, y + height),
            getItemCoordinates(x, y),
          ],
        )
        break
      default:
        break
    }

    return {
      isClosed,
      coordinates,
      type: shapeProperties.type,
      divisionStartAngle,
      divisionEndAngle,
    } as ISitemapItemShapeCoordinates
  }

  public async saveDataObjectIfChanged(item: SitemapItemBase) {
    const { dataObject } = item
    if (!dataObject) {
      return { item: null, isUpdated: false }
    }
    const existingObject = this.hierarchyAttributes.find(i => i.id === item.id)
    const isParentChanged = !areObjectsEqual(
      existingObject?.parent || {},
      dataObject.parent || {},
    )
    let res
    switch (dataObject.type) {
      case LocationType.Building:
        res = await this.locationAttributesStore.buildingsStore.updateIfChanged(
          dataObject as Building,
        )
        await this.activityFiltersStore.setDefaultActivityLocationRelationships(
          FilterType.Building,
          dataObject,
        )
        break
      case LocationType.Zone:
        res = await this.locationAttributesStore.zonesStore.updateIfChanged(
          dataObject as Zone,
        )
        break
      case LocationType.Gate:
        res = await this.locationAttributesStore.gatesStore.updateIfChanged(
          dataObject as Gate,
        )
        break
      case LocationType.Route:
        res = await this.locationAttributesStore.routesStore.updateIfChanged(
          dataObject as Route,
        )
        break
      case LocationType.OffloadingEquipment:
        res =
          await this.locationAttributesStore.offloadingEquipmentsStore.updateIfChanged(
            dataObject as OffloadingEquipment,
          )
        break
      case LocationType.LogisticsObject:
        res =
          await this.locationAttributesStore.logisticsObjectsStore.updateIfChanged(
            dataObject as LogisticsObject,
          )
        break
      case LocationType.Integration:
        res =
          await this.locationAttributesStore.locationIntegrationsStore.updateIfChanged(
            dataObject as LocationIntegration,
          )
        break
      case LocationType.VerticalObject:
        res =
          await this.locationAttributesStore.verticalObjectsStore.updateIfChanged(
            dataObject as VerticalObject,
          )
        break
      case LocationType.Level:
        res = await this.locationAttributesStore.levelsStore.updateIfChanged(
          dataObject as Level,
        )
        await this.activityFiltersStore.setDefaultActivityLocationRelationships(
          FilterType.Level,
          dataObject,
        )
        break
      case LocationType.Area:
        res = await this.locationAttributesStore.areasStore.updateIfChanged(
          dataObject as Area,
        )
        await this.activityFiltersStore.setDefaultActivityLocationRelationships(
          FilterType.Zone,
          dataObject,
        )
        break
      case LocationType.Staging:
        res = await this.locationAttributesStore.stagingsStore.updateIfChanged(
          dataObject as Staging,
        )
        break
      case LocationType.InteriorDoor:
        res =
          await this.locationAttributesStore.interiorDoorsStore.updateIfChanged(
            dataObject as InteriorDoor,
          )
        break
      case LocationType.InteriorPath:
        res =
          await this.locationAttributesStore.interiorPathsStore.updateIfChanged(
            dataObject as InteriorPath,
          )
        break
    }

    if (isParentChanged) {
      this.syncRestrictionsStore.updateRestrictionsForItem(dataObject)
    }
    return res
  }

  public async saveSitemapItemRelatedData(
    item: SitemapItemBase,
  ): Promise<{ id: string; isUpdated: boolean }> {
    const { sitemapItem } = item

    const existingItem = this.sitemapItemsStore.byId.get(sitemapItem.id)

    if (existingItem && existingItem.isEqual(sitemapItem)) {
      return {
        id: sitemapItem.id,
        isUpdated: false,
      }
    }

    const id = await this.saveItem(sitemapItem)

    return { id, isUpdated: true }
  }

  public updateSitemapSpecificItem(item: SitemapItemBase): boolean {
    const { sitemapItem } = item
    const displayData = this.sitemap.getItemDisplayData(sitemapItem.id)

    if (!item.isDisplayDataEqual(displayData)) {
      const updatedData = item.getDisplayData()

      this.sitemap.setItemDisplayData(sitemapItem.id, updatedData)

      return true
    }

    return false
  }

  @action.bound
  public createDataLessItem(type: SitemapItemType) {
    const item = SitemapItemFactory.createDataLessItem(
      type,
      this.state.activeProject.id,
      this.sitemapItems.map(i => i.name),
    )
    this.selectSitemapItem(item)
  }

  @action.bound
  public enableCreatingAttribute(
    type: LocationType,
    iconName?: SitemapAttributeIcon,
  ) {
    this.saveSelectedSitemapItem()
    this.isTextStickerCreationActive = false
    this.creatableAttributeType = type
    this.creatableAttributeIcon = iconName
  }

  @action.bound
  public enableCreatingText() {
    this.saveSelectedSitemapItem()
    this.disableCreatingAttribute()
    this.isTextStickerCreationActive = true
  }

  @action.bound
  public disableCreatingAttribute() {
    this.creatableAttributeType = null
    this.creatableAttributeIcon = null
  }

  @action.bound
  public createAttributeInPosition(position: IPosition) {
    if (this.isTextStickerCreationActive) {
      this.createTextBoxEditor(position)
      return
    }

    if (!this.creatableAttributeType) {
      return
    }

    const color =
      this.creatableAttributeType === LocationType.Building &&
      this.locationAttributesStore.buildingsStore.colorForNewItem

    const item = SitemapItemFactory.createDataItem(
      this.creatableAttributeType,
      this.creatableAttributeIcon,
      position,
      this.state.activeProject.id,
      this.sitemapItems.map(i => i.name),
      color,
      this.deliverySitemapSetUpStore.sitemapSetupStore.selectedSitemap
        .isReferenced,
    )

    this.selectSitemapItem(item)
  }

  public get deletableSitemapItemCaption(): string {
    if (!this.deletableSitemapItem) {
      return
    }
    const { dataObject } = this.deletableSitemapItem
    if (dataObject) {
      return dataObject.getFieldName(this.state)
    }
    return this.deletableSitemapItem.typeCaption
  }

  @action.bound
  public showDeleteConfirmationDialog(item: SitemapItemBase) {
    if (!item) {
      return
    }
    if (!item.id) {
      return this.deselectSitemapItem()
    }
    this.deletableSitemapItem = item
  }

  @action.bound
  public hideSitemapItemDeleteConfirmDialog() {
    this.deletableSitemapItem = null
  }

  @action.bound
  public async applySitemapItemDeleteConfirmDialog() {
    if (!this.deletableSitemapItem?.id) {
      return
    }

    const item = this.deletableSitemapItem

    this.hideSitemapItemDeleteConfirmDialog()

    this.deliverySitemapSetUpStore.showLoader()

    if (this.selectedSitemapItem && this.selectedSitemapItem.id === item.id) {
      this.deselectSitemapItem()
    }

    this.setNewParentsForDeletableItem(item)

    if (item.dataObject) {
      await this.deleteDataObject(item.dataObject)
    }

    if (item.sitemapItem.id) {
      await this.removeSitemapItems([item.sitemapItem.id])
    }

    this.sitemap.deleteItemDisplayData(item.sitemapItem.id)

    await this.sitemapsSetupStore.saveSitemap(this.sitemap)

    await this.deliverySitemapSetUpStore.requestSaveDeliverySitemapImage()

    this.deliverySitemapSetUpStore.hideLoader()
  }

  public async deleteSitemapItemsByIds(ids: string[]) {
    const items = this.sitemapItems.filter(i => ids.includes(i.id))

    if (this.selectedSitemapItem && ids.includes(this.selectedSitemapItem.id)) {
      this.deselectSitemapItem()
    }

    for (const item of items) {
      this.setNewParentsForDeletableItem(item)

      if (item.dataObject) {
        await this.deleteDataObject(item.dataObject)
      }

      if (this.sitemap.isItemDisplayed(item.sitemapItem.id)) {
        this.sitemap.deleteItemDisplayData(item.sitemapItem.id)
      }
    }

    const itemsIdsToDelete = items.map(i => i.sitemapItem?.id).filter(i => i)
    await this.removeSitemapItems(itemsIdsToDelete)

    await this.sitemapsSetupStore.saveSitemap(this.sitemap)
    await this.deliverySitemapSetUpStore.requestSaveDeliverySitemapImage()
  }

  public async deleteDataObject(dataObject: LocationBase) {
    switch (dataObject.type) {
      case LocationType.Building:
        return await this.locationAttributesStore.buildingsStore.removeItems([
          dataObject.id,
        ])
      case LocationType.Zone:
        return await this.locationAttributesStore.zonesStore.removeItems([
          dataObject.id,
        ])
      case LocationType.Gate:
        return await this.locationAttributesStore.gatesStore.removeItems([
          dataObject.id,
        ])
      case LocationType.Route:
        return await this.locationAttributesStore.routesStore.removeItems([
          dataObject.id,
        ])
      case LocationType.OffloadingEquipment:
        return await this.locationAttributesStore.offloadingEquipmentsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.LogisticsObject:
        return await this.locationAttributesStore.logisticsObjectsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.Integration:
        return await this.locationAttributesStore.locationIntegrationsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.Staging:
        return await this.locationAttributesStore.stagingsStore.removeItems([
          dataObject.id,
        ])
      case LocationType.InteriorDoor:
        return await this.locationAttributesStore.interiorDoorsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.InteriorPath:
        return await this.locationAttributesStore.interiorPathsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.VerticalObject:
        return await this.locationAttributesStore.verticalObjectsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.Level:
        return await this.locationAttributesStore.levelsStore.removeItems([
          dataObject.id,
        ])
      case LocationType.Area:
        return await this.locationAttributesStore.areasStore.removeItems([
          dataObject.id,
        ])
    }
  }

  public async saveDataObject(
    dataObject: LocationBase,
    shouldSkipAdditionalSaveAction?: boolean,
  ) {
    switch (dataObject.type) {
      case LocationType.Building:
        return await this.locationAttributesStore.buildingsStore.saveItem(
          dataObject as Building,
          shouldSkipAdditionalSaveAction,
        )
      case LocationType.Zone:
        return await this.locationAttributesStore.zonesStore.saveItem(
          dataObject as Zone,
        )
      case LocationType.Gate:
        return await this.locationAttributesStore.gatesStore.saveItem(
          dataObject as Gate,
        )
      case LocationType.Route:
        return await this.locationAttributesStore.routesStore.saveItem(
          dataObject as Route,
        )
      case LocationType.OffloadingEquipment:
        return await this.locationAttributesStore.offloadingEquipmentsStore.saveItem(
          dataObject as OffloadingEquipment,
        )
      case LocationType.LogisticsObject:
        return await this.locationAttributesStore.logisticsObjectsStore.saveItem(
          dataObject as LogisticsObject,
        )
      case LocationType.Integration:
        return await this.locationAttributesStore.locationIntegrationsStore.saveItem(
          dataObject as LocationIntegration,
        )
      case LocationType.Staging:
        return await this.locationAttributesStore.stagingsStore.saveItem(
          dataObject as Staging,
        )
      case LocationType.InteriorDoor:
        return await this.locationAttributesStore.interiorDoorsStore.saveItem(
          dataObject as InteriorDoor,
        )
      case LocationType.InteriorPath:
        return await this.locationAttributesStore.interiorPathsStore.saveItem(
          dataObject as InteriorPath,
        )
      case LocationType.VerticalObject:
        return await this.locationAttributesStore.verticalObjectsStore.saveItem(
          dataObject as VerticalObject,
        )
      case LocationType.Level:
        return await this.locationAttributesStore.levelsStore.saveItem(
          dataObject as Level,
        )
      case LocationType.Area:
        return await this.locationAttributesStore.areasStore.saveItem(
          dataObject as Area,
        )
    }
  }

  public async setNewParentsForDeletableItem(item: SitemapItemBase) {
    const children = this.sitemapItems.filter(i => i.isParent(item))
    if (!children.length) {
      return
    }

    const levelIdsToRemove = []

    const updateRelatedSiteMapItems = children.map(child => {
      if (child.dataObject?.type === LocationType.Level) {
        child.setParent(null)
        this.setNewParentsForDeletableItem(child)
        levelIdsToRemove.push(child.id)
      }
      child.setParent(item.parent)
      return this.saveSitemapItem(child)
    })

    this.locationAttributesStore.levelsStore.removeItems(levelIdsToRemove)

    return Promise.all(updateRelatedSiteMapItems)
  }

  public sitemapAssignedItems(sitemap: Sitemap): LocationBase[] {
    if (!sitemap) {
      return []
    }
    return this.hierarchyAttributes.filter(dto =>
      dto.isSitemapAssigned(sitemap.id),
    )
  }

  public get selectedSitemapAssignedItems(): LocationBase[] {
    if (!this.sitemap) {
      return []
    }
    return this.hierarchyAttributes.filter(dto =>
      dto.isSitemapAssigned(this.sitemap.id),
    )
  }

  public updateSitemapAssignedItems = async (
    newAssignedIds: string[],
    sitemap?: Sitemap,
  ) => {
    const sitemapToCheck = sitemap || this.sitemap
    if (!sitemapToCheck) {
      return
    }

    await this.hierarchyAttributes.forEach(item => {
      if (
        item.isSitemapAssigned(sitemapToCheck.id) &&
        !newAssignedIds.includes(item.id)
      ) {
        item.deassignSitemap(sitemapToCheck.id)
        this.saveDataObject(item)
      }

      if (
        !item.isSitemapAssigned(sitemapToCheck.id) &&
        newAssignedIds.includes(item.id)
      ) {
        item.assignSitemap(sitemapToCheck.id)
        this.saveDataObject(item)
      }
    })
  }

  public isItemAssignedToSitemap = (item: SitemapItemBase) => {
    return (
      item.dataObject && this.isDataObjectAssignedToSitemap(item.dataObject)
    )
  }

  public isDataObjectAssignedToSitemap = (dataObject: LocationBase) => {
    return dataObject.isSitemapAssigned(this.sitemap.id)
  }

  @computed
  public get areThereDisplayedItems(): boolean {
    return this.sitemapItems.some(i => i.isDisplayed)
  }

  @action.bound
  public toggleAllItemsVisibility() {
    if (!this.isUpdatingSitemapLoaderShown) {
      this.toggleItemsVisibility(this.sitemapItems, this.areThereDisplayedItems)
    }
  }

  @action.bound
  public toggleSingleItemVisibility(
    item: SitemapItemBase,
    avoidSaving?: boolean,
  ) {
    this.toggleItemsVisibility([item], item.isDisplayed, avoidSaving)
  }

  @action.bound
  public async toggleItemsVisibility(
    items: SitemapItemBase[],
    areItemsDisplayed: boolean,
    avoidSaving?: boolean,
  ) {
    const { showLoader, hideLoader } = this.deliverySitemapSetUpStore

    if (!avoidSaving) {
      showLoader()
    }

    if (areItemsDisplayed) {
      await this.hideItems(items, avoidSaving)
    } else {
      await this.displayItems(items, avoidSaving)
    }

    hideLoader()
  }

  @computed
  public get hierarchyAttributes(): LocationBase[] {
    return [
      ...this.locationAttributesStore.buildingsStore.list.sort(sortAttributes),
      ...this.locationAttributesStore.zonesStore.list.sort(sortAttributes),
      ...this.locationAttributesStore.gatesStore.list.sort(sortAttributes),
      ...this.locationAttributesStore.routesStore.list.sort(sortAttributes),
      ...this.locationAttributesStore.offloadingEquipmentsStore.list.sort(
        sortAttributes,
      ),
      ...this.locationAttributesStore.logisticsObjectsStore.list.sort(
        sortAttributes,
      ),
      ...this.locationAttributesStore.locationIntegrationsStore.list.sort(
        sortAttributes,
      ),
      ...this.locationAttributesStore.verticalObjectsStore.list.sort(
        sortAttributes,
      ),
      ...this.locationAttributesStore.levelsStore.list,
      ...this.locationAttributesStore.stagingsStore.list.sort(sortAttributes),
      ...this.locationAttributesStore.interiorDoorsStore.list.sort(
        sortAttributes,
      ),
      ...this.locationAttributesStore.interiorPathsStore.list.sort(
        sortAttributes,
      ),
      ...this.locationAttributesStore.areasStore.list.sort(sortAttributes),
    ]
  }

  public addCurrentStateToHistory = () => {
    if (!this.selectedSitemapItem) {
      return
    }
    const newActionHistory: IActionHistory = {
      selectedItem: this.selectedSitemapItem.copy(),
    }

    this.actionsHistory.splice(
      ++this.historyIndex,
      this.actionsHistory.length,
      newActionHistory,
    )
  }

  public undoAction = () => {
    if (this.actionsHistory[this.historyIndex - 1]) {
      this.historyIndex--
      this.applyStateFromHistory()
    }
  }

  public redoAction = () => {
    if (this.actionsHistory[this.historyIndex + 1]) {
      this.historyIndex++
      this.applyStateFromHistory()
    }
  }

  public updateAccessibleLevelsByLevel = async (level: LocationBase<Level>) => {
    if (
      level?.type !== LocationType.Level ||
      level?.parent?.parentType !== LocationType.Building
    ) {
      return
    }

    const buildingDto =
      this.locationAttributesStore.buildingsStore.getInstanceById(
        level.parent.parentId,
      )
    await this.addLevelsToAccessibles(buildingDto, [level.id])
  }

  @action.bound
  public resetAccessibleLevels(item: SitemapItemBase) {
    const { dataObject } = item

    let attribute: VerticalObject | OffloadingEquipment = null
    switch (dataObject?.type) {
      case LocationType.VerticalObject:
        attribute = dataObject as VerticalObject
        break
      case LocationType.OffloadingEquipment:
        attribute = dataObject as OffloadingEquipment
        break
    }

    if (!attribute) {
      return
    }

    attribute.accessibleLevels = this.getAccessibleLevelsByParentId(
      attribute.parent?.parentId,
    )
  }

  public addLevelsToAccessibles = async (
    buildingDto: Building,
    levelIds: string[],
  ) => {
    this.deliverySitemapSetUpStore.showLoader()

    const {
      list: verticalObjects,
      saveItemsWhenNestedNumberOfLevelsChanges: saveMultipleVerticalObjs,
    } = this.locationAttributesStore.verticalObjectsStore
    const {
      list: equipments,
      saveItemsWhenNestedNumberOfLevelsChanges: saveMultipleEquipments,
    } = this.locationAttributesStore.offloadingEquipmentsStore

    await this.updateObjectAccessibleLevels(
      verticalObjects,
      saveMultipleVerticalObjs,
      buildingDto,
      levelIds,
    )
    await this.updateObjectAccessibleLevels(
      equipments,
      saveMultipleEquipments,
      buildingDto,
      levelIds,
    )

    this.deliverySitemapSetUpStore.hideLoader()
  }

  private async updateObjectAccessibleLevels<
    T extends IAccessibleLevelsAttribute & LocationBase,
  >(
    list: T[],
    saveMany: (elements: T[]) => Promise<string[]>,
    buildingDto: Building,
    levelIds: string[],
  ) {
    const elements = list.filter(vo => vo.isParent(buildingDto))

    const updatedObjects = this.getUpdatedAccessibleLvlsObjs(
      elements,
      buildingDto,
      levelIds,
    )

    await saveMany(updatedObjects)
  }

  private getUpdatedAccessibleLvlsObjs<T extends IAccessibleLevelsAttribute>(
    list: T[],
    buildingDto: Building,
    levelIds: string[],
  ): T[] {
    return list
      .map(element => {
        const currentAccessibleLevels = this.getAccessibleLevelsByParentId(
          buildingDto.id,
          element,
        )
        const idsToAdd = levelIds.filter(
          lvl => !currentAccessibleLevels.includes(lvl),
        )

        if (!idsToAdd.length) {
          return null
        }

        currentAccessibleLevels.push(...idsToAdd)
        element.accessibleLevels = currentAccessibleLevels

        return element
      })
      .filter(el => el)
  }

  private getAccessibleLevelsByParentId = (
    parentId: string,
    attribute?: IAccessibleLevelsAttribute,
  ): string[] => {
    const { list: allLevels } = this.locationAttributesStore.levelsStore
    const building =
      this.locationAttributesStore.buildingsStore.getInstanceById(parentId)

    let levels = allLevels.filter(l => l.isParent(building))

    if (attribute?.accessibleLevels?.length) {
      const filteredLvls = levels.filter(l =>
        attribute.accessibleLevels.includes(l.id),
      )
      levels = filteredLvls.length ? filteredLvls : levels
    }

    return (
      building?.sortLevelsByCustomOrder(levels) ||
      levels.sort((a, b) => sortLevels(b, a))
    ).map(({ id }) => id)
  }

  private isLevelParentChanged = (attribute: LocationBase): boolean => {
    if (attribute?.type !== LocationType.Level) {
      return false
    }

    const existingLevel =
      this.locationAttributesStore.levelsStore.getInstanceById(attribute?.id)

    return (
      existingLevel &&
      attribute.parent?.parentId !== existingLevel.parent?.parentId
    )
  }

  private initActionsHistory() {
    if (this.selectedSitemapItem) {
      this.actionsHistory = [
        {
          selectedItem: this.selectedSitemapItem.copy(),
        },
      ]
      this.historyIndex = 0
    }
  }

  private createTextBoxEditor(position: IPosition) {
    const textItem = SitemapItemFactory.createDataLessItem(
      SitemapItemType.TextBox,
      this.state.activeProject.id,
      this.sitemapItems.map(i => i.name),
    )
    textItem.labelProperties.setPosition(position)
    this.selectSitemapItem(textItem)
    this.isTextStickerCreationActive = false
  }

  private clearActionsHistory = () => {
    this.actionsHistory = []
    this.historyIndex = -1
  }

  private applyStateFromHistory = () => {
    const state = this.actionsHistory[this.historyIndex]

    if (
      this.selectedSitemapItemDrawnPart &&
      !state.selectedItem.hasDrawnPart(this.selectedSitemapItemDrawnPart)
    ) {
      this.deselectSitemapItemDrawnPart()
    }

    this.selectedSitemapItem = state.selectedItem.copy()
  }

  private getDefaultSitemapItemForAttribute(dto: LocationBase) {
    return new SitemapItem(
      null,
      dto.name,
      dto.color,
      null,
      null,
      this.state.activeProject.id,
      dto.type,
      dto.id,
      null,
      null,
      null,
      null,
      null,
      this.deliverySitemapSetUpStore.sitemapSetupStore.selectedSitemap.isReferenced,
    )
  }

  private getNodeChildren(node: HierarchyNode): HierarchyNode[] {
    return this.sitemapItems
      .filter(item => item.isParent(node.item))
      .map(item => {
        const childNode = new HierarchyNode(this.hierarchyExpandState, item)
        childNode.children = this.getNodeChildren(childNode)
        return childNode
      })
  }

  private saveItemChildren = async (
    item: SitemapItemBase,
    newParent: SitemapItemBase,
  ) => {
    const children = this.sitemapItems
      .filter(i => i.isParent(item))
      .map(i => i.copy())
    if (!children.length) {
      return
    }
    return await Promise.all(
      children.map(async child => {
        const initialChild = child.copy()
        child.sitemapItem.setId(null)
        if (child.dataObject) {
          child.dataObject.id = null
        }

        child.setParent({
          parentId: newParent.id,
          parentType: newParent.dataObject.type,
        })
        await this.saveSitemapItem(child)

        this.saveItemChildren(initialChild, child)
      }),
    )
  }

  private get sitemapsSetupStore(): SitemapSetupStore {
    return this.deliverySitemapSetUpStore.sitemapSetupStore
  }

  private get state(): DesktopInitialState {
    return this.eventsStore.appState
  }

  public get sitemap(): Sitemap {
    return this.sitemapsSetupStore.selectedSitemap
  }

  private get allSitemaps(): Sitemap[] {
    // Sorting:
    // selected sitemap first
    // then sitemaps with same basemaps
    // then rest
    return this.sitemapsSetupStore.allSitemaps.sort((a, b) => {
      let aVal = 0
      let bVal = 0

      if (a === this.sitemap) {
        aVal = 2
      } else if (this.sitemap.basemapId === a.basemapId) {
        aVal = 1
      }

      if (b === this.sitemap) {
        bVal = 2
      } else if (this.sitemap.basemapId === b.basemapId) {
        bVal = 1
      }

      return bVal - aVal
    })
  }

  @action.bound
  private async hideItems(items: SitemapItemBase[], avoidSaving?: boolean) {
    items.forEach(item => {
      if (this.isSitemapItemSelected(item)) {
        this.deselectSitemapItem()
      }

      const updatedItem = item.copy().hide()
      this.updateSitemapSpecificItem(updatedItem)
    })

    await this.sitemapsSetupStore.saveSitemap(this.sitemap, true, avoidSaving)
    if (avoidSaving) {
      return
    }
    await this.deliverySitemapSetUpStore.requestSaveDeliverySitemapImage()
  }

  @action.bound
  private async displayItems(items: SitemapItemBase[], avoidSaving?: boolean) {
    for (const item of items) {
      let updatedItem = item
        .copy()
        .fillDataBeforeDisplaying(this.allSitemaps)
        .display()

      if (!updatedItem.sitemapItem.coordinates) {
        updatedItem =
          this.deliverySitemapSetUpStore.mapBoxEditorStore.getUpdatedItemCoords(
            updatedItem,
            true,
          )
      } else {
        updatedItem =
          this.deliverySitemapSetUpStore.mapBoxEditorStore.getUpdatedItemCoords(
            updatedItem,
            true,
          )
      }
      const { id } = await this.saveSitemapItemRelatedData(updatedItem)
      updatedItem.sitemapItem.id = id

      this.updateSitemapSpecificItem(updatedItem)
    }

    this.deliverySitemapSetUpStore.mapBoxEditorStore.setItems()
    await this.sitemapsSetupStore.saveSitemap(this.sitemap, true, avoidSaving)
    if (!avoidSaving) {
      await this.deliverySitemapSetUpStore.requestSaveDeliverySitemapImage()
    }

    const oldSelectedItem = items.find(i => this.isSitemapItemSelected(i))
    if (oldSelectedItem) {
      const updatedItem = this.sitemapItems.find(
        i => i.id === oldSelectedItem.id,
      )
      this.selectSitemapItem(updatedItem)
    }
  }

  private saveItem(sitemapItem: SitemapItem): Promise<string> {
    return new Promise<string>(resolve => {
      this.sitemapItemsStore.save(sitemapItem, id => resolve(id))
    })
  }

  private removeSitemapItems(sitemapItemIds: string[]): Promise<void> {
    return new Promise<void>(resolve => {
      if (!sitemapItemIds?.length) {
        return resolve()
      }

      this.sitemapItemsStore.removeMany(sitemapItemIds, resolve)
    })
  }

  private get allowedTypes(): LocationType[] {
    const { dataObject } = this.selectedSitemapItem
    if (dataObject && ALLOWED_PARENTS_BY_TYPE[dataObject.type]) {
      return ALLOWED_PARENTS_BY_TYPE[dataObject.type]
    }
    return DEFAULT_ALLOWED_PARENTS
  }

  public get isUpdatingSitemapLoaderShown(): boolean {
    return this.deliverySitemapSetUpStore.isUpdatingSitemapLoaderShown
  }

  private setShapeCoordinates = (
    shape: ShapeProperties,
    x: number,
    y: number,
  ) => {
    switch (shape.type) {
      case SitemapItemShapeType.Circle:
        const circle = shape.copy() as SitemapCircleProperties
        circle.position = {
          x: circle.position.x + x,
          y: circle.position.y + y,
        }
        return circle
      case SitemapItemShapeType.Rectangle:
        const rectangle = shape.copy() as SitemapRectangleProperties
        rectangle.position = {
          x: rectangle.position.x + x,
          y: rectangle.position.y + y,
        }
        return rectangle
      case SitemapItemShapeType.Polyline:
        const polyline = shape.copy() as SitemapPolyLineProperties
        polyline.points = polyline.points.map(point => {
          return {
            x: point.x + x,
            y: point.y + y,
          }
        })
        return polyline
    }
  }

  private setSaving(isSaving: boolean) {
    this.isSaving = isSaving
  }
}

enum SitemapAttributeIcon {
  Gate = 'gate',
  Zone = 'zone',
  Route = 'route',
  Equipment = 'equipment',
  Building = 'building',
  Line = 'line',
  TextBox = 'text-box',
  Level = 'level',
  Area = 'area',
  Logistics = 'logistics',
  Bathroom = 'bathroom',
  Break = 'break',
  Canteen = 'canteen',
  Dumpster = 'dumpster',
  Elevator = 'elevator',
  Shaft = 'shaft',
  Entrance = 'entrance',
  HandWash = 'hand-wash',
  Medical = 'medical',
  MeetingPoint = 'meeting-point',
  Parking = 'parking',
  Smoking = 'smoking',
  Stairs = 'stairs',
  Temperature = 'temperature',
  Tent = 'tent',
  Toilet = 'toilet',
  Walkway = 'walkway',
  ElectricalRoom = 'electrical-room',
  Crane = 'crane',
  Hoist = 'hoist',
  AerialLift = 'aerial-lift',
  Gradall = 'gradall',
  ForkLift = 'fork-lift',
  Monitoring = 'monitoring',
  InteriorPath = 'interior-path',
  InteriorDoor = 'interior-door',
  Staging = 'staging',
  Trailer = 'trailer',
}

export default SitemapAttributeIcon

import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_HEIGHT = 20
const ICON_WIDTH = 20

const ICON_OFFSET_X = 2
const ICON_OFFSET_Y = 1
const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const ICON_SCALE = 0.8

const ICON_PATH =
  'M7.5 10.8333C7.26389 10.8333 7.06597 10.7535 6.90625 10.5938C6.74653 10.434 6.66667 10.2361 6.66667 10C6.66667 9.76389 6.74653 9.56597 6.90625 9.40625C7.06597 9.24653 7.26389 9.16667 7.5 9.16667C7.73611 9.16667 7.93403 9.24653 8.09375 9.40625C8.25347 9.56597 8.33333 9.76389 8.33333 10C8.33333 10.2361 8.25347 10.434 8.09375 10.5938C7.93403 10.7535 7.73611 10.8333 7.5 10.8333ZM12.5 10.8333C12.2639 10.8333 12.066 10.7535 11.9063 10.5938C11.7465 10.434 11.6667 10.2361 11.6667 10C11.6667 9.76389 11.7465 9.56597 11.9063 9.40625C12.066 9.24653 12.2639 9.16667 12.5 9.16667C12.7361 9.16667 12.934 9.24653 13.0938 9.40625C13.2535 9.56597 13.3333 9.76389 13.3333 10C13.3333 10.2361 13.2535 10.434 13.0938 10.5938C12.934 10.7535 12.7361 10.8333 12.5 10.8333ZM2.5 17.5V15.8333H3.33333V4.16667C3.33333 3.70833 3.49653 3.31597 3.82292 2.98958C4.14931 2.66319 4.54167 2.5 5 2.5H15C15.4583 2.5 15.8507 2.66319 16.1771 2.98958C16.5035 3.31597 16.6667 3.70833 16.6667 4.16667V15.8333H17.5V17.5H2.5ZM5 15.8333H9.16667V4.16667H5V15.8333ZM10.8333 15.8333H15V4.16667H10.8333V15.8333Z'
const STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaInteriorDoorIcon = ({ color, ...rest }) => {
  const halfSize = ICON_HEIGHT / 2
  return (
    <Group {...rest} offsetX={ICON_WIDTH / 2} offsetY={ICON_HEIGHT / 2}>
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={ICON_PATH}
        fill={getHEXColor(Colors.neutral100)}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        scaleX={ICON_SCALE}
        scaleY={ICON_SCALE}
      />
    </Group>
  )
}

export default KonvaInteriorDoorIcon

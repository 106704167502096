import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IInspectionOptions, IWorkflowStep } from '~/client/graph'
import PermitWorkflowDiagram from '~/client/src/desktop/components/WorkflowDiagrams/PermitWorkflowDiagram/PermitWorkflowDiagram'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import TooltipWrapper from '~/client/src/shared/components/TooltipWrapper/TooltipWrapper'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import OperationRulesStore from '~/client/src/shared/stores/domain/OperationRules.store'
import PermitBallInCourtStore from '~/client/src/shared/stores/ui/PermitBallInCourt.store'
import { showErrorToast } from '~/client/src/shared/utils/toaster'
import { BULLET } from '~/client/src/shared/utils/usefulStrings'

import FormWorkflowSteps from '../FormWorkflowSteps'
import WorkflowStore from './Workflow.store'
import WorkflowStepConfigurator from './components/WorkflowStepConfigurator/WorkflowStepConfigurator'
import WorkflowStepDescriptionsDialog from './components/WorkflowStepDescriptionsDialog'

// localization: translated

interface IProps {
  activePermitType: PermitType
  isUpdating: boolean
  onWorkflowSettingsChange: (
    workflowSteps: IWorkflowStep[],
    inspectionOptions: IInspectionOptions,
    isAutoActivationEnabled: boolean,
  ) => void
  onStepClick(stepId: string): void

  className?: string

  permitBallInCourtStore: PermitBallInCourtStore

  operationRulesStore?: OperationRulesStore
}

@inject('operationRulesStore')
@observer
export default class Workflow extends React.Component<IProps> {
  private readonly store: WorkflowStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new WorkflowStore(
      props.operationRulesStore,
      props.permitBallInCourtStore,
      props.activePermitType,
      props.onWorkflowSettingsChange,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { activePermitType } = this.props

    if (
      activePermitType?.id !== prevProps.activePermitType?.id &&
      activePermitType?.type !== prevProps.activePermitType?.type
    ) {
      this.showToastErrorIfNeed()
    }

    if (activePermitType !== prevProps.activePermitType) {
      this.store.init(activePermitType)
    }
  }

  public componentWillUnmount() {
    this.showToastErrorIfNeed()
  }

  public render() {
    const { className, isUpdating, onStepClick, permitBallInCourtStore } =
      this.props
    const {
      getUsersFromExpressions,
      duplicateRule,
      tryToRemoveRule,
      addOrDeleteUserFromRule,
      changeRuleExpression,
      showNewRuleRow,
      workflowSteps,
      addNewWorkflowStep,
      replaceWorkflowStep,
      showDeleteStepModal,
      areWorkflowStepsChanged,
      areInspectionOptionsChanged,
      restoreWorkflowSettings,
      changeInspectionOptions,
      inspectionOptions,
      saveWorkflowChanges,
      isAllowedToSave,
      warningMessage,
      isStepDescrDialogShown,
      showStepDescrDialog,
      hideStepDescrDialog,
      permitTypeId,
      isAutoActivationEnabled,
      toggleAutoActivationState,
      isWorkflowChanged,
      isAutoActivationChanged,
    } = this.store

    return (
      <div
        className={classList({
          'col permit-type-workflow': true,
          [className]: !!className,
        })}
      >
        {this.deleteRuleDialog}
        {this.deleteStepDialog}
        {this.activationToggleDialog}

        <span className="row mb20 no-white-space-wrap text extra-large bold line-24 no-grow">
          {Localization.translator.workflowSteps}
        </span>

        <div className="row overflow-auto">
          <div className="row x-center">
            <FormWorkflowSteps workflowSteps={workflowSteps} />
          </div>
        </div>

        <span className="row mt20 no-white-space-wrap text extra-large bold line-24 no-grow">
          {Localization.translator.workflowDiagram}
        </span>

        <div className="row overflow-auto">
          <div className="py20 row x-center">
            <PermitWorkflowDiagram
              workflowSteps={workflowSteps}
              isAutoActivationEnabled={isAutoActivationEnabled}
              inspectionFrequency={inspectionOptions?.inspectionFrequency}
              inspectionFrequencyType={
                inspectionOptions?.inspectionFrequencyType
              }
            />
          </div>
        </div>

        <div
          className={classList({
            'col my10 py10 sticky bg-white z-index-10': true,
            'top0 bottom-0': isWorkflowChanged,
            'inactive-element': isUpdating,
          })}
        >
          <div className="row relative">
            <span className="text extra-large bold line-24 no-grow nowrap">
              {Localization.translator.workflowStepConfigurator}
            </span>

            <div
              className="text large bold blue-highlight line-extra-large no-grow nowrap pointer mx15"
              onClick={showStepDescrDialog}
            >
              {Localization.translator.workflowTableText.seeDescr}
            </div>
            <WorkflowStepDescriptionsDialog
              isOpened={isStepDescrDialogShown}
              hideDialog={hideStepDescrDialog}
            />
            <div />

            <TooltipWrapper
              content={Localization.translator.restoreToLastSavedState}
              className="no-grow mr10 no-select"
            >
              <Icon
                icon={IconNames.RESET}
                className={classList({
                  text: true,
                  'pointer blue-highlight': isWorkflowChanged,
                  grey: !isWorkflowChanged,
                })}
                onClick={restoreWorkflowSettings}
              />
            </TooltipWrapper>
            <BaseActionButton
              title={Localization.translator.save}
              className={classList({
                'mw160 brada24 no-select': true,
                'scale-blue-theme pointer': isWorkflowChanged,
                'gray-theme': !isWorkflowChanged,
              })}
              isEnabled={isWorkflowChanged && isAllowedToSave}
              isLoading={isUpdating}
              onClick={saveWorkflowChanges}
            />
          </div>
          {warningMessage && (
            <div className="row text large bold center red mt5">
              <span>
                {Localization.translator.error}: {warningMessage}
              </span>
            </div>
          )}
        </div>

        <div className="row">
          <div className="py20 row x-center">
            <WorkflowStepConfigurator
              formTypeId={permitTypeId}
              workflowSteps={workflowSteps}
              inspectionOptions={inspectionOptions}
              isUpdating={isUpdating}
              isAutoActivationEnabled={isAutoActivationEnabled}
              onAddStep={addNewWorkflowStep}
              onReplaceStep={replaceWorkflowStep}
              onRemoveStep={showDeleteStepModal}
              onInspectionChange={changeInspectionOptions}
              onFormSectionClick={onStepClick}
              onAutoActivationChange={toggleAutoActivationState}
              areStepsChanged={
                areWorkflowStepsChanged || isAutoActivationChanged
              }
              areInspectionOptionsChanged={areInspectionOptionsChanged}
              getUsersFromAssignment={getUsersFromExpressions}
              onRemoveRuleClick={tryToRemoveRule}
              onDuplicateRuleClick={duplicateRule}
              onChangeUserForRule={addOrDeleteUserFromRule}
              onRuleExpressionChange={changeRuleExpression}
              onAddNewRuleClick={showNewRuleRow}
              permitBallInCourtStore={permitBallInCourtStore}
            />
          </div>
        </div>
      </div>
    )
  }

  private get deleteRuleDialog(): JSX.Element {
    const { removingRuleId, resetRemovingRuleId, performRemoveRule } =
      this.store

    return (
      <ConfirmDialog
        portalClassName="z-index-120"
        isOpen={!!removingRuleId}
        onCancelClicked={resetRemovingRuleId}
        onDoneClicked={performRemoveRule}
        doneTitle={Localization.translator.delete}
      >
        <div className="text large pre-line">
          {Localization.translator.shouldDeleteRule}?
        </div>
      </ConfirmDialog>
    )
  }

  private get deleteStepDialog(): JSX.Element {
    const {
      isDeleteStepModalShown,
      hideDeleteStepModal,
      confirmRemoveWorkflowStep,
    } = this.store

    return (
      <ConfirmDialog
        portalClassName="z-index-120"
        isOpen={isDeleteStepModalShown}
        onCancelClicked={hideDeleteStepModal}
        onDoneClicked={confirmRemoveWorkflowStep}
        doneTitle={Localization.translator.delete}
      >
        <div className="text large pre-line">
          {Localization.translator.workflowConfDescr.removeStepDialogMsg}
        </div>
      </ConfirmDialog>
    )
  }

  private get activationToggleDialog(): JSX.Element {
    const {
      isAutoToggleDialogShown,
      isAutoActivationEnabled,
      hideAllDialogs,
      confirmAutoActivationChange,
    } = this.store

    return (
      <ConfirmDialog
        portalClassName="z-index-120"
        isOpen={isAutoToggleDialogShown}
        onCancelClicked={hideAllDialogs}
        onDoneClicked={confirmAutoActivationChange}
        doneTitle={Localization.translator.change_verb}
      >
        <div className="text large red pre-line">
          {Localization.translator.workflowConfDescr.recurringHasConstr}
          <br />
          <br />
          {BULLET} {Localization.translator.workflowTableText.recurringConstr}
          <br />
          <br />
        </div>
        <div className="text large pre-line">
          {Localization.translator.workflowConfDescr.toggleDialogMsg(
            isAutoActivationEnabled,
          )}
        </div>
      </ConfirmDialog>
    )
  }

  private showToastErrorIfNeed = () => {
    if (this.store.isWorkflowChanged) {
      showErrorToast(Localization.translator.workflowConfDescr.toastUnsavedMsg)
    }
  }
}

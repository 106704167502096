import { NodeModel, getDescendants } from '@minoru/react-dnd-treeview'
import { action, observable } from 'mobx'

import {
  IConditionalField,
  IInstructions,
  IPermitTypeChecklist,
  IPermitTypeField,
  IRestrictedOption,
  IWorkflowStep,
  PermitFieldType,
} from '~/client/graph'
import {
  nestablePermitFields,
  nonHiddeablePermitFieldTypes,
  nonRemovableTransferFieldTypes,
  singleInstancesPermitFields,
  workflowFieldTypes,
} from '~/client/src/shared/constants/PermitFieldTypeConstants'
import formFieldsCaptionMap from '~/client/src/shared/constants/formFieldsCaptionMap'
import {
  MAX_CONDITIONAL_DEPTH_LEVEL,
  MAX_FIELDS_DEPTH,
  MAX_FIELDS_NUMBER,
} from '~/client/src/shared/constants/permitTypeFieldsConstants'
import QuestionnaireType from '~/client/src/shared/enums/QuestionnaireType'
import { InstructionListType } from '~/client/src/shared/models/PermitType'
import {
  areArraysEqual,
  areObjectArraysEqual,
  copyArray,
  copyObjectDeep,
} from '~/client/src/shared/utils/util'

export const TREE_ROOT_ID = 'tree-root-id'

function sortTreeData(
  treeData: NodeModel<IPermitTypeField>[],
  sortedData: NodeModel<IPermitTypeField>[],
  parentId: string,
) {
  const children = treeData.filter(node => node.parent === parentId)

  children.forEach(node => {
    sortedData.push(node)
    sortTreeData(treeData, sortedData, node.id.toString())
  })
}

function getDepth(
  tree: NodeModel<IPermitTypeField>[],
  id: string,
  depth = 0,
): number {
  if (depth > MAX_FIELDS_DEPTH) {
    return depth
  }

  const target = tree.find(node => node.id === id)

  if (target) {
    return getDepth(tree, target.parent?.toString(), depth + 1)
  }

  return depth
}

function getChildrenDepth(
  tree: NodeModel<IPermitTypeField>[],
  ids: string[],
  depth = 0,
): number {
  if (depth > MAX_FIELDS_DEPTH) {
    return depth
  }

  const nestableItems = tree.filter(
    node => ids.includes(node.parent.toString()) && node.droppable,
  )

  if (nestableItems.length) {
    return getChildrenDepth(
      tree,
      nestableItems.map(({ id }) => id.toString()),
      depth + 1,
    )
  }

  return depth
}

export default class FormConfiguratorStore {
  @observable public isRemoveDialogShown = false

  @observable private workflowStep: IWorkflowStep
  @observable private isInitialFormStep: boolean
  @observable private isMaterialTransfer: boolean
  @observable private removeFieldId: string
  @observable private readonly collapsedFieldIds = new Set<string>()

  public constructor(
    workflowStep: IWorkflowStep,
    isInitialFormStep: boolean,
    isMaterialTransfer: boolean,
    private readonly onChange: (workflowStep: IWorkflowStep) => void,
  ) {
    this.setWorkflowStep(workflowStep)
    this.setIsInitialFormStep(isInitialFormStep)
    this.setIsMaterialTransfer(isMaterialTransfer)
  }

  @action.bound
  public setWorkflowStep(workflowStep: IWorkflowStep) {
    this.workflowStep = {
      id: workflowStep.id,
      type: workflowStep.type,
      fields: workflowStep.fields.map(f => copyObjectDeep(f)),
      conditionalFields: workflowStep.conditionalFields.map(cf =>
        copyObjectDeep(cf),
      ),
      workflowRuleIds: copyArray(workflowStep.workflowRuleIds || []),
    }
  }

  @action.bound
  public setIsInitialFormStep(isInitialFormStep: boolean) {
    this.isInitialFormStep = isInitialFormStep
  }

  @action.bound
  public setIsMaterialTransfer(isMaterialTransfer: boolean) {
    this.isMaterialTransfer = isMaterialTransfer
  }

  public get fields(): IPermitTypeField[] {
    return this.workflowStep.fields
  }

  public get conditionalFields(): IConditionalField[] {
    return this.workflowStep.conditionalFields
  }

  public get treeData(): NodeModel<IPermitTypeField>[] {
    return this.fields.map(field => ({
      id: field.id,
      parent: field.parentId || TREE_ROOT_ID,
      text: field.id,
      droppable: nestablePermitFields.includes(field.type),
      data: field,
    }))
  }

  public get openedFieldIds(): string[] {
    return this.fields
      .filter(
        ({ id, type }) =>
          nestablePermitFields.includes(type) &&
          !this.collapsedFieldIds.has(id),
      )
      .map(({ id }) => id)
  }

  public get isAddBtnHidden(): boolean {
    return this.fields.length >= MAX_FIELDS_NUMBER
  }

  public get singleUseFields(): PermitFieldType[] {
    return singleInstancesPermitFields.filter(pf =>
      this.fields.some(f => f.type === pf),
    )
  }

  public get restrictedFields(): PermitFieldType[] {
    return this.isInitialFormStep ? [] : workflowFieldTypes
  }

  public canDropField = (
    tree: NodeModel<IPermitTypeField>[],
    dragSource: NodeModel<IPermitTypeField>,
    dropTargetId: string,
    dropTarget: NodeModel<IPermitTypeField>,
  ): boolean => {
    if (dragSource?.parent === dropTargetId) {
      return true
    }

    if (dropTarget && dragSource?.droppable) {
      const depth = getDepth(tree, dropTarget.id.toString())

      if (depth > MAX_FIELDS_DEPTH) {
        return false
      }
      const childrenDepth = getChildrenDepth(tree, [dragSource.id.toString()])
      if (childrenDepth + depth > MAX_FIELDS_DEPTH) {
        return false
      }
    }
  }

  public canRemoveField = (field: IPermitTypeField): boolean => {
    if (!this.isMaterialTransfer) {
      return true
    }

    if (nonRemovableTransferFieldTypes.includes(field.type)) {
      return false
    }
    return getDescendants(this.treeData, field.id).every(
      f => !nonRemovableTransferFieldTypes.includes(f.data.type),
    )
  }

  public getRestrictedFieldsByDepth = (
    currentFieldDepth: number,
  ): PermitFieldType[] => {
    return this.restrictedFields.concat(
      currentFieldDepth >= MAX_FIELDS_DEPTH ? nestablePermitFields : [],
    )
  }

  @action.bound
  public showRemoveDialog(fieldId: string) {
    this.isRemoveDialogShown = true

    this.removeFieldId = fieldId
  }

  @action.bound
  public hideRemoveDialog() {
    this.isRemoveDialogShown = false

    this.removeFieldId = null
  }

  @action.bound
  public collapseField(fieldId: string, isCollapsed: boolean) {
    if (isCollapsed) {
      this.collapsedFieldIds.delete(fieldId)
    } else {
      this.collapsedFieldIds.add(fieldId)
    }
  }

  @action.bound
  public onChangeFields(newTreeData: NodeModel<IPermitTypeField>[]) {
    const sortedData: NodeModel<IPermitTypeField>[] = []
    sortTreeData(newTreeData, sortedData, TREE_ROOT_ID)

    if (areObjectArraysEqual(this.treeData, sortedData)) {
      return
    }

    this.workflowStep.fields = sortedData.map(({ parent, data }) => {
      data.parentId = parent === TREE_ROOT_ID ? null : parent.toString()
      return data
    })

    this.saveWorkflowStep()
  }

  @action.bound
  public addNewField(type: PermitFieldType, field?: IPermitTypeField) {
    const newField = this.getNewFieldModel(type, field)

    const index = this.fields.findIndex(({ id }) => field?.id === id)
    this.fields.splice(index + 1, 0, newField)

    if (this.collapsedFieldIds.has(field?.id)) {
      this.collapsedFieldIds.delete(field.id)
    }

    this.saveWorkflowStep()
  }

  @action.bound
  public updateField(field: IPermitTypeField) {
    const fieldIndex = this.fields.findIndex(({ id }) => field.id === id)

    if (fieldIndex !== -1) {
      this.fields.splice(fieldIndex, 1, field)

      this.saveWorkflowStep()
    }
  }

  @action.bound
  public removeField() {
    if (!this.removeFieldId) {
      return
    }

    const deleteIds = [
      this.removeFieldId,
      ...getDescendants(this.treeData, this.removeFieldId).map(
        node => node.id as string,
      ),
    ]
    const conditionalIds = this.getConditionalDescendants(deleteIds).map(
      cf => cf.fieldId,
    )

    this.workflowStep.fields = this.fields.filter(
      ({ id }) => !deleteIds.includes(id),
    )
    this.workflowStep.conditionalFields = this.conditionalFields.filter(
      ({ fieldId }) => !conditionalIds.includes(fieldId),
    )

    this.hideRemoveDialog()

    this.saveWorkflowStep()
  }

  @action.bound
  public changeFieldRestrictedOptions(
    field: IPermitTypeField,
    restrictedOptions: IRestrictedOption[],
  ) {
    field.restrictedOptions = restrictedOptions

    this.saveWorkflowStep()
  }

  @action.bound
  public addNewConditionalField(
    fieldId: string,
    key: string,
    index: number,
    fieldType: PermitFieldType,
    customFieldName?: string,
  ) {
    if (!fieldId || !key?.trim()) {
      return
    }

    const newField = this.getNewFieldModel(fieldType, null, customFieldName)

    const conditionalFieldObj = this.conditionalFields
      .filter(cf => cf.fieldId === fieldId)
      .find(cf => cf.key === key)

    if (conditionalFieldObj) {
      conditionalFieldObj.values.splice(index + 1, 0, newField)
    } else {
      this.conditionalFields.push({ fieldId, key, values: [newField] })
    }

    this.saveWorkflowStep()
  }

  @action.bound
  public updateConditionalField(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
  ) {
    fields.splice(index, 1, field)

    this.saveWorkflowStep()
  }

  @action.bound
  public updateConditionalChecklistField(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
    checklist: IPermitTypeChecklist,
  ) {
    if (this.isChecklistChanged(field.checklist, checklist)) {
      field.checklist = checklist

      fields.splice(index, 1, field)

      this.saveWorkflowStep()
    }
  }

  @action.bound
  public updateConditionalRestrictionOptions(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
    restrictedLocations: IRestrictedOption[],
  ) {
    field.restrictedOptions = restrictedLocations

    fields.splice(index, 1, field)

    this.saveWorkflowStep()
  }

  @action.bound
  public removeConditionalField(
    fieldId: string,
    key: string,
    conditionalFieldId: string,
  ) {
    const conditionalFieldObj = this.conditionalFields
      .filter(cf => cf.fieldId === fieldId)
      .find(cf => cf.key === key)

    if (!conditionalFieldObj) {
      return
    }

    conditionalFieldObj.values = conditionalFieldObj.values.filter(
      f => f.id !== conditionalFieldId,
    )

    const idsToDelete = this.getConditionalDescendants([
      conditionalFieldId,
    ]).map(({ fieldId }) => fieldId)

    this.workflowStep.conditionalFields = this.conditionalFields.filter(
      ({ fieldId }) => !idsToDelete.includes(fieldId),
    )

    this.saveWorkflowStep()
  }

  @action.bound
  public changePermitTypeInstruction(
    field: IPermitTypeField,
    instructions: IInstructions,
  ) {
    if (this.areInstructionsChanged(field.instructions, instructions)) {
      field.instructions = instructions

      this.saveWorkflowStep()
    }
  }

  @action.bound
  public changePermitTypeChecklist(
    field: IPermitTypeField,
    checklist: IPermitTypeChecklist,
  ) {
    if (this.isChecklistChanged(field.checklist, checklist)) {
      field.checklist = checklist

      this.saveWorkflowStep()
    }
  }

  private getNewFieldModel(
    type: PermitFieldType,
    field?: IPermitTypeField,
    fieldName?: string,
  ): IPermitTypeField {
    const isInstructionField = type === PermitFieldType.Instructions
    const isChecklistField =
      type === PermitFieldType.Checklist || type === PermitFieldType.Question

    const caption: string =
      isInstructionField || isChecklistField
        ? ''
        : fieldName || formFieldsCaptionMap[type]

    const isEditable =
      !nonHiddeablePermitFieldTypes.includes(type) &&
      (!this.isMaterialTransfer ||
        !nonRemovableTransferFieldTypes.includes(type))

    const newField: IPermitTypeField = {
      id: null,
      parentId: nestablePermitFields.includes(field?.type)
        ? field?.id
        : field?.parentId,
      type,
      caption,
      isMandatory: false,
      isShown: true,
      canEditIsMandatory: isEditable,
      canEditIsShown: isEditable,
    }

    if (isInstructionField) {
      newField.instructions = {
        list: [formFieldsCaptionMap[type]],
        listType: InstructionListType.Bulleted,
      }
    }
    if (isChecklistField) {
      newField.checklist = {
        list: [
          {
            id: null,
            text: formFieldsCaptionMap[type],
            questionnaireType: QuestionnaireType.YesNoNA,
          },
        ],
        listType: InstructionListType.Enumerated,
      }
    }

    return newField
  }

  private areInstructionsChanged(
    existing: IInstructions,
    newInstructions: IInstructions,
  ): boolean {
    return (
      !areArraysEqual(existing?.list || [], newInstructions?.list || []) ||
      existing?.listType !== newInstructions?.listType
    )
  }

  private isChecklistChanged(
    existing: IPermitTypeChecklist,
    newChecklist: IPermitTypeChecklist,
  ): boolean {
    return (
      !areObjectArraysEqual(existing?.list || [], newChecklist?.list || []) ||
      existing.listType !== newChecklist.listType
    )
  }

  private getConditionalDescendants(
    fieldIds: string[],
    depthLevel: number = 0,
  ): IConditionalField[] {
    if (depthLevel === MAX_CONDITIONAL_DEPTH_LEVEL) {
      return []
    }

    const descendants: IConditionalField[] = []
    const filteredConditionals = this.conditionalFields.filter(cf =>
      fieldIds.includes(cf.fieldId),
    )
    const childrenIds = filteredConditionals.flatMap(({ values }) =>
      values.map(({ id }) => id),
    )

    descendants.push(
      ...filteredConditionals,
      ...this.getConditionalDescendants(childrenIds, depthLevel + 1),
    )

    return descendants
  }

  private saveWorkflowStep() {
    this.onChange(this.workflowStep)
  }
}

import { LocationType } from '~/client/graph'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import FieldIds from '../../enums/DeliveryFieldIds'
import LocationBase from './LocationBase'

export default class LogisticsObject extends LocationBase<LogisticsObject> {
  public static fromDto(dto: ILocationDto) {
    return LocationBase.factory(
      LogisticsObject,
      dto,
      SitemapAttributeIcon.Logistics,
    )
  }

  public type = LocationType.LogisticsObject
  protected relatedFieldId = FieldIds.LOGISTICS_OBJECT
  protected model = LogisticsObject
}

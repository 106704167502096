import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import * as Icons from '~/client/src/shared/components/Icons'
import TooltipWrapper from '~/client/src/shared/components/TooltipWrapper/TooltipWrapper'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { ISitemapGeoPosition } from '~/client/src/shared/models/Sitemap'

import DeliverySitemapSetUpStore, {
  SetUpSteps,
} from '../GeneralSitemapSetUp.store'
import { ItemsCollapseState } from '../stores/SitemapItemsSetup.store'
import HierarchyTree from './HierarchyTree/HierarchyTree'
import { C_KEY_CODE, V_KEY_CODE } from './SitemapEditor/SitemapEditor'

import './LeftPanel.scss'

interface IProps {
  store: DeliverySitemapSetUpStore
  projectSetUpPageStore: ProjectSetUpPageStore
  viewport: ISitemapGeoPosition
  setViewport: (viewport: ISitemapGeoPosition) => void
  step: SetUpSteps
  state?: DesktopInitialState
}

const objectsVisible = 'objects visible'

@inject('state')
@observer
export default class LeftPanel extends React.Component<IProps> {
  @observable private isCommonVisibilityToggleHovering: boolean = false

  public componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  public render() {
    if (this.props.step === SetUpSteps.alignPlan) {
      return this.renderRubberStep()
    }
    const { sitemapItemsSetupStore } = this.props.store
    const { toggleItemsCollapsingState, sitemapItems } = sitemapItemsSetupStore

    return (
      <div className="sitemaps-left-bar br-light-grey full-height col no-outline-container">
        <div className="sitemaps-left-bar-header no-grow row y-center bb-light-input-border">
          <div
            className="row text light large ml10 pointer"
            onClick={toggleItemsCollapsingState}
          >
            {this.navigationIcon}
            {`${sitemapItems.length} ${objectsVisible}`}
          </div>
          {this.renderCommonVisibilityToggle()}
        </div>
        {this.renderSitemapPanel()}
      </div>
    )
  }

  private renderRubberStep() {
    const {
      sitemapItemsSetupStore,
      sitemapViewsSetupStore,
      mapBoxEditorStore,
    } = this.props.store
    const { hierarchyTree } = sitemapItemsSetupStore

    return (
      <div className="pa10 sitemaps-left-bar br-light-grey full-height col no-outline-container">
        <div
          className={classList({
            'relative overflow-auto brada4': true,
            'ba2-group-highlight': this.isCommonVisibilityToggleHovering,
            'ba2-transparent': !this.isCommonVisibilityToggleHovering,
          })}
        >
          <div className="pt10">
            <HierarchyTree
              nodes={hierarchyTree}
              store={sitemapItemsSetupStore}
              sitemapViewsSetupStore={sitemapViewsSetupStore}
              mapBoxEditorStore={mapBoxEditorStore}
            />
          </div>
        </div>
      </div>
    )
  }

  private renderSitemapPanel() {
    const {
      sitemapItemsSetupStore,
      sitemapViewsSetupStore,
      mapBoxEditorStore: { getUpdatedItemCoords },
    } = this.props.store
    const { hierarchyTree } = sitemapItemsSetupStore

    return (
      <div
        className={classList({
          'relative overflow-auto brada4': true,
          'ba2-group-highlight': this.isCommonVisibilityToggleHovering,
          'ba2-transparent': !this.isCommonVisibilityToggleHovering,
        })}
      >
        <div className="pt10">
          <HierarchyTree
            nodes={hierarchyTree}
            store={sitemapItemsSetupStore}
            sitemapViewsSetupStore={sitemapViewsSetupStore}
            getUpdatedItem={getUpdatedItemCoords}
          />
        </div>
      </div>
    )
  }

  private get navigationIcon() {
    const { itemsCollapseState } = this.props.store.sitemapItemsSetupStore
    const className = 'no-grow navigation-arrows'

    switch (itemsCollapseState) {
      case ItemsCollapseState.collapsed:
        return <Icons.NavigationArrowsUp className={className} />
      case ItemsCollapseState.notCollapsed:
        return <Icons.NavigationArrowsDown className={className} />
      default:
        return <Icons.NavigationArrows className={className} />
    }
  }

  private renderCommonVisibilityToggle(): JSX.Element {
    const {
      areThereDisplayedItems,
      isUpdatingSitemapLoaderShown,
      toggleAllItemsVisibility,
    } = this.props.store.sitemapItemsSetupStore

    const { hideAll, showAll } = Localization.translator

    return (
      <div className="row x-end">
        <TooltipWrapper
          disabled={isUpdatingSitemapLoaderShown}
          className="no-grow mr12"
          content={areThereDisplayedItems ? hideAll : showAll}
        >
          <span
            className={classList({
              'all-toggle pa1 row no-grow  brada2': true,
              pointer: !isUpdatingSitemapLoaderShown,
              'wait-cursor': isUpdatingSitemapLoaderShown,
            })}
            onMouseEnter={this.handleMouseEnterOnCommonVisibilityToggle}
            onMouseLeave={this.handleMouseLeaveOnCommonVisibilityToggle}
            onClick={toggleAllItemsVisibility}
          >
            {areThereDisplayedItems ? (
              <Icons.EyeHideAll />
            ) : (
              <Icons.EyeViewAll />
            )}
          </span>
        </TooltipWrapper>
      </div>
    )
  }

  private handleMouseEnterOnCommonVisibilityToggle = () => {
    this.isCommonVisibilityToggleHovering = true
  }

  private handleMouseLeaveOnCommonVisibilityToggle = () => {
    this.isCommonVisibilityToggleHovering = false
  }

  private onKeyDown = (event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.code === C_KEY_CODE) {
      this.props.store.sitemapItemsSetupStore.copyLevel()
    }
    if ((event.metaKey || event.ctrlKey) && event.code === V_KEY_CODE) {
      this.props.store.sitemapItemsSetupStore.pasteLevel()
    }
  }
}

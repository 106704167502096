import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Circle, Group, Rect } from 'react-konva'

import { IPosition } from '~/client/graph'
import SitemapRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapRectangleProperties'
import { MAX_RELATIVE_POSITION } from '~/client/src/shared/utils/SitemapCalculationHelpers'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import SitemapItemsSetupStore from '../../stores/SitemapItemsSetup.store'

import Colors from '~/client/src/shared/theme.module.scss'

const MIN_POINT_RADIUS = 5
const POINT_STROKE_WIDTH = 2

interface IProps {
  store: SitemapItemsSetupStore
  rectangle: SitemapRectangleProperties
  containerHeight: number
  containerWidth: number

  onModifyStart: () => void
  onModifyEnd: () => void
}

@observer
export default class RectangleEditor extends React.Component<IProps> {
  private initialPosition: IPosition = null
  @observable private dragCursorPosition: IPosition = null

  public render() {
    const {
      containerHeight,
      containerWidth,
      rectangle,
      onModifyStart,
      onModifyEnd,
    } = this.props

    const pointRadius = Math.max(MIN_POINT_RADIUS, rectangle.lineWidth)

    const circlePosition = this.dragCursorPosition || {
      x: rectangle.width,
      y: rectangle.height,
    }

    return (
      <>
        <Rect width={containerWidth} height={containerHeight} x={0} y={0} />
        <Group
          x={(rectangle.position.x * containerWidth) / MAX_RELATIVE_POSITION}
          y={(rectangle.position.y * containerHeight) / MAX_RELATIVE_POSITION}
          rotation={rectangle.rotation}
        >
          <Circle
            x={(circlePosition.x * containerWidth) / MAX_RELATIVE_POSITION}
            y={(circlePosition.y * containerHeight) / MAX_RELATIVE_POSITION}
            radius={pointRadius}
            fill={ThemeMode.getHEXColor(Colors.neutral100)}
            stroke={ThemeMode.getHEXColor(Colors.neutral0)}
            strokeWidth={POINT_STROKE_WIDTH}
            draggable={true}
            onDragStart={this.onPointDragStart}
            onDragMove={this.onPointDragMove}
            onDragEnd={this.onPointDragEnd}
            onMouseDown={onModifyStart}
            onMouseUp={onModifyEnd}
          />
        </Group>
      </>
    )
  }

  private onPointDragStart = (event: KonvaEventObject<DragEvent>) => {
    this.props.onModifyStart()
    this.initialPosition = this.props.rectangle.position
    event.cancelBubble = true
  }

  private onPointDragMove = (event: KonvaEventObject<DragEvent>) => {
    const { containerWidth, containerHeight, rectangle } = this.props
    const { currentTarget } = event
    const newPoint = {
      x: (currentTarget.x() / containerWidth) * MAX_RELATIVE_POSITION,
      y: (currentTarget.y() / containerHeight) * MAX_RELATIVE_POSITION,
    }

    const minX = Math.min(
      this.initialPosition.x,
      rectangle.position.x + newPoint.x,
    )
    const maxX = Math.max(
      this.initialPosition.x,
      rectangle.position.x + newPoint.x,
    )
    const minY = Math.min(
      this.initialPosition.y,
      rectangle.position.y + newPoint.y,
    )
    const maxY = Math.max(
      this.initialPosition.y,
      rectangle.position.y + newPoint.y,
    )

    rectangle.setPosition({ x: minX, y: minY })
    rectangle.setWidth(maxX - minX)
    rectangle.setHeight(maxY - minY)
    this.dragCursorPosition = newPoint
  }

  private onPointDragEnd = (event: KonvaEventObject<DragEvent>) => {
    this.props.onModifyEnd()
    this.initialPosition = null
    this.dragCursorPosition = null
    event.cancelBubble = true
    this.props.store.addCurrentStateToHistory()
  }
}

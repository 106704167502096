import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { AdditionalReportFile } from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import BaseReportButton, {
  IReportInfo,
} from '~/client/src/desktop/components/HeaderBar/components/BaseReportButton/BaseReportButton'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopDeliveryViewStore from '~/client/src/desktop/views/Deliveries/Deliveries.store'
import DeliveriesListStore from '~/client/src/desktop/views/Deliveries/components/DeliveriesList/DeliveriesList.store'
import { IAppConfig } from '~/client/src/shared/Config'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import SuperFilterStore from '~/client/src/shared/stores/ui/SuperFilter.store'
import { DELIVERIES_REPORT_TEMPLATE_ID } from '~/client/src/shared/utils/usefulStrings'
import { areArraysEqual } from '~/client/src/shared/utils/util'

// localization: translated

interface IProps {
  title: string
  isEnabled?: boolean
  desktopDeliveryViewStore: DesktopDeliveryViewStore
  deliveriesListStore: DeliveriesListStore
  additionalReportFiles?: AdditionalReportFile[]
  filterStoresByTypeMap: {
    [filterType: string]: SuperFilterStore
  }

  className?: string
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  configuration?: IAppConfig
  toggleModal?: () => void
  shouldPreventCreation?: boolean
  shouldHideIcon?: boolean
  shouldInvertColors?: boolean
  companiesStore?: CompaniesStore
}

const DEFAULT_SORT_KEY = 'booking-id'
const DELIVERY_REPORT_TITLE = 'Delivery list report'
const REPORT_LBS_GROUPING_KEYS = [
  DeliveryGroupingOption.Building,
  DeliveryGroupingOption.Zone,
  DeliveryGroupingOption.Level,
  DeliveryGroupingOption.Area,
]

enum AcceptableDeliveryFilterTypes {
  status = 'status',
  company = 'company',
}

@inject('state', 'projectDateStore', 'configuration', 'companiesStore')
@observer
export default class DeliveryReportButton extends React.Component<IProps> {
  public render() {
    const {
      className,
      title,
      toggleModal,
      shouldPreventCreation,
      shouldHideIcon,
      shouldInvertColors,
      isEnabled,
    } = this.props

    return (
      <BaseReportButton
        className={className}
        getReportInfo={this.getReportInfo}
        title={title}
        onClick={toggleModal}
        shouldPreventCreation={shouldPreventCreation}
        shouldHideIcon={shouldHideIcon}
        shouldInvertColors={shouldInvertColors}
        isEnabled={isEnabled}
      />
    )
  }

  private getReportInfo = (): IReportInfo => {
    const {
      configuration: { TENANT_ID: tenantName },
      desktopDeliveryViewStore: { startDate, endDate },
      state: {
        activeProject: { name, id: projectId, logoUrl, materialsUploadId },
        filters,
        deliveryFilters: { fieldsMap },
      },
      deliveriesListStore: {
        sortState: { order, columnKey },
        filteredCollection,
      },
      projectDateStore: { getMonthAndDayToDisplay, getClientTimezoneId },
      additionalReportFiles = [],
    } = this.props

    const { bands } = filters.selectedDeliveryBandsOption

    const startProjectDate = getMonthAndDayToDisplay(startDate)
    const endProjectDate = getMonthAndDayToDisplay(endDate)

    const filename = `${tenantName}_${name}_${startProjectDate}-${endProjectDate}`

    const deliveriesGrouping = areArraysEqual(bands, [FieldIds.LOCATION])
      ? REPORT_LBS_GROUPING_KEYS
      : bands

    const deliveryFilters = this.getDeliveryFilters(fieldsMap)
    const data = {
      projectId,
      materialsUploadId,
      logoUrl,
      projectTimeZone: getClientTimezoneId(),
      deliveriesFrom: startDate.getTime(),
      deliveriesTo: endDate.getTime(),
      filteredIds: {
        deliveries: filteredCollection.map(delivery => delivery.id),
      },
      filters: deliveryFilters,
      grouping: {
        deliveries: deliveriesGrouping,
      },
      sorting: {
        deliveries: [
          {
            ascending: order !== SortOrder.DESC,
            key:
              !columnKey || columnKey === DEFAULT_SORT_KEY
                ? DEFAULT_SORT_KEY
                : DeliveryGroupingOption[columnKey],
          },
        ],
      },
    }

    return {
      projectId,
      dateFrom: startDate.getTime(),
      dateTo: endDate.getTime(),
      timezoneId: getClientTimezoneId(),
      templateId: DELIVERIES_REPORT_TEMPLATE_ID,
      data,
      files: [
        ...additionalReportFiles
          .filter(file => file.isChecked)
          .map(file => ({
            fileId: file.value,
            fileName: `${filename}_${file.name}.${file.format.toLowerCase()}`,
          })),
      ],
      title: DELIVERY_REPORT_TITLE,
    }
  }

  private getDeliveryFilters(fieldsMap: {
    [filterType: string]: UIFilterInfo
  }): { [filterType: string]: string[] } {
    const {
      filterStoresByTypeMap,
      companiesStore: { getCompanyById },
    } = this.props
    const result: { [filterType: string]: string[] } = {}

    Object.entries(fieldsMap).forEach(([filterType, filter]) => {
      if (filterStoresByTypeMap[filterType]?.areAllOptionsSelected) {
        return
      }
      switch (filterType) {
        case AcceptableDeliveryFilterTypes.company:
          result[filterType] = Array.from(
            filter.selectedFilterOptions.keys(),
          ).map(id => getCompanyById(id)?.name)
          break
        case AcceptableDeliveryFilterTypes.status:
          result[filterType] = Array.from(filter.selectedFilterOptions.keys())
          break
      }
    })

    return result
  }
}

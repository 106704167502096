import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'

import { IInspectionOptions, IWorkflowStep } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { ITabObject } from '~/client/src/shared/components/Tabs/Tabs'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import PermitTypesStore, {
  CUSTOM_PERMIT_TYPE_INDICATOR,
} from '~/client/src/shared/stores/domain/PermitTypes.store'
import { enumToList } from '~/client/src/shared/utils/converters'
import { NOOP } from '~/client/src/shared/utils/noop'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

import PreviewPermitFormStore from './PreviewPermitForm.store'

enum FormTabSection {
  TYPES = 'Types',
  WORKFLOW = 'Workflow',
  CONFIGURATOR = 'Configurator',
}

export const formSectionsAsTabs: ITabObject<string>[] = enumToList(
  FormTabSection,
).map(formTab => ({
  title: formTab,
  page: formTab,
}))

export default class PermitsSetUpStore {
  @observable private _activePermitTypeId: string = null
  @observable public isNewFormTypeDialogShown: boolean = false
  @observable public isRemoveFormDialogShown: boolean = false
  @observable public activeFormsTab: string = FormTabSection.TYPES
  @observable public selectedWorkflowStepId: string

  private formIdToRemove: string

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly previewPermitStore: PreviewPermitFormStore,
  ) {}

  public get isReceived(): boolean {
    return this.permitTypesStore.isDataReceived
  }

  public get isUpdating(): boolean {
    const { loading } = this.appState
    return (
      loading.get(e.SAVE_PERMIT_TYPES) ||
      loading.get(e.REMOVE_PERMIT_TYPES) ||
      loading.get(e.SAVE_MANY_OPERATION_RULES) ||
      loading.get(e.DELETE_MANY_OPERATION_RULES)
    )
  }

  public get permitTypes(): PermitType[] {
    return this.permitTypesStore.actualTypes
  }

  private get appState(): DesktopInitialState {
    return this.eventsStore.appState
  }

  @computed
  public get activePermitTypeId(): string {
    return this._activePermitTypeId || this.permitTypes[0]?.id
  }

  @computed
  public get selectedWorkflowStep(): IWorkflowStep {
    return this.activePermitType.workflowSteps.find(
      s => s.id === this.selectedWorkflowStepId,
    )
  }

  @computed
  public get isInitialStepSelected(): boolean {
    return this.selectedWorkflowStep.id === this.activePermitType.initialStep.id
  }

  public get isTypesTabSelected(): boolean {
    return this.activeFormsTab === FormTabSection.TYPES
  }

  public get isWorkflowTabSelected(): boolean {
    return this.activeFormsTab === FormTabSection.WORKFLOW
  }

  public get isConfiguratorTabSelected(): boolean {
    return this.activeFormsTab === FormTabSection.CONFIGURATOR
  }

  public get isFormSelectorShown(): boolean {
    return this.isWorkflowTabSelected || this.isConfiguratorTabSelected
  }

  @computed
  public get permitTypesAsTabs(): ITabObject<string>[] {
    return this.permitTypes.map(({ id, name }) => ({
      title: name,
      page: id,
    }))
  }

  @computed
  public get activePermitType(): PermitType {
    return this.permitTypesStore.getPermitTypeById(this.activePermitTypeId)
  }

  @action.bound
  public changeWorkflowStepId(stepId: string) {
    this.selectedWorkflowStepId = stepId
  }

  @action.bound
  public goToSelectedStep(stepId: string) {
    this.changeActiveFormTab(FormTabSection.CONFIGURATOR)
    this.changeWorkflowStepId(stepId)
  }

  public resetWorkflowStep = () => {
    this.changeWorkflowStepId(this.activePermitType.initialStep.id)
  }

  public resetWorkflowStepIfNeed = () => {
    if (!this.selectedWorkflowStep?.id) {
      this.resetWorkflowStep()
    }
  }

  @action.bound
  public changeActiveFormTab(newTab: FormTabSection) {
    this.activeFormsTab = newTab
  }

  @action.bound
  public showNewFormTypeDialog() {
    this.isNewFormTypeDialogShown = true
  }

  @action.bound
  public hideNewFormTypeDialog() {
    this.isNewFormTypeDialogShown = false
  }

  @action.bound
  public showRemoveFormDialog(formId: string) {
    this.isRemoveFormDialogShown = true
    this.formIdToRemove = formId
  }

  @action.bound
  public hideRemoveFormDialog() {
    this.isRemoveFormDialogShown = false
    this.formIdToRemove = null
  }

  @action.bound
  public createCustomPermitType(name: string, basedOnType: PermitType) {
    if (!name?.trim() || !basedOnType) {
      return
    }

    this.hideNewFormTypeDialog()

    this.permitTypesStore.createCustomType(name, basedOnType, ([{ id }]) =>
      this.setActivePermitTypeId(id),
    )
  }

  public isCustomType = (type: string): boolean => {
    return type.includes(CUSTOM_PERMIT_TYPE_INDICATOR)
  }

  @action.bound
  public removeCustomPermitType() {
    if (!this.formIdToRemove) {
      return
    }

    if (this.activePermitTypeId === this.formIdToRemove) {
      const type = this.permitTypes.find(
        ({ id: typeId }) => typeId !== this.formIdToRemove,
      )
      this.setActivePermitTypeId(type?.id || null)
    }

    this.permitTypesStore.deleteType(this.formIdToRemove)
    this.hideRemoveFormDialog()
  }

  @action.bound
  public changeWorkflowSettings(
    workflowSteps: IWorkflowStep[],
    inspectionOptions: IInspectionOptions,
    isAutoActivationEnabled: boolean,
  ) {
    this.changePermitType(
      this.activePermitType,
      {
        inspectionOptions,
        workflowSteps,
        isAutoActivationEnabled,
      },
      this.showSuccessMsg,
    )
  }

  @action.bound
  public togglePermitType(pTypeId: string) {
    const updatingPermitType = this.permitTypesStore.getPermitTypeById(pTypeId)
    this.changePermitType(updatingPermitType, {
      isEnabled: !updatingPermitType.isEnabled,
    })
  }

  @action.bound
  public changeTypeName(pTypeId: string, newName: string) {
    const updatingPermitType = this.permitTypesStore.getPermitTypeById(pTypeId)

    if (
      !newName?.trim() ||
      !updatingPermitType ||
      updatingPermitType.name === newName
    ) {
      return
    }

    this.changePermitType(updatingPermitType, {
      name: newName,
    })
  }

  @action.bound
  public changePermitTypeWorkflowStep(workflowStep: IWorkflowStep) {
    const updatedSteps = this.activePermitType.workflowSteps.slice()
    const index = updatedSteps.findIndex(s => s.id === workflowStep.id)

    if (index === -1) {
      return
    }

    updatedSteps[index] = workflowStep

    this.changePermitType(
      this.activePermitType,
      { workflowSteps: updatedSteps },
      this.changePermitTypeSuccessCb,
    )
  }

  @action.bound
  public changeNonWorkTimeBlocking(shouldBlockOnNonWorkTimes: boolean) {
    this.changePermitType(
      this.activePermitType,
      { shouldBlockOnNonWorkTimes },
      this.changePermitTypeSuccessCb,
    )
  }

  @action.bound
  public setActivePermitTypeId(
    activePermitTypeId: string = this.permitTypes[0]?.id,
    shouldOmitResetting?: boolean,
  ) {
    this._activePermitTypeId = activePermitTypeId

    this.previewPermitStore.setDefaultSitePermit(null, activePermitTypeId, true)

    if (!shouldOmitResetting) {
      this.resetWorkflowStep()
    }
  }

  @action.bound
  private changePermitTypeSuccessCb([{ id }]: PermitType[]) {
    this.setActivePermitTypeId(id, true)
  }

  @action.bound
  private changePermitType(
    updatingPermitType: PermitType,
    updatedFields: any,
    postAction: (permitTypes: PermitType[]) => void = NOOP,
  ) {
    const permitType: PermitType = Object.assign(
      updatingPermitType.getCopy(),
      updatedFields,
    )

    this.permitTypesStore.saveMany([permitType], postAction)
  }

  @action.bound
  public showSuccessMsg([{ id }]: PermitType[]) {
    this.setActivePermitTypeId(id)
    showToast(
      Localization.translator.successfullyUpdated,
      ToastTheme.SUCCESS,
      IconNames.TICK_CIRCLE,
    )
  }
}

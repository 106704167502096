import * as React from 'react'

import { Stage } from 'konva/types/Stage'
import { observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { SitemapItemShapeType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseSitemap from '~/client/src/shared/components/BaseSitemap/BaseSitemap'
import SitemapItems from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import SitemapItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Sitemap, {
  ISitemapGeoPosition,
} from '~/client/src/shared/models/Sitemap'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { ProjectSetUpSteps } from '~/client/src/shared/stores/InitialState'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import Awaiter from '~/client/src/shared/utils/Awaiter'

import DeliverySitemapSetUpStore from '../../GeneralSitemapSetUp.store'
import CreateNewItemArea from './CreateNewItemArea'
import RichTextEditor from './RichTextEditor'
import SitemapEditableItem from './SitemapEditableItem'

export interface IProps {
  store: DeliverySitemapSetUpStore
  sitemap: Sitemap
  viewport: ISitemapGeoPosition
  setViewport: (viewport: ISitemapGeoPosition) => void
  opacity: number

  eventsStore?: DesktopEventStore
  basemapsStore?: BasemapsStore
}

const ESCAPE_KEY_CODE = 27
const DELETE_KEY_CODE = 46
const BACKSPACE_KEY_CODE = 8
const ENTER_KEY_CODE = 13
const Z_KEY_CODE = 'KeyZ'
export const C_KEY_CODE = 'KeyC'
export const V_KEY_CODE = 'KeyV'

const INPUTS_NODE_NAMES = ['INPUT', 'TEXTAREA']
const nodeNameProp = 'nodeName'
const contentEditableProp = 'contentEditable'

const MAC = 'MAC'

@inject('eventsStore', 'basemapsStore')
@observer
export default class SitemapEditor extends React.Component<IProps> {
  @observable private deliverySitemap: BaseSitemap
  private clearPostEventCallback: () => void
  private clearPostEventCallbackItems: () => void
  private textboxesAwaiter: Awaiter = new Awaiter()
  private textboxesCache: ICanvasImageCache = {}
  public static contextType = MobXProviderContext

  public constructor(props: IProps) {
    super(props)
    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onSaveSitemapImageRequest,
    )
    this.props.store.mapBoxEditorStore.setViewportFromAdress()
    this.props.store.mapBoxEditorStore.isLoaded = false
  }

  public UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this.onKeyDown)
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const {
      store: { mapBoxEditorStore },
      sitemap,
    } = this.props
    if (sitemap?.id !== prevProps.sitemap?.id) {
      const { removeRefs, setDefaultMapMode, resetMapboxInfo } =
        mapBoxEditorStore

      removeRefs()
      setDefaultMapMode()
      resetMapboxInfo()
      mapBoxEditorStore.isLoaded = false
    }
  }

  public componentWillUnmount() {
    const { removeRefs, setDefaultMapMode, resetMapboxInfo } =
      this.props.store.mapBoxEditorStore

    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
    if (this.clearPostEventCallbackItems) {
      this.clearPostEventCallbackItems()
    }
    document.removeEventListener('keydown', this.onKeyDown)
    this.textboxesCache = {}
    removeRefs()
    setDefaultMapMode()
    resetMapboxInfo()
  }

  public render() {
    const {
      store: {
        sitemapSetupStore: { sitemapUrl, selectedSitemap },
        sitemapItemsSetupStore: {
          creatableAttributeType,
          isTextStickerCreationActive,
          selectSitemapItem,
          selectedSitemapItem,
          isSitemapBlocked,
        },
        mapBoxEditorStore,
      },
      opacity,
      basemapsStore,
      eventsStore: { appState },
    } = this.props
    const {
      isDraggingMode,
      isImageRubberMode,
      shouldShowImage,
      sourceBounds,
      setViewport,
      isEditorMode,
      sitemapStage,
      mapViewport,
      features,
      mapBoxItems,
      isRubberMode,
    } = mapBoxEditorStore

    const isFirstGeoreferencing =
      appState.projectCreationStep === ProjectSetUpSteps.GeoreferencePlan

    return (
      selectedSitemap && (
        <div
          className={classList({
            'sitemap-editor full-width full-height relative general-map-container':
              true,
            'unclickable-element': isSitemapBlocked,
          })}
          onClick={this.stopPropagation}
        >
          <BaseSitemap
            sitemapUrl={sitemapUrl}
            isDraggable={true}
            onClick={this.onMapClick}
            setSitemap={this.setDeliverySitemapStage}
            isDraggingMode={isDraggingMode}
            selectedSitemap={selectedSitemap}
            opacity={opacity}
            mapBoxEditorStore={mapBoxEditorStore}
            mapViewport={mapViewport}
            setViewport={setViewport}
            shouldShowImage={shouldShowImage}
            isImageRubberMode={isImageRubberMode}
            sourceBounds={sourceBounds}
            ref={this.setDeliverySitemap}
            getEditableItem={this.getTextEditor}
            saveSitemapItem={selectSitemapItem}
            selectedSitemapItem={selectedSitemapItem}
            creatableAttributeType={creatableAttributeType}
            mapBoxItems={mapBoxItems}
            features={features}
            isEditorMode={isEditorMode}
            basemapsStore={basemapsStore}
            projectAddress={appState.projectAddress}
            isFirstGeoreferencing={isFirstGeoreferencing}
            isRubberMode={isRubberMode}
            isTextStickerCreationActive={isTextStickerCreationActive}
            areAdditionalMarkersAvailable={true}
          >
            {({ width, height, key, isMainSitemap }) => {
              if (
                !this.deliverySitemap ||
                (selectedSitemap.isReferenced && !sitemapStage)
              ) {
                return null
              }

              return (
                <Provider {...this.context}>
                  {this.renderSitemapChildren(
                    width,
                    height,
                    key,
                    !selectedSitemap.isReferenced || isMainSitemap,
                  )}
                </Provider>
              )
            }}
          </BaseSitemap>
        </div>
      )
    )
  }

  private renderSitemapChildren = (
    width: number,
    height: number,
    key: string,
    isMainChildren?: boolean,
  ) => {
    const {
      store: { sitemapItemsSetupStore, mapBoxEditorStore, sitemapSetupStore },
      sitemap,
    } = this.props
    const { secondaryItems, itemsFiltering } = mapBoxEditorStore
    const { selectSitemapItem, displayedSitemapItems } = sitemapItemsSetupStore

    const referencedChildren = isMainChildren
      ? itemsFiltering
      : secondaryItems[key]
    const itemsToShow = sitemapSetupStore.selectedSitemap.isReferenced
      ? referencedChildren
      : displayedSitemapItems

    return (
      <>
        <SitemapItems
          items={itemsToShow || []}
          containerWidth={width}
          containerHeight={height}
          onSelectItem={selectSitemapItem}
          isEditMode={true}
          textboxesAwaiter={this.textboxesAwaiter}
          textboxesCache={this.textboxesCache}
          mapBoxEditorStore={mapBoxEditorStore}
          isReferenced={sitemap?.isReferenced}
        />
        {isMainChildren && (
          <>
            <SitemapEditableItem
              containerWidth={width}
              containerHeight={height}
              store={sitemapItemsSetupStore}
              textboxesCache={this.textboxesCache}
              sitemapSetupStore={sitemapSetupStore}
              mapBoxEditorStore={mapBoxEditorStore}
            />
            <CreateNewItemArea
              containerWidth={width}
              containerHeight={height}
              store={sitemapItemsSetupStore}
              sitemapSetupStore={sitemapSetupStore}
              mapBoxEditorStore={mapBoxEditorStore}
            />
          </>
        )}
      </>
    )
  }

  private getTextEditor = (width: number, height: number, stage: Stage) => {
    const {
      sitemapSetupStore: { selectedSitemap },
      sitemapItemsSetupStore: {
        selectedSitemapItem,
        isTextStickerCreationActive,
      },
    } = this.props.store

    return (
      !isTextStickerCreationActive &&
      selectedSitemapItem?.labelProperties &&
      selectedSitemapItem.isRichTextBox && (
        <RichTextEditor
          canvasSize={{ width, height }}
          stage={stage.getStage()}
          selectedSitemapItem={selectedSitemapItem}
          isReferenced={selectedSitemap?.isReferenced}
        />
      )
    )
  }

  private onMapClick = () => {
    this.deselectEditing()
  }

  private onKeyDown = (event: KeyboardEvent) => {
    const {
      selectedSitemapItem,
      selectedSitemapItemDrawnPart,
      showDeleteConfirmationDialog,
      disableCreatingAttribute,
      redoAction,
      undoAction,
      focusedLevel,
      isSitemapBlocked,
    } = this.props.store.sitemapItemsSetupStore
    const isMac = navigator.platform.toUpperCase().includes(MAC)
    const isCtrlPressed = event.ctrlKey || (isMac && event.metaKey)
    const isShiftPressed = event.shiftKey
    const { target } = event

    if (isMac && isCtrlPressed && !this.isCopyPasteMode(event.code)) {
      event.preventDefault()
    }

    switch (event.keyCode) {
      // Backspace === Delete on MacOS
      case BACKSPACE_KEY_CODE:
        if (!isMac) {
          break
        }
      // eslint-disable-next-line no-fallthrough
      case DELETE_KEY_CODE:
        if (
          INPUTS_NODE_NAMES.includes(target && target[nodeNameProp]) ||
          // check if it is rich text editor
          (target && target[contentEditableProp] === 'true')
        ) {
          return
        }

        if (
          selectedSitemapItem &&
          selectedSitemapItem.shapeProperties &&
          selectedSitemapItem.shapeProperties.type ===
            SitemapItemShapeType.Polyline &&
          selectedSitemapItemDrawnPart === SitemapItemDrawnPart.Shape // Line has custom behavior on DELETE click
        ) {
          return
        }
        showDeleteConfirmationDialog(selectedSitemapItem)
        disableCreatingAttribute()
        break
      case ESCAPE_KEY_CODE:
        if (isSitemapBlocked) {
          break
        }
        disableCreatingAttribute()
      // eslint-disable-next-line no-fallthrough
      case ENTER_KEY_CODE:
        if (
          (!selectedSitemapItem || !selectedSitemapItem.isRichTextBox) &&
          !focusedLevel
        ) {
          this.deselectEditing()
        }
        break
    }

    switch (event.code) {
      case Z_KEY_CODE:
        if (isShiftPressed && isCtrlPressed) {
          redoAction()
        } else if (isCtrlPressed) {
          undoAction()
        }
    }
  }

  private deselectEditing() {
    const {
      selectedSitemapItem,
      selectedSitemapItemDrawnPart,
      onMapClick,
      deselectSitemapItemDrawnPart,
      isSitemapBlocked,
    } = this.props.store.sitemapItemsSetupStore

    if (isSitemapBlocked) {
      return
    }

    if (selectedSitemapItemDrawnPart && selectedSitemapItem.hasMultipleParts) {
      deselectSitemapItemDrawnPart()
      return
    }
    onMapClick()
  }

  private setDeliverySitemap = ref => {
    this.deliverySitemap = ref
  }

  private setDeliverySitemapStage = (stage: Stage) => {
    this.props.store.mapBoxEditorStore.sitemapStage = stage
  }

  private stopPropagation(event: React.MouseEvent<HTMLDivElement>) {
    event.nativeEvent.preventDefault()
    event.nativeEvent.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  private onSaveSitemapImageRequest = async (eventContext: EventContext) => {
    const [eventType] = eventContext.event
    if (eventType === e.SAVE_DELIVERY_SITEMAP_IMAGE && this.deliverySitemap) {
      await this.textboxesAwaiter.ready()
      const base64ItemsImage = await this.deliverySitemap.getItemsDataURL()
      const base64Image = await this.deliverySitemap.getDataURL()
      this.props.store.saveDeliverySitemapImage(base64Image, base64ItemsImage)
    }
  }

  private isCopyPasteMode(code: string): boolean {
    return code === C_KEY_CODE || code === V_KEY_CODE
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'
import { Group } from 'react-konva'

interface IProps {
  children: React.ReactNode
  className?: string
}

@observer
export default class SitemapElementsWrapper extends React.Component<IProps> {
  public render() {
    const { className } = this.props
    return (
      <Group
        className={className}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.props.children}
      </Group>
    )
  }

  private handleMouseEnter = () => {
    document.body.style.cursor = 'pointer'
  }

  private handleMouseLeave = () => {
    document.body.style.cursor = 'default'
  }
}

import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { KonvaEventObject } from 'konva/types/Node'
import { Stage } from 'konva/types/Stage'
import { computed, observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import DeliveriesMapStore from '~/client/src/desktop/views/Deliveries/components/DeliveriesMap/DeliveriesMap.store'
import BaseSitemap from '~/client/src/shared/components/BaseSitemap/BaseSitemap'
import * as Icons from '~/client/src/shared/components/Icons'
import KonvaWorkflowDeliveriesPin from '~/client/src/shared/components/Konva/KonvaWorkflowDeliveriesPin'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SitemapDeliveriesDraggableModal from '~/client/src/shared/components/SitemapDraggableModalWrapper/components/SitemapDeliveriesDraggableModal'
import SitemapItems from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryPinLocationOption, {
  getDeliveryPinLocationOptionDisplayName,
} from '~/client/src/shared/enums/DeliveryPinLocationOption'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import DeliverySitemapPinStore from '~/client/src/shared/stores/ui/DeliverySitemapPin.store'
import { clickPosition } from '~/client/src/shared/utils/SitemapCalculationHelpers'

import SitemapElementsWrapper from '../../../shared/components/SitemapElementsWrapper'
import DesktopInitialState from '../../stores/DesktopInitialState'

// localization: translated
interface IProps {
  deliveriesMapStore: DeliveriesMapStore
  sitemapId: string
  textboxesCache: ICanvasImageCache

  state?: DesktopInitialState
  companiesStore?: CompaniesStore

  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
}

interface IShowOnMenuOption {
  name: DeliveryPinLocationOption
  icon: JSX.Element
  fieldId?: FieldIds
}

const showOnMenuOptions: IShowOnMenuOption[] = [
  {
    fieldId: FieldIds.BUILDING,
    name: DeliveryPinLocationOption.building,
    icon: <TagIcon.Building className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.AREA,
    name: DeliveryPinLocationOption.area,
    icon: <TagIcon.Area className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.ZONE,
    name: DeliveryPinLocationOption.zone,
    icon: <TagIcon.Zone className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.GATE,
    name: DeliveryPinLocationOption.gate,
    icon: <TagIcon.Gate className="no-grow mx5" />,
  },
  {
    fieldId: FieldIds.ROUTE,
    name: DeliveryPinLocationOption.route,
    icon: <TagIcon.Route className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.OFFLOADING_EQUIPMENT,
    name: DeliveryPinLocationOption.equipment,
    icon: <TagIcon.Equipment className="no-grow mx5 option-icon" />,
  },
  {
    name: DeliveryPinLocationOption.mostPertinent,
    icon: <Icons.EyeView className="no-grow mx5 option-icon row y-center" />,
  },
  {
    fieldId: FieldIds.LEVEL,
    name: DeliveryPinLocationOption.level,
    icon: <TagIcon.Level className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.STAGING,
    name: DeliveryPinLocationOption.staging,
    icon: <TagIcon.Staging className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.INTERIOR_DOOR,
    name: DeliveryPinLocationOption.interiorDoor,
    icon: <TagIcon.InteriorDoor className="no-grow mx5 option-icon" />,
  },
  {
    fieldId: FieldIds.INTERIOR_PATH,
    name: DeliveryPinLocationOption.interiorPath,
    icon: <TagIcon.InteriorPath className="no-grow mx5 option-icon" />,
  },
]

@inject('state', 'companiesStore', 'basemapsStore', 'sitemapsStore')
@observer
export default class DeliverySitemapPreviewHolder extends React.Component<IProps> {
  @observable private isShowOnMenuShown: boolean = false
  @observable private deliverySitemap: BaseSitemap
  public static contextType = MobXProviderContext

  private readonly store: DeliverySitemapPinStore = null

  private containerRef: HTMLElement = null

  public constructor(props: IProps) {
    super(props)

    const { deliveriesMapStore, sitemapId, state, sitemapsStore } = props

    this.store = new DeliverySitemapPinStore(state)

    deliveriesMapStore.resetAttrSelection()
    deliveriesMapStore.deselectSitemap()
    const sitemap = sitemapsStore.byId.get(sitemapId)
    deliveriesMapStore.selectSitemap(sitemap)

    deliveriesMapStore.mapBoxEditorStore.setViewportFromAdress()
    deliveriesMapStore.mapBoxEditorStore.setDefaultMapMode()
    deliveriesMapStore.mapBoxEditorStore.hideToggleMapMode()
  }

  public componentDidMount() {
    this.validateShowOnOption()
  }

  public componentDidUpdate(props: IProps) {
    const { sitemapId, deliveriesMapStore, sitemapsStore } = this.props
    if (sitemapId !== props.sitemapId) {
      const sitemap = sitemapsStore.byId.get(sitemapId)
      deliveriesMapStore.resetAttrSelection()
      deliveriesMapStore.selectSitemap(sitemap)
      this.validateShowOnOption()
      deliveriesMapStore.mapBoxEditorStore.setViewportFromAdress()
      deliveriesMapStore.mapBoxEditorStore.resetMapboxInfo()
      deliveriesMapStore.mapBoxEditorStore.removeRefs()
    }
  }

  public render() {
    const {
      deliveriesMapStore,
      sitemapId,
      basemapsStore,
      state,
      sitemapsStore,
    } = this.props
    const {
      selectedSitemapUrl,
      resetAttrSelection,
      resetAllFilters,
      selectDelivery,
      activeDeliveryId,
      attrIdToDeliveriesMap,
      selectedSitemapItem,
      mapBoxEditorStore,
      topOffset,
      leftOffset,
    } = deliveriesMapStore

    const {
      isDraggingMode,
      isImageRubberMode,
      shouldShowImage,
      sourceBounds,
      setViewport,
      viewport,
      mapViewport,
      opacity,
      sitemapStage,
    } = mapBoxEditorStore

    const sitemap = sitemapsStore.byId.get(sitemapId)

    return (
      <section
        ref={this.setContainerRef}
        className="full-height full-width relative general-map-container"
      >
        <BaseSitemap
          isDeliveryView={true}
          sitemapUrl={selectedSitemapUrl}
          isDraggable={true}
          onClick={this.handleMouseDownOnContainer}
          onTouch={this.handleMouseDownOnContainer}
          setSitemap={this.setDeliverySitemapStage}
          isDraggingMode={isDraggingMode}
          selectedSitemap={sitemap}
          opacity={opacity}
          mapBoxEditorStore={deliveriesMapStore.mapBoxEditorStore}
          mapViewport={viewport || mapViewport}
          setViewport={setViewport}
          shouldShowImage={shouldShowImage}
          isImageRubberMode={isImageRubberMode}
          sourceBounds={sourceBounds}
          basemapsStore={basemapsStore}
          projectAddress={state.projectAddress}
          ref={this.setDeliverySitemap}
        >
          {({ width, height, key, isMainSitemap }) => {
            if (
              !this.deliverySitemap ||
              (sitemap.isReferenced && !sitemapStage)
            ) {
              return null
            }

            return (
              <Provider {...this.context}>
                {this.renderSitemapChildren(width, height, key, isMainSitemap)}
              </Provider>
            )
          }}
        </BaseSitemap>
        {!!selectedSitemapItem && (
          <SitemapDeliveriesDraggableModal
            onClose={resetAttrSelection}
            item={selectedSitemapItem}
            containerRef={this.containerRef}
            onDeliveryClick={selectDelivery}
            activeDeliveryId={activeDeliveryId}
            onFilterResetClick={resetAllFilters}
            relatedDeliveries={attrIdToDeliveriesMap[selectedSitemapItem.id]}
            topOffset={topOffset}
            leftOffset={leftOffset}
          />
        )}
        {this.renderShowOnMenu()}
      </section>
    )
  }

  private setDeliverySitemap = ref => {
    this.deliverySitemap = ref
  }

  private renderSitemapChildren = (
    width: number,
    height: number,
    key: string,
    isMainSitemap?: boolean,
  ) => {
    const { textboxesCache, deliveriesMapStore, sitemapId, sitemapsStore } =
      this.props

    const {
      activeDeliveryId,
      getClosestAttrIdToDeliveriesMap,
      mapBoxEditorStore,
      displayedSitemapItems,
    } = deliveriesMapStore
    const sitemap = sitemapsStore.byId.get(sitemapId)

    const { secondaryItems, itemsFiltering } = mapBoxEditorStore

    const attrsToDeliveryMap = getClosestAttrIdToDeliveriesMap(
      this.sortedSitemapItems,
    )
    const referencedChildren = isMainSitemap
      ? itemsFiltering
      : secondaryItems[key]
    const itemsToShow = sitemap.isReferenced
      ? referencedChildren
      : displayedSitemapItems
    return (
      <>
        <SitemapItems
          className={classList({ 'unclickable-element': !isMainSitemap })}
          items={itemsToShow || []}
          containerWidth={width}
          containerHeight={height}
          textboxesCache={textboxesCache}
          onSelectItem={this.selectSitemapItem}
          mapBoxEditorStore={mapBoxEditorStore}
          isReferenced={sitemap?.isReferenced}
        />
        {(isMainSitemap || !sitemap.isReferenced) && (
          <SitemapElementsWrapper>
            {this.sortedSitemapItems.map(item =>
              this.renderWorkflowPin(
                item,
                width,
                height,
                attrsToDeliveryMap,
                activeDeliveryId,
              ),
            )}
          </SitemapElementsWrapper>
        )}
      </>
    )
  }

  private setDeliverySitemapStage = (stage: Stage) => {
    this.props.deliveriesMapStore.mapBoxEditorStore.sitemapStage = stage
  }

  private renderWorkflowPin(
    item: SitemapItemBase,
    cWidth: number,
    cHeight: number,
    attrIdToDeliveriesMap: { [attrId: string]: Delivery[] },
    activeDeliveryId: string,
  ): JSX.Element {
    const {
      companiesStore,
      deliveriesMapStore: { isSelectedAttr },
      state: { user },
    } = this.props

    const {
      getPinLabel,
      shouldShowPin,
      getPinPosition,
      shouldRenderCircle,
      shouldShowPinAsDone,
      shouldShowPinAsAssigned,
      shouldShowPinAsCanceled,
    } = this.store

    const deliveries = attrIdToDeliveriesMap[item.id] || []

    if (!shouldShowPin(item, deliveries)) {
      return null
    }

    const { id: sitemapItemId } = item
    const { x, y } = getPinPosition(item, cWidth, cHeight)

    const isPinSelected =
      isSelectedAttr(sitemapItemId) ||
      deliveries?.some(d => d.id === activeDeliveryId)

    return (
      <KonvaWorkflowDeliveriesPin
        key={sitemapItemId}
        isDone={shouldShowPinAsDone(deliveries)}
        isAssigned={shouldShowPinAsAssigned(deliveries, user)}
        isSelected={isPinSelected}
        isCanceled={shouldShowPinAsCanceled(deliveries)}
        text={getPinLabel(deliveries, companiesStore)}
        shouldRenderCircle={shouldRenderCircle(deliveries)}
        x={x}
        y={y}
        onClick={this.onDeliveryPinClick.bind(this, item, cWidth, cHeight)}
        onTouchEnd={this.onDeliveryPinClick.bind(this, item, cWidth, cHeight)}
      />
    )
  }

  private onDeliveryPinClick = (
    item: SitemapItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<MouseEvent>,
  ) => {
    event.cancelBubble = true
    const { clickCoords, canvasMap } =
      this.props.deliveriesMapStore.mapBoxEditorStore
    const position = clickPosition(
      width,
      height,
      clickCoords,
      canvasMap,
      null,
      item,
    )
    this.selectSitemapItem(item, position.y, position.x)
  }

  private selectSitemapItem = (item: SitemapItemBase, y: number, x: number) => {
    const { isSitemapItemSupported, selectAttr } = this.props.deliveriesMapStore

    if (isSitemapItemSupported(item)) {
      selectAttr(item.id, y, x)
    }
  }

  private setContainerRef = (ref: HTMLElement) => {
    this.containerRef = ref
  }

  private handleMouseDownOnContainer = () => {
    const { resetAttrSelection, selectedSitemapItem } =
      this.props.deliveriesMapStore

    if (selectedSitemapItem) {
      resetAttrSelection()
    }
  }

  @computed
  private get sortedSitemapItems(): SitemapItemBase[] {
    const {
      sitemapId,
      deliveriesMapStore: { mapBoxEditorStore, displayedSitemapItems },
      sitemapsStore,
    } = this.props
    const sitemap = sitemapsStore.byId.get(sitemapId)

    return this.store.getItemsByPinLocationOption(
      sitemap?.isReferenced ? mapBoxEditorStore.items : displayedSitemapItems,
    )
  }

  @computed
  private get locationOptions(): DeliveryPinLocationOption[] {
    const { displayedSitemapItems } = this.props.deliveriesMapStore

    const hasLevelOption = displayedSitemapItems.some(
      x => x?.dataObject?.type === LocationType.Level,
    )

    return Object.values(DeliveryPinLocationOption).filter(o =>
      o === DeliveryPinLocationOption.level ? hasLevelOption : true,
    )
  }

  private validateShowOnOption = () => {
    if (!this.locationOptions.includes(this.store.selectedPinOption)) {
      this.store.selectDefaultShowOnOption()
    }
  }

  private renderShowOnMenu = () => {
    const iconName = this.isShowOnMenuShown
      ? IconNames.CARET_DOWN
      : IconNames.CARET_UP

    const { selectedPinOption } = this.store
    const showOnMenuOption = showOnMenuOptions.find(
      o => o.name === selectedPinOption,
    )

    return (
      <div className="absolute row show-on-menu pointer">
        <div className="show-on-text-box text lp15 small uppercase pa5 br-light-input-border row y-center nowrap no-grow">
          <Icons.RoundedLocation className="shown-on-icon no-grow mr5" />
          {Localization.translator.showOn}
        </div>
        <div
          className={classList({
            'show-on-icons-box row': true,
            selected: this.isShowOnMenuShown,
          })}
          onClick={this.toggleShowOnMenu}
        >
          {showOnMenuOption.icon}
          <span className="large text">
            {this.getOptionName(showOnMenuOption)}
          </span>
          <Icon icon={iconName} className="no-grow mx5 caret-icon" />
        </div>
        {this.isShowOnMenuShown && (
          <MenuCloser className="no-grow" closeMenu={this.toggleShowOnMenu}>
            <div className="absolute col show-on-options brada10">
              {this.locationOptions.map((option, index) => {
                const currentOption = showOnMenuOptions.find(
                  o => o.name === option,
                )
                const isSelected = option === selectedPinOption
                const isLastOption = index === this.locationOptions.length - 1

                return (
                  <div
                    onClick={this.selectShowOnOption.bind(this, option)}
                    key={option}
                    className={classList({
                      'bb-dark-grey-border py5 option-holder row y-center pl10  no-grow pointer':
                        true,
                      selected: isSelected,
                      'first-option': !index,
                      'last-option': isLastOption,
                    })}
                  >
                    {currentOption.icon}
                    <span className="large text">
                      {this.getOptionName(currentOption)}
                    </span>
                    {isSelected && (
                      <Icons.CheckPaletteBlue className="no-grow ml10" />
                    )}
                  </div>
                )
              })}
            </div>
          </MenuCloser>
        )}
      </div>
    )
  }

  private selectShowOnOption = (option: DeliveryPinLocationOption) => {
    this.store.selectShowOnOption(option)

    this.toggleShowOnMenu()
  }

  private toggleShowOnMenu = () => {
    this.isShowOnMenuShown = !this.isShowOnMenuShown
  }

  private getOptionName({ fieldId, name }: IShowOnMenuOption): string {
    const { state } = this.props
    return fieldId
      ? state.getDeliveryFieldName(fieldId)
      : getDeliveryPinLocationOptionDisplayName(name)
  }
}

import * as React from 'react'

import { Line } from 'react-konva'

import { IBoundingBox } from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

export interface IProps {
  boundingBox: IBoundingBox
}

const BOUNDING_BOX_WIDTH = 2

export default class BoundingBox extends React.Component<IProps & any> {
  public render() {
    const { boundingBox, ...rest } = this.props
    if (!boundingBox) {
      return null
    }

    const { x, y, width, height } = boundingBox
    const points = [
      x,
      y,
      x + width,
      y,
      x + width,
      y + height,
      x,
      y + height,
      x,
      y,
    ]

    return (
      <Line
        points={points}
        strokeWidth={BOUNDING_BOX_WIDTH}
        stroke={ThemeMode.getHEXColor(Colors.primary60)}
        {...rest}
      />
    )
  }
}

import { DocumentNode } from 'graphql'
import { ObservableMap, computed, observable } from 'mobx'

import { DeleteLocationsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import ILocationDto from '~/client/src/shared/types/ILocationDto'
import { SITEMAP_ITEMS_COLORS_VALUES } from '~/client/src/shared/utils/SitemapItemsColors'

import IAccessibleLevelsAttribute from '../../models/IAccessibleLevelsAttribute'
import { sortLevels } from '../../utils/sortingFunctions'
import EventsStore from '../EventStore/Events.store'
import * as e from '../EventStore/eventConstants'
import GraphExecutorStore from './GraphExecutor.store'
import IDeliveryAttributeWithColorStore from './interfaces/IDeliveryAttributeWithColorStore'

export const FORBIDDEN_SITE_NAME = 'Site'
export const FORBIDDEN_SITE_NAME_MESSAGE = `'Site' name is prohibited`

export default abstract class LocationBaseStore<T extends LocationBase<T>>
  implements IDeliveryAttributeWithColorStore
{
  @observable public isDataReceived = false

  protected abstract saveMutation: DocumentNode
  protected deleteMutation: DocumentNode = DeleteLocationsDocument
  protected abstract graphName: string
  protected abstract defaultIconName: SitemapAttributeIcon

  public constructor(
    protected eventsStore: EventsStore,
    protected graphExecutorStore: GraphExecutorStore,
  ) {}

  public abstract get byId(): Map<string, T>

  @computed
  public get listWithDeletedItems() {
    return Array.from(this.byId.values())
  }

  @computed
  public get list() {
    return this.listWithDeletedItems.filter(entry => !entry.isDeleted)
  }

  public clearList() {
    this.byId.clear()
    this.isDataReceived = false
  }

  public receiveList(list: ILocationDto[]) {
    this.clearList()

    list.forEach(dto => {
      const item = this.fromDto(dto)
      this.byId.set(item.id, item)
    })
    this.isDataReceived = true
  }

  public receiveOne(dto: ILocationDto) {
    const item = this.fromDto(dto)
    this.byId.set(item.id, item)
  }

  public clearFromStorage(id: string) {
    this.byId.delete(id)
  }

  public getInstanceById = (instanceId: string) => {
    const instance = this.byId.get(instanceId)
    if (instance && !instance.isDeleted) {
      return instance
    }
  }

  public async createFromName(name: string) {
    if (name === FORBIDDEN_SITE_NAME) {
      return
    }
    const item = this.fromDto({
      id: null,
      color: this.colorForNewItem,
      name,
      projectId: this.eventsStore.appState.activeProject.id,
      iconName: this.defaultIconName,
    })

    await this.saveItem(item)

    return item
  }

  public updateName(id: string, name: string) {
    if (name === FORBIDDEN_SITE_NAME) {
      return
    }
    const dto = this.byId.get(id)
    dto.name = name
    this.saveItem(dto)
  }

  public updateColor(id: string, color: string) {
    const dto = this.byId.get(id)
    dto.color = color
    this.saveItem(dto)
  }

  public async updateIfChanged(
    item: T,
  ): Promise<{ item: T; isUpdated: boolean }> {
    const existing = this.byId.get(item.id)
    if (existing && existing.isEqual(item)) {
      return { item, isUpdated: false }
    }

    item.id = await this.saveItem(item)
    return { item, isUpdated: true }
  }

  public async saveItem(
    item: T,
    shouldSkipAdditionalSaveAction?: boolean,
  ): Promise<string> {
    if (item.name === FORBIDDEN_SITE_NAME) {
      return
    }
    const oldItem = this.byId.get(item.id)

    this.loadingMap.set(e.SAVE_LOCATION, true)

    const res = await this.graphExecutorStore.mutate(this.saveMutation, {
      [this.graphName]: [item.getDto()],
    })

    this.loadingMap.set(e.SAVE_LOCATION, false)

    const [data] = Object.values<any>(res.data)[0]

    if (!item.id) {
      item.id = data.id
    }

    if (!shouldSkipAdditionalSaveAction) {
      await this.additionalSaveAction(item, oldItem)
    }

    return data.id || item.id
  }

  public async removeItems(ids: string[]) {
    await this.graphExecutorStore.mutate(this.deleteMutation, { ids: ids })

    ids.forEach(id => this.byId.delete(id))
  }

  public getString = (itemId: any, shouldShowDeletedEquipment: boolean) => {
    let result = '—'
    const item = this.byId.get(itemId)
    if (item && (!item.isDeleted || shouldShowDeletedEquipment)) {
      result = item.name
    }

    return result
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected async additionalSaveAction(item: T, oldItem?: T): Promise<void> {
    return
  }

  protected abstract fromDto(dto: ILocationDto): T

  public get colorForNewItem(): string {
    const colorsCount = SITEMAP_ITEMS_COLORS_VALUES.length
    const nextColorIdx = this.list.length % colorsCount
    return SITEMAP_ITEMS_COLORS_VALUES[nextColorIdx]
  }

  protected get loadingMap(): ObservableMap<any, boolean> {
    return this.eventsStore.appState.loading
  }

  protected getAllowedAccessibleLevels(item: IAccessibleLevelsAttribute) {
    const { levels: allLevels, buildings } = this.eventsStore.appState
    const building = buildings.get(item.parent?.parentId)

    let levels = Array.from(allLevels.values()).filter(
      l => !l.isDeleted && l.isParent(building),
    )
    if (item.accessibleLevels?.length) {
      const filteredLvls = levels.filter(l =>
        item.accessibleLevels.includes(l.id),
      )
      levels = filteredLvls.length ? filteredLvls : levels
    }
    return (
      building?.sortLevelsByCustomOrder(levels) ||
      levels.sort((a, b) => sortLevels(b, a))
    ).map(lvl => lvl.id)
  }
}

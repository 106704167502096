import { computed } from 'mobx'

import { SaveIntegrationsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

import LocationIntegration, {
  LocationIntegrationType,
} from '../../models/LocationObjects/LocationIntegration'
import ILocationDto from '../../types/ILocationDto'
import LocationStoreBase from './LocationBase.store'

export default class LocationIntegrationsStore extends LocationStoreBase<LocationIntegration> {
  protected saveMutation = SaveIntegrationsDocument
  protected graphName = 'integrations'
  protected defaultIconName = SitemapAttributeIcon.Monitoring

  public get byId(): Map<string, LocationIntegration> {
    return this.eventsStore.appState.locationIntegrations
  }

  @computed
  public get maturixStationsList() {
    return this.list.filter(
      entry =>
        entry?.integrationType === LocationIntegrationType.MaturixStation,
    )
  }

  protected fromDto(dto: ILocationDto): LocationIntegration {
    return LocationIntegration.fromDto(dto)
  }
}

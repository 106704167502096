import { action, computed, observable } from 'mobx'

import { ISitemap, ISitemapInput } from '~/client/graph'
import SitemapType from '~/client/src/shared/enums/SitemapType'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import Guard from '~/client/src/shared/utils/Guard'

import { DELETE_SITEMAP, SAVE_SITEMAP } from '../EventStore/eventConstants'

export default class SitemapsStore {
  @observable public isDataReceived = false

  public constructor(private readonly eventsStore: EventsStore) {
    Guard.requireAll({
      eventsStore,
    })
  }

  @computed
  public get list(): Sitemap[] {
    return Array.from(this.byId.values())
  }

  @computed
  public get defaultSitemaps(): Sitemap[] {
    return this.list.filter(dto => dto.type === SitemapType.Default)
  }

  @computed
  public get gateSitemaps(): Sitemap[] {
    return this.list.filter(dto => dto.type === SitemapType.Gate)
  }

  @computed
  public get zoneSitemaps(): Sitemap[] {
    return this.list.filter(dto => dto.type === SitemapType.Zone)
  }

  public get byId() {
    const { sitemaps } = this.eventsStore.appState
    return sitemaps
  }

  @action.bound
  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @action.bound
  public receiveList(list: ISitemap[]) {
    this.clearList()

    list.forEach(dto => {
      const sitemap = Sitemap.fromDto(dto)
      this.byId.set(sitemap.id, sitemap)
    })

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: ISitemap) {
    if (dto) {
      this.byId.set(dto.id, Sitemap.fromDto(dto))
    } else {
      this.byId.delete(id)
    }
  }

  @action.bound
  public save(
    sitemap: Sitemap,
    callbackFn?: (id: string) => void,
    shouldApplyChangesBeforeSave?: boolean,
    avoidSaving?: boolean,
    width?: number,
    height?: number,
  ) {
    const { id: projectId } = this.eventsStore.appState.activeProject

    const sitemapInput: ISitemapInput = {
      id: sitemap.id,
      name: sitemap.name,
      basemapId: sitemap.basemapId,
      type: sitemap.type,
      filledImage: sitemap.filledImage,
      itemsFilledImage: sitemap.itemsFilledImage,
      isLabelsShown: sitemap.isLabelsShown,
      projectId: sitemap.projectId || projectId,
      isProjectOverviewMap: sitemap.isProjectOverviewMap,
      bounds: sitemap.bounds,
      zoom: sitemap.zoom,
      pitch: sitemap.pitch,
      center: sitemap.center,
      bearing: sitemap.bearing,
      width: width || sitemap.width,
      height: height || sitemap.height,
      geoCorners: sitemap.geoCorners,
    }

    const { polylineItemDtos, rectangleItemDtos, circleItemDtos } = sitemap

    if (shouldApplyChangesBeforeSave && sitemap.id) {
      this.byId.set(sitemap.id, sitemap.getFullCopy())
    }

    if (!avoidSaving) {
      this.eventsStore.dispatch(
        SAVE_SITEMAP,
        sitemapInput,
        polylineItemDtos,
        rectangleItemDtos,
        circleItemDtos,
        callbackFn,
      )
    }
  }

  @action.bound
  public removeOne(sitemapId: string, callbackFn?: () => void) {
    if (!this.byId.has(sitemapId)) {
      return
    }

    this.byId.delete(sitemapId)

    this.eventsStore.dispatch(DELETE_SITEMAP, sitemapId, callbackFn)
  }
}

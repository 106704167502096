import { observable } from 'mobx'

import { LocationType } from '~/client/graph'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import IHierarchyParent from '~/client/src/shared/types/IHierarchyParent'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'
import IMsDateInterval from '~/client/src/shared/types/IMsDateInterval'
import { DEFAULT_SITEMAP_ITEM_COLOR } from '~/client/src/shared/utils/SitemapItemsColors'

import { copyObject, copyObjectArray } from '../../utils/util'
import LocationBase from './LocationBase'

// TODO: Consider simplification of inheritance logic and using of generics.
// Also consider renaming LocationAttributeBase into something related with deliveries
// since properties "maxBookingDuration" and "maxOverlappingDeliveries" refer to Delivery.

export default abstract class LocationAttributeBase<
    T extends LocationAttributeBase<T> = any,
  >
  extends LocationBase<T>
  implements ILocationAttributeDto
{
  public static factory<U>(
    model: new (...args: any) => U,
    dto: ILocationAttributeDto,
    defaultIcon: SitemapAttributeIcon,
  ): U {
    return new model(
      dto.id,
      dto.name,
      dto.color || DEFAULT_SITEMAP_ITEM_COLOR,
      dto.iconName || defaultIcon,
      dto.projectId,
      dto.code,
      Object.values(dto.operatingIntervals || {}),
      dto.parent,
      Object.values(dto.permittedCompanies || {}),
      dto.isDeleted,
      Object.values(dto.assignedSitemaps || {}),
      dto.maxBookingDuration,
      dto.maxOverlappingDeliveries,
    )
  }

  @observable public maxBookingDuration: number = 0
  @observable public maxOverlappingDeliveries: number = 0

  public abstract type: LocationType
  protected abstract model: new (...args: any) => T

  public constructor(
    id: string,
    name: string,
    color: string,
    iconName: SitemapAttributeIcon,
    projectId: string,
    code?: string,
    operatingIntervals: IMsDateInterval[] = [],
    parent?: IHierarchyParent,
    permittedCompanies: string[] = [],
    isDeleted: boolean = false,
    assignedSitemaps: string[] = [],
    maxBookingDuration: number = 0,
    maxOverlappingDeliveries: number = 0,
  ) {
    super(
      id,
      name,
      color,
      iconName,
      projectId,
      code,
      operatingIntervals,
      parent,
      permittedCompanies,
      isDeleted,
      assignedSitemaps,
    )
    this.maxBookingDuration = maxBookingDuration
    this.maxOverlappingDeliveries = maxOverlappingDeliveries
  }

  public isEqual(dto: T) {
    return (
      super.isEqual(dto) &&
      this.maxBookingDuration === dto.maxBookingDuration &&
      this.maxOverlappingDeliveries === dto.maxOverlappingDeliveries
    )
  }

  public copy(): T {
    return new this.model(
      this.id,
      this.name,
      this.color,
      this.iconName,
      this.projectId,
      this.code,
      copyObjectArray(this.operatingIntervals),
      this.parent && copyObject(this.parent),
      this.permittedCompanies,
      this.isDeleted,
      this.assignedSitemaps,
      this.maxBookingDuration,
      this.maxOverlappingDeliveries,
    )
  }

  public getDto(): ILocationAttributeDto {
    return Object.assign(super.getDto(), {
      maxBookingDuration: this.maxBookingDuration,
      maxOverlappingDeliveries: this.maxOverlappingDeliveries,
    })
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Activity from '~/client/src/shared/models/Activity'
import { NOOP } from '~/client/src/shared/utils/noop'

import ActivityItemContentWithStatusUpdate from '../../ActivityItemContentWithStatusUpdate/ActivityItemContentWithStatusUpdate'
import SitemapItemBase from '../../SitemapHelpers/models/SitemapItemBase'
import SitemapDraggableModalWrapper from '../SitemapDraggableModalWrapper'

interface IProps {
  item: SitemapItemBase
  containerRef: HTMLElement
  selectedActivities: Activity[]
  selectedActivityId: string

  openActivity(code: string): void
  onClose(): void

  topOffset: number
  leftOffset: number

  currentViewDate?: Date
}

// localization: no text to translate

@observer
export default class SitemapActivitiesDraggableModal extends React.Component<IProps> {
  public render() {
    const {
      selectedActivities,
      selectedActivityId,
      currentViewDate,
      item,
      containerRef,
      onClose,
      topOffset,
      leftOffset,
    } = this.props

    if (!selectedActivities.length) {
      return
    }

    return (
      <SitemapDraggableModalWrapper
        className="overflow-auto"
        item={item}
        containerRef={containerRef}
        onClose={onClose}
        topOffset={topOffset}
        leftOffset={leftOffset}
      >
        <div className="relative overflow-auto draggable-elements-list">
          {selectedActivities.map(activity => (
            <div
              key={activity.code}
              onClick={this.openActivity.bind(this, activity)}
              className={classList({
                'bt-palette-grey activity-item': true,
                'selected-card': activity.id === selectedActivityId,
              })}
            >
              <ActivityItemContentWithStatusUpdate
                activity={activity}
                currentViewDate={currentViewDate}
                displayStatusUpdateModal={NOOP}
                displayActivityDetails={this.openActivity.bind(this, activity)}
                activitiesTreeStore={null}
              />
            </div>
          ))}
        </div>
      </SitemapDraggableModalWrapper>
    )
  }

  private openActivity(activity: Activity) {
    this.props.openActivity(activity.code)
  }
}

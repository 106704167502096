import * as React from 'react'

import { Group } from 'konva/types/Group'
import { Shape, ShapeConfig } from 'konva/types/Shape'
import { Stage } from 'konva/types/Stage'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Draggable, {
  ControlPosition,
  DraggableData,
  DraggableEvent,
} from 'react-draggable'
import ReactQuill from 'react-quill'

import { IPosition } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import {
  MAX_RELATIVE_POSITION,
  sitemapMousePointTo,
} from '~/client/src/shared/utils/SitemapCalculationHelpers'

import 'react-quill/dist/quill.snow.css'
import './RichTextEditor.scss'

interface IProps {
  canvasSize: ISizes
  selectedSitemapItem: SitemapItemBase
  stage: Stage

  isReferenced?: boolean
}

interface ISizes {
  width: number
  height: number
}
const EDITOR_CLASS = 'ql-editor'

// TODO: redesign and remove react quill as its additional functionality is not used
@observer
export default class RichTextEditor extends React.Component<IProps> {
  private resizableEditor = null
  @observable private editorSize: ISizes = {
    width: 0,
    height: 0,
  }

  private get editorHalfWidth(): number {
    return this.editorSize.width / 2
  }

  private get editorHalfHeight(): number {
    return this.editorSize.height / 2
  }

  @computed
  private get position(): ControlPosition {
    const { canvasSize, selectedSitemapItem, isReferenced } = this.props
    const { x, y } = selectedSitemapItem.labelProperties.position

    // scale x, y to canvas and center it
    const offsetX = (this.stage.width() - canvasSize.width) / 2
    const offsetY = (this.stage.height() - canvasSize.height) / 2

    const additionalX = isReferenced
      ? this.itemsLayer.attrs.x
      : offsetX * this.stage.scaleX() + this.stage.x()
    const additionalY = isReferenced
      ? this.itemsLayer.attrs.y
      : offsetY * this.stage.scaleY() + this.stage.y()
    const width = isReferenced
      ? this.imageLayer.attrs.width
      : canvasSize.width * this.stage.scaleX()
    const height = isReferenced
      ? this.imageLayer.attrs.height
      : canvasSize.height * this.stage.scaleY()

    const position: ControlPosition = {
      x:
        (width * x) / MAX_RELATIVE_POSITION +
        additionalX -
        this.editorHalfWidth,
      y:
        (height * y) / MAX_RELATIVE_POSITION +
        additionalY -
        this.editorHalfHeight,
    }

    return position
  }

  public componentDidMount(): void {
    this.resizableEditor?.focus()
  }

  public componentWillUnmount(): void {
    this.resizableEditor = null
  }

  public render(): JSX.Element {
    const { labelProperties, name, color } = this.props.selectedSitemapItem
    const { isTextBoxDisplayed, fontSize, color: labelColor } = labelProperties
    // wrap editor with div to make draggable work
    return (
      <Draggable
        position={this.position}
        onStop={this.dragStop}
        handle=".ql-toolbar.ql-snow"
      >
        <div
          className={classList({
            'text-box': true,
            'show-textbox': isTextBoxDisplayed,
          })}
          style={{ fontSize: fontSize + 'px', color: labelColor }}
        >
          <ReactQuill
            value={name}
            onChange={this.onChange}
            ref={this.setEditor}
          />
          <Icons.TLetter className="move-icon" style={{ fill: color }} />
        </div>
      </Draggable>
    )
  }

  private dragStop = (event: DraggableEvent, data: DraggableData): void => {
    const { canvasSize, selectedSitemapItem, isReferenced } = this.props
    const { x, y } = selectedSitemapItem.labelProperties.position

    const width = isReferenced ? this.imageLayer.attrs.width : canvasSize.width
    const height = isReferenced
      ? this.imageLayer.attrs.height
      : canvasSize.height
    let relativePosition: IPosition

    if (isReferenced) {
      const { x: offsetX, y: offsetY } = this.itemsLayer.attrs
      const cursorPosition: IPosition = {
        x: data.x + this.editorHalfWidth - offsetX,
        y: data.y + this.editorHalfHeight - offsetY,
      }

      relativePosition = {
        x: (cursorPosition?.x / width) * MAX_RELATIVE_POSITION - x,
        y: (cursorPosition?.y / height) * MAX_RELATIVE_POSITION - y,
      }
    } else {
      const position: IPosition = {
        x: data.x + this.editorHalfWidth,
        y: data.y + this.editorHalfHeight,
      }
      const mousePointTo: IPosition = sitemapMousePointTo(
        this.stage,
        width,
        height,
        position,
      )

      relativePosition = {
        x: (mousePointTo.x / width) * MAX_RELATIVE_POSITION - x,
        y: (mousePointTo.y / height) * MAX_RELATIVE_POSITION - y,
      }
    }

    selectedSitemapItem.move(relativePosition.x, relativePosition.y)
    this.resizableEditor?.focus()
  }

  private setEditor = ref => {
    if (ref) {
      this.resizableEditor = ref
      // remove default quill class
      // as it adds css styles which hard to override
      ref.editingArea
        .querySelector('.' + EDITOR_CLASS)
        .classList.remove(EDITOR_CLASS)
      const width = this.resizableEditor.editingArea.offsetWidth
      const height = this.resizableEditor.editingArea.offsetHeight
      this.editorSize = { width, height }
    }
  }

  @action.bound
  private onChange(model: string): void {
    this.props.selectedSitemapItem.setName(model)
    this.onResize()
  }

  private onResize = (): void => {
    if (!this.resizableEditor?.editingArea) {
      return
    }

    const { offsetWidth, offsetHeight } = this.resizableEditor.editingArea
    const newEditorSize = { width: offsetWidth, height: offsetHeight }
    const { canvasSize, selectedSitemapItem } = this.props

    const centerDX = (offsetWidth - this.editorSize.width) / 2
    const dx = (centerDX * MAX_RELATIVE_POSITION) / canvasSize.width

    const centerDY = (offsetHeight - this.editorSize.height) / 2
    const dy = (centerDY * MAX_RELATIVE_POSITION) / canvasSize.height
    selectedSitemapItem.move(dx, dy)
    this.editorSize = newEditorSize
  }

  private get stage(): Stage {
    return this.props.stage
  }

  private get itemsLayer(): Group | Shape<ShapeConfig> {
    return this.stage.children[0].children[1]
  }

  private get imageLayer(): Group | Shape<ShapeConfig> {
    return this.stage.children[0].children[0]
  }
}

import { action, observable } from 'mobx'

import HierarchyNode from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/models/HierarchyNode'
import SitemapItemsSetupStore from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/stores/SitemapItemsSetup.store'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'

export default class HierarchyTreeNodeStore {
  @observable public isNodeHovering: boolean = false
  @observable public isGroupToggleHovering: boolean = false
  @observable public isObjectToggleHovering: boolean = false
  @observable public hoveredNode: HierarchyNode = null

  public constructor(
    private readonly sitemapItemsSetupStore: SitemapItemsSetupStore,
    private node: HierarchyNode,
  ) {}

  public get isNodeSelected(): boolean {
    return (
      !this.node.isSite &&
      this.sitemapItemsSetupStore.isSitemapItemSelected(this.node.item)
    )
  }

  public get groupItems(): SitemapItemBase[] {
    return this.node.groupNodes.map(n => n.item).filter(i => i)
  }

  public get isGroupDisplayed(): boolean {
    if (!this.node.hasChildren) {
      return false
    }

    return this.groupItems.every(i => i.isDisplayed)
  }

  public get lastNodeOfGroupId(): string {
    if (!this.isGroupToggleHovering) {
      return ''
    }

    const { groupNodes } = this.node
    return groupNodes[groupNodes.length - 1].nodeId
  }

  @action.bound
  public setNode(node: HierarchyNode) {
    this.node = node
  }

  @action.bound
  public setNodeHovering(node: HierarchyNode) {
    this.isNodeHovering = true
    this.sitemapItemsSetupStore.hoveredNode = node
  }

  @action.bound
  public resetNodeHovering() {
    this.isNodeHovering = false
    this.sitemapItemsSetupStore.hoveredNode = null
  }

  @action.bound
  public setGroupToggleHovering() {
    this.isGroupToggleHovering = true
  }

  @action.bound
  public resetGroupToggleHovering() {
    this.isGroupToggleHovering = false
  }

  @action.bound
  public setObjectToggleHovering() {
    this.isObjectToggleHovering = true
  }

  @action.bound
  public resetObjectToggleHovering() {
    this.isObjectToggleHovering = false
  }

  @action.bound
  public toggleItemsGroup() {
    if (!this.isUpdatingSitemapLoaderShown) {
      this.sitemapItemsSetupStore.toggleItemsVisibility(
        this.groupItems,
        this.isGroupDisplayed,
      )
    }
  }

  @action.bound
  public toggleItem() {
    if (!this.node.isSite && !this.isUpdatingSitemapLoaderShown) {
      this.sitemapItemsSetupStore.toggleSingleItemVisibility(this.node.item)
    }
  }

  @action.bound
  public selectItem(
    event: React.SyntheticEvent<HTMLDivElement>,
    item?: SitemapItemBase,
  ) {
    event.stopPropagation()

    if (!this.node.isSite) {
      this.sitemapItemsSetupStore.selectSitemapItem(item || this.node.item)
    }
  }

  public get isUpdatingSitemapLoaderShown(): boolean {
    return this.sitemapItemsSetupStore.isUpdatingSitemapLoaderShown
  }
}

import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { PresentationScreenKey } from '~/client/graph'
import ProjectSelector from '~/client/src/desktop/components/ProjectSelector/ProjectSelector'
import DesktopInitialState, {
  SidebarComponentName,
} from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import PresentationModeStore from '~/client/src/desktop/stores/ui/PresentationMode.store'
import ChatIconWithBadge from '~/client/src/shared/components/ChatIconWithBadge/ChatIconWithBadge'
import * as Icons from '~/client/src/shared/components/Icons'
import LanguageSelector from '~/client/src/shared/components/LanguageSelector/LanguageSelector'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import PurpleConfirm from '~/client/src/shared/components/PurpleConfirm/PurpleConfirm'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ChatService from '~/client/src/shared/services/ChatService/Chat.service'
import { ProjectSetUpSteps } from '~/client/src/shared/stores/InitialState'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'
import { NOOP } from '~/client/src/shared/utils/noop'

import desktopRoutes from '../../constants/desktopRoutes'
import NavBarStore from './NavBar.store'
import { NavBarItem } from './components/NavBarItem'

import './NavBar.scss'

// localization: translated

interface INavBarProps {
  common?: DesktopCommonStore
  eventsStore?: DesktopEventStore
  projectsStore?: ProjectsStore
  state?: DesktopInitialState
  presentationModeStore?: PresentationModeStore
  projectDateStore?: ProjectDateStore
  currentSettingsPage?: string
  chatService?: ChatService
  className?: string
}

@inject(
  'common',
  'eventsStore',
  'projectsStore',
  'state',
  'presentationModeStore',
  'projectDateStore',
  'chatService',
)
@observer
export default class NavBar extends React.Component<INavBarProps> {
  private readonly navBarStore: NavBarStore

  public constructor(props: INavBarProps) {
    super(props)

    this.navBarStore = new NavBarStore(
      props.common,
      props.eventsStore,
      props.projectsStore,
      props.state,
      props.presentationModeStore,
      props.projectDateStore,
    )
  }

  public render() {
    const {
      isPresentationStopped,
      isProjectSelectorDisplayed,
      isProjectSelectorDisabled,
      projects,
      activeProject,
      shouldShowProjectClock,
      logoutHandler,
      hideStopPresentationDialog,
      selectProject,
    } = this.navBarStore
    const { className } = this.props

    const { projectCreationStep } = this.props.state

    return (
      <div
        className={classList({
          'desktop-nav-bar row y-center relative': true,
          [className]: !!className,
        })}
      >
        <PurpleConfirm
          isShown={isPresentationStopped}
          title={Localization.translator.presentationMode.toUpperCase()}
          actionTitle={Localization.translator.stop}
          className="stop-presentation-confirm"
          onApply={logoutHandler}
          onHide={hideStopPresentationDialog}
        >
          {Localization.translator.presentationModeDescriptions.stop}
        </PurpleConfirm>
        <div className="flex-item row no-flex-children x-start y-center pl15 no-grow">
          <img src="/static/icons/strux-hub-dark.svg" />
          <NavBarItem
            isHovering={false}
            onClickHandler={this.stopPropagation}
            navBarStore={this.navBarStore}
          >
            {isProjectSelectorDisplayed && (
              <ProjectSelector
                isDisabled={isProjectSelectorDisabled}
                isLoading={false}
                currentProject={activeProject}
                projects={projects}
                onItemSelect={selectProject}
                shouldShowProjectClock={shouldShowProjectClock}
              />
            )}
          </NavBarItem>
        </div>
        {projectCreationStep ? (
          this.renderProjectCreationRow()
        ) : (
          <>
            <div className="flex-item row x-end y-center text menu-toggler-icon mr30">
              <Icon icon={IconNames.MENU} />
            </div>
            <input type="checkbox" className="menu-toggler" />
            <div className="flex-item row x-end y-center text menu-links">
              <div className="flex-item row no-flex x-end y-center text navbar-list-left">
                {this.renderLeftMenuItems()}
              </div>
              <div className="flex-item no-grow row x-end y-center text navbar-list-right flex-pull-right">
                {this.renderRightMenuItems()}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  private renderLeftMenuItems() {
    const {
      presentationModeStore: { canShowPage },
      state: { isVisitorMode, isPresentationMode },
    } = this.props

    const {
      isTrackerDisabled,
      isDeliveriesDisabled,
      isLogisticsDisabled,
      isMaterialsDisabled,
      isPhotosDisabled,
      isFormsDisabled,
      isAnalyticsDisabled,
    } = this.navBarStore

    return (
      <>
        {!isLogisticsDisabled &&
          canShowPage(PresentationScreenKey.Logistics) && (
            <NavBarItem
              navBarStore={this.navBarStore}
              routeLabel={Localization.translator.home}
              route={desktopRoutes.HOME}
            />
          )}
        {!isDeliveriesDisabled &&
          canShowPage(PresentationScreenKey.Deliveries) && (
            <NavBarItem
              navBarStore={this.navBarStore}
              routeLabel={Localization.translator.deliveries}
              route={desktopRoutes.DELIVERIES}
              isDisabled={isVisitorMode}
            />
          )}
        {!isFormsDisabled && canShowPage(PresentationScreenKey.Forms) && (
          <NavBarItem
            navBarStore={this.navBarStore}
            routeLabel={Localization.translator.forms}
            route={desktopRoutes.FORMS}
            isDisabled={isVisitorMode}
          />
        )}
        {!isTrackerDisabled && canShowPage(PresentationScreenKey.Gantt) && (
          <NavBarItem
            navBarStore={this.navBarStore}
            routeLabel={Localization.translator.activities}
            route={desktopRoutes.ACTIVITIES}
            isDisabled={isVisitorMode}
          />
        )}

        {!isMaterialsDisabled && !isPresentationMode && (
          <NavBarItem
            navBarStore={this.navBarStore}
            routeLabel={Localization.translator.materials}
            route={desktopRoutes.MATERIALS}
            isDisabled={isVisitorMode}
          />
        )}

        {!isPhotosDisabled && !isPresentationMode && (
          <NavBarItem
            navBarStore={this.navBarStore}
            routeLabel={Localization.translator.photos}
            route={desktopRoutes.PHOTOS}
            isDisabled={isVisitorMode}
          />
        )}

        {!isAnalyticsDisabled && !isPresentationMode && (
          <NavBarItem
            navBarStore={this.navBarStore}
            routeLabel={Localization.translator.analytics}
            route={desktopRoutes.ANALYTICS}
            isDisabled={isVisitorMode}
          />
        )}
      </>
    )
  }

  public get settingsTitle(): React.ReactNode {
    const { isPresentationMode } = this.props.state

    switch (true) {
      case isPresentationMode:
        return Localization.translator.settings

      default:
        return (
          <UserProfilePreview
            isBriefInfoHidden
            isUserNameClickable={false}
            user={this.navBarStore.user}
          />
        )
    }
  }

  private renderRightMenuItems() {
    const { state, chatService } = this.props
    const {
      isVisitorMode,
      toggleChat,
      toggleSideUserDirectory,
      activeSideBarComponent,
    } = state
    const {
      clickOnNotifications,
      clickOnSettings,
      shouldShowSettingsMenu,
      unreadNotificationsCount,
    } = this.navBarStore

    const hasUnreadNotifications = !!unreadNotificationsCount && !isVisitorMode

    return (
      <>
        <NavBarItem
          navBarStore={this.navBarStore}
          shouldShowIcon={false}
          onClickHandler={toggleSideUserDirectory}
          isActive={
            activeSideBarComponent === SidebarComponentName.UserDirectory
          }
          className="row nohover"
        >
          <Icons.UsersDirectory className="row icon-wrapper" />
        </NavBarItem>
        <NavBarItem
          navBarStore={this.navBarStore}
          shouldShowIcon={false}
          onClickHandler={toggleChat}
          isActive={activeSideBarComponent === SidebarComponentName.Chat}
          isDisabled={isVisitorMode}
          className="row nohover"
        >
          <ChatIconWithBadge
            className="icon-wrapper"
            count={chatService.allUnreadMessagesCount}
          />
        </NavBarItem>
        <NavBarItem
          navBarStore={this.navBarStore}
          route={desktopRoutes.NOTIFICATIONS}
          onClickHandler={clickOnNotifications}
          isDisabled={isVisitorMode}
          className="row nohover"
        >
          <div className="badge-container">
            <Icons.Bell className="row" />
            {hasUnreadNotifications && (
              <span className="row x-center absolute text small badge px4">
                {unreadNotificationsCount}
              </span>
            )}
          </div>
        </NavBarItem>
        {Localization.shouldShowLanguageSelector && (
          <NavBarItem
            navBarStore={this.navBarStore}
            onClickHandler={NOOP}
            shouldShowIcon={true}
            className="nohover"
          >
            <LanguageSelector />
          </NavBarItem>
        )}
        {ThemeMode.shouldShowThemeSelector && (
          <NavBarItem
            navBarStore={this.navBarStore}
            onClickHandler={NOOP}
            shouldShowIcon={false}
            className="nohover"
          >
            <Icon
              icon={IconNames.MOON}
              className={classList({
                'no-grow theme-mode-switcher-icon': true,
                active: ThemeMode.isDarkMode,
              })}
              onClick={ThemeMode.toggleMode}
            />
          </NavBarItem>
        )}
        <div>
          <NavBarItem
            navBarStore={this.navBarStore}
            route={desktopRoutes.PROJECT_SETUP}
            onClickHandler={clickOnSettings}
            isUnclickable={shouldShowSettingsMenu}
            shouldShowIcon={true}
            className="nohover"
          >
            {this.settingsTitle}
          </NavBarItem>
          {shouldShowSettingsMenu && this.renderSettingsDropDown()}
        </div>
      </>
    )
  }

  private renderSettingsDropDown() {
    const { userActiveProjectSettings } = this.props.state
    const {
      clickOnSettings,
      logoutHandler,
      clickOnProjectSetup,
      clickOnUserSettings,
      shouldShowSettingsMenu,
    } = this.navBarStore

    const onSettingsClickHandler = userActiveProjectSettings?.isAdmin
      ? clickOnProjectSetup
      : clickOnUserSettings

    return (
      <MenuCloser closeMenu={clickOnSettings} isOpen={shouldShowSettingsMenu}>
        <div className="settings brada4 ba-light-input-border">
          <div className="settings-item" onClick={onSettingsClickHandler}>
            <a className="text large option">
              {Localization.translator.settings}
            </a>
          </div>
          <div className="settings-item" onClick={logoutHandler}>
            <a className="text large option">
              {Localization.translator.logOut}
            </a>
          </div>
        </div>
      </MenuCloser>
    )
  }

  private renderProjectCreationRow() {
    const { projectCreationStep } = this.props.state
    return (
      <div className="row full-width">
        <div className="project-creation-vertical-line no-grow" />
        {Object.keys(ProjectSetUpSteps).map(step => {
          return (
            <div
              key={step}
              className="row project-creation-step brada10 no-grow"
            >
              <div
                className={classList({
                  'row y-center pa10 w-max-content text large bold project-creation-step':
                    true,
                  active: projectCreationStep === ProjectSetUpSteps[step],
                })}
              >
                {ProjectSetUpSteps[step]}
              </div>
              <Icons.ChevronRight className="no-grow mx20" />
            </div>
          )
        })}
      </div>
    )
  }

  private stopPropagation(event: React.SyntheticEvent) {
    event.stopPropagation()
  }
}

import {
  IAddressBounds,
  IGeoJson2DGeographicCoordinates,
  ILatLng,
  ISitemap,
  ISitemapCircle,
  ISitemapCircleInput,
  ISitemapItemShapeInterface,
  ISitemapPin,
  ISitemapPinInput,
  ISitemapPolyline,
  ISitemapPolylineInput,
  ISitemapRectangle,
  ISitemapRectangleInput,
  ISitemapSpecificItemData,
  ISitemapSpecificItemDataCircleInput,
  ISitemapSpecificItemDataPolylineInput,
  ISitemapSpecificItemDataRectangleInput,
  ISitemapTextBox,
  ISitemapTextBoxInput,
  SitemapItemShapeType,
} from '~/client/graph'
import SitemapType from '~/client/src/shared/enums/SitemapType'
import Guard from '~/client/src/shared/utils/Guard'

import { copyObjectArray } from '../utils/util'
import BaseModel from './BaseModel'

export interface ISitemapGeoPosition {
  latitude: number
  longitude: number
  zoom: number
  bearing?: number
  pitch?: number
  altitude?: number
  bounds?
}

export default class Sitemap extends BaseModel<ISitemap> implements ISitemap {
  public static fromDto(dto: ISitemap) {
    return new Sitemap(
      dto.id,
      dto.name,
      dto.projectId,
      (dto.type as SitemapType) || SitemapType.Default,
      dto.basemapId,
      dto.filledImage,
      dto.itemsFilledImage,
      dto.isLabelsShown,
      dto.items || [],
      dto.isProjectOverviewMap,
      dto.createdAt,
      dto.updatedAt,
      dto.bounds,
      dto.center,
      dto.zoom,
      dto.altitude,
      dto.pitch,
      dto.bearing,
      dto.width,
      dto.height,
      dto.geoCorners,
    )
  }

  public items: ISitemapSpecificItemData[] = []

  public constructor(
    id: string,
    public name: string,
    public projectId: string,
    public type: SitemapType,
    public basemapId: string,
    public filledImage: string,
    public itemsFilledImage: string,
    public isLabelsShown: boolean = true,
    items: ISitemapSpecificItemData[] = [],
    public isProjectOverviewMap: boolean = false,
    createdAt: number = 0,
    updatedAt: number = 0,
    public bounds?: IAddressBounds,
    public center?: ILatLng,
    public zoom?: number,
    public altitude?: number,
    public pitch?: number,
    public bearing?: number,
    public width?: number,
    public height?: number,
    public geoCorners?: IGeoJson2DGeographicCoordinates[],
  ) {
    super(id)

    this.items = copyObjectArray(items)
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
    Guard.requireAll({ name, projectId, basemapId })
  }

  public get isReferenced(): boolean {
    return !!this.center?.lat && !!this.bounds
  }

  public getItemDisplayData = (
    sitemapItemId: string,
  ): ISitemapSpecificItemData => {
    return (
      this.findItemBySitemapItemId(sitemapItemId) ||
      ({} as ISitemapSpecificItemData)
    )
  }

  public hasDisplayData = (sitemapItemId: string): boolean => {
    return !!this.findItemBySitemapItemId(sitemapItemId)
  }

  public setIsProjectOverviewMap = (value: boolean) => {
    this.isProjectOverviewMap = value
  }

  public isItemDisplayed = (sitemapItemId: string): boolean => {
    const item = this.getItemDisplayData(sitemapItemId)
    return this.hasDisplayData(sitemapItemId) && !item.isHidden
  }

  public setItemDisplayData(
    sitemapItemId: string,
    data: ISitemapSpecificItemData,
  ) {
    const objIndex = this.findItemIndexBySitemapItemId(sitemapItemId)

    if (objIndex === -1) {
      this.items.push(data)
      return
    }

    this.items.splice(objIndex, 1, data)
  }

  public deleteItemDisplayData(sitemapItemId: string) {
    const objIndex = this.findItemIndexBySitemapItemId(sitemapItemId)

    if (objIndex !== -1) {
      this.items.splice(objIndex, 1)
    }
  }

  public toggleLabelsShown() {
    this.isLabelsShown = !this.isLabelsShown
  }

  public setGeoposition(
    geoposition: ISitemapGeoPosition,
    width?: number,
    height?: number,
    bounds?,
  ) {
    this.bounds = {
      ne: {
        lat: geoposition.bounds?._ne?.lat || geoposition.bounds?.ne?.lat,
        lng: geoposition.bounds?._ne?.lng || geoposition.bounds?.ne?.lng,
      },
      sw: {
        lat: geoposition.bounds?._sw?.lat || geoposition.bounds?.sw?.lat,
        lng: geoposition.bounds?._sw?.lng || geoposition.bounds?.sw?.lng,
      },
    }
    this.center = {
      lat: geoposition.latitude,
      lng: geoposition.longitude,
    }
    this.zoom = geoposition.zoom
    this.altitude = geoposition.altitude
    this.pitch = geoposition.pitch
    this.bearing = geoposition.bearing
    this.width = width
    this.height = height
    this.geoCorners = bounds
  }

  public setFilledImage(filledImage: string) {
    this.filledImage = filledImage
  }

  public setItemsFilledImage(itemsFilledImage: string) {
    this.itemsFilledImage = itemsFilledImage
  }

  public isNameEqual(name: string) {
    return this.name === name
  }

  public setName(name: string) {
    this.name = name
  }

  public getCopy() {
    return new Sitemap(
      this.id,
      this.name,
      this.projectId,
      this.type,
      this.basemapId,
      this.filledImage,
      this.itemsFilledImage,
      true,
      [],
      this.isProjectOverviewMap,
      null,
      null,
      this.bounds,
      this.center,
      this.zoom,
      this.altitude,
      this.pitch,
      this.bearing,
      this.width,
      this.height,
      this.geoCorners?.slice(),
    )
  }

  public getFullCopy(): Sitemap {
    const copy = this.getCopy()
    copy.updateFromJson(this.asJson)
    return copy
  }

  public get polylineItems(): ISitemapSpecificItemData[] {
    return this.getItemsByShapeType(SitemapItemShapeType.Polyline)
  }

  public get rectangleItems(): ISitemapSpecificItemData[] {
    return this.getItemsByShapeType(SitemapItemShapeType.Rectangle)
  }

  public get circleItems(): ISitemapSpecificItemData[] {
    return this.getItemsByShapeType(SitemapItemShapeType.Circle)
  }

  public get itemsWithoutShape(): ISitemapSpecificItemData[] {
    return this.items?.filter(i => !i.shape)
  }

  public get polylineItemDtos(): ISitemapSpecificItemDataPolylineInput[] {
    return this.convertItemsToDtos<ISitemapPolylineInput>(
      [...this.polylineItems, ...this.itemsWithoutShape],
      this.convertShapeToPolylineDto,
    )
  }

  public get rectangleItemDtos(): ISitemapSpecificItemDataRectangleInput[] {
    return this.convertItemsToDtos<ISitemapRectangleInput>(
      this.rectangleItems,
      this.convertShapeToRectangleDto,
    )
  }

  public get circleItemDtos(): ISitemapSpecificItemDataCircleInput[] {
    return this.convertItemsToDtos<ISitemapCircleInput>(
      this.circleItems,
      this.convertShapeToCircleDto,
    )
  }

  public getItemsByShapeType(
    shapeType: SitemapItemShapeType,
  ): ISitemapSpecificItemData[] {
    return this.items?.filter(({ shape }) => shape?.type === shapeType)
  }

  private findItemBySitemapItemId(itemId: string): ISitemapSpecificItemData {
    if (!itemId) {
      return null
    }

    return this.items?.find(({ sitemapItemId }) => sitemapItemId === itemId)
  }

  private findItemIndexBySitemapItemId(itemId: string): number {
    return this.items?.findIndex(
      ({ sitemapItemId }) => sitemapItemId === itemId,
    )
  }

  private convertItemsToDtos<ShapeInputType>(
    items: ISitemapSpecificItemData[],
    convertShapeFn: (shape: ISitemapItemShapeInterface) => ShapeInputType,
  ): any[] {
    return items.map(i => {
      return {
        sitemapItemId: i.sitemapItemId,
        isHidden: i.isHidden,
        icon: this.convertToSitemapPinDto(i.icon),
        label: this.convertToTextBoxDto(i.label),
        shape: convertShapeFn(i.shape as ISitemapItemShapeInterface),
      }
    })
  }

  private convertToSitemapPinDto(sitemapPin: ISitemapPin): ISitemapPinInput {
    if (!sitemapPin) {
      return null
    }

    return {
      position: sitemapPin.position && {
        x: sitemapPin.position.x,
        y: sitemapPin.position.y,
      },
      isHidden: sitemapPin.isHidden,
    }
  }

  private convertToTextBoxDto(textBox: ISitemapTextBox): ISitemapTextBoxInput {
    if (!textBox) {
      return null
    }

    return {
      fontSize: textBox.fontSize,
      isTextBoxDisplayed: textBox.isTextBoxDisplayed,
      position: textBox.position && {
        x: textBox.position.x,
        y: textBox.position.y,
      },
      isHidden: textBox.isHidden,
      color: textBox.color,
    }
  }

  private convertShapeToPolylineDto(
    shape: ISitemapPolyline,
  ): ISitemapPolylineInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      isClosed: shape.isClosed,
      points: shape.points?.map(p => {
        return { x: p.x, y: p.y }
      }),
      arrowPosition: shape.arrowPosition,
      isDisplayed: shape.isDisplayed,
    }
  }

  private convertShapeToRectangleDto(
    shape: ISitemapRectangle,
  ): ISitemapRectangleInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      position: shape.position && {
        x: shape.position.x,
        y: shape.position.y,
      },
      width: shape.width,
      height: shape.height,
      rotation: shape.rotation,
      isDisplayed: shape.isDisplayed,
    }
  }

  private convertShapeToCircleDto(shape: ISitemapCircle): ISitemapCircleInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      radius: shape.radius,
      position: shape.position && {
        x: shape.position.x,
        y: shape.position.y,
      },
      isDivided: shape.isDivided,
      divisionStartAngle: shape.divisionStartAngle,
      divisionEndAngle: shape.divisionEndAngle,
      isDisplayed: shape.isDisplayed,
    }
  }
}

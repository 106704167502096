import { LocationType } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import LocationAttributeBase from './LocationAttributeBase'

export default class Gate extends LocationAttributeBase<Gate> {
  public static fromDto(dto: ILocationAttributeDto) {
    return LocationAttributeBase.factory(Gate, dto, SitemapAttributeIcon.Gate)
  }

  public type = LocationType.Gate
  protected relatedFieldId = FieldIds.GATE
  protected model = Gate
}

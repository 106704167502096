import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 20

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const { Crane, Hoist, AerialLift, Gradall, ForkLift } = SitemapAttributeIcon

const ICON_BY_TYPE = {
  [Crane]: {
    PATH: 'M9.75 19.5H11.25C11.4489 19.5 11.6397 19.579 11.7803 19.7197C11.921 19.8603 12 20.0511 12 20.25C12 20.4489 11.921 20.6397 11.7803 20.7803C11.6397 20.921 11.4489 21 11.25 21H4.5C4.30109 21 4.11032 20.921 3.96967 20.7803C3.82902 20.6397 3.75 20.4489 3.75 20.25C3.75 20.0511 3.82902 19.8603 3.96967 19.7197C4.11032 19.579 4.30109 19.5 4.5 19.5H6V9H3.75C3.55109 9 3.36032 8.92098 3.21967 8.78033C3.07902 8.63968 3 8.44891 3 8.25V6.58575C3.00005 6.41845 3.05603 6.25597 3.15904 6.12415C3.26205 5.99233 3.40618 5.89874 3.5685 5.85825L6 5.25V3.75C6 3.55109 6.07902 3.36032 6.21967 3.21967C6.36032 3.07902 6.55109 3 6.75 3H9C9.19891 3 9.38968 3.07902 9.53033 3.21967C9.67098 3.36032 9.75 3.55109 9.75 3.75V19.5ZM20.3032 6L11.25 5.35725V9H20.25C20.4489 9 20.6397 8.92098 20.7803 8.78033C20.921 8.63968 21 8.44891 21 8.25V6.75C21.0005 6.55998 20.9288 6.37686 20.7995 6.23764C20.6701 6.09842 20.4928 6.01349 20.3032 6ZM20.25 14.25H18.75V10.5H17.25V14.25H15.75C15.5511 14.25 15.3603 14.329 15.2197 14.4697C15.079 14.6103 15 14.8011 15 15V17.25C15 17.4489 15.079 17.6397 15.2197 17.7803C15.3603 17.921 15.5511 18 15.75 18H20.25C20.4489 18 20.6397 17.921 20.7803 17.7803C20.921 17.6397 21 17.4489 21 17.25V15C21 14.8011 20.921 14.6103 20.7803 14.4697C20.6397 14.329 20.4489 14.25 20.25 14.25Z',
    SCALE: 0.75,
  },
  [Hoist]: {
    PATH: 'M6,5L17,5 17,16 6,16z M10.5 1.5 L10.5 23.5 M14.5 1.5 L14.5 23.5 M10.707 19L14.5 22.5 M14.5 18.5L10.5 22.5 M10.707 14.5L14.5 18 M14.5 14L10.5 18 M10.707 9.5L14.5 13 M14.5 9L10.5 13 M10.707 4.5L14.5 8 M14.5 4L10.5 8',
    SCALE: 0.75,
    OFFSET_Y: 1,
  },
  [AerialLift]: {
    PATH: 'M3 3.75C3 3.19772 3.44772 2.75 4 2.75H20C20.5523 2.75 21 3.19772 21 3.75V9.75C21 10.3023 20.5523 10.75 20 10.75H17.5675L13.7348 13.4503L16.7713 15.5896C17.2228 15.9076 17.3309 16.5315 17.0128 16.983C16.6947 17.4345 16.0709 17.5426 15.6194 17.2245L11.9985 14.6735L8.37769 17.2245C7.9262 17.5426 7.30234 17.4345 6.98425 16.983C6.66617 16.5315 6.77431 15.9076 7.22579 15.5896L10.2623 13.4503L6.42954 10.75H4C3.44772 10.75 3 10.3023 3 9.75V3.75ZM11.9985 12.227L14.095 10.75H9.90209L11.9985 12.227ZM5 5H19V9H5V5ZM5 21C6.10457 21 7 20.1046 7 19C7 17.8954 6.10457 17 5 17C3.89543 17 3 17.8954 3 19C3 20.1046 3.89543 21 5 21ZM19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21ZM8 19C8 18.4477 8.44772 18 9 18H15C15.5523 18 16 18.4477 16 19C16 19.5523 15.5523 20 15 20H9C8.44772 20 8 19.5523 8 19Z',
    SCALE: 0.7,
  },
  [Gradall]: {
    PATH: 'M5 22C6.10457 22 7 21.1046 7 20C7 18.8954 6.10457 18 5 18C3.89543 18 3 18.8954 3 20C3 21.1046 3.89543 22 5 22Z M17 22C18.1046 22 19 21.1046 19 20C19 18.8954 18.1046 18 17 18C15.8954 18 15 18.8954 15 20C15 21.1046 15.8954 22 17 22Z M7 20H15 M3 20V17H19V20 M6 16V11H10.5714L14 16 M3.00028 17.0001L1.00028 12.5001L19.5003 4.00017L19.5003 9.50006L21.9998 9.5',
    SCALE: 0.72,
  },
  [ForkLift]: {
    PATH: 'M5 19C6.10457 19 7 18.1046 7 17C7 15.8954 6.10457 15 5 15C3.89543 15 3 15.8954 3 17C3 18.1046 3.89543 19 5 19Z M14 19C15.1046 19 16 18.1046 16 17C16 15.8954 15.1046 15 14 15C12.8954 15 12 15.8954 12 17C12 18.1046 12.8954 19 14 19Z M7 17H12 M3 17V11H16V17 M5 11V7H9 M9 11V5H13L16 11 M22 15H19V5 M16 13H19',
    SCALE: 0.78,
    OFFSET_X: 1.5,
  },
}

const STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaEquipmentTypeIcon = ({ color, type, ...rest }) => {
  const path = ICON_BY_TYPE[type].PATH
  const SCALE = ICON_BY_TYPE[type].SCALE || 0.9
  const OFFSET_X = ICON_BY_TYPE[type].OFFSET_X || 2
  const OFFSET_Y = ICON_BY_TYPE[type].OFFSET_Y || 2
  const halfSize = ICON_SIZE / 2

  const isFilled = [Crane, AerialLift].includes(type)

  if (!path) {
    return null
  }

  return (
    <Group {...rest} offsetX={halfSize} offsetY={halfSize}>
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={path}
        fill={getHEXColor(isFilled ? Colors.neutral100 : null)}
        stroke={getHEXColor(isFilled ? null : Colors.neutral100)}
        scaleX={SCALE}
        scaleY={SCALE}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={OFFSET_X}
        y={OFFSET_Y}
      />
    </Group>
  )
}

export default KonvaEquipmentTypeIcon

import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { AutoSizer, MultiGrid } from 'react-virtualized'

import Checkbox from '~/client/src/shared/components/Checkbox'
import SelectionPopUp, {
  SelectionPopUpOption,
} from '~/client/src/shared/components/SelectionPopUp/SelectionPopUp'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import { NOOP } from '~/client/src/shared/utils/noop'
import {
  getBackgroundColor,
  getTextColor,
} from '~/client/src/shared/utils/tableColoring'

import Localization from '../../localization/LocalizationManager'
import KnownTranslatorKeys from '../../localization/knownTranslatorKeys'
import LocationBase from '../../models/LocationObjects/LocationBase'
import SitemapAttributeTag from '../SitemapAttributeTag/SitemapAttributeTag'
import TableSeparators from '../TableSeparators'
import BaseListWithFixedColumns, {
  IBaseListWithFixedColumns,
  NO_SCROLL,
  SCROLLING_RESET_TIME_INTERVAL,
} from './BaseListWithFixedColumns'
import TableHeaderCell from './components/TableHeaderCell'

import './ListWithFixedColumns.scss'

export enum BasicDataKeys {
  ID = 'id',
  CHECKBOX = 'checkbox',
}

type CellRenderers = { [columnKey: string]: (value: any) => JSX.Element }

export type ILWFCCategory = {
  categoryId: string
  categoryLabel: string
  isChecked: boolean
  shortCategoryLabel?: string
  instancesInfo?: string
  idsToSelect?: string[]
}

export type LWFCRowData = { [key: string]: any }

export interface ILWFCRow {
  category?: ILWFCCategory
  data: LWFCRowData
  height?: number
  level?: number
}

export interface ILWFCColumn {
  dataKey: string
  label?: string
  width?: number
  isMonospace?: boolean
  translatorKey?: KnownTranslatorKeys
  sectionName?: string
  isHidden?: boolean
  isFixed?: boolean
}

interface IGroupedListWithFixedColumns extends IBaseListWithFixedColumns {
  rows: ILWFCRow[]
  columns: ILWFCColumn[]
  columnsWidthState: Map<string, number>
  fixedColumnsCount: number
  columnsCount: number
  collapsedCategories: Map<string, boolean>
  groupingKey: string
  sortedColumnKey?: string
  sortingOrder?: SortOrder
  onColumnSort?: (columnKey: string) => void
  onCellClick?: (rowData: LWFCRowData, columnKey: string) => void
  onCategoryCheckboxToggle?: (category: ILWFCCategory) => void
  onCategoryCollapsingToggle?: (categoryId: string) => void
  columnKeyToCellRenderer?: CellRenderers
  getCellClassNames?: (
    columnKey: string,
    data?: LWFCRowData,
  ) => { [className: string]: boolean }
  selectAll?: () => void
  resetAll?: () => void
  selectAllTittle?: string
  scrollToRow?: number
}

const { CARET_DOWN, CARET_RIGHT, ARROW_UP, ARROW_DOWN } = IconNames
const SORT_INDICATION_ICON_SIZE = 8
const DEFAULT_COLUMN_WIDTH = 100
const DEFAULT_ROW_HEIGHT = 47
const CATEGORY_LABEL_COLUMN_INDEX = 1
const DEFAULT_SELECT_ALL_TITTLE = 'Select all'
const MIN_COLUMN_WIDTH = 100
const MIN_CHECKBOX_COLUMN_WIDTH = 40

const isSeparatorColumn = (columnIndex: number): boolean => columnIndex === 1

@observer
export default class GroupedListWithFixedColumns extends BaseListWithFixedColumns<IGroupedListWithFixedColumns> {
  public static defaultProps = {
    columnKeyToCellRenderer: {},
    getCellClassNames: NOOP,
    sortedColumnKey: null,
    sortingOrder: SortOrder.DEFAULT,
    selectAll: NOOP,
    resetAll: NOOP,
    onColumnSort: NOOP,
    onCategoryCollapsingToggle: NOOP,
    selectAllTittle: DEFAULT_SELECT_ALL_TITTLE,
    scrollToRow: NO_SCROLL,
  }

  private columnKeyToDefaultRenderer: CellRenderers = {
    [BasicDataKeys.CHECKBOX]: value => (
      <Checkbox
        className="checkbox-selector text large no-bold"
        isCentered={true}
        isChecked={value}
      />
    ),
  }

  public constructor(props: IGroupedListWithFixedColumns) {
    super(props)

    this.scrollToColumn = props.fixedColumnsCount
    this.scrollToRow = props.scrollToRow
  }

  public componentDidUpdate(prevProps: Readonly<IGroupedListWithFixedColumns>) {
    if (prevProps.scrollToRow !== this.props.scrollToRow) {
      this.scrollToRow = this.props.scrollToRow
    }

    if (
      prevProps.rows.length !== this.props.rows.length ||
      prevProps.groupingKey !== this.props.groupingKey
    ) {
      this.recomputeGridSize()
    }
  }

  @computed
  private get selectionPopUpOptions(): SelectionPopUpOption[] {
    return [
      {
        label: this.props.selectAllTittle,
        onClick: this.handleSelectAll,
      },
      {
        label: Localization.translator.clear,
        onClick: this.handleResetAll,
      },
    ]
  }

  protected renderSelectionPopUp(): JSX.Element {
    return (
      <SelectionPopUp
        isDisplayed={this.shouldShowSelectionPopup}
        options={this.selectionPopUpOptions}
      />
    )
  }

  protected renderGrid(): JSX.Element {
    const { rows, columns, fixedColumnsCount } = this.props
    const shownColumns = columns.filter(column => !column.isHidden)

    const { scrollToColumn, scrollToRow } = this
    return (
      <AutoSizer>
        {({ width, height }) => (
          <MultiGrid
            width={width}
            height={height}
            rowHeight={this.getRowHeight}
            ref={ref => (this.grid = ref)}
            fixedColumnCount={fixedColumnsCount}
            scrollToColumn={scrollToColumn}
            fixedRowCount={1}
            scrollToRow={scrollToRow}
            cellRenderer={this.cellRenderer}
            columnWidth={this.getColumnWidth}
            enableFixedColumnScroll={true}
            enableFixedRowScroll={false}
            rowCount={rows.length}
            columnCount={shownColumns.length}
            onScroll={this.onScroll}
            scrollingResetTimeInterval={SCROLLING_RESET_TIME_INTERVAL}
            hideTopRightGridScrollbar={true}
            hideBottomLeftGridScrollbar={true}
            styleBottomRightGrid={{ outline: 'none' }}
          />
        )}
      </AutoSizer>
    )
  }

  private renderDataCell = (
    { data, level }: ILWFCRow,
    column: ILWFCColumn,
    isEdge: boolean,
    gridProps: any,
    columnIndex: number,
  ) => {
    const { columnKeyToCellRenderer, getCellClassNames } = this.props
    const { dataKey: columnKey, isMonospace } = column
    const value = data[columnKey]

    const renderer =
      columnKeyToCellRenderer[columnKey] ||
      this.columnKeyToDefaultRenderer[columnKey] ||
      (() => value)

    const element = renderer(value)
    const isTableSeparatorCol = isSeparatorColumn(columnIndex)

    return (
      <div
        {...gridProps}
        title={this.toTitle(element)}
        onClick={this.handleCellClick.bind(null, data, columnKey)}
        className={classList({
          'row bb-light-input-border cell text large': true,
          'br-light-cool-grey': isEdge,
          'gant-selection': data[BasicDataKeys.CHECKBOX],
          'no-padding': isTableSeparatorCol,
          monospace: isMonospace,
          ...getCellClassNames(columnKey, data),
        })}
      >
        {isTableSeparatorCol && (
          <TableSeparators
            level={level}
            className="no-grow full-height br-palette-grey ml10"
          />
        )}
        <div
          className={classList({
            'text-ellipsis': true,
            ml10: isTableSeparatorCol,
          })}
        >
          {element}
        </div>
      </div>
    )
  }

  private cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const props = { key, style }
    const { rows, columns } = this.props

    if (!rows.length) return

    const column = columns[columnIndex]

    if (column.isHidden) return

    if (!rowIndex) {
      return this.renderHeaderCell(column, columnIndex, style)
    }

    const row = rows[rowIndex]
    const isEdge = this.isColumnEdgeOfFixedColumns(columnIndex)

    return row.category
      ? this.renderCategoryCell(row, column, isEdge, columnIndex, props)
      : this.renderDataCell(row, column, isEdge, props, columnIndex)
  }

  private getColumnWidth = ({ index }): number => {
    const column = this.props.columns[index]

    return (column && column.width) || DEFAULT_COLUMN_WIDTH
  }

  private getRowHeight = ({ index }) => {
    const row = this.props.rows[index]
    return (row && row.height) || DEFAULT_ROW_HEIGHT
  }

  private renderHeaderCell(
    { dataKey, translatorKey, label }: ILWFCColumn,
    columnIndex: number,
    style: React.CSSProperties,
  ) {
    const { onColumnSort, sortedColumnKey, getCellClassNames, sortingOrder } =
      this.props
    const labelText = translatorKey
      ? Localization.getText(translatorKey)
      : label || ''

    return (
      <TableHeaderCell
        key={columnIndex}
        dataKey={dataKey}
        label={labelText}
        columnIndex={columnIndex}
        toggleSelectionPopup={this.toggleSelectionPopup}
        getCellClassNames={getCellClassNames}
        onColumnSort={onColumnSort}
        sortedColumnKey={sortedColumnKey}
        sortingOrder={sortingOrder}
        isResizable={true}
        onResizeColumn={this.onResizeColumn}
        style={style}
      />
    )
  }

  @action.bound
  private onResizeColumn({ columnIndex, deltaX }) {
    const { columns } = this.props
    const column = columns[columnIndex]

    const minWidth =
      column.dataKey === BasicDataKeys.CHECKBOX
        ? MIN_CHECKBOX_COLUMN_WIDTH
        : MIN_COLUMN_WIDTH
    const width = Math.max(minWidth, column.width + deltaX)
    this.props.columnsWidthState.set(column.dataKey, width)

    Promise.resolve().then(() => {
      this.recomputeGridSize()
      this.grid.forceUpdate()
    })
  }

  private renderHeaderLabel(label: string, isColumnSorted: boolean) {
    if (!isColumnSorted) {
      return label
    }

    const { sortingOrder } = this.props

    return (
      <>
        {label}
        <Icon
          className="pl2"
          iconSize={SORT_INDICATION_ICON_SIZE}
          icon={sortingOrder === SortOrder.ASC ? ARROW_DOWN : ARROW_UP}
        />
      </>
    )
  }

  private renderCategoryCell(
    { category, data, level }: ILWFCRow,
    { dataKey }: ILWFCColumn,
    isEdge: boolean,
    columnIndex: number,
    { key, style }: { key: React.Key; style: any },
  ) {
    const { categoryId, categoryLabel, isChecked } = category
    const shouldShowCategoryLabel = this.isColumnForCategoryLable(columnIndex)
    const {
      collapsedCategories,
      getCellClassNames,
      onCategoryCollapsingToggle,
    } = this.props

    let cellContent = null

    const location = data as LocationBase

    const backgroundColor = getBackgroundColor(categoryId, location)
    const textColor = getTextColor(categoryId)

    switch (true) {
      case shouldShowCategoryLabel:
        const categoryIcon = collapsedCategories.get(categoryId)
          ? CARET_RIGHT
          : CARET_DOWN

        cellContent = (
          <>
            <TableSeparators
              level={level}
              className="no-grow full-height br-palette-grey ml10"
            />
            <div className="row">
              <Icon className="pointer ml3 mr5 no-grow" icon={categoryIcon} />
              <SitemapAttributeTag
                shouldShowAsTag={false}
                contentContainerClassName="text-ellipsis py2 row name-cell"
                dataObject={location}
              >
                {categoryLabel}
              </SitemapAttributeTag>
            </div>
          </>
        )
        break
      case dataKey === BasicDataKeys.CHECKBOX:
        cellContent = (
          <Checkbox
            className="checkbox-selector text large no-bold"
            isChecked={isChecked}
            onClick={this.handleCategoryCheckboxToggle.bind(null, category)}
          />
        )
        break
    }

    return (
      <div
        key={key}
        style={{ ...style, backgroundColor, color: textColor }}
        onClick={onCategoryCollapsingToggle.bind(null, categoryId)}
        className={classList({
          'row bb-palette-grey text large bold': true,
          'cell category-cell no-select pointer': true,
          'br-light-cool-grey': isEdge,
          'category-cell-name': shouldShowCategoryLabel,
          'no-padding': isSeparatorColumn(columnIndex),
          ...getCellClassNames(dataKey),
        })}
      >
        {cellContent}
      </div>
    )
  }

  private isColumnEdgeOfFixedColumns(columnIndex: number) {
    return columnIndex === this.props.fixedColumnsCount - 1
  }

  private isColumnForCategoryLable(columnIndex: number) {
    return columnIndex === CATEGORY_LABEL_COLUMN_INDEX
  }

  @action.bound
  private handleCategoryCheckboxToggle(
    category: ILWFCCategory,
    e: React.MouseEvent<HTMLElement>,
  ) {
    e.stopPropagation()
    this.props.onCategoryCheckboxToggle(category)
  }

  @action.bound
  private handleCellClick(row: LWFCRowData, columnKey: string) {
    this.props.onCellClick(row, columnKey)
  }

  @action.bound
  private handleSelectAll() {
    this.props.selectAll()
    this.hideSelectionPopup()
  }

  @action.bound
  private handleResetAll() {
    this.props.resetAll()
    this.hideSelectionPopup()
  }

  @action
  private hideSelectionPopup() {
    this.shouldShowSelectionPopup = false
  }

  private toTitle(value: any) {
    return typeof value === 'string' ? value : undefined
  }
}

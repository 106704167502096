import { action, observable } from 'mobx'

import { IStringPair, LocationType } from '~/client/graph'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

import FieldIds from '../../enums/DeliveryFieldIds'
import ILocationDto from '../../types/ILocationDto'
import LocationBase from './LocationBase'

type IIntegrationMetadata = {
  [key in KnownLocationIntegrationMetadataKeys]?: string
}

export enum LocationIntegrationType {
  MaturixStation = 'MaturixStation',
  None = 'None',
}

export function getIntegrationType(integrationType: string) {
  switch (integrationType) {
    case LocationIntegrationType.MaturixStation:
      return LocationIntegrationType.MaturixStation
    default:
      return LocationIntegrationType.None
  }
}

export enum KnownLocationIntegrationMetadataKeys {
  Type = 'Type',
  CastId = 'CastId',
  MonitoringId = 'MonitoringId',
  Status = 'Status',
}

const knownLocationIntegrationMetadataKeys = Object.values(
  KnownLocationIntegrationMetadataKeys,
)

interface IIntegrationDto extends ILocationDto {
  metadata?: Array<IStringPair>
}

export default class LocationIntegration extends LocationBase<LocationIntegration> {
  public static fromDto(dto: IIntegrationDto) {
    const item = LocationBase.factory(
      LocationIntegration,
      dto,
      SitemapAttributeIcon.Monitoring,
    )
    dto.metadata.forEach(({ key, value }) => {
      if (key === KnownLocationIntegrationMetadataKeys.Type) {
        item.integrationType = getIntegrationType(value)
        if (item.integrationType === LocationIntegrationType.MaturixStation) {
          item.relatedFieldId = FieldIds.MATURIX_STATION
        }
      } else if (
        knownLocationIntegrationMetadataKeys.includes(
          key as KnownLocationIntegrationMetadataKeys,
        )
      ) {
        item.metadata[key] = value
      }
    })
    return item
  }

  public type = LocationType.Integration
  public relatedFieldId = FieldIds.INTEGRATION
  protected model = LocationIntegration

  @observable public metadata: IIntegrationMetadata = {}
  @observable public integrationType: LocationIntegrationType

  public is(type: LocationType | LocationIntegrationType): boolean {
    return this.integrationType === type || this.type === type
  }

  public isEqual(dto: LocationIntegration) {
    return (
      super.isEqual(dto) &&
      this.integrationType === dto.integrationType &&
      JSON.stringify(this.metadata) === JSON.stringify(dto.metadata)
    )
  }

  public copy(): LocationIntegration {
    const item = super.copy()
    item.metadata = JSON.parse(JSON.stringify(this.metadata))
    item.integrationType = this.integrationType

    return item
  }

  @action
  public getDto(): IIntegrationDto {
    const dto: IIntegrationDto = super.getDto()
    dto.metadata = []
    dto.metadata.push({
      key: KnownLocationIntegrationMetadataKeys.Type,
      value: this.integrationType,
    })
    Object.entries(this.metadata).forEach(([key, value]) => {
      dto.metadata.push({ key, value })
    })

    return dto
  }
}

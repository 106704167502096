import * as React from 'react'

import { IReactionDisposer, action, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import * as Icons from '~/client/src/shared/components/Icons'
import LogisticsSitemap from '~/client/src/shared/components/LogisticsSitemap/LogisticsSitemap'
import LogisticsSitemapSetUpStore from '~/client/src/shared/components/LogisticsSitemap/LogisticsSitemapSetUp.store'
import SitemapsGallery from '~/client/src/shared/components/SitemapsGallery/SitemapsGallery'
import ConfirmDialogTypes from '~/client/src/shared/enums/ConfirmDialogTypes'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import {
  DELETE_ANNOUNCEMENTS,
  SAVE_LOGISTICS_CONFIGURATIONS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaturixProjectsStore from '~/client/src/shared/stores/domain/MaturixStores/MaturixProjects.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import LogisticsStore from '../../Logistics.store'
import ConfirmAnnouncementDialog from '../ConfirmAnnouncementDialog'
import LogisticsSideBar from '../LogisticsSideBar'
import LogisticsMapStore from './LogisticsMap.store'

interface IProps {
  store: LogisticsStore
  logisticsMapStore: LogisticsMapStore
  isCreationAvailable: boolean

  shouldShowEntity?: boolean
  entityOrder?: number
  isPermitOnly?: boolean

  state?: DesktopInitialState
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
  tagsStore?: TagsStore
  deliveryDetailsStore?: DeliveryDetailsStore
  activitiesStore?: ActivitiesStore
  maturixProjectsStore?: MaturixProjectsStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
}

const showFor = 'Show for'

@inject(
  'state',
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'locationAttributesStore',
  'tagsStore',
  'deliveryDetailsStore',
  'activitiesStore',
  'maturixProjectsStore',
  'companiesStore',
  'permitTypesStore',
)
@observer
export default class LogisticsMap extends React.Component<IProps> {
  public readonly store: LogisticsSitemapSetUpStore = null
  private textboxesCache: ICanvasImageCache = {}
  private disposeDisplayedPermitIdReaction: IReactionDisposer
  private disposeDisplayedDeliveryIdReaction: IReactionDisposer
  private disposeDisplayedActivityIdReaction: IReactionDisposer

  public constructor(props: IProps) {
    super(props)

    this.store = new LogisticsSitemapSetUpStore(
      props.sitemapsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
      props.tagsStore,
      props.state,
      () => props.store.displayedPermitId,
    )
  }

  public componentDidMount() {
    const { changeDatePickerMode, setInitialDateValue } = this.props.store
    changeDatePickerMode(TwoMonthsDatePickerMode.ONE_DAY_DEFAULT_TODAY)
    setInitialDateValue()

    this.disposeDisplayedPermitIdReaction = reaction(
      () => this.props.store.displayedPermitId,
      displayedPermitId => {
        if (!displayedPermitId) {
          this.store.deselectPermits()
        }
      },
      { fireImmediately: true },
    )
    this.disposeDisplayedDeliveryIdReaction = reaction(
      () => this.props.deliveryDetailsStore.displayedDelivery,
      displayedDelivery => {
        if (!displayedDelivery) {
          this.store.deselectDeliveries()
        }
      },
      { fireImmediately: true },
    )
    this.disposeDisplayedActivityIdReaction = reaction(
      () => this.props.activitiesStore.selectedActivity,
      selectedActivity => {
        if (!selectedActivity) {
          this.store.deselectActivities()
        }
      },
      { fireImmediately: true },
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      this.props.shouldShowEntity &&
      prevProps.entityOrder !== this.props.entityOrder
    ) {
      this.props.store.hideAnnouncementDialog()
      const announcement =
        this.props.logisticsMapStore.orderedAnnouncements[
          this.props.entityOrder
        ]
      if (announcement) {
        this.props.store.showAnnouncementContent(announcement)
      }
    }
  }

  public componentWillUnmount() {
    this.textboxesCache = {}
    this.disposeDisplayedPermitIdReaction?.()
    this.disposeDisplayedDeliveryIdReaction?.()
    this.disposeDisplayedActivityIdReaction?.()
  }

  public render() {
    const { store, state, isPermitOnly } = this.props
    const {
      removeAnnouncements,
      selectedAnnouncements,
      shouldShowConfirmDeleteDialog,
    } = store

    const areAnnouncementsBeingDeleted = state.loading.get(DELETE_ANNOUNCEMENTS)

    return (
      <>
        <div className="row full-height relative">{this.content}</div>
        {!isPermitOnly && (
          <ConfirmAnnouncementDialog
            type={ConfirmDialogTypes.DELETE}
            isOpen={shouldShowConfirmDeleteDialog}
            loading={areAnnouncementsBeingDeleted}
            closeDialog={this.hideConfirmDeleteDialog}
            performItems={removeAnnouncements}
            itemsToPerform={selectedAnnouncements}
          />
        )}
      </>
    )
  }

  private get content() {
    const { store, isPermitOnly, logisticsMapStore, isCreationAvailable } =
      this.props
    const {
      logisticsSitemapsIds,
      selectedSitemap,
      openPermitCreationForm,
      showCreationAndEditionAnnouncementForm: showAnnouncementContent,
    } = store

    return (
      <>
        <LogisticsSideBar
          store={store}
          mapStore={logisticsMapStore}
          logisticsSitemapSetUpStore={this.store}
          selectedSitemap={selectedSitemap}
          isPermitOnly={isPermitOnly}
        />
        <div className="col full-height">
          <div className="full-height relative">
            <SitemapsGallery
              isHideButtonDisplayed={false}
              sitemapsIds={logisticsSitemapsIds}
              SelectedSitemapComponent={this.SitemapComponent}
              eventName={SAVE_LOGISTICS_CONFIGURATIONS}
              selectSitemap={this.selectSitemapAndCloseModals}
              areArrowsLeftSided={true}
              shouldUseFullHeight={true}
              shouldDisableZoom={true}
              shouldShowCreateNewButton={isCreationAvailable}
              FileInputType={DesktopFileInput}
              openPermitCreationForm={openPermitCreationForm}
              openAnnouncementCreationForm={
                !isPermitOnly && showAnnouncementContent
              }
              mapboxEditorStore={this.store.mapBoxEditorStore}
              isCreationLimited={isPermitOnly}
            />
          </div>
        </div>
      </>
    )
  }

  private get SitemapComponent() {
    const {
      showAnnouncementContent,
      showPermitViewOrApprovalDialog,
      displayDeliverySideView,
      displayActivitySideView,
      showRemoveDialog,
      arePermitsHidden,
      areAnnouncementsHidden,
      areDeliveriesHidden,
      areActivitiesHidden,
      areMonitoringsHidden,
      toggleAnnouncementsHiddenState,
      toggleDeliveriesHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
    } = this.props.store

    return props => (
      <LogisticsSitemap
        {...props}
        store={this.store}
        mapBoxEditorStore={this.store.mapBoxEditorStore}
        logisticsStore={this.props.store}
        logisticsMapStore={this.props.logisticsMapStore}
        textboxesCache={this.textboxesCache}
        openAnnouncement={showAnnouncementContent}
        openPermit={showPermitViewOrApprovalDialog}
        openDelivery={displayDeliverySideView}
        openActivity={displayActivitySideView}
        deleteAnnouncement={showRemoveDialog}
        arePermitsHidden={arePermitsHidden}
        areAnnouncementsHidden={areAnnouncementsHidden}
        areDeliveriesHidden={areDeliveriesHidden}
        areActivitiesHidden={areActivitiesHidden}
        areMonitoringsHidden={areMonitoringsHidden}
        renderShowOnMenu={this.renderShowOnMenu}
        toggleAnnouncementsHiddenState={toggleAnnouncementsHiddenState}
        toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
        togglePermitsHiddenState={togglePermitsHiddenState}
        toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
        isPermitOnly={this.props.isPermitOnly}
      />
    )
  }

  @action.bound
  private hideConfirmDeleteDialog() {
    this.props.store.shouldShowConfirmDeleteDialog = false
  }

  private renderShowOnMenu = () => {
    const {
      toggleDeliveriesHiddenState,
      toggleAnnouncementsHiddenState,
      togglePermitsHiddenState,
      toggleActivitiesHiddenState,
      toggleMonitoringsHiddenState,
      areDeliveriesHidden,
      areAnnouncementsHidden,
      arePermitsHidden,
      areActivitiesHidden,
      areMonitoringsHidden,
    } = this.props.store

    return (
      <>
        <div className="show-on-text-box text lp15 small grey uppercase pa5 br-light-input-border row y-center nowrap no-grow">
          <Icons.RoundedLocation className="shown-on-icon no-grow mr5" />
          {showFor}
        </div>
        <div className="show-on-icons-box row">
          <div
            className={classList({
              'icon-holder row y-center x-center no-grow mx5': true,
              'de-selected': areAnnouncementsHidden,
            })}
          >
            <Icons.SidebarAnnouncement
              className="no-grow"
              onClick={toggleAnnouncementsHiddenState}
            />
          </div>
          <div
            className={classList({
              'icon-holder row y-center x-center no-grow mx5': true,
              'de-selected': arePermitsHidden,
            })}
          >
            <Icons.GeneralForm
              className="row no-grow permit-icon text white"
              onClick={togglePermitsHiddenState}
            />
          </div>
          <div
            className={classList({
              'icon-holder row y-center x-center no-grow mx5': true,
              'de-selected': areDeliveriesHidden,
            })}
          >
            <Icons.Delivery
              className="row no-grow delivery-icon"
              onClick={toggleDeliveriesHiddenState}
            />
          </div>
          <div
            className={classList({
              'icon-holder row y-center x-center no-grow mx5': true,
              'de-selected': areActivitiesHidden,
            })}
          >
            <Icons.Activities
              className="row no-grow activity-icon"
              onClick={toggleActivitiesHiddenState}
            />
          </div>
          {this.props.maturixProjectsStore.activeProjectId && (
            <div
              className={classList({
                'icon-holder row y-center x-center no-grow mx5': true,
                'de-selected': areMonitoringsHidden,
              })}
            >
              <Icons.Sensor
                className="row no-grow activity-icon"
                onClick={toggleMonitoringsHiddenState}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  @action.bound
  private selectSitemapAndCloseModals(sitemapIndex: number) {
    this.props.store.selectSitemap(sitemapIndex)
    this.store.deselectAll()
  }
}

import { action, computed } from 'mobx'

import { IMutation, IScanner, IScannerInput } from '~/client/graph'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import Scanner from '../../models/Scanner'

export default class ScannersStore {
  public constructor(private readonly eventsStore: EventsStore) {}

  public get isDataReceived(): boolean {
    return !this.appState.loading.get(e.LOAD_AND_LISTEN_TO_SCANNERS)
  }

  public get sourceMap(): Map<string, Scanner> {
    return this.appState.scanners
  }

  @computed
  public get scannersList(): Scanner[] {
    return this.list.filter(item => !item.isInverted)
  }

  @action.bound
  public save(sc: IScannerInput, callback?: (id: string) => void) {
    this.saveMany([sc]).then(([c]) => callback?.(c.id))
  }

  public getScannerById = (
    scannerId: string,
    skipDeleted: boolean = false,
  ): Scanner => {
    const scanner = this.sourceMap.get(scannerId)

    if (!scanner) {
      return null
    }

    return skipDeleted && scanner.isDeleted ? null : scanner
  }

  @action.bound
  public saveMany(scanners: IScanner[]): Promise<IScanner[]> {
    return new Promise((resolve, reject) =>
      this.eventsStore.dispatch(e.SAVE_SCANNERS, scanners, resolve, reject),
    )
  }

  @action.bound
  public async delete(scannerId: string, callback?: () => void) {
    await this.deleteMany([scannerId])
    this.sourceMap.delete(scannerId)
    callback?.()
  }

  @action.bound
  public async deleteMany(scannerIds: string[]) {
    const { deleteManyScanners: isOk } = await new Promise<IMutation>(
      (res, rej) =>
        this.eventsStore.dispatch(e.DELETE_SCANNERS, scannerIds, res, rej),
    )

    if (!isOk) {
      throw new Error()
    }
  }

  public clearList() {
    this.sourceMap.clear()
  }

  public updateFromList(list: IScanner[]) {
    list.forEach(dto => {
      const scanner = Scanner.fromDto(dto)
      this.sourceMap.set(scanner.id, scanner)
    })
  }

  public receiveOne(id: string, dto: IScanner) {
    if (dto) {
      const scanner = Scanner.fromDto(dto)
      this.sourceMap.set(id, scanner)
    }
  }

  public receiveList(dtos: IScanner[]) {
    this.clearList()

    dtos.forEach(dto => {
      const scanner = Scanner.fromDto(dto)
      this.sourceMap.set(dto.id, scanner)
    })
  }

  protected createAnInstance(id: string, dto: any) {
    return Scanner.fromDto(dto)
  }

  private get appState() {
    return this.eventsStore.appState
  }

  @computed
  public get userLocationsMap(): { [userId: string]: string[] } {
    const map: { [userId: string]: string[] } = {}

    this.list.forEach(scanner => {
      if (scanner.location) {
        scanner.allowedUsers.forEach(userId => {
          if (map[userId]) {
            map[userId].push(scanner.location.id)
          } else {
            map[userId] = [scanner.location.id]
          }
        })
      }
    })

    return map
  }

  @computed
  public get userBadgesMap(): { [userId: string]: string[] } {
    const map: { [userId: string]: string[] } = {}

    this.list.forEach(scanner => {
      scanner.allowedUsers.forEach(userId => {
        if (map[userId]) {
          map[userId].push(scanner.id)
        } else {
          map[userId] = [scanner.id]
        }
      })
    })

    return map
  }

  @computed
  private get list(): Scanner[] {
    return Array.from(this.sourceMap.values())
  }
}

import { action, observable } from 'mobx'

import { ISiteLocation } from '~/client/graph'
import Basemap from '~/client/src/shared/models/Basemap'
import Sitemap from '~/client/src/shared/models/Sitemap'
import InitialState, {
  ProjectSetUpSteps,
} from '~/client/src/shared/stores/InitialState'
import Guard from '~/client/src/shared/utils/Guard'

import GeneralSitemapSetUpStore, {
  SetUpSteps,
} from '../../GeneralSitemapSetUp.store'
import SitemapItemsSetupStore from '../../stores/SitemapItemsSetup.store'
import SitemapSetupStore from '../../stores/SitemapsSetup.store'

export default class SitemapRibbonStore {
  @observable public shouldShowEditBasemapMenu: boolean = false
  @observable public shouldShowCreateMenu: boolean = false
  @observable public shouldShowTooltipMenu: boolean = false
  @observable public pdfFile: File = null
  @observable public sitemapPdfFile: File = null
  @observable public location: ISiteLocation = null
  @observable public sitemapName: string = ''
  @observable public isUploading: boolean = false
  @observable public basemapFile: File = null
  @observable public basemap: Basemap
  @observable public isDeliveryAssigned: boolean = true
  @observable public isScheduleAssigned: boolean = true
  @observable public isLogisticsAssigned: boolean = true
  @observable public isProjectOverviewMap: boolean = true

  public constructor(
    private readonly sitemapSetupStore: SitemapSetupStore,
    private readonly sitemapItemsSetupStore: SitemapItemsSetupStore,
    private readonly generalSitemapSetUpStore: GeneralSitemapSetUpStore,
    private readonly state: InitialState,
  ) {
    Guard.requireAll({
      sitemapSetupStore,
      sitemapItemsSetupStore,
    })
  }

  public setConvertedBasemap = async (imgFile: Blob) => {
    this.shouldShowTooltipMenu = true
    if (imgFile) {
      await this.updateBasemap(imgFile)
    }

    this.pdfFile = null
    this.shouldShowTooltipMenu = false
  }

  public applyLocation = (
    location: ISiteLocation,
    isProjectOverviewMap: boolean,
  ) => {
    this.isProjectOverviewMap = isProjectOverviewMap
    this.location = location
  }

  public changeMapName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.sitemapName = event.currentTarget.value
  }

  public setConvertedSitemap = async (imgFile: Blob) => {
    this.shouldShowCreateMenu = true
    if (imgFile) {
      if (this.state.projectCreationStep === ProjectSetUpSteps.UploadSitePlan) {
        this.state.projectCreationStep = ProjectSetUpSteps.GeoreferencePlan
        this.generalSitemapSetUpStore.mapBoxEditorStore.isFirstTooltipShown =
          true
      }
      await this.saveNewSitemap(imgFile)
    }
    this.generalSitemapSetUpStore.setStep(SetUpSteps.alignPlan)

    this.sitemapPdfFile = null
    this.shouldShowCreateMenu = false
  }

  public toggleTooltipMenu = () => {
    this.shouldShowTooltipMenu = !this.shouldShowTooltipMenu
    this.clearForm()
  }

  public hideTooltipMenu = () => {
    this.shouldShowTooltipMenu = false
  }

  public toggleCreateMenu = () => {
    this.shouldShowCreateMenu = !this.shouldShowCreateMenu
    if (this.shouldShowCreateMenu) {
      this.isProjectOverviewMap = true
      this.isScheduleAssigned = true
      this.isLogisticsAssigned = true
      this.isDeliveryAssigned = true
      this.generalSitemapSetUpStore.setStep(SetUpSteps.uploadPlan)
    } else {
      this.generalSitemapSetUpStore.setStep(null)
    }
    this.clearForm()
  }

  public hideEditMenu = () => {
    this.shouldShowEditBasemapMenu = false
    this.clearForm()
  }

  public toggleEditMenu = () => {
    this.shouldShowEditBasemapMenu = !this.shouldShowEditBasemapMenu
    this.clearForm()
  }

  @action.bound
  public async setPdfOrUpdateBasemap() {
    this.isUploading = true
    if (!this.basemapFile) {
      return
    }

    if (this.isPdfTypeBasemapSelected) {
      this.pdfFile = this.basemapFile
      this.hideTooltipMenu()
      return
    }

    await this.updateBasemap(this.basemapFile)
  }

  @action.bound
  public async setPdfOrCreateSitemap() {
    this.isUploading = true
    if (!this.basemapFile) {
      return
    }

    if (this.state.projectCreationStep === ProjectSetUpSteps.UploadSitePlan) {
      this.state.projectCreationStep = ProjectSetUpSteps.GeoreferencePlan
      this.generalSitemapSetUpStore.mapBoxEditorStore.isFirstTooltipShown = true
    }

    if (this.isPdfTypeBasemapSelected) {
      this.sitemapPdfFile = this.basemapFile
      this.hideCreateMenu(false)
      return
    }

    await this.saveNewSitemap(this.basemapFile)
    this.generalSitemapSetUpStore.setStep(SetUpSteps.alignPlan)
  }

  public get isPdfTypeBasemapSelected(): boolean {
    return this.basemapFile?.type === 'application/pdf'
  }

  public showDeleteDialog = () => {
    const { showDeleteConfirmationDialog, selectedSitemap } =
      this.sitemapSetupStore
    showDeleteConfirmationDialog(selectedSitemap)
  }

  public toggleIsLogisticsAssigned = () => {
    this.isLogisticsAssigned = !this.isLogisticsAssigned
  }

  public toggleIsDeliveryAssigned = () => {
    this.isDeliveryAssigned = !this.isDeliveryAssigned
  }

  public toggleIsScheduleAssigned = () => {
    this.isScheduleAssigned = !this.isScheduleAssigned
  }

  public setSitemapBasemap = async (file: File) => {
    this.isUploading = true
    this.basemapFile = file
    this.basemap = await this.sitemapSetupStore.uploadBaseMap(file)
    this.isUploading = false
  }

  public setSitemapBasemapFromBrowse = async event => {
    this.isUploading = true
    const file = event.target.files[0]
    this.basemapFile = file
    this.basemap = await this.sitemapSetupStore.uploadBaseMap(file)
    this.isUploading = false
  }

  private saveNewSitemap = async (imgFile?: File | Blob) => {
    const sitemap = await this.sitemapSetupStore.uploadNewSitemap(
      imgFile,
      this.sitemapName,
      this.isProjectOverviewMap,
    )
    await this.sitemapSetupStore.selectSitemap(sitemap)

    const {
      onSiteSitemapSectionClick,
      onActivitiesSitemapSectionClick,
      onDeliveriesSectionClick,
    } = this.sitemapSetupStore
    if (this.isScheduleAssigned) {
      onActivitiesSitemapSectionClick(sitemap)
    }
    if (this.isDeliveryAssigned) {
      onDeliveriesSectionClick(sitemap)
    }
    if (this.isLogisticsAssigned) {
      onSiteSitemapSectionClick(sitemap)
    }
    this.isUploading = false
    this.updateAssociations(sitemap)
    this.hideCreateMenu()
    this.clearForm()
  }

  private updateAssociations = (sitemap: Sitemap) => {
    if (!this.location) {
      return
    }

    const { updateSitemapAssignedItems } = this.sitemapItemsSetupStore

    updateSitemapAssignedItems([this.location.id], sitemap)
  }

  private hideCreateMenu = (shouldClearForm: boolean = true) => {
    this.shouldShowCreateMenu = false
    if (shouldClearForm) {
      this.clearForm()
    }
  }

  private clearForm = () => {
    this.basemapFile = null
    this.basemap = null
    this.sitemapPdfFile = null
    this.pdfFile = null
    this.location = null
    this.sitemapName = ''
  }

  @action.bound
  private async updateBasemap(imgFile?: File | Blob) {
    const { selectedSitemap, updateBasemapForSitemap } = this.sitemapSetupStore

    await updateBasemapForSitemap(imgFile || this.basemapFile, selectedSitemap)

    this.isUploading = false
    this.hideEditMenu()
  }
}

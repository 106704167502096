import { SitePermitStatus } from '~/client/graph'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

enum AdditionTagIcon {
  Company = 'company',
  Status = 'status',
  Role = 'role',
  Team = 'team',
  User = 'user',
  At = 'at',
  Trade = 'trade',
}

enum DefaultTagIcon {
  DefaultTeam = 'defaultTeam',
}

export type TagIconType =
  | SitemapAttributeIcon
  | AdditionTagIcon
  | DeliveryStatus
  | SitePermitStatus
  | DefaultTagIcon
export const TagIconType = {
  ...SitemapAttributeIcon,
  ...AdditionTagIcon,
  ...DeliveryStatus,
  ...SitePermitStatus,
  ...DefaultTagIcon,
}

import { observable } from 'mobx'

import {
  IGeoJson2DGeographicCoordinates,
  IPosition,
  ISitemapSpecificItemData,
  SitemapItemShapeType,
} from '~/client/graph'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import SitemapItemType from '~/client/src/shared/enums/SitemapItemType'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapItem from '~/client/src/shared/models/SitemapItem'
import IHierarchyParent from '~/client/src/shared/types/IHierarchyParent'

import { LocationIntegrationType } from '../../../models/LocationObjects/LocationIntegration'
import { FORBIDDEN_SITE_NAME } from '../../../stores/domain/LocationBase.store'
import SitemapCircleProperties from './SitemapCircleProperties'
import SitemapIconProperties from './SitemapIconProperties'
import SitemapItemDrawnPart from './SitemapItemDrawnPart'
import SitemapItemFactory from './SitemapItemFactory'
import SitemapLabelProperties, {
  DEFAULT_MATURIX_FONT_SIZE,
} from './SitemapLabelProperties'
import SitemapPolyLineProperties from './SitemapPolyLineProperties'
import SitemapRectangleProperties, {
  DEFAULT_RECT_HEIGHT,
  DEFAULT_RECT_WIDTH,
} from './SitemapRectangleProperties'

export interface IBoundingBox {
  x: number
  y: number
  width: number
  height: number
}

export type ShapeProperties =
  | SitemapPolyLineProperties
  | SitemapCircleProperties
  | SitemapRectangleProperties

const CAPTION_MAP = {
  [SitemapItemType.Line]: 'Line',
  [SitemapItemType.TextBox]: 'Text Box',
}

export default class SitemapItemBase {
  @observable public dataObject: LocationBase
  @observable public sitemapItem: SitemapItem
  @observable public isDisplayed: boolean
  @observable public iconProperties: SitemapIconProperties
  @observable public labelProperties: SitemapLabelProperties
  @observable public shapeProperties: ShapeProperties
  @observable public coordinates: IGeoJson2DGeographicCoordinates

  public constructor(
    dataObject: LocationBase,
    sitemapItem: SitemapItem,
    isDisplayed: boolean,
    iconProperties?: SitemapIconProperties,
    labelProperties?: SitemapLabelProperties,
    shapeProperties?: ShapeProperties,
    coordinates?: IGeoJson2DGeographicCoordinates,
  ) {
    this.dataObject = dataObject || null
    this.sitemapItem = sitemapItem
    this.isDisplayed = isDisplayed
    this.iconProperties = iconProperties || null
    this.labelProperties = labelProperties || null
    this.shapeProperties = shapeProperties || null
    this.coordinates = coordinates || null

    this.createRequiredProperties()
  }

  public get id(): string {
    return this.dataSource.id
  }

  public get typeCaption() {
    return CAPTION_MAP[this.sitemapItem.type]
  }

  public get isDataLess() {
    return !this.dataObject
  }

  public getBoundingBox(cWidth: number, cHeight: number): IBoundingBox {
    const partBoundingBoxes = [
      this.iconProperties &&
        this.iconProperties.isDisplayed &&
        this.iconProperties.getBoundingBox(this.iconName, cWidth, cHeight),
      this.labelProperties &&
        this.labelProperties.isDisplayed &&
        this.labelProperties.getBoundingBox(this.name, cWidth, cHeight),
      this.shapeProperties &&
        this.shapeProperties.isDisplayed &&
        this.shapeProperties.getBoundingBox(cWidth, cHeight),
    ].filter(b => !!b)

    if (!partBoundingBoxes.length) {
      return null
    }

    const minX = Math.min(...partBoundingBoxes.map(b => b.x))
    const minY = Math.min(...partBoundingBoxes.map(b => b.y))
    const maxX = Math.max(...partBoundingBoxes.map(b => b.x + b.width))
    const maxY = Math.max(...partBoundingBoxes.map(b => b.y + b.height))

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    }
  }

  public setPosition(x: number, y: number) {
    if (this.iconProperties) {
      this.iconProperties.setPosition({ x, y })
    }
    if (this.labelProperties) {
      this.labelProperties.setPosition({ x, y })
    }
  }

  public move(x: number, y: number) {
    if (this.iconProperties) {
      this.iconProperties.move(x, y)
    }
    if (this.labelProperties) {
      this.labelProperties.move(x, y)
    }
    if (this.shapeProperties) {
      this.shapeProperties.move(x, y)
    }
  }

  public get hasMultipleParts() {
    return (
      [this.iconProperties, this.labelProperties, this.shapeProperties].filter(
        p => p,
      ).length > 1
    )
  }

  public hasDrawnPart(part: SitemapItemDrawnPart) {
    switch (part) {
      case SitemapItemDrawnPart.Icon:
        return !!this.iconProperties
      case SitemapItemDrawnPart.Label:
        return !!this.labelProperties
      case SitemapItemDrawnPart.Shape:
        return !!this.shapeProperties
    }
  }

  public isDisplayDataEqual({
    sitemapItemId,
    isHidden,
    icon,
    label,
    shape,
  }: ISitemapSpecificItemData) {
    const arePartsEqual = [
      [icon, this.iconProperties],
      [label, this.labelProperties],
      [shape, this.shapeProperties],
    ].every(([data, properties]: any) => {
      if (data) {
        return properties && properties.isDisplayDataEqual(data)
      }
      return !properties
    })

    return (
      sitemapItemId === this.sitemapItem.id &&
      !isHidden === this.isDisplayed &&
      arePartsEqual
    )
  }

  public getDisplayData(): ISitemapSpecificItemData {
    return {
      sitemapItemId: this.sitemapItem.id,
      isHidden: !this.isDisplayed,
      icon: this.iconProperties && this.iconProperties.getDisplayData(),
      label: this.labelProperties && this.labelProperties.getDisplayData(),
      shape: this.shapeProperties && this.shapeProperties.getDisplayData(),
    }
  }

  public scale(originPoint: IPosition, scaleX: number, scaleY: number) {
    if (this.iconProperties) {
      this.iconProperties.scale(originPoint, scaleX, scaleY)
    }
    if (this.labelProperties) {
      this.labelProperties.scale(originPoint, scaleX, scaleY)
    }
    if (this.shapeProperties) {
      this.shapeProperties.scale(originPoint, scaleX, scaleY)
    }
    return this
  }

  public rotate(center: IPosition, angle: number) {
    if (this.iconProperties) {
      this.iconProperties.rotate(center, angle)
    }
    if (this.labelProperties) {
      this.labelProperties.rotate(center, angle)
    }
    if (this.shapeProperties) {
      this.shapeProperties.rotate(center, angle)
    }
    return this
  }

  public setShapesFromItem(item: SitemapItemBase) {
    this.iconProperties = item.iconProperties
    this.labelProperties = item.labelProperties
    this.shapeProperties = item.shapeProperties
  }

  public display(): SitemapItemBase {
    this.isDisplayed = true
    return this
  }

  public hide(): SitemapItemBase {
    this.isDisplayed = false
    return this
  }

  public isValid(): boolean {
    return (
      Boolean(this.name && this.color) &&
      this.name !== FORBIDDEN_SITE_NAME &&
      [this.iconProperties, this.labelProperties, this.shapeProperties].every(
        p => !p || p.isValid(),
      )
    )
  }

  public fillDataBeforeDisplaying(sitemaps: Sitemap[]): SitemapItemBase {
    const sitemapWithItem = sitemaps.find(s =>
      s.hasDisplayData(this.sitemapItem.id),
    )

    if (!sitemapWithItem) {
      return this
    }

    const sitemapData = sitemapWithItem.getItemDisplayData(this.sitemapItem.id)
    const shape = SitemapItemFactory.getShapeProperties(
      sitemapData.shape,
      this.color,
    )
    const icon = SitemapItemFactory.getIconProperties(sitemapData.icon, shape)
    const label = SitemapItemFactory.getLabelProperties(sitemapData.label, icon)

    this.iconProperties = icon
    this.labelProperties = label
    this.shapeProperties = shape

    this.createRequiredProperties()

    return this
  }

  public get colorValues(): string[] {
    const colors = [this.color]
    if (this.shapeProperties) {
      colors.push(this.shapeProperties.fillColor)
      colors.push(this.shapeProperties.lineColor)
    }
    return colors
  }

  public get iconName() {
    return this.dataSource.iconName
  }

  public get name(): string {
    return this.dataSource.name
  }

  public get isRichTextBox(): boolean {
    return this.sitemapItem.type === SitemapItemType.TextBox
  }

  public get displayName(): string {
    if (!this.isRichTextBox) {
      return this.name
    }

    const div = document.createElement('div')
    div.innerHTML = this.name
    return div.innerText
  }

  public setName(name: string): SitemapItemBase {
    this.dataSource.name = name
    return this
  }

  public get parent(): IHierarchyParent {
    return this.dataSource.parent
  }

  public get hasParent(): boolean {
    return !!this.parent
  }

  public isParent(item: SitemapItemBase) {
    if (!this.hasParent || !item.dataObject) {
      return false
    }
    return (
      this.parent.parentId === item.dataObject.id &&
      this.parent.parentType === item.dataObject.type
    )
  }

  public setParent(parent: IHierarchyParent) {
    this.dataSource.parent = parent
  }

  public get color(): string {
    return this.dataSource.color
  }

  public setColor(color: string): SitemapItemBase {
    this.dataSource.color = color
    return this
  }

  public setAllColors(color: string): SitemapItemBase {
    this.dataSource.color = color
    if (this.shapeProperties) {
      this.shapeProperties.setFillColor(color)
      this.shapeProperties.setLineColor(color)
    }
    return this
  }

  public updateIcon(iconName: SitemapAttributeIcon) {
    this.dataSource.iconName = iconName
  }

  public updateShape(type: SitemapItemShapeType) {
    if (!type) {
      this.shapeProperties = null
      return
    }
    if (this.shapeProperties && this.shapeProperties.type === type) {
      return
    }

    const prevShapeData: any = this.shapeProperties || {}
    switch (type) {
      case SitemapItemShapeType.Polyline:
        this.shapeProperties = new SitemapPolyLineProperties(
          prevShapeData.lineWidth,
          prevShapeData.lineColor || this.color,
          prevShapeData.fillColor || this.color,
          prevShapeData.fillOpacity,
        )
        break
      case SitemapItemShapeType.Circle:
        this.shapeProperties = new SitemapCircleProperties(
          prevShapeData.lineWidth,
          prevShapeData.lineColor || this.color,
          prevShapeData.fillColor || this.color,
          prevShapeData.fillOpacity,
          {
            x: this.iconProperties.position.x,
            y: this.iconProperties.position.y,
          },
        )
        break
      case SitemapItemShapeType.Rectangle:
        this.shapeProperties = new SitemapRectangleProperties(
          prevShapeData.lineWidth,
          prevShapeData.lineColor || this.color,
          prevShapeData.fillColor || this.color,
          prevShapeData.fillOpacity,
          {
            x: this.iconProperties.position.x - DEFAULT_RECT_WIDTH / 2,
            y: this.iconProperties.position.y - DEFAULT_RECT_HEIGHT / 2,
          },
        )
        break
    }
  }

  public copy(): SitemapItemBase {
    return new SitemapItemBase(
      this.dataObject && this.dataObject.copy(),
      this.sitemapItem && this.sitemapItem.copy(),
      this.isDisplayed,
      this.iconProperties && this.iconProperties.copy(),
      this.labelProperties && this.labelProperties.copy(),
      this.shapeProperties && this.shapeProperties.copy(),
    )
  }

  protected get dataSource(): LocationBase | SitemapItem {
    return this.dataObject || this.sitemapItem
  }

  private createRequiredProperties() {
    if (this.isDataLess) {
      return
    }

    if (!this.iconProperties) {
      this.iconProperties = new SitemapIconProperties()
    }

    let fontSize = null
    let isDisplayed = true

    if (this.dataObject?.is(LocationIntegrationType.MaturixStation)) {
      fontSize = DEFAULT_MATURIX_FONT_SIZE
      isDisplayed = false
    }
    if (!this.labelProperties) {
      this.labelProperties = new SitemapLabelProperties(
        fontSize,
        false,
        isDisplayed,
        this.iconProperties.calculateLabelPosition(),
      )
    }
  }
}

import React from 'react'

import { IReactionDisposer, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ActivitiesSitemap from '~/client/src/shared/components/ActivitiesSitemap/ActivitiesSitemap'
import ActivitiesSitemapSetUpStore from '~/client/src/shared/components/ActivitiesSitemap/ActivitiesSitemapSetUp.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import SitemapsGallery from '~/client/src/shared/components/SitemapsGallery/SitemapsGallery'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import { SAVE_ACTIVITIES_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import DesktopActivityListStore from '../DesktopActivityList.store'

import './ActivitiesMapView.scss'

interface IProps {
  store: DesktopActivityListStore

  state?: DesktopInitialState
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
  tagsStore?: TagsStore
  deliveryDetailsStore?: DeliveryDetailsStore
}

@inject(
  'state',
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'locationAttributesStore',
  'tagsStore',
  'deliveryDetailsStore',
)
@observer
export default class ActivitiesMapView extends React.Component<IProps> {
  private readonly store: ActivitiesSitemapSetUpStore = null
  private textboxesCache: ICanvasImageCache = {}
  private disposeDisplayedActivityIdReaction: IReactionDisposer

  public constructor(props: IProps) {
    super(props)

    this.store = new ActivitiesSitemapSetUpStore(
      props.sitemapsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
      props.tagsStore,
      props.state,
    )
  }

  public componentDidMount() {
    this.disposeDisplayedActivityIdReaction = reaction(
      () => this.props.store.selectedActivity,
      selectedActivity => {
        if (!selectedActivity) {
          this.store.deselectAll()
        }
      },
      { fireImmediately: true },
    )
  }

  public componentWillUnmount() {
    this.textboxesCache = {}
    this.disposeDisplayedActivityIdReaction?.()
  }

  public render() {
    const { activitiesSitemapsIds } = this.props.store

    return (
      <div className="activities-map full-height">
        <SitemapsGallery
          sitemapsIds={activitiesSitemapsIds}
          SelectedSitemapComponent={this.SitemapComponent}
          eventName={SAVE_ACTIVITIES_CONFIGURATIONS}
          areArrowsLeftSided={true}
          shouldUseFullHeight={true}
          shouldDisableZoom={true}
          FileInputType={DesktopFileInput}
          mapboxEditorStore={this.store.mapBoxEditorStore}
        />
      </div>
    )
  }

  private get SitemapComponent(): (props: any) => JSX.Element {
    return props => (
      <div className="sitemap-container full-width full-height">
        <ActivitiesSitemap
          {...props}
          activityListStore={this.props.store}
          store={this.store}
          textboxesCache={this.textboxesCache}
          shouldUseFullHeight={true}
          mapBoxEditorStore={this.store.mapBoxEditorStore}
          openActivity={this.openActivity}
        />
      </div>
    )
  }

  private openActivity = (code: string) => {
    const { setSelection, showActivityDetailsPanel } = this.props.store

    setSelection([code])
    showActivityDetailsPanel()
  }
}

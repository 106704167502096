import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DesktopActivitiesSavedFiltersStore from '../ActivitiesSavedFilters/DesktopActivitiesSavedFilters.store'
import DesktopDeliverySavedFiltersStore from '../DeliverySavedFilters/DesktopDeliverySavedFilters.store'
import SectionButton, { ISavedFiltersSection } from './components/SectionButton'

import './SavedFiltersActionBar.scss'

// localization: translated

interface IProps {
  desktopSavedFiltersStore:
    | DesktopActivitiesSavedFiltersStore
    | DesktopDeliverySavedFiltersStore
}

@observer
export default class SavedFiltersHeader extends React.Component<IProps> {
  private sections: ISavedFiltersSection[] = [
    {
      getLabel: () => Localization.translator.share,
      icon: <Icon icon={IconNames.SHARE} />,
      onClick: this.clickOnShare,
      isDisabled: true,
    },
    {
      getLabel: () => Localization.translator.delete,
      icon: <Icon icon={IconNames.TRASH} />,
      onClick: this.clickOnDelete,
    },
  ]

  public render() {
    const { filterDialogMode: mode, toggleSaveFilterDialog } =
      this.props.desktopSavedFiltersStore

    const isEditMode = mode === CustomFilterDialogModes.Edit

    const headerCapture = isEditMode
      ? Localization.translator.editACustomFilter
      : Localization.translator.saveANewCustomFilter
    return (
      <div className="row px10 saved-filters-action-bar-wrapper mt5 mb10">
        <div className="row pl5" onClick={toggleSaveFilterDialog}>
          <div className="row no-grow pointer">
            <Icons.BackArrow className="row" />
          </div>
          <div className="ml10 text extra-large">{headerCapture}</div>
        </div>
        {isEditMode &&
          this.sections.map((section, index) => (
            <SectionButton key={index} section={section} />
          ))}
      </div>
    )
  }

  @action.bound
  private clickOnShare() {
    // TODO: Implement logic to share custom filter
  }

  @action.bound
  private clickOnDelete() {
    this.props.desktopSavedFiltersStore.deleteCustomFilter()
  }
}

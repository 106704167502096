import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryConfigurationsFieldsFragment = Pick<
  Types.IDeliveryConfigurations,
  | 'allColors'
  | 'customActionButtonText'
  | 'customActionText'
  | 'customActionUrl'
  | 'deadlineInterval'
  | 'deliveryDuration'
  | 'id'
  | 'onTimeWindow'
  | 'shouldBlockLateRequesting'
  | 'isCustomActionIncluded'
  | 'cardColoringLocationType'
  | 'unextendableFields'
  | 'isMultipleEquipmentAllowed'
  | 'projectId'
  | 'nonWorkTimesBlockType'
  | 'hiddenCancelationReasons'
> & {
  savedDeliveryGroups?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IFilterGroup, 'filterNames'>>>
  >
  sitemaps?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IOrderedSitemap, 'sitemapId' | 'order'>>>
  >
  cardFieldsConfig?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IDeliveryCardField, 'fieldId' | 'isHidden'>>>
  >
}

export type IGetDeliveryConfigurationsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetDeliveryConfigurationsQuery = {
  deliveryConfigurations?: Types.Maybe<{
    data: Array<IDeliveryConfigurationsFieldsFragment>
  }>
}

export type ISaveDeliveryConfigurationsMutationVariables = Types.Exact<{
  deliveryConfigurations: Types.IDeliveryConfigurationsInput
}>

export type ISaveDeliveryConfigurationsMutation = {
  saveDeliveryConfigurations?: Types.Maybe<IDeliveryConfigurationsFieldsFragment>
}

export type IListenToDeliveryConfigurationsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToDeliveryConfigurationsSubscription = {
  deliveryConfigurations?: Types.Maybe<
    Pick<Types.IDeliveryConfigurationsChangeEvent, 'id'> & {
      item?: Types.Maybe<IDeliveryConfigurationsFieldsFragment>
    }
  >
}

export const DeliveryConfigurationsFieldsFragmentDoc = gql`
  fragment DeliveryConfigurationsFields on DeliveryConfigurations {
    allColors
    customActionButtonText
    customActionText
    customActionUrl
    deadlineInterval
    deliveryDuration
    id
    onTimeWindow
    savedDeliveryGroups {
      filterNames
    }
    shouldBlockLateRequesting
    isCustomActionIncluded
    cardColoringLocationType
    sitemaps {
      sitemapId
      order
    }
    unextendableFields
    isMultipleEquipmentAllowed
    projectId
    nonWorkTimesBlockType
    cardFieldsConfig {
      fieldId
      isHidden
    }
    hiddenCancelationReasons
  }
`
export const GetDeliveryConfigurationsDocument = gql`
  query GetDeliveryConfigurations($projectId: ObjectId!) {
    deliveryConfigurations(projectId: $projectId, limit: 1) {
      data {
        ...DeliveryConfigurationsFields
      }
    }
  }
  ${DeliveryConfigurationsFieldsFragmentDoc}
`

/**
 * __useGetDeliveryConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetDeliveryConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryConfigurationsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetDeliveryConfigurationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryConfigurationsQuery,
    IGetDeliveryConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryConfigurationsQuery,
    IGetDeliveryConfigurationsQueryVariables
  >(GetDeliveryConfigurationsDocument, options)
}
export function useGetDeliveryConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryConfigurationsQuery,
    IGetDeliveryConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryConfigurationsQuery,
    IGetDeliveryConfigurationsQueryVariables
  >(GetDeliveryConfigurationsDocument, options)
}
export type GetDeliveryConfigurationsQueryHookResult = ReturnType<
  typeof useGetDeliveryConfigurationsQuery
>
export type GetDeliveryConfigurationsLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryConfigurationsLazyQuery
>
export type GetDeliveryConfigurationsQueryResult = Apollo.QueryResult<
  IGetDeliveryConfigurationsQuery,
  IGetDeliveryConfigurationsQueryVariables
>
export const SaveDeliveryConfigurationsDocument = gql`
  mutation SaveDeliveryConfigurations(
    $deliveryConfigurations: DeliveryConfigurationsInput!
  ) {
    saveDeliveryConfigurations(
      deliveryConfigurations: $deliveryConfigurations
    ) {
      ...DeliveryConfigurationsFields
    }
  }
  ${DeliveryConfigurationsFieldsFragmentDoc}
`
export type ISaveDeliveryConfigurationsMutationFn = Apollo.MutationFunction<
  ISaveDeliveryConfigurationsMutation,
  ISaveDeliveryConfigurationsMutationVariables
>

/**
 * __useSaveDeliveryConfigurationsMutation__
 *
 * To run a mutation, you first call `useSaveDeliveryConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeliveryConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeliveryConfigurationsMutation, { data, loading, error }] = useSaveDeliveryConfigurationsMutation({
 *   variables: {
 *      deliveryConfigurations: // value for 'deliveryConfigurations'
 *   },
 * });
 */
export function useSaveDeliveryConfigurationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveDeliveryConfigurationsMutation,
    ISaveDeliveryConfigurationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveDeliveryConfigurationsMutation,
    ISaveDeliveryConfigurationsMutationVariables
  >(SaveDeliveryConfigurationsDocument, options)
}
export type SaveDeliveryConfigurationsMutationHookResult = ReturnType<
  typeof useSaveDeliveryConfigurationsMutation
>
export type SaveDeliveryConfigurationsMutationResult =
  Apollo.MutationResult<ISaveDeliveryConfigurationsMutation>
export type SaveDeliveryConfigurationsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveDeliveryConfigurationsMutation,
    ISaveDeliveryConfigurationsMutationVariables
  >
export const ListenToDeliveryConfigurationsDocument = gql`
  subscription ListenToDeliveryConfigurations($projectId: ObjectId!) {
    deliveryConfigurations(projectId: $projectId) {
      id
      item {
        ...DeliveryConfigurationsFields
      }
    }
  }
  ${DeliveryConfigurationsFieldsFragmentDoc}
`

/**
 * __useListenToDeliveryConfigurationsSubscription__
 *
 * To run a query within a React component, call `useListenToDeliveryConfigurationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToDeliveryConfigurationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToDeliveryConfigurationsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToDeliveryConfigurationsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToDeliveryConfigurationsSubscription,
    IListenToDeliveryConfigurationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToDeliveryConfigurationsSubscription,
    IListenToDeliveryConfigurationsSubscriptionVariables
  >(ListenToDeliveryConfigurationsDocument, options)
}
export type ListenToDeliveryConfigurationsSubscriptionHookResult = ReturnType<
  typeof useListenToDeliveryConfigurationsSubscription
>
export type ListenToDeliveryConfigurationsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToDeliveryConfigurationsSubscription>

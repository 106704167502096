import * as React from 'react'

import Spinner from '~/client/src/shared/components/Spinner/Spinner'

interface ILoader {
  hint?: string
  size?: number
  className?: string
}

const DEFAULT_SPINNER_SIZE = 25

export class Loader extends React.Component<ILoader> {
  public static defaultProps = {
    className: 'full-height col x-center y-center',
    size: DEFAULT_SPINNER_SIZE,
    hint: '',
  }

  public render() {
    const { hint, size, className } = this.props

    return (
      <div className={className}>
        <Spinner size={size} className="loader" />
        {hint}
      </div>
    )
  }
}

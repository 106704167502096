import * as React from 'react'

import { observer } from 'mobx-react'

import DetailListItem from '~/client/src/shared/components/DetailListItem'
import ActivityInfoStore from '~/client/src/shared/components/ActivityDetails/components/ActivityInfoSection/ActivityInfo.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'

// translated

@observer
export default class CompanyDetails extends React.Component<{
  store: ActivityInfoStore
}> {
  public render() {
    const { companies } = this.props.store

    return (
      <DetailListItem category={Localization.translator.company}>
        <div>{companies}</div>
      </DetailListItem>
    )
  }
}

import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 20
const PATH_SCALE = 0.7
const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const GATE_ICON_PATH =
  'M14.636.8c.621 0 1.132.472 1.194 1.077l.006.123v14.025c0 .621-.472 1.132-1.077 1.194l-.123.006H5c-.621 0-1.132-.472-1.194-1.077l-.006-.123v-3.526c0-.386.313-.7.7-.7.354 0 .647.264.694.605l.006.095-.001 3.325h9.237V2.2H5.199L5.2 5.52c0 .355-.263.648-.605.694L4.5 6.22c-.354 0-.647-.263-.694-.605L3.8 5.52V2c0-.621.472-1.132 1.077-1.194L5 .8h9.636zM9.771 5.773l.086.074 2.481 2.481c.343.343.37.882.08 1.254l-.08.09-2.48 2.48c-.274.274-.717.274-.99 0-.247-.245-.271-.629-.074-.902l.073-.087 1.462-1.464L1 9.7c-.276 0-.5-.224-.5-.5v-.4c0-.276.224-.5.5-.5l9.329-.001-1.462-1.462c-.246-.246-.27-.63-.073-.903l.073-.087c.247-.246.63-.27.904-.074z'
const ICON_OFFSET_X = 3
const ICON_OFFSET_Y = 3

const CIRCLE_STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaGateIcon = ({ color, children, ...rest }) => {
  const halfSize = ICON_SIZE / 2

  return (
    <Group {...rest} offsetX={halfSize} offsetY={halfSize}>
      {children}
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={CIRCLE_STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={GATE_ICON_PATH}
        fill={getHEXColor(Colors.neutral100)}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        scaleX={PATH_SCALE}
        scaleY={PATH_SCALE}
      />
    </Group>
  )
}

export default KonvaGateIcon

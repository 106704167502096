import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import { ICompanyStatusUpdate, StatusUpdateType } from '~/client/graph'
import CommonThread from '~/client/src/shared/components/CommonThread/CommonThread'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import Message from '~/client/src/shared/models/Message'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import InitialState from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import StatusUpdatesStore from '../../stores/domain/StatusUpdates.store'
import CommonStore from '../../stores/ui/Common.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import {
  FIELDS_AND_NOTE_SEPARATOR,
  FIELD_LINES_SEPARATOR,
  FIELD_VALUES_SEPARATOR,
} from '../DeliveryDetails/DeliveryDetails.store'
import FileInputBase from '../FileInput/FileInput'
import StatusUpdateViewStore from './StatusUpdateViewStore'

import './StatusUpdateThread.scss'

// translated

interface IStatusUpdateEntryProps {
  statusUpdate: StatusUpdate
  messagesStore?: MessagesStore
  hideButtons?: boolean
  activitiesStore?: ActivitiesStore
  statusUpdatesStore?: StatusUpdatesStore
  state?: InitialState
  isStatusUpdateViewDisplayed?: boolean
  threadClass?: string
  rightAlign?: boolean
  threadIcon?: JSX.Element
  FileInputType: typeof FileInputBase
  common?: CommonStore
  projectDateStore?: ProjectDateStore
}

type StatusUpdateThreadType = IStatusUpdateEntryProps & RouteComponentProps<any>

@inject(
  'messagesStore',
  'activitiesStore',
  'statusUpdatesStore',
  'state',
  'common',
  'projectDateStore',
)
@observer
class StatusUpdateThread extends React.Component<StatusUpdateThreadType> {
  public static defaultProps = {
    rightAlign: false,
    threadIcon: <Icons.StatusUpdateSmall className="icon-blue" />,
    threadClass: 'photo-thread',
    statusUpdate: StatusUpdate.none,
  }

  private readonly store: StatusUpdateViewStore

  public constructor(props: StatusUpdateThreadType) {
    super(props)

    this.store = new StatusUpdateViewStore(
      this.props.statusUpdatesStore,
      this.props.activitiesStore,
    )
  }

  public render() {
    const { statusUpdate, isStatusUpdateViewDisplayed } = this.props
    const { companyNamesList } = statusUpdate

    switch (true) {
      case statusUpdate.type === StatusUpdateType.Change:
        return this.renderEditLogMessage()
      case statusUpdate.isOldOrUnassigned:
        return this.renderOldOrUnassignedUpdate()
      case isStatusUpdateViewDisplayed || companyNamesList.length === 1:
        return this.renderDistinctCompanyUpdates()

      default:
        return (
          <CommonThread
            messages={this.buildStatusUpdateMessages(
              this.multiCompanyStatusUpdateBlock,
            )}
            caption={this.getThreadCaption()}
            {...this.commonProps}
          />
        )
    }
  }

  private get authorId(): string {
    return this.props.statusUpdate.authorId
  }

  private get commonProps() {
    const {
      rightAlign,
      hideButtons,
      FileInputType,
      threadClass,
      statusUpdate,
      threadIcon,
    } = this.props

    return {
      rightAlign,
      hideButtons,
      FileInputType,
      threadClass,
      threadIcon,
      showFooterLine: true,
      showImages: true,
      hideThread: false,
      model: statusUpdate,
      subCaption: this.threadSubCaption,
      buttonsElement: this.replyButton,
      customPhotoClick: this.displayStatusUpdateView,
    }
  }

  private buildStatusUpdateMessages(textBlock: JSX.Element) {
    const { createdAt } = this.props.statusUpdate

    return [
      {
        userId: this.authorId,
        text: textBlock,
        createdAt,
      } as any,
      ...this.threadMessages,
    ]
  }

  private getUpdateMessagesByCompany(
    company: ICompanyStatusUpdate,
    companyName: string,
  ) {
    const { manpower, percentComplete } = company

    const textBlock = this.renderBaseCompanyBlock(
      manpower,
      percentComplete,
      companyName,
    )

    return this.buildStatusUpdateMessages(textBlock)
  }

  private get multiCompanyStatusUpdateBlock() {
    return (
      <div className="status-update-text-container col text light large">
        <div className="col pt7">{this.renderCompanyUpdates()}</div>
      </div>
    )
  }

  private renderEditLogMessage() {
    return this.threadMessages.map((threadMessage, index) => {
      const message = {
        id: threadMessage.id,
        userId: threadMessage.userId,
        createdAt: threadMessage.createdAt,
        text: this.defineChangedMessage(threadMessage.text),
      }

      const isLastMessage = index === this.threadMessages.length - 1

      return (
        <div key={index} className="pb18">
          <CommonThread
            showImages={true}
            messages={[message]}
            threadIcon={null}
            threadClass="photo-thread"
            caption={this.selectedActivity.id}
            model={this.selectedActivity}
            shouldHideFooterCircle={true}
            shouldHideFooter={true}
            showFooterLine={false}
            hideThread={true}
            isActivityColumn={true}
            FileInputType={this.props.FileInputType}
            isLastThreadInLog={isLastMessage}
          />
        </div>
      )
    })
  }

  private defineChangedMessage = (message: string): JSX.Element | string => {
    if (
      !message?.includes(FIELDS_AND_NOTE_SEPARATOR) &&
      !message?.includes(FIELD_VALUES_SEPARATOR)
    ) {
      return message
    }

    const [fieldLines] = message.split(FIELDS_AND_NOTE_SEPARATOR)
    const changedFieldLines = fieldLines.split(FIELD_LINES_SEPARATOR)

    return (
      <div className="col">
        {changedFieldLines.map((fieldLine, index) => {
          const [fieldName, oldValue, newValue] = fieldLine.split(
            FIELD_VALUES_SEPARATOR,
          )
          const nameWithOldValue =
            oldValue === 'undefined' || oldValue === 'null'
              ? `${fieldName}`
              : `${fieldName}: ${oldValue}`

          const newValueText =
            newValue === 'undefined' || newValue === 'null'
              ? `[Cleared]`
              : `${newValue}`

          return (
            <span
              key={index}
              className="changed-status-message vertical-align-middle nowrap text grey large italic text-ellipsis"
            >
              <span className="text-ellipsis inline-block vertical-align-bottom">
                {nameWithOldValue}&nbsp;
              </span>
              <span>→&nbsp;</span>
              <span title={newValueText} className="text large red">
                {newValueText}&nbsp;
              </span>
            </span>
          )
        })}
      </div>
    )
  }

  private renderOldOrUnassignedUpdate() {
    const { manpower, percentComplete, authorId } = this.props.statusUpdate

    const messages = this.buildStatusUpdateMessages(
      this.renderBaseCompanyBlock(manpower, percentComplete, authorId),
    )

    return (
      <CommonThread
        messages={messages}
        threadIcon={this.props.threadIcon}
        caption={this.getThreadCaption(true)}
        {...this.commonProps}
      />
    )
  }

  private renderDistinctCompanyUpdates() {
    const { statusUpdate, threadIcon } = this.props
    const { companyNamesList } = statusUpdate

    return companyNamesList.map((companyName, index) => {
      const company = statusUpdate.findCompanyStatus(companyName)

      return (
        <CommonThread
          key={index}
          shouldHideFooterCircle={index !== companyNamesList.length - 1}
          shouldSetBottomPaddingToZero={index !== companyNamesList.length - 1}
          messages={this.getUpdateMessagesByCompany(company, companyName)}
          caption={this.getThreadCaption(true, companyName)}
          subCaption={this.threadSubCaption}
          {...this.commonProps}
          showFooterLine={companyNamesList.length === 1}
          threadIcon={index === 0 ? threadIcon : <div />}
        />
      )
    })
  }

  private renderBaseCompanyBlock(
    manpower: number,
    percentComplete: number,
    companyName?: string,
  ) {
    const plannedPercentComplete = companyName
      ? this.getPlannedPercentCompleteByCompany(companyName)
      : this.defaultPlannedPercentComplete

    return (
      <div className="pb3 col">
        <div className="row">
          <div className="primary-column" />
          <div className="table-heder-cell activity-log-bold-text common-column">
            {Localization.translator.planned}
          </div>
          <div className="table-heder-cell activity-log-bold-text common-column">
            {Localization.translator.actual}
          </div>
        </div>
        {this.renderRow(
          Localization.translator.PComplete,
          plannedPercentComplete,
          percentComplete,
        )}
        {this.renderRow(
          Localization.translator.manpower,
          0,
          manpower,
        )}
        {this.renderRow(
          Localization.translator.start_noun,
          this.plannedStartDate,
          this.actualStartDate,
        )}
        {this.renderRow(
          Localization.translator.end_noun,
          this.plannedFinishDate,
          this.actualFinishDate,
        )}
      </div>
    )
  }

  private renderRow(
    title: string,
    plannedValue: string | number,
    actualValue: string | number,
  ) {
    return (
      <div className="row">
        <div className="activity-log-bold-text primary-column">{title}</div>
        <div className="common-column">{plannedValue}</div>
        <div className="common-column">{actualValue}</div>
      </div>
    )
  }

  private renderCompanyLine(
    companyName: string,
    timestamp: number,
    percentComplete: number,
    manpower: number,
  ) {
    return (
      <div className="row pb3">
        <div className="text large ellipsis company-name">{companyName}</div>
        <div className="text large right">
          {!!timestamp &&
            this.props.projectDateStore.getTimeToDisplay(timestamp)}
        </div>
        <div
          className={classList({
            'row x-end no-grow ml15 ': true,
            'light-blue': !!timestamp,
            grey: !timestamp,
          })}
        >
          {this.renderPercentComplete(percentComplete)}
          {this.renderManpower(manpower)}
        </div>
      </div>
    )
  }

  private renderCompanyUpdates() {
    const su = this.props.statusUpdate

    return su.companyNamesList.map((companyName, index) => {
      const { percentComplete, manpower, updatedAt } =
        su.findCompanyStatus(companyName)

      return (
        <React.Fragment key={index}>
          {this.renderCompanyLine(
            companyName,
            updatedAt,
            percentComplete,
            manpower,
          )}
        </React.Fragment>
      )
    })
  }

  private renderPercentComplete(percentComplete: number = 0) {
    return (
      <div className="row mw55">
        <Icons.StatusUpdateSmall className="pr5 no-grow status-update-icon" />
        <div className="text large ellipsis value">{percentComplete}%</div>
      </div>
    )
  }

  private renderManpower(manpower: number = 0) {
    return (
      <div className="row mw55 ml5">
        <Icons.ResourcesBlue className="pr5 no-grow manpower-icon" />
        <div className="text large ellipsis value">{manpower}</div>
      </div>
    )
  }

  private get replyButton() {
    if (this.props.hideButtons) {
      return null
    }

    const { userActiveProjectSettings } = this.props.state
    return (
      <div
        className={classList({
          'row item-buttons-container': true,
          'inactive-element': userActiveProjectSettings?.isPresentationUser,
        })}
      >
        <button
          className="item-button reply-btn"
          onClick={this.displayStatusUpdateView}
        >
          {Localization.translator.reply}
        </button>
      </div>
    )
  }

  @computed
  private get threadMessages(): Message[] {
    const { statusUpdate, messagesStore } = this.props
    return messagesStore.getMessagesByThreadId(statusUpdate.threadId)
  }

  private get defaultPlannedPercentComplete() {
    const { getPlannedPercentComplete } = this.store.selectedActivity
    return getPlannedPercentComplete(this.props.projectDateStore)
  }

  private get selectedActivity() {
    const { selectedActivity } = this.props.activitiesStore
    return selectedActivity || Activity.none
  }

  private get actualFinishDate(): string {
    const { getMonthAndDayToDisplayWithCheck } = this.props.projectDateStore
    const { date } = this.selectedActivity.dates.actual.finish
    return getMonthAndDayToDisplayWithCheck(date)
  }

  private get actualStartDate(): string {
    const { getMonthAndDayToDisplayWithCheck } = this.props.projectDateStore
    const { date } = this.selectedActivity.dates.actual.start
    return getMonthAndDayToDisplayWithCheck(date)
  }

  private get plannedFinishDate(): string {
    const { getMonthAndDayToDisplayWithCheck } = this.props.projectDateStore
    const { date } = this.selectedActivity.dates.planned.finish
    return getMonthAndDayToDisplayWithCheck(date)
  }

  private get plannedStartDate(): string {
    const { getMonthAndDayToDisplayWithCheck } = this.props.projectDateStore
    const { date } = this.selectedActivity.dates.planned.start
    return getMonthAndDayToDisplayWithCheck(date)
  }

  @action.bound
  private displayStatusUpdateView = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const { common, statusUpdate } = this.props
    common.displayStatusUpdateMessagesView(statusUpdate)
  }

  private getPlannedPercentCompleteByCompany(companyName: string) {
    const { getPercentCompleteByActivityAndCompany } = this.store
    return getPercentCompleteByActivityAndCompany(companyName)
  }

  private getThreadCaption(
    withCompany: boolean = false,
    customCompany?: string,
  ) {
    const { typeCaption, companyName } = this.props.statusUpdate

    const updateCompany = companyName || Localization.translator.unassigned
    const companyPortion = customCompany || updateCompany

    return withCompany ? `${typeCaption}: ${companyPortion}` : typeCaption
  }

  private get threadSubCaption() {
    const { dateFor } = this.props.statusUpdate
    const { getMonthAndDayToDisplay } = this.props.projectDateStore
    return Localization.translator.forDate(getMonthAndDayToDisplay(dateFor))
  }
}

export default withRouter(StatusUpdateThread)

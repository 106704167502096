import { action, computed, observable } from 'mobx'

import { ISiteLocation } from '~/client/graph'
import BaseSitemapSetUpStore from '~/client/src/shared/components/SitemapHelpers/DeliverySitemap.store'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import { lbsBands } from '~/client/src/shared/types/IHierarchyParent'
import { areArraysEqual } from '~/client/src/shared/utils/util'

import Activity from '../../models/Activity'
import InitialState from '../../stores/InitialState'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import TagsStore from '../../stores/domain/Tags.store'
import MapBoxEditorStore from '../MapBoxEditor/MapBoxEditor.store'

export default class ActivitiesSitemapSetUpStore extends BaseSitemapSetUpStore {
  @observable public selectedActivities: Activity[] = []
  @observable public selectedAttribute: SitemapItemBase = null
  @observable public topOffset: number = 0
  @observable public leftOffset: number = 0
  public readonly mapBoxEditorStore: MapBoxEditorStore

  public constructor(
    public readonly sitemapsStore: SitemapsStore,
    protected readonly basemapsStore: BasemapsStore,
    protected readonly sitemapItemsStore: SitemapItemsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    private readonly tagsStore: TagsStore,
    state: InitialState,
  ) {
    super(
      sitemapsStore,
      basemapsStore,
      sitemapItemsStore,
      locationAttributesStore,
    )

    this.mapBoxEditorStore = new MapBoxEditorStore(
      () => this.sitemap,
      sitemapItemsStore,
      sitemapsStore,
      locationAttributesStore,
      state,
      false,
      null,
      false,
      false,
      true,
    )
  }

  @action.bound
  public selectActivities(
    activities?: Activity[],
    item?: SitemapItemBase,
    x?: number,
    y?: number,
  ) {
    if (!this.areActivitiesSelected) {
      this.deselectAll()
    }

    this.selectedAttribute = item === this.selectedAttribute ? null : item

    this.selectedActivities = areArraysEqual(
      this.selectedActivities,
      activities,
    )
      ? []
      : [...activities]

    this.topOffset = y
    this.leftOffset = x
  }

  @action.bound
  public deselectAll() {
    this.selectedActivities = []
    this.selectedAttribute = null
    this.topOffset = null
    this.leftOffset = null
  }

  public getRelatedItems = (locations: ISiteLocation[]): SitemapItemBase[] => {
    if (!locations?.length) {
      return []
    }

    return locations.reduce((list, loc) => {
      const relatedItem =
        this.getDisplayedItemById(loc.id) || this.findParentItemByAttrId(loc.id)

      if (relatedItem?.iconProperties?.position) {
        list.push(relatedItem)
      }

      return list
    }, [] as SitemapItemBase[])
  }

  public findParentItemByAttrId = (id: string): SitemapItemBase => {
    const attribute = this.hierarchyAttributes.find(a => a.id === id)

    const parents = attribute?.getHierarchyChainsObjects(
      this.tagsStore.tagStoreByTagTypeMap,
    )

    let closestParent: SitemapItemBase = null

    parents?.some(
      a => (closestParent = this.getDisplayedItemById(a.id)) && !!closestParent,
    )

    return closestParent
  }

  private getDisplayedItemById = (id: string): SitemapItemBase => {
    return (
      this.sitemap?.isReferenced
        ? this.mapBoxEditorStore.itemsOnMap
        : this.displayedSitemapItems
    )?.find(item => item.id === id)
  }

  public get areActivitiesSelected(): boolean {
    return !!this.selectedActivities.length
  }

  @computed
  public get sortedSitemapAttributes(): SitemapItemBase[] {
    return (this.displayedSitemapItems || [])
      .filter(item => item.dataObject)
      .sort((a, b) => {
        const aNodeType = a.dataObject.type
        const bNodeType = b.dataObject.type

        const aIndex = lbsBands.indexOf(aNodeType)
        const bIndex = lbsBands.indexOf(bNodeType)

        return bIndex - aIndex
      })
  }
}

import { SaveStagingsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import Staging from '../../models/LocationObjects/Staging'
import LocationStoreBase from './LocationBase.store'

export default class StagingsStore extends LocationStoreBase<Staging> {
  protected saveMutation = SaveStagingsDocument
  protected graphName = 'stagings'
  protected defaultIconName = SitemapAttributeIcon.Staging

  public get byId(): Map<string, Staging> {
    return this.eventsStore.appState.stagings
  }

  protected fromDto(dto: ILocationDto): Staging {
    return Staging.fromDto(dto)
  }
}

import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import BaseGroupingOptionsStore from './BaseGroupingOptions.store'
import BaseGroupingOptionsPopup from './components/BaseGroupingOptionsPopup/BaseGroupingOptionsPopup'

import './BaseGroupingOptions.scss'

// localization: translated

interface IGroupingOptions {
  store: BaseGroupingOptionsStore
  forceClose: boolean
  eventsStore?: DesktopEventStore
  isDisabled?: boolean
  CustomHandleButton?
  shouldHideBandCreation?: boolean
}

@inject('eventsStore')
@observer
export default class BaseGroupingOptions extends React.Component<IGroupingOptions> {
  private readonly clearPostEventCallback: () => void

  public constructor(props: IGroupingOptions) {
    super(props)

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.store.onActionRequest,
    )
  }

  public UNSAFE_componentWillMount() {
    this.store.setSelectedBand()
  }

  public componentDidUpdate() {
    if (this.props.forceClose) {
      this.store.isShown = false
    }
  }

  public componentWillUnmount() {
    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
  }

  public render() {
    const { shouldHideBandCreation } = this.props
    return (
      <>
        {this.renderHandleButton()}
        {this.store.isShown && (
          <BaseGroupingOptionsPopup
            store={this.store}
            shouldHideBandCreation={shouldHideBandCreation}
          />
        )}
      </>
    )
  }

  private get store() {
    return this.props.store
  }

  private renderHandleButton() {
    const { CustomHandleButton, isDisabled } = this.props
    const { groupByCaption, toggleGroupingOptions, isShown } = this.store

    if (CustomHandleButton) {
      return (
        <CustomHandleButton
          onClick={toggleGroupingOptions}
          isEnabled={!isDisabled}
          isActive={isShown}
          title={groupByCaption}
          icon={<Icon icon={IconNames.BOX} />}
        />
      )
    }

    return (
      <FilterHandleButton
        className="grouping-options no-flex-children"
        onClick={toggleGroupingOptions}
        isSelected={isShown}
        isActive={true}
      >
        <span>{this.groupByCaption}</span>
      </FilterHandleButton>
    )
  }

  private get groupByCaption() {
    const { groupByCaption } = this.store
    return groupByCaption
      ? Localization.translator.groupByX(groupByCaption)
      : Localization.translator.groupBy
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ActivityDetails from '../../components/ActivityDetails/ActivityDetails'
import DesktopInitialState from '../../stores/DesktopInitialState'
import DesktopActivityListStore from '../SimpleGanttView/components/DesktopActivityList.store'
import UnsafeSideBar from '../SimpleGanttView/components/SideBar/SideBar'

const SideBar = withErrorBoundary(UnsafeSideBar)

interface INotificationDetailsProps {
  activitiesStore?: ActivitiesStore
  state?: DesktopInitialState
  backClicked: () => void

  activityPresetsStore?: ActivityPresetsStore
  activityFiltersStore?: ActivityFiltersStore
  activityDetailsStore?: ActivityDetailsStore
  statusUpdatesStore?: StatusUpdatesStore
  rfisStore?: RfisStore
  flagsStore?: FlagsStore
  scheduleCommentsStore?: ScheduleCommentsStore
  categoriesOfVarianceStore?: CategoriesOfVarianceStore
  safetyHazardsStore?: SafetyHazardsStore
  activityAssignmentsStore?: ActivityAssignmentsStore
  activityFollowingsStore?: ActivityFollowingsStore
  deliveriesStore?: DeliveriesStore
  projectDateStore?: ProjectDateStore
  sitemapsStore?: SitemapsStore
}

@inject(
  'activitiesStore',
  'state',
  'activityPresetsStore',
  'activityFiltersStore',
  'activityDetailsStore',
  'statusUpdatesStore',
  'rfisStore',
  'flagsStore',
  'scheduleCommentsStore',
  'categoriesOfVarianceStore',
  'safetyHazardsStore',
  'activityAssignmentsStore',
  'activityFollowingsStore',
  'deliveriesStore',
  'projectDateStore',
  'sitemapsStore',
)
@observer
export default class NotificationActivityDetails extends React.Component<INotificationDetailsProps> {
  private readonly store: DesktopActivityListStore

  public constructor(props: INotificationDetailsProps) {
    super(props)

    this.store = new DesktopActivityListStore(
      props.state,
      props.activitiesStore,
      props.activityPresetsStore,
      props.activityFiltersStore,
      props.activityDetailsStore,
      props.statusUpdatesStore,
      props.rfisStore,
      props.flagsStore,
      props.scheduleCommentsStore,
      props.categoriesOfVarianceStore,
      props.safetyHazardsStore,
      props.activityAssignmentsStore,
      props.activityFollowingsStore,
      props.deliveriesStore,
      props.projectDateStore,
      props.sitemapsStore,
    )
  }

  public UNSAFE_componentWillMount() {
    this.props.state.activityList.isActivityDetailsPanelDisplayed = true
  }

  public render() {
    return (
      <div>
        {this.props.activitiesStore.selectedActivity?.code ? (
          <ActivityDetails
            activityId={this.props.activitiesStore.selectedActivity?.code}
            backClicked={this.props.backClicked}
            hideEditButton={true}
          />
        ) : (
          <SideBar
            activityListStore={this.store}
            shouldPreventScrollToBottom={true}
          />
        )}
      </div>
    )
  }
}

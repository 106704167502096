import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import mapboxgl, { LngLat, LngLatBounds } from 'mapbox-gl'
import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IFormsConfigurations, IProjectAddressInput } from '~/client/graph'
import desktopRoutes from '~/client/src/desktop/constants/desktopRoutes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import { Loader } from '~/client/src/shared/components/Loader'
import {
  getAdressFromCoords,
  mapboxFeatureToAddress,
} from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor.store'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubMapboxAddressInput from '~/client/src/shared/components/StruxhubInputs/StruxhubMapboxAddressInput/StruxhubMapboxAddressInput'
import TooltipWrapper from '~/client/src/shared/components/TooltipWrapper/TooltipWrapper'
import { IMapBoxFeature } from '~/client/src/shared/interfaces/IMapBoxFeature'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Sitemap, {
  ISitemapGeoPosition,
} from '~/client/src/shared/models/Sitemap'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { ProjectSetUpSteps } from '~/client/src/shared/stores/InitialState'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import {
  NORTH_HEADING,
  WORLD_BOUNDS,
  getAddressCopy,
  toAddressBoundsInputFromMapBox,
  toLngLat,
  toLngLatBounds,
} from '~/client/src/shared/utils/Address'

import { MapBoxEditor } from '../../../../../shared/components/MapBoxEditor/MapBoxEditor'
import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import ProjectSetUpPage from '../../ProjectSetUpPages'
import GeneralSitemapSetUpStore from '../AppsSitemap/GeneralSitemapSetUp.store'
import ProjectSettingsStore from '../ProjectWorkspaceSetUp/ProjectSettings.store'
import {
  areLngLatBoundsEqual,
  areLngLatEqual,
  getMapBoxPlaces,
} from '../ProjectWorkspaceSetUp/components/MapBoxView'
import ProjectCreationStore from './ProjectCreation.store'

import './ProjectCreation.scss'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore
  eventsStore?: DesktopEventStore
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  locationAttributesStore?: LocationAttributesStore
  sitemapItemsStore?: SitemapItemsStore
  fileUploadingStore?: FileUploadingStore
  common?: DesktopCommonStore
  syncRestrictionsStore?: SyncRestrictionsStore
  activityFiltersStore?: ActivityFiltersStore
  userProjectsStore?: UserProjectsStore
  tagsStore?: TagsStore
  projectsStore?: ProjectsStore
  graphExecutorStore?: GraphExecutorStore
  projectDateStore?: ProjectDateStore
}

const regExp = /^[+-]?([0-9]*[.])?[0-9]+$/

const formatTooltip = 'Follow the next format: longitude,latitude'
const createNewProject = 'Create new project'
const projectAddress = 'Project address'
const longitudeLatitude = 'Longitude-Latitude'
const useYourOwnPlan = 'Do you want to upload your project site plan?'
const uploadMyPlan = 'Yes, upload my plan'
const savingProject = 'Saving Project'
const noIWillDoitLater = 'No, I will do it later'
const uploadSitePlan = 'Upload site plan'

const notUniqueCodeMessage = (code: string) =>
  `The project with the code [${code}] already exists. Try another value`

const ZOOM_ON_ADDRESS = 16
const defaultCoordinates = '0, 0'

@inject(
  'projectsStore',
  'eventsStore',
  'sitemapsStore',
  'basemapsStore',
  'locationAttributesStore',
  'sitemapItemsStore',
  'fileUploadingStore',
  'common',
  'syncRestrictionsStore',
  'activityFiltersStore',
  'userProjectsStore',
  'tagsStore',
  'graphExecutorStore',
  'projectDateStore',
)
@observer
export default class ProjectCreation extends React.Component<IProps> {
  @observable private file: File = null
  @observable private shouldShowModal: boolean = false
  @observable private coordinates: string = defaultCoordinates

  private readonly generalSitemapSetupStore: GeneralSitemapSetUpStore
  private readonly store: ProjectCreationStore

  @observable private viewport: ISitemapGeoPosition = {
    latitude: 0,
    longitude: 0,
    zoom: 6,
    bearing: NORTH_HEADING,
    pitch: 0,
    bounds: {
      ne: WORLD_BOUNDS._ne,
      sw: WORLD_BOUNDS._sw,
    },
  }
  @observable private latitude: number = 0
  @observable private longitude: number = 0
  @observable private sitemapName: string = 'new sitemap'
  private readonly projectSettingsStore: ProjectSettingsStore
  private sitemapToSave: Sitemap = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ProjectCreationStore(props.graphExecutorStore)

    this.generalSitemapSetupStore = new GeneralSitemapSetUpStore(
      props.eventsStore,
      props.sitemapsStore,
      props.basemapsStore,
      props.locationAttributesStore,
      props.sitemapItemsStore,
      props.fileUploadingStore,
      props.syncRestrictionsStore,
      props.activityFiltersStore,
      props.userProjectsStore,
      props.tagsStore,
    )

    this.projectSettingsStore = new ProjectSettingsStore(
      props.projectsStore,
      props.eventsStore,
      props.projectSetUpPageStore,
      props.userProjectsStore,
      props.graphExecutorStore,
      props.projectDateStore,
    )
    this.projectSettingsStore.setDefaultProjectWorkingHours()

    props.eventsStore.appState.projectCreationStep =
      ProjectSetUpSteps.ProjectDetails

    this.projectSettingsStore.projectAddress =
      this.props.eventsStore.appState.getDefaultProjectAddress()
    const { bearing, zoom, pitch, bounds } =
      this.projectSettingsStore.projectAddress
    const { latitude, longitude } = props.eventsStore.appState

    this.latitude = latitude
    this.longitude = longitude
    this.viewport.latitude = latitude
    this.viewport.longitude = longitude
    this.viewport.zoom = zoom
    this.viewport.pitch = pitch
    this.viewport.bounds = bounds
    this.viewport.bearing = bearing
    this.coordinates = `${this.longitude},${this.latitude}`
  }

  public UNSAFE_componentWillMount() {
    this.projectSettingsStore.enableNewProjectSelection()
  }

  public async componentDidMount() {
    const item = await getAdressFromCoords(this.latitude, this.longitude)
    this.mapBoxOnAddressValueChanged(item)
  }

  @computed
  public get isLoading(): boolean {
    const { loading } = this.props.eventsStore.appState
    return loading.get(e.ACTIVATE_PROJECT)
  }

  @computed
  public get isSavingProject(): boolean {
    const { loading } = this.props.eventsStore.appState

    return (
      loading.get(e.SAVE_PROJECT) ||
      loading.get(e.SAVE_SITEMAP) ||
      loading.get(e.SAVE_BASEMAP)
    )
  }

  public render() {
    if (this.isLoading) {
      return <Loader hint={Localization.translator.loading} />
    }

    return (
      <div className="row full-width full-height project-creation">
        {this.isSavingProject && (
          <div className="absolute full-width full-height opacity-backgroung unclickable-element bg-light-cool-grey">
            <Loader hint={savingProject} />
          </div>
        )}
        {this.shouldShowModal && (
          <>
            <div className="absolute full-width full-height opacity-backgroung" />
            {!this.isSavingProject && this.renderModal()}
          </>
        )}
        {this.renderContent()}
        {this.renderRightTable()}
      </div>
    )
  }

  private renderModal() {
    return (
      <div className="col bg-white select-sitemap-option-menu">
        <div className="text size24 pb50 px10">{uploadSitePlan}</div>
        <div className="text large pb20 px10">{useYourOwnPlan}</div>

        <div className="row">
          <div className="col">
            <div className="row y-center">
              <div
                className="relative pointer mr10 text blue-highlight large bold center"
                onClick={this.onCreateProjectClick}
              >
                {noIWillDoitLater}
              </div>
              <div className="upload-map-dnd-area pointer mr10 brada10">
                {/* TODO: @Val use BaseActionButton component instead of div with "submit-project-button class" */}
                <div
                  className={classList({
                    'submit-project-button pa10 col y-center x-center text large no-grow x-center bold light':
                      true,
                    disabled: this.isButtonDisabled,
                  })}
                  onClick={this.useMyPlan}
                >
                  {uploadMyPlan}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderContent() {
    return <div className="full-height">{this.renderSecondStep()}</div>
  }

  private renderSecondStep() {
    const {
      mapBoxEditorStore,
      sitemapItemsSetupStore: {
        saveSitemapItem,
        selectedSitemapItem,
        creatableAttributeType,
      },
    } = this.generalSitemapSetupStore

    return (
      <div
        className={classList({
          'map-content relative': true,
          'inactive-element': this.shouldShowModal,
        })}
      >
        <MapBoxEditor
          store={mapBoxEditorStore}
          viewport={this.viewport}
          setViewport={this.setViewport}
          onAddressChanged={this.onAddressChanged}
          shouldShowProjectMarker={true}
          latitude={this.latitude}
          longitude={this.longitude}
          updateLngLat={this.updateLngLat}
          saveSitemapItem={saveSitemapItem}
          selectedSitemapItem={selectedSitemapItem}
          creatableAttributeType={creatableAttributeType}
        />
      </div>
    )
  }

  private closeProjectCreation = () => {
    this.props.eventsStore.appState.projectCreationStep = null
    this.props.projectSetUpPageStore.navigateTo(
      ProjectSetUpPage.PROJECT_DETAILS,
    )
  }

  private renderRightTable() {
    const { isProjectCodeBeingChecked } = this.store

    return (
      <div className="full-height col side-table bl-palette-grey pa10">
        <div className="row pb20 pt10">
          <Icon
            className="no-grow pointer"
            icon={IconNames.CROSS}
            size={22}
            onClick={this.closeProjectCreation}
          />
          <div className="text size22 center bold">{createNewProject}</div>
        </div>
        {this.renderLocationRightTable()}
        <div className="col y-end">
          <BaseActionButton
            className="primary-theme-inverted"
            title={Localization.translator.submit_verb}
            isEnabled={!this.isButtonDisabled && !isProjectCodeBeingChecked}
            onClick={this.onButtonClick}
            isLoading={isProjectCodeBeingChecked}
          />
        </div>
      </div>
    )
  }

  private get isButtonDisabled(): boolean {
    const {
      projectName: activeProjectName,
      projectCode,
      isProjectErrorMessageVisible,
      isProjectCodeErrorMessageVisible,
    } = this.projectSettingsStore

    return (
      isProjectErrorMessageVisible ||
      !activeProjectName ||
      isProjectCodeErrorMessageVisible ||
      !projectCode ||
      !this.projectAddressString.trim()
    )
  }

  private onCreateProjectClick = async () => {
    this.props.eventsStore.appState.projectCreationStep = null
    this.props.eventsStore.appState.loading.set(e.SAVE_PROJECT, true)
    const projectId = await this.projectSettingsStore.submitForm(
      this.saveSitemap,
    )

    if (this.sitemapToSave) {
      const formsSettings = {
        sitemaps: [
          {
            sitemapId: this.sitemapToSave.id,
            order: 0,
          },
        ],
        projectId,
      } as IFormsConfigurations
      this.props.eventsStore.dispatch(
        e.SAVE_FORMS_CONFIGURATIONS,
        formsSettings,
      )
      this.props.eventsStore.dispatch(
        e.SAVE_LOGISTICS_CONFIGURATIONS,
        formsSettings,
      )
      this.props.eventsStore.dispatch(
        e.SAVE_ACTIVITIES_CONFIGURATIONS,
        formsSettings,
      )
      this.props.eventsStore.dispatch(
        e.SAVE_DELIVERY_CONFIGURATIONS,
        formsSettings,
      )
    }

    this.sitemapToSave = null
  }

  private onButtonClick = async () => {
    const { projectCode } = this.projectSettingsStore

    try {
      // eslint-disable-next-line no-var
      var isUnique = await this.store.checkProjectCodeUniqueness(
        this.projectSettingsStore.projectCode,
      )
    } catch (e) {
      return alert(
        Localization.translator.somethingWentWrongDuringAPIInteraction,
      )
    }

    if (isUnique) {
      this.shouldShowModal = true
      this.props.eventsStore.appState.projectCreationStep =
        ProjectSetUpSteps.UploadSitePlan
    } else {
      alert(notUniqueCodeMessage(projectCode))
      this.projectSettingsStore.resetProjectCode()
    }
  }

  private useMyPlan = () => {
    this.props.eventsStore.appState.loading.set(e.SAVE_PROJECT, true)
    this.projectSettingsStore.submitForm(this.redirectToMapSetup)
  }

  private redirectToMapSetup = async () => {
    this.props.eventsStore.appState.loading.set(e.SAVE_PROJECT, false)
    this.props.common._displayView(desktopRoutes.MAP_SETUP)
  }

  private saveSitemap = async (projectId: string) => {
    const {
      mapBoxEditorStore,
      mapBoxEditorStore: { getDataURL, canvasMap, setSourceBounds },
      sitemapSetupStore: { uploadNewSitemap },
      base64ImageToFile,
    } = this.generalSitemapSetupStore

    const base64Image = getDataURL()
    const file = base64ImageToFile(base64Image, this.sitemapName)
    setSourceBounds()
    this.sitemapToSave = await uploadNewSitemap(
      this.file || file,
      this.sitemapName,
      false,
      projectId,
      this.viewport,
      canvasMap.getMap().transform.width,
      canvasMap.getMap().transform.height,
      mapBoxEditorStore.getCorners,
    )
    this.props.eventsStore.appState.loading.set(e.SAVE_PROJECT, false)

    this.redirectToMapSetup()
  }

  private get projectAddressString(): string {
    const { address, city, state, zipcode, country } = this.activeProjectAddress

    return `${address} ${city} ${state} ${zipcode} ${country}`
  }

  private get activeProjectAddress(): IProjectAddressInput {
    const { projectAddress: activeProjectAddress } = this.projectSettingsStore

    return activeProjectAddress || ({ projectId: null } as IProjectAddressInput)
  }

  private renderLocationRightTable() {
    const {
      projectDetailsDescriptions: { projectCodeError, projectNameError },
      projectName,
      projectCode,
    } = Localization.translator
    const {
      projectAddressSuggestions,
      projectName: activeProjectName,
      projectCode: activeProjectCode,
      isProjectErrorMessageVisible,
      isProjectCodeErrorMessageVisible,
    } = this.projectSettingsStore
    const { address } = this.activeProjectAddress

    return (
      <div className="col y-start no-grow mb20">
        <StruxhubInput
          id={projectName}
          label={projectName}
          isRequired={true}
          isValid={!isProjectErrorMessageVisible}
          value={activeProjectName}
          onChange={this.onProjectNameChange}
          onValueReset={this.onProjectNameReset}
          validationMessage={isProjectErrorMessageVisible && projectNameError}
        />
        <StruxhubInput
          id={projectCode}
          label={projectCode}
          isRequired={true}
          isValid={!isProjectCodeErrorMessageVisible}
          value={activeProjectCode}
          onChange={this.onProjectCodeChange}
          onValueReset={this.onProjectCodeReset}
          validationMessage={
            isProjectCodeErrorMessageVisible && projectCodeError
          }
        />
        <div className="col">
          <StruxhubMapboxAddressInput
            label={projectAddress}
            isRequired={true}
            fullAddressLabel={this.projectAddressString}
            items={projectAddressSuggestions}
            onItemSelect={this.mapBoxOnAddressValueChanged}
            onQueryChange={this.mapBoxOnQueryChange}
            value={address}
          />
          <div className="row">
            <TooltipWrapper
              className="coordinates-wrapper"
              content={formatTooltip}
            >
              <StruxhubInput
                id="latLng"
                label={longitudeLatitude}
                isRequired={true}
                value={this.coordinates}
                onChange={this.updateCoordinates}
                onBlur={this.setCoordinates}
              />
            </TooltipWrapper>
          </div>
        </div>
      </div>
    )
  }

  private updateCoordinates = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.coordinates = event.target.value
  }

  private setCoordinates = async () => {
    const [lng, lat] = this.coordinates.trim().replace(/\s/g, '').split(',')

    if (!lng.match(regExp) || !lat.match(regExp)) {
      this.coordinates = `${this.longitude},${this.latitude}`
      return
    }

    this.longitude = Number(lng)
    this.latitude = Number(lat)

    const item = await getAdressFromCoords(this.latitude, this.longitude)
    this.mapBoxOnAddressValueChanged(item)
  }

  private mapBoxOnQueryChange = async (query: string): Promise<void> => {
    const data: GeoJSON.FeatureCollection = await getMapBoxPlaces(
      query,
      mapboxgl.accessToken,
    )

    this.projectSettingsStore.setProjectAddressSuggestions(
      data.features ? (data.features as IMapBoxFeature[]) : [],
    )
  }

  private mapBoxOnAddressValueChanged = (item: IMapBoxFeature) => {
    if (!item) {
      return
    }

    const addr = mapboxFeatureToAddress(item)
    this.latitude = addr.center.lat
    this.longitude = addr.center.lng

    this.viewport.zoom = ZOOM_ON_ADDRESS
    this.viewport.latitude = addr.center.lat
    this.viewport.longitude = addr.center.lng
    this.viewport.bearing = addr.bearing
    this.viewport.bounds = addr.bounds

    if (!addr.zoom) {
      addr.zoom = ZOOM_ON_ADDRESS
      addr.pitch = this.viewport.pitch
    }

    this.projectSettingsStore.setProjectAddress(addr)
    this.coordinates = `${this.longitude}, ${this.latitude}`
  }

  private setViewport = (viewport: ISitemapGeoPosition) => {
    this.viewport = viewport
    if (
      this.generalSitemapSetupStore?.mapBoxEditorStore?.canvasMap?.getMap?.()
    ) {
      this.viewport.bounds = toAddressBoundsInputFromMapBox(
        this.generalSitemapSetupStore.mapBoxEditorStore.canvasMap
          .getMap()
          .getBounds(),
      )
    } else {
      this.viewport.bounds = {
        ne: WORLD_BOUNDS._ne,
        sw: WORLD_BOUNDS._sw,
      }
    }

    const bounds = toLngLatBounds(this.viewport.bounds)
    this.onMapBoxDataChanged(
      this.viewport.bearing,
      bounds,
      toLngLat({
        lng: this.viewport.longitude,
        lat: this.viewport.latitude,
      }),
      this.viewport.zoom,
      this.viewport.pitch,
    )
  }

  private updateLngLat = (latitude: number, longitude: number) => {
    this.longitude = longitude
    this.latitude = latitude
    this.coordinates = `${this.longitude}, ${this.latitude}`
  }

  private onAddressChanged = ({
    address,
    country,
    city,
    state,
    zipcode,
    center,
  }: IProjectAddressInput) => {
    this.projectSettingsStore.projectAddress.address = address
    this.projectSettingsStore.projectAddress.country = country
    this.projectSettingsStore.projectAddress.city = city
    this.projectSettingsStore.projectAddress.state = state
    this.projectSettingsStore.projectAddress.zipcode = zipcode
    this.longitude = center.lng
    this.latitude = center.lat
  }

  private onMapBoxDataChanged = (
    bearing: number,
    bounds: LngLatBounds,
    center: LngLat,
    zoom: number,
    pitch: number,
  ) => {
    const addr = getAddressCopy(this.projectSettingsStore.projectAddress)
    let changed = false

    if (addr.bearing !== bearing) {
      changed = true
      addr.bearing = bearing
    }

    if (!areLngLatBoundsEqual(toLngLatBounds(addr.bounds), bounds)) {
      changed = true
      addr.bounds = toAddressBoundsInputFromMapBox(bounds)
    }

    if (!areLngLatEqual(toLngLat(addr.center), center)) {
      changed = true
      addr.center = center
    }

    if (zoom !== addr.zoom) {
      changed = true
      addr.zoom = zoom
    }

    if (pitch !== addr.pitch) {
      changed = true
      addr.pitch = pitch
    }

    if (changed && this.generalSitemapSetupStore.mapBoxEditorStore.canvasMap) {
      addr.bounds = toAddressBoundsInputFromMapBox(
        this.generalSitemapSetupStore.mapBoxEditorStore.canvasMap
          .getMap()
          .getBounds(),
      )
      this.projectSettingsStore.setProjectAddress(addr)
    }
  }

  private onProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value: projectName = '' } = event.target

    this.projectSettingsStore.setProjectName(projectName)
    this.projectSettingsStore.setProjectCode(projectName)
  }

  private onProjectNameReset = () => {
    this.projectSettingsStore.setProjectName('')
  }

  private onProjectCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.projectSettingsStore.setProjectCode(event.target.value)
  }

  private onProjectCodeReset = () => {
    this.projectSettingsStore.setProjectCode('')
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  IInstructions,
  IPermitTypeField,
  PermitFieldType,
} from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import { conditionalPermitFields } from '~/client/src/shared/constants/PermitFieldTypeConstants'

import FieldConfigurator from '../../FieldConfigurator/FieldConfigurator'
import FormFieldsModal from '../../FormFieldsModal'
import Instructions from '../../Instructions/Instructions'
import FormConfiguratorStore from '../FormConfigurator.store'
import ConditionalFieldsConfigurator from './ConditionalFieldsConfigurator'
import MaterialSubFieldsConfigurator from './MaterialSubFieldsConfigurator'
import TableFieldsConfigurator from './TableFieldsConfigurator'

interface IProps {
  depth: number
  field: IPermitTypeField

  renderNonWorkTimeSelector(field: IPermitTypeField): JSX.Element

  store: FormConfiguratorStore

  isCollapsed?: boolean
  onCollapseToggle?(): void
}

@observer
export default class FormFieldConfigurator extends React.Component<IProps> {
  public render() {
    const { field, store } = this.props

    return (
      <div className="col relative py4">
        <div
          className={classList({
            'forms-field-row row x-center pl15': true,
            'y-center': field.type === PermitFieldType.Divider,
            'y-start': field.type !== PermitFieldType.Divider,
          })}
        >
          {this.addNewFieldBtn}
          <div className="col field-configuration full-width relative overflow-hidden">
            {this.fieldConfigurator}
            {this.areConditionalFieldsShown && (
              <ConditionalFieldsConfigurator
                field={field}
                allConditionalFields={store.conditionalFields}
                addNewConditionalField={store.addNewConditionalField}
                updateConditionalField={store.updateConditionalField}
                updateConditionalChecklist={
                  store.updateConditionalChecklistField
                }
                updateConditionalRestrictions={
                  store.updateConditionalRestrictionOptions
                }
                removeConditionalField={store.removeConditionalField}
                tableConfiguratorRenderer={this.renderTableConfigurator}
              />
            )}
          </div>
          {this.deleteIcon}
        </div>
      </div>
    )
  }

  private get fieldConfigurator(): JSX.Element {
    const {
      field,
      renderNonWorkTimeSelector,
      store,
      isCollapsed,
      onCollapseToggle,
    } = this.props
    const {
      updateField,
      changeFieldRestrictedOptions,
      changePermitTypeChecklist,
    } = store

    if (field.instructions) {
      return (
        <Instructions
          className="mb30"
          instructions={field.instructions}
          onChange={this.changePermitTypeInstruction}
        />
      )
    }

    return (
      <FieldConfigurator
        field={field}
        onChange={updateField}
        onChecklistUpdate={changePermitTypeChecklist}
        onLocationRestrictionsUpdate={changeFieldRestrictedOptions}
        nonWorkTimeControl={renderNonWorkTimeSelector(field)}
        isCollapsed={isCollapsed}
        onCollapseToggle={onCollapseToggle}
        tableConfiguratorRenderer={this.renderTableConfigurator}
        materialSubFieldsRenderer={this.renderMaterialSubFieldsConfigurator}
      />
    )
  }

  private renderTableConfigurator = (field: IPermitTypeField): JSX.Element => {
    if (field.type !== PermitFieldType.Table) {
      return null
    }

    const {
      conditionalFields,
      addNewConditionalField,
      updateConditionalField,
      updateConditionalChecklistField,
      updateConditionalRestrictionOptions,
      removeConditionalField,
    } = this.props.store

    return (
      <TableFieldsConfigurator
        field={field}
        allConditionalFields={conditionalFields}
        addNewTableField={addNewConditionalField}
        updateTableField={updateConditionalField}
        updateTableChecklist={updateConditionalChecklistField}
        updateLocationRestrictions={updateConditionalRestrictionOptions}
        removeTableField={removeConditionalField}
      />
    )
  }

  private renderMaterialSubFieldsConfigurator = (
    field: IPermitTypeField,
  ): JSX.Element => {
    if (field.type !== PermitFieldType.Material) {
      return null
    }

    const {
      conditionalFields,
      updateConditionalField,
      updateConditionalRestrictionOptions,
      addNewConditionalField,
    } = this.props.store

    return (
      <MaterialSubFieldsConfigurator
        field={field}
        allConditionalFields={conditionalFields}
        updateMaterialSubField={updateConditionalField}
        updateLocationRestrictions={updateConditionalRestrictionOptions}
        addMaterialSubField={addNewConditionalField}
      />
    )
  }

  private get addNewFieldBtn(): JSX.Element {
    const { field, store, depth } = this.props
    const { getRestrictedFieldsByDepth, singleUseFields, isAddBtnHidden } =
      store

    if (isAddBtnHidden) {
      return null
    }

    return (
      <div
        className={classList({
          'absolute row x-center y-center forms-add-icon-container': true,
          mt20: field.type !== PermitFieldType.Divider,
        })}
      >
        <FormFieldsModal
          addNewField={this.addNewField}
          restrictedFields={getRestrictedFieldsByDepth(depth)}
          disabledFields={singleUseFields}
        >
          <Icons.Add className="forms-add-icon pointer relative no-flex" />
        </FormFieldsModal>
      </div>
    )
  }

  private get deleteIcon(): JSX.Element {
    const { field, store } = this.props

    if (!store.canRemoveField(field)) {
      return <div className="mw20 ml2" />
    }

    return (
      <Icons.CrossGrey
        className={classList({
          'forms-del-icon text light pointer ml4 no-grow opacity5 row x-center y-center':
            true,
          mt20: field.type !== PermitFieldType.Divider,
        })}
        onMouseDown={this.showRemoveDialog}
      />
    )
  }

  private get areConditionalFieldsShown(): boolean {
    const { isMultiple, type } = this.props.field
    return !isMultiple && conditionalPermitFields.includes(type)
  }

  private changePermitTypeInstruction = (instructions: IInstructions) => {
    const { field, store } = this.props
    store.changePermitTypeInstruction(field, instructions)
  }

  private addNewField = (fieldType: PermitFieldType) => {
    const { field, store } = this.props
    store.addNewField(fieldType, field)
  }

  private showRemoveDialog = () => {
    const { field, store } = this.props
    store.showRemoveDialog(field.id)
  }
}

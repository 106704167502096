import * as React from 'react'

import { FileInput, Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import OpacityBackground from '~/client/src/desktop/views/SimpleGanttView/components/OpacityBackground/OpacityBackground'
import DndArea from '~/client/src/shared/components/DndArea/DndArea'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import { ACCEPTED_SITEMAP_EXTENSIONS } from '../../GeneralSitemapSetUp'
import DeliverySitemapSetUpStore from '../../GeneralSitemapSetUp.store'
import PdfToImageConverter from '../PdfToImageConverter'
import SitemapRibbonStore from './SitemapRibbon.store'

import './SitemapRibbon.scss'

export interface IProps {
  store: DeliverySitemapSetUpStore
  projectDateStore?: ProjectDateStore
  shouldHideAdditionMenu?: boolean
  shouldFixTopPosition?: boolean
  isHidden?: boolean
  state?: DesktopInitialState
}

const createNew = 'Create new'
const EXTENSIONS_SEPARATOR = ','
const updateBasemap = 'Update basemap'
const mapName = 'Plan name'
const deleteSitemap = 'Delete sitemap'
const withThisOne = '... with this one.'
const replaceThisBasemap = 'Replace this base map'
const dragMapHere = 'Drag map here'
const acceptedExtensions = '(.pdf, .png, .tif accepted)'
const selectPage = 'Select page'
const uploadSitePlan = 'Upload site plan'
const browseYourHardDrive = 'Browse your hard drive'
const basemapUploaded = 'Basemap uploaded'
const uploadingBasemap = 'Uploading basemap'

const BASEMAP_TITLE_WIDTH = 240
const BASEMAP_TITLE_HEIGHT = 30

@inject('projectDateStore', 'state')
@observer
export default class SitemapRibbon extends React.Component<IProps> {
  private store: SitemapRibbonStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new SitemapRibbonStore(
      props.store.sitemapSetupStore,
      props.store.sitemapItemsSetupStore,
      props.store,
      props.state,
    )
  }

  public UNSAFE_componentWillMount() {
    const { selectInitialSitemap, selectedSitemap } =
      this.props.store.sitemapSetupStore

    if (!selectedSitemap) {
      selectInitialSitemap()
    }
  }

  public render() {
    const {
      shouldHideAdditionMenu,
      isHidden,
      store: { currentStep },
    } = this.props
    const {
      toggleCreateMenu,
      toggleTooltipMenu,
      shouldShowCreateMenu,
      shouldShowTooltipMenu,
      setConvertedBasemap,
      setConvertedSitemap,
      sitemapPdfFile,
      pdfFile,
    } = this.store

    return (
      <>
        <div className="sitemap-ribbon no-grow row px15 absolute">
          {!currentStep && !isHidden && (
            <>
              <div
                className="text white bg-blue-bright brada24 px10 py5 mr10 large title-row pointer"
                onClick={toggleCreateMenu}
              >
                <Icon
                  icon={IconNames.PLUS}
                  intent={Intent.NONE}
                  className="mr5"
                />
                {createNew}
              </div>
              {!shouldHideAdditionMenu && (
                <div
                  className="actions-menu row x-center y-center brada24 no-grow ba-palette-grey bg-white pointer"
                  onClick={toggleTooltipMenu}
                >
                  <Icon icon={IconNames.CARET_DOWN} iconSize={12} />
                </div>
              )}
            </>
          )}
        </div>
        {shouldShowCreateMenu && (
          <>
            <OpacityBackground isAnyPopupOpened={true} />
            {this.renderMapUploadMenu()}
          </>
        )}
        {shouldShowTooltipMenu && this.renderTooltipMenu()}
        {pdfFile && (
          <PdfToImageConverter
            pdfFile={pdfFile}
            uploadImage={setConvertedBasemap}
          />
        )}
        {sitemapPdfFile && (
          <PdfToImageConverter
            pdfFile={sitemapPdfFile}
            uploadImage={setConvertedSitemap}
          />
        )}
      </>
    )
  }

  private renderTooltipMenu() {
    const {
      shouldShowEditBasemapMenu,
      toggleTooltipMenu,
      showDeleteDialog,
      toggleEditMenu,
    } = this.store

    if (shouldShowEditBasemapMenu) {
      return this.renderBasemapMenu()
    }

    return (
      <MenuCloser
        className="absolute tooltip-popup ba-black"
        closeMenu={toggleTooltipMenu}
      >
        <div className="col">
          <div className="option text large pa10" onClick={toggleEditMenu}>
            {updateBasemap}
          </div>
          <div className="option text large pa10" onClick={showDeleteDialog}>
            {deleteSitemap}
          </div>
        </div>
      </MenuCloser>
    )
  }

  private get submitTitle(): string {
    return this.store.isPdfTypeBasemapSelected
      ? selectPage
      : Localization.translator.save
  }

  private renderBasemapMenu() {
    const { selectedBasemap } = this.props.store.sitemapSetupStore
    const {
      isUploading,
      basemap,
      toggleEditMenu,
      setSitemapBasemap,
      setPdfOrUpdateBasemap,
      hideEditMenu,
    } = this.store

    const isSaveButtonActive = !isUploading && !!basemap
    return (
      <MenuCloser
        closeMenu={toggleEditMenu}
        className="col absolute tooltip-popup brada10"
      >
        <div className="row px20 pt20 mb20">
          <div className="col">
            <div className="text large bold">{replaceThisBasemap}</div>
            <div>
              <DesktopFileInput
                id={selectedBasemap.id}
                name=""
                value={selectedBasemap.source}
                isReadonly={true}
                textClassName="hint"
                shouldHideIconAndOutline={true}
                shouldHideName={true}
              />
            </div>
            <div
              style={{
                height: BASEMAP_TITLE_HEIGHT,
                maxWidth: BASEMAP_TITLE_WIDTH,
              }}
              className="text large text-ellipsis"
            >
              {selectedBasemap.fileName}
            </div>
          </div>
          <div className="col y-center no-grow mx10">
            <Icon icon={IconNames.ARROW_RIGHT} />
          </div>
          <div className="col">
            <div className="text large bold">{withThisOne}</div>
            {!basemap ? (
              <div className="general-sitemap-setup-dnd-area">
                <DndArea
                  fileType={FileType.Image}
                  accept={ACCEPTED_SITEMAP_EXTENSIONS}
                  title={this.title}
                  onDrop={setSitemapBasemap}
                  className="upload-schedule-dnd-container col mt20 x-center y-center"
                />
                {this.renderBrowseComputerLine()}
              </div>
            ) : (
              <>
                <DesktopFileInput
                  id={basemap.id}
                  name=""
                  value={basemap.source}
                  isReadonly={true}
                  textClassName="hint"
                  shouldHideIconAndOutline={true}
                />
                {this.renderBrowseComputerLine()}
              </>
            )}
          </div>
        </div>
        {this.renderActionBottomButtons(
          Localization.translator.clear,
          this.submitTitle,
          hideEditMenu,
          setPdfOrUpdateBasemap,
          isSaveButtonActive,
        )}
      </MenuCloser>
    )
  }

  private renderMapUploadMenu() {
    const { shouldFixTopPosition } = this.props
    const {
      sitemapName,
      isUploading,
      basemap,
      setSitemapBasemap,
      changeMapName,
      setPdfOrCreateSitemap,
      toggleCreateMenu,
    } = this.store

    const isSaveButtonActive = !isUploading && !!sitemapName && !!basemap

    return (
      <MenuCloser
        className={classList({
          'absolute map-upload-block': true,
          'fixed-top': shouldFixTopPosition,
        })}
        closeMenu={toggleCreateMenu}
      >
        <div className="col">
          <div className="text size24 mb30">{uploadSitePlan}</div>
          <div className="row y-start">
            <div>
              <div className="general-sitemap-setup-dnd-area">
                {!basemap ? (
                  <>
                    <DndArea
                      fileType={FileType.Image}
                      accept={ACCEPTED_SITEMAP_EXTENSIONS}
                      title={this.title}
                      onDrop={setSitemapBasemap}
                      className="upload-schedule-dnd-container col x-center y-center"
                    />
                    {this.renderBrowseComputerLine()}
                  </>
                ) : (
                  <DesktopFileInput
                    id={basemap.id}
                    name=""
                    value={basemap.source}
                    isReadonly={true}
                    textClassName="hint"
                    shouldHideIconAndOutline={true}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row mt5 relative overflow-hidden">
            <StruxhubInput
              label={mapName}
              isRequired={true}
              value={sitemapName}
              onChange={changeMapName}
            />
          </div>
          <div
            className={classList({
              'submit-button light pa10 full-width mt30': true,
              'inactive-element unclickable-element': !isSaveButtonActive,
            })}
            onClick={setPdfOrCreateSitemap}
          >
            {this.store.isUploading ? <Loader /> : Localization.translator.save}
          </div>
        </div>
      </MenuCloser>
    )
  }

  private renderActionBottomButtons(
    cancelText: string,
    applyText: string,
    cancelAction: () => void,
    applyAction: () => void,
    isApplyActive: boolean,
  ) {
    return (
      <div className="row bt-light-input-border">
        <div className="bt-input-border row x-around dialog-footer">
          <div
            className="text large ml20 mr10 row x-center y-center pointer action-button mt5 mb5"
            onClick={cancelAction}
          >
            {cancelText}
          </div>
          <div
            className={classList({
              'bg-blue-bright brada5 text white large mr20 ml10 row x-center y-center pointer action-button mt5 mb5':
                true,
              'inactive-element unclickable-element': !isApplyActive,
            })}
            onClick={applyAction}
          >
            {this.store.isUploading ? <Loader /> : applyText}
          </div>
        </div>
      </div>
    )
  }

  private get title() {
    if (this.store.isUploading) {
      return <Loader hint={uploadingBasemap} />
    }

    if (this.store.sitemapPdfFile) {
      return basemapUploaded
    }

    return (
      <div className="col text large no-grow x-center">
        <span>
          <Icons.Upload className="no-grow" /> {dragMapHere}
        </span>
        <span>{acceptedExtensions}</span>
      </div>
    )
  }

  private renderBrowseComputerLine = () => {
    return (
      <div>
        <FileInput
          className="top-controls-upload-csv-button tooltip-menu-option"
          disabled={false}
          inputProps={{
            onChange: this.store.setSitemapBasemapFromBrowse,
            accept: ACCEPTED_SITEMAP_EXTENSIONS.join(EXTENSIONS_SEPARATOR),
            value: EMPTY_STRING,
          }}
          text={
            <span className="row text tooltip-menu-option px10 large pointer light-blue bold x-end">
              {!this.store.basemap
                ? browseYourHardDrive
                : Localization.translator.change_verb}
            </span>
          }
        />
      </div>
    )
  }
}

import { action, computed, observable } from 'mobx'

import { ISitemapItem, ISitemapItemInput } from '~/client/graph'
import SitemapItem from '~/client/src/shared/models/SitemapItem'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import {
  DELETE_SITEMAP_ITEMS,
  SAVE_SITEMAP_ITEM,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import Guard from '~/client/src/shared/utils/Guard'

export default class SitemapItemsStore {
  @observable public isDataReceived = false

  public constructor(private eventsStore: EventsStore) {
    Guard.requireAll({
      eventsStore,
    })
  }

  @computed
  public get list(): SitemapItem[] {
    return Array.from(this.byId.values())
  }

  public get byId() {
    return this.eventsStore.appState.sitemapItems
  }

  @action.bound
  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @action.bound
  public receiveList(list: ISitemapItem[]) {
    this.clearList()

    list.forEach(dto => {
      const item = SitemapItem.fromDto(dto)
      this.byId.set(item.id, item)
    })

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: ISitemapItem) {
    if (dto) {
      this.byId.set(dto.id, SitemapItem.fromDto(dto))
    } else {
      this.byId.delete(id)
    }
  }

  @action.bound
  public save(sitemapItem: SitemapItem, callbackFn?: (id: string) => void) {
    const { id: projectId } = this.eventsStore.appState.activeProject

    const sitemapItemInput: ISitemapItemInput = {
      id: sitemapItem.id,
      name: sitemapItem.name,
      color: sitemapItem.color,
      iconName: sitemapItem.iconName,
      type: sitemapItem.type,
      assignedId: sitemapItem.assignedId,
      assignedTo: sitemapItem.assignedTo,
      parent: sitemapItem.parent,
      projectId,
      coordinates: sitemapItem.coordinates,
      shapeCoordinates: sitemapItem.shapeCoordinates,
      isReferenced: sitemapItem.isReferenced,
    }

    this.eventsStore.dispatch(SAVE_SITEMAP_ITEM, sitemapItemInput, callbackFn)
  }

  @action.bound
  public removeMany(sitemapItemIds: string[], callbackFn?: () => void) {
    if (!sitemapItemIds?.length) {
      return
    }

    this.eventsStore.dispatch(DELETE_SITEMAP_ITEMS, sitemapItemIds, callbackFn)
  }
}

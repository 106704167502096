import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import CustomActivityListFilter from '~/client/src/shared/types/CustomActivityListFilter'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'

import SavedItemsSelector from '../components/SavedFiltersSelector'

// localization: translated

export interface IProps {
  activeFilterId: string
  getEntitiesCountByCustomFilter: (
    filter: CustomActivityListFilter | CustomDeliveryListFilter,
  ) => number
  selectCustomFilterById: (id: string) => void
  editCustomFilter: () => void
  selectCustomFilter: (event) => void
  numberOfEntitiesTitle: string
  customFilters: CustomActivityListFilter[] | CustomDeliveryListFilter[]
}

@observer
export default class SavedFiltersContainer extends React.Component<IProps> {
  public render() {
    const {
      activeFilterId,
      selectCustomFilter,
      customFilters,
      selectCustomFilterById,
      editCustomFilter,
      getEntitiesCountByCustomFilter,
      numberOfEntitiesTitle,
    } = this.props

    return (
      <div>
        <div className="row no-flex-children x-between y-center bb-light-grey pb10">
          <div className="text extra-large bold">
            {Localization.translator.savedFilters}
          </div>
          <div className="text extra-large bold">{numberOfEntitiesTitle}</div>
        </div>
        <div className="mb20 no-outline-container">
          <SavedItemsSelector
            activeFilterId={activeFilterId}
            editCustomFilter={editCustomFilter}
            getEntitiesCountByCustomFilter={getEntitiesCountByCustomFilter}
            selectCustomFilterById={selectCustomFilterById}
            customFilters={customFilters}
            selectCustomFilter={selectCustomFilter}
          />
        </div>
      </div>
    )
  }
}

import { observable } from 'mobx'

import { LocationType } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import { sortLevels } from '../../utils/sortingFunctions'
import { areArraysEqual, copyArray } from '../../utils/util'
import Level from './Level'
import LocationAttributeBase from './LocationAttributeBase'

const DEFAULT_SKIP_INDEX_TEMPLATE = ''
const DEFAULT_LEVEL_BASENAME = 'Level'

interface IBuildingDto extends ILocationAttributeDto {
  levelsOrder?: string[]
  groundLevel?: string
  isGroundIndexZero?: boolean
  skipLevelIndexTemplate?: string
  levelBaseName?: string
}

export default class Building extends LocationAttributeBase<Building> {
  public static fromDto(dto: IBuildingDto) {
    const item = LocationAttributeBase.factory(
      Building,
      dto,
      SitemapAttributeIcon.Building,
    )

    item.levelsOrder = Object.values(dto.levelsOrder || {})
    item.groundLevel = dto.groundLevel
    item.isGroundIndexZero = !!dto.isGroundIndexZero
    item.skipLevelIndexTemplate =
      dto.skipLevelIndexTemplate || DEFAULT_SKIP_INDEX_TEMPLATE
    item.levelBaseName = dto.levelBaseName || DEFAULT_LEVEL_BASENAME

    return item
  }

  @observable public levelsOrder: string[] = []
  @observable public groundLevel: string
  @observable public isGroundIndexZero: boolean = false
  @observable
  public skipLevelIndexTemplate: string = DEFAULT_SKIP_INDEX_TEMPLATE
  @observable public levelBaseName: string = DEFAULT_LEVEL_BASENAME

  public type = LocationType.Building
  protected relatedFieldId = FieldIds.BUILDING
  protected model = Building

  public isEqual(dto: Building) {
    return (
      super.isEqual(dto) &&
      areArraysEqual(this.levelsOrder, dto.levelsOrder) &&
      this.groundLevel === dto.groundLevel &&
      this.isGroundIndexZero === dto.isGroundIndexZero &&
      this.skipLevelIndexTemplate === dto.skipLevelIndexTemplate &&
      this.levelBaseName === dto.levelBaseName
    )
  }

  public copy(): Building {
    const item = super.copy()

    item.levelsOrder = copyArray(this.levelsOrder || [])
    item.groundLevel = this.groundLevel
    item.isGroundIndexZero = this.isGroundIndexZero
    item.skipLevelIndexTemplate = this.skipLevelIndexTemplate
    item.levelBaseName = this.levelBaseName

    return item
  }

  public getDto(): IBuildingDto {
    const dto: IBuildingDto = super.getDto()

    dto.levelsOrder = this.levelsOrder
    dto.groundLevel = this.groundLevel
    dto.isGroundIndexZero = !!this.isGroundIndexZero
    dto.skipLevelIndexTemplate = this.skipLevelIndexTemplate
    dto.levelBaseName = this.levelBaseName

    return dto
  }

  public sortLevelsByCustomOrder(levels: Level[]): Level[] {
    if (!this.levelsOrder?.length) {
      return levels.sort((a, b) => sortLevels(b, a))
    }

    return levels.sort((a, b) => {
      let aPos = this.levelsOrder.indexOf(a.id)
      let bPos = this.levelsOrder.indexOf(b.id)

      if (aPos === -1 && bPos === -1) {
        return sortLevels(b, a)
      }

      if (aPos === -1) {
        aPos = levels.length
      }
      if (bPos === -1) {
        bPos = levels.length
      }

      return bPos - aPos
    })
  }
}

import { observable } from 'mobx'

import { IPosition, ISitemapCircle, SitemapItemShapeType } from '~/client/graph'

import { IBoundingBox } from './SitemapItemBase'

const DEFAULT_IS_DISPLAYED = true
const MAX_PERCENT = 100

const DEFAULT_LINE_WIDTH = 5
const DEFAULT_RADIUS = 8
// multiplier for turf distance measurer
export const FEET_TO_TURF_DISTANCE = 0.00913447810363004
const DEFAULT_POSITION = {
  x: 50,
  y: 50,
}
const DEFAULT_FILL_OPACITY = 0.2

const DEFAULT_DIVISION_START_ANGLE = -30
const DEFAULT_DIVISION_END_ANGLE = DEFAULT_DIVISION_START_ANGLE - 30

const DEGREE_IN_RADIANS = Math.PI / 180

export default class SitemapCircleProperties implements ISitemapCircle {
  public type: SitemapItemShapeType = SitemapItemShapeType.Circle
  @observable public lineWidth: number
  @observable public radius: number
  @observable public position: IPosition
  @observable public isDivided: boolean
  @observable public divisionStartAngle: number
  @observable public divisionEndAngle: number
  @observable public fillColor: string
  @observable public fillOpacity: number
  @observable public lineColor: string
  @observable public isDisplayed: boolean

  public constructor(
    lineWidth?: number,
    lineColor?: string,
    fillColor?: string,
    fillOpacity?: number,
    position?: IPosition,
    radius?: number,
    isDivided?: boolean,
    divisionStartAngle?: number,
    divisionEndAngle?: number,
    isDisplayed?: boolean,
  ) {
    this.lineWidth = lineWidth || DEFAULT_LINE_WIDTH
    this.lineColor = lineColor
    this.radius = radius || DEFAULT_RADIUS
    this.position = position || DEFAULT_POSITION
    this.isDivided = !!isDivided
    this.divisionStartAngle = divisionStartAngle
    this.divisionEndAngle = divisionEndAngle
    this.fillColor = fillColor
    this.fillOpacity =
      typeof fillOpacity === 'number' ? fillOpacity : DEFAULT_FILL_OPACITY
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
  }

  public get isDividedAndDivisionAnglesValid(): boolean {
    return (
      this.isDivided &&
      typeof this.divisionStartAngle === 'number' &&
      typeof this.divisionEndAngle === 'number'
    )
  }

  public getBoundingBox(
    containerWidth: number,
    containerHeight: number,
  ): IBoundingBox {
    const radius = (containerHeight * this.radius) / MAX_PERCENT
    const centerX = (containerWidth * this.position.x) / MAX_PERCENT
    const centerY = (containerHeight * this.position.y) / MAX_PERCENT

    return {
      x: centerX - radius,
      y: centerY - radius,
      width: radius * 2,
      height: radius * 2,
    }
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public isValid(): boolean {
    return !!this.position && !!this.radius
  }

  public setLineWidth(lineWidth: number) {
    this.lineWidth = lineWidth
  }

  public setLineColor(color: string) {
    this.lineColor = color
  }

  public setRadius(radius: number) {
    this.radius = Math.max(radius, FEET_TO_TURF_DISTANCE)
  }

  public setPosition(position: IPosition) {
    this.position = position
  }

  public move(x: number, y: number) {
    this.position = {
      x: this.position.x + x,
      y: this.position.y + y,
    }
  }

  public toggleIsDivided() {
    this.isDivided = !this.isDivided
    if (this.isDivided && !this.isDividedAndDivisionAnglesValid) {
      this.divisionStartAngle = DEFAULT_DIVISION_START_ANGLE
      this.divisionEndAngle = DEFAULT_DIVISION_END_ANGLE
    }
    return this
  }

  public setDivisionStartAngle = (angle: number) => {
    this.divisionStartAngle = angle
  }

  public setDivisionEndAngle = (angle: number) => {
    this.divisionEndAngle = angle
  }

  public setFillColor(color: string) {
    this.fillColor = color
  }

  public setFillOpacity(opacity: number) {
    this.fillOpacity = opacity
  }

  public calculateIconPosition(): IPosition {
    if (!this.isValid()) {
      return null
    }

    return {
      x: this.position.x,
      y: this.position.y,
    }
  }

  public isPositionEqual(position: IPosition) {
    if (!this.position) {
      return !position
    } else {
      return (
        position &&
        this.position.x === position.x &&
        this.position.y === position.y
      )
    }
  }

  public scale(originPoint: IPosition, scaleX: number, scaleY: number) {
    const maxPositiveScale = Math.max(Math.abs(scaleX), Math.abs(scaleY))
    this.position.x = scaleX * (this.position.x - originPoint.x) + originPoint.x
    this.position.y = scaleY * (this.position.y - originPoint.y) + originPoint.y
    this.radius *= maxPositiveScale
  }

  public rotate(center: IPosition, angle: number) {
    let { x, y } = this.position

    x -= center.x
    y -= center.y

    const cos = Math.cos(angle * DEGREE_IN_RADIANS)
    const sin = Math.sin(angle * DEGREE_IN_RADIANS)

    const newX = x * cos - y * sin
    const newY = x * sin + y * cos

    this.position = {
      x: newX + center.x,
      y: newY + center.y,
    }

    if (this.isDividedAndDivisionAnglesValid) {
      this.divisionStartAngle += angle
      this.divisionEndAngle += angle
    }
  }

  public isDisplayDataEqual(circle: ISitemapCircle) {
    return (
      this.isDisplayed ===
        (typeof circle.isDisplayed === 'boolean'
          ? circle.isDisplayed
          : DEFAULT_IS_DISPLAYED) &&
      this.type === circle.type &&
      this.lineWidth === circle.lineWidth &&
      this.lineColor === circle.lineColor &&
      this.radius === circle.radius &&
      this.isDivided === circle.isDivided &&
      this.divisionStartAngle === circle.divisionStartAngle &&
      this.divisionEndAngle === circle.divisionEndAngle &&
      this.fillColor === circle.fillColor &&
      this.fillOpacity === circle.fillOpacity &&
      this.isPositionEqual(circle.position)
    )
  }

  public getDisplayData(): ISitemapCircle {
    return {
      type: this.type,
      lineWidth: this.lineWidth,
      lineColor: this.lineColor,
      radius: this.radius,
      isDivided: this.isDivided,
      divisionStartAngle: this.divisionStartAngle,
      divisionEndAngle: this.divisionEndAngle,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      position: {
        x: this.position.x,
        y: this.position.y,
      },
      isDisplayed: this.isDisplayed,
    }
  }

  public copy(): SitemapCircleProperties {
    return new SitemapCircleProperties(
      this.lineWidth,
      this.lineColor,
      this.fillColor,
      this.fillOpacity,
      {
        x: this.position.x,
        y: this.position.y,
      },
      this.radius,
      this.isDivided,
      this.divisionStartAngle,
      this.divisionEndAngle,
      this.isDisplayed,
    )
  }
}

import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Delivery from '~/client/src/shared/models/Delivery'
import InitialState from '~/client/src/shared/stores/InitialState'
import AreasStore from '~/client/src/shared/stores/domain/Areas.store'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import RoutesStore from '~/client/src/shared/stores/domain/Routes.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import DeliveryCardFieldsConfigStore, {
  ICardAttribute,
  MAX_NAME_LENGTH,
} from '~/client/src/shared/stores/ui/DeliveryCardFieldsConfig.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { truncateText } from '~/client/src/shared/utils/textUtils'

import { DeliveryIcon } from '../../../DeliveryIcon/DeliveryIcon'
import DeliveryStatusText from '../../../DeliveryStatusText/DeliveryStatusText'
import DeliveryCalendarCardStore, {
  CARD_ROW_HEIGHT,
  MIN_CARD_TITLE_ROW_HEIGHT,
  MIN_CARD_WIDTH,
} from './DeliveryCalendarCard.store'

interface IProps {
  delivery: Delivery
  backgroundColor: string
  color: string
  border: string
  isRotated: boolean
  containerHeight: number
  containerWidth: number

  className?: string
  shouldUseMockValues?: boolean

  state?: InitialState
  projectDateStore?: ProjectDateStore
  areasStore?: AreasStore
  gatesStore?: GatesStore
  zonesStore?: ZonesStore
  deliveryVehicleTypesStore?: DeliveryVehicleTypesStore
  offloadingEquipmentsStore?: OffloadingEquipmentsStore
  companiesStore?: CompaniesStore
  buildingsStore?: BuildingsStore
  routesStore?: RoutesStore
  levelsStore?: LevelsStore
  materialsStore?: MaterialsStore
  projectMembersStore?: ProjectMembersStore
  materialCategoryStore?: MaterialCategoryStore
}

@inject(
  'state',
  'projectDateStore',
  'areasStore',
  'gatesStore',
  'zonesStore',
  'deliveryVehicleTypesStore',
  'offloadingEquipmentsStore',
  'companiesStore',
  'buildingsStore',
  'routesStore',
  'levelsStore',
  'materialsStore',
  'projectMembersStore',
  'materialCategoryStore',
)
@observer
export default class DeliveryCalendarCard extends React.Component<IProps> {
  private deliveryTitleContainer: HTMLDivElement
  private readonly store: DeliveryCalendarCardStore
  private readonly cardFieldsStore: DeliveryCardFieldsConfigStore

  public constructor(props: IProps) {
    super(props)

    this.store = new DeliveryCalendarCardStore()

    this.cardFieldsStore = new DeliveryCardFieldsConfigStore(
      props.delivery,
      props.state,
      props.projectDateStore,
      props.areasStore,
      props.gatesStore,
      props.zonesStore,
      props.deliveryVehicleTypesStore,
      props.offloadingEquipmentsStore,
      props.companiesStore,
      props.buildingsStore,
      props.routesStore,
      props.levelsStore,
      props.materialsStore,
      props.projectMembersStore,
      props.materialCategoryStore,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { delivery } = this.props

    if (delivery !== prevProps.delivery) {
      this.cardFieldsStore.init(delivery)
    }

    this.changeStatusRowAlignment()
  }

  public render() {
    const { delivery, backgroundColor, border, isRotated, className } =
      this.props
    const { isDone, isCanceled } = delivery

    return (
      <div
        className={classList({
          'full-height full-width px5 brada4': true,
          [className]: !!className,
          opacity7: isDone,
          opacity6: isCanceled,
        })}
        style={{ border }}
      >
        <div
          className="event-card-background full-width full-height"
          style={{ backgroundColor }}
        />
        <div
          className={toggleClass('rotated-event-label relative pl4', isRotated)}
        >
          <div className="row" ref={this.setDeliveryTitleContainerRef}>
            <div
              className={classList({
                'relative row full-width': true,
                wrap: !isRotated,
              })}
            >
              {this.deliveryTitle}
            </div>
          </div>
          <div
            className={classList({
              'no-grow text large grey-light': true,
              'white-space-normal': this.shouldCombineInfoIntoRow,
              col: !this.shouldCombineInfoIntoRow && !isRotated,
            })}
          >
            {this.attributeValueElements}
          </div>
        </div>
      </div>
    )
  }

  private get deliveryTitle(): JSX.Element[] {
    const { shouldUseMockValues, delivery, companiesStore } = this.props
    const { shouldAlignStatusToEnd } = this.store
    const { titleCardFields, getFieldValueById } = this.cardFieldsStore

    return titleCardFields.map((f, index) => {
      const fieldValue = getFieldValueById(f.fieldId, shouldUseMockValues)
      const displayValue = Array.isArray(fieldValue)
        ? fieldValue.join(', ')
        : fieldValue
      const truncatedFieldValue = truncateText(displayValue, MAX_NAME_LENGTH)
      const isStatusField = f.fieldId === FieldIds.STATUS
      const isCompanyField = f.fieldId === FieldIds.COMPANY

      const shouldUseNoGrowClass =
        !isStatusField || !index || (isStatusField && !shouldAlignStatusToEnd)

      return (
        <div
          key={f.fieldId}
          className={classList({
            row: true,
            'no-grow': shouldUseNoGrowClass,
          })}
        >
          {!index && this.renderDeliveryIcon()}
          {isStatusField ? (
            <DeliveryStatusText
              className="relative text large bold capitalize mr4"
              delivery={delivery}
              shouldAlignStatusToEnd={shouldAlignStatusToEnd}
            />
          ) : (
            <>
              <span
                title={displayValue}
                className="mr5 text large bold brand-dark"
              >
                {truncatedFieldValue}
              </span>
              {isCompanyField && (
                <span className="text large light mr4">
                  {delivery.codeToDisplay(companiesStore)}
                </span>
              )}
            </>
          )}
        </div>
      )
    })
  }

  private get attributeValueElements(): JSX.Element[] {
    const { titleCardFields } = this.cardFieldsStore
    const isTitleRowEmpty = !titleCardFields?.length

    return this.cardAttributeValues.map((value, index) => (
      <React.Fragment key={value.fieldId}>
        {this.renderAttributeField(value, !!index, isTitleRowEmpty && !index)}
      </React.Fragment>
    ))
  }

  private renderAttributeField = (
    field: ICardAttribute,
    shouldAddDot: boolean,
    shouldAddIcon: boolean,
  ): JSX.Element => {
    const iconContent = shouldAddIcon && this.renderDeliveryIcon()

    if (!this.shouldCombineInfoIntoRow && !this.props.isRotated) {
      return (
        <span
          title={field.fieldValue}
          className={classList({
            'text large grey-light': true,
            bold: field.isBold,
          })}
        >
          {iconContent}
          {field.fieldValue}
        </span>
      )
    }

    return (
      <>
        {iconContent}
        {shouldAddDot && ' • '}
        {field.isBold ? <b>{field.fieldValue}</b> : field.fieldValue}
      </>
    )
  }

  private renderDeliveryIcon(): JSX.Element {
    const {
      delivery: { isFromConcreteDirect },
      color,
    } = this.props

    return (
      <DeliveryIcon
        isFromCD={isFromConcreteDirect}
        className={classList({
          'no-grow mr4': true,
          'delivery-concrete-icon': isFromConcreteDirect,
          'delivery-icon': !isFromConcreteDirect,
        })}
        color={color}
      />
    )
  }

  @action.bound
  private changeStatusRowAlignment() {
    if (this.deliveryTitleContainer) {
      const { scrollHeight } = this.deliveryTitleContainer
      this.store.setStatusAlignment(scrollHeight < MIN_CARD_TITLE_ROW_HEIGHT)
    }
  }

  private setDeliveryTitleContainerRef = (ref: HTMLDivElement) => {
    this.deliveryTitleContainer = ref
  }

  @computed
  private get cardAttributeValues(): ICardAttribute[] {
    return this.cardFieldsStore.getCardAttributeValues(
      this.props.shouldUseMockValues,
    )
  }

  @computed
  private get shouldCombineInfoIntoRow(): boolean {
    const { containerWidth = 0, containerHeight = 0 } = this.props
    const scrollHeight = this.deliveryTitleContainer?.scrollHeight || 0
    const attributesHeight =
      this.cardAttributeValues.length * CARD_ROW_HEIGHT + scrollHeight

    return attributesHeight > containerHeight && containerWidth > MIN_CARD_WIDTH
  }
}

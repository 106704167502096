import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CompaniesList from '~/client/src/desktop/components/CompaniesList/CompaniesList'
import CompaniesListStore from '~/client/src/desktop/components/CompaniesList/CompaniesList.store'
import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import AddEditCompanyDialog from '~/client/src/desktop/views/ProjectSetUp/components/CompaniesDirectory/AddEditCompanyDialog/AddEditCompanyDialog'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import ConfirmDialogTypes from '~/client/src/shared/enums/ConfirmDialogTypes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import WhiteListItemsStore from '~/client/src/shared/stores/domain/WhiteListItems.store'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'
import BulkEditCompaniesSideBar from './BulkEditCompaniesSideBar/BulkEditCompaniesSideBar'
import CompaniesDirectoryStore, { ActionType } from './CompaniesDirectory.store'
import ConfirmCompaniesDialog from './ConfirmCompaniesDialog/ConfirmCompaniesDialog'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore

  state?: DesktopInitialState
  companiesStore?: CompaniesStore
  tagsStore?: TagsStore
  whiteListItemsStore?: WhiteListItemsStore
}

const iconByActionType = {
  [ActionType.GroupBy]: <Icon icon={IconNames.BOX} />,
  [ActionType.CompanyFilter]: <TagIcon.Company />,
}

@inject('state', 'companiesStore', 'tagsStore', 'whiteListItemsStore')
@observer
export default class CompaniesDirectory extends React.Component<IProps> {
  private readonly store: CompaniesDirectoryStore = null
  private readonly companiesListStore: CompaniesListStore = null

  public constructor(props: IProps) {
    super(props)

    this.companiesListStore = new CompaniesListStore(
      props.tagsStore,
      props.whiteListItemsStore,
      props.state,
      props.companiesStore,
    )

    this.store = new CompaniesDirectoryStore(
      props.state,
      props.companiesStore,
      this.companiesListStore,
    )
  }

  public render() {
    if (this.store.isRelatedDataLoading) {
      return <Loader />
    }

    const {
      itemsToEdit,
      allCompanies,
      hideCompanyModal,
      shouldShowCompanyModal,
      showEditModal,
      saveCompanies,
      areCompaniesBeingUpdated,
      lastUpdatedCompanyId,
      selectedCompanies,
      showBulkEditSideBar,
      shouldShowBulkModal,
      toggleConfirmDeleteDialog,
      hideBulkEditSideBar,
      updateCompaniesSettings,
      deleteCompanies,
      activateCompanies,
      errorMessage,
      shouldShowConfirmDeleteDialog,
    } = this.store

    const {
      displayedCompaniesCountLabel,
      isTabWithActiveCompaniesActivated,
      isTabWithVendorCompaniesActivated,
      toggleActiveCompanies,
      toggleVendorCompanies,
    } = this.companiesListStore

    return (
      <Wrapper>
        <Header title={Localization.translator.companyDirectory} />
        <div className="col scrollable mx30">
          {shouldShowCompanyModal && (
            <AddEditCompanyDialog
              closeDialog={hideCompanyModal}
              loading={areCompaniesBeingUpdated}
              addSaveItems={saveCompanies}
              itemsToEdit={itemsToEdit}
              allItems={allCompanies}
              errorMessage={errorMessage}
            />
          )}
          {shouldShowBulkModal && (
            <BulkEditCompaniesSideBar
              selectedCompanies={selectedCompanies}
              loading={areCompaniesBeingUpdated}
              toggleDeleteDialog={toggleConfirmDeleteDialog}
              isActivationMode={!isTabWithActiveCompaniesActivated}
              onClose={hideBulkEditSideBar}
              saveCompanies={updateCompaniesSettings}
            />
          )}
          <div className="row pb20">
            <div className="row no-grow nowrap pl5 pr10 tags-directory-tab">
              <div
                className={classList({
                  'row no-grow px10 nowrap text extra-large full-height ba-transparent pointer':
                    true,
                  'bg-palette-brand-lighter ba-light-input-border brada4':
                    isTabWithActiveCompaniesActivated,
                })}
                onClick={toggleActiveCompanies.bind(this, true)}
              >
                <span>{Localization.translator.active}</span>
              </div>
            </div>
            <div className="row no-grow nowrap px10 tags-directory-tab bl-light-grey">
              <div
                className={classList({
                  'row no-grow px10 nowrap text extra-large full-height ba-transparent pointer':
                    true,
                  'bg-palette-brand-lighter ba-light-input-border brada4':
                    !isTabWithActiveCompaniesActivated,
                })}
                onClick={toggleActiveCompanies.bind(this, false)}
              >
                <span>{Localization.translator.deactivated}</span>
              </div>
            </div>
          </div>
          <div className="row pb20">
            <div className="row no-grow nowrap pl5 pr10 tags-directory-tab">
              <div
                className={classList({
                  'row no-grow px10 nowrap text extra-large full-height ba-transparent pointer':
                    true,
                  'bg-palette-brand-lighter ba-light-input-border brada4':
                    !isTabWithVendorCompaniesActivated,
                })}
                onClick={toggleVendorCompanies.bind(this, false)}
              >
                <span>{Localization.translator.common}</span>
              </div>
            </div>
            <div className="row no-grow nowrap px10 tags-directory-tab bl-light-grey">
              <div
                className={classList({
                  'row no-grow px10 nowrap text extra-large full-height ba-transparent pointer':
                    true,
                  'bg-palette-brand-lighter ba-light-input-border brada4':
                    isTabWithVendorCompaniesActivated,
                })}
                onClick={toggleVendorCompanies.bind(this, true)}
              >
                <span>
                  {Localization.translator.vendor}{' '}
                  {Localization.translator.only}
                </span>
              </div>
            </div>
          </div>
          {this.renderActionBar()}
          <div className="row bb-light-cool-grey pa5 mb10">
            <div className="text large light py10 mr40 no-grow w-max-content">
              {displayedCompaniesCountLabel}
            </div>
            <BaseActionButton
              icon={<Icons.Edit />}
              className="inverse-scale-blue-theme mx4"
              isEnabled={!!selectedCompanies.length}
              isActive={true}
              shouldShowBorder={false}
              title={Localization.translator.edit_verb}
              onClick={showBulkEditSideBar}
            />
          </div>
          <CompaniesList
            initialScrollToId={lastUpdatedCompanyId}
            store={this.companiesListStore}
            rows={this.companiesListStore.rows}
            onEditClick={showEditModal}
          />
        </div>
        <ConfirmCompaniesDialog
          type={ConfirmDialogTypes.DELETE}
          isOpen={shouldShowConfirmDeleteDialog}
          loading={areCompaniesBeingUpdated}
          closeDialog={toggleConfirmDeleteDialog.bind(this, false)}
          performItems={
            isTabWithActiveCompaniesActivated
              ? deleteCompanies
              : activateCompanies
          }
          itemsToPerform={selectedCompanies}
          isActivationMode={!isTabWithActiveCompaniesActivated}
        />
      </Wrapper>
    )
  }

  private renderActionBar(): JSX.Element {
    const { actions, companiesFilters } = this.store

    return (
      <div className="row pb10">
        <div className="no-grow ml4">
          <EntityNameFilter filters={companiesFilters} />
        </div>
        {actions.map(({ type, title, isActive, isEnabled, onClick }) => (
          <BaseActionButton
            key={type}
            className={classList({
              ml12: true,
              'scale-blue-theme ml-auto': type === ActionType.AddCompany,
            })}
            title={title}
            isActive={isActive}
            isEnabled={isEnabled}
            icon={iconByActionType[type]}
            onClick={onClick}
          />
        ))}
      </div>
    )
  }
}

import { action, computed, observable } from 'mobx'

import { IProject, IProjectAddressInput } from '~/client/graph'
import { GetProjectAddressDocument } from '~/client/graph/operations/generated/ProjectAddress.generated'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Project from '~/client/src/shared/models/Project'
import ProjectStatus from '~/client/src/shared/models/ProjectStatus'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import {
  ACTIVATE_PROJECT,
  HANDLE_SAME_PROJECT_SELECTION,
  LOAD_PROJECT_STATUSES,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import Guard from '~/client/src/shared/utils/Guard'

import { IProjectMarkerInfo } from '../../components/MapBoxEditor/MapBoxEditor'
import { getAddressCopy } from '../../utils/Address'
import GraphExecutorStore from './GraphExecutor.store'

const ADDRESS_DISPLAY_PROPS = ['address', 'city', 'state', 'zipcode', 'country']

export default class ProjectsStore {
  @observable public sourceMap: Map<string, IProject> = new Map() // by project code

  @observable public projectStatuses: Map<string, ProjectStatus> = new Map()
  private projectAddresses = observable.map<string, IProjectAddressInput>()

  public constructor(
    public eventsStore: EventsStore,
    private graphExecutorStore: GraphExecutorStore,
  ) {
    Guard.requireAll({
      eventsStore,
      graphExecutorStore,
    })
  }

  @computed
  public get list(): IProject[] {
    return [...this.sourceMap.values()]
  }

  public getProjectByCode = (projectCode: string): IProject => {
    if (projectCode) {
      return this.sourceMap.get(projectCode)
    }
  }

  public getProjectById = (projectId: string): IProject => {
    return this.list.find(p => p.id === projectId)
  }

  public get activeProject() {
    return this.eventsStore.appState.activeProject
  }

  public get currentProjectStatus() {
    return this.projectStatuses.get(this.activeProject.id)
  }

  public get currentProjectStartDate() {
    return this.currentProjectStatus && this.currentProjectStatus.startDate
  }

  public get currentProjectEndDate() {
    return this.currentProjectStatus && this.currentProjectStatus.endDate
  }

  public async loadProjectAddresses() {
    const projectAddressesResult = {}
    const projectIds = this.list.map(p => p.id)

    await Promise.all(
      projectIds.map(async projectId => {
        if (!projectId) {
          return
        }

        const { data } = await this.graphExecutorStore.query(
          GetProjectAddressDocument,
          { projectId },
        )

        if (data?.projectAddresses.data.length) {
          projectAddressesResult[projectId] = getAddressCopy(
            data.projectAddresses.data[0],
          )
        }
      }),
    )

    this.projectAddresses.replace(projectAddressesResult)
  }

  public getAddressString = (projectId: string) => {
    const address = this.projectAddresses.get(projectId)
    if (!address) {
      return Localization.translator.notSpecified
    }

    return ADDRESS_DISPLAY_PROPS.map(propName => address[propName])
      .filter(prop => prop)
      .join(' ')
  }

  public getProjectMarkerInfo = (project: IProject): IProjectMarkerInfo => {
    const address = this.projectAddresses.get(project.id)
    if (!address?.center) {
      return null
    }

    return {
      coordinates: {
        latitude: address.center.lat,
        longitude: address.center.lng,
      },
      projectId: project.id,
      projectName: project.name,
      projectCode: project.code,
    } as IProjectMarkerInfo
  }

  @action.bound
  public selectProject(project: Project | string): Promise<void> {
    const projectId = typeof project === 'string' ? project : project.id

    if (projectId === this.activeProject.id) {
      return this.eventsStore.dispatch(HANDLE_SAME_PROJECT_SELECTION)
    }

    return this.eventsStore.dispatch(ACTIVATE_PROJECT, projectId)
  }

  @action.bound
  public setStatus(projectId: string, status: ProjectStatus) {
    this.projectStatuses.set(projectId, status)
  }

  public receiveList(projects: IProject[]) {
    this.clear()
    projects.forEach(this.setOne)
  }

  @action.bound
  public setOne(dto: IProject) {
    this.sourceMap.set(dto.code, dto)
  }

  @action.bound
  public clear() {
    this.sourceMap.clear()
  }

  public loadProjectsList() {
    this.eventsStore.dispatch(LOAD_PROJECT_STATUSES)
  }

  @computed
  public get lastViewedProjectCode(): string {
    try {
      const { id: userId } = this.eventsStore.appState.user
      // eslint-disable-next-line no-var
      var userPref = JSON.parse(localStorage.getItem(userId)) || {}
    } catch (e) {
      /* empty */
    }

    if (userPref.lastViewedProjectCode) {
      return userPref.lastViewedProjectCode
    }

    if (userPref.lastViewedProject) {
      const project = this.getProjectById(userPref.lastViewedProject)
      return project?.code || ''
    }

    return ''
  }

  public get hasActiveUserAccessToTargetProject(): boolean {
    return !!this.getProjectByCode(
      this.eventsStore.appState.activeOrInitProjectCode,
    )
  }

  public getAvailableProjectCode = (): string => {
    const lastViewedProject = this.getProjectByCode(this.lastViewedProjectCode)

    return lastViewedProject?.code || this.list[0]?.code
  }
}

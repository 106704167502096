import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DetailListItem from '~/client/src/shared/components/DetailListItem'
import ActivityInfoStore from '~/client/src/shared/components/ActivityDetails/components/ActivityInfoSection/ActivityInfo.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import getUserName from '../../utils/GetUserName'

import './ActivityTeamDetails.scss'

// translated

export interface IActivityTeamDetailsProps {
  store: ActivityInfoStore
}

@observer
class ActivityTeamDetails extends React.Component<IActivityTeamDetailsProps> {
  @observable private shouldExpandAssigneeList = false

  public render() {
    const shouldRenderToggle =
      (this.assigneesCount > 2 && this.companies.length > 1) ||
      this.assigneesCount > 3

    return (
      <DetailListItem category={Localization.translator.assigned}>
        <div
          className={classList({
            'col assignees-container': true,
            expanded: this.shouldExpandAssigneeList,
          })}
        >
          {this.renderAssigneesList()}
        </div>
        {shouldRenderToggle && this.renderToggle()}
      </DetailListItem>
    )
  }

  @computed
  public get companies() {
    const { owners } = this.props.store
    const companies = Object.keys(owners || {})
    return companies.sort((a, b) => {
      return a.localeCompare(b)
    })
  }

  @computed
  private get assigneesCount(): number {
    const { owners } = this.props.store

    return this.companies.reduce((count, company) => {
      return count + owners[company].length
    }, 0)
  }

  private renderToggle() {
    const { CHEVRON_DOWN, CHEVRON_UP } = IconNames

    return (
      <span
        className="row x-end text small primary-blue uppercase pr20"
        onClick={this.toggleAssigneesList}
      >
        {Localization.translator.viewTeam}
        <Icon
          icon={this.shouldExpandAssigneeList ? CHEVRON_UP : CHEVRON_DOWN}
          className="text primary-blue"
        />
      </span>
    )
  }

  private renderAssigneesList() {
    if (!this.companies.length) {
      return NO_VALUE
    }

    const { owners } = this.props.store

    return this.companies.map((company, idx) => (
      <div key={idx} className="pb3">
        <div className="row y-start">
          <span className="col text large primary-blue">{company}</span>
        </div>
        {owners[company].map(user => (
          <div key={user.id}>
            <span className="text bold large no-grow">{getUserName(user)}</span>
            {user.globalRole && (
              <span className="text px5 large">
                {'(' + user.globalRole + ')'}
              </span>
            )}
          </div>
        ))}
      </div>
    ))
  }

  @action.bound
  private toggleAssigneesList() {
    this.shouldExpandAssigneeList = !this.shouldExpandAssigneeList
  }
}

export default ActivityTeamDetails

import * as React from 'react'

import MapBoxEditorStore from '~/client/src/shared/components/MapBoxEditor/MapBoxEditor.store'
import SitemapItemBase from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemBase'

import HierarchyNode from '../../models/HierarchyNode'
import SitemapItemsSetupStore from '../../stores/SitemapItemsSetup.store'
import SitemapViewsSetupStore from '../../stores/SitemapViewsSetup.store'
import HierarchyTreeNode from './HierarchyTreeNode'

const DEFAULT_LEVEL = 1

export interface IProps {
  nodes: HierarchyNode[]
  store: SitemapItemsSetupStore
  sitemapViewsSetupStore: SitemapViewsSetupStore
  mapBoxEditorStore?: MapBoxEditorStore
  level?: number

  withinHighlightedGroup?: boolean
  lastNodeOfHighlightedGroupId?: string
  getUpdatedItem?: (item: SitemapItemBase) => SitemapItemBase
}

export default class HierarchyTree extends React.Component<IProps> {
  public render() {
    const {
      nodes,
      store,
      level,
      withinHighlightedGroup,
      lastNodeOfHighlightedGroupId,
      sitemapViewsSetupStore,
      mapBoxEditorStore,
      getUpdatedItem,
    } = this.props

    return nodes.map((node, idx) => (
      <HierarchyTreeNode
        node={node}
        key={idx}
        sitemapItemsSetupStore={store}
        sitemapViewsSetupStore={sitemapViewsSetupStore}
        level={level || DEFAULT_LEVEL}
        withinHighlightedGroup={withinHighlightedGroup}
        lastNodeOfHighlightedGroupId={lastNodeOfHighlightedGroupId}
        mapBoxEditorStore={mapBoxEditorStore}
        getUpdatedItem={getUpdatedItem}
      />
    ))
  }
}

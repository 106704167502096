import { SaveInteriorDoorsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import SitemapAttributeIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import InteriorDoor from '../../models/LocationObjects/InteriorDoor'
import LocationStoreBase from './LocationBase.store'

export default class InteriorDoorsStore extends LocationStoreBase<InteriorDoor> {
  protected saveMutation = SaveInteriorDoorsDocument
  protected graphName = 'interiorDoors'
  protected defaultIconName = SitemapAttributeIcon.InteriorDoor

  public get byId(): Map<string, InteriorDoor> {
    return this.eventsStore.appState.interiorDoors
  }

  protected fromDto(dto: ILocationDto): InteriorDoor {
    return InteriorDoor.fromDto(dto)
  }
}
